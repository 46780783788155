import { createSlice } from '@reduxjs/toolkit'
import { IEventsSlice } from '../../events.types'

const initialState = {
  currentDate: new Date(),
  filters: { country: '', name: '' },
  event: null,
} as IEventsSlice

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    SET_CURRENT_DATE(state, action) {
      state.currentDate = action.payload
    },
    SET_EVENTS_FILTERS(state, action) {
      state.filters = action.payload
    },
    SET_EVENT(state, action) {
      state.event = action.payload
    },
  },
})

export const { SET_CURRENT_DATE, SET_EVENTS_FILTERS, SET_EVENT } = eventsSlice.actions

export const eventsReducer = eventsSlice.reducer
