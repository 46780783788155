import { message } from 'antd'
import {
  useLazyArchiveCampaignQuery,
  useLazySetCampaignsActiveQuery,
  useLazyUnarchiveCampaignQuery,
} from 'features/Outreach/state/api/OutreachApi'
import { useEffect, useState } from 'react'
import moment from 'moment'
interface Campaign {
  campaign_id: string
  campaign_name: string
  status: any
  run_at: string
}

interface InactiveCampaignInfo {
  campaign_name: string
  nextActiveMoment: moment.Moment
}

export const useCampaigns = (campaigns: any[]) => {
  const [refetchArchiveCampaign] = useLazyArchiveCampaignQuery()
  const [refetchUnarchiveCampaign] = useLazyUnarchiveCampaignQuery()
  const [refetchSetCampaignsActive] = useLazySetCampaignsActiveQuery()
  const [inactiveCampaigns, setInactiveCampaigns] = useState<InactiveCampaignInfo[]>([])

  const handleArchiveCampaignModal = (
    item: any,
    setCampaignName: (name: string) => void,
    setCampaignId: (id: string) => void,
    setOpenArchiveCampaignModal: (open: boolean) => void,
  ) => {
    setCampaignName(item.campaign_name)
    setCampaignId(item.campaign_id)
    setOpenArchiveCampaignModal(true)
  }

  const handleArchiveCampaign = async (
    campaignId: string,
    campaignName: string,
    setOpenArchiveCampaignModal: (open: boolean) => void,
    setTriggerGetCampaigns: any,
  ) => {
    try {
      await refetchArchiveCampaign({ campaign_ids: [campaignId], archive: true })
      setTriggerGetCampaigns((prev: boolean) => !prev)
      setOpenArchiveCampaignModal(false)
      message.success(`${campaignName} Archived Successfully!`)
    } catch (e) {
      message.error('Something went wrong. Please try again.')
    }
  }

  const handleUnarchiveCampaign = async (item: any, setTriggerGetCampaigns: any) => {
    try {
      await refetchUnarchiveCampaign({ campaign_ids: [item.campaign_id], archive: false })
      setTriggerGetCampaigns((prev: boolean) => !prev)

      message.success(`${item.campaign_name} Unarchived Successfully!`)
    } catch (e) {
      message.error('Something went wrong. Please try again.')
    }
  }

  const handleCampaignActiveStatus = async (
    checked: boolean,
    campaign_name: any,
    campaign_id: any,
    setCampaignName: (name: string) => void,
    setCampaignId: (id: string) => void,
    setOpenDisableCampaignModal: (open: boolean) => void,
    setCampaignLoading: (loading: any) => void,
    setCampaignStatuses: (statuses: any) => void,
    setCampaignStates?: (states: any) => void,
  ) => {
    if (!checked) {
      setCampaignName(campaign_name)
      setCampaignId(campaign_id)
      setOpenDisableCampaignModal(true)
    } else {
      setCampaignLoading((prevStates: any) => ({
        ...prevStates,
        [campaign_id]: true,
      }))
      await refetchSetCampaignsActive({ campaign_ids: [campaign_id], is_active: checked, status: 'warming' })
    }
  }

  const handleConfirmation = async (
    campaignId: string,
    setOpenDisableCampaignModal: (open: boolean) => void,
    setCampaignStates: (states: any) => void,
    setCampaignLoading: (loading: any) => void,
    setCampaignStatuses: (statuses: any) => void,
  ) => {
    setOpenDisableCampaignModal(false)
    setCampaignLoading &&
      setCampaignLoading((prevStates: any) => ({
        ...prevStates,
        [campaignId]: true,
      }))
    await refetchSetCampaignsActive({ is_active: false, campaign_ids: [campaignId], status: 'paused' })
    setTimeout(() => {
      setCampaignStates((prevStates: any) => ({
        ...prevStates,
        [campaignId]: false,
      }))
      setCampaignStatuses &&
        setCampaignStatuses((prevStates: any) => ({
          ...prevStates,
          [campaignId]: 'paused',
        }))
      setCampaignLoading &&
        setCampaignLoading((prevStates: any) => ({
          ...prevStates,
          [campaignId]: false,
        }))
    }, 2000)
  }

  const filterDuplicates = (data: any[]): any[] => {
    const uniqueEntries: { [key: string]: any } = {}

    data.forEach((entry) => {
      const key = entry?.user_telegram_id
      const entryTime =
        entry?.send_type === 'web'
          ? new Date(entry?.created_at).getTime() + 8 * 60 * 60 * 1000
          : new Date(entry?.created_at).getTime()

      if (!uniqueEntries[key] || new Date(uniqueEntries[key]?.created_at).getTime() < entryTime) {
        uniqueEntries[key] = entry
      }
    })

    return Object.values(uniqueEntries)
  }

  const useCountdowns = (cooldowns: any[]) => {
    const [countdowns, setCountdowns] = useState<number[]>([])

    useEffect(() => {
      const updateCountdowns = () => {
        const now = new Date()
        const result = filterDuplicates(cooldowns)
        const newCountdowns = result
          .map((cooldown) => {
            const targetTime = new Date(cooldown.created_at)
            const cooldownTime = cooldown.send_type === 'web' ? 8 : 24
            targetTime.setHours(targetTime.getHours() + cooldownTime)
            const difference = targetTime.getTime() - now.getTime()
            return Math.floor(difference / 1000)
          })
          .filter((countdown) => countdown > 0)
        setCountdowns(newCountdowns)
      }

      updateCountdowns()

      const intervalId = setInterval(updateCountdowns, 1000)

      return () => clearInterval(intervalId)
    }, [cooldowns])

    return countdowns
  }

  // Bulk functions
  const handleBulkArchiveCampaign = async (campaignIds: string[], setTriggerGetCampaigns: any) => {
    try {
      await refetchArchiveCampaign({ campaign_ids: campaignIds, archive: true })
      setTriggerGetCampaigns((prev: boolean) => !prev)
      message.success(`Campaigns Archived Successfully!`)
      return true
    } catch (e) {
      message.error('Something went wrong. Please try again.')
    }
  }

  const handleBulkUnarchiveCampaign = async (campaignIds: string[], setTriggerGetCampaigns: any) => {
    try {
      await refetchUnarchiveCampaign({ campaign_ids: campaignIds, archive: false })
      setTriggerGetCampaigns((prev: boolean) => !prev)
      message.success(`Campaigns Unarchived Successfully!`)
    } catch (e) {
      message.error('Something went wrong. Please try again.')
    }
  }

  const handleBulkCampaignActiveStatus = async (
    campaignIds: string[],
    isActive: boolean,
    status: string,
    setCampaignLoading: (loading: any) => void,
    setTriggerGetCampaigns: any,
  ) => {
    setCampaignLoading((prevStates: any) =>
      campaignIds.reduce(
        (acc, id) => {
          acc[id] = true
          return acc
        },
        { ...prevStates },
      ),
    )

    try {
      await refetchSetCampaignsActive({ campaign_ids: campaignIds, is_active: isActive, status })
      setTriggerGetCampaigns((prev: boolean) => !prev)
      setCampaignLoading((prevStates: any) =>
        campaignIds.reduce(
          (acc, id) => {
            acc[id] = false
            return acc
          },
          { ...prevStates },
        ),
      )
      message.success(`Campaigns ${isActive ? 'Activated' : 'Deactivated'} Successfully!`)
    } catch (e) {
      setCampaignLoading((prevStates: any) =>
        campaignIds.reduce(
          (acc, id) => {
            acc[id] = false
            return acc
          },
          { ...prevStates },
        ),
      )
      message.error('Something went wrong. Please try again.')
    }
  }

  const handleBulkConfirmation = async (
    campaignIds: string[],
    setCampaignLoading: (loading: any) => void,
    setCampaignStates: (states: any) => void,
    setCampaignStatuses: (statuses: any) => void,
  ) => {
    setCampaignLoading((prevStates: any) =>
      campaignIds.reduce(
        (acc, id) => {
          acc[id] = true
          return acc
        },
        { ...prevStates },
      ),
    )

    try {
      await refetchSetCampaignsActive({ is_active: false, campaign_ids: campaignIds, status: 'paused' })
      setTimeout(() => {
        setCampaignStates((prevStates: any) =>
          campaignIds.reduce(
            (acc, id) => {
              acc[id] = false
              return acc
            },
            { ...prevStates },
          ),
        )
        setCampaignStatuses((prevStates: any) =>
          campaignIds.reduce(
            (acc, id) => {
              acc[id] = 'paused'
              return acc
            },
            { ...prevStates },
          ),
        )
        setCampaignLoading((prevStates: any) =>
          campaignIds.reduce(
            (acc, id) => {
              acc[id] = false
              return acc
            },
            { ...prevStates },
          ),
        )
      }, 2000)
    } catch (e) {
      setCampaignLoading((prevStates: any) =>
        campaignIds.reduce(
          (acc, id) => {
            acc[id] = false
            return acc
          },
          { ...prevStates },
        ),
      )
      message.error('Something went wrong. Please try again.')
    }
  }

  const isActive = (schedule: { timeZone: string; timeRanges: { [key: string]: string[] } }): boolean => {
    const now = moment().tz(schedule.timeZone)
    const dayOfWeek = now.format('dddd')
    const currentTime = now.format('HHmm')

    const activeToday = schedule.timeRanges[dayOfWeek]
    if (!activeToday) return false
    const startTime = activeToday[0] + activeToday[1]
    const endTime = activeToday[2] + activeToday[3]

    return currentTime >= startTime && currentTime <= endTime
  }

  const getNextActiveMoment = (schedule: { timeZone: string; timeRanges: any }): moment.Moment => {
    const timeZone = schedule?.timeZone
    const now = moment().tz(timeZone)
    let nextActiveMoment = moment.tz('2100-01-01', timeZone) // Future date as a fallback

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    // Find today's index and rearrange the array so today is first
    const todayIndex = now.day()
    const orderedDays = [...daysOfWeek.slice(todayIndex), ...daysOfWeek.slice(0, todayIndex)]

    for (const day of orderedDays) {
      const times = schedule.timeRanges[day]
      if (!times) continue // Skip days without a schedule
      const [startHour, startMinute] = times
      const startTime = now
        .clone()
        .add(orderedDays.indexOf(day), 'days')
        .hour(parseInt(startHour, 10))
        .minute(parseInt(startMinute, 10))
        .second(0)

      // If we've found a day in the future or today but later than now, return its start time
      if (startTime.isAfter(now) && startTime.isBefore(nextActiveMoment)) {
        nextActiveMoment = startTime
        break
      }
    }

    // Adjust for the scenario where the next active time is next week
    if (nextActiveMoment.year() === 2100 && schedule?.timeRanges[daysOfWeek[todayIndex]]) {
      const [startHour, startMinute] = schedule?.timeRanges[daysOfWeek[todayIndex]]

      nextActiveMoment = now
        .clone()
        .day(daysOfWeek[todayIndex])
        .add(1, 'weeks')
        .hour(parseInt(startHour, 10))
        .minute(parseInt(startMinute, 10))
        .second(0)
    }

    return nextActiveMoment
  }

  useEffect(() => {
    if (campaigns) {
      const newInactiveCampaigns: InactiveCampaignInfo[] = campaigns.reduce<InactiveCampaignInfo[]>((acc, campaign) => {
        if (campaign.status !== 'running') {
          return acc
        }
        const schedule = JSON.parse(campaign.run_at) as {
          timeZone: string
          timeRanges: { [day: string]: string[] }
        }
        if (schedule && !isActive(schedule)) {
          let nextActiveMoment = getNextActiveMoment(schedule)
          acc.push({
            campaign_name: campaign.campaign_name,
            nextActiveMoment: nextActiveMoment,
          })
        }
        return acc
      }, [])

      setInactiveCampaigns(newInactiveCampaigns)
    }
  }, [campaigns])

  const getNextActiveTime = (campaign: Campaign): moment.Moment | null => {
    if (campaign.status !== 'running') return null
    const schedule = JSON.parse(campaign.run_at) as {
      timeZone: string
      timeRanges: { [day: string]: string[] }
    }
    if (schedule && !isActive(schedule)) {
      return getNextActiveMoment(schedule)
    }
    return null
  }

  return {
    handleArchiveCampaignModal,
    handleArchiveCampaign,
    handleUnarchiveCampaign,
    handleCampaignActiveStatus,
    handleConfirmation,
    handleBulkArchiveCampaign,
    handleBulkUnarchiveCampaign,
    handleBulkCampaignActiveStatus,
    handleBulkConfirmation,
    useCountdowns,
    inactiveCampaigns,
    getNextActiveTime,
  }
}
