import { Card } from 'antd'
import React from 'react'
import styles from './GettingStartedCard.module.scss'
import bookIcon from 'common/assets/svg/Book.svg'
import arrowRight from 'common/assets/svg/arrowRight.svg'
import { useAppDispatch } from 'state'
import { SET_GETTING_STARTED_OPEN, SET_LEARNING_OPEN } from '../../state/slice/learningSlice'
import GettingStartedDrawer from './GettingStartedDrawer'
import { post } from 'common/api/axios'
interface GettingStartedCardProps {
  allArticles: any
}
export default function GettingStartedCard({ allArticles }: GettingStartedCardProps) {
  const dispatch = useAppDispatch()
  return (
    <>
      <GettingStartedDrawer allArticles={allArticles} />
      <Card
        onClick={() => {
          post('/log', {
            action: 'Learning Center: User Clicked Getting Started',
          })
          dispatch(SET_LEARNING_OPEN(false))
          dispatch(SET_GETTING_STARTED_OPEN(true))
        }}
        bodyStyle={{ width: '100%', height: '100%', padding: 15 }}
        className={styles.card}>
        <div style={{ width: '100%', height: '100%', display: 'flex' }}>
          <div style={{ flex: 0.1, display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <img style={{}} src={bookIcon} alt='Book' />
          </div>
          <div style={{ flex: 0.9, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 4, justifyContent: 'center', height: '100%' }}>
              <span className={styles.title}>Getting Started</span>
              <span className={styles.subTitle}>How To Start With Convrt</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <img src={arrowRight} alt='Arrow Right' />
            </div>
          </div>
        </div>
      </Card>
    </>
  )
}
