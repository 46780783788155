// import { useAuth } from 'common/hooks/useAuth.hooks'
import styles from './OnBoardingTelegramDetailsPage.module.scss'

import { FancyImage } from 'features/contacts/components/FancyImage/FancyImage'
import { useState } from 'react'

interface Props {
  anyimageUrl: any
  userName: any
  telegram: any
  bio: any
  senderName: any
  senderCompany: any
}
const OnBoardingTelegramDetailsPage = ({ anyimageUrl, userName, telegram, bio, senderName, senderCompany }: Props) => {
  const [url] = useState(anyimageUrl)
  return (
    <div className={styles.container}>
      <div className={styles.containerText}>
        <p>{'Oh, Here You Are!'}</p>
        <section>
          Verify your details and finish the setup
          <br />
        </section>
      </div>
      <div className={styles.details}>
        <FancyImage url={url} className={styles.img} iscompany={true} />
        <div className={styles.userName}>{userName}</div>
        <div className={styles.telegram}>{telegram}</div>
        <div className={styles.bio}>{bio}</div>
        <div className={styles.bio}>{senderName}</div>
        <div className={styles.bio}>{senderCompany}</div>
      </div>
    </div>
  )
}

export default OnBoardingTelegramDetailsPage
