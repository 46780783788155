import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Collapse, Form, Input, Slider, message } from 'antd'
import styles from './WizardBlock.module.scss'
import step_send_tg from 'common/assets/svg/step_send_tg.svg'
import step_enrich_hubspot from 'common/assets/svg/step_enrich_hubspot.svg'
import condition_is_message_replied from 'common/assets/svg/condition_is_message_replied.svg'
import condition_wait from 'common/assets/svg/condition_wait.svg'
import linkedin_campaign_icon from 'common/assets/svg/linkedin_campaign_icon.svg'
import request_campaign_icon from 'common/assets/svg/request_campaign_icon.svg'
import coming_soon_badge from 'common/assets/svg/coming_soon_badge.svg'
import { SET_SELECTED_PLATFORM, SET_TEMPLATE } from '../../../../state/outreachCampaignSlice'
import { useAppDispatch } from 'state'
import { useLazySendTemplateRequestQuery, useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import type { SliderMarks } from 'antd/es/slider'

interface WizardBlockProps {
  circleNumber: number
  label: string
  setTemplate?: any
  platform?: any
  setPlatform?: any
}

const { Panel } = Collapse

const WizardBlock: React.FC<WizardBlockProps> = ({ circleNumber, label, setTemplate, platform, setPlatform }) => {
  const [refetchSendTemplateRequest] = useLazySendTemplateRequestQuery()
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [maxTemplate, setMaxTemplate] = useState<number>(0)
  const [inputValue, setInputValue] = useState(0)
  const [activePanel, setActivePanel] = useState('1')
  const [requestTemplateMessage, setRequestTemplateMessage] = useState('')
  const [linkedinAddon, setLinkedinAddon] = useState(false)
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  // const [activeSequence, setActiveSequence] = useState('telegram')

  const image =
    label === 'send_tg_message'
      ? step_send_tg
      : label === 'enrich_hubspot'
      ? step_enrich_hubspot
      : label === 'is_message_replied'
      ? condition_is_message_replied
      : label === 'is_wait'
      ? condition_wait
      : ''

  const text =
    label === 'send_tg_message'
      ? 'Basic Outreach Sequence'
      : label === 'enrich_hubspot'
      ? 'CRM Actions Steps'
      : label === 'request'
      ? 'request'
      : ''

  const onChange = (value: number) => {
    const newValue = Math.round(value)
    // if (newValue > maxTemplate) {
    //   setInputValue(maxTemplate)
    //   setTemplate(maxTemplate)
    //   dispatch(SET_TEMPLATE(maxTemplate))
    // } else {
    setInputValue(newValue)
    setTemplate(newValue)
    dispatch(SET_TEMPLATE(newValue))
    // }
  }

  const handleSubmit = (values: any) => {
    // Handle the form submission here
  }

  // const handleCancel = () => {
  // }

  const handlePanelChange = (key: any, platform?: any) => {
    setActivePanel(key)
    // setActiveSequence(platform)
  }

  const handleTemplateMessage = (e: any) => {
    setRequestTemplateMessage(e.target.value)
  }

  const handleSendTemplateRequest = async () => {
    try {
      const { data } = await refetchSendTemplateRequest({ request: requestTemplateMessage })
      if (data) {
        message.success('Template Request Received! Thank You!')
        form.setFieldValue('requestText', '')
        setRequestTemplateMessage('')
      }
    } catch (e) {
      message.error('An error occured while sending your template request.')
    }
  }

  const validateRequestText = async (_: any, value: string) => {
    if (value && value.length < 10) {
      throw new Error('Text must be at least 10 characters.')
    }
  }

  useEffect(() => {
    const getAddons = async () => {
      const { data } = await refetchGetUserDetails()
      const addons = data?.addons
      const outreachFollowups = addons?.outreach_followups
      if (typeof outreachFollowups === 'number' && outreachFollowups >= 0 && outreachFollowups <= 2) {
        setMaxTemplate(outreachFollowups)
      } else {
        setMaxTemplate(0)
      }

      const linkedin = addons?.linkedin_campaign
      setLinkedinAddon(linkedin)
    }
    getAddons()
  }, [])

  // const marks: SliderMarks = {
  //   0: <div className={styles.sliderMax}>{/* <span>Max</span> */}</div>,
  //   1: (
  //     <div className={styles.sliderMax}>
  //       <span>{inputValue === 1 ? 'Max' : ''}</span>
  //     </div>
  //   ),
  //   2: <div className={styles.sliderMax}>{/* <span>Max</span> */}</div>,
  // }

  const marks: SliderMarks =
    maxTemplate === 0
      ? {
          0: <div className={styles.sliderMax}>{/* <span>{inputValue === 0 ? 'Max' : ''}</span> */}</div>,
          1: <span />,
          2: <span />,
        }
      : maxTemplate === 1
      ? {
          0: <span />,
          1: <div className={styles.sliderMax}>{/* <span>{inputValue === 1 ? 'Max' : ''}</span> */}</div>,
          2: <span />,
        }
      : maxTemplate === 2
      ? {
          0: <span />,
          1: <span />,
          2: <div className={styles.sliderMax}>{/* <span>{inputValue === 2 ? 'Max' : ''}</span> */}</div>,
        }
      : {}

  const BasicOutreachSequence = (
    <Collapse
      className={styles.container}
      style={platform === 'telegram' ? { outline: '1px solid #7043ff' } : {}}
      accordion
      ghost
      activeKey={platform === 'telegram' ? activePanel : ''}
      destroyInactivePanel={true}
      onChange={(key) => {
        handlePanelChange(key, 'telegram')
        setPlatform('telegram')
        setTemplate(0)
        dispatch(SET_SELECTED_PLATFORM('telegram'))
        dispatch(SET_TEMPLATE(0))
        setInputValue(0)
      }}
      // defaultActiveKey={['1']}
      expandIconPosition='end'>
      <Panel
        header={
          <div className={`${styles.node}`} id={circleNumber.toLocaleString()}>
            <img src={image} alt='' />
            {text}
          </div>
        }
        key='1'>
        <div className={styles.slider}>
          <div className={styles.numberOfFollowups}>
            <span># of Followups</span>
            <span>{inputValue}</span>
          </div>
          <Slider
            onChange={onChange}
            tooltip={{ formatter: null }}
            min={0}
            max={2}
            step={0.1}
            value={typeof inputValue === 'number' ? inputValue : 0}
            marks={marks}
          />
        </div>
      </Panel>
    </Collapse>
  )

  const CRMActionsSteps = (
    <Collapse
      className={styles.container}
      // style={{ filter: 'grayscale(1) opacity(30%) ' }}
      accordion
      ghost
      defaultActiveKey={['0']}
      activeKey={activePanel}
      onChange={handlePanelChange}
      destroyInactivePanel={true}
      expandIconPosition='end'
      collapsible='disabled'>
      <Panel
        header={
          <>
            <div className={styles.comingSoon}>
              <img src={coming_soon_badge} alt='' />
            </div>
            <div className={`${styles.node}`} id={circleNumber.toLocaleString()}>
              <img src={image} alt='' />
              {text}
            </div>
          </>
        }
        key='2'>
        <div className={styles.body}>
          <div className={styles.createCheckbox}>
            <Checkbox />
            <span style={{ marginLeft: '10px' }}>
              Create a Deal
              <br /> When Sent
            </span>
          </div>
          <div className={styles.updateCheckbox}>
            <Checkbox />
            <span style={{ marginLeft: '10px' }}>
              Update Deal
              <br />
              When Replied
            </span>
          </div>
        </div>
      </Panel>
    </Collapse>
  )

  const Request = (
    <Collapse
      className={styles.container}
      accordion
      ghost
      defaultActiveKey={['0']}
      expandIconPosition='end'
      style={platform === 'request' ? { outline: '1px solid #7043ff' } : {}}
      activeKey={platform === 'request' ? activePanel : ''}
      onChange={(key) => {
        handlePanelChange(key, 'request')
        setPlatform('request')
        dispatch(SET_SELECTED_PLATFORM('request'))
      }}
      destroyInactivePanel={true}>
      <Panel
        header={
          <div className={`${styles.node}`} id={circleNumber.toLocaleString()}>
            <img src={request_campaign_icon} alt='' />
            Request a Template
          </div>
        }
        key='3'>
        <Form form={form} onFinish={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
          <Form.Item name='requestText' style={{ marginBottom: '20px' }} rules={[{ validator: validateRequestText }]}>
            <Input.TextArea
              onChange={handleTemplateMessage}
              placeholder='Write your request here'
              autoSize={{ minRows: 3, maxRows: 5 }}
              showCount={true}
              maxLength={300}
            />
          </Form.Item>
          <Form.Item style={{ textAlign: 'right', marginBottom: '0px' }}>
            <Button
              type='primary'
              htmlType='submit'
              onClick={handleSendTemplateRequest}
              style={{ marginLeft: '5px', width: '77.45px' }}
              disabled={requestTemplateMessage?.length < 10}>
              Send
            </Button>
          </Form.Item>
        </Form>
      </Panel>
    </Collapse>
  )

  const Linkedin = (
    <Collapse
      className={`${styles.container} ${styles.gray}`}
      // style={{ filter: 'grayscale(1) opacity(30%) ' }}
      style={platform === 'linkedin' ? { outline: '1px solid #7043ff' } : {}}
      accordion
      ghost
      defaultActiveKey={['0']}
      expandIconPosition='end'
      activeKey={platform === 'linkedin' ? activePanel : ''}
      destroyInactivePanel={true}
      onChange={(key) => {
        if (linkedinAddon) {
          handlePanelChange(key, 'linkedin')
          setPlatform('linkedin')
          dispatch(SET_SELECTED_PLATFORM('linkedin'))
          setTemplate(3)
          dispatch(SET_TEMPLATE(3))
        } else {
          message.warning('Your plan does not support LinkedIn Campaigns.')
        }
      }}
      // collapsible={!linkedinAddon ? 'disabled' : undefined}
    >
      <Panel
        header={
          <>
            {/* <div className={styles.comingSoon}>
              <img src={coming_soon_badge} alt='' />
            </div> */}

            <div className={`${styles.node}`} id={circleNumber.toLocaleString()}>
              <img src={linkedin_campaign_icon} alt='' />
              Linkedin Outreach Sequence
            </div>
          </>
        }
        key='4'>
        <div className={styles.slider}>
          <div className={styles.numberOfFollowups} style={{ marginBottom: '10px' }}>
            <span># of Followups</span>
            <span>1</span>
          </div>
          {/* <Slider
            onChange={onChange}
            tooltip={{ formatter: null }}
            min={0}
            max={1}
            step={0.1}
            value={1}
            // marks={marks}
          /> */}
        </div>
      </Panel>
    </Collapse>
  )

  return (
    <div className={styles.wizardBlock}>
      <span
        className={styles.circleNumber}
        // style={circleNumber !== 1 ? { filter: 'grayscale(1) opacity(30%) ' } : {}}
      >
        {circleNumber}
      </span>
      {label === 'send_tg_message' && BasicOutreachSequence}
      {label === 'enrich_hubspot' && CRMActionsSteps}
      {label === 'request' && Request}
      {label === 'linkedin' && Linkedin}
    </div>
  )
}

export default WizardBlock

// const render = (
//   <Collapse
//     className={styles.container}
//     style={{ filter: 'grayscale(1) opacity(30%) ' }}
//     accordion
//     ghost
//     defaultActiveKey={['1']}
//     expandIconPosition='end'
//     // collapsible='disabled'
//   >
//     <Panel
//       header={
//         <div className={`${styles.node}`} id={circleNumber.toLocaleString()}>
//           <img src={image} alt='' />
//           {text}
//         </div>
//       }
//       key='1'>
//       <div className={styles.slider}>
//         <div className={styles.numberOfFollowups}>
//           <span># of Followups</span>
//           <span>{inputValue}</span>
//         </div>
//         <Slider
//           onChange={onChange}
//           tooltip={{ formatter: null }}
//           min={0}
//           max={2}
//           step={1}
//           value={typeof inputValue === 'number' ? inputValue : 0}
//         />
//       </div>
//     </Panel>
//     <Panel
//       header={
//         <div className={`${styles.node}`} id={circleNumber.toLocaleString()}>
//           <img src={image} alt='' />
//           {text}
//         </div>
//       }
//       key='1'>
//       <div className={styles.body}>
//         <div className={styles.createCheckbox}>
//           <Checkbox />
//           <span style={{ marginLeft: '10px' }}>
//             Create a Deal
//             <br /> When Sent
//           </span>
//         </div>
//         <div className={styles.updateCheckbox}>
//           <Checkbox />
//           <span style={{ marginLeft: '10px' }}>
//             Update Deal
//             <br />
//             When Replied
//           </span>
//         </div>
//       </div>
//     </Panel>
//     <Panel
//       header={
//         <div className={`${styles.node}`} id={circleNumber.toLocaleString()}>
//           <img src={request_campaign_icon} alt='' />
//           Request a Template
//         </div>
//       }
//       key='1'>
//       <Form onFinish={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
//         <Form.Item name='requestText' style={{ marginBottom: '10px' }}>
//           <Input.TextArea placeholder='Write your request here' autoSize={{ minRows: 3, maxRows: 5 }} />
//         </Form.Item>
//         <Form.Item style={{ textAlign: 'right', marginBottom: '0px' }}>
//           <Button type='default' onClick={handleCancel}>
//             Cancel
//           </Button>
//           <Button type='primary' htmlType='submit' style={{ marginLeft: '5px', width: '77.45px' }}>
//             Send
//           </Button>
//         </Form.Item>
//       </Form>
//     </Panel>
//     <Panel
//       header={
//         <div className={`${styles.node}`} id={circleNumber.toLocaleString()}>
//           <img src={linkedin_campaign_icon} alt='' />
//           Linkedin Connection
//         </div>
//       }
//       key='1'
//     />
//   </Collapse>
// )
