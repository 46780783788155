import { Drawer, Select, Switch, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import closeIcon from 'common/assets/svg/close_icon.svg'
import hubspotLogo from 'common/assets/svg/hubspot_icon.svg'
import styles from './Chat.module.scss'
import {
  useGetAllContactHubspotQuery,
  useGetConvrtObjectsMapQuery,
  useGetHubspotUnifiedInboxQuery,
} from './state/api/Chat-API'

interface Contact {
  id: string
  lastname: string
  firstname: string
}

interface Props {
  open: boolean
  onClose: () => void
  onChangeSyncContact: (value: boolean) => Promise<void>
  onSelectContact: (value: string) => Promise<void>
  dialogId: string
}

export const HubspotSyncSider = (props: Props) => {
  const { open, onChangeSyncContact, onSelectContact, onClose, dialogId } = props
  const {
    refetch: refetchHubspotUnifiedSetting,
    data: hubspotUnifiedSettings,
    isFetching: fetchingUnifiedSetting,
  } = useGetHubspotUnifiedInboxQuery(dialogId, { refetchOnMountOrArgChange: true })
  const { data: hubspotContacts, isFetching: fetchingHubspotContacts } = useGetAllContactHubspotQuery({})

  const {
    data: convrtHubspotObjectMap,
    isFetching: fetchingConvrtObject,
    refetch: fetchConvrtObjectMap,
  } = useGetConvrtObjectsMapQuery(dialogId, {
    refetchOnMountOrArgChange: true,
  })

  const loading = fetchingHubspotContacts || fetchingUnifiedSetting || fetchingConvrtObject
  const dataOptions = hubspotContacts?.contacts?.map((item: Contact) => ({
    label: `${item.firstname} ${item.lastname}`,
    value: item.id,
  }))

  const filterOption: any = (inputValue: any, option: any) => {
    if (!option) return false

    return (
      option.label.toLowerCase().indexOf(inputValue.trim().toLowerCase()) >= 0 ||
      option.value.toLowerCase().indexOf(inputValue.trim().toLowerCase()) >= 0
    )
  }

  return (
    <Drawer
      className='hubspotSyncSider'
      title={
        <div className={styles.hubspotSyncSider__header}>
          <span>Recipient</span>
          <span>Manage Sync</span>
        </div>
      }
      onClose={onClose}
      open={open}
      closeIcon={<img style={{ position: 'absolute', left: '-50px', top: '24px' }} src={closeIcon} alt='close' />}>
      <div className={styles.hubspotSyncSider__body}>
        <div className={styles['hubspotSyncSider__body__header']}>
          <div className={styles['hubspotSyncSider__body__header__left']}>
            <img src={hubspotLogo} alt='hubspot-logo' />
            <span>Hubspot</span>
            {loading && (
              <Spin
                size='large'
                indicator={<LoadingOutlined style={{ fontSize: 18, marginLeft: '10px', color: '#7043ff' }} spin />}
              />
            )}
          </div>

          <Switch
            defaultChecked={false}
            checked={hubspotUnifiedSettings?.response?.is_sync}
            onChange={(v) =>
              onChangeSyncContact(v).then(() => {
                refetchHubspotUnifiedSetting()
                fetchConvrtObjectMap()
              })
            }
          />
        </div>

        <div className={styles['hubspotSyncSider__body__content']}>
          <span>Sync chat with Hubspot activity on contacts</span>
          {hubspotUnifiedSettings?.response?.is_sync && (
            <>
              <div className={styles['hubspotSyncSider__body__content__checkbox']}>
                <div className={styles['hubspotSyncSider__body__content__checkbox__text']}>
                  <p>
                    Sync this contact directly with Hubspot including all chats and communication by choosing the name
                    of the contact in Hubspot.
                  </p>
                </div>
              </div>

              <div className={styles['hubspotSyncSider__body__content__select']}>
                <p>Contact's Name - Hubspot</p>
                <Select
                  style={{ width: '100%' }}
                  placeholder='Select a contact'
                  onChange={(value) => {
                    onSelectContact(value).then(() => {
                      fetchConvrtObjectMap()
                    })
                  }}
                  disabled={loading}
                  options={dataOptions}
                  showSearch={true}
                  filterOption={filterOption}
                  value={convrtHubspotObjectMap?.response?.['hubspot_id']}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Drawer>
  )
}
