export const techStackTools = [
  {
    title: 'Decentralized Storage',
    key: 'techStack.Blockchain_decentralized_storage',
    children: [
      {
        title: <span className='font-bold'>Select All</span>,
        key: 'techStack.Blockchain_decentralized_storage.select',
      },
      { title: 'Sia', key: 'techStack.blockchain_decentralized_storage.sia' },
      { title: 'Skynet', key: 'techStack.blockchain_decentralized_storage.skynet' },
      { title: 'DxChain', key: 'techStack.blockchain_decentralized_storage.dxChain' },
      { title: 'Filecoin', key: 'techStack.blockchain_decentralized_storage.filecoin' },
      { title: 'MaidSafe', key: 'techStack.blockchain_decentralized_storage.maidSafe' },
      { title: 'Swarm', key: 'techStack.blockchain_decentralized_storage.swarm' },
      { title: 'Akash Network', key: 'techStack.blockchain_decentralized_storage.akashNetwork' },
      { title: 'Elastos', key: 'techStack.blockchain_decentralized_storage.elastos' },
      { title: 'Arweave', key: 'techStack.blockchain_decentralized_storage.arweave' },
      { title: 'DADI', key: 'techStack.blockchain_decentralized_storage.dADI' },
      { title: 'IPFS', key: 'techStack.blockchain_decentralized_storage.iPFS' },
      { title: 'Golem', key: 'techStack.blockchain_decentralized_storage.golem' },
      { title: 'Lambda', key: 'techStack.blockchain_decentralized_storage.lambda' },
      { title: 'Ocean Protocol', key: 'techStack.blockchain_decentralized_storage.oceanProtocol' },
      { title: 'Ceph', key: 'techStack.blockchain_decentralized_storage.ceph' },
      { title: 'Perkeep', key: 'techStack.blockchain_decentralized_storage.perkeep' },
      { title: 'Bluzelle', key: 'techStack.blockchain_decentralized_storage.bluzelle' },
      { title: 'Storj', key: 'techStack.blockchain_decentralized_storage.storj' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Web3 Development Tools',
    key: 'techStack.Blockchain_development_and_testing_tools',
    children: [
      {
        title: <span className='font-bold'>Select All</span>,
        key: 'techStack.Blockchain_development_and_testing_tools.select',
      },
      { title: 'Ethpm', key: 'techStack.blockchain_development_and_testing_tools.ethpm' },
      { title: 'Brownie', key: 'techStack.blockchain_development_and_testing_tools.brownie' },
      { title: 'Echidna', key: 'techStack.blockchain_development_and_testing_tools.echidna' },
      { title: 'Surya', key: 'techStack.blockchain_development_and_testing_tools.surya' },
      { title: 'Ethlint', key: 'techStack.blockchain_development_and_testing_tools.ethlint' },
      { title: 'Embark', key: 'techStack.blockchain_development_and_testing_tools.embark' },
      { title: 'Web3', key: 'techStack.blockchain_development_and_testing_tools.web3' },
      { title: 'Geth', key: 'techStack.blockchain_development_and_testing_tools.geth' },
      { title: 'Infura', key: 'techStack.blockchain_development_and_testing_tools.infura' },
      { title: 'Manticore', key: 'techStack.blockchain_development_and_testing_tools.manticore' },
      { title: 'Ether', key: 'techStack.blockchain_development_and_testing_tools.ether' },
      { title: 'Solium', key: 'techStack.blockchain_development_and_testing_tools.solium' },
      { title: 'Solhint', key: 'techStack.blockchain_development_and_testing_tools.solhint' },
      { title: 'ZeppelinOS', key: 'techStack.blockchain_development_and_testing_tools.zeppelinOS' },
      { title: 'Slither', key: 'techStack.blockchain_development_and_testing_tools.slither' },
      { title: 'IPFS', key: 'techStack.blockchain_development_and_testing_tools.iPFS' },
      { title: 'Parity', key: 'techStack.blockchain_development_and_testing_tools.parity' },
      { title: 'Waffle', key: 'techStack.blockchain_development_and_testing_tools.waffle' },
      { title: 'Securify', key: 'techStack.blockchain_development_and_testing_tools.securify' },
      { title: 'OpenZeppelin', key: 'techStack.blockchain_development_and_testing_tools.openZeppelin' },
      { title: 'MetaMask', key: 'techStack.blockchain_development_and_testing_tools.metaMask' },
      { title: 'Solc', key: 'techStack.blockchain_development_and_testing_tools.solc' },
      { title: 'Remix IDE', key: 'techStack.blockchain_development_and_testing_tools.remixIDE' },
      { title: 'DappHub', key: 'techStack.blockchain_development_and_testing_tools.dappHub' },
      { title: 'Ganache', key: 'techStack.blockchain_development_and_testing_tools.ganache' },
      { title: 'Truffle Suite', key: 'techStack.blockchain_development_and_testing_tools.truffleSuite' },
      { title: 'Nethereum', key: 'techStack.blockchain_development_and_testing_tools.nethereum' },
      { title: 'Scaffold-eth', key: 'techStack.blockchain_development_and_testing_tools.scaffold-Eth' },
      { title: 'Mythril', key: 'techStack.blockchain_development_and_testing_tools.mythril' },
      { title: 'Hardhat', key: 'techStack.blockchain_development_and_testing_tools.hardhat' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Security Audits',
    key: 'techStack.Blockchain_infra_audits',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Blockchain_infra_audits.select' },
      { title: 'Hacken', key: 'techStack.blockchain_infra_audits.hacken' },
      { title: 'Trail of Bits', key: 'techStack.blockchain_infra_audits.trailOfBits' },
      { title: 'SECBIT Labs', key: 'techStack.blockchain_infra_audits.sECBITLabs' },
      { title: '0xGuard', key: 'techStack.blockchain_infra_audits.xGuard' },
      { title: 'Beosin', key: 'techStack.blockchain_infra_audits.beosin' },
      { title: 'yAcademy', key: 'techStack.blockchain_infra_audits.yAcademy' },
      { title: 'Pessimistic', key: 'techStack.blockchain_infra_audits.pessimistic' },
      { title: 'Verichains', key: 'techStack.blockchain_infra_audits.verichains' },
      { title: 'Zellic', key: 'techStack.blockchain_infra_audits.zellic' },
      { title: 'CertiK', key: 'techStack.blockchain_infra_audits.certiK' },
      { title: 'PeckShield', key: 'techStack.blockchain_infra_audits.peckShield' },
      { title: 'Solidified', key: 'techStack.blockchain_infra_audits.solidified' },
      { title: 'SolidProof', key: 'techStack.blockchain_infra_audits.solidProof' },
      { title: 'HashEx', key: 'techStack.blockchain_infra_audits.hashEx' },
      { title: 'Quantstamp', key: 'techStack.blockchain_infra_audits.quantstamp' },
      { title: 'CredShields', key: 'techStack.blockchain_infra_audits.credShields' },
      { title: 'Zokyo', key: 'techStack.blockchain_infra_audits.zokyo' },
      { title: 'Coinspect', key: 'techStack.blockchain_infra_audits.coinspect' },
      { title: 'ChainSecurity', key: 'techStack.blockchain_infra_audits.chainSecurity' },
      { title: 'Tech Audit', key: 'techStack.blockchain_infra_audits.techAudit' },
      { title: 'BlockApex', key: 'techStack.blockchain_infra_audits.blockApex' },
      { title: 'OpenZeppelin', key: 'techStack.blockchain_infra_audits.openZeppelin' },
      { title: 'InterFi Network', key: 'techStack.blockchain_infra_audits.interFiNetwork' },
      { title: 'Sigma Prime', key: 'techStack.blockchain_infra_audits.sigmaPrime' },
      { title: 'TechRate', key: 'techStack.blockchain_infra_audits.techRate' },
      { title: 'HAECHI LABS', key: 'techStack.blockchain_infra_audits.hAECHILABS' },
      { title: 'MixBytes', key: 'techStack.blockchain_infra_audits.mixBytes' },
      { title: 'SmartDec', key: 'techStack.blockchain_infra_audits.smartDec' },
      { title: 'Chainsulting', key: 'techStack.blockchain_infra_audits.chainsulting' },
      { title: 'Halborn', key: 'techStack.blockchain_infra_audits.halborn' },
      { title: 'ConsenSys Diligence', key: 'techStack.blockchain_infra_audits.consenSysDiligence' },
      { title: 'Omniscia', key: 'techStack.blockchain_infra_audits.omniscia' },
      { title: 'Certik', key: 'techStack.blockchain_infra_audits.certik' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'DAO Technologies',
    key: 'techStack.Blockchain_infra_dao',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Blockchain_infra_dao.select' },
      { title: 'DAOstack', key: 'techStack.blockchain_infra_dao.dAOstack' },
      { title: 'Colony', key: 'techStack.blockchain_infra_dao.colony' },
      { title: 'MolochDAO', key: 'techStack.blockchain_infra_dao.molochDAO' },
      { title: 'Gnosis Safe Multisig', key: 'techStack.blockchain_infra_dao.gnosisSafeMultisig' },
      { title: 'DAOhaus', key: 'techStack.blockchain_infra_dao.dAOhaus' },
      { title: 'Aragon', key: 'techStack.blockchain_infra_dao.aragon' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Oracles',
    key: 'techStack.Blockchain_infra_oracles',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Blockchain_infra_oracles.select' },
      { title: 'DAOstack', key: 'techStack.blockchain_infra_oracles.dAOstack' },
      { title: 'Colony', key: 'techStack.blockchain_infra_oracles.colony' },
      { title: 'MolochDAO', key: 'techStack.blockchain_infra_oracles.molochDAO' },
      { title: 'Gnosis Safe Multisig', key: 'techStack.blockchain_infra_oracles.gnosisSafeMultisig' },
      { title: 'DAOhaus', key: 'techStack.blockchain_infra_oracles.dAOhaus' },
      { title: 'Aragon', key: 'techStack.blockchain_infra_oracles.aragon' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Web3 Programming Languages',
    key: 'techStack.Blockchain_infra_programming_languages',
    children: [
      {
        title: <span className='font-bold'>Select All</span>,
        key: 'techStack.Blockchain_infra_programming_languages.select',
      },
      { title: 'Python', key: 'techStack.blockchain_infra_programming_languages.python' },
      { title: 'TypeScript', key: 'techStack.blockchain_infra_programming_languages.typeScript' },
      { title: 'Java', key: 'techStack.blockchain_infra_programming_languages.java' },
      { title: 'Rust', key: 'techStack.blockchain_infra_programming_languages.rust' },
      { title: 'Go', key: 'techStack.blockchain_infra_programming_languages.go' },
      { title: 'Vyper', key: 'techStack.blockchain_infra_programming_languages.vyper' },
      { title: 'C++', key: 'techStack.blockchain_infra_programming_languages.c++' },
      { title: 'Ruby', key: 'techStack.blockchain_infra_programming_languages.ruby' },
      { title: 'JavaScript', key: 'techStack.blockchain_infra_programming_languages.javaScript' },
      { title: 'Solidity', key: 'techStack.blockchain_infra_programming_languages.solidity' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Blockchain Infra SDK',
    key: 'techStack.Blockchain_infra_sdks',
    children: [
      { title: <span className='font-bold'>{'Select All'}</span>, key: 'techStack.Blockchain_infra_sdks.select' },
      { title: 'Ripple SDK', key: 'techStack.blockchain_infra_sdks.rippleSDK' },
      { title: 'Foundry SDK', key: 'techStack.blockchain_infra_sdks.foundrySDK' },
      { title: 'QuickNode SDK', key: 'techStack.blockchain_infra_sdks.quickNodeSDK' },
      { title: 'Pinata SDK', key: 'techStack.blockchain_infra_sdks.pinataSDK' },
      { title: 'Fleek SDK', key: 'techStack.blockchain_infra_sdks.fleekSDK' },
      { title: 'NFT.Storage SDK', key: 'techStack.blockchain_infra_sdks.nftStorageSDK' },
      { title: 'Web3.Storage SDK', key: 'techStack.blockchain_infra_sdks.web3StorageSDK' },
      { title: 'Figment SDK', key: 'techStack.blockchain_infra_sdks.figmentSDK' },
      { title: 'Blockdaemon SDK', key: 'techStack.blockchain_infra_sdks.blockdaemonSDK' },
      { title: 'Binance Smart Chain SDK', key: 'techStack.blockchain_infra_sdks.binanceSmartChainSDK' },
      { title: 'Polygon SDK', key: 'techStack.blockchain_infra_sdks.polygonSDK' },
      { title: 'Alchemy SDK', key: 'techStack.blockchain_infra_sdks.alchemySDK' },
      { title: 'TheGraph SDK', key: 'techStack.blockchain_infra_sdks.theGraphSDK' },
      { title: 'Chainlink SDK', key: 'techStack.blockchain_infra_sdks.chainlinkSDK' },
      { title: 'Ethers SDK', key: 'techStack.blockchain_infra_sdks.ethersSDK' },
      { title: 'ThirdWeb SDK', key: 'techStack.blockchain_infra_sdks.thirdWebSDK' },
      { title: 'Defillama SDK', key: 'techStack.blockchain_infra_sdks.defillamaSDK' },
      { title: 'Hyperledger Fabric SDK', key: 'techStack.blockchain_infra_sdks.hyperledgerFabricSDK' },
      { title: 'Cords SDK', key: 'techStack.blockchain_infra_sdks.cordsSDK' },
      { title: 'Arkane SDK:', key: 'techStack.blockchain_infra_sdks.arkaneSDK:' },
      { title: 'IOTA SDK', key: 'techStack.blockchain_infra_sdks.iOTASDK' },
      { title: 'Near SDK', key: 'techStack.blockchain_infra_sdks.nearSDK' },
      { title: 'EOSIO SDK', key: 'techStack.blockchain_infra_sdks.eOSIOSDK' },
      { title: 'Nethereum', key: 'techStack.blockchain_infra_sdks.nethereum' },
      { title: 'Truffle Suite', key: 'techStack.blockchain_infra_sdks.truffleSuite' },
      { title: 'Web3 SDK', key: 'techStack.blockchain_infra_sdks.web3SDK' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Web3 Security Tools',
    key: 'techStack.Blockchain_infra_security',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Blockchain_infra_security.select' },
      { title: 'Stela Labs', key: 'techStack.blockchain_infra_security.stelaLabs' },
      { title: 'Hashlock', key: 'techStack.blockchain_infra_security.hashlock' },
      { title: 'Callisto Network', key: 'techStack.blockchain_infra_security.callistoNetwork' },
      { title: 'SlowMist', key: 'techStack.blockchain_infra_security.slowMist' },
      { title: 'Spearbit', key: 'techStack.blockchain_infra_security.spearbit' },
      { title: 'CertiK', key: 'techStack.blockchain_infra_security.certiK' },
      { title: 'Quantstamp', key: 'techStack.blockchain_infra_security.quantstamp' },
      { title: 'SmartDec', key: 'techStack.blockchain_infra_security.smartDec' },
      { title: 'Beosin', key: 'techStack.blockchain_infra_security.beosin' },
      { title: 'Fortitude', key: 'techStack.blockchain_infra_security.fortitude' },
      { title: 'Red4Sec', key: 'techStack.blockchain_infra_security.red4Sec' },
      { title: 'Chainalysis', key: 'techStack.blockchain_infra_security.chainalysis' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Blockchain Infra Standards',
    key: 'techStack.Blockchain_infra_standards',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Blockchain_infra_standards.select' },
      { title: 'Tezos Improvement Proposals', key: 'techStack.blockchain_infra_standards.tezosImprovementProposals' },
      { title: 'ERC-721', key: 'techStack.blockchain_infra_standards.eRC-721' },
      { title: 'Counterparty', key: 'techStack.blockchain_infra_standards.counterparty' },
      {
        title: 'Cardano Improvement Proposals',
        key: 'techStack.blockchain_infra_standards.cardanoImprovementProposals',
      },
      { title: 'NFT Standards', key: 'techStack.blockchain_infra_standards.nFTStandards' },
      { title: 'NEP-5', key: 'techStack.blockchain_infra_standards.nEP-5' },
      { title: 'EOSIO', key: 'techStack.blockchain_infra_standards.eOSIO' },
      { title: 'Hyperledger Fabric', key: 'techStack.blockchain_infra_standards.hyperledgerFabric' },
      { title: 'ERC-3156', key: 'techStack.blockchain_infra_standards.eRC-3156' },
      { title: 'ERC-2612', key: 'techStack.blockchain_infra_standards.eRC-2612' },
      { title: 'Colored Coins', key: 'techStack.blockchain_infra_standards.coloredCoins' },
      { title: 'TRC-20', key: 'techStack.blockchain_infra_standards.tRC-20' },
      { title: 'ERC-4337', key: 'techStack.blockchain_infra_standards.eRC-4337' },
      {
        title: 'Bitcoin Improvement Proposals',
        key: 'techStack.blockchain_infra_standards.bitcoinImprovementProposals',
      },
      { title: 'ERC-1155', key: 'techStack.blockchain_infra_standards.eRC-1155' },
      { title: 'Hedera Token Service', key: 'techStack.blockchain_infra_standards.hederaTokenService' },
      { title: 'Omni Layer', key: 'techStack.blockchain_infra_standards.omniLayer' },
      { title: 'Interledger Protocol', key: 'techStack.blockchain_infra_standards.interledgerProtocol' },
      { title: 'Rootstock', key: 'techStack.blockchain_infra_standards.rootstock' },
      { title: 'AION', key: 'techStack.blockchain_infra_standards.aION' },
      { title: 'ERC-20', key: 'techStack.blockchain_infra_standards.eRC-20' },
      { title: 'WAVES', key: 'techStack.blockchain_infra_standards.wAVES' },
      { title: 'BEP-20', key: 'techStack.blockchain_infra_standards.bEP-20' },
      { title: 'Stellar Assets', key: 'techStack.blockchain_infra_standards.stellarAssets' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Blockchain Payment Providers',
    key: 'techStack.Blockchain_payment_providers',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Blockchain_payment_providers.select' },
      { title: 'Indacoin', key: 'techStack.blockchain_payment_providers.indacoin' },
      { title: 'Februar', key: 'techStack.blockchain_payment_providers.februar' },
      { title: 'BitPay', key: 'techStack.blockchain_payment_providers.bitPay' },
      { title: 'Coinbase Commerce', key: 'techStack.blockchain_payment_providers.coinbaseCommerce' },
      { title: 'SphereOne', key: 'techStack.blockchain_payment_providers.sphereOne' },
      { title: 'BTCPay Server', key: 'techStack.blockchain_payment_providers.bTCPayServer' },
      { title: 'Wert', key: 'techStack.blockchain_payment_providers.wert' },
      { title: 'Stripe Crypto Onramp', key: 'techStack.blockchain_payment_providers.stripeCryptoOnramp' },
      { title: 'Kado', key: 'techStack.blockchain_payment_providers.kado' },
      { title: 'MoonPay', key: 'techStack.blockchain_payment_providers.moonPay' },
      { title: 'RBX', key: 'techStack.blockchain_payment_providers.rBX' },
      { title: 'Simplex', key: 'techStack.blockchain_payment_providers.simplex' },
      { title: 'Ramp Network', key: 'techStack.blockchain_payment_providers.rampNetwork' },
      { title: 'Transak', key: 'techStack.blockchain_payment_providers.transak' },
      { title: 'Wyre', key: 'techStack.blockchain_payment_providers.wyre' },
      { title: 'Mirror World', key: 'techStack.blockchain_payment_providers.mirrorWorld' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Blockchain Data Providers',
    key: 'techStack.blockchain_data_providers',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Blockchain_rpc_providers.select' },
      { title: 'NodeFactory', key: 'techStack.blockchain_rpc_providers.nodeFactory' },
      { title: 'RPC-ETH', key: 'techStack.blockchain_rpc_providers.rPC-ETH' },
      { title: 'BeaconScan', key: 'techStack.blockchain_rpc_providers.beaconScan' },
      { title: 'Biconomy', key: 'techStack.blockchain_rpc_providers.biconomy' },
      { title: 'Infura', key: 'techStack.blockchain_rpc_providers.infura' },
      { title: 'Blockfrost', key: 'techStack.blockchain_rpc_providers.blockfrost' },
      { title: 'Alchemy', key: 'techStack.blockchain_rpc_providers.alchemy' },
      { title: 'Amberdata', key: 'techStack.blockchain_rpc_providers.amberdata' },
      { title: 'Pocket Network', key: 'techStack.blockchain_rpc_providers.pocketNetwork' },
      { title: 'Blockdaemon', key: 'techStack.blockchain_rpc_providers.blockdaemon' },
      { title: 'BlockCypher', key: 'techStack.blockchain_rpc_providers.blockCypher' },
      { title: 'MaticVigil', key: 'techStack.blockchain_rpc_providers.maticVigil' },
      { title: 'TheGraph SDK', key: 'techStack.blockchain_rpc_providers.theGraphSDK' },
      { title: 'Moralis', key: 'techStack.blockchain_rpc_providers.moralis' },
      { title: 'Chainstack', key: 'techStack.blockchain_rpc_providers.chainstack' },
      { title: 'QuickNode', key: 'techStack.blockchain_rpc_providers.quickNode' },
      { title: 'Bison Trails', key: 'techStack.blockchain_rpc_providers.bisonTrails' },
      { title: 'Anyblock Analytics', key: 'techStack.blockchain_rpc_providers.anyblockAnalytics' },
      { title: 'Ankr', key: 'techStack.blockchain_rpc_providers.ankr' },
      { title: 'Nansen SDK', key: 'techStack.blockchain_rpc_providers.nansenSDK' },
      { title: 'DEXTools', key: 'techStack.blockchain_rpc_providers.dextoolsSDK' },
      { title: 'Dune', key: 'techStack.blockchain_rpc_providers.duneSDK' },
      { title: 'Messari', key: 'techStack.blockchain_rpc_providers.messariSDK' },
      { title: 'NFTScan', key: 'techStack.blockchain_rpc_providers.nftscanSDK' },
      { title: 'Zash', key: 'techStack.blockchain_rpc_providers.zashSDK' },
      { title: 'n.xyz', key: 'techStack.blockchain_rpc_providers.nxyzSDK' },
      { title: 'SimpleHash', key: 'techStack.blockchain_rpc_providers.simplehashSDK' },
      { title: 'Covalenth', key: 'techStack.blockchain_rpc_providers.covalenthSDK' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Ad Servers',
    key: 'techStack.Ad servers',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Ad servers.select' },
      { title: 'Smart AdServer', key: 'techStack.adServers.smartAdServer' },
      { title: 'Adform', key: 'techStack.adServers.adform' },
      { title: 'Switch Concepts', key: 'techStack.adServers.switchConcepts' },
      { title: 'Weborama', key: 'techStack.adServers.weborama' },
      { title: 'Atlas by Facebook', key: 'techStack.adServers.atlasByFacebook' },
      { title: 'Sizmek (MediaMind)', key: 'techStack.adServers.sizmek' },
      { title: 'FreeWheel', key: 'techStack.adServers.freeWheel' },
      { title: 'DoubleClick', key: 'techStack.adServers.doubleClick' },
      { title: 'E-planning', key: 'techStack.adServers.ePlanning' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Ad Networks',
    key: 'techStack.Ad Networks',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Ad Networks.select' },
      { title: 'TribalFusion', key: 'techStack.adNetworks.tribalFusion' },
      { title: 'Marchex', key: 'techStack.adNetworks.marchex' },
      { title: 'Flashtalking', key: 'techStack.adNetworks.flashtalking' },
      { title: 'HasOffers', key: 'techStack.adNetworks.hasOffers' },
      { title: 'Integral Ad Science', key: 'techStack.adNetworks.integralAdScience' },
      { title: 'Flite', key: 'techStack.adNetworks.flite' },
      { title: 'Complex Media Network', key: 'techStack.adNetworks.complexMediaNetwork' },
      { title: 'Baidu Ads', key: 'techStack.adNetworks.baiduAds' },
      { title: 'Moat', key: 'techStack.adNetworks.moat' },
      { title: 'OwnerIQ', key: 'techStack.adNetworks.ownerIQ' },
      { title: 'Chitika', key: 'techStack.adNetworks.chitika' },
      { title: 'Linkedin Marketing Solutions', key: 'techStack.adNetworks.linkedinMarketingSolutions' },
      { title: 'Specific Media', key: 'techStack.adNetworks.specificMedia' },
      { title: 'Tradedoubler', key: 'techStack.adNetworks.tradedoubler' },
      { title: 'Advertising.com', key: 'techStack.adNetworks.advertisingCom' },
      { title: 'Adition Technologies - Advertisers', key: 'techStack.adNetworks.aditionTechnologiesAdvertisers' },
      { title: 'Kontera', key: 'techStack.adNetworks.kontera' },
      { title: 'BlogHer', key: 'techStack.adNetworks.blogHer' },
      { title: 'Google AdSense', key: 'techStack.adNetworks.googleAdSense' },
      { title: 'Centro', key: 'techStack.adNetworks.centro' },
      { title: 'DoubleClick Conversion', key: 'techStack.adNetworks.doubleClickConversion' },
      { title: 'SiteScout', key: 'techStack.adNetworks.siteScout' },
      { title: 'Infolinks', key: 'techStack.adNetworks.infolinks' },
      { title: 'TripleLift', key: 'techStack.adNetworks.tripleLift' },
      { title: 'Twitter Advertising', key: 'techStack.adNetworks.twitterAdvertising' },
      { title: 'CPMStar', key: 'techStack.adNetworks.cpmStar' },
      { title: 'Dstillery', key: 'techStack.adNetworks.dstillery' },
      { title: 'Right Media (Yahoo Ads)', key: 'techStack.adNetworks.rightMedia' },
      { title: 'Google AdWords Conversion', key: 'techStack.adNetworks.googleAdWordsConversion' },
      { title: 'Onscroll', key: 'techStack.adNetworks.onscroll' },
      { title: 'StackAdapt', key: 'techStack.adNetworks.stackAdapt' },
      { title: 'Bing Ads', key: 'techStack.adNetworks.bingAds' },
      { title: 'Google AdSense for Domains', key: 'techStack.adNetworks.googleAdSenseForDomains' },
      { title: 'CPX Interactive', key: 'techStack.adNetworks.cpxInteractive' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Affiliate Advertising Network',
    key: 'techStack.Affiliate advertising network',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Affiliate advertising network.select' },
      { title: 'Affiliate Window', key: 'techStack.affiliateAdvertisingNetwork.affiliateWindow' },
      { title: 'ShareASale', key: 'techStack.affiliateAdvertisingNetwork.shareASale' },
      { title: 'Linkshare', key: 'techStack.affiliateAdvertisingNetwork.linkshare' },
      { title: 'Webgains', key: 'techStack.affiliateAdvertisingNetwork.webgains' },
      { title: 'Impact Radius', key: 'techStack.affiliateAdvertisingNetwork.impactRadius' },
      { title: 'AvantLink', key: 'techStack.affiliateAdvertisingNetwork.avantLink' },
      { title: 'SkimLinks', key: 'techStack.affiliateAdvertisingNetwork.skimLinks' },
      { title: 'Amazon Associates', key: 'techStack.affiliateAdvertisingNetwork.amazonAssociates' },
      { title: 'VigLink', key: 'techStack.affiliateAdvertisingNetwork.vigLink' },
      { title: 'Zanox', key: 'techStack.affiliateAdvertisingNetwork.zanox' },
      { title: 'Deqwas', key: 'techStack.affiliateAdvertisingNetwork.deqwas' },
      { title: 'Commission Junction', key: 'techStack.affiliateAdvertisingNetwork.commissionJunction' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Analytics And Tracking',
    key: 'techStack.Analytics and tracking',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Analytics and tracking.select' },
      { title: 'Microsoft Application Insights', key: 'techStack.analyticsAndTracking.microsoftApplicationInsights' },
      { title: 'AT Internet', key: 'techStack.analyticsAndTracking.aTInternet' },
      { title: 'Gauges', key: 'techStack.analyticsAndTracking.gauges' },
      { title: 'Histats', key: 'techStack.analyticsAndTracking.histats' },
      { title: 'Hotjar', key: 'techStack.analyticsAndTracking.hotjar' },
      { title: 'Taboola Newsroom', key: 'techStack.analyticsAndTracking.taboolaNewsroom' },
      { title: 'Etracker', key: 'techStack.analyticsAndTracking.etracker' },
      { title: 'Gripp', key: 'techStack.analyticsAndTracking.gripp' },
      { title: 'Webtraxs', key: 'techStack.analyticsAndTracking.webtraxs' },
      { title: 'Walkme', key: 'techStack.analyticsAndTracking.walkme' },
      { title: 'StatCounter', key: 'techStack.analyticsAndTracking.statCounter' },
      { title: 'Mixpanel', key: 'techStack.analyticsAndTracking.mixpanel' },
      { title: 'Yandex Metrica', key: 'techStack.analyticsAndTracking.yandexMetrica' },
      { title: 'whos.amung.us', key: 'techStack.analyticsAndTracking.whos_Amung_Us' },
      { title: 'Demandware Analytics', key: 'techStack.analyticsAndTracking.demandwareAnalytics' },
      { title: 'SessionCam', key: 'techStack.analyticsAndTracking.sessionCam' },
      { title: 'comScore', key: 'techStack.analyticsAndTracking.comScore' },
      { title: 'AudioEye', key: 'techStack.analyticsAndTracking.audioEye' },
      { title: 'Leadfeeder', key: 'techStack.analyticsAndTracking.leadfeeder' },
      { title: 'Inspectlet', key: 'techStack.analyticsAndTracking.inspectlet' },
      { title: 'Amazon CloudWatch', key: 'techStack.analyticsAndTracking.amazonCloudWatch' },
      { title: 'UptimeRobot', key: 'techStack.analyticsAndTracking.uptimeRobot' },
      { title: 'Omniture (Adobe)', key: 'techStack.analyticsAndTracking.omniture(Adobe)' },
      { title: 'Microsoft Azure Monitor', key: 'techStack.analyticsAndTracking.microsoftAzureMonitor' },
      { title: 'MouseFlow', key: 'techStack.analyticsAndTracking.mouseFlow' },
      { title: 'DemandBase', key: 'techStack.analyticsAndTracking.demandBase' },
      { title: 'HeapAnalytics', key: 'techStack.analyticsAndTracking.heapAnalytics' },
      { title: 'Motigo', key: 'techStack.analyticsAndTracking.motigo' },
      { title: 'accessiBe', key: 'techStack.analyticsAndTracking.accessiBe' },
      { title: 'Segment.io', key: 'techStack.analyticsAndTracking.segment_Io' },
      { title: 'Keen.io', key: 'techStack.analyticsAndTracking.keen_Io' },
      { title: 'Lucky Orange', key: 'techStack.analyticsAndTracking.luckyOrange' },
      { title: 'Zoho Analytics', key: 'techStack.analyticsAndTracking.zohoAnalytics' },
      { title: 'Pipedrive', key: 'techStack.analyticsAndTracking.pipedrive' },
      { title: 'GoSquared', key: 'techStack.analyticsAndTracking.goSquared' },
      { title: 'Tapad', key: 'techStack.analyticsAndTracking.tapad' },
      { title: 'Grafana', key: 'techStack.analyticsAndTracking.grafana' },
      { title: 'Zift Solutions', key: 'techStack.analyticsAndTracking.ziftSolutions' },
      { title: 'Hitslink', key: 'techStack.analyticsAndTracking.hitslink' },
      { title: 'Woopra', key: 'techStack.analyticsAndTracking.woopra' },
      { title: 'Yahoo Analytics', key: 'techStack.analyticsAndTracking.yahooAnalytics' },
      { title: 'KISSmetrics', key: 'techStack.analyticsAndTracking.kISSmetrics' },
      { title: 'Facebook Conversion Tracking', key: 'techStack.analyticsAndTracking.facebookConversionTracking' },
      { title: 'Braze', key: 'techStack.analyticsAndTracking.braze' },
      { title: 'Drawbridge', key: 'techStack.analyticsAndTracking.drawbridge' },
      { title: 'ShinyStat', key: 'techStack.analyticsAndTracking.shinyStat' },
      { title: 'Pmetrics', key: 'techStack.analyticsAndTracking.pmetrics' },
      { title: 'W3Counter', key: 'techStack.analyticsAndTracking.w3Counter' },
      { title: 'CrazyEgg', key: 'techStack.analyticsAndTracking.crazyEgg' },
      { title: 'Piwik', key: 'techStack.analyticsAndTracking.piwik' },
      { title: 'Amplitude', key: 'techStack.analyticsAndTracking.amplitude' },
      { title: 'Clicky', key: 'techStack.analyticsAndTracking.clicky' },
      { title: 'eXTReMe Tracker', key: 'techStack.analyticsAndTracking.eXTReMeTracker' },
      { title: 'SalesLoft', key: 'techStack.analyticsAndTracking.salesLoft' },
      { title: 'SiteMeter', key: 'techStack.analyticsAndTracking.siteMeter' },
      { title: 'Webtrends', key: 'techStack.analyticsAndTracking.webtrends' },
      { title: 'Quantcast', key: 'techStack.analyticsAndTracking.quantcast' },
      { title: 'Nielsen NetRatings', key: 'techStack.analyticsAndTracking.nielsenNetRatings' },
      { title: 'Google Analytics', key: 'techStack.analyticsAndTracking.googleAnalytics' },
      { title: 'Chartbeat', key: 'techStack.analyticsAndTracking.chartbeat' },
      { title: 'Dynatrace', key: 'techStack.analytics.dynatrace' },
      { title: 'Branch', key: 'techStack.analytics.branch' },
      { title: 'Plotly', key: 'techStack.analytics.plotly' },
      { title: 'Google Conversion Linker', key: 'techStack.analytics.googleConversionLinker' },
      { title: 'Report URI', key: 'techStack.analytics.reportURI' },
      { title: 'Facebook Pixel for Shopify', key: 'techStack.analytics.facebookPixelForShopify' },
      { title: 'Facebook Domain Insights', key: 'techStack.analytics.facebookDomainInsights' },
      { title: 'Fathom Analytics', key: 'techStack.analytics.fathomAnalytics' },
      { title: 'Matomo', key: 'techStack.analytics.matomo' },
      { title: 'Raygun', key: 'techStack.analytics.raygun' },
      { title: 'PHP My Visits', key: 'techStack.analytics.pHPMyVisits' },
      { title: 'Fireblade', key: 'techStack.analytics.fireblade' },
      { title: 'Datadog', key: 'techStack.analytics.datadog' },
      { title: 'Bing Universal Event Tracking', key: 'techStack.analytics.bingUniversalEventTracking' },
      { title: 'Cloudflare Web Analytics', key: 'techStack.analytics.cloudflareWebAnalytics' },
      { title: 'Twitter Analytics', key: 'techStack.analytics.twitterAnalytics' },
      { title: 'Probely', key: 'techStack.analytics.probely' },
      { title: 'Baidu Analytics', key: 'techStack.analytics.baiduAnalytics' },
      { title: 'Snowplow', key: 'techStack.analytics.snowplow' },
      { title: 'loader.io', key: 'techStack.analytics.loader_Io' },
      { title: 'Salesforce', key: 'techStack.analytics.salesforce' },
      { title: 'Google Optimize 360', key: 'techStack.analytics.googleOptimize360' },
      { title: 'COCOLOG Nifty', key: 'techStack.analytics.cOCOLOGNifty' },
      { title: 'ConvertKit', key: 'techStack.analytics.convertKit' },
      { title: 'Salesforce Web to Lead', key: 'techStack.analytics.salesforceWebToLead' },
      { title: 'Infoline', key: 'techStack.analytics.infoline' },
      { title: 'Popmechanic', key: 'techStack.analytics.popmechanic' },
      { title: 'RD Station', key: 'techStack.analytics.rDStation' },
      { title: 'SendPulse', key: 'techStack.analytics.sendPulse' },
      { title: 'Alexa Metrics', key: 'techStack.analytics.alexaMetrics' },
      { title: 'HubSpot Analytics', key: 'techStack.analytics.hubSpotAnalytics' },
      { title: 'BizSpring', key: 'techStack.analytics.bizSpring' },
      { title: 'Perfex', key: 'techStack.analytics.perfex' },
      { title: 'Naver Analytics', key: 'techStack.analytics.naverAnalytics' },
      { title: 'TrackDuck', key: 'techStack.analytics.trackDuck' },
      { title: 'Heap', key: 'techStack.analytics.heap' },
      { title: 'Fastly', key: 'techStack.analytics.fastly' },
      { title: 'Hubspot', key: 'techStack.analytics.hubspot' },
      { title: '51.la', key: 'techStack.analytics.51_La' },
      { title: 'Trustpilot', key: 'techStack.analytics.trustpilot' },
      { title: 'Adobe Dynamic Tag Management', key: 'techStack.analytics.adobeDynamicTagManagement' },
      { title: 'LeadBoxer', key: 'techStack.analytics.leadBoxer' },
      { title: 'Freshsales', key: 'techStack.analytics.freshsales' },
      { title: 'Site24x7', key: 'techStack.analytics.site24x7' },
      { title: 'Smartlook', key: 'techStack.analytics.smartlook' },
      { title: 'Google Universal Analytics', key: 'techStack.analytics.googleUniversalAnalytics' },
      { title: 'Visual Website Optimizer', key: 'techStack.analytics.visualWebsiteOptimizer' },
      { title: 'Nocodelytics', key: 'techStack.analytics.nocodelytics' },
      { title: 'MailerLite', key: 'techStack.analytics.mailerLite' },
      { title: 'Sumo', key: 'techStack.analytics.sumo' },
      { title: 'Twitter Website Universal Tag', key: 'techStack.analytics.twitterWebsiteUniversalTag' },
      { title: 'Cloudflare Insights', key: 'techStack.analytics.cloudflareInsights' },
      { title: 'UserEcho', key: 'techStack.analytics.userEcho' },
      { title: 'Funnelll', key: 'techStack.analytics.funnelll' },
      { title: 'Baomitu', key: 'techStack.analytics.baomitu' },
      { title: 'KnowBe4', key: 'techStack.analytics.knowBe4' },
      { title: 'Twitter Conversion Tracking', key: 'techStack.analytics.twitterConversionTracking' },
      { title: 'Yandex Metrika', key: 'techStack.analytics.yandexMetrika' },
      { title: 'phpMyVisites', key: 'techStack.analytics.phpMyVisites' },
      { title: 'Outfunnel', key: 'techStack.analytics.outfunnel' },
      { title: 'Yahoo Dot', key: 'techStack.analytics.yahooDot' },
      { title: 'Facebook Signal', key: 'techStack.analytics.facebookSignal' },
      { title: 'Placester', key: 'techStack.analytics.placester' },
      { title: 'Clearbit', key: 'techStack.analytics.clearbit' },
      { title: 'Rapleaf', key: 'techStack.analytics.rapleaf' },
      { title: 'Checkly', key: 'techStack.analytics.checkly' },
      { title: 'Moat', key: 'techStack.analytics.moat' },
      { title: 'Optimizely', key: 'techStack.analytics.optimizely' },
      { title: 'CNZZ', key: 'techStack.analytics.cNZZ' },
      { title: 'Visitor Analytics', key: 'techStack.analytics.visitorAnalytics' },
      { title: 'LinkedIn Insights', key: 'techStack.analytics.linkedInInsights' },
      { title: 'Microsoft Clarity', key: 'techStack.analytics.microsoftClarity' },
      { title: 'Plausible Analytics', key: 'techStack.analytics.plausibleAnalytics' },
      { title: 'Hubspot Forms', key: 'techStack.analytics.hubspotForms' },
      { title: 'Active Campaign', key: 'techStack.analytics.activeCampaign' },
      { title: 'HubSpot Call to Actions', key: 'techStack.analytics.hubSpotCallToActions' },
      { title: 'Honeybadger', key: 'techStack.analytics.honeybadger' },
      { title: 'Google AdWords Conversion', key: 'techStack.analytics.googleAdWordsConversion' },
      { title: 'Alexa Certified Site Metrics', key: 'techStack.analytics.alexaCertifiedSiteMetrics' },
      { title: 'Mouseflow', key: 'techStack.analytics.mouseflow' },
      { title: 'Cloudflare Rocket Loader', key: 'techStack.analytics.cloudflareRocketLoader' },
      { title: 'GrowSurf', key: 'techStack.analytics.growSurf' },
      { title: 'RudderStack', key: 'techStack.analytics.rudderStack' },
      { title: 'PostHog', key: 'techStack.analytics.postHog' },
      { title: 'Zoho Desk', key: 'techStack.analytics.zohoDesk' },
      { title: 'New Relic', key: 'techStack.analytics.newRelic' },
      { title: 'Cedexis', key: 'techStack.analytics.cedexis' },
      { title: 'Global Site Tag', key: 'techStack.analytics.globalSiteTag' },
      { title: 'ClickMagick Marketing', key: 'techStack.analytics.clickMagickMarketing' },
      { title: 'Facebook Pixel', key: 'techStack.analytics.facebookPixel' },
      { title: 'SiteWit', key: 'techStack.analytics.siteWit' },
      { title: 'Statping', key: 'techStack.analytics.statping' },
      { title: 'Google Content Experiments', key: 'techStack.analytics.googleContentExperiments' },
      { title: 'Financial Content', key: 'techStack.analytics.financialContent' },
      { title: 'Dynatrace RUM', key: 'techStack.analytics.dynatraceRUM' },
      { title: 'Zipy', key: 'techStack.analytics.zipy' },
      { title: 'Shopify Tracking', key: 'techStack.analytics.shopifyTracking' },
      { title: 'Zoho PageSense', key: 'techStack.analytics.zohoPageSense' },
      { title: 'FullStory', key: 'techStack.analytics.fullStory' },
      { title: 'Klaviyo', key: 'techStack.analytics.klaviyo' },
      { title: 'Agora', key: 'techStack.analytics.agora' },
      { title: 'Segment', key: 'techStack.analytics.segment' },
      { title: 'Google Conversion Tracking', key: 'techStack.analytics.googleConversionTracking' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Appointment Scheduling',
    key: 'techStack.Appointment scheduling',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Appointment scheduling.select' },
      { title: 'Vagaro', key: 'techStack.appointmentScheduling.vagaro' },
      { title: 'ZocDoc', key: 'techStack.appointmentScheduling.zocDoc' },
      { title: 'Zen Planner', key: 'techStack.appointmentScheduling.zenPlanner' },
      { title: 'TimeTrade', key: 'techStack.appointmentScheduling.timeTrade' },
      { title: 'YouCanBook.me', key: 'techStack.appointmentScheduling.youCanBook_Me' },
      { title: 'AppointmentPlus', key: 'techStack.appointmentScheduling.appointmentPlus' },
      { title: 'Schedulicity', key: 'techStack.appointmentScheduling.schedulicity' },
      { title: 'Mindbody', key: 'techStack.appointmentScheduling.mindbody' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'CMS',
    key: 'techStack.Cms',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Cms.select' },
      { title: 'Mobirise', key: 'techStack.cms.mobirise' },
      { title: 'Strikingly', key: 'techStack.cms.strikingly' },
      { title: 'Zoho Sites', key: 'techStack.cms.zohoSites' },
      { title: 'Unbounce', key: 'techStack.cms.unbounce' },
      { title: 'WordPress 6.0', key: 'techStack.cms.wordPress6_' },
      { title: 'Adxstudio', key: 'techStack.cms.adxstudio' },
      { title: 'WPTouch Pro', key: 'techStack.cms.wPTouchPro' },
      { title: 'WordPress 5.8', key: 'techStack.cms.wordPress5_8' },
      { title: 'Flarum', key: 'techStack.cms.flarum' },
      { title: 'GraphCMS', key: 'techStack.cms.graphCMS' },
      { title: 'Asana', key: 'techStack.cms.asana' },
      { title: 'Discourse', key: 'techStack.cms.discourse' },
      { title: 'Adalo', key: 'techStack.cms.adalo' },
      { title: 'WordPress 5.6', key: 'techStack.cms.wordPress5_6' },
      { title: 'Weebly', key: 'techStack.cms.weebly' },
      { title: 'Atlassian Cloud', key: 'techStack.cms.atlassianCloud' },
      { title: 'Nextcloud', key: 'techStack.cms.nextcloud' },
      { title: 'GitBook', key: 'techStack.cms.gitBook' },
      { title: 'Wordpress Staging', key: 'techStack.cms.wordpressStaging' },
      { title: 'Medium', key: 'techStack.cms.medium' },
      { title: 'Beaver Builder', key: 'techStack.cms.beaverBuilder' },
      { title: 'Web.com', key: 'techStack.cms.web_Com' },
      { title: 'Netlify', key: 'techStack.cms.netlify' },
      { title: 'Super', key: 'techStack.cms.super' },
      { title: '1and1 Website Builder', key: 'techStack.cms.1and1WebsiteBuilder' },
      { title: 'Strapi', key: 'techStack.cms.strapi' },
      { title: 'K2', key: 'techStack.cms.k2' },
      { title: 'Circle', key: 'techStack.cms.circle' },
      { title: 'ActiveCollab', key: 'techStack.cms.activeCollab' },
      { title: 'Docusaurus', key: 'techStack.cms.docusaurus' },
      { title: 'Softr', key: 'techStack.cms.softr' },
      { title: 'KaTeX', key: 'techStack.cms.kaTeX' },
      { title: 'WordPress 5.4', key: 'techStack.cms.wordPress5_4' },
      { title: 'Ghost', key: 'techStack.cms.ghost' },
      { title: 'Submittable', key: 'techStack.cms.submittable' },
      { title: 'BookStack', key: 'techStack.cms.bookStack' },
      { title: 'WHMCS', key: 'techStack.cms.wHMCS' },
      { title: 'SMF', key: 'techStack.cms.sMF' },
      { title: 'HubSpot CMS Hub', key: 'techStack.cms.hubSpotCMSHub' },
      { title: 'WordPress 4.0', key: 'techStack.cms.wordPress4_' },
      { title: 'Wordpress 4.9', key: 'techStack.cms.wordpress4_9' },
      { title: 'Directus', key: 'techStack.cms.directus' },
      { title: 'Zendesk Guide', key: 'techStack.cms.zendeskGuide' },
      { title: 'WordPress 6.2', key: 'techStack.cms.wordPress6_2' },
      { title: 'Drupal', key: 'techStack.cms.drupal' },
      { title: 'Microsoft Power Apps', key: 'techStack.cms.microsoftPowerApps' },
      { title: 'WordPress 5.9', key: 'techStack.cms.wordPress5_9' },
      { title: 'Wordpress 5.1', key: 'techStack.cms.wordpress5_1' },
      { title: 'ReadMe', key: 'techStack.cms.readMe' },
      { title: 'Carrd', key: 'techStack.cms.carrd' },
      { title: 'Nextra', key: 'techStack.cms.nextra' },
      { title: 'Adobe Business Catalyst', key: 'techStack.cms.adobeBusinessCatalyst' },
      { title: 'SPIP', key: 'techStack.cms.sPIP' },
      { title: 'onpublix', key: 'techStack.cms.onpublix' },
      { title: 'Adobe Muse', key: 'techStack.cms.adobeMuse' },
      { title: 'Sketch', key: 'techStack.cms.sketch' },
      { title: 'JIRA', key: 'techStack.cms.jIRA' },
      { title: 'Google Sites', key: 'techStack.cms.googleSites' },
      { title: 'GetCourse', key: 'techStack.cms.getCourse' },
      { title: 'Adobe Portfolio', key: 'techStack.cms.adobePortfolio' },
      { title: 'Strato Sitebuilder', key: 'techStack.cms.stratoSitebuilder' },
      { title: 'Wix', key: 'techStack.cms.wix' },
      { title: 'Microsoft Word', key: 'techStack.cms.microsoftWord' },
      { title: '1and1 DIY Websites', key: 'techStack.cms.1and1DIYWebsites' },
      { title: 'Wix Hosted', key: 'techStack.cms.wixHosted' },
      { title: 'Framer Sites', key: 'techStack.cms.framerSites' },
      { title: 'ONLYOFFICE', key: 'techStack.cms.oNLYOFFICE' },
      { title: 'Blogger', key: 'techStack.cms.blogger' },
      { title: 'WordPress 5.3', key: 'techStack.cms.wordPress5_3' },
      { title: 'Mastodon', key: 'techStack.cms.mastodon' },
      { title: 'Joomla!', key: 'techStack.cms.joomla!' },
      { title: 'Wordpress 5.0', key: 'techStack.cms.wordpress5_' },
      { title: 'Read the Docs', key: 'techStack.cms.readTheDocs' },
      { title: 'Ghost 3.4*', key: 'techStack.cms.ghost3_4*' },
      { title: 'Sharefaith', key: 'techStack.cms.sharefaith' },
      { title: 'Wordpress 4.8', key: 'techStack.cms.wordpress4_8' },
      { title: 'Plesk', key: 'techStack.cms.plesk' },
      { title: 'Finsweet', key: 'techStack.cms.finsweet' },
      { title: 'Substack', key: 'techStack.cms.substack' },
      { title: 'Webflow', key: 'techStack.cms.webflow' },
      { title: 'MediaWiki', key: 'techStack.cms.mediaWiki' },
      { title: 'Just Another Wordpress Site', key: 'techStack.cms.justAnotherWordpressSite' },
      { title: 'DokuWiki', key: 'techStack.cms.dokuWiki' },
      { title: 'WordPress 6.1', key: 'techStack.cms.wordPress6_1' },
      { title: 'HubSpot COS', key: 'techStack.cms.hubSpotCOS' },
      { title: 'StatusPage IO', key: 'techStack.cms.statusPageIO' },
      { title: 'Invision Power Board', key: 'techStack.cms.invisionPowerBoard' },
      { title: 'Chorus', key: 'techStack.cms.chorus' },
      { title: 'Intercom Help', key: 'techStack.cms.intercomHelp' },
      { title: 'Postman', key: 'techStack.cms.postman' },
      { title: 'RapidWeaver', key: 'techStack.cms.rapidWeaver' },
      { title: 'WordPress 5.2', key: 'techStack.cms.wordPress5_2' },
      { title: 'Vanilla Forums', key: 'techStack.cms.vanillaForums' },
      { title: 'Progress Sitefinity', key: 'techStack.cms.progressSitefinity' },
      { title: 'Sanity', key: 'techStack.cms.sanity' },
      { title: 'MkDocs', key: 'techStack.cms.mkDocs' },
      { title: 'Google My Business', key: 'techStack.cms.googleMyBusiness' },
      { title: 'Moodle', key: 'techStack.cms.moodle' },
      { title: 'Discuz!', key: 'techStack.cms.discuz!' },
      { title: 'WordPress', key: 'techStack.cms.wordPress' },
      { title: 'Mailchimp Website Builder', key: 'techStack.cms.mailchimpWebsiteBuilder' },
      { title: 'Gridsome', key: 'techStack.cms.gridsome' },
      { title: 'Tilda', key: 'techStack.cms.tilda' },
      { title: 'Freshdesk Support', key: 'techStack.cms.freshdeskSupport' },
      { title: 'WordPress 5.5', key: 'techStack.cms.wordPress5_5' },
      { title: 'Wordpress 4.4', key: 'techStack.cms.wordpress4_4' },
      { title: 'Atlassian Confluence', key: 'techStack.cms.atlassianConfluence' },
      { title: 'JobScore', key: 'techStack.cms.jobScore' },
      { title: 'GoHire', key: 'techStack.cms.goHire' },
      { title: 'CM4All Website Creator', key: 'techStack.cms.cM4AllWebsiteCreator' },
      { title: 'CPanel', key: 'techStack.cms.cPanel' },
      { title: 'Ghost Pro', key: 'techStack.cms.ghostPro' },
      { title: 'Linktree', key: 'techStack.cms.linktree' },
      { title: 'WordPress 5.7', key: 'techStack.cms.wordPress5_7' },
      { title: 'Wordpress 4.7', key: 'techStack.cms.wordpress4_7' },
      { title: 'Greenhouse', key: 'techStack.cms.greenhouse' },
      { title: 'Apple Store Redirect', key: 'techStack.cms.appleStoreRedirect' },
      { title: 'Contentful', key: 'techStack.cms.contentful' },
      { title: 'Wordpress Private Site', key: 'techStack.cms.wordpressPrivateSite' },
      { title: 'Better Uptime', key: 'techStack.cms.betterUptime' },
      { title: 'Squarespace', key: 'techStack.cms.squarespace' },
      { title: 'Tumblr', key: 'techStack.cms.tumblr' },
      { title: 'Yii Framework', key: 'techStack.cms.yiiFramework' },
      { title: 'Slack', key: 'techStack.cms.slack' },
      { title: 'Instapage', key: 'techStack.cms.instapage' },
      { title: 'Jekyll', key: 'techStack.cms.jekyll' },
      { title: 'Wix Answers', key: 'techStack.cms.wixAnswers' },
      { title: 'GoDaddy Website Builder', key: 'techStack.cms.goDaddyWebsiteBuilder' },
      { title: 'October CMS', key: 'techStack.cms.octoberCMS' },
      { title: 'Google Search Appliance', key: 'techStack.cms.googleSearchAppliance' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'CSS And Javascript Libraries',
    key: 'techStack.Css and javascript libraries',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Css and javascript libraries.select' },
      { title: 'JQuery 2.1.1', key: 'techStack.cssAndJavascriptLibraries.jQuery2_1_1' },
      { title: 'Highcharts JS Library', key: 'techStack.cssAndJavascriptLibraries.highchartsJSLibrary' },
      { title: 'AWS SDK for JavaScript', key: 'techStack.cssAndJavascriptLibraries.aWSSDKForJavaScript' },
      { title: 'JQuery 1.11.1', key: 'techStack.cssAndJavascriptLibraries.jQuery1_11_1' },
      { title: 'Amcharts JS Library', key: 'techStack.cssAndJavascriptLibraries.amchartsJSLibrary' },
      { title: 'VueJS', key: 'techStack.cssAndJavascriptLibraries.vueJS' },
      { title: 'Bootstrap Framework', key: 'techStack.cssAndJavascriptLibraries.bootstrapFramework' },
      { title: 'SignalR', key: 'techStack.cssAndJavascriptLibraries.signalR' },
      { title: 'React Router', key: 'techStack.cssAndJavascriptLibraries.reactRouter' },
      { title: 'Backbone JS Library', key: 'techStack.cssAndJavascriptLibraries.backboneJSLibrary' },
      { title: 'Angular JS v1', key: 'techStack.cssAndJavascriptLibraries.angularJSV1' },
      { title: 'Fusioncharts JS Library', key: 'techStack.cssAndJavascriptLibraries.fusionchartsJSLibrary' },
      { title: 'React', key: 'techStack.cssAndJavascriptLibraries.react' },
      { title: 'React Redux', key: 'techStack.cssAndJavascriptLibraries.reactRedux' },
      { title: 'Ember JS Library', key: 'techStack.cssAndJavascriptLibraries.emberJSLibrary' },
      { title: 'Bootstrap Framework v3.2.0', key: 'techStack.cssAndJavascriptLibraries.bootstrapFrameworkV3_2_0' },
      { title: 'Bootstrap Framework v3.1.1', key: 'techStack.cssAndJavascriptLibraries.bootstrapFrameworkV3_1_1' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Call Tracking',
    key: 'techStack.Call tracking',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Call tracking.select' },
      { title: 'CallRail', key: 'techStack.callTracking.callRail' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Captcha',
    key: 'techStack.Captcha',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Captcha.select' },
      { title: 'reCAPTCHA', key: 'techStack.captcha.reCAPTCHA' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Chats',
    key: 'techStack.Chats',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Chats.select' },
      { title: 'LivePerson Monitor', key: 'techStack.chats.livePersonMonitor' },
      { title: 'UserLike', key: 'techStack.chats.userLike' },
      { title: 'Pure Chat', key: 'techStack.chats.pureChat' },
      { title: 'SnapEngage', key: 'techStack.chats.snapEngage' },
      { title: 'JivoSite', key: 'techStack.chats.jivoSite' },
      { title: 'Comm100', key: 'techStack.chats.comm100' },
      { title: 'Cbox', key: 'techStack.chats.cbox' },
      { title: 'Zopim', key: 'techStack.chats.zopim' },
      { title: 'Smartsupp', key: 'techStack.chats.smartsupp' },
      { title: 'LiveChat', key: 'techStack.chats.liveChat' },
      { title: 'WhosOn', key: 'techStack.chats.whosOn' },
      { title: 'ProvideSupport', key: 'techStack.chats.provideSupport' },
      { title: 'Salesforce Live Agent', key: 'techStack.chats.salesforceLiveAgent' },
      { title: 'BoldChat', key: 'techStack.chats.boldChat' },
      { title: 'Olark', key: 'techStack.chats.olark' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Cloud Services',
    key: 'techStack.Cloud services',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Cloud services.select' },
      { title: 'Cloudinary', key: 'techStack.cloudServices.cloudinary' },
      { title: 'Dropbox', key: 'techStack.cloudServices.dropbox' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Comments',
    key: 'techStack.Comments',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Comments.select' },
      { title: 'Facebook Comments', key: 'techStack.comments.facebookComments' },
      { title: 'CommentLuv', key: 'techStack.comments.commentLuv' },
      { title: 'Intense Debate', key: 'techStack.comments.intenseDebate' },
      { title: 'Disqus', key: 'techStack.comments.disqus' },
      { title: 'LiveFyre', key: 'techStack.comments.liveFyre' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Content Syndication Networks',
    key: 'techStack.Content syndication networks',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Content syndication networks.select' },
      { title: 'Zemanta', key: 'techStack.contentSyndicationNetworks.zemanta' },
      { title: 'Gravity', key: 'techStack.contentSyndicationNetworks.gravity' },
      { title: 'AdBlade Advertisement', key: 'techStack.contentSyndicationNetworks.adBladeAdvertisement' },
      { title: 'Outbrain', key: 'techStack.contentSyndicationNetworks.outbrain' },
      { title: 'SimpleReach', key: 'techStack.contentSyndicationNetworks.simpleReach' },
      { title: 'Plista', key: 'techStack.contentSyndicationNetworks.plista' },
      { title: 'Taboola', key: 'techStack.contentSyndicationNetworks.taboola' },
      { title: 'Zergnet', key: 'techStack.contentSyndicationNetworks.zergnet' },
      { title: 'Content.ad', key: 'techStack.contentSyndicationNetworks.content_Ad' },
      { title: 'Dianomi', key: 'techStack.contentSyndicationNetworks.dianomi' },
      {
        title: 'Shareaholic Content Amplification',
        key: 'techStack.contentSyndicationNetworks.shareaholicContentAmplification',
      },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Customer Relationship Management',
    key: 'techStack.Customer relationship management',
    children: [
      {
        title: <span className='font-bold'>Select All</span>,
        key: 'techStack.Customer relationship management.select',
      },
      { title: 'Ontraport', key: 'techStack.customerRelationshipManagement.ontraport' },
      { title: 'Avectra', key: 'techStack.customerRelationshipManagement.avectra' },
      { title: 'InfusionSoft', key: 'techStack.customerRelationshipManagement.infusionSoft' },
      { title: 'Insightly', key: 'techStack.customerRelationshipManagement.insightly' },
      { title: 'Lithium Technologies', key: 'techStack.customerRelationshipManagement.lithiumTechnologies' },
      { title: 'AthenaHealth', key: 'techStack.customerRelationshipManagement.athenaHealth' },
      { title: 'Zoho CRM', key: 'techStack.customerRelationshipManagement.zohoCRM' },
      { title: 'Salesforce', key: 'techStack.customerRelationshipManagement.salesforce' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Customer Reviews',
    key: 'techStack.Customer reviews',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Customer reviews.select' },
      { title: 'Trustpilot', key: 'techStack.customerReviews.trustpilot' },
      { title: 'Bazaarvoice', key: 'techStack.customerReviews.bazaarvoice' },
      { title: 'PriceGrabber', key: 'techStack.customerReviews.priceGrabber' },
      { title: 'Shopper Approved', key: 'techStack.customerReviews.shopperApproved' },
      { title: 'Reviews.co.uk', key: 'techStack.customerReviews.reviews_Co_Uk' },
      { title: 'ReviewCentre', key: 'techStack.customerReviews.reviewCentre' },
      { title: 'ResellerRatings', key: 'techStack.customerReviews.resellerRatings' },
      { title: 'Ekomi', key: 'techStack.customerReviews.ekomi' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Data Management Platform',
    key: 'techStack.Data management platform',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Data management platform.select' },
      { title: 'TailTarget', key: 'techStack.dataManagementPlatform.tailTarget' },
      {
        title: 'Nielsen Display Ads (Formerly eXelate)',
        key: 'techStack.dataManagementPlatform.nielsenDisplayAds(FormerlyEXelate)',
      },
      { title: 'Lotame', key: 'techStack.dataManagementPlatform.lotame' },
      { title: 'Krux', key: 'techStack.dataManagementPlatform.krux' },
      { title: 'Bluekai', key: 'techStack.dataManagementPlatform.bluekai' },
      { title: 'LiveRamp', key: 'techStack.dataManagementPlatform.liveRamp' },
      { title: 'Navegg', key: 'techStack.dataManagementPlatform.navegg' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Demand Side Platforms',
    key: 'techStack.Demand side platforms',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Demand side platforms.select' },
      { title: 'AppNexus', key: 'techStack.demandSidePlatforms.appNexus' },
      { title: 'Simpli.fi', key: 'techStack.demandSidePlatforms.simpli_Fi' },
      { title: 'Yahoo Ad Manager Plus', key: 'techStack.demandSidePlatforms.yahooAdManagerPlus' },
      { title: 'The Trade Desk', key: 'techStack.demandSidePlatforms.theTradeDesk' },
      { title: 'MediaMath', key: 'techStack.demandSidePlatforms.mediaMath' },
      { title: 'Turn', key: 'techStack.demandSidePlatforms.turn' },
      { title: 'DataXu', key: 'techStack.demandSidePlatforms.dataXu' },
      { title: 'RocketFuel', key: 'techStack.demandSidePlatforms.rocketFuel' },
      { title: 'FreakOut', key: 'techStack.demandSidePlatforms.freakOut' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Domain Name Services',
    key: 'techStack.Domain name services',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Domain name services.select' },
      { title: 'Enom', key: 'techStack.domainNameServices.enom' },
      { title: 'InMotion Hosting DNS', key: 'techStack.domainNameServices.inMotionHostingDNS' },
      { title: 'Digital Ocean DNS', key: 'techStack.domainNameServices.digitalOceanDNS' },
      { title: 'Hostgator DNS', key: 'techStack.domainNameServices.hostgatorDNS' },
      { title: 'pointHQ', key: 'techStack.domainNameServices.pointHQ' },
      { title: 'A2 Hosting DNS', key: 'techStack.domainNameServices.a2HostingDNS' },
      { title: 'Binero', key: 'techStack.domainNameServices.binero' },
      { title: 'DNSPod', key: 'techStack.domainNameServices.dNSPod' },
      { title: 'DNSimple', key: 'techStack.domainNameServices.dNSimple' },
      { title: 'Media Temple DNS', key: 'techStack.domainNameServices.mediaTempleDNS' },
      { title: 'Route 53', key: 'techStack.domainNameServices.route53' },
      { title: 'Melbourne IT', key: 'techStack.domainNameServices.melbourneIT' },
      { title: 'DNS.com', key: 'techStack.domainNameServices.dNS_Com' },
      { title: 'iPage DNS', key: 'techStack.domainNameServices.iPageDNS' },
      { title: 'Campaign Monitor', key: 'techStack.domainNameServices.campaignMonitor' },
      { title: 'WorldwideDNS', key: 'techStack.domainNameServices.worldwideDNS' },
      { title: 'CSC Corporate Domains', key: 'techStack.domainNameServices.cSCCorporateDomains' },
      { title: 'DNS Made Easy', key: 'techStack.domainNameServices.dNSMadeEasy' },
      { title: 'EasyDNS', key: 'techStack.domainNameServices.easyDNS' },
      { title: 'Cloudflare DNS', key: 'techStack.domainNameServices.cloudflareDNS' },
      { title: 'NSOne', key: 'techStack.domainNameServices.nSOne' },
      { title: 'Netriplex', key: 'techStack.domainNameServices.netriplex' },
      { title: '123-reg DNS', key: 'techStack.domainNameServices.123-RegDNS' },
      { title: 'Akamai DNS', key: 'techStack.domainNameServices.akamaiDNS' },
      { title: 'Return Path', key: 'techStack.domainNameServices.returnPath' },
      { title: 'SiteGround DNS', key: 'techStack.domainNameServices.siteGroundDNS' },
      { title: 'Dyn Managed DNS', key: 'techStack.domainNameServices.dynManagedDNS' },
      { title: 'Verisign', key: 'techStack.domainNameServices.verisign' },
      { title: 'RackSpace DNS', key: 'techStack.domainNameServices.rackSpaceDNS' },
      { title: 'UltraDns', key: 'techStack.domainNameServices.ultraDns' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'E-Commerce Platforms',
    key: 'techStack.E-commerce platforms',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.E-commerce platforms.select' },
      { title: 'WP eCommerce', key: 'techStack.e-CommercePlatforms.wPECommerce' },
      { title: 'Thinkific', key: 'techStack.e-CommercePlatforms.thinkific' },
      { title: 'osCommerce', key: 'techStack.e-CommercePlatforms.osCommerce' },
      { title: 'Magento', key: 'techStack.e-CommercePlatforms.magento' },
      { title: 'OpenCart', key: 'techStack.e-CommercePlatforms.openCart' },
      { title: 'PrestaShop', key: 'techStack.e-CommercePlatforms.prestaShop' },
      { title: 'Shopify Plus', key: 'techStack.e-CommercePlatforms.shopifyPlus' },
      { title: 'UltraCart', key: 'techStack.e-CommercePlatforms.ultraCart' },
      { title: 'Intershop', key: 'techStack.e-CommercePlatforms.intershop' },
      { title: 'Demandware', key: 'techStack.e-CommercePlatforms.demandware' },
      { title: 'Magento 2 Community', key: 'techStack.e-CommercePlatforms.magento2Community' },
      { title: 'Visualsoft', key: 'techStack.e-CommercePlatforms.visualsoft' },
      { title: 'Virtuemart', key: 'techStack.e-CommercePlatforms.virtuemart' },
      { title: 'Woo Commerce', key: 'techStack.e-CommercePlatforms.wooCommerce' },
      { title: 'Epages', key: 'techStack.e-CommercePlatforms.epages' },
      { title: 'Woo Commerce Memberships', key: 'techStack.e-CommercePlatforms.wooCommerceMemberships' },
      { title: 'Squarespace ECommerce', key: 'techStack.e-CommercePlatforms.squarespaceECommerce' },
      { title: 'Spree', key: 'techStack.e-CommercePlatforms.spree' },
      { title: 'ATG Commerce', key: 'techStack.e-CommercePlatforms.aTGCommerce' },
      { title: 'BigCommerce', key: 'techStack.e-CommercePlatforms.bigCommerce' },
      { title: 'MyCommerce', key: 'techStack.e-CommercePlatforms.myCommerce' },
      { title: 'Yahoo Store', key: 'techStack.e-CommercePlatforms.yahooStore' },
      { title: 'IBM Websphere', key: 'techStack.e-CommercePlatforms.iBMWebsphere' },
      { title: 'BigCartel', key: 'techStack.e-CommercePlatforms.bigCartel' },
      { title: 'Shopify Product Reviews', key: 'techStack.e-CommercePlatforms.shopifyProductReviews' },
      { title: 'Salesforce Commerce Cloud', key: 'techStack.e-CommercePlatforms.salesforceCommerceCloud' },
      { title: 'Shopify', key: 'techStack.e-CommercePlatforms.shopify' },
      { title: 'Ecwid', key: 'techStack.e-CommercePlatforms.ecwid' },
      { title: 'Xt-commerce', key: 'techStack.e-CommercePlatforms.xt-Commerce' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'E-Commerce Tools',
    key: 'techStack.E-commerce tools',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.E-commerce tools.select' },
      { title: 'SociableLabs', key: 'techStack.e-CommerceTools.sociableLabs' },
      { title: 'Yotpo', key: 'techStack.e-CommerceTools.yotpo' },
      { title: 'Easy Digital Downloads', key: 'techStack.e-CommerceTools.easyDigitalDownloads' },
      { title: 'Scene7', key: 'techStack.e-CommerceTools.scene7' },
      { title: 'WebEngage', key: 'techStack.e-CommerceTools.webEngage' },
      { title: 'ChannelAdvisor', key: 'techStack.e-CommerceTools.channelAdvisor' },
      { title: 'Lexity', key: 'techStack.e-CommerceTools.lexity' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Education Management Software',
    key: 'techStack.Education management software',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Education management software.select' },
      { title: 'Echo 360', key: 'techStack.educationManagementSoftware.echo360' },
      { title: 'Blackboard LMS', key: 'techStack.educationManagementSoftware.blackboardLMS' },
      { title: 'Aspen', key: 'techStack.educationManagementSoftware.aspen' },
      { title: 'REnWeb', key: 'techStack.educationManagementSoftware.rEnWeb' },
      { title: 'MyBigCampus', key: 'techStack.educationManagementSoftware.myBigCampus' },
      { title: 'Rediker (Plus Portals)', key: 'techStack.educationManagementSoftware.rediker(PlusPortals)' },
      { title: 'ANGEL LMS', key: 'techStack.educationManagementSoftware.aNGELLMS' },
      { title: 'SunGard K12 eSchool Plus', key: 'techStack.educationManagementSoftware.sunGardK12ESchoolPlus' },
      { title: 'Schoology', key: 'techStack.educationManagementSoftware.schoology' },
      { title: 'Litmos', key: 'techStack.educationManagementSoftware.litmos' },
      { title: 'Moodle', key: 'techStack.educationManagementSoftware.moodle' },
      { title: 'CourseSites by Blackboard', key: 'techStack.educationManagementSoftware.courseSitesByBlackboard' },
      { title: 'Canvas by Instructure', key: 'techStack.educationManagementSoftware.canvasByInstructure' },
      { title: 'Desire2Learn / Brightspace', key: 'techStack.educationManagementSoftware.desire2Learn/Brightspace' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Email Delivery',
    key: 'techStack.Email delivery',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Email delivery.select' },
      { title: 'Postmark', key: 'techStack.emailDelivery.postmark' },
      { title: 'SparkPost', key: 'techStack.emailDelivery.sparkPost' },
      { title: 'TurboSMTP', key: 'techStack.emailDelivery.turboSMTP' },
      { title: 'Rackspace MailGun', key: 'techStack.emailDelivery.rackspaceMailGun' },
      { title: 'SMTP.com', key: 'techStack.emailDelivery.sMTP_Com' },
      { title: 'ElasticEmail', key: 'techStack.emailDelivery.elasticEmail' },
      { title: 'MailJet', key: 'techStack.emailDelivery.mailJet' },
      { title: 'Mailchimp Mandrill', key: 'techStack.emailDelivery.mailchimpMandrill' },
      { title: 'Amazon SES', key: 'techStack.emailDelivery.amazonSES' },
      { title: 'Sendgrid', key: 'techStack.emailDelivery.sendgrid' },
      { title: 'DynECT', key: 'techStack.emailDelivery.dynECT' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Email Marketing',
    key: 'techStack.Email marketing',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Email marketing.select' },
      { title: 'Emma', key: 'techStack.emailMarketing.emma' },
      { title: 'MailChimp', key: 'techStack.emailMarketing.mailChimp' },
      { title: 'CampaignMonitor', key: 'techStack.emailMarketing.campaignMonitor' },
      { title: 'Silverpop', key: 'techStack.emailMarketing.silverpop' },
      { title: 'Mad Mimi', key: 'techStack.emailMarketing.madMimi' },
      { title: 'AWeber', key: 'techStack.emailMarketing.aWeber' },
      { title: 'Customer.IO', key: 'techStack.emailMarketing.customer_IO' },
      { title: 'ExactTarget', key: 'techStack.emailMarketing.exactTarget' },
      { title: 'iContact', key: 'techStack.emailMarketing.iContact' },
      { title: 'MailUp', key: 'techStack.emailMarketing.mailUp' },
      { title: 'Klaviyo', key: 'techStack.emailMarketing.klaviyo' },
      { title: 'Adestra', key: 'techStack.emailMarketing.adestra' },
      { title: 'Communicator', key: 'techStack.emailMarketing.communicator' },
      { title: 'Constant Contact', key: 'techStack.emailMarketing.constantContact' },
      { title: 'SendInBlue', key: 'techStack.emailMarketing.sendInBlue' },
      { title: 'Bluehornet', key: 'techStack.emailMarketing.bluehornet' },
      { title: 'GetResponse', key: 'techStack.emailMarketing.getResponse' },
      { title: 'Vision6', key: 'techStack.emailMarketing.vision6' },
      { title: 'Maropost', key: 'techStack.emailMarketing.maropost' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Email Providers',
    key: 'techStack.Email providers',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Email providers.select' },
      { title: 'Roundcube Email', key: 'techStack.emailProviders.roundcubeEmail' },
      { title: 'Zimbra', key: 'techStack.emailProviders.zimbra' },
      { title: 'ServiceNow', key: 'techStack.emailProviders.serviceNow' },
      { title: 'Mimecast', key: 'techStack.emailProviders.mimecast' },
      { title: 'Outlook', key: 'techStack.emailProviders.outlook' },
      { title: '1&1 Email Provider', key: 'techStack.emailProviders.1&1EmailProvider' },
      { title: 'Hotmail', key: 'techStack.emailProviders.hotmail' },
      { title: 'Horde', key: 'techStack.emailProviders.horde' },
      { title: 'Gmail', key: 'techStack.emailProviders.gmail' },
      { title: 'DreamHost', key: 'techStack.emailProviders.dreamHost' },
      { title: 'Rackspace Email', key: 'techStack.emailProviders.rackspaceEmail' },
      { title: 'Zoho Email', key: 'techStack.emailProviders.zohoEmail' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Event Management',
    key: 'techStack.Event management',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Event management.select' },
      { title: 'ActiveNetwork', key: 'techStack.eventManagement.activeNetwork' },
      { title: 'Eventbrite', key: 'techStack.eventManagement.eventbrite' },
      { title: 'RegOnline', key: 'techStack.eventManagement.regOnline' },
      { title: 'etouches', key: 'techStack.eventManagement.etouches' },
      { title: 'Experient', key: 'techStack.eventManagement.experient' },
      { title: 'EventsAir by Centium', key: 'techStack.eventManagement.eventsAirByCentium' },
      { title: 'Cvent', key: 'techStack.eventManagement.cvent' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Fonts',
    key: 'techStack.Fonts',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Fonts.select' },
      { title: 'Google Font API', key: 'techStack.fonts.googleFontAPI' },
      { title: 'Typekit', key: 'techStack.fonts.typekit' },
      { title: 'FontDeck', key: 'techStack.fonts.fontDeck' },
      { title: 'Cufon', key: 'techStack.fonts.cufon' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Hosted Forms',
    key: 'techStack.Hosted forms',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Hosted forms.select' },
      { title: 'Formassembly', key: 'techStack.hostedForms.formassembly' },
      { title: 'Jotform', key: 'techStack.hostedForms.jotform' },
      { title: 'Formstack', key: 'techStack.hostedForms.formstack' },
      { title: 'Formsite', key: 'techStack.hostedForms.formsite' },
      { title: 'Wufoo', key: 'techStack.hostedForms.wufoo' },
      { title: 'Gravity Forms', key: 'techStack.hostedForms.gravityForms' },
      { title: 'Podio', key: 'techStack.hostedForms.podio' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Hosting',
    key: 'techStack.Hosting',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Hosting.select' },
      { title: 'NetRegistry', key: 'techStack.hosting.netRegistry' },
      { title: 'Akamai Hosted', key: 'techStack.hosting.akamaiHosted' },
      { title: 'Network Solutions', key: 'techStack.hosting.networkSolutions' },
      { title: 'Strato', key: 'techStack.hosting.strato' },
      { title: 'Steadfast', key: 'techStack.hosting.steadfast' },
      { title: 'Amazon Montreal Region', key: 'techStack.hosting.amazonMontrealRegion' },
      { title: 'Google Cloud', key: 'techStack.hosting.googleCloud' },
      { title: 'Netinternet', key: 'techStack.hosting.netinternet' },
      { title: 'Amazon ELB Mumbai', key: 'techStack.hosting.amazonELBMumbai' },
      { title: 'GoDaddy', key: 'techStack.hosting.goDaddy' },
      { title: 'Google Cloud Sydney', key: 'techStack.hosting.googleCloudSydney' },
      { title: 'Synergy Wholesale', key: 'techStack.hosting.synergyWholesale' },
      { title: '013 NetVision', key: 'techStack.hosting.13NetVision' },
      { title: 'Verizon', key: 'techStack.hosting.verizon' },
      { title: 'Discourse Hosted', key: 'techStack.hosting.discourseHosted' },
      { title: 'Cloudflare Network Error Logging', key: 'techStack.hosting.cloudflareNetworkErrorLogging' },
      { title: 'Zenlayer', key: 'techStack.hosting.zenlayer' },
      { title: 'IO Flood', key: 'techStack.hosting.iOFlood' },
      { title: 'SingleHop', key: 'techStack.hosting.singleHop' },
      { title: 'OVH', key: 'techStack.hosting.oVH' },
      { title: 'Clever Cloud', key: 'techStack.hosting.cleverCloud' },
      { title: 'Videotron', key: 'techStack.hosting.videotron' },
      { title: 'Amazon London Region', key: 'techStack.hosting.amazonLondonRegion' },
      { title: 'Amazon San Paulo Region', key: 'techStack.hosting.amazonSanPauloRegion' },
      { title: 'A Small Orange', key: 'techStack.hosting.aSmallOrange' },
      { title: 'Amazon ELB Singapore', key: 'techStack.hosting.amazonELBSingapore' },
      { title: 'Amazon Stockholm Region', key: 'techStack.hosting.amazonStockholmRegion' },
      { title: 'China Telecom', key: 'techStack.hosting.chinaTelecom' },
      { title: 'Internet Names for Business', key: 'techStack.hosting.internetNamesForBusiness' },
      { title: 'VARITI', key: 'techStack.hosting.vARITI' },
      { title: 'Vultr', key: 'techStack.hosting.vultr' },
      { title: 'Selectel', key: 'techStack.hosting.selectel' },
      { title: 'TheGigabit', key: 'techStack.hosting.theGigabit' },
      { title: 'Amazon Virginia Region', key: 'techStack.hosting.amazonVirginiaRegion' },
      { title: 'Amazon Elastic Beanstalk', key: 'techStack.hosting.amazonElasticBeanstalk' },
      { title: 'Microsoft', key: 'techStack.hosting.microsoft' },
      { title: 'OLM', key: 'techStack.hosting.oLM' },
      { title: 'Soluciones Corporativas IP', key: 'techStack.hosting.solucionesCorporativasIP' },
      { title: 'China Unicom', key: 'techStack.hosting.chinaUnicom' },
      { title: 'Tencent Cloud', key: 'techStack.hosting.tencentCloud' },
      { title: 'Hostpoint', key: 'techStack.hosting.hostpoint' },
      { title: 'Amazon Oregon Region', key: 'techStack.hosting.amazonOregonRegion' },
      { title: 'HostMonster', key: 'techStack.hosting.hostMonster' },
      { title: 'Amazon Bahrain Region', key: 'techStack.hosting.amazonBahrainRegion' },
      { title: 'Amazon Frankfurt Region', key: 'techStack.hosting.amazonFrankfurtRegion' },
      { title: 'Hostkey', key: 'techStack.hosting.hostkey' },
      { title: 'Media Temple', key: 'techStack.hosting.mediaTemple' },
      { title: 'Amazon ELB Seoul', key: 'techStack.hosting.amazonELBSeoul' },
      { title: 'Codero', key: 'techStack.hosting.codero' },
      { title: 'Syptec', key: 'techStack.hosting.syptec' },
      { title: 'genesys informatica', key: 'techStack.hosting.genesysInformatica' },
      { title: 'InterNetX', key: 'techStack.hosting.interNetX' },
      { title: 'Forthnet', key: 'techStack.hosting.forthnet' },
      { title: 'Amazon ELB Tokyo', key: 'techStack.hosting.amazonELBTokyo' },
      { title: 'Hostway', key: 'techStack.hosting.hostway' },
      { title: 'domainFACTORY', key: 'techStack.hosting.domainFACTORY' },
      { title: 'Dreamscape Networks', key: 'techStack.hosting.dreamscapeNetworks' },
      { title: 'Energy Group Hosting', key: 'techStack.hosting.energyGroupHosting' },
      { title: 'GigeNET', key: 'techStack.hosting.gigeNET' },
      { title: 'Quang Trung', key: 'techStack.hosting.quangTrung' },
      { title: 'Amazon ELB Oregon', key: 'techStack.hosting.amazonELBOregon' },
      { title: 'Amazon ELB Paris', key: 'techStack.hosting.amazonELBParis' },
      { title: 'ATT', key: 'techStack.hosting.aTT' },
      { title: 'node4', key: 'techStack.hosting.node4' },
      { title: 'SK broadband', key: 'techStack.hosting.sKBroadband' },
      { title: 'ServerCental', key: 'techStack.hosting.serverCental' },
      { title: 'Infomaniak Network', key: 'techStack.hosting.infomaniakNetwork' },
      { title: 'SuperHosting', key: 'techStack.hosting.superHosting' },
      { title: 'Carrier Zone Host', key: 'techStack.hosting.carrierZoneHost' },
      { title: 'Amazon Singapore Region', key: 'techStack.hosting.amazonSingaporeRegion' },
      { title: 'Bizland', key: 'techStack.hosting.bizland' },
      { title: 'Public Domain Registry', key: 'techStack.hosting.publicDomainRegistry' },
      { title: 'VNPT', key: 'techStack.hosting.vNPT' },
      { title: 'Verizon FIOS', key: 'techStack.hosting.verizonFIOS' },
      { title: 'M247', key: 'techStack.hosting.m247' },
      { title: 'Hetzner', key: 'techStack.hosting.hetzner' },
      { title: 'Microsoft Azure Asia South East Region', key: 'techStack.hosting.microsoftAzureAsiaSouthEastRegion' },
      { title: 'ChinaNet', key: 'techStack.hosting.chinaNet' },
      { title: 'Google Cloud Singapore', key: 'techStack.hosting.googleCloudSingapore' },
      { title: 'Data Foundry', key: 'techStack.hosting.dataFoundry' },
      { title: 'Microsoft Azure Asia East Region', key: 'techStack.hosting.microsoftAzureAsiaEastRegion' },
      { title: 'Domain Not Resolving', key: 'techStack.hosting.domainNotResolving' },
      { title: 'Softlayer', key: 'techStack.hosting.softlayer' },
      { title: 'Rackspace', key: 'techStack.hosting.rackspace' },
      { title: 'Cogent Communications', key: 'techStack.hosting.cogentCommunications' },
      { title: 'Contabo', key: 'techStack.hosting.contabo' },
      { title: 'Vercel', key: 'techStack.hosting.vercel' },
      { title: 'Linode', key: 'techStack.hosting.linode' },
      { title: 'Amazon ELB Northern California', key: 'techStack.hosting.amazonELBNorthernCalifornia' },
      { title: 'Microsoft Azure US East2 Region', key: 'techStack.hosting.microsoftAzureUSEast2Region' },
      { title: 'Reg.ru Hosted', key: 'techStack.hosting.reg_RuHosted' },
      { title: 'Dingfeng Xinhui', key: 'techStack.hosting.dingfengXinhui' },
      { title: 'Microsoft Azure US East Region', key: 'techStack.hosting.microsoftAzureUSEastRegion' },
      { title: '1and1', key: 'techStack.hosting.1and1' },
      { title: 'Salesforce Hosted', key: 'techStack.hosting.salesforceHosted' },
      { title: 'Hawk Host', key: 'techStack.hosting.hawkHost' },
      { title: 'Amazon Ohio Region', key: 'techStack.hosting.amazonOhioRegion' },
      { title: 'Intuit', key: 'techStack.hosting.intuit' },
      { title: 'EdgeCast Networks Hosting', key: 'techStack.hosting.edgeCastNetworksHosting' },
      { title: 'Private Layer', key: 'techStack.hosting.privateLayer' },
      { title: 'OpenSRS', key: 'techStack.hosting.openSRS' },
      { title: 'Google Cloud Tokyo', key: 'techStack.hosting.googleCloudTokyo' },
      { title: 'Amazon Sydney Region', key: 'techStack.hosting.amazonSydneyRegion' },
      { title: 'IDC Frontier', key: 'techStack.hosting.iDCFrontier' },
      { title: 'Aerotek', key: 'techStack.hosting.aerotek' },
      { title: 'Duocast', key: 'techStack.hosting.duocast' },
      { title: 'TransIP Hosted', key: 'techStack.hosting.transIPHosted' },
      { title: 'Microsoft Azure Europe North Region', key: 'techStack.hosting.microsoftAzureEuropeNorthRegion' },
      { title: 'Digital Pacific', key: 'techStack.hosting.digitalPacific' },
      { title: 'Amazon', key: 'techStack.hosting.amazon' },
      { title: 'Amazon Elastic Load Balancing', key: 'techStack.hosting.amazonElasticLoadBalancing' },
      { title: 'Hostwinds', key: 'techStack.hosting.hostwinds' },
      { title: 'OJSC RTComm.RU', key: 'techStack.hosting.oJSCRTComm_RU' },
      { title: 'Google', key: 'techStack.hosting.google' },
      { title: 'WordPress Hosting', key: 'techStack.hosting.wordPressHosting' },
      { title: 'Serverius', key: 'techStack.hosting.serverius' },
      { title: 'Baidu Cloud', key: 'techStack.hosting.baiduCloud' },
      { title: 'CNNIC', key: 'techStack.hosting.cNNIC' },
      { title: 'Google Cloud Netherlands', key: 'techStack.hosting.googleCloudNetherlands' },
      { title: 'Microsoft Azure', key: 'techStack.hosting.microsoftAzure' },
      { title: 'DreamHost Hosting', key: 'techStack.hosting.dreamHostHosting' },
      { title: 'Telecom Argentina', key: 'techStack.hosting.telecomArgentina' },
      { title: 'Squarespace Hosted', key: 'techStack.hosting.squarespaceHosted' },
      { title: 'Belpak', key: 'techStack.hosting.belpak' },
      { title: 'Google Cloud London', key: 'techStack.hosting.googleCloudLondon' },
      { title: 'CenturyLink', key: 'techStack.hosting.centuryLink' },
      { title: 'LG U Plus', key: 'techStack.hosting.lGUPlus' },
      { title: 'Sedo Hosting', key: 'techStack.hosting.sedoHosting' },
      { title: 'InterServer', key: 'techStack.hosting.interServer' },
      { title: 'Host Europe', key: 'techStack.hosting.hostEurope' },
      { title: 'Korea Telecom', key: 'techStack.hosting.koreaTelecom' },
      { title: 'Heart Internet', key: 'techStack.hosting.heartInternet' },
      { title: 'Time Warner', key: 'techStack.hosting.timeWarner' },
      { title: 'EasyWP Hosting', key: 'techStack.hosting.easyWPHosting' },
      { title: 'pair Networks Hosting', key: 'techStack.hosting.pairNetworksHosting' },
      { title: 'Xserver', key: 'techStack.hosting.xserver' },
      { title: 'Namecheap Hosting', key: 'techStack.hosting.namecheapHosting' },
      { title: 'Savvis', key: 'techStack.hosting.savvis' },
      { title: 'Google Cloud Global Multi-Region', key: 'techStack.hosting.googleCloudGlobalMulti-Region' },
      { title: 'Huawei Cloud', key: 'techStack.hosting.huaweiCloud' },
      { title: 'Google Cloud North Virginia', key: 'techStack.hosting.googleCloudNorthVirginia' },
      { title: 'Quantil', key: 'techStack.hosting.quantil' },
      { title: 'Digital Ocean', key: 'techStack.hosting.digitalOcean' },
      { title: 'Mittwald', key: 'techStack.hosting.mittwald' },
      { title: 'Railway', key: 'techStack.hosting.railway' },
      { title: 'Microsoft Azure Europe West Region', key: 'techStack.hosting.microsoftAzureEuropeWestRegion' },
      { title: 'Shark Tech', key: 'techStack.hosting.sharkTech' },
      { title: 'Zerolag', key: 'techStack.hosting.zerolag' },
      { title: 'RU Center', key: 'techStack.hosting.rUCenter' },
      { title: 'HiNet', key: 'techStack.hosting.hiNet' },
      { title: 'A2 Hosting', key: 'techStack.hosting.a2Hosting' },
      { title: 'Endurance International', key: 'techStack.hosting.enduranceInternational' },
      { title: 'Logic Boxes', key: 'techStack.hosting.logicBoxes' },
      { title: 'Smileserv IDC', key: 'techStack.hosting.smileservIDC' },
      { title: 'Hosting Company', key: 'techStack.hosting.hostingCompany' },
      { title: 'Hostopia', key: 'techStack.hosting.hostopia' },
      { title: 'BlueHost', key: 'techStack.hosting.blueHost' },
      { title: 'Bodis', key: 'techStack.hosting.bodis' },
      { title: 'Google Cloud Iowa', key: 'techStack.hosting.googleCloudIowa' },
      { title: '1984', key: 'techStack.hosting.1984' },
      { title: 'Yahoo', key: 'techStack.hosting.yahoo' },
      { title: 'Sharktech', key: 'techStack.hosting.sharktech' },
      { title: 'Fastly Hosted', key: 'techStack.hosting.fastlyHosted' },
      { title: 'UpCloud', key: 'techStack.hosting.upCloud' },
      { title: 'Safenames', key: 'techStack.hosting.safenames' },
      { title: 'Active 24 CZ', key: 'techStack.hosting.active24CZ' },
      { title: 'Beanfield Technologies', key: 'techStack.hosting.beanfieldTechnologies' },
      { title: 'WP Engine', key: 'techStack.hosting.wPEngine' },
      { title: 'Amazon Mumbai Region', key: 'techStack.hosting.amazonMumbaiRegion' },
      { title: 'UCloud', key: 'techStack.hosting.uCloud' },
      { title: 'SolidSpace', key: 'techStack.hosting.solidSpace' },
      { title: 'Microsoft Azure US Central Region', key: 'techStack.hosting.microsoftAzureUSCentralRegion' },
      { title: 'Black Lotus', key: 'techStack.hosting.blackLotus' },
      { title: 'Fastly Load Balancer', key: 'techStack.hosting.fastlyLoadBalancer' },
      { title: 'Google Cloud South Carolina', key: 'techStack.hosting.googleCloudSouthCarolina' },
      { title: 'Web Werks', key: 'techStack.hosting.webWerks' },
      { title: 'Cloudflare Hosting', key: 'techStack.hosting.cloudflareHosting' },
      { title: 'Uberspace', key: 'techStack.hosting.uberspace' },
      { title: 'All-Inkl', key: 'techStack.hosting.all-Inkl' },
      { title: 'DOSarrest', key: 'techStack.hosting.dOSarrest' },
      { title: 'Microsoft Azure Brazil South Region', key: 'techStack.hosting.microsoftAzureBrazilSouthRegion' },
      { title: 'Amazon ELB Ireland', key: 'techStack.hosting.amazonELBIreland' },
      { title: 'EuroDNS', key: 'techStack.hosting.euroDNS' },
      { title: 'Cogeco Peer 1', key: 'techStack.hosting.cogecoPeer1' },
      { title: 'Google Cloud Oregon', key: 'techStack.hosting.googleCloudOregon' },
      { title: 'Aruba', key: 'techStack.hosting.aruba' },
      { title: 'HostCollective', key: 'techStack.hosting.hostCollective' },
      { title: 'Amazon ELB North Virginia', key: 'techStack.hosting.amazonELBNorthVirginia' },
      { title: 'Yandex Cloud Hosted', key: 'techStack.hosting.yandexCloudHosted' },
      { title: 'OzServers', key: 'techStack.hosting.ozServers' },
      { title: 'Google Cloud Taiwan', key: 'techStack.hosting.googleCloudTaiwan' },
      { title: '20i', key: 'techStack.hosting.20i' },
      { title: 'Amazon South Korea Region', key: 'techStack.hosting.amazonSouthKoreaRegion' },
      { title: 'QuadraNet', key: 'techStack.hosting.quadraNet' },
      { title: 'Amazon Paris Region', key: 'techStack.hosting.amazonParisRegion' },
      { title: 'Limestone Networks', key: 'techStack.hosting.limestoneNetworks' },
      { title: 'iWeb', key: 'techStack.hosting.iWeb' },
      { title: 'Peak 10', key: 'techStack.hosting.peak10' },
      { title: 'Google Cloud Hong Kong', key: 'techStack.hosting.googleCloudHongKong' },
      { title: 'Xneelo Hosting', key: 'techStack.hosting.xneeloHosting' },
      { title: 'GitHub Hoster', key: 'techStack.hosting.gitHubHoster' },
      { title: 'key-Systems', key: 'techStack.hosting.key-Systems' },
      { title: 'HostGator', key: 'techStack.hosting.hostGator' },
      { title: 'Fasthosts', key: 'techStack.hosting.fasthosts' },
      { title: 'Fly', key: 'techStack.hosting.fly' },
      { title: 'Krystal Hosting', key: 'techStack.hosting.krystalHosting' },
      { title: 'Register.IT', key: 'techStack.hosting.register_IT' },
      { title: 'Google Cloud Frankfurt', key: 'techStack.hosting.googleCloudFrankfurt' },
      { title: 'Shopify Hosted', key: 'techStack.hosting.shopifyHosted' },
      { title: 'NFOrce Entertainment', key: 'techStack.hosting.nFOrceEntertainment' },
      { title: 'Microsoft Azure US West Region', key: 'techStack.hosting.microsoftAzureUSWestRegion' },
      { title: 'Microsoft Azure US West Central Region', key: 'techStack.hosting.microsoftAzureUSWestCentralRegion' },
      { title: 'Webzilla', key: 'techStack.hosting.webzilla' },
      { title: 'Neterra', key: 'techStack.hosting.neterra' },
      { title: 'Gandi', key: 'techStack.hosting.gandi' },
      { title: 'Incapsula', key: 'techStack.hosting.incapsula' },
      { title: 'Oracle Cloud', key: 'techStack.hosting.oracleCloud' },
      { title: 'InMotion Hosting', key: 'techStack.hosting.inMotionHosting' },
      { title: 'Level 3 Communications', key: 'techStack.hosting.level3Communications' },
      { title: 'Hivelocity Hosting', key: 'techStack.hosting.hivelocityHosting' },
      { title: 'ColoCrossing', key: 'techStack.hosting.coloCrossing' },
      { title: 'Hover', key: 'techStack.hosting.hover' },
      { title: 'Confluence Networks', key: 'techStack.hosting.confluenceNetworks' },
      { title: 'Peg Technic', key: 'techStack.hosting.pegTechnic' },
      { title: 'Cloud Networks', key: 'techStack.hosting.cloudNetworks' },
      { title: 'Choopa', key: 'techStack.hosting.choopa' },
      { title: 'Rumahweb', key: 'techStack.hosting.rumahweb' },
      { title: 'Kinsta', key: 'techStack.hosting.kinsta' },
      { title: 'SAKURA Internet', key: 'techStack.hosting.sAKURAInternet' },
      { title: 'netcup', key: 'techStack.hosting.netcup' },
      { title: 'Amazon Hong Kong Region', key: 'techStack.hosting.amazonHongKongRegion' },
      { title: 'GMO Internet', key: 'techStack.hosting.gMOInternet' },
      { title: 'Amazon ELB Frankfurt', key: 'techStack.hosting.amazonELBFrankfurt' },
      { title: '123-Reg', key: 'techStack.hosting.123-Reg' },
      { title: 'Hubspot Hosted', key: 'techStack.hosting.hubspotHosted' },
      { title: 'CSC', key: 'techStack.hosting.cSC' },
      { title: 'Amazon Ireland Region', key: 'techStack.hosting.amazonIrelandRegion' },
      { title: 'Alibaba', key: 'techStack.hosting.alibaba' },
      { title: 'Webflow Hosting', key: 'techStack.hosting.webflowHosting' },
      { title: 'Heroku', key: 'techStack.hosting.heroku' },
      { title: 'Telefonica', key: 'techStack.hosting.telefonica' },
      { title: 'Google Cloud Montreal', key: 'techStack.hosting.googleCloudMontreal' },
      { title: 'Liquid Web', key: 'techStack.hosting.liquidWeb' },
      { title: 'Microsoft Azure India Central Region', key: 'techStack.hosting.microsoftAzureIndiaCentralRegion' },
      { title: 'arsys.es', key: 'techStack.hosting.arsys_Es' },
      { title: 'Amazon Tokyo Region', key: 'techStack.hosting.amazonTokyoRegion' },
      { title: 'Afrihost Hosting', key: 'techStack.hosting.afrihostHosting' },
      { title: 'Weebly Hosting', key: 'techStack.hosting.weeblyHosting' },
      { title: 'Vodien', key: 'techStack.hosting.vodien' },
      { title: 'Hosting UK', key: 'techStack.hosting.hostingUK' },
      { title: 'Sprint', key: 'techStack.hosting.sprint' },
      { title: 'Hengtong', key: 'techStack.hosting.hengtong' },
      { title: 'Perfect International', key: 'techStack.hosting.perfectInternational' },
      { title: 'Automattic', key: 'techStack.hosting.automattic' },
      { title: 'Microsoft Azure US South Region', key: 'techStack.hosting.microsoftAzureUSSouthRegion' },
      { title: 'DINA Hosting', key: 'techStack.hosting.dINAHosting' },
      { title: 'MochaHost', key: 'techStack.hosting.mochaHost' },
      { title: 'AWS Global Accelerator', key: 'techStack.hosting.aWSGlobalAccelerator' },
      { title: 'Amazon California Region', key: 'techStack.hosting.amazonCaliforniaRegion' },
      { title: 'LeaseWeb', key: 'techStack.hosting.leaseWeb' },
      { title: 'Unified Layer', key: 'techStack.hosting.unifiedLayer' },
      { title: 'Amazon ELB London', key: 'techStack.hosting.amazonELBLondon' },
      { title: 'Hostinger', key: 'techStack.hosting.hostinger' },
      { title: 'BuyVM', key: 'techStack.hosting.buyVM' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Lead Generation Software',
    key: 'techStack.Lead generation software',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Lead generation software.select' },
      { title: 'Leads by Web.com', key: 'techStack.leadGenerationSoftware.leadsByWeb_Com' },
      { title: 'VisiStat', key: 'techStack.leadGenerationSoftware.visiStat' },
      { title: 'LeadForensics', key: 'techStack.leadGenerationSoftware.leadForensics' },
      { title: 'Intelligent Demand', key: 'techStack.leadGenerationSoftware.intelligentDemand' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Live Streaming Platforms',
    key: 'techStack.Live streaming platforms',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Live streaming platforms.select' },
      { title: 'Dacast', key: 'techStack.liveStreamingPlatforms.dacast' },
      { title: 'Ustream', key: 'techStack.liveStreamingPlatforms.ustream' },
      { title: 'Panopto', key: 'techStack.liveStreamingPlatforms.panopto' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Load Balancers',
    key: 'techStack.Load balancers',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Load balancers.select' },
      { title: 'Citrix NetScaler', key: 'techStack.loadBalancers.citrixNetScaler' },
      { title: 'Amazon Elastic Load Balancer', key: 'techStack.loadBalancers.amazonElasticLoadBalancer' },
      { title: 'Google Frontend (Webserver)', key: 'techStack.loadBalancers.googleFrontend(Webserver)' },
      { title: 'F5 BIG-IP', key: 'techStack.loadBalancers.f5BIG-IP' },
      { title: 'Netcache', key: 'techStack.loadBalancers.netcache' },
      { title: 'Gunicorn', key: 'techStack.loadBalancers.gunicorn' },
      { title: 'Oracle iPlanet Web Server', key: 'techStack.loadBalancers.oracleIPlanetWebServer' },
      { title: 'Ubuntu', key: 'techStack.loadBalancers.ubuntu' },
      { title: 'Microsoft-IIS', key: 'techStack.loadBalancers.microsoft-IIS' },
      { title: 'Nginx', key: 'techStack.loadBalancers.nginx' },
      { title: 'Apache', key: 'techStack.loadBalancers.apache' },
      { title: 'Varnish', key: 'techStack.loadBalancers.varnish' },
      { title: 'Phusion Passenger', key: 'techStack.loadBalancers.phusionPassenger' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Loyalty And Gamification',
    key: 'techStack.Loyalty and gamification',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Loyalty and gamification.select' },
      { title: 'Eprize', key: 'techStack.loyaltyAndGamification.eprize' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Marketing Automation',
    key: 'techStack.Marketing automation',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Marketing automation.select' },
      { title: 'Drip', key: 'techStack.marketingAutomation.drip' },
      { title: 'Act-On', key: 'techStack.marketingAutomation.act-On' },
      { title: 'Pardot', key: 'techStack.marketingAutomation.pardot' },
      { title: 'Hatchbuck', key: 'techStack.marketingAutomation.hatchbuck' },
      { title: 'SnapApp', key: 'techStack.marketingAutomation.snapApp' },
      { title: 'SharpSpring', key: 'techStack.marketingAutomation.sharpSpring' },
      { title: 'UberFlip', key: 'techStack.marketingAutomation.uberFlip' },
      { title: 'SALESmanago', key: 'techStack.marketingAutomation.sALESmanago' },
      { title: 'Reach Local', key: 'techStack.marketingAutomation.reachLocal' },
      { title: 'Demandforce', key: 'techStack.marketingAutomation.demandforce' },
      { title: 'Microsoft Dynamics 365 Marketing', key: 'techStack.marketingAutomation.microsoftDynamics365Marketing' },
      { title: 'Active Campaign', key: 'techStack.marketingAutomation.activeCampaign' },
      { title: 'Marketo', key: 'techStack.marketingAutomation.marketo' },
      { title: 'Manticore', key: 'techStack.marketingAutomation.manticore' },
      { title: 'Hubspot', key: 'techStack.marketingAutomation.hubspot' },
      { title: 'Eloqua', key: 'techStack.marketingAutomation.eloqua' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Online Testing Platforms',
    key: 'techStack.Online testing platforms',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Online testing platforms.select' },
      { title: 'AB Tasty', key: 'techStack.onlineTestingPlatforms.aBTasty' },
      { title: 'Visual Website Optimizer', key: 'techStack.onlineTestingPlatforms.visualWebsiteOptimizer' },
      { title: 'Google Website Optimizer', key: 'techStack.onlineTestingPlatforms.googleWebsiteOptimizer' },
      { title: 'Unbounce', key: 'techStack.onlineTestingPlatforms.unbounce' },
      { title: 'LeadPages', key: 'techStack.onlineTestingPlatforms.leadPages' },
      { title: 'Monetate', key: 'techStack.onlineTestingPlatforms.monetate' },
      { title: 'ClickFunnels', key: 'techStack.onlineTestingPlatforms.clickFunnels' },
      { title: 'Adobe TestAndTarget', key: 'techStack.onlineTestingPlatforms.adobeTestAndTarget' },
      { title: 'Optimizely', key: 'techStack.onlineTestingPlatforms.optimizely' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Online Video Platforms',
    key: 'techStack.Online video platforms',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Online video platforms.select' },
      { title: 'Kaltura', key: 'techStack.onlineVideoPlatforms.kaltura' },
      { title: 'ThePlatform', key: 'techStack.onlineVideoPlatforms.thePlatform' },
      { title: 'Brightcove', key: 'techStack.onlineVideoPlatforms.brightcove' },
      { title: 'YouTube', key: 'techStack.onlineVideoPlatforms.youTube' },
      { title: 'Ooyala', key: 'techStack.onlineVideoPlatforms.ooyala' },
      { title: 'Wistia', key: 'techStack.onlineVideoPlatforms.wistia' },
      { title: 'Flowplayer', key: 'techStack.onlineVideoPlatforms.flowplayer' },
      { title: 'Viddler', key: 'techStack.onlineVideoPlatforms.viddler' },
      { title: 'Bits on the Run', key: 'techStack.onlineVideoPlatforms.bitsOnTheRun' },
      { title: 'DailyMotion', key: 'techStack.onlineVideoPlatforms.dailyMotion' },
      { title: 'Vzaar', key: 'techStack.onlineVideoPlatforms.vzaar' },
      { title: 'Vimeo', key: 'techStack.onlineVideoPlatforms.vimeo' },
      { title: 'Vidyard', key: 'techStack.onlineVideoPlatforms.vidyard' },
      { title: 'BlipTV', key: 'techStack.onlineVideoPlatforms.blipTV' },
      { title: 'JW Player', key: 'techStack.onlineVideoPlatforms.jWPlayer' },
      { title: 'Flickr Video', key: 'techStack.onlineVideoPlatforms.flickrVideo' },
      { title: 'jPlayer', key: 'techStack.onlineVideoPlatforms.jPlayer' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Other',
    key: 'techStack.Other',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Other.select' },
      { title: 'DudaMobile', key: 'techStack.other.dudaMobile' },
      { title: 'Pet Exec', key: 'techStack.other.petExec' },
      { title: 'MailChimp SPF', key: 'techStack.other.mailChimpSPF' },
      { title: 'DoubleVerify', key: 'techStack.other.doubleVerify' },
      { title: 'TikTok', key: 'techStack.other.tikTok' },
      { title: 'Bing Maps', key: 'techStack.other.bingMaps' },
      { title: 'Shutterstock', key: 'techStack.other.shutterstock' },
      { title: 'Avvo', key: 'techStack.other.avvo' },
      { title: 'Box.net', key: 'techStack.other.box_Net' },
      { title: 'Ad Unit 320 x 50', key: 'techStack.other.adUnit320X50' },
      { title: 'SoundCloud', key: 'techStack.other.soundCloud' },
      { title: 'Paczkomaty', key: 'techStack.other.paczkomaty' },
      { title: 'Kubernetes', key: 'techStack.other.kubernetes' },
      { title: 'Yelp', key: 'techStack.other.yelp' },
      { title: 'Zencoder', key: 'techStack.other.zencoder' },
      { title: 'Indeed', key: 'techStack.other.indeed' },
      { title: 'A2Z Inc', key: 'techStack.other.a2ZInc' },
      { title: 'Xenforo', key: 'techStack.other.xenforo' },
      { title: 'ALEX', key: 'techStack.other.aLEX' },
      { title: 'Cision', key: 'techStack.other.cision' },
      { title: 'Linkedin Widget', key: 'techStack.other.linkedinWidget' },
      { title: 'Ad Unit 728 x 90', key: 'techStack.other.adUnit728X90' },
      { title: 'Ad Unit 160 x 600', key: 'techStack.other.adUnit160X600' },
      { title: 'Find Law', key: 'techStack.other.findLaw' },
      { title: 'Okta (sub-domains only)', key: 'techStack.other.okta(Sub-DomainsOnly)' },
      { title: 'Unity 3D Web Player', key: 'techStack.other.unity3DWebPlayer' },
      { title: 'Transifex', key: 'techStack.other.transifex' },
      { title: 'Shopsense', key: 'techStack.other.shopsense' },
      { title: 'DocuSign', key: 'techStack.other.docuSign' },
      { title: 'Sequence Monitor SPF', key: 'techStack.other.sequenceMonitorSPF' },
      { title: 'PostAffiliatePro', key: 'techStack.other.postAffiliatePro' },
      { title: 'PowerSchool', key: 'techStack.other.powerSchool' },
      { title: 'Ad Unit 300 x 250', key: 'techStack.other.adUnit300X250' },
      { title: 'OpenID', key: 'techStack.other.openID' },
      { title: 'Altas Relaunched', key: 'techStack.other.altasRelaunched' },
      { title: 'Google Maps', key: 'techStack.other.googleMaps' },
      { title: 'FullStory', key: 'techStack.other.fullStory' },
      { title: 'Deluxe', key: 'techStack.other.deluxe' },
      { title: 'Gigya Registration', key: 'techStack.other.gigyaRegistration' },
      { title: 'Echo', key: 'techStack.other.echo' },
      { title: 'Flickr Badge', key: 'techStack.other.flickrBadge' },
      { title: 'Flickr Slideshow', key: 'techStack.other.flickrSlideshow' },
      { title: 'Map Quest', key: 'techStack.other.mapQuest' },
      { title: 'Google Maps (Non Paid Users)', key: 'techStack.other.googleMaps(NonPaidUsers)' },
      { title: 'ExactOnline', key: 'techStack.other.exactOnline' },
      { title: 'Mobile Friendly', key: 'techStack.other.mobileFriendly' },
      { title: 'Loggly', key: 'techStack.other.loggly' },
      { title: 'Google Apps', key: 'techStack.other.googleApps' },
      { title: 'Ad Unit 300 x 600', key: 'techStack.other.adUnit300X600' },
      { title: 'Google Checkout', key: 'techStack.other.googleCheckout' },
      { title: 'Microsoft Office 365', key: 'techStack.other.microsoftOffice365' },
      { title: 'Google Places', key: 'techStack.other.googlePlaces' },
      { title: 'Steepto', key: 'techStack.other.steepto' },
      { title: 'Google Maps (Paid Users)', key: 'techStack.other.googleMaps(PaidUsers)' },
      { title: 'greyscale - grey', key: 'techStack.other.greyscale-Grey' },
      { title: 'Mapbox', key: 'techStack.other.mapbox' },
      { title: 'IntelliAd', key: 'techStack.other.intelliAd' },
      { title: 'Notion', key: 'techStack.other.notion' },
      { title: 'Flickr API', key: 'techStack.other.flickrAPI' },
      { title: 'Gettyimages', key: 'techStack.other.gettyimages' },
      { title: 'Unreal Engine', key: 'techStack.other.unrealEngine' },
      { title: 'Atlas Legacy', key: 'techStack.other.atlasLegacy' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Personalization',
    key: 'techStack.Personalization',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Personalization.select' },
      { title: 'iGoDigital', key: 'techStack.personalization.iGoDigital' },
      { title: 'Sailthru', key: 'techStack.personalization.sailthru' },
      { title: 'Certona', key: 'techStack.personalization.certona' },
      { title: 'RichRelevance', key: 'techStack.personalization.richRelevance' },
      { title: 'Amadesa', key: 'techStack.personalization.amadesa' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Publisher Advertising Tools',
    key: 'techStack.Publisher advertising tools',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Publisher advertising tools.select' },
      { title: 'Tynt', key: 'techStack.publisherAdvertisingTools.tynt' },
      { title: 'Sovrn', key: 'techStack.publisherAdvertisingTools.sovrn' },
      { title: 'Open AdStream (Appnexus)', key: 'techStack.publisherAdvertisingTools.openAdStream(Appnexus)' },
      { title: 'Nativo', key: 'techStack.publisherAdvertisingTools.nativo' },
      { title: 'BuySellAds', key: 'techStack.publisherAdvertisingTools.buySellAds' },
      { title: 'Parse.ly', key: 'techStack.publisherAdvertisingTools.parse_Ly' },
      { title: 'OpenX - Exchange', key: 'techStack.publisherAdvertisingTools.openX-Exchange' },
      { title: 'Media.net', key: 'techStack.publisherAdvertisingTools.media_Net' },
      {
        title: 'Adition Technologies - Publishers',
        key: 'techStack.publisherAdvertisingTools.aditionTechnologies-Publishers',
      },
      { title: 'Sharethrough', key: 'techStack.publisherAdvertisingTools.sharethrough' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Recruitment',
    key: 'techStack.Recruitment',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Recruitment.select' },
      { title: 'Crelate', key: 'techStack.recruitment.crelate' },
      { title: 'SuccessFactors (SAP)', key: 'techStack.recruitment.successFactors(SAP)' },
      { title: 'iCIMS', key: 'techStack.recruitment.iCIMS' },
      { title: 'Greenhouse.io', key: 'techStack.recruitment.greenhouse_Io' },
      { title: 'Jobscore', key: 'techStack.recruitment.jobscore' },
      { title: 'myStaffingPro', key: 'techStack.recruitment.myStaffingPro' },
      { title: 'eRecruit', key: 'techStack.recruitment.eRecruit' },
      { title: 'Cornerstone On Demand', key: 'techStack.recruitment.cornerstoneOnDemand' },
      { title: 'UltiPro', key: 'techStack.recruitment.ultiPro' },
      { title: 'PeopleFluent', key: 'techStack.recruitment.peopleFluent' },
      { title: 'Kenexa', key: 'techStack.recruitment.kenexa' },
      { title: 'ZipRecruiter', key: 'techStack.recruitment.zipRecruiter' },
      { title: 'SilkRoad', key: 'techStack.recruitment.silkRoad' },
      { title: 'Jobvite', key: 'techStack.recruitment.jobvite' },
      { title: 'BambooHR', key: 'techStack.recruitment.bambooHR' },
      { title: 'JobDiva', key: 'techStack.recruitment.jobDiva' },
      { title: 'Zoho Recruit', key: 'techStack.recruitment.zohoRecruit' },
      { title: 'Lever', key: 'techStack.recruitment.lever' },
      { title: 'Applicant Pro', key: 'techStack.recruitment.applicantPro' },
      { title: 'PC Recruiter', key: 'techStack.recruitment.pCRecruiter' },
      { title: 'HireBridge', key: 'techStack.recruitment.hireBridge' },
      { title: 'Resumator', key: 'techStack.recruitment.resumator' },
      { title: 'TalentEd', key: 'techStack.recruitment.talentEd' },
      { title: 'Breezy HR (formerly NimbleHR)', key: 'techStack.recruitment.breezyHR(FormerlyNimbleHR)' },
      { title: 'HRMDirect', key: 'techStack.recruitment.hRMDirect' },
      { title: 'Workable', key: 'techStack.recruitment.workable' },
      { title: 'Workday Recruit', key: 'techStack.recruitment.workdayRecruit' },
      { title: 'Catsone', key: 'techStack.recruitment.catsone' },
      { title: 'Taleo', key: 'techStack.recruitment.taleo' },
      { title: 'SmartRecruiters', key: 'techStack.recruitment.smartRecruiters' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Reservation Software',
    key: 'techStack.Reservation software',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Reservation software.select' },
      { title: 'RestaurantConnect', key: 'techStack.reservationSoftware.restaurantConnect' },
      { title: 'SynXis (Sabre Hospitality)', key: 'techStack.reservationSoftware.synXis(SabreHospitality)' },
      { title: 'Opentable', key: 'techStack.reservationSoftware.opentable' },
      { title: 'Opentable Widget', key: 'techStack.reservationSoftware.opentableWidget' },
      { title: 'Booker Software', key: 'techStack.reservationSoftware.bookerSoftware' },
      { title: 'Quandoo', key: 'techStack.reservationSoftware.quandoo' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Retargeting',
    key: 'techStack.Retargeting',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Retargeting.select' },
      { title: 'Criteo', key: 'techStack.retargeting.criteo' },
      { title: 'Google Remarketing', key: 'techStack.retargeting.googleRemarketing' },
      { title: 'Facebook Custom Audiences', key: 'techStack.retargeting.facebookCustomAudiences' },
      { title: 'Perfect Audience', key: 'techStack.retargeting.perfectAudience' },
      { title: 'Yahoo Retargeting', key: 'techStack.retargeting.yahooRetargeting' },
      { title: 'Ve Interactive', key: 'techStack.retargeting.veInteractive' },
      { title: 'Google Dynamic Remarketing', key: 'techStack.retargeting.googleDynamicRemarketing' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'SSL Certificates',
    key: 'techStack.Ssl certificates',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Ssl certificates.select' },
      { title: 'OpenSSL', key: 'techStack.sslCertificates.openSSL' },
      { title: 'DreamHost SSL', key: 'techStack.sslCertificates.dreamHostSSL' },
      { title: 'Media Temple SSL', key: 'techStack.sslCertificates.mediaTempleSSL' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Search Appliances',
    key: 'techStack.Search appliances',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Search appliances.select' },
      { title: 'Swiftype', key: 'techStack.searchAppliances.swiftype' },
      { title: 'Google Custom Search', key: 'techStack.searchAppliances.googleCustomSearch' },
      { title: 'Google AFS', key: 'techStack.searchAppliances.googleAFS' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Search Marketing',
    key: 'techStack.Search marketing',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Search marketing.select' },
      { title: 'Yandex.Direct', key: 'techStack.searchMarketing.yandex_Direct' },
      { title: 'IgnitionOne', key: 'techStack.searchMarketing.ignitionOne' },
      { title: 'Adobe Marketing Cloud', key: 'techStack.searchMarketing.adobeMarketingCloud' },
      { title: 'Wishpond', key: 'techStack.searchMarketing.wishpond' },
      { title: 'Adobe Media Optimizer', key: 'techStack.searchMarketing.adobeMediaOptimizer' },
      { title: 'Kenshoo', key: 'techStack.searchMarketing.kenshoo' },
      { title: 'Bizible', key: 'techStack.searchMarketing.bizible' },
      { title: 'Marin', key: 'techStack.searchMarketing.marin' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Security',
    key: 'techStack.Security',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Security.select' },
      { title: 'Starfield', key: 'techStack.security.starfield' },
      { title: 'Trustwave Seal', key: 'techStack.security.trustwaveSeal' },
      { title: 'Network Solutions Seal', key: 'techStack.security.networkSolutionsSeal' },
      { title: 'BuySafe', key: 'techStack.security.buySafe' },
      { title: 'Sift Science', key: 'techStack.security.siftScience' },
      { title: 'Verisign Seal', key: 'techStack.security.verisignSeal' },
      { title: 'GoDaddy Verified', key: 'techStack.security.goDaddyVerified' },
      { title: 'McAfee', key: 'techStack.security.mcAfee' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Shipping',
    key: 'techStack.Shipping',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Shipping.select' },
      { title: 'UPS', key: 'techStack.shipping.uPS' },
      { title: 'FedEx', key: 'techStack.shipping.fedEx' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Site & Cart Abandonment',
    key: 'techStack.Site and cart abandonment',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Site and cart abandonment.select' },
      { title: 'PicReel', key: 'techStack.siteAndCartAbandonment.picReel' },
      { title: 'Optimonk', key: 'techStack.siteAndCartAbandonment.optimonk' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Social Login',
    key: 'techStack.Social login',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Social login.select' },
      { title: 'Linkedin Login', key: 'techStack.socialLogin.linkedinLogin' },
      { title: 'Janrain', key: 'techStack.socialLogin.janrain' },
      { title: 'Google Plus', key: 'techStack.socialLogin.googlePlus' },
      { title: 'OneAll', key: 'techStack.socialLogin.oneAll' },
      { title: 'Gigya', key: 'techStack.socialLogin.gigya' },
      { title: 'Twitter Widgets', key: 'techStack.socialLogin.twitterWidgets' },
      { title: 'Facebook Login (Connect)', key: 'techStack.socialLogin.facebookLogin(Connect)' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Social Media Marketing',
    key: 'techStack.Social media marketing',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Social media marketing.select' },
      { title: 'RebelMouse', key: 'techStack.socialMediaMarketing.rebelMouse' },
      { title: 'Nanigans', key: 'techStack.socialMediaMarketing.nanigans' },
      { title: 'Friendbuy', key: 'techStack.socialMediaMarketing.friendbuy' },
      { title: 'Agropulse', key: 'techStack.socialMediaMarketing.agropulse' },
      { title: 'Extole', key: 'techStack.socialMediaMarketing.extole' },
      { title: 'Tint', key: 'techStack.socialMediaMarketing.tint' },
      { title: 'Gleam', key: 'techStack.socialMediaMarketing.gleam' },
      { title: 'Ambassador', key: 'techStack.socialMediaMarketing.ambassador' },
      { title: 'Curebit', key: 'techStack.socialMediaMarketing.curebit' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Social Sharing',
    key: 'techStack.Social sharing',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Social sharing.select' },
      { title: 'Twitter Sharing', key: 'techStack.socialSharing.twitterSharing' },
      { title: 'Instagram', key: 'techStack.socialSharing.instagram' },
      { title: 'Hupso', key: 'techStack.socialSharing.hupso' },
      { title: 'ShareThis', key: 'techStack.socialSharing.shareThis' },
      { title: 'Janrain Social Share', key: 'techStack.socialSharing.janrainSocialShare' },
      { title: 'Facebook Social Plugins', key: 'techStack.socialSharing.facebookSocialPlugins' },
      { title: 'Facebook Like Button', key: 'techStack.socialSharing.facebookLikeButton' },
      { title: 'AddShoppers', key: 'techStack.socialSharing.addShoppers' },
      { title: 'AddThis', key: 'techStack.socialSharing.addThis' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Supply Side Platforms',
    key: 'techStack.Supply side platforms',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Supply side platforms.select' },
      { title: 'Rubicon Project', key: 'techStack.supplySidePlatforms.rubiconProject' },
      { title: 'Zedo', key: 'techStack.supplySidePlatforms.zedo' },
      { title: 'Sonobi', key: 'techStack.supplySidePlatforms.sonobi' },
      { title: 'PubMatic', key: 'techStack.supplySidePlatforms.pubMatic' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Support And Feedback',
    key: 'techStack.Support and feedback',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Support and feedback.select' },
      { title: 'SurveyGizmo', key: 'techStack.supportAndFeedback.surveyGizmo' },
      { title: 'PerfectMind', key: 'techStack.supportAndFeedback.perfectMind' },
      { title: 'Kayako', key: 'techStack.supportAndFeedback.kayako' },
      { title: 'MemberClicks', key: 'techStack.supportAndFeedback.memberClicks' },
      { title: 'GetSatisfaction', key: 'techStack.supportAndFeedback.getSatisfaction' },
      { title: 'Qualaroo', key: 'techStack.supportAndFeedback.qualaroo' },
      { title: 'Rosy Salon Software', key: 'techStack.supportAndFeedback.rosySalonSoftware' },
      { title: 'Qualtrics', key: 'techStack.supportAndFeedback.qualtrics' },
      { title: 'UserReport', key: 'techStack.supportAndFeedback.userReport' },
      { title: 'Helpscout', key: 'techStack.supportAndFeedback.helpscout' },
      { title: 'Questback', key: 'techStack.supportAndFeedback.questback' },
      { title: 'RightNow', key: 'techStack.supportAndFeedback.rightNow' },
      { title: 'Freshdesk', key: 'techStack.supportAndFeedback.freshdesk' },
      { title: 'Autotask', key: 'techStack.supportAndFeedback.autotask' },
      { title: 'Spigit (Mindjet)', key: 'techStack.supportAndFeedback.spigit(Mindjet)' },
      { title: 'Zendesk', key: 'techStack.supportAndFeedback.zendesk' },
      { title: 'Intercom', key: 'techStack.supportAndFeedback.intercom' },
      { title: 'SOAP Vault', key: 'techStack.supportAndFeedback.sOAPVault' },
      { title: 'SurveyMonkey', key: 'techStack.supportAndFeedback.surveyMonkey' },
      { title: 'iPerceptions', key: 'techStack.supportAndFeedback.iPerceptions' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Tag Management',
    key: 'techStack.Tag management',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Tag management.select' },
      { title: 'Signal by BrightTag', key: 'techStack.tagManagement.signalByBrightTag' },
      { title: 'Adobe Tag Management', key: 'techStack.tagManagement.adobeTagManagement' },
      { title: 'TagMan', key: 'techStack.tagManagement.tagMan' },
      { title: 'Tag Commander', key: 'techStack.tagManagement.tagCommander' },
      { title: 'Yahoo Tag Manager', key: 'techStack.tagManagement.yahooTagManager' },
      { title: 'DoubleClick Floodlight', key: 'techStack.tagManagement.doubleClickFloodlight' },
      { title: 'Ensighten', key: 'techStack.tagManagement.ensighten' },
      { title: 'Tealium', key: 'techStack.tagManagement.tealium' },
      { title: 'Google Tag Manager', key: 'techStack.tagManagement.googleTagManager' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Video Advertising Networks',
    key: 'techStack.Video advertising networks',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Video advertising networks.select' },
      { title: 'Unruly Media', key: 'techStack.videoAdvertisingNetworks.unrulyMedia' },
      { title: 'Teads', key: 'techStack.videoAdvertisingNetworks.teads' },
      { title: 'Brightroll', key: 'techStack.videoAdvertisingNetworks.brightroll' },
      { title: 'NDN', key: 'techStack.videoAdvertisingNetworks.nDN' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Web Accelerators',
    key: 'techStack.Web accelerators',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Web accelerators.select' },
      { title: 'CloudFlare', key: 'techStack.webAccelerators.cloudFlare' },
      { title: 'Incapsula', key: 'techStack.webAccelerators.incapsula' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Web Performance Monitoring',
    key: 'techStack.Web performance monitoring',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Web performance monitoring.select' },
      { title: 'Ruxit', key: 'techStack.webPerformanceMonitoring.ruxit' },
      { title: 'Cedexis Radar', key: 'techStack.webPerformanceMonitoring.cedexisRadar' },
      { title: 'dynaTrace', key: 'techStack.webPerformanceMonitoring.dynaTrace' },
      { title: 'Akamai RUM', key: 'techStack.webPerformanceMonitoring.akamaiRUM' },
      { title: 'SOASTA', key: 'techStack.webPerformanceMonitoring.sOASTA' },
      { title: 'Datadog', key: 'techStack.webPerformanceMonitoring.datadog' },
      { title: 'Pingdom', key: 'techStack.webPerformanceMonitoring.pingdom' },
      { title: 'New Relic', key: 'techStack.webPerformanceMonitoring.newRelic' },
      { title: 'StatusPage IO', key: 'techStack.webPerformanceMonitoring.statusPageIO' },
      { title: 'AppDynamics', key: 'techStack.webPerformanceMonitoring.appDynamics' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Web Presentation Tools',
    key: 'techStack.Web presentation tools',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Web presentation tools.select' },
      { title: 'GoToMeeting', key: 'techStack.webPresentationTools.goToMeeting' },
      { title: 'ON24', key: 'techStack.webPresentationTools.oN24' },
      { title: 'ReadyTalk', key: 'techStack.webPresentationTools.readyTalk' },
      { title: 'GoToWebinar', key: 'techStack.webPresentationTools.goToWebinar' },
      { title: 'Webex', key: 'techStack.webPresentationTools.webex' },
      { title: 'Adobe Connect', key: 'techStack.webPresentationTools.adobeConnect' },
      { title: 'Knowledge Vision', key: 'techStack.webPresentationTools.knowledgeVision' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Widgets',
    key: 'techStack.Widgets',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Widgets.select' },
      { title: 'Unity', key: 'techStack.widgets.unity' },
      { title: 'Apple Whitelist', key: 'techStack.widgets.appleWhitelist' },
      { title: 'Smart App Banner', key: 'techStack.widgets.smartAppBanner' },
      { title: 'TurboLinks', key: 'techStack.widgets.turboLinks' },
      { title: 'Tencent Waterproof Wall', key: 'techStack.widgets.tencentWaterproofWall' },
      { title: 'Q2W3 Fixed Widget', key: 'techStack.widgets.q2W3FixedWidget' },
      { title: 'Azure Active Directory', key: 'techStack.widgets.azureActiveDirectory' },
      { title: 'FrontApp', key: 'techStack.widgets.frontApp' },
      { title: 'GetButton.io', key: 'techStack.widgets.getButton_Io' },
      { title: 'CleanTalk Spam Protect', key: 'techStack.widgets.cleanTalkSpamProtect' },
      { title: 'Comments Plugin for Wix', key: 'techStack.widgets.commentsPluginForWix' },
      { title: 'ImageKit.io', key: 'techStack.widgets.imageKit_Io' },
      { title: 'Akismet', key: 'techStack.widgets.akismet' },
      { title: 'GeneratePress Premium', key: 'techStack.widgets.generatePressPremium' },
      { title: 'Bloom Wordpress Plugin', key: 'techStack.widgets.bloomWordpressPlugin' },
      { title: 'Elfsight', key: 'techStack.widgets.elfsight' },
      { title: 'Contact Form 7', key: 'techStack.widgets.contactForm7' },
      { title: 'TradingView', key: 'techStack.widgets.tradingView' },
      { title: 'Quill', key: 'techStack.widgets.quill' },
      { title: 'Shopify Inbox', key: 'techStack.widgets.shopifyInbox' },
      { title: 'WhatsApp Chat WP', key: 'techStack.widgets.whatsAppChatWP' },
      { title: 'Yoast SEO Premium', key: 'techStack.widgets.yoastSEOPremium' },
      { title: 'My Calendar', key: 'techStack.widgets.myCalendar' },
      { title: 'Rocket Chat', key: 'techStack.widgets.rocketChat' },
      { title: 'GeoDirectory', key: 'techStack.widgets.geoDirectory' },
      { title: 'Facebook Customer Chat SDK', key: 'techStack.widgets.facebookCustomerChatSDK' },
      { title: 'ShareButton', key: 'techStack.widgets.shareButton' },
      { title: 'Rebrandly', key: 'techStack.widgets.rebrandly' },
      { title: 'Fontello', key: 'techStack.widgets.fontello' },
      { title: 'Page scroll to id', key: 'techStack.widgets.pageScrollToId' },
      { title: 'TranslatePress', key: 'techStack.widgets.translatePress' },
      { title: 'CrUX Top 5m', key: 'techStack.widgets.crUXTop5m' },
      { title: 'Visual Composer', key: 'techStack.widgets.visualComposer' },
      { title: 'GitHub Buttons Official', key: 'techStack.widgets.gitHubButtonsOfficial' },
      { title: 'Silktide Cookie Consent', key: 'techStack.widgets.silktideCookieConsent' },
      { title: 'Wix Chat', key: 'techStack.widgets.wixChat' },
      { title: 'Forminator', key: 'techStack.widgets.forminator' },
      { title: 'Thickbox', key: 'techStack.widgets.thickbox' },
      { title: 'WordPress PopUp', key: 'techStack.widgets.wordPressPopUp' },
      { title: 'Viral Loops', key: 'techStack.widgets.viralLoops' },
      { title: 'WhatsApp', key: 'techStack.widgets.whatsApp' },
      { title: 'Coming Soon Page for Wordpress', key: 'techStack.widgets.comingSoonPageForWordpress' },
      { title: 'TrustLogo', key: 'techStack.widgets.trustLogo' },
      { title: 'Hasura', key: 'techStack.widgets.hasura' },
      { title: 'MongoDB', key: 'techStack.widgets.mongoDB' },
      { title: 'Drift', key: 'techStack.widgets.drift' },
      { title: 'RankMath', key: 'techStack.widgets.rankMath' },
      {
        title: 'European Commission Online Dispute Resolution',
        key: 'techStack.widgets.europeanCommissionOnlineDisputeResolution',
      },
      { title: 'WPO365', key: 'techStack.widgets.wPO365' },
      { title: 'PowerPack Addons for Elementor', key: 'techStack.widgets.powerPackAddonsForElementor' },
      { title: 'OpenShareCount', key: 'techStack.widgets.openShareCount' },
      { title: 'FastClick', key: 'techStack.widgets.fastClick' },
      { title: 'Bunny Fonts', key: 'techStack.widgets.bunnyFonts' },
      { title: 'Freshdesk Chat', key: 'techStack.widgets.freshdeskChat' },
      { title: 'Smart Slider 3', key: 'techStack.widgets.smartSlider3' },
      { title: 'Cloudflare Bot Manager', key: 'techStack.widgets.cloudflareBotManager' },
      { title: 'Lightspeed Cache', key: 'techStack.widgets.lightspeedCache' },
      { title: 'Iframely', key: 'techStack.widgets.iframely' },
      { title: 'Usersnap', key: 'techStack.widgets.usersnap' },
      { title: 'Unsplash', key: 'techStack.widgets.unsplash' },
      { title: 'keybase', key: 'techStack.widgets.keybase' },
      { title: 'Calendly', key: 'techStack.widgets.calendly' },
      { title: 'Popup Maker for Wordpress', key: 'techStack.widgets.popupMakerForWordpress' },
      { title: 'WP Rocket', key: 'techStack.widgets.wPRocket' },
      { title: 'Google No Site Links Search', key: 'techStack.widgets.googleNoSiteLinksSearch' },
      { title: 'Termly', key: 'techStack.widgets.termly' },
      { title: 'bbPress', key: 'techStack.widgets.bbPress' },
      { title: 'iStockPhoto', key: 'techStack.widgets.iStockPhoto' },
      { title: 'Microdata for Google Shopping', key: 'techStack.widgets.microdataForGoogleShopping' },
      { title: 'OptinMonster', key: 'techStack.widgets.optinMonster' },
      { title: 'Wix Podcast Player', key: 'techStack.widgets.wixPodcastPlayer' },
      { title: 'Monday', key: 'techStack.widgets.monday' },
      { title: 'Ahrefs Site Verification', key: 'techStack.widgets.ahrefsSiteVerification' },
      { title: 'Lorem Ipsum', key: 'techStack.widgets.loremIpsum' },
      { title: 'Paperform', key: 'techStack.widgets.paperform' },
      { title: 'Giphy', key: 'techStack.widgets.giphy' },
      { title: 'WP Super Cache', key: 'techStack.widgets.wPSuperCache' },
      { title: 'Google News Button', key: 'techStack.widgets.googleNewsButton' },
      { title: 'tawk.to', key: 'techStack.widgets.tawk_To' },
      { title: 'Getty Images', key: 'techStack.widgets.gettyImages' },
      { title: 'WP GDPR Compliance', key: 'techStack.widgets.wPGDPRCompliance' },
      { title: 'CubePortfolio', key: 'techStack.widgets.cubePortfolio' },
      { title: 'Froala Editor', key: 'techStack.widgets.froalaEditor' },
      { title: 'AnyChart', key: 'techStack.widgets.anyChart' },
      { title: 'Twitter Follow Button', key: 'techStack.widgets.twitterFollowButton' },
      { title: 'Modern Events Calendar Lite', key: 'techStack.widgets.modernEventsCalendarLite' },
      { title: 'Facebook Like Box', key: 'techStack.widgets.facebookLikeBox' },
      { title: 'Brave Browser Rewards', key: 'techStack.widgets.braveBrowserRewards' },
      { title: 'ShareThis Share Buttons for WordPress', key: 'techStack.widgets.shareThisShareButtonsForWordPress' },
      { title: 'Extensive VC Addons for WPBakery', key: 'techStack.widgets.extensiveVCAddonsForWPBakery' },
      { title: 'Popup Anything', key: 'techStack.widgets.popupAnything' },
      { title: 'CookieYes for WordPress', key: 'techStack.widgets.cookieYesForWordPress' },
      { title: 'Twitter Timeline', key: 'techStack.widgets.twitterTimeline' },
      { title: 'Embedly', key: 'techStack.widgets.embedly' },
      { title: 'AddThis', key: 'techStack.widgets.addThis' },
      { title: 'Lever', key: 'techStack.widgets.lever' },
      { title: 'Google Font API', key: 'techStack.widgets.googleFontAPI' },
      { title: 'Pygments', key: 'techStack.widgets.pygments' },
      { title: 'Zendesk Dropbox', key: 'techStack.widgets.zendeskDropbox' },
      { title: 'WooCommerce Payments', key: 'techStack.widgets.wooCommercePayments' },
      { title: 'Pinterest', key: 'techStack.widgets.pinterest' },
      { title: 'Linearicons', key: 'techStack.widgets.linearicons' },
      { title: 'Pixabay', key: 'techStack.widgets.pixabay' },
      { title: 'Google Tag Manager', key: 'techStack.widgets.googleTagManager' },
      { title: 'Simple Banner for Wordpress', key: 'techStack.widgets.simpleBannerForWordpress' },
      { title: 'TI WooCommerce Wishlist', key: 'techStack.widgets.tIWooCommerceWishlist' },
      { title: 'AccessPress Social Icons', key: 'techStack.widgets.accessPressSocialIcons' },
      { title: 'Booking.com', key: 'techStack.widgets.booking_Com' },
      { title: 'LayerSlider Responsive', key: 'techStack.widgets.layerSliderResponsive' },
      { title: 'flatpickr', key: 'techStack.widgets.flatpickr' },
      { title: 'Wordpress Plugins', key: 'techStack.widgets.wordpressPlugins' },
      { title: 'Workplace by Facebook', key: 'techStack.widgets.workplaceByFacebook' },
      { title: 'JotForm', key: 'techStack.widgets.jotForm' },
      { title: 'Zoho SalesIQ', key: 'techStack.widgets.zohoSalesIQ' },
      { title: 'Zoom Video Conferencing', key: 'techStack.widgets.zoomVideoConferencing' },
      {
        title: 'Booked - Appointment Booking for WordPress',
        key: 'techStack.widgets.booked-AppointmentBookingForWordPress',
      },
      { title: 'Max Mega Menu', key: 'techStack.widgets.maxMegaMenu' },
      { title: 'Google Chrome Webstore Application', key: 'techStack.widgets.googleChromeWebstoreApplication' },
      { title: 'SVG Support', key: 'techStack.widgets.sVGSupport' },
      { title: 'Uploadcare', key: 'techStack.widgets.uploadcare' },
      { title: 'geoPlugin', key: 'techStack.widgets.geoPlugin' },
      { title: 'Weglot', key: 'techStack.widgets.weglot' },
      { title: 'WhatsApp Me', key: 'techStack.widgets.whatsAppMe' },
      { title: 'Intercom', key: 'techStack.widgets.intercom' },
      { title: 'CrUX Top 10m', key: 'techStack.widgets.crUXTop10m' },
      { title: 'Tumblr Buttons', key: 'techStack.widgets.tumblrButtons' },
      { title: 'COVID-19', key: 'techStack.widgets.cOVID-19' },
      { title: 'Gravity Forms', key: 'techStack.widgets.gravityForms' },
      { title: 'Yet Another Related Posts Plugin', key: 'techStack.widgets.yetAnotherRelatedPostsPlugin' },
      { title: 'Algolia', key: 'techStack.widgets.algolia' },
      { title: 'Wix FAQ', key: 'techStack.widgets.wixFAQ' },
      { title: 'Sitelinks Search Box', key: 'techStack.widgets.sitelinksSearchBox' },
      {
        title: 'Site Offline Or Coming Soon Or Maintenance Mode',
        key: 'techStack.widgets.siteOfflineOrComingSoonOrMaintenanceMode',
      },
      { title: 'Crisp', key: 'techStack.widgets.crisp' },
      { title: 'PhotoSwipe', key: 'techStack.widgets.photoSwipe' },
      { title: 'Download Monitor', key: 'techStack.widgets.downloadMonitor' },
      { title: 'DigiCert', key: 'techStack.widgets.digiCert' },
      { title: 'Sina Weibo', key: 'techStack.widgets.sinaWeibo' },
      { title: 'Visual Composer Ultimate Addons', key: 'techStack.widgets.visualComposerUltimateAddons' },
      { title: 'Login with Amazon', key: 'techStack.widgets.loginWithAmazon' },
      {
        title: 'DuracellTomi Google Tag Manager for WordPress',
        key: 'techStack.widgets.duracellTomiGoogleTagManagerForWordPress',
      },
      { title: 'Facebook Like Button', key: 'techStack.widgets.facebookLikeButton' },
      { title: 'Tally', key: 'techStack.widgets.tally' },
      { title: 'Iconfinder', key: 'techStack.widgets.iconfinder' },
      { title: 'Reamaze', key: 'techStack.widgets.reamaze' },
      { title: 'OnceHub', key: 'techStack.widgets.onceHub' },
      { title: 'involve.me', key: 'techStack.widgets.involve_Me' },
      { title: 'User Submitted Posts', key: 'techStack.widgets.userSubmittedPosts' },
      { title: 'Hubspot Messages', key: 'techStack.widgets.hubspotMessages' },
      { title: 'Zendesk Embeddables', key: 'techStack.widgets.zendeskEmbeddables' },
      { title: 'Disqus Comment System for WordPress', key: 'techStack.widgets.disqusCommentSystemForWordPress' },
      { title: 'AccessPress Social Counter', key: 'techStack.widgets.accessPressSocialCounter' },
      { title: 'McAfee Secure', key: 'techStack.widgets.mcAfeeSecure' },
      { title: 'About Cookies', key: 'techStack.widgets.aboutCookies' },
      { title: 'Header, Footer and Blocks for Elementor', key: 'techStack.widgets.header,FooterAndBlocksForElementor' },
      { title: 'Endurance Page Cache', key: 'techStack.widgets.endurancePageCache' },
      { title: 'LogRocket', key: 'techStack.widgets.logRocket' },
      { title: 'CrUX Top 50m', key: 'techStack.widgets.crUXTop50m' },
      { title: 'Ultimate Member', key: 'techStack.widgets.ultimateMember' },
      { title: 'Wix Paid Plans', key: 'techStack.widgets.wixPaidPlans' },
      { title: 'WP to Twitter', key: 'techStack.widgets.wPToTwitter' },
      { title: 'Fonts.com', key: 'techStack.widgets.fonts_Com' },
      { title: 'Google Plus One Platform', key: 'techStack.widgets.googlePlusOnePlatform' },
      { title: 'EWWW Image Optimizer', key: 'techStack.widgets.eWWWImageOptimizer' },
      { title: 'reCAPTCHA', key: 'techStack.widgets.reCAPTCHA' },
      { title: 'USP API Live', key: 'techStack.widgets.uSPAPILive' },
      { title: 'Shortcode for Current Date', key: 'techStack.widgets.shortcodeForCurrentDate' },
      { title: 'Have I Been Pwned', key: 'techStack.widgets.haveIBeenPwned' },
      { title: 'WP Automatic', key: 'techStack.widgets.wPAutomatic' },
      { title: 'LivePerson', key: 'techStack.widgets.livePerson' },
      { title: 'JustWatch', key: 'techStack.widgets.justWatch' },
      { title: 'LogMeIn', key: 'techStack.widgets.logMeIn' },
      { title: 'Google Identity Platform', key: 'techStack.widgets.googleIdentityPlatform' },
      { title: 'Yoast Plugins', key: 'techStack.widgets.yoastPlugins' },
      { title: 'Instapaper', key: 'techStack.widgets.instapaper' },
      { title: 'GoodFirms', key: 'techStack.widgets.goodFirms' },
      { title: 'AccessPress Social Share', key: 'techStack.widgets.accessPressSocialShare' },
      { title: 'Splide', key: 'techStack.widgets.splide' },
      { title: 'Freshchat', key: 'techStack.widgets.freshchat' },
      { title: 'MetaMask Blacklist', key: 'techStack.widgets.metaMaskBlacklist' },
      { title: 'Google No Translate', key: 'techStack.widgets.googleNoTranslate' },
      { title: 'Google Maps Embed', key: 'techStack.widgets.googleMapsEmbed' },
      { title: 'Digg', key: 'techStack.widgets.digg' },
      { title: 'CryptoCompare', key: 'techStack.widgets.cryptoCompare' },
      { title: 'reCAPTCHA Enterprise', key: 'techStack.widgets.reCAPTCHAEnterprise' },
      { title: 'MetaMask Whitelist', key: 'techStack.widgets.metaMaskWhitelist' },
      { title: 'CodeMirror', key: 'techStack.widgets.codeMirror' },
      { title: 'Olark', key: 'techStack.widgets.olark' },
      { title: 'GLYPHICONS', key: 'techStack.widgets.gLYPHICONS' },
      { title: 'Newsletter for Wordpress', key: 'techStack.widgets.newsletterForWordpress' },
      { title: 'W3 Total Cache', key: 'techStack.widgets.w3TotalCache' },
      { title: 'Google Translate Widget', key: 'techStack.widgets.googleTranslateWidget' },
      { title: 'PHSDL Spam Filter', key: 'techStack.widgets.pHSDLSpamFilter' },
      { title: 'Events Calendar', key: 'techStack.widgets.eventsCalendar' },
      {
        title: 'Boostify Header Footer Builder for Elementor',
        key: 'techStack.widgets.boostifyHeaderFooterBuilderForElementor',
      },
      { title: 'ThemePunch', key: 'techStack.widgets.themePunch' },
      { title: 'MailPoet', key: 'techStack.widgets.mailPoet' },
      { title: 'Tagembed', key: 'techStack.widgets.tagembed' },
      { title: 'LinkedIn Share Plugin', key: 'techStack.widgets.linkedInSharePlugin' },
      { title: 'Fonts Plugin', key: 'techStack.widgets.fontsPlugin' },
      { title: 'Filepicker.io', key: 'techStack.widgets.filepicker_Io' },
      { title: 'Pocket', key: 'techStack.widgets.pocket' },
      { title: 'Site Kit', key: 'techStack.widgets.siteKit' },
      { title: 'MailChimp for WordPress', key: 'techStack.widgets.mailChimpForWordPress' },
      { title: 'JetEngine', key: 'techStack.widgets.jetEngine' },
      { title: 'ElementsKit Elementor', key: 'techStack.widgets.elementsKitElementor' },
      { title: 'Yoast WordPress SEO Plugin', key: 'techStack.widgets.yoastWordPressSEOPlugin' },
      { title: 'Acuity Scheduling', key: 'techStack.widgets.acuityScheduling' },
      { title: 'MasterSlider', key: 'techStack.widgets.masterSlider' },
      { title: 'MonsterInsights', key: 'techStack.widgets.monsterInsights' },
      { title: 'Onfido', key: 'techStack.widgets.onfido' },
      { title: 'Disqus', key: 'techStack.widgets.disqus' },
      { title: 'Instant Search Plus', key: 'techStack.widgets.instantSearchPlus' },
      { title: 'Rollbar', key: 'techStack.widgets.rollbar' },
      { title: 'ZeroClipboard', key: 'techStack.widgets.zeroClipboard' },
      { title: 'YourAdChoices.com', key: 'techStack.widgets.yourAdChoices_Com' },
      { title: 'Wix Forms', key: 'techStack.widgets.wixForms' },
      { title: 'CrUX Dataset', key: 'techStack.widgets.crUXDataset' },
      { title: 'Axeptio', key: 'techStack.widgets.axeptio' },
      { title: 'Wix Bookings', key: 'techStack.widgets.wixBookings' },
      { title: 'reCAPTCHA v2', key: 'techStack.widgets.reCAPTCHAV2' },
      { title: 'DeviceAtlas Client Side Component', key: 'techStack.widgets.deviceAtlasClientSideComponent' },
      { title: 'AdRoll CMP System', key: 'techStack.widgets.adRollCMPSystem' },
      { title: 'Delighted', key: 'techStack.widgets.delighted' },
      { title: 'hCaptcha', key: 'techStack.widgets.hCaptcha' },
      { title: 'Astra Widgets', key: 'techStack.widgets.astraWidgets' },
      { title: 'Typekit Async', key: 'techStack.widgets.typekitAsync' },
      { title: 'goQR', key: 'techStack.widgets.goQR' },
      { title: 'OneSignal', key: 'techStack.widgets.oneSignal' },
      { title: 'Discord', key: 'techStack.widgets.discord' },
      { title: 'Facebook Comments', key: 'techStack.widgets.facebookComments' },
      { title: 'Sticky Menu and Sticky Header', key: 'techStack.widgets.stickyMenuAndStickyHeader' },
      { title: 'GetResponse', key: 'techStack.widgets.getResponse' },
      { title: 'Global Privacy Control', key: 'techStack.widgets.globalPrivacyControl' },
      { title: 'Front', key: 'techStack.widgets.front' },
      { title: 'Twitter Tweet Button', key: 'techStack.widgets.twitterTweetButton' },
      { title: 'Google Plus One Button', key: 'techStack.widgets.googlePlusOneButton' },
      { title: 'Typeform', key: 'techStack.widgets.typeform' },
      { title: 'Facebook Sharer', key: 'techStack.widgets.facebookSharer' },
      { title: 'ASP.NET Core GDPR Consent', key: 'techStack.widgets.aSP_NETCoreGDPRConsent' },
      { title: 'ShareThis CMP System', key: 'techStack.widgets.shareThisCMPSystem' },
      { title: 'SiteLock', key: 'techStack.widgets.siteLock' },
      { title: 'DMCA Badge', key: 'techStack.widgets.dMCABadge' },
      { title: 'YouTube IFrame Upload', key: 'techStack.widgets.youTubeIFrameUpload' },
      { title: 'The Events Calendar', key: 'techStack.widgets.theEventsCalendar' },
      { title: 'Stonly', key: 'techStack.widgets.stonly' },
      { title: 'US Privacy User Signal Mechanism', key: 'techStack.widgets.uSPrivacyUserSignalMechanism' },
      { title: 'Google Forms', key: 'techStack.widgets.googleForms' },
      { title: 'Linkly', key: 'techStack.widgets.linkly' },
      { title: 'myStickymenu', key: 'techStack.widgets.myStickymenu' },
      { title: 'Detectify', key: 'techStack.widgets.detectify' },
      {
        title: 'Amazon Elastic Load Balancing Sticky Session',
        key: 'techStack.widgets.amazonElasticLoadBalancingStickySession',
      },
      { title: 'Pexels', key: 'techStack.widgets.pexels' },
      { title: 'Feedly', key: 'techStack.widgets.feedly' },
      { title: 'DreamsTime', key: 'techStack.widgets.dreamsTime' },
      { title: 'WP AutoTerms', key: 'techStack.widgets.wPAutoTerms' },
      { title: 'HTTrack Website Copier', key: 'techStack.widgets.hTTrackWebsiteCopier' },
      { title: 'OoohBoi Steroids for Elementor', key: 'techStack.widgets.ooohBoiSteroidsForElementor' },
      { title: 'TermsFeed', key: 'techStack.widgets.termsFeed' },
      { title: 'Airtable', key: 'techStack.widgets.airtable' },
      { title: 'Baidu Share', key: 'techStack.widgets.baiduShare' },
      { title: 'All in One SEO Pack', key: 'techStack.widgets.allInOneSEOPack' },
      { title: 'Wordfence', key: 'techStack.widgets.wordfence' },
      { title: '99Robots HFCM', key: 'techStack.widgets.99RobotsHFCM' },
      { title: 'Gist', key: 'techStack.widgets.gist' },
      { title: 'WP Smart Preloader', key: 'techStack.widgets.wPSmartPreloader' },
      { title: 'Strong Testimonials', key: 'techStack.widgets.strongTestimonials' },
      { title: 'Clutch', key: 'techStack.widgets.clutch' },
      { title: 'Photo Gallery for WordPress', key: 'techStack.widgets.photoGalleryForWordPress' },
      { title: 'GDPR Consent Management Platform', key: 'techStack.widgets.gDPRConsentManagementPlatform' },
      { title: 'Print Friendly', key: 'techStack.widgets.printFriendly' },
      { title: 'JivoSite', key: 'techStack.widgets.jivoSite' },
      { title: 'iubenda', key: 'techStack.widgets.iubenda' },
      { title: 'Widgetkit by YOOtheme for WordPress', key: 'techStack.widgets.widgetkitByYOOthemeForWordPress' },
      { title: 'Browse Happy', key: 'techStack.widgets.browseHappy' },
      { title: 'Clearbit Logo', key: 'techStack.widgets.clearbitLogo' },
      { title: 'Autoptimize', key: 'techStack.widgets.autoptimize' },
      { title: 'Elementor Pro', key: 'techStack.widgets.elementorPro' },
      { title: 'Smart Logo Showcase Lite', key: 'techStack.widgets.smartLogoShowcaseLite' },
      { title: 'Imgur', key: 'techStack.widgets.imgur' },
      { title: 'GitHub Redirect', key: 'techStack.widgets.gitHubRedirect' },
      { title: 'Facebook Like', key: 'techStack.widgets.facebookLike' },
      { title: 'Gravatar Profiles', key: 'techStack.widgets.gravatarProfiles' },
      { title: 'Stumbleupon', key: 'techStack.widgets.stumbleupon' },
      { title: 'Add to Any', key: 'techStack.widgets.addToAny' },
      { title: 'Jetpack', key: 'techStack.widgets.jetpack' },
      { title: 'China Ministry of Public Security', key: 'techStack.widgets.chinaMinistryOfPublicSecurity' },
      { title: 'Ultimate Social Media', key: 'techStack.widgets.ultimateSocialMedia' },
      { title: 'Slaask', key: 'techStack.widgets.slaask' },
      { title: 'Twemoji', key: 'techStack.widgets.twemoji' },
      { title: 'ShareThis', key: 'techStack.widgets.shareThis' },
      { title: 'Kakao', key: 'techStack.widgets.kakao' },
      { title: 'Grow Social Pro', key: 'techStack.widgets.growSocialPro' },
      { title: 'Elementor', key: 'techStack.widgets.elementor' },
      { title: 'Element.io', key: 'techStack.widgets.element_Io' },
      { title: 'amCharts', key: 'techStack.widgets.amCharts' },
      { title: 'SiteOrigin', key: 'techStack.widgets.siteOrigin' },
      { title: 'Convertful', key: 'techStack.widgets.convertful' },
      { title: 'Facebook Facepile', key: 'techStack.widgets.facebookFacepile' },
      { title: 'Facebook Login', key: 'techStack.widgets.facebookLogin' },
      { title: 'TablePress', key: 'techStack.widgets.tablePress' },
      { title: 'Formspree', key: 'techStack.widgets.formspree' },
      { title: 'WPBakery', key: 'techStack.widgets.wPBakery' },
      { title: 'Site Booster', key: 'techStack.widgets.siteBooster' },
      { title: 'Materialis Companion', key: 'techStack.widgets.materialisCompanion' },
      { title: 'WP Job Openings', key: 'techStack.widgets.wPJobOpenings' },
      { title: 'reCAPTCHA v3', key: 'techStack.widgets.reCAPTCHAV3' },
      { title: 'Wix Pro Gallery', key: 'techStack.widgets.wixProGallery' },
      { title: 'Imperva Bot Detection and Mitigation', key: 'techStack.widgets.impervaBotDetectionAndMitigation' },
      { title: 'HubSpot Conversations', key: 'techStack.widgets.hubSpotConversations' },
      { title: 'Reddit', key: 'techStack.widgets.reddit' },
      { title: 'Cookie Consent by Osano', key: 'techStack.widgets.cookieConsentByOsano' },
      { title: 'Stocksy', key: 'techStack.widgets.stocksy' },
      { title: 'Shutterstock', key: 'techStack.widgets.shutterstock' },
      { title: 'Contact Collection', key: 'techStack.widgets.contactCollection' },
      { title: 'Essential Grid', key: 'techStack.widgets.essentialGrid' },
      {
        title: 'Asesor de Cookies RGPD para normativa europea',
        key: 'techStack.widgets.asesorDeCookiesRGPDParaNormativaEuropea',
      },
      { title: 'Sharetribe', key: 'techStack.widgets.sharetribe' },
      { title: 'Wordpress oEmbed', key: 'techStack.widgets.wordpressOEmbed' },
      { title: 'Google Calendar', key: 'techStack.widgets.googleCalendar' },
      { title: 'Under Construction for WordPress', key: 'techStack.widgets.underConstructionForWordPress' },
      { title: 'Headway', key: 'techStack.widgets.headway' },
      { title: 'Adobe Creative Cloud WebFonts', key: 'techStack.widgets.adobeCreativeCloudWebFonts' },
      { title: 'mooSocial', key: 'techStack.widgets.mooSocial' },
      { title: 'Accelerated Mobile Pages', key: 'techStack.widgets.acceleratedMobilePages' },
      { title: 'Tidio', key: 'techStack.widgets.tidio' },
      { title: 'Contact Form', key: 'techStack.widgets.contactForm' },
      { title: 'MailChimp', key: 'techStack.widgets.mailChimp' },
      { title: 'Typekit', key: 'techStack.widgets.typekit' },
      { title: 'POWr', key: 'techStack.widgets.pOWr' },
      { title: 'Wufoo', key: 'techStack.widgets.wufoo' },
      { title: 'LiveChat', key: 'techStack.widgets.liveChat' },
      { title: 'WPML Multilingual', key: 'techStack.widgets.wPMLMultilingual' },
      { title: 'CrUX Top 1m', key: 'techStack.widgets.crUXTop1m' },
      { title: 'Layer0', key: 'techStack.widgets.layer0' },
      { title: 'Material Design Icons', key: 'techStack.widgets.materialDesignIcons' },
      { title: 'GTranslate.io', key: 'techStack.widgets.gTranslate_Io' },
      { title: 'JiaThis', key: 'techStack.widgets.jiaThis' },
      { title: 'Deposit Photos', key: 'techStack.widgets.depositPhotos' },
      { title: 'Ultimate Addons for Elementor', key: 'techStack.widgets.ultimateAddonsForElementor' },
      { title: 'Essential Addons for Elementor', key: 'techStack.widgets.essentialAddonsForElementor' },
      { title: 'Smart Recent Posts Widget', key: 'techStack.widgets.smartRecentPostsWidget' },
      { title: 'WP User Frontend', key: 'techStack.widgets.wPUserFrontend' },
      { title: 'WebEx', key: 'techStack.widgets.webEx' },
      { title: 'Twitter Embedded Tweets', key: 'techStack.widgets.twitterEmbeddedTweets' },
      { title: 'WP PageNavi', key: 'techStack.widgets.wPPageNavi' },
      { title: 'Auth0', key: 'techStack.widgets.auth0' },
      { title: 'Fotorama', key: 'techStack.widgets.fotorama' },
      { title: 'IP2Location Lite', key: 'techStack.widgets.iP2LocationLite' },
      { title: 'ShortPixel Image Optimizer', key: 'techStack.widgets.shortPixelImageOptimizer' },
      { title: 'B2Chat', key: 'techStack.widgets.b2Chat' },
      { title: 'Slider Revolution', key: 'techStack.widgets.sliderRevolution' },
      { title: 'Imgix', key: 'techStack.widgets.imgix' },
      { title: 'ContentViews', key: 'techStack.widgets.contentViews' },
      { title: 'GitHub Buttons', key: 'techStack.widgets.gitHubButtons' },
      { title: 'Ionicons', key: 'techStack.widgets.ionicons' },
      { title: 'IcoMoon', key: 'techStack.widgets.icoMoon' },
      { title: 'Akismet Non Hosted', key: 'techStack.widgets.akismetNonHosted' },
      { title: 'Yieldlab CMP System', key: 'techStack.widgets.yieldlabCMPSystem' },
      { title: 'Font Awesome', key: 'techStack.widgets.fontAwesome' },
      { title: 'WPForms', key: 'techStack.widgets.wPForms' },
      { title: 'Impressive Slideshow', key: 'techStack.widgets.impressiveSlideshow' },
      { title: 'Sassy Social Share', key: 'techStack.widgets.sassySocialShare' },
      { title: 'Ninja Forms', key: 'techStack.widgets.ninjaForms' },
      { title: 'BoomTech', key: 'techStack.widgets.boomTech' },
      { title: 'Formidable Pro Form', key: 'techStack.widgets.formidableProForm' },
      { title: 'Web of Trust', key: 'techStack.widgets.webOfTrust' },
      { title: 'Google Code Prettify', key: 'techStack.widgets.googleCodePrettify' },
      { title: 'IE SiteMode', key: 'techStack.widgets.iESiteMode' },
      { title: 'CrUX Top 500k', key: 'techStack.widgets.crUXTop500k' },
      { title: 'CoinMarketCap', key: 'techStack.widgets.coinMarketCap' },
      { title: 'Bugsnag', key: 'techStack.widgets.bugsnag' },
      { title: 'Shortcodes Ultimate', key: 'techStack.widgets.shortcodesUltimate' },
      { title: 'Advanced Popups', key: 'techStack.widgets.advancedPopups' },
      { title: 'Zopim', key: 'techStack.widgets.zopim' },
      { title: 'Persona Identity', key: 'techStack.widgets.personaIdentity' },
      { title: 'CKEditor', key: 'techStack.widgets.cKEditor' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'CDN',
    key: 'techStack.Cdn',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Cdn.select' },
      { title: 'Google Cloud CDN', key: 'techStack.cdn.googleCloudCDN' },
      { title: 'AWS Cloudfront Stockholm Edge', key: 'techStack.cdn.aWSCloudfrontStockholmEdge' },
      { title: 'AWS Cloudfront Tokyo Edge', key: 'techStack.cdn.aWSCloudfrontTokyoEdge' },
      { title: 'Wix Media', key: 'techStack.cdn.wixMedia' },
      { title: 'AWS Cloudfront Los Angeles CA Edge', key: 'techStack.cdn.aWSCloudfrontLosAngelesCAEdge' },
      { title: 'GoDaddy CDN', key: 'techStack.cdn.goDaddyCDN' },
      { title: 'WordPress Grid', key: 'techStack.cdn.wordPressGrid' },
      { title: 'Staticfile CDN', key: 'techStack.cdn.staticfileCDN' },
      { title: 'BunnyCDN General', key: 'techStack.cdn.bunnyCDNGeneral' },
      { title: 'RawGit', key: 'techStack.cdn.rawGit' },
      { title: 'Cloudflare Challenge', key: 'techStack.cdn.cloudflareChallenge' },
      { title: 'OVH AnyCast', key: 'techStack.cdn.oVHAnyCast' },
      { title: 'Alibaba Cloud CDN', key: 'techStack.cdn.alibabaCloudCDN' },
      { title: 'AWS Cloudfront San Francisco CA Edge', key: 'techStack.cdn.aWSCloudfrontSanFranciscoCAEdge' },
      { title: 'BunnyCDN Header', key: 'techStack.cdn.bunnyCDNHeader' },
      { title: 'AWS Cloudfront Ashburn VA Edge', key: 'techStack.cdn.aWSCloudfrontAshburnVAEdge' },
      { title: 'Fastly CDN', key: 'techStack.cdn.fastlyCDN' },
      { title: 'BunnyCDN', key: 'techStack.cdn.bunnyCDN' },
      { title: 'Cloudflare JS', key: 'techStack.cdn.cloudflareJS' },
      { title: 'OSS CDN', key: 'techStack.cdn.oSSCDN' },
      { title: 'UNPKG', key: 'techStack.cdn.uNPKG' },
      { title: 'AJAX Libraries API', key: 'techStack.cdn.aJAXLibrariesAPI' },
      { title: 'Akamai', key: 'techStack.cdn.akamai' },
      { title: 'Vimeo CDN', key: 'techStack.cdn.vimeoCDN' },
      { title: 'Content Delivery Network', key: 'techStack.cdn.contentDeliveryNetwork' },
      { title: 'StackPath BootstrapCDN', key: 'techStack.cdn.stackPathBootstrapCDN' },
      { title: 'CloudFront', key: 'techStack.cdn.cloudFront' },
      { title: 'Microsoft Ajax Content Delivery Network', key: 'techStack.cdn.microsoftAjaxContentDeliveryNetwork' },
      { title: 'StackPath', key: 'techStack.cdn.stackPath' },
      { title: 'CDN JS', key: 'techStack.cdn.cDNJS' },
      { title: 'jQuery CDN', key: 'techStack.cdn.jQueryCDN' },
      { title: 'Cloudflare Blocked', key: 'techStack.cdn.cloudflareBlocked' },
      { title: 'AWS Cloudfront Paris Edge', key: 'techStack.cdn.aWSCloudfrontParisEdge' },
      { title: 'Amazon CloudFront', key: 'techStack.cdn.amazonCloudFront' },
      { title: 'Cloudflare Automatic Challenge', key: 'techStack.cdn.cloudflareAutomaticChallenge' },
      { title: 'AWS Cloudfront Sydney Edge', key: 'techStack.cdn.aWSCloudfrontSydneyEdge' },
      { title: 'AWS Cloudfront Taipei TW Edge', key: 'techStack.cdn.aWSCloudfrontTaipeiTWEdge' },
      { title: 'AWS Cloudfront Montréal QC Edge', key: 'techStack.cdn.aWSCloudfrontMontréAlQCEdge' },
      { title: 'Stackoverflow CDN', key: 'techStack.cdn.stackoverflowCDN' },
      { title: 'AWS Cloudfront Toronto ON Edge', key: 'techStack.cdn.aWSCloudfrontTorontoONEdge' },
      { title: 'AWS Cloudfront Salt Lake City UT Edge', key: 'techStack.cdn.aWSCloudfrontSaltLakeCityUTEdge' },
      { title: 'Google Cloud Storage', key: 'techStack.cdn.googleCloudStorage' },
      { title: 'jsDelivr', key: 'techStack.cdn.jsDelivr' },
      { title: 'AWS Cloudfront Miami FL Edge', key: 'techStack.cdn.aWSCloudfrontMiamiFLEdge' },
      { title: 'Facebook CDN', key: 'techStack.cdn.facebookCDN' },
      { title: 'Cloudflare Pages', key: 'techStack.cdn.cloudflarePages' },
      { title: 'AWS Cloudfront Helsinki Edge', key: 'techStack.cdn.aWSCloudfrontHelsinkiEdge' },
      { title: 'Cloudflare', key: 'techStack.cdn.cloudflare' },
      { title: 'GStatic Google Static Content', key: 'techStack.cdn.gStaticGoogleStaticContent' },
      { title: 'Bootcss', key: 'techStack.cdn.bootcss' },
      { title: 'Digital Ocean Spaces', key: 'techStack.cdn.digitalOceanSpaces' },
      { title: 'Yahoo Image CDN', key: 'techStack.cdn.yahooImageCDN' },
      { title: 'Cloudflare CDN', key: 'techStack.cdn.cloudflareCDN' },
      { title: 'AWS Cloudfront Frankfurt Edge', key: 'techStack.cdn.aWSCloudfrontFrankfurtEdge' },
      { title: 'Highwinds CDN', key: 'techStack.cdn.highwindsCDN' },
      { title: 'Cloudinary', key: 'techStack.cdn.cloudinary' },
      { title: 'Twitter CDN', key: 'techStack.cdn.twitterCDN' },
      { title: 'Amazon S3', key: 'techStack.cdn.amazonS3' },
      { title: 'Amazon S3 CDN', key: 'techStack.cdn.amazonS3CDN' },
      { title: 'Microsoft Azure Blob Storage', key: 'techStack.cdn.microsoftAzureBlobStorage' },
      { title: 'AWS Cloudfront Seattle WA Edge', key: 'techStack.cdn.aWSCloudfrontSeattleWAEdge' },
      { title: 'Azure Edge', key: 'techStack.cdn.azureEdge' },
      { title: 'OptiMole', key: 'techStack.cdn.optiMole' },
      { title: 'Akamai Edge', key: 'techStack.cdn.akamaiEdge' },
      { title: 'AWS Cloudfront Hong Kong Edge', key: 'techStack.cdn.aWSCloudfrontHongKongEdge' },
      { title: 'AWS Cloudfront Chicago IL Edge', key: 'techStack.cdn.aWSCloudfrontChicagoILEdge' },
      { title: 'Microsoft Azure CDN', key: 'techStack.cdn.microsoftAzureCDN' },
      { title: 'Level3', key: 'techStack.cdn.level3' },
      { title: 'BootstrapCDN', key: 'techStack.cdn.bootstrapCDN' },
      { title: 'ScaleWay', key: 'techStack.cdn.scaleWay' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Framework',
    key: 'techStack.Framework',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Framework.select' },
      { title: 'Angular 4.2', key: 'techStack.framework.angular4_2' },
      { title: 'Pusher', key: 'techStack.framework.pusher' },
      { title: 'EasyUI', key: 'techStack.framework.easyUI' },
      { title: 'Remix', key: 'techStack.framework.remix' },
      { title: 'GlobalSign Domain Verification', key: 'techStack.framework.globalSignDomainVerification' },
      { title: 'SoftwareApplication Schema', key: 'techStack.framework.softwareApplicationSchema' },
      { title: 'Nuxt.js', key: 'techStack.framework.nuxt_Js' },
      { title: 'JobPosting Schema', key: 'techStack.framework.jobPostingSchema' },
      { title: 'Mantine', key: 'techStack.framework.mantine' },
      { title: 'Event Schema', key: 'techStack.framework.eventSchema' },
      { title: 'Local Business', key: 'techStack.framework.localBusiness' },
      { title: 'Twenty Thirteen', key: 'techStack.framework.twentyThirteen' },
      { title: 'Laravel Livewire', key: 'techStack.framework.laravelLivewire' },
      { title: 'Microsoft Frontpage', key: 'techStack.framework.microsoftFrontpage' },
      { title: 'Angular Material', key: 'techStack.framework.angularMaterial' },
      { title: 'Avada', key: 'techStack.framework.avada' },
      { title: 'Python 3.6', key: 'techStack.framework.python3_6' },
      { title: 'Bug Bounty', key: 'techStack.framework.bugBounty' },
      { title: 'Engintron', key: 'techStack.framework.engintron' },
      { title: 'Organization Schema', key: 'techStack.framework.organizationSchema' },
      { title: 'ASP.NET', key: 'techStack.framework.aSP_NET' },
      { title: 'NewsMediaOrganization Schema', key: 'techStack.framework.newsMediaOrganizationSchema' },
      { title: 'Replit', key: 'techStack.framework.replit' },
      { title: 'ASP.NET Ajax', key: 'techStack.framework.aSP_NETAjax' },
      { title: 'Django CSRF', key: 'techStack.framework.djangoCSRF' },
      { title: 'ASP.NET Core', key: 'techStack.framework.aSP_NETCore' },
      { title: 'Adobe Dreamweaver', key: 'techStack.framework.adobeDreamweaver' },
      { title: 'Frappe', key: 'techStack.framework.frappe' },
      { title: 'CodeIgniter', key: 'techStack.framework.codeIgniter' },
      { title: 'Parcel', key: 'techStack.framework.parcel' },
      { title: 'Subversion', key: 'techStack.framework.subversion' },
      { title: 'Twenty Twenty-Two', key: 'techStack.framework.twentyTwenty-Two' },
      { title: 'ASP.NET 2.0', key: 'techStack.framework.aSP_NET2_0' },
      { title: 'DevExtreme', key: 'techStack.framework.devExtreme' },
      { title: 'Sucuri Cloudproxy', key: 'techStack.framework.sucuriCloudproxy' },
      { title: 'IPFS', key: 'techStack.framework.iPFS' },
      { title: 'GeneratePress', key: 'techStack.framework.generatePress' },
      { title: 'ElementUI', key: 'techStack.framework.elementUI' },
      { title: 'Centmin Mod', key: 'techStack.framework.centminMod' },
      { title: 'FinancialService Schema', key: 'techStack.framework.financialServiceSchema' },
      { title: 'Ruby on Rails Token', key: 'techStack.framework.rubyOnRailsToken' },
      { title: 'Twenty Seventeen', key: 'techStack.framework.twentySeventeen' },
      { title: 'Materialize CSS', key: 'techStack.framework.materializeCSS' },
      { title: 'Product Schema', key: 'techStack.framework.productSchema' },
      { title: 'Onion Location', key: 'techStack.framework.onionLocation' },
      { title: 'Gatsby JS', key: 'techStack.framework.gatsbyJS' },
      { title: 'Milligram', key: 'techStack.framework.milligram' },
      { title: 'ASP.NET 4.0', key: 'techStack.framework.aSP_NET4_0' },
      { title: 'Hello Elementor', key: 'techStack.framework.helloElementor' },
      { title: 'ProfessionalService Schema', key: 'techStack.framework.professionalServiceSchema' },
      { title: 'Twenty Nineteen', key: 'techStack.framework.twentyNineteen' },
      { title: 'OpeningHoursSpecification Schema', key: 'techStack.framework.openingHoursSpecificationSchema' },
      { title: 'Express', key: 'techStack.framework.express' },
      { title: 'VitePress', key: 'techStack.framework.vitePress' },
      { title: 'Colorlib', key: 'techStack.framework.colorlib' },
      { title: 'AdonisJs', key: 'techStack.framework.adonisJs' },
      { title: 'ASP.NET Web Pages', key: 'techStack.framework.aSP_NETWebPages' },
      { title: 'W3layouts', key: 'techStack.framework.w3layouts' },
      { title: 'EducationalOrganization Schema', key: 'techStack.framework.educationalOrganizationSchema' },
      { title: 'Foundation', key: 'techStack.framework.foundation' },
      { title: 'Twenty Fourteen', key: 'techStack.framework.twentyFourteen' },
      { title: 'DDoS-GUARD', key: 'techStack.framework.dDoS-GUARD' },
      { title: 'PHP 7', key: 'techStack.framework.pHP7' },
      { title: 'CakePHP', key: 'techStack.framework.cakePHP' },
      { title: 'Chakra UI', key: 'techStack.framework.chakraUI' },
      { title: 'Visual Studio', key: 'techStack.framework.visualStudio' },
      { title: 'CoreUI', key: 'techStack.framework.coreUI' },
      { title: 'WP Bootstrap Starter', key: 'techStack.framework.wPBootstrapStarter' },
      { title: 'DAV', key: 'techStack.framework.dAV' },
      { title: 'Twenty Twenty-One', key: 'techStack.framework.twentyTwenty-One' },
      { title: 'Offer Schema', key: 'techStack.framework.offerSchema' },
      { title: 'Laravel', key: 'techStack.framework.laravel' },
      { title: 'Classic ASP', key: 'techStack.framework.classicASP' },
      { title: 'Twenty Twelve', key: 'techStack.framework.twentyTwelve' },
      { title: 'LocalBusiness Schema', key: 'techStack.framework.localBusinessSchema' },
      { title: 'Twenty Twenty-Three', key: 'techStack.framework.twentyTwenty-Three' },
      { title: 'Placehold IT', key: 'techStack.framework.placeholdIT' },
      { title: 'Twenty Twenty', key: 'techStack.framework.twentyTwenty' },
      { title: 'Place Schema', key: 'techStack.framework.placeSchema' },
      { title: 'Inertia.js', key: 'techStack.framework.inertia_Js' },
      { title: 'Review Schema', key: 'techStack.framework.reviewSchema' },
      { title: 'Rating Schema', key: 'techStack.framework.ratingSchema' },
      { title: 'AggregateRating Schema', key: 'techStack.framework.aggregateRatingSchema' },
      { title: 'Astra Theme', key: 'techStack.framework.astraTheme' },
      { title: 'Next.js', key: 'techStack.framework.next_Js' },
      { title: 'Amazon API Gateway', key: 'techStack.framework.amazonAPIGateway' },
      { title: 'Firebase', key: 'techStack.framework.firebase' },
      { title: 'Sapper', key: 'techStack.framework.sapper' },
      { title: 'Telerik Controls', key: 'techStack.framework.telerikControls' },
      { title: 'ASP.NET MVC', key: 'techStack.framework.aSP_NETMVC' },
      { title: 'Twenty Eleven', key: 'techStack.framework.twentyEleven' },
      { title: 'Thumbor', key: 'techStack.framework.thumbor' },
      { title: 'Brand Schema', key: 'techStack.framework.brandSchema' },
      { title: 'Corporation Schema', key: 'techStack.framework.corporationSchema' },
      { title: 'Kubernetes', key: 'techStack.framework.kubernetes' },
      { title: 'Thing Schema', key: 'techStack.framework.thingSchema' },
      { title: 'PHP', key: 'techStack.framework.pHP' },
      { title: 'Adobe Enterprise Cloud', key: 'techStack.framework.adobeEnterpriseCloud' },
      { title: 'WebMatrix', key: 'techStack.framework.webMatrix' },
      { title: 'Ruby on Rails', key: 'techStack.framework.rubyOnRails' },
      { title: 'Transportex', key: 'techStack.framework.transportex' },
      { title: 'Python 3.7', key: 'techStack.framework.python3_7' },
      { title: 'Service Schema', key: 'techStack.framework.serviceSchema' },
      { title: 'Shockwave Flash Embed', key: 'techStack.framework.shockwaveFlashEmbed' },
      { title: 'Flutter', key: 'techStack.framework.flutter' },
      { title: 'Headless UI', key: 'techStack.framework.headlessUI' },
      { title: 'CreativeWork Schema', key: 'techStack.framework.creativeWorkSchema' },
      { title: 'ContactPoint Schema', key: 'techStack.framework.contactPointSchema' },
      { title: 'Bulma', key: 'techStack.framework.bulma' },
      { title: 'Tailwind CSS', key: 'techStack.framework.tailwindCSS' },
      { title: 'Salient', key: 'techStack.framework.salient' },
      { title: 'bubble', key: 'techStack.framework.bubble' },
      { title: 'UmiJS', key: 'techStack.framework.umiJS' },
      { title: 'Angular', key: 'techStack.framework.angular' },
      { title: 'Apollo GraphQL', key: 'techStack.framework.apolloGraphQL' },
      { title: 'AggregateOffer Schema', key: 'techStack.framework.aggregateOfferSchema' },
      { title: 'Java EE', key: 'techStack.framework.javaEE' },
      { title: 'Google Cloud Functions', key: 'techStack.framework.googleCloudFunctions' },
      { title: 'Newspaper', key: 'techStack.framework.newspaper' },
      { title: 'Python', key: 'techStack.framework.python' },
      { title: 'Blocksy', key: 'techStack.framework.blocksy' },
      { title: 'Akamai Bot Manager', key: 'techStack.framework.akamaiBotManager' },
      { title: 'Create React App', key: 'techStack.framework.createReactApp' },
      { title: 'Slackin', key: 'techStack.framework.slackin' },
      { title: 'Material-UI', key: 'techStack.framework.material-UI' },
      { title: 'Heroku Vegur Proxy', key: 'techStack.framework.herokuVegurProxy' },
      { title: 'Tornado', key: 'techStack.framework.tornado' },
      { title: 'Twenty Fifteen', key: 'techStack.framework.twentyFifteen' },
      { title: 'Meteor', key: 'techStack.framework.meteor' },
      { title: 'Batman.js', key: 'techStack.framework.batman_Js' },
      { title: 'PostalAddress Schema', key: 'techStack.framework.postalAddressSchema' },
      { title: 'Kibana', key: 'techStack.framework.kibana' },
      { title: 'Svelte', key: 'techStack.framework.svelte' },
      { title: 'OceanWP', key: 'techStack.framework.oceanWP' },
      { title: 'Semantic UI', key: 'techStack.framework.semanticUI' },
      { title: 'Blazor', key: 'techStack.framework.blazor' },
      { title: 'Facebook Domain Verification', key: 'techStack.framework.facebookDomainVerification' },
      { title: 'Prefix Free', key: 'techStack.framework.prefixFree' },
      { title: 'Styled Components', key: 'techStack.framework.styledComponents' },
      { title: 'Dart', key: 'techStack.framework.dart' },
      { title: 'ColdFusion Mark-up Language (CFML)', key: 'techStack.framework.coldFusionMark-UpLanguage(CFML)' },
      { title: 'GeoCoordinates Schema', key: 'techStack.framework.geoCoordinatesSchema' },
      { title: 'LottieFiles', key: 'techStack.framework.lottieFiles' },
      { title: 'GeneratePress Company', key: 'techStack.framework.generatePressCompany' },
      { title: 'Person Schema', key: 'techStack.framework.personSchema' },
      { title: 'Perl', key: 'techStack.framework.perl' },
      { title: 'Divi', key: 'techStack.framework.divi' },
      { title: 'Phoenix Framework', key: 'techStack.framework.phoenixFramework' },
      { title: 'SailsJS', key: 'techStack.framework.sailsJS' },
      { title: 'DevExpress ASP.NET', key: 'techStack.framework.devExpressASP_NET' },
      { title: 'Theme In WP', key: 'techStack.framework.themeInWP' },
      { title: 'Vesta Control Panel', key: 'techStack.framework.vestaControlPanel' },
      { title: 'OpenResty', key: 'techStack.framework.openResty' },
      { title: 'Tachyons', key: 'techStack.framework.tachyons' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Mapping',
    key: 'techStack.Mapping',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Mapping.select' },
      { title: 'ArcGIS', key: 'techStack.mapping.arcGIS' },
      { title: 'Leaflet JS', key: 'techStack.mapping.leafletJS' },
      { title: 'MaxMind', key: 'techStack.mapping.maxMind' },
      { title: 'Google Places Library', key: 'techStack.mapping.googlePlacesLibrary' },
      { title: 'Baidu Maps', key: 'techStack.mapping.baiduMaps' },
      { title: 'MapTiler', key: 'techStack.mapping.mapTiler' },
      { title: 'Google Maps API', key: 'techStack.mapping.googleMapsAPI' },
      { title: 'Navigator GeoLocation', key: 'techStack.mapping.navigatorGeoLocation' },
      { title: 'Mapbox', key: 'techStack.mapping.mapbox' },
      { title: 'Google Maps', key: 'techStack.mapping.googleMaps' },
      { title: 'Leaflet', key: 'techStack.mapping.leaflet' },
      { title: 'Amap', key: 'techStack.mapping.amap' },
      { title: 'Google Maps Engine', key: 'techStack.mapping.googleMapsEngine' },
      { title: 'OpenStreetMap', key: 'techStack.mapping.openStreetMap' },
      { title: 'gMap', key: 'techStack.mapping.gMap' },
      { title: 'Google Maps for Work', key: 'techStack.mapping.googleMapsForWork' },
      { title: 'Naver Maps', key: 'techStack.mapping.naverMaps' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Media',
    key: 'techStack.Media',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Media.select' },
      { title: 'SoundCloud', key: 'techStack.media.soundCloud' },
      { title: 'VideoJS', key: 'techStack.media.videoJS' },
      { title: 'Zoom', key: 'techStack.media.zoom' },
      { title: 'YouTube', key: 'techStack.media.youTube' },
      { title: 'jPlayer', key: 'techStack.media.jPlayer' },
      { title: 'Mux', key: 'techStack.media.mux' },
      { title: 'YouTube IFrame API', key: 'techStack.media.youTubeIFrameAPI' },
      { title: 'MediaElement.js', key: 'techStack.media.mediaElement_Js' },
      { title: 'Loom', key: 'techStack.media.loom' },
      { title: 'YouTube Privacy Enchanced', key: 'techStack.media.youTubePrivacyEnchanced' },
      { title: 'Podbean', key: 'techStack.media.podbean' },
      { title: 'JW Player Platform', key: 'techStack.media.jWPlayerPlatform' },
      { title: 'Vimeo', key: 'techStack.media.vimeo' },
      { title: 'Buzzsprout', key: 'techStack.media.buzzsprout' },
      { title: 'Twitch', key: 'techStack.media.twitch' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Mobile',
    key: 'techStack.Mobile',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Mobile.select' },
      { title: 'Apple Mobile Web App Capable', key: 'techStack.mobile.appleMobileWebAppCapable' },
      { title: 'Mobile Non Scaleable Content', key: 'techStack.mobile.mobileNonScaleableContent' },
      { title: 'Mobile Optimized', key: 'techStack.mobile.mobileOptimized' },
      { title: 'Android Alternative', key: 'techStack.mobile.androidAlternative' },
      { title: 'Twitter App Card', key: 'techStack.mobile.twitterAppCard' },
      { title: 'Apple Mobile Web App Status Bar Style', key: 'techStack.mobile.appleMobileWebAppStatusBarStyle' },
      { title: 'Viewport Meta', key: 'techStack.mobile.viewportMeta' },
      { title: 'Apple Mobile Web Clips Icon', key: 'techStack.mobile.appleMobileWebClipsIcon' },
      { title: 'Apple Mobile Web Clips Startup', key: 'techStack.mobile.appleMobileWebClipsStartup' },
      { title: 'Twitter App Card iPad', key: 'techStack.mobile.twitterAppCardIPad' },
      { title: 'IPhone / Mobile Compatible', key: 'techStack.mobile.iPhone/MobileCompatible' },
      { title: 'Twitter App Card iPhone', key: 'techStack.mobile.twitterAppCardIPhone' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'MX',
    key: 'techStack.Mx',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Mx.select' },
      { title: 'Newsletter2Go', key: 'techStack.mx.newsletter2Go' },
      { title: 'Revue', key: 'techStack.mx.revue' },
      { title: 'pair Networks', key: 'techStack.mx.pairNetworks' },
      { title: 'GoDaddy Email', key: 'techStack.mx.goDaddyEmail' },
      { title: 'Paubox', key: 'techStack.mx.paubox' },
      { title: 'Hostgator Mail', key: 'techStack.mx.hostgatorMail' },
      { title: 'Alibaba Cloud DirectMail', key: 'techStack.mx.alibabaCloudDirectMail' },
      { title: 'Microsoft Exchange Online', key: 'techStack.mx.microsoftExchangeOnline' },
      { title: 'FastMail', key: 'techStack.mx.fastMail' },
      { title: 'AppRiver', key: 'techStack.mx.appRiver' },
      { title: 'Elastic Email', key: 'techStack.mx.elasticEmail' },
      { title: 'Shopify Hosted Email', key: 'techStack.mx.shopifyHostedEmail' },
      { title: 'Amazon SES', key: 'techStack.mx.amazonSES' },
      { title: 'SendinBlue', key: 'techStack.mx.sendinBlue' },
      { title: 'Mimecast', key: 'techStack.mx.mimecast' },
      { title: 'Namecheap', key: 'techStack.mx.namecheap' },
      { title: 'ImprovMX', key: 'techStack.mx.improvMX' },
      { title: 'Office 365 Mail', key: 'techStack.mx.office365Mail' },
      { title: 'MailChannels', key: 'techStack.mx.mailChannels' },
      { title: 'Zoho Mail', key: 'techStack.mx.zohoMail' },
      { title: 'Tucows Inc', key: 'techStack.mx.tucowsInc' },
      { title: 'Autopilot SPF', key: 'techStack.mx.autopilotSPF' },
      { title: 'MailChimp SPF', key: 'techStack.mx.mailChimpSPF' },
      { title: 'LINE WORKS', key: 'techStack.mx.lINEWORKS' },
      { title: 'OVH Mail', key: 'techStack.mx.oVHMail' },
      { title: 'Sender ID', key: 'techStack.mx.senderID' },
      { title: 'lemlist', key: 'techStack.mx.lemlist' },
      { title: 'SendCloud', key: 'techStack.mx.sendCloud' },
      { title: 'Microsoft Azure DNS', key: 'techStack.mx.microsoftAzureDNS' },
      { title: 'DreamHost', key: 'techStack.mx.dreamHost' },
      { title: 'ForwardEmail', key: 'techStack.mx.forwardEmail' },
      { title: 'MXRoute', key: 'techStack.mx.mXRoute' },
      { title: 'CodeTwo', key: 'techStack.mx.codeTwo' },
      { title: 'Campaign Monitor', key: 'techStack.mx.campaignMonitor' },
      { title: 'Namecheap Private Email', key: 'techStack.mx.namecheapPrivateEmail' },
      { title: 'Zendesk', key: 'techStack.mx.zendesk' },
      { title: 'Google Apps for Business', key: 'techStack.mx.googleAppsForBusiness' },
      { title: 'PowWeb Mail', key: 'techStack.mx.powWebMail' },
      { title: 'MailJet', key: 'techStack.mx.mailJet' },
      { title: 'easyDNS Mail', key: 'techStack.mx.easyDNSMail' },
      { title: 'Migadu', key: 'techStack.mx.migadu' },
      { title: 'Infomaniak Mail', key: 'techStack.mx.infomaniakMail' },
      { title: 'Exclaimer', key: 'techStack.mx.exclaimer' },
      { title: 'CDNS Network', key: 'techStack.mx.cDNSNetwork' },
      { title: 'Rackspace Email Hosting', key: 'techStack.mx.rackspaceEmailHosting' },
      { title: 'Pardot Mail', key: 'techStack.mx.pardotMail' },
      { title: 'Apple iCloud Mail', key: 'techStack.mx.appleICloudMail' },
      { title: 'Sendy', key: 'techStack.mx.sendy' },
      { title: 'UniSender', key: 'techStack.mx.uniSender' },
      { title: 'Sendgrid', key: 'techStack.mx.sendgrid' },
      { title: 'Proofpoint', key: 'techStack.mx.proofpoint' },
      { title: 'DKIM', key: 'techStack.mx.dKIM' },
      { title: 'BigCommerce Mail', key: 'techStack.mx.bigCommerceMail' },
      { title: 'Yahoo! Business Email', key: 'techStack.mx.yahoo!BusinessEmail' },
      { title: 'Mandrill', key: 'techStack.mx.mandrill' },
      { title: 'Mailgun', key: 'techStack.mx.mailgun' },
      { title: 'Constant Contact Mail', key: 'techStack.mx.constantContactMail' },
      { title: 'Salesforce SPF', key: 'techStack.mx.salesforceSPF' },
      { title: 'SPF', key: 'techStack.mx.sPF' },
      { title: 'ProtonMail', key: 'techStack.mx.protonMail' },
      { title: 'Help Scout', key: 'techStack.mx.helpScout' },
      { title: 'Sender', key: 'techStack.mx.sender' },
      { title: 'Kolab Now', key: 'techStack.mx.kolabNow' },
      { title: '1and1 Email Solutions', key: 'techStack.mx.1and1EmailSolutions' },
      { title: 'EasyDMARC', key: 'techStack.mx.easyDMARC' },
      { title: 'Cisco Ironport Cloud', key: 'techStack.mx.ciscoIronportCloud' },
      { title: 'Alibaba Cloud MailBox', key: 'techStack.mx.alibabaCloudMailBox' },
      { title: 'DMARC Analyzer', key: 'techStack.mx.dMARCAnalyzer' },
      { title: 'SMTP.com', key: 'techStack.mx.sMTP_Com' },
      { title: 'BlueHost Mail', key: 'techStack.mx.blueHostMail' },
      { title: 'Tencent QQ Mail', key: 'techStack.mx.tencentQQMail' },
      { title: 'Flockmail', key: 'techStack.mx.flockmail' },
      { title: 'Natro Mail', key: 'techStack.mx.natroMail' },
      { title: 'Freshdesk', key: 'techStack.mx.freshdesk' },
      { title: 'DMARC', key: 'techStack.mx.dMARC' },
      { title: 'Postmark', key: 'techStack.mx.postmark' },
      { title: 'Mittwald Email', key: 'techStack.mx.mittwaldEmail' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Payment',
    key: 'techStack.Payment',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Payment.select' },
      { title: 'Bancontact', key: 'techStack.payment.bancontact' },
      { title: 'Stripe', key: 'techStack.payment.stripe' },
      { title: 'MasterCard', key: 'techStack.payment.masterCard' },
      { title: 'Maestro', key: 'techStack.payment.maestro' },
      { title: 'Omise', key: 'techStack.payment.omise' },
      { title: 'ethers.js', key: 'techStack.payment.ethers_Js' },
      { title: 'Wyre', key: 'techStack.payment.wyre' },
      { title: 'Stripe v3', key: 'techStack.payment.stripeV3' },
      { title: 'Plaid', key: 'techStack.payment.plaid' },
      { title: 'Coinbase Commerce', key: 'techStack.payment.coinbaseCommerce' },
      { title: 'PayPal Button', key: 'techStack.payment.payPalButton' },
      { title: 'American Express', key: 'techStack.payment.americanExpress' },
      { title: 'Pound Sterling', key: 'techStack.payment.poundSterling' },
      { title: 'PayPal JavaScript SDK', key: 'techStack.payment.payPalJavaScriptSDK' },
      { title: 'Shopify Pay', key: 'techStack.payment.shopifyPay' },
      { title: 'Stripe Checkout', key: 'techStack.payment.stripeCheckout' },
      { title: 'PayPal Express Checkout', key: 'techStack.payment.payPalExpressCheckout' },
      { title: 'Metadium', key: 'techStack.payment.metadium' },
      { title: 'Venmo', key: 'techStack.payment.venmo' },
      { title: 'CoinGecko', key: 'techStack.payment.coinGecko' },
      { title: 'Harmony One', key: 'techStack.payment.harmonyOne' },
      { title: 'Euro', key: 'techStack.payment.euro' },
      { title: 'Braintree', key: 'techStack.payment.braintree' },
      { title: 'Checkout.com', key: 'techStack.payment.checkout_Com' },
      { title: 'Klarna', key: 'techStack.payment.klarna' },
      { title: 'Japanese Yen', key: 'techStack.payment.japaneseYen' },
      { title: 'Uniswap', key: 'techStack.payment.uniswap' },
      { title: 'PayPal', key: 'techStack.payment.payPal' },
      { title: 'Coinbase', key: 'techStack.payment.coinbase' },
      { title: 'Google Pay', key: 'techStack.payment.googlePay' },
      { title: 'CoinCap.io', key: 'techStack.payment.coinCap_Io' },
      { title: 'Apple Pay', key: 'techStack.payment.applePay' },
      { title: 'Visa', key: 'techStack.payment.visa' },
      { title: 'PayPal Checkout', key: 'techStack.payment.payPalCheckout' },
      { title: '1inch', key: 'techStack.payment.1inch' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'SEO',
    key: 'techStack.Seo_headers',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Seo_headers.select' },
      { title: 'SEO_H2', key: 'techStack.seo_headers.sEO_H2' },
      { title: 'SEO_H1', key: 'techStack.seo_headers.sEO_H1' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Server',
    key: 'techStack.Server',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Server.select' },
      { title: 'QUIC', key: 'techStack.server.qUIC' },
      { title: 'Debian', key: 'techStack.server.debian' },
      { title: 'Limiter Modules', key: 'techStack.server.limiterModules' },
      { title: 'OpenSSL 1.0.1', key: 'techStack.server.openSSL1_0_1' },
      { title: 'mod_pagespeed', key: 'techStack.server.mod_pagespeed' },
      { title: 'Parallels Plesk Panel', key: 'techStack.server.parallelsPleskPanel' },
      { title: 'Ubuntu', key: 'techStack.server.ubuntu' },
      { title: 'OpenSSL', key: 'techStack.server.openSSL' },
      { title: 'Envoy', key: 'techStack.server.envoy' },
      { title: 'mod_ssl', key: 'techStack.server.mod_ssl' },
      { title: 'CentOS', key: 'techStack.server.centOS' },
      { title: 'Unix', key: 'techStack.server.unix' },
      { title: 'IPv6', key: 'techStack.server.iPv6' },
      { title: 'GitHub Hosting', key: 'techStack.server.gitHubHosting' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Shop',
    key: 'techStack.Shop',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Shop.select' },
      { title: 'Squarespace Add to Cart', key: 'techStack.shop.squarespaceAddToCart' },
      { title: 'osCommerce', key: 'techStack.shop.osCommerce' },
      { title: 'Magento', key: 'techStack.shop.magento' },
      { title: 'OpenCart', key: 'techStack.shop.openCart' },
      { title: 'WooCommerce 2.2', key: 'techStack.shop.wooCommerce2_2' },
      { title: 'Wix Stores', key: 'techStack.shop.wixStores' },
      { title: 'Shopify Blog', key: 'techStack.shop.shopifyBlog' },
      { title: 'Magento 2 Community', key: 'techStack.shop.magento2Community' },
      { title: 'WooCommerce 3.6', key: 'techStack.shop.wooCommerce3_6' },
      { title: 'WooCommerce Add To Cart', key: 'techStack.shop.wooCommerceAddToCart' },
      { title: '10 WooCommerce Products', key: 'techStack.shop.10WooCommerceProducts' },
      { title: 'Squarespace Light Cart', key: 'techStack.shop.squarespaceLightCart' },
      { title: 'WooCommerce 6.7', key: 'techStack.shop.wooCommerce6_7' },
      { title: 'WooCommerce 4.5', key: 'techStack.shop.wooCommerce4_5' },
      { title: 'Shipping to over 200 Countries', key: 'techStack.shop.shippingToOver200Countries' },
      { title: 'US Shipping Only', key: 'techStack.shop.uSShippingOnly' },
      { title: 'Teespring', key: 'techStack.shop.teespring' },
      { title: 'WooCommerce Checkout', key: 'techStack.shop.wooCommerceCheckout' },
      { title: 'Shipping to over 100 Countries', key: 'techStack.shop.shippingToOver100Countries' },
      { title: 'Shopify Coming Soon', key: 'techStack.shop.shopifyComingSoon' },
      { title: 'Shopify Custom Theme', key: 'techStack.shop.shopifyCustomTheme' },
      { title: 'Shopify Gift Cards', key: 'techStack.shop.shopifyGiftCards' },
      { title: 'Shopify Taste Theme', key: 'techStack.shop.shopifyTasteTheme' },
      { title: 'Shopify Discounts', key: 'techStack.shop.shopifyDiscounts' },
      { title: 'WooCommerce Follow-Ups', key: 'techStack.shop.wooCommerceFollow-Ups' },
      { title: 'Ships to China', key: 'techStack.shop.shipsToChina' },
      { title: 'Magento 2', key: 'techStack.shop.magento2' },
      { title: 'WooCommerce 6.5', key: 'techStack.shop.wooCommerce6_5' },
      { title: 'Shopify US Dollar', key: 'techStack.shop.shopifyUSDollar' },
      { title: 'Shopify Dormant', key: 'techStack.shop.shopifyDormant' },
      { title: 'Drupal Commerce', key: 'techStack.shop.drupalCommerce' },
      { title: 'Product Add-Ons by WooCommerce', key: 'techStack.shop.productAdd-OnsByWooCommerce' },
      { title: 'WooCommerce 5.5', key: 'techStack.shop.wooCommerce5_5' },
      { title: 'Scandinavian Shipping', key: 'techStack.shop.scandinavianShipping' },
      { title: 'Magento 2.2', key: 'techStack.shop.magento2_2' },
      { title: 'Cart Functionality', key: 'techStack.shop.cartFunctionality' },
      { title: 'Shopify Product Reviews', key: 'techStack.shop.shopifyProductReviews' },
      { title: 'USA and Canada Shipping', key: 'techStack.shop.uSAAndCanadaShipping' },
      { title: 'Shopify', key: 'techStack.shop.shopify' },
      { title: 'Ecwid', key: 'techStack.shop.ecwid' },
      { title: 'WooCommerce', key: 'techStack.shop.wooCommerce' },
      { title: 'Shopify Conversions', key: 'techStack.shop.shopifyConversions' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'SSL',
    key: 'techStack.Ssl',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Ssl.select' },
      { title: 'LetsEncrypt', key: 'techStack.ssl.letsEncrypt' },
      { title: 'Sectigo Domain SSL', key: 'techStack.ssl.sectigoDomainSSL' },
      { title: 'Invalid Certificate Dates', key: 'techStack.ssl.invalidCertificateDates' },
      { title: 'EssentialSSL', key: 'techStack.ssl.essentialSSL' },
      { title: 'Comodo EV Multi-Domain', key: 'techStack.ssl.comodoEVMulti-Domain' },
      { title: 'Google SSL', key: 'techStack.ssl.googleSSL' },
      { title: 'Sectigo SSL', key: 'techStack.ssl.sectigoSSL' },
      { title: 'Comodo InstantSSL', key: 'techStack.ssl.comodoInstantSSL' },
      { title: 'DreamHost SSL', key: 'techStack.ssl.dreamHostSSL' },
      { title: 'CERTUM', key: 'techStack.ssl.cERTUM' },
      { title: 'GoGetSSL', key: 'techStack.ssl.goGetSSL' },
      { title: 'Comodo SSL', key: 'techStack.ssl.comodoSSL' },
      { title: 'GlobalSign', key: 'techStack.ssl.globalSign' },
      { title: 'SwissSign', key: 'techStack.ssl.swissSign' },
      { title: 'AlphaSSL', key: 'techStack.ssl.alphaSSL' },
      { title: 'Cloudflare SSL', key: 'techStack.ssl.cloudflareSSL' },
      { title: 'TrustAsia', key: 'techStack.ssl.trustAsia' },
      { title: 'USERTrust', key: 'techStack.ssl.uSERTrust' },
      { title: 'HSTS', key: 'techStack.ssl.hSTS' },
      { title: 'RapidSSL', key: 'techStack.ssl.rapidSSL' },
      { title: 'SSL by Default', key: 'techStack.ssl.sSLByDefault' },
      { title: 'Entrust SSL', key: 'techStack.ssl.entrustSSL' },
      { title: 'GeoTrust SSL', key: 'techStack.ssl.geoTrustSSL' },
      { title: 'Amazon SSL', key: 'techStack.ssl.amazonSSL' },
      { title: 'Thawte SSL', key: 'techStack.ssl.thawteSSL' },
      { title: 'CPanel SSL', key: 'techStack.ssl.cPanelSSL' },
      { title: 'Starfield Technologies', key: 'techStack.ssl.starfieldTechnologies' },
      { title: 'DigiCert SSL', key: 'techStack.ssl.digiCertSSL' },
      { title: 'Entrust EV', key: 'techStack.ssl.entrustEV' },
      { title: 'GoDaddy SSL', key: 'techStack.ssl.goDaddySSL' },
      { title: 'HSTS IncludeSubdomains PreLoad', key: 'techStack.ssl.hSTSIncludeSubdomainsPreLoad' },
      { title: 'Encryption Everywhere', key: 'techStack.ssl.encryptionEverywhere' },
      { title: 'Symantec Secure Site', key: 'techStack.ssl.symantecSecureSite' },
      { title: 'Common Name Invalid', key: 'techStack.ssl.commonNameInvalid' },
      { title: 'Network Solutions SSL', key: 'techStack.ssl.networkSolutionsSSL' },
      { title: 'Comodo Essential SSL WildCard', key: 'techStack.ssl.comodoEssentialSSLWildCard' },
      { title: 'GeoTrust EV', key: 'techStack.ssl.geoTrustEV' },
      { title: 'Sectigo Organization SSL', key: 'techStack.ssl.sectigoOrganizationSSL' },
      { title: 'Sectigo EV SSL', key: 'techStack.ssl.sectigoEVSSL' },
    ],
    disableCheckbox: true,
  },
  {
    title: 'Web Server',
    key: 'techStack.Web_server',
    children: [
      { title: <span className='font-bold'>Select All</span>, key: 'techStack.Web_server.select' },
      { title: 'IIS 7', key: 'techStack.web_server.iIS7' },
      { title: 'Rack Cache', key: 'techStack.web_server.rackCache' },
      { title: 'nginx', key: 'techStack.web_server.nginx' },
      { title: 'Apache Tomcat Coyote', key: 'techStack.web_server.apacheTomcatCoyote' },
      { title: 'nginx 1.10', key: 'techStack.web_server.nginx1_10' },
      { title: 'Nginx 1.13', key: 'techStack.web_server.nginx1_13' },
      { title: 'Google Web Server', key: 'techStack.web_server.googleWebServer' },
      { title: 'Apache 2.2', key: 'techStack.web_server.apache2_2' },
      { title: 'mod_wsgi', key: 'techStack.web_server.mod_wsgi' },
      { title: 'LiteSpeed', key: 'techStack.web_server.liteSpeed' },
      { title: 'Apache 2.4', key: 'techStack.web_server.apache2_4' },
      { title: 'Nginx 1.17', key: 'techStack.web_server.nginx1_17' },
      { title: 'Caddy', key: 'techStack.web_server.caddy' },
      { title: 'IIS', key: 'techStack.web_server.iIS' },
      { title: 'Varnish 4.x', key: 'techStack.web_server.varnish4_X' },
      { title: 'Nginx 1.11', key: 'techStack.web_server.nginx1_11' },
      { title: 'nginx 1.1', key: 'techStack.web_server.nginx1_1' },
      { title: 'nginx 1.4', key: 'techStack.web_server.nginx1_4' },
      { title: 'IIS 10', key: 'techStack.web_server.iIS10' },
      { title: 'Nginx 1.16', key: 'techStack.web_server.nginx1_16' },
      { title: 'IIS 8', key: 'techStack.web_server.iIS8' },
      { title: 'Uvicorn', key: 'techStack.web_server.uvicorn' },
      { title: 'Nginx 1.15', key: 'techStack.web_server.nginx1_15' },
      { title: 'Jetty', key: 'techStack.web_server.jetty' },
      { title: 'Apache Traffic Server', key: 'techStack.web_server.apacheTrafficServer' },
      { title: 'Sun ONE Web Server', key: 'techStack.web_server.sunONEWebServer' },
      { title: 'Apache', key: 'techStack.web_server.apache' },
      { title: 'lighttpd', key: 'techStack.web_server.lighttpd' },
      { title: 'Phusion Passenger', key: 'techStack.web_server.phusionPassenger' },
      { title: 'Citrix NetScaler', key: 'techStack.web_server.citrixNetScaler' },
      { title: 'Tengine', key: 'techStack.web_server.tengine' },
      { title: 'Varnish 6.x', key: 'techStack.web_server.varnish6_X' },
      { title: 'Sun ONE Web Server v6.1', key: 'techStack.web_server.sunONEWebServerV6_1' },
      { title: 'Nginx 1.14', key: 'techStack.web_server.nginx1_14' },
      { title: 'Amazon ALB', key: 'techStack.web_server.amazonALB' },
      { title: 'nginx 1.8', key: 'techStack.web_server.nginx1_8' },
      { title: 'Cowboy', key: 'techStack.web_server.cowboy' },
      { title: 'Imunify360', key: 'techStack.web_server.imunify360' },
      { title: 'Nginx 1.12', key: 'techStack.web_server.nginx1_12' },
      { title: 'nginx 1.6', key: 'techStack.web_server.nginx1_6' },
      { title: 'Varnish', key: 'techStack.web_server.varnish' },
      { title: 'Apache 1.3', key: 'techStack.web_server.apache1_3' },
    ],
    disableCheckbox: true,
  },
]
