import React from 'react'
import telegram_white from 'common/assets/svg/telegram_white.svg'
import no_logo_contact from 'common/assets/svg/no_logo_contact.svg'
import styles from './CircularImage.module.scss'

interface CircularImageProps {
  src: string
  alt: string
  color: number
  className?: string
  badge?: boolean
  addStyle?: boolean
}

const CircularImage: React.FC<CircularImageProps> = ({ src, alt, color, className, badge, addStyle = true }) => {
  const borderColor = {
    1: styles.firstCircle,
    2: styles.secondCircle,
    3: styles.thirdCircle,
  }[color]

  const backgroundColor = {
    0: styles.secondNumber,
    1: styles.firstNumber,
    2: styles.secondNumber,
    3: styles.thirdNumber,
  }[color]

  const number = {
    1: <span style={{ fontSize: '12px' }}>1st</span>,
    2: <span style={{ fontSize: '12px' }}>2nd</span>,
    3: <span style={{ fontSize: '12px' }}>3rd</span>,
  }[color]

  return (
    <div className={`${styles.imageWithCircle} ${borderColor} `}>
      <img
        src={src || no_logo_contact}
        alt={alt}
        style={addStyle ? { filter: 'grayScale(100%)' } : {}}
        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
          const target = e.target as HTMLImageElement
          target.src = no_logo_contact
        }}
      />
      {badge && (
        <div className={`${className ? className : styles.numberCircle} ${backgroundColor}`}>
          {color >= 1 && color <= 3 ? number : <img src={telegram_white} alt='' />}
        </div>
      )}
    </div>
  )
}

export default CircularImage
