import { useState } from 'react'

export const useDisclaimer = () => {
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false)

  const toggleShowDisclaimer = () => {
    setShowDisclaimer((x) => !x)
  }

  return { showDisclaimer, setShowDisclaimer, toggleShowDisclaimer }
}
