import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Input, Button, message } from 'antd'
import { AuthService } from 'common/services/authServices'
import { Buttons } from 'common/constants/label.constants'
import convrt_logo_login from '../../common/assets/svg/convrt_logo_login.svg'

import styles from './sign-up.module.scss'

const SignupRegistrationCode: React.FC = () => {
  const navigate = useNavigate()
  // const [errorSignup, setErrorSignup] = useState('')
  // const [isSuccessfully, setIsSuccessfully] = useState(false)
  // eslint-disable-next-line
  const [user, setUser] = useState<any>([])
  const { registrationCode } = useParams<{ registrationCode: string }>()
  // eslint-disable-next-line
  const [fixedEmail, setFixedEmail] = useState('')
  const [fixedCompanyName, setFixedCompanyName] = useState('')

  const checkRegistrationCode = async () => {
    try {
      const result = await AuthService.checkRegistrationCode(registrationCode)
      if (result?.response?.status === 400 || result?.response?.status === 500) {
        navigate('/signup')
      }
      setFixedEmail(result.email)
      setFixedCompanyName(result.company)
      formik.setValues({
        ...formik.values,
        company: result.company,
        email: result.email,
      })
    } catch (e) {
      console.error(e)
      navigate('/signup')
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      company: fixedCompanyName,
      email: fixedEmail,
      password: '',
    },
    onSubmit: async (values) => {
      const result = await AuthService.signUpRegistrationCode(values, registrationCode)
      // setIsSuccessfully(result.code === 201 ? true : false)
      if (result.code === 201) {
        // setIsSuccessfully(true)
        message.success('Signed Up Successfully')
        navigate('/login')
      } else {
        // setIsSuccessfully(false)
      }
      if (result.code === 'ERR_BAD_REQUEST') {
        // setErrorSignup(result.response.data.message)
        message.error(result.response.data.message)
      } else {
        // setErrorSignup(result.message)
        message.error(result.message)
      }
    },
  })

  const handleTerms = () => {
    window.open('https://www.convrt.io/terms-of-use', '_blank')
  }

  const handlePrivacyPolicy = () => {
    window.open('https://www.convrt.io/privacy-policy', '_blank')
  }

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json',
            },
          })
          .then(async (res) => {
            let data: any = {}
            data.email = res.data.email
            data.name = res.data.name

            const result = await AuthService.ssoSignUp(data)
            if (
              (result && result?.response?.data?.message === 'The email already exists') ||
              result?.user?.email === res.data.email
            ) {
              navigate('/login')
            }
          })
          .catch((err) => console.error(err))
      }
    }
    checkRegistrationCode()
    fetchData().catch(console.error)
  }, [user])

  return (
    <div className={styles.holdingContainer}>
      <div className={styles.logo}>
        <img src={convrt_logo_login} alt='' />
      </div>
      <div className={styles.form}>
        <div className={styles.header}>
          <p>Welcome</p>
          <span>Please complete your account details below.</span>
        </div>
        <Form onFinish={formik.handleSubmit}>
          <Form.Item
            style={{ marginTop: '10px' }}
            name='name'
            rules={[{ required: true, message: 'Full Name is required', whitespace: true }]}>
            <Input
              style={{ height: 40, borderRadius: '10px' }}
              {...formik.getFieldProps('name')}
              placeholder='Full Name'
            />
          </Form.Item>
          <Form.Item style={{ marginTop: '10px' }}>
            <Input style={{ height: 40, borderRadius: '10px' }} readOnly={true} placeholder={fixedCompanyName} />
          </Form.Item>
          <Form.Item style={{ marginTop: '10px' }}>
            <Input style={{ height: 40, borderRadius: '10px' }} readOnly={true} placeholder={fixedEmail} />
          </Form.Item>
          <Form.Item
            style={{ marginTop: '10px' }}
            name='password'
            rules={[
              {
                required: true,
                message: 'Password is required',
              },
              {
                min: 8,
                message: 'Invalid credentials',
              },
            ]}>
            <Input.Password
              style={{ height: 40, color: 'rgba(255,255,255)', borderRadius: '10px' }}
              {...formik.getFieldProps('password')}
              placeholder='Password'
              className='password'
            />
          </Form.Item>
          <Form.Item>
            <Button type='primary' className={styles.btnLogin} htmlType='submit'>
              {Buttons.SIGN_UP}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className={styles.footer}>
        <span>
          By signing in, you agree to our{' '}
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleTerms}>
            Terms
          </span>{' '}
          &{' '}
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handlePrivacyPolicy}>
            Privacy Policy.
          </span>
        </span>
      </div>
    </div>
  )
}

export default SignupRegistrationCode
