import { createSlice } from '@reduxjs/toolkit'

interface IOutreachedSlice {
  status: string
  is_outreached: Array<any>
  is_sent: boolean
}

const initialState = {
  status: '',
  is_outreached: [],
  is_sent: false,
} as IOutreachedSlice

export const outreachedSlice = createSlice({
  name: 'outreached',
  initialState,
  reducers: {
    SET_IS_OUTREACHED(state, action) {
      state.status = 'succeeded'
      state.is_outreached = action.payload
    },
    SET_IS_SENT(state, action) {
      state.status = 'succeeded'
      state.is_sent = action.payload
    },
  },
})

export const { SET_IS_OUTREACHED, SET_IS_SENT } = outreachedSlice.actions
export const outreachedReducer = outreachedSlice.reducer
