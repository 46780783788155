import React from 'react'
import styles from './Chat.module.scss'
import MessageComponent from './MessageComponent'
interface Props {
  selectedChannelId: string | null
  selectedChannelMessages: any[]
}
const ChatMessage: React.FC<Props> = ({ selectedChannelId, selectedChannelMessages }) => {
  return (
    <div className={`${styles['ChatTabsMessageContent']} flex flex-column relative overflow-auto max-h-full`}>
      {/* <NoChat /> */}
      <MessageComponent channelId={selectedChannelId} channelMessages={selectedChannelMessages} />
    </div>
  )
}

export default ChatMessage
