import React, { useEffect } from 'react'
import black_tick from 'common/assets/svg/black_tick.svg'
import awaiting from 'common/assets/svg/awaiting_tag.svg'

import styles from './NewCampaignWait.module.scss'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_SEQUENCE_DATA } from 'common/components/OutreachCampaign/state/outreachCampaignSlice'

interface NewCampaignWaitProps {
  currentSideBar?: any
  nodesData?: any
  setNodesData?: any
}

const NewCampaignWait: React.FC<NewCampaignWaitProps> = ({ currentSideBar, nodesData, setNodesData }) => {
  const dispatch = useAppDispatch()
  const { wait } = useAppSelector((state) => state.outreachCampaign)
  const time = `${wait?.duration || wait?.duration === 0 ? wait.duration : 'X'} ${
    wait.duration === 1 ? wait.units.slice(0, -1) : wait.units
  }`
  const type = wait.units === 'hours' ? 0 : wait.units === 'days' ? 1 : 2

  useEffect(() => {
    const nodeId = currentSideBar.nodeId
    const updatedSteps = {
      ...nodesData.steps,
      [nodeId]: {
        type: 'is_wait',
        previous_step_id: nodesData?.steps?.[currentSideBar?.nodeId]?.previous_step_id,
        data: { duration: wait.duration, units: wait.units, type: type },
      },
    }

    setNodesData((prev: any) => {
      dispatch(SET_SEQUENCE_DATA({ ...prev, steps: updatedSteps }))
      return { ...prev, steps: updatedSteps }
    })
  }, [time])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={black_tick} alt='' />
        <span>Condition is Set</span>
      </div>
      <div className={styles.mainBody}>
        <img src={awaiting} alt='' />
        <p>Wait {time}</p>
        <span>And then...</span>
      </div>
    </div>
  )
}

export default NewCampaignWait
