import { DataNode } from 'antd/es/tree'
import { Key } from 'react'

export const findNodeByKey = (data: DataNode[], key: React.Key): DataNode | null => {
  for (const node of data) {
    if (node.key === key) {
      return node
    }
    if (node.children) {
      const foundNode = findNodeByKey(node.children, key)
      if (foundNode) {
        return foundNode
      }
    }
  }
  return null
}

export const findTitle = (data: any[], key: Key) => {
  return data.reduce((acc: string, curr: any) => {
    if (curr.children) {
      const title = curr?.children?.find((item: any) => item.key === key)?.title
      return title !== undefined ? title || '' : acc
    } else return curr.key === key ? curr.title : acc
  }, '')
}
