import { createSlice } from '@reduxjs/toolkit'
import { ILearningSlice } from '../interface/ILearningSlice'

const initialState = {
  floatVisible: true,
  learningOpen: false,
  articleOpen: 'NONE',
  gettingStartedOpen: false,
  isAdmin: false,
  intercomOpen: false,
  articleSeeMoreOpen: false,
  videoSeeMoreOpen: false,
  articleSeeMoreData: {},
  allCategories: [],
} as ILearningSlice

export const learningSlice = createSlice({
  name: 'learning',
  initialState,
  reducers: {
    SET_FLOAT_VISIBLE(state, action) {
      state.floatVisible = action.payload
    },
    SET_LEARNING_OPEN(state, action) {
      state.learningOpen = action.payload
    },
    SET_ARTICLE_OPEN(state, action) {
      state.articleOpen = action.payload
    },
    SET_GETTING_STARTED_OPEN(state, action) {
      state.gettingStartedOpen = action.payload
    },
    SET_IS_ADMIN(state, action) {
      state.isAdmin = action.payload
    },
    SET_INTERCOM_OPEN(state, action) {
      state.intercomOpen = action.payload
    },
    SET_VIDEO_SEE_MORE_OPEN(state, action) {
      state.videoSeeMoreOpen = action.payload
    },
    SET_ARTICLE_SEE_MORE_OPEN(state, action) {
      state.articleSeeMoreOpen = action.payload
    },
    SET_ARTICLE_SEE_MORE_DATA(state, action) {
      state.articleSeeMoreData = action.payload
    },
    SET_ALL_CATEGORIES(state, action) {
      state.allCategories = action.payload
    },
  },
})

export const {
  SET_INTERCOM_OPEN,
  SET_IS_ADMIN,
  SET_GETTING_STARTED_OPEN,
  SET_ARTICLE_OPEN,
  SET_FLOAT_VISIBLE,
  SET_LEARNING_OPEN,
  SET_ARTICLE_SEE_MORE_OPEN,
  SET_VIDEO_SEE_MORE_OPEN,
  SET_ARTICLE_SEE_MORE_DATA,
  SET_ALL_CATEGORIES,
} = learningSlice.actions

export const learningReducer = learningSlice.reducer
