import { useState } from 'react'
import { Input, Form } from 'antd'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

import styles from './OnBoardingPageD.module.scss'
import classNames from 'classnames'

interface OnBoardingPageDProps {
  setTelegramPassword: any
  error?: any
}

const OnBoardingPageD = ({ setTelegramPassword, error }: OnBoardingPageDProps) => {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleChange = (e: any) => {
    setTelegramPassword(e.target.value)
  }

  return (
    <div className={styles.container}>
      <div className={styles.centeredContent}>
        <p>{'Enter Your Telegram Password'}</p>
        <section>{'Your Telegram account is protected by 2-factor authentication'}</section>
        <div className={styles.codeInputs}>
          <Form.Item name='password' rules={[{ required: true, message: 'Password is required' }]}>
            <Input.Password
              className={styles['form-input']}
              onChange={handleChange}
              placeholder='Password'
              iconRender={(visible) =>
                visible ? (
                  <EyeOutlined onClick={togglePasswordVisibility} />
                ) : (
                  <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                )
              }
            />
            <div className={classNames('ant-form-item-explain-error', styles.error)}>{error}</div>
          </Form.Item>
        </div>
      </div>
    </div>
  )
}

export default OnBoardingPageD
