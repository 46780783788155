import { Tag, TagProps } from 'antd'
import classNames from 'classnames'

import styles from './CustomTag.module.scss'

interface ICustomTag extends TagProps {
  className?: string
  excluded?: boolean
  children?: any
}

export const CustomTag = ({ className, excluded, children, ...props }: ICustomTag) => (
  <Tag className={classNames(styles.Tag, className)} color={excluded ? 'red' : 'purple'} {...props}>
    <span className={styles.Text}>{children}</span>
  </Tag>
)
