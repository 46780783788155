import React, { useState } from 'react'
import { Modal, Form, Input, Button } from 'antd'
import styles from './ResetPasswordModal.module.scss'

interface ResetPasswordModalProps {
  open: boolean
  onCancel: () => void
  data: any
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({ open, onCancel, data }) => {
  const [form] = Form.useForm()
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)

  const handleOk = async () => {
    try {
      setLoading(true)
      // Add logic to handle password reset

      form.resetFields()
      onCancel()
    } finally {
      setLoading(false)
    }
  }

  const isFormValid = async () => {
    try {
      const values = await form.validateFields()
      return values
    } catch (error) {
      return false
    }
  }

  const handleResetPasswordClick = async () => {
    const isValid = await isFormValid()

    if (isValid) {
      handleOk()
    }
  }

  return (
    <Modal
      className='modalStyleNewDeal'
      centered
      open={open}
      footer={[
        <div className={styles.footer} key='footer'>
          <Button size={'large'} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            size={'large'}
            style={{ color: 'white', backgroundColor: '#7043ff' }}
            onClick={handleResetPasswordClick}
            // disabled={!form.isFieldsTouched(true) || form.getFieldsError().some((field) => field.errors.length > 0)}
          >
            Reset Password
          </Button>
        </div>,
      ]}
      onCancel={onCancel}
      width={'fit-content'}
      style={{ minWidth: 500, maxWidth: 500 }}>
      <Form form={form} layout='vertical' className={styles.container}>
        <div className={styles.header}>
          <p>Reset Password</p>
        </div>
        <Form.Item
          label='New Password'
          name='password'
          rules={[
            { required: true, message: 'Please enter your new password' },
            { min: 6, message: 'Password must be at least 6 characters long' },
          ]}>
          <Input.Password size='large' placeholder='Enter your new password' />
        </Form.Item>
        <Form.Item
          label='Confirm Password'
          name='confirmPassword'
          dependencies={['password']}
          rules={[
            { required: true, message: 'Please confirm your new password' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Passwords do not match'))
              },
            }),
          ]}>
          <Input.Password size='large' placeholder='Confirm your new password' />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ResetPasswordModal
