export enum ConfirmBox {
  GET_DETAILS = 'Get Contact Details',
  EXPORT_HUBSPOT = 'Export to HubSpot',
  EXPORT_CSV = 'Export to CSV',
  DELETE_LIST = 'Delete List',
  RENAME_LIST = 'Rename List',
  DELETE_LIST_ARE_YOU_SURE = 'Are you sure that you want to delete this list?',
  RENAME_LIST_ARE_YOU_SURE = 'Are you sure that you want to rename this list?',
  ARE_YOU_SURE = 'This action will cost you 1 credit. Read disclaimer:',
  ARE_YOU_SURE_EVENT = `This action will cost you 1 credit.
   You will gain access to information about all the event's
  attendees.
   Read disclaimer:`,
  ARE_YOU_SURE_CREDITS = 'This action will cost you ',
  ARE_YOU_SURE_UPDATE = 'Are you sure that you want to update the api key?',
  DISCLAIMER = 'Disclaimer: Please safeguard your private keys and refrain from sharing them. Some contacts obtained through our platform may need to be verified. Misuses, such as harassment or spam, can lead to account suspension or termination.',
}
