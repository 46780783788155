import videoBgSignup from './videoBg.mp4'
import videoBgLogin from './Login.mp4'
import './video-bg.css'

const VideoBg = ({ srcType }) => {
  const videoSrc = srcType === 1 ? videoBgLogin : videoBgSignup

  return (
    <div className='videoBg'>
      <div className='overlay'></div>
      <video src={videoSrc} autoPlay loop muted></video>
    </div>
  )
}

export default VideoBg
