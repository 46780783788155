import React, { useEffect, useState } from 'react'
import { Tabs, Modal } from 'antd'
import styles from './Campaigns.module.scss'
import type { TabsProps } from 'antd'
import {
  useLazyGetCampaignHeaderQuery,
  useLazyGetCampaignsQuery,
  useLazyGetCooldownsQuery,
} from 'features/Outreach/state/api/OutreachApi'
import BlurPaywallFilters from '../../../../features/intent-signals/components/paywalls/BlurPaywallFilters'
import { outreach } from 'common/constants/outreach.constants'
import { useAppDispatch, useAppSelector } from 'state'
import CampaignFilters from '../../OutreachCampaign/MainCampaign/Campaigns/Filters/CampaignFilters'
import MainRecipients from '../../OutreachCampaign/MainCampaign/Recipients/MainRecipients'
import MainMessages from '../../OutreachCampaign/MainCampaign/Messages/MainMessages'
import CampaignsTable from './CampaignsTab/CampaignsTable'
import CampaignsStats from './CampaignsStats'
import {
  SET_AI_GENERATED_DATA,
  SET_ALL_CAMPAIGNS_DATA,
  SET_CAMPAIGN_ID,
  SET_CAMPAIGN_SETTINGS,
  SET_EDIT_MODE,
  SET_ENABLE_EDITING,
  SET_IMPORTED_LEADS,
  SET_LEAD_ACCEPTED,
  SET_MESSAGE_REPLIED,
  SET_SELECTED_LEADS,
  SET_SELECTED_NODE,
  SET_SELECTED_PLATFORM,
  SET_SELECTED_SENDER,
  SET_SEQUENCE_DATA,
  SET_TEMPLATE,
  SET_WAIT,
} from 'common/components/OutreachCampaign/state/outreachCampaignSlice'

const Campaigns: React.FC = () => {
  const dispatch = useAppDispatch()
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const [refetchGetCooldowns] = useLazyGetCooldownsQuery()
  const [getCampaigns, { data: campaignsData }] = useLazyGetCampaignsQuery()
  const [getCampaignStats, { data: campaignsHeaderData }] = useLazyGetCampaignHeaderQuery()
  const { campaign_filters, campaign_date_filters } = useAppSelector((state) => state.outreachCampaign)
  const [addonType, setAddonType] = useState('')
  // eslint-disable-next-line
  const [cooldowns, setCooldowns] = useState(['1', '2'])
  const [selectedTab, setSelectedTab] = useState<string>('1')
  const [openContactSales, setOpenContactSales] = useState(false)
  const [loading, setLoading] = useState(false)
  const [filtersChanged, setFiltersChanged] = useState(false)
  const [triggerGetCampaigns, setTriggerGetCampaigns] = useState(false)
  // const [hasCampaigns, setHasCampaigns] = useState(true)

  const handleTabChange = (key: string) => {
    if (
      (!user?.addons?.campaign_messages_pannel && key === '3') ||
      (!user?.addons?.campaign_recipient_pannel && key === '2')
    ) {
      setOpenContactSales(true)
      setAddonType(key === '3' ? 'campaign_messages_pannel' : 'campaign_recipient_pannel')
    } else {
      setSelectedTab(key)
    }
  }

  useEffect(() => {
    const getCooldownAccounts = async () => {
      const { data } = await refetchGetCooldowns()
      setCooldowns(data?.cooldowns)
    }

    getCooldownAccounts()

    dispatch(SET_TEMPLATE(0))
    dispatch(SET_CAMPAIGN_ID(''))
    dispatch(SET_SELECTED_NODE('1'))
    dispatch(SET_SEQUENCE_DATA({}))
    dispatch(SET_WAIT({ nodeId: '', duration: 0, units: 'days' }))
    dispatch(SET_MESSAGE_REPLIED({ nodeId: '', duration: 1, units: 'days' }))
    dispatch(SET_LEAD_ACCEPTED({ nodeId: '', duration: 1, units: 'days' }))
    dispatch(SET_SELECTED_SENDER([]))
    dispatch(SET_CAMPAIGN_SETTINGS({}))
    dispatch(SET_EDIT_MODE(false))
    dispatch(SET_ENABLE_EDITING(false))
    dispatch(SET_IMPORTED_LEADS(false))
    dispatch(SET_SELECTED_LEADS([]))
    dispatch(SET_ALL_CAMPAIGNS_DATA([]))
    dispatch(SET_AI_GENERATED_DATA({}))
    dispatch(SET_SELECTED_PLATFORM('telegram'))
  }, [])

  useEffect(() => {
    const getCampaignsData = async () => {
      if (filtersChanged) {
        setLoading(true)
      }
      if (campaign_filters.type) {
        const { from, to, dateType } = campaign_date_filters
        switch (campaign_filters.type) {
          case 'Campaign Name':
            await getCampaignStats({
              campaign_name: campaign_filters.value,
              fromDate: from,
              toDate: to,
              dateType: dateType,
            })
            await getCampaigns({
              campaign_name: campaign_filters.value,
              fromDate: from,
              toDate: to,
              dateType: dateType,
            })
            break
          case 'Senders':
            await getCampaignStats({
              senders: campaign_filters.value,
              fromDate: from,
              toDate: to,
              dateType: dateType,
            })
            await getCampaigns({
              senders: campaign_filters.value,
              fromDate: from,
              toDate: to,
              dateType: dateType,
            })

            break
          case 'Show Archive':
            await getCampaignStats({
              is_archived: campaign_filters.value,
              fromDate: from,
              toDate: to,
              dateType: dateType,
            })
            await getCampaigns({
              is_archived: campaign_filters.value,
              fromDate: from,
              toDate: to,
              dateType: dateType,
            })

            break
          case 'Platform':
            await getCampaignStats({
              platform: campaign_filters.value,
              fromDate: from,
              toDate: to,
              dateType: dateType,
            })
            await getCampaigns({
              platform: campaign_filters.value,
              fromDate: from,
              toDate: to,
              dateType: dateType,
            })

            break
          default:
            break
        }
      } else {
        const { from, to, dateType } = campaign_date_filters
        await getCampaignStats({ fromDate: from, toDate: to, dateType: dateType })
        const { data } = await getCampaigns({ fromDate: from, toDate: to, dateType: dateType })
        dispatch(SET_ALL_CAMPAIGNS_DATA(data))
      }
      if (filtersChanged) {
        setLoading(false)
        setFiltersChanged(false)
      }
    }
    getCampaignsData()

    const intervalId = setInterval(getCampaignsData, 30000)
    return () => clearInterval(intervalId)
  }, [campaign_filters, campaign_date_filters, filtersChanged, triggerGetCampaigns])

  useEffect(() => {
    setFiltersChanged(true)
  }, [campaign_filters, campaign_date_filters])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Campaigns',
    },
    {
      key: '2',
      label: 'Recipients',
    },
    {
      key: '3',
      label: 'Messages',
    },
  ]

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <Tabs defaultActiveKey='1' items={items} onChange={handleTabChange} className='outreachCampaignTabs' />
        </div>
        <CampaignFilters selectedTab={selectedTab} />
        <CampaignsStats campaignsHeaderData={campaignsHeaderData} isFetching={loading} />

        {selectedTab === '1' && (
          <CampaignsTable
            campaigns={campaignsData}
            isFetching={loading}
            setTriggerGetCampaigns={setTriggerGetCampaigns}
          />
        )}
        {selectedTab === '2' && <MainRecipients />}
        {selectedTab === '3' && <MainMessages />}

        <Modal
          open={openContactSales}
          onCancel={() => setOpenContactSales(false)}
          okButtonProps={{
            className: 'hidden',
          }}
          cancelButtonProps={{
            className: 'hidden',
          }}>
          <div className='flex justify-content-center'>
            <BlurPaywallFilters
              addon={addonType}
              component={[]}
              onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
              customClass='contactSalesNoMargin'
            />
          </div>
        </Modal>
      </div>
    </>
  )
}

export default Campaigns
