import React, { useEffect, useState, useCallback } from 'react'
import { Modal, Button, Layout, Divider, Pagination, Checkbox, Switch, message } from 'antd'
import styles from './ImportLeadsFromGroupsModal.module.scss'

import GroupsContent from './GroupsContent'
import FeaturedGroupsContent from './FeaturedGroupsContent'
import EventAttendees from './EventAttendees'
import GroupsTable from './GroupsTable'
import EventsTable from './EventsTable'
import SaveFiltersComponent from './SavedFilters'
import { useAppDispatch, useAppSelector } from 'state'

import {
  SET_ARE_LEADS_FROM_GROUPS,
  SET_SELECTED_LEADS,
} from 'common/components/OutreachCampaign/state/outreachCampaignSlice'
import {
  useGetGroupsQuery,
  useGetEventsQuery,
  useGetGroupMembersQuery,
  useGetEventAttendeesQuery,
} from 'features/Outreach/state/api/OutreachApi'
import { debounce } from 'lodash'
import GroupsFilters from './GroupsFilters'
import { useImmer } from 'use-immer'
import GroupsSidebar from './GroupsSidebar'
import three_users from 'common/assets/svg/three_users.svg'

const { Header } = Layout

interface ImportLeadsFromGroupsModalProps {
  open: boolean
  isLoading: boolean
  campaign_id: string
  onClose: () => void
  onConfirm: (data: any) => Promise<any>
  isUserInATeam?: boolean
}

const initialTableParam = { current: 1, pageSize: 10 }

const getGroupsParamsDefault = {
  current: 1,
  pageSize: 102,
  group_name: '',
  is_featured_groups: false,
}

const getEventsParamsDefault = {
  title: '',
  current: 1,
  pageSize: 102,
  from_group: '',
}

const NewImportLeadsFromGroupsModal: React.FC<ImportLeadsFromGroupsModalProps> = ({
  open,
  isLoading,
  campaign_id,
  onClose,
  onConfirm,
  isUserInATeam,
}) => {
  const dispatch = useAppDispatch()
  const { selected_leads } = useAppSelector((state) => state.outreachCampaign)
  const [keywords, setKeywords] = useState<string[]>([])
  const [conditionedKeywords, setConditionedKeywords] = useState<string[]>([])
  const [operator, setOperator] = useState('AND')
  const [selectedAll, setSelectedAll] = useState<any>(undefined)
  const [selectedCategory, setSelectedCategory] = useState<string>('Explore All Groups')
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectedGroup, setSelectedGroup] = useState<any>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [scrollTopGroups, setScrollTopGroups] = useState(false)
  const [ignoreLeadsFromOtherCampaigns, setIgnoreLeadsFromOtherCampaigns] = useState(true)
  const [ignoreLeadsFromOtherTeamMembers, setIgnoreLeadsFromOtherTeamMembers] = useState(true)
  const [ignoreOutreachedLeads, setIgnoreOutreachedLeads] = useState(true)
  const [ignoreOutreachedLeadsFromTeam, setIgnoreOutreachedLeadsFromTeam] = useState(true)
  // const { selected_platform } = useAppSelector((state) => state.outreachCampaign)
  // const platform = selected_platform ? selected_platform.charAt(0).toUpperCase() + selected_platform.slice(1) : ''
  const [getParamGroupMember, setGetParamGroupMember] = useImmer(() => {
    return {
      tableParamGroup: { ...initialTableParam },
      group_name: selectedGroup?.group_name || '',
      search: '',
      extraSearch: '',
      isAdmin: false,
    }
  })
  const [getParamEventAttendees, setGetParamEventAttendees] = useImmer(() => {
    return {
      tableParamGroup: { ...initialTableParam },
      from_group: selectedGroup?.from_group || '',
      search: '',
      extraSearch: '',
      isAdmin: false,
    }
  })

  const [getGroupsParams, setGetGroupsParams] = useImmer(() => {
    return getGroupsParamsDefault
  })

  const [getEventsParams, setGetEventsParams] = useImmer(() => {
    return getEventsParamsDefault
  })

  const { data: groupMembers, isFetching: isGroupMemberDataFetching } = useGetGroupMembersQuery(
    {
      ...getParamGroupMember,
      ...getParamGroupMember.tableParamGroup,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !getParamGroupMember?.group_name,
    },
  )

  const { data: allGroupMembers, isFetching: isAllGroupMemberDataFetching } = useGetGroupMembersQuery(
    {
      ...getParamGroupMember,
      ...getParamGroupMember.tableParamGroup,
      getAll: true,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !getParamGroupMember?.group_name,
    },
  )

  const { data: eventAttendees, isFetching: isEventAttendeesDataFetching } = useGetEventAttendeesQuery(
    {
      ...getParamEventAttendees,
      ...getParamEventAttendees.tableParamGroup,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !getParamEventAttendees?.from_group,
    },
  )

  const { data: allEventAttendees, isFetching: isAllEventAttendeesDataFetching } = useGetEventAttendeesQuery(
    {
      ...getParamEventAttendees,
      ...getParamEventAttendees.tableParamGroup,
      getAll: true,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !getParamEventAttendees?.from_group,
    },
  )

  const { data: allGroupsData, isFetching: isGetAllGroupsFetching } = useGetGroupsQuery(getGroupsParams)
  const { data: allEventsData, isFetching: isGetAllEventsFetching } = useGetEventsQuery(getEventsParams)
  const handleSearchGroupChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (selectedCategory === 'Events Attendees') {
        setGetEventsParams((draft) => {
          draft.title = e.target.value
          draft.current = getEventsParamsDefault.current
          draft.pageSize = getEventsParamsDefault.pageSize
        })
      } else {
        setGetGroupsParams((draft) => {
          draft.current = getGroupsParamsDefault.current
          draft.pageSize = getGroupsParamsDefault.pageSize
          draft.group_name = e.target.value
          draft.is_featured_groups = selectedCategory === 'Explore Featured Groups' ? true : false
        })
      }
    },
    [selectedCategory],
  )

  const debouncedHandleSearchGroupChange = useCallback(debounce(handleSearchGroupChange, 500), [
    handleSearchGroupChange,
  ])

  const onChangeSearchGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
    debouncedHandleSearchGroupChange(e)
  }

  const handleResetState = () => {
    setKeywords([])
    setConditionedKeywords([])
    setOperator('AND')
    setSearchQuery('')
    setSelectedAll(undefined)
    setSelectedGroup(null)
    setSelectedRowKeys([])
    setGetParamGroupMember((draft) => {
      draft.tableParamGroup = { ...initialTableParam }
      draft.isAdmin = false
      draft.extraSearch = ''
      draft.search = ''
      draft.group_name = ''
    })
    setGetParamEventAttendees((draft) => {
      draft.tableParamGroup = { ...initialTableParam }
      draft.isAdmin = false
      draft.extraSearch = ''
      draft.search = ''
      draft.from_group = ''
    })
    setGetEventsParams((draft) => {
      draft.title = ''
    })
    setGetGroupsParams((draft) => {
      draft.group_name = ''
    })
    setGetEventsParams((draft) => {
      draft.from_group = ''
    })
  }

  const handleBack = () => {
    handleResetState()
  }

  const handleCloseModal = () => {
    setSelectedCategory('Explore All Groups')
    handleResetState()
    onClose()
  }

  const handleAddLeads = async () => {
    dispatch(SET_ARE_LEADS_FROM_GROUPS(true))
    setOpenConfirmationModal(false)

    const submissionData = {
      campaignId: campaign_id,
      // source: 'telegram',
      source: selectedCategory === 'Events Attendees' ? 'event_attendees' : 'telegram',
      ids: selected_leads,
      group_name: selectedCategory === 'Events Attendees' ? selectedGroup?.from_group : selectedGroup?.group_name,
      ignoreLeadsFromOtherCampaigns: ignoreLeadsFromOtherCampaigns,
      ignoreLeadsFromOtherTeamMembers: ignoreLeadsFromOtherTeamMembers,
      ignoreOutreachedLeads: ignoreOutreachedLeads,
      ignoreOutreachedLeadsFromTeam: ignoreOutreachedLeadsFromTeam,
    }
    const response = await onConfirm(submissionData)
    if (response) {
      setSelectedRowKeys([])
      dispatch(SET_SELECTED_LEADS([]))
      handleResetState()
    }
  }

  const handleSelectAllData = () => {
    if (
      (selectedCategory === 'Events Attendees' && isAllEventAttendeesDataFetching) ||
      (selectedCategory !== 'Events Attendees' && isAllGroupMemberDataFetching)
    ) {
      return message.info('All data is loading, please try again in a few seconds!')
    }
    setSelectedAll(true)
    const respSelectFormat = selectedCategory === 'Events Attendees' ? allEventAttendees?.rows : allGroupMembers?.rows
    const uniqueContactsIds = Array.from(new Set(respSelectFormat))
    dispatch(SET_SELECTED_LEADS(uniqueContactsIds))
  }

  const headerStyle: React.CSSProperties = {
    color: 'black',
    fontWeight: 600,
    fontSize: '18px',
    height: 80,
    paddingInline: 24,
    lineHeight: '64px',
    backgroundColor: 'white',
    borderBottom: '1px solid #DFE1E6',
    alignContent: 'center',
  }

  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
  }

  const handleFilterChange = ({ keywords, operator, conditionedKeywords }: any) => {
    setGetParamGroupMember((draft) => {
      draft.tableParamGroup = { ...initialTableParam }
    })

    setGetEventsParams((draft) => {
      draft.current = initialTableParam.current
      draft.pageSize = initialTableParam.pageSize
    })

    const formatKeyword = keywords.length > 0 ? keywords.map((keyword: any) => keyword).join(',') : ''

    const formatOperator = operator === 'AND' ? 'AND' : 'AND_NOT'
    const extraSearch =
      conditionedKeywords.length > 0
        ? `${formatOperator}:${conditionedKeywords.map((keyword: any) => keyword).join(',')}`
        : ''

    setGetParamGroupMember((draft) => {
      draft.search = formatKeyword
      draft.extraSearch = extraSearch
    })
    setGetParamEventAttendees((draft) => {
      draft.search = formatKeyword
      draft.extraSearch = extraSearch
    })
  }

  const parseExtraSearch = (input: string) => {
    const [operator, conditionsString] = input?.split(':') || 'AND'
    const conditions = conditionsString?.split(',').map((condition) => condition.trim()) || []

    return {
      operator,
      conditions,
    }
  }

  const handleSelectFilter = (item: any) => {
    if (item.extraSearch) {
      const { operator, conditions } = parseExtraSearch(item.extraSearch)
      setConditionedKeywords(conditions)
      setOperator(operator)
    } else {
      setConditionedKeywords([])
      setOperator('AND')
    }

    setKeywords(item?.search?.split(',') || [])
    setGetParamGroupMember((draft) => {
      draft.search = item.search
      draft.extraSearch = item.extraSearch
      draft.isAdmin = item.isAdmin
    })
    setGetParamEventAttendees((draft) => {
      draft.search = item.search
      draft.extraSearch = item.extraSearch
      draft.isAdmin = item.isAdmin
    })
  }

  const handleSelectedGroupChange = (newGroup: any) => {
    setSelectedGroup(newGroup)
    setGetParamGroupMember((draft) => {
      draft.group_name = newGroup?.group_name
    })
  }

  const handleSelectedEventChange = (newGroup: any) => {
    setSelectedGroup(newGroup)
    setGetParamEventAttendees((draft) => {
      draft.from_group = newGroup?.from_group
    })
  }

  const handleDismissData = () => {
    setSelectedAll(undefined)
    dispatch(SET_SELECTED_LEADS([]))
    setSelectedRowKeys([])
  }

  const resetFilters = () => {
    setKeywords([])
    setConditionedKeywords([])
    setOperator('AND')
    setGetParamGroupMember((draft) => {
      draft.search = ''
      draft.extraSearch = ''
      draft.isAdmin = false
    })
    setGetParamEventAttendees((draft) => {
      draft.search = ''
      draft.extraSearch = ''
      draft.isAdmin = false
    })
  }

  useEffect(() => {
    dispatch(SET_SELECTED_LEADS(selectedRowKeys))
  }, [selectedRowKeys])

  useEffect(() => {
    setScrollTopGroups(true)
  }, [allGroupsData])

  useEffect(() => {
    if (scrollTopGroups) {
      setScrollTopGroups(false)
    }
  }, [scrollTopGroups])

  useEffect(() => {
    setSearchQuery('')
    if (selectedCategory === 'Explore All Groups') {
      setGetGroupsParams((draft) => {
        draft.current = 1
        draft.pageSize = 102
        draft.group_name = ''
        draft.is_featured_groups = false
      })
    } else if (selectedCategory === 'Explore Featured Groups') {
      setGetGroupsParams((draft) => {
        draft.current = 1
        draft.pageSize = 102
        draft.group_name = ''
        draft.is_featured_groups = true
      })
    } else if (selectedCategory === 'Events Attendees') {
      setGetEventsParams((draft) => {
        draft.title = ''
        draft.current = 1
        draft.pageSize = 102
        draft.from_group = ''
      })
    }
  }, [selectedCategory])
  return (
    <Modal
      className='groupsModal'
      centered
      closable={false}
      open={open}
      footer={null}
      onCancel={handleCloseModal}
      width='87%'>
      {selectedGroup ? (
        <Layout style={layoutStyle}>
          <Header style={headerStyle}>
            <div className={styles.header}>
              <span>
                Add Leads From {selectedGroup?.title} <Divider type='vertical' />{' '}
                <span style={{ fontSize: '14px' }}>
                  {selected_leads?.length || 0} leads <span style={{ fontWeight: 500 }}>were selected</span>
                </span>
                {selectedAll && (
                  <span
                    onClick={handleDismissData}
                    style={{ fontSize: '14px', marginLeft: '10px', color: 'blue', cursor: 'pointer' }}>
                    Dismiss
                  </span>
                )}
              </span>
              <div className={styles.buttons}>
                <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={handleCloseModal}>
                  Cancel
                </span>
                <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={handleBack}>
                  Back
                </span>
                <Button
                  type='primary'
                  disabled={selected_leads?.length === 0 || isLoading}
                  loading={isLoading}
                  onClick={() => {
                    setOpenConfirmationModal(true)
                  }}>
                  Import Leads
                </Button>
              </div>
            </div>
          </Header>
          <Layout>
            {selectedCategory === 'Events Attendees' ? (
              <>
                <EventsTable
                  selectedGroup={selectedGroup?.title}
                  data={isEventAttendeesDataFetching ? [] : eventAttendees?.rows}
                  isLoading={isEventAttendeesDataFetching}
                  selectedRowKeys={selected_leads?.map((lead: any) => lead.id || lead)}
                  handleSelectAllData={handleSelectAllData}
                  tableParams={{
                    ...getParamEventAttendees.tableParamGroup,
                    total: +eventAttendees?.total,
                  }}
                  handleTableChange={(param: any) => {
                    setGetParamEventAttendees((draft) => {
                      draft.tableParamGroup = param
                    })
                  }}
                  setSelectedRowKeys={setSelectedRowKeys}
                />
                <div className={styles.filter}>
                  <GroupsFilters
                    conditionedKeywords={conditionedKeywords}
                    setConditionedKeywords={setConditionedKeywords}
                    keywords={keywords}
                    setKeywords={setKeywords}
                    onFilterChange={handleFilterChange}
                    setOperator={setOperator}
                    operator={operator}
                  />
                  <Checkbox
                    style={{ margin: '0 10px' }}
                    onChange={(e) =>
                      setGetParamEventAttendees((draft) => {
                        draft.isAdmin = e.target.checked
                      })
                    }>
                    Show Only Admins
                  </Checkbox>
                  <SaveFiltersComponent
                    resetFilters={resetFilters}
                    handleSelect={handleSelectFilter}
                    params={getParamGroupMember}
                    keywords={keywords}
                    conditionedKeywords={conditionedKeywords}
                  />
                </div>
              </>
            ) : (
              <>
                <GroupsTable
                  selectedGroup={selectedGroup?.title}
                  data={isGroupMemberDataFetching ? [] : groupMembers?.rows}
                  isLoading={isGroupMemberDataFetching}
                  selectedRowKeys={selected_leads?.map((lead: any) => lead.id || lead)}
                  handleSelectAllData={handleSelectAllData}
                  tableParams={{
                    ...getParamGroupMember.tableParamGroup,
                    total: +groupMembers?.total,
                  }}
                  handleTableChange={(param: any) => {
                    setGetParamGroupMember((draft) => {
                      draft.tableParamGroup = param
                    })
                  }}
                  setSelectedRowKeys={setSelectedRowKeys}
                />
                <div className={styles.filter}>
                  <GroupsFilters
                    conditionedKeywords={conditionedKeywords}
                    setConditionedKeywords={setConditionedKeywords}
                    keywords={keywords}
                    setKeywords={setKeywords}
                    onFilterChange={handleFilterChange}
                    setOperator={setOperator}
                    operator={operator}
                  />
                  <Checkbox
                    style={{ margin: '0 10px' }}
                    onChange={(e) =>
                      setGetParamGroupMember((draft) => {
                        draft.isAdmin = e.target.checked
                      })
                    }>
                    Show Only Admins
                  </Checkbox>
                  <SaveFiltersComponent
                    resetFilters={resetFilters}
                    handleSelect={handleSelectFilter}
                    params={getParamGroupMember}
                    keywords={keywords}
                    conditionedKeywords={conditionedKeywords}
                  />
                </div>
              </>
            )}
          </Layout>
        </Layout>
      ) : (
        <Layout style={layoutStyle}>
          <Header style={headerStyle}>
            <div className={styles.header}>
              <span>Engagement Hub</span>

              <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={handleCloseModal}>
                Cancel
              </span>
            </div>
          </Header>
          <Layout>
            <GroupsSidebar selectedCategory={selectedCategory} categories={[]} onCategoryChange={setSelectedCategory} />
            <Layout>
              {selectedCategory === 'Explore All Groups' && (
                <>
                  <GroupsContent
                    totalGroups={allGroupsData?.total}
                    groupsData={allGroupsData?.response}
                    isLoading={isGetAllGroupsFetching}
                    searchQuery={searchQuery}
                    onChangeSearchGroup={onChangeSearchGroup}
                    scrollTopGroups={scrollTopGroups}
                    setSelectedGroup={handleSelectedGroupChange}
                  />
                  {!isGetAllGroupsFetching && (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: 5, backgroundColor: 'white' }}>
                      <Pagination
                        defaultCurrent={1}
                        current={getGroupsParams.current}
                        onChange={(current, pageSize) => {
                          setGetGroupsParams((draft) => {
                            draft.current = current
                            draft.pageSize = pageSize
                            draft.is_featured_groups = false
                          })
                        }}
                        total={allGroupsData?.total}
                        size='small'
                        pageSize={102}
                      />
                    </div>
                  )}
                </>
              )}
              {selectedCategory === 'Explore Featured Groups' && (
                <>
                  <FeaturedGroupsContent
                    totalGroups={allGroupsData?.total}
                    groupsData={allGroupsData?.response}
                    isLoading={isGetAllGroupsFetching}
                    searchQuery={searchQuery}
                    onChangeSearchGroup={onChangeSearchGroup}
                    scrollTopGroups={scrollTopGroups}
                    setSelectedGroup={handleSelectedGroupChange}
                  />
                  {!isGetAllGroupsFetching && (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: 5, backgroundColor: 'white' }}>
                      <Pagination
                        defaultCurrent={1}
                        current={getGroupsParams.current}
                        onChange={(current, pageSize) => {
                          setGetGroupsParams((draft) => {
                            draft.current = current
                            draft.pageSize = pageSize
                            draft.is_featured_groups = true
                          })
                        }}
                        total={allGroupsData?.total}
                        size='small'
                        pageSize={102}
                      />
                    </div>
                  )}
                </>
              )}
              {selectedCategory === 'Events Attendees' && (
                <>
                  <EventAttendees
                    total={allEventsData?.total}
                    eventsData={allEventsData?.response}
                    isLoading={isGetAllEventsFetching}
                    searchQuery={searchQuery}
                    onChangeSearchGroup={onChangeSearchGroup}
                    scrollTopGroups={scrollTopGroups}
                    setSelectedEvent={handleSelectedEventChange}
                  />
                  {!isGetAllEventsFetching && (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: 5, backgroundColor: 'white' }}>
                      <Pagination
                        defaultCurrent={1}
                        current={getEventsParams.current}
                        onChange={(current, pageSize) => {
                          setGetEventsParams((draft) => {
                            draft.current = current
                            draft.pageSize = pageSize
                          })
                        }}
                        total={allEventsData?.total}
                        size='small'
                        pageSize={102}
                      />
                    </div>
                  )}
                </>
              )}
            </Layout>
          </Layout>
        </Layout>
      )}
      {openConfirmationModal && (
        <Modal
          className={styles.modalStyleNewDeal}
          centered
          open={open}
          okButtonProps={{ className: 'hidden' }}
          cancelButtonProps={{ className: 'hidden' }}
          onCancel={() => {
            setOpenConfirmationModal(false)
          }}
          width={'fit-content'}
          style={{ minWidth: 500, maxWidth: 500 }}>
          <div className={styles.contentStyle}>
            <div className={styles.container}>
              <img src={three_users} alt='' />
              <p>Leads Configurations</p>
              <span>Please select your configurations before adding your desired leads</span>
              <div className={styles.switches}>
                <div className={styles.switchItem}>
                  <Switch
                    onChange={() => {
                      setIgnoreLeadsFromOtherCampaigns(!ignoreLeadsFromOtherCampaigns)
                    }}
                    checked={ignoreLeadsFromOtherCampaigns}
                    size={'small'}
                  />
                  <span className={styles.switchLabel}>{`Exclude Leads Already Present in Other Campaigns`}</span>
                </div>
                <div className={styles.switchItem}>
                  <Switch
                    onChange={() => {
                      setIgnoreOutreachedLeads(!ignoreOutreachedLeads)
                    }}
                    checked={ignoreOutreachedLeads}
                    size={'small'}
                  />
                  <span className={styles.switchLabel}>{`Exclude Leads Already Contacted in Other Campaigns`}</span>
                </div>
                {isUserInATeam && (
                  <>
                    <div className={styles.switchItem}>
                      <Switch
                        onChange={() => {
                          setIgnoreLeadsFromOtherTeamMembers(!ignoreLeadsFromOtherTeamMembers)
                        }}
                        checked={ignoreLeadsFromOtherTeamMembers}
                        size={'small'}
                      />
                      <span className={styles.switchLabel}>{`Exclude Leads Already Present in Team Campaign`}</span>
                    </div>
                    <div className={styles.switchItem}>
                      <Switch
                        onChange={() => {
                          setIgnoreOutreachedLeadsFromTeam(!ignoreOutreachedLeadsFromTeam)
                        }}
                        checked={ignoreOutreachedLeadsFromTeam}
                        size={'small'}
                      />
                      <span className={styles.switchLabel}>
                        {`Exclude Leads Already Contacted by other Team member`}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className={styles.footer}>
                <Button
                  size={'middle'}
                  style={{ color: '#7043ff', backgroundColor: 'white' }}
                  onClick={() => setOpenConfirmationModal(false)}>
                  Close
                </Button>
                <Button size={'middle'} style={{ color: 'white', backgroundColor: '#7043ff' }} onClick={handleAddLeads}>
                  Add Leads
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </Modal>
  )
}

export default NewImportLeadsFromGroupsModal
