import React, { useState, useEffect } from 'react'
import { Layout, Menu } from 'antd'
// import styles from './GroupsSidebar.module.scss'
import type { MenuProps } from 'antd'
// import telegram_icon from 'common/assets/svg/tele_icon.svg'
// import facebook_icon from 'common/assets/svg/facebook_icon.svg'
// import instagram_icon from 'common/assets/svg/instagram_icon.svg'
// import linkedin_icon from 'common/assets/svg/linked_icon.svg'
// import discord_icon from 'common/assets/svg/disc_icon.svg'
// import twitter_icon from 'common/assets/svg/xtwit_icon.svg'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_MY_GROUPS_FILTERS } from 'common/components/OutreachCampaign/state/outreachCampaignSlice'

// const getLogoByPlatform = (platform: any) => {
//   const platformLowerCase = platform.toLocaleLowerCase()
//   switch (platformLowerCase) {
//     case 'instagram':
//       return instagram_icon
//     case 'facebook':
//       return facebook_icon
//     case 'linkedin':
//       return linkedin_icon
//     case 'twitter':
//       return twitter_icon
//     case 'discord':
//       return discord_icon
//     case 'telegram':
//       return telegram_icon
//     default:
//       return ''
//   }
// }

const { Sider } = Layout

const siderStyle: React.CSSProperties = {
  borderRight: '1px solid #dfe1e6',
  background: 'white',
}

interface GroupsSidebarProps {
  categories: string[]
  selectedCategory: string
  onCategoryChange: (category: string) => void
}

const GroupsSidebar: React.FC<GroupsSidebarProps> = ({ categories = [], selectedCategory, onCategoryChange }) => {
  const { my_groups_filters } = useAppSelector((state) => state.outreachCampaign)
  const [selectedKey, setSelectedKey] = useState<string>(selectedCategory)
  const [viewAll, setViewAll] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const displayedCategories = ['Explore All Groups', 'Explore Featured Groups', 'Events Attendees']

  const platforms = ['Facebook', 'Instagram', 'LinkedIn', 'Telegram', 'Discord', 'Twitter']

  const handleViewToggle = () => {
    setViewAll(!viewAll)
  }

  const handleExploreAll = (e: string) => {
    setSelectedKey(e)
    onCategoryChange(e)
  }

  const handlePlatformSelection = (key: string) => {
    if (key === selectedKey) {
      setSelectedKey('')
    } else {
      setSelectedKey(key)
    }
  }

  const handleCategorySelection = (key: string) => {
    if (key === selectedKey) {
      setSelectedKey('')
      onCategoryChange('')
    } else {
      setSelectedKey(key)
      onCategoryChange(key)
    }
  }

  const onClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'View All':
      case 'View Less':
        handleViewToggle()
        break
      case 'Explore All Groups':
      case 'All Groups':
        dispatch(SET_MY_GROUPS_FILTERS(''))
        handleExploreAll(e.key)
        break
      case 'Explore Featured Groups':
        handleExploreAll(e.key)
        break
      case 'Events Attendees':
        handleExploreAll(e.key)
        break
      case 'On Tracking':
        if (my_groups_filters === 'trackMode') {
          setSelectedKey('')
          dispatch(SET_MY_GROUPS_FILTERS(''))
        } else {
          setSelectedKey(e.key)
          dispatch(SET_MY_GROUPS_FILTERS('trackMode'))
        }
        break
      case 'On Importing Progress':
        if (my_groups_filters === 'in_progress') {
          setSelectedKey('')
          dispatch(SET_MY_GROUPS_FILTERS(''))
        } else {
          setSelectedKey(e.key)
          dispatch(SET_MY_GROUPS_FILTERS('in_progress'))
        }
        break
      default:
        platforms.includes(e.key) ? handlePlatformSelection(e.key) : handleCategorySelection(e.key)
        break
    }
  }

  useEffect(() => {
    setSelectedKey(selectedCategory)
  }, [selectedCategory])

  const items: MenuProps['items'] = ['Categories'].map((text, index) => {
    const key = String(index + 1)
    return {
      key: `sub${key}`,
      label: <span style={{ fontWeight: 600 }}>{text}</span>,
      children: displayedCategories.map((cat, j) => {
        return {
          key: cat,
          label: <span style={cat === 'View All' || cat === 'View Less' ? { color: '#7043ff' } : {}}>{cat}</span>,
        }
      }),
    }
  })
  return (
    <Sider style={siderStyle} width={240}>
      <Menu
        defaultOpenKeys={['sub1']}
        className='groupsSidebar'
        mode='inline'
        style={{ height: '100%', borderRight: 0, maxHeight: '680px', overflowY: 'auto', backgroundColor: '#fff' }}
        items={items}
        onClick={onClick}
        selectedKeys={[selectedKey]}
      />
    </Sider>
  )
}

export default GroupsSidebar
