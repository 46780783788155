export const shortenNumber = (number: number): string => {
  const suffixes: string[] = ['', 'k', 'M', 'B', 'T'] // Thousand, Million, Billion, Trillion

  const sign: number = Math.sign(number)
  const absNumber: number = Math.abs(number)

  if (absNumber < 1000) {
    return number.toString() // Return as is if less than 1000
  }

  const magnitude: number = Math.floor(Math.log10(absNumber) / 3)
  const scaledNumber: number = absNumber / Math.pow(1000, magnitude)
  const shortenedNumber: number = Math.round(10 * scaledNumber) / 10 // Round to one decimal place

  return sign * shortenedNumber + suffixes[magnitude]
}

export const expandShortNumber = (shortNumber?: string): number => {
  const suffixes = ['', 'k', 'M', 'B', 'T'] // Thousand, Million, Billion, Trillion

  const match = shortNumber?.match(/^(-?[\d.]+)([kMBT]?)$/)
  if (!match) {
    return NaN // Invalid input, return NaN
  }

  const value = parseFloat(match[1])
  const suffix = match[2]

  const magnitude = suffixes.indexOf(suffix)
  if (magnitude === -1) {
    return NaN // Invalid suffix, return NaN
  }

  return value * Math.pow(1000, magnitude)
}
