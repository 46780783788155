import { Col, InputNumber, Row, Modal } from 'antd'
import BlurPaywall from '../../../features/intent-signals/components/paywalls/BlurPaywall'
import _ from 'lodash'
import { useState } from 'react'
import { outreach } from 'common/constants/outreach.constants'
import { post } from 'common/api/axios'

import { expandShortNumber, shortenNumber } from 'common/utils/inputUtils'

import styles from './Range.module.scss'
import { ReactComponent as LockComponent } from 'common/assets/svg/lock.svg'

const Range = ({ filter, item, onNumberChange, min }: any) => {
  const [openContactSales, setOpenContactSales] = useState(false)

  return (
    <>
      <Row
        onClick={() => {
          if (item.comingSoon) {
            post('/log', {
              action: `${item.label}`,
            })
            setOpenContactSales(true)
          }
        }}>
        <Col span={10}>
          <InputNumber
            addonBefore={item?.children?.[0]?.hasCurrency && '$'}
            placeholder={shortenNumber(item?.children?.[0]?.defaultValue)}
            step={item?.children?.[0]?.steps}
            formatter={(value) => shortenNumber(value)}
            parser={(value) => expandShortNumber(value)}
            className={styles.numberBox}
            min={0}
            value={_.get(filter, item?.children?.[0].key || '')}
            onChange={(event) => {
              onNumberChange(item?.children?.[0]?.key || '', item?.children?.[1]?.key || '', event, 'min')
            }}
            disabled={item.comingSoon}
          />
        </Col>
        <Col span={4}>
          {!item.comingSoon && <span className={'rangeSeparator'}>-</span>}
          {item.comingSoon && <LockComponent className={styles.soonTag} />}
        </Col>
        <Col span={10}>
          <InputNumber
            formatter={(value) => shortenNumber(value)}
            parser={(value) => expandShortNumber(value)}
            addonBefore={item?.children?.[1]?.hasCurrency && '$'}
            placeholder={shortenNumber(item?.children?.[1]?.defaultValue)}
            min={min || _.get(filter, item?.children?.[0].key) || 0}
            step={item?.children?.[1]?.steps}
            className={styles.numberBox}
            value={_.get(filter, item?.children?.[1].key || '')}
            onChange={(event) => {
              onNumberChange(item?.children?.[0]?.key || '', item?.children?.[1]?.key || '', event, 'max')
            }}
            disabled={item.comingSoon}
          />
        </Col>
      </Row>

      <Modal
        open={openContactSales}
        onCancel={() => setOpenContactSales(false)}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}>
        <div className='flex justify-content-center'>
          <BlurPaywall
            addon={'This filter'}
            component={[]}
            onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
            customClass='contactSalesNoMargin'
          />
        </div>
      </Modal>
    </>
  )
}

export default Range
