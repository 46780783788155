import React, { useEffect, useState } from 'react'
import { Card, Typography, Progress, Input, Tooltip } from 'antd'
import styles from './ProfileSettings.module.scss'
import { useNavigate } from 'react-router-dom'
import BuyCreditsModal from 'common/components/BuyConvrt/BuyCreditsModal'
import { useLocalStorage } from 'common/hooks/useLocalStorage.hooks'
import { InfoCircleOutlined } from '@ant-design/icons'

const { Title, Text } = Typography

const planMapping: any = {
  go_to_market: 'Go To Market',
  connect_plus: 'Connect+',
  market_dominance: 'Market Dominance',
  custom: 'Custom',
  trial: 'Trial',
  TRIAL: 'Trial',
  PAID_TRIAL: 'Trial',
  BETA: 'Trial',
}

interface ProfileSettingsProps {
  shouldHideCheckout: any
}

const ProfileSettings: React.FC<ProfileSettingsProps> = ({ shouldHideCheckout }) => {
  const [user] = useLocalStorage('user', null)
  const userlocalStorage = localStorage.getItem('user') || '{}'
  const [userData, setUserData] = useState(JSON.parse(userlocalStorage))
  // const userData = JSON.parse(userlocalStorage)

  const navigate = useNavigate()
  const [openBuyCreditsModal, setOpenBuyCreditsModal] = useState(false)
  const [points, setPoints] = useState(user.convrt_points)
  const fullName = user?.name
  const nameArray = fullName.split(' ')
  const firstName = nameArray[0]
  const lastName = nameArray.slice(1).join(' ')
  const yourPlan = user?.plan_type
    ? planMapping[user?.plan_type]
    : user?.subscription_type === 'BETA'
    ? 'Trial'
    : user?.subscription_type

  const handleNavigation = () => {
    if (shouldHideCheckout) {
    } else {
      navigate('/checkout')
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      const saved2 = localStorage.getItem('user') || ''
      const u = JSON.parse(saved2)
      setPoints(u.convrt_points)
      setUserData(u)
    }, 250)
    return () => clearTimeout(timer)
  }, [user])

  // useEffect(() => {
  //   const userlocalStorage = localStorage.getItem('user') || '{}'
  //   // const userData = JSON.parse(userlocalStorage)
  //   setUserData(JSON.parse(userlocalStorage))
  // }, [user])

  const pointsTable = (
    <div className={styles.pointsTable}>
      <table>
        <tr>
          <th style={{ borderRadius: '8px 0px 0px 0px' }}>Feature</th>
          <th style={{ borderRadius: '0px 8px 0px 0px' }}>Points Cost</th>
        </tr>
        <tr>
          <td>Telegram Expose</td>
          <td>50</td>
        </tr>
        <tr>
          <td>Phone Expose</td>
          <td>50</td>
        </tr>
        <tr>
          <td>Contacts Export</td>
          <td>20</td>
        </tr>
        <tr>
          <td>Company Export</td>
          <td>20</td>
        </tr>
        <tr>
          <td>Expose Communities</td>
          <td>20</td>
        </tr>
        <tr>
          <td>Email Expose</td>
          <td>15</td>
        </tr>
        <tr>
          <td>Linkedin Expose</td>
          <td>15</td>
        </tr>
        <tr>
          <td>Group Members Expose</td>
          <td>5</td>
        </tr>
        <tr>
          <td>Group Members With First Name Expose</td>
          <td>7</td>
        </tr>
        <tr>
          <td>Group Admins Members Expose</td>
          <td>40</td>
        </tr>
        {/* <tr>
          <td>LinkedIn Expose</td>
          <td>0</td>
        </tr>
        <tr>
          <td>Twitter/X Expose</td>
          <td>0</td>
        </tr> */}
      </table>
    </div>
  )

  return (
    <Card className={styles.profileCard}>
      <Title level={3}>Profile Settings</Title>
      <Card className={styles.profileInputsCard}>
        <div className={styles.inputRow} style={{ marginBottom: '20px' }}>
          <div className={styles.inputContainer}>
            <Text className={styles.inputTitle}>First Name</Text>
            <Input disabled placeholder='First Name' value={firstName} />
          </div>
          <div className={styles.inputContainer}>
            <Text className={styles.inputTitle}>Last Name</Text>
            <Input disabled placeholder='Last Name' value={lastName} />
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.inputContainer}>
            <Text className={styles.inputTitle}>Title</Text>
            <Input disabled placeholder='' />
          </div>
          <div className={styles.inputContainer}>
            <Text className={styles.inputTitle}>Timezone</Text>
            <Input disabled placeholder='' />
          </div>
        </div>
      </Card>

      <div className={styles.yourPlan}>
        <p style={{ fontSize: '20px' }}>Your Plan</p>
        <p style={{ fontSize: '35px' }}>
          {yourPlan}{' '}
          {!shouldHideCheckout && (
            <span
              style={{ color: '#7043ff', fontWeight: 600, cursor: 'pointer', fontSize: '16px' }}
              onClick={handleNavigation}>
              Upgrade
            </span>
          )}
        </p>
      </div>

      <div className={styles.progressBarContainer}>
        <Text className={styles.progressText}>
          {`${user.initial_convrt_points - points || 0} / ${user.initial_convrt_points || 0} Convrt Points Used`}
          <Tooltip
            title={pointsTable}
            placement='bottom'
            trigger={['hover']}
            color='transparent'
            overlayInnerStyle={{ boxShadow: 'none', width: '400px' }}>
            <InfoCircleOutlined style={{ cursor: 'pointer', marginLeft: '5px' }} />
          </Tooltip>
        </Text>
        <Progress
          percent={
            user.initial_convrt_points ? ((user.initial_convrt_points - points) / user.initial_convrt_points) * 100 : 0
          }
          // size={[1400, 30]}
          status='active'
          showInfo={false}
          strokeColor={'#7043ff'}
        />
        <div className={styles.buyMoreBar}>
          <Text
            className={styles.buyMore}
            onClick={() => {
              setOpenBuyCreditsModal(true)
            }}>
            Buy More
          </Text>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <Text className={styles.progressText}>
            {`${
              userData?.initial_credits?.contacts_outreached_left - userData?.credits?.contacts_outreached_left || 0
            } / ${userData?.initial_credits?.contacts_outreached_left || 0} Contacts Outreached`}
          </Text>
          <Progress
            percent={
              userData?.initial_credits
                ? ((userData?.initial_credits?.contacts_outreached_left - userData?.credits?.contacts_outreached_left) /
                    userData?.initial_credits?.contacts_outreached_left) *
                  100
                : 0
            }
            // size={[400, 30]}
            status='active'
            showInfo={false}
            strokeColor={'#7043ff'}
          />
        </div>
        <div className={styles.buyMoreBar}>
          <Text
            className={styles.buyMore}
            onClick={() => {
              navigate('/checkout')
            }}>
            Upgrade Plan
          </Text>
        </div>
      </div>
      {openBuyCreditsModal && (
        <BuyCreditsModal
          open={openBuyCreditsModal}
          onClose={() => {
            setOpenBuyCreditsModal(false)
          }}
        />
      )}
    </Card>
  )
}

export default ProfileSettings
