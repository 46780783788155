import React, { useState } from 'react'
import _ from 'lodash'
import { Radio, Select } from 'antd'

import { SET_FILTER } from 'features/intent-signals/state/slice/companiesSlice'
import { removeEmpty } from 'common/utils/objectToValues'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import styles from './fancy-filter-expand-item-tabs-select.module.scss'

type TabPosition = 'twitter' | 'discord' | 'telegram'
interface IFancyFilterExpandItemTabs {
  data: any
}
const FancyFilterExpandItemTabsSelect: React.FC<IFancyFilterExpandItemTabs> = ({ data }) => {
  const [mode, setMode] = useState<TabPosition>('twitter')
  const [current, serCurrent] = useState<any>(data[0])
  const dispatch = useAppDispatch()
  const { filter } = useAppSelector((state) => state.companies)

  const handleFilter = (key: string, value: any) => {
    const filterCopy = JSON.parse(JSON.stringify(filter))
    _.set(filterCopy, key, value)
    dispatch(SET_FILTER(removeEmpty(filterCopy)))
  }

  return (
    <div className={styles.tabsContainer}>
      {data?.length > 0 && (
        <>
          <Radio.Group
            className={styles.tabsBox}
            onChange={(event) => {
              setMode(event.target.value)
              data.forEach((element: any) => {
                if (element.key.indexOf(event.target.value) > 0) serCurrent(element)
              })
            }}
            value={mode}
            style={{ marginBottom: 8 }}>
            {data?.map((item: any, idx: number) => {
              return (
                <Radio.Button
                  className='w-full flex justify-content-center'
                  value={item.key.split('.')[item.key.split('.').length - 1]}
                  key={idx}>
                  {item.label}
                </Radio.Button>
              )
            })}
          </Radio.Group>
          {current && (
            <div className='fullWidth'>
              <Select
                maxTagCount={3}
                className={styles.selectBox}
                placeholder='Choose...'
                value={_.get(filter, current?.children[0]?.key)}
                onChange={(event) => {
                  handleFilter(current?.children[0]?.key || '', event)
                }}
                showSearch={false}
                options={current?.children[0]?.options}
                filterOption={current?.children[0]?.options}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default FancyFilterExpandItemTabsSelect
