import React from 'react'
import { Button, Spin, Typography } from 'antd'
import styles from './EnrichContactModal.module.scss'
import enrich_contact_point_icon from 'common/assets/svg/enrich_contact_point.svg'
import { useAppSelector } from 'state'
import { toNumber } from 'lodash'
import BuyCreditsModal from 'common/components/BuyConvrt/BuyCreditsModal'
import { LoadingOutlined } from '@ant-design/icons'
import { numberFormatter } from 'helper'

export const EnrichCost = () => {
  const [openBuyPoint, setOpenBuyPoint] = React.useState(false)
  const { enrichPoints, userPoints, isLoadingEnrich } = useAppSelector((state) => state.importContact)
  const totalPoints = enrichPoints?.totalPoints || 0

  return (
    <div className={styles.EnrichContactModal__body__channel__right}>
      <div className={styles.EnrichContactModal__body__channel__right__text}>
        <Typography>Enrichment Cost</Typography>
      </div>
      <div className={styles.EnrichContactModal__body__channel__right__point}>
        <img src={enrich_contact_point_icon} alt='point' />
        {isLoadingEnrich ? (
          <div style={{ display: 'flex', gap: '12px' }}>
            <Spin style={{ margin: '0 10px' }} indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
            <Typography>Calculating Points....</Typography>
          </div>
        ) : (
          <Typography>{totalPoints} Points</Typography>
        )}
        <Typography>
          You will be charged up to {totalPoints} points out of your{' '}
          {numberFormatter.format(toNumber(userPoints?.points || 0))} available points
        </Typography>
      </div>
      <Button className={styles.EnrichContactModal__body__channel__right__button} onClick={() => setOpenBuyPoint(true)}>
        Buy Points
      </Button>

      <BuyCreditsModal
        open={openBuyPoint}
        onClose={() => {
          setOpenBuyPoint(false)
        }}
      />
    </div>
  )
}
