import { CloseOutlined } from '@ant-design/icons'
import styles from './TagItem.module.scss'
import { Typography } from 'antd'

interface TagProps {
  title: string
  showClose?: boolean
  onClose?: () => void
}

const TagItem = (props: TagProps) => {
  const { title, onClose } = props

  return (
    <div className={styles.tagWrapper}>
      <Typography className={styles.tagWrapper__title}>{title}</Typography>
      {onClose && <CloseOutlined className={styles.tagWrapper__icon} onClick={onClose} />}
    </div>
  )
}

export default TagItem
