import React, { useRef, useState } from 'react'
//import { useLazySetRecipientsFromCsvQuery } from 'features/Outreach/state/api/OutreachApi'
import Papa from 'papaparse'

import { useAppSelector } from 'state'
import styles from './csv-importer.module.scss'
import { post } from 'common/api/axios'
import { message } from 'antd'

interface CSVRow {
  full_name: string
  company: string
  job_title: string
  telegram_handle: string
}

interface Props {
  onFinish: (campaign_id: any, campaign_name: any, csvData: any) => void
}

const CSVImporter: React.FC<Props> = ({ onFinish }) => {
  // const [refetcSetRecipients, { data: campaignRecipientsData }] = useLazySetRecipientsFromCsvQuery()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isErrorMessage, setIsErrorMessage] = useState(false)
  const { campaign_id, campaign_name } = useAppSelector((state) => state.outreachCampaign)

  const expectedKeys = ['full_name', 'company', 'job_title', 'handle', 'custom_1', 'custom_2']

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    post('/log', {
      action: 'Browse - Import From CSV',
      data: { campaign_id, campaign_name },
    })
    const file = event.target.files?.[0]

    if (file) {
      Papa.parse(file as any, {
        complete: (result) => {
          if (file && file.type === 'text/csv') {
            // console.log('---csv---', result, '---csv---')

            const data = result.data.slice(0) as CSVRow[]

            if (data.length === 0) {
              message.error('The CSV is empty')
              return
            }

            if (result.errors.length > 0) {
              const errorMessage: any = () => {
                const rows = result.errors.map((item: any) => item.row + 1).join(',')
                return `You have a comma inside a word in line/s ${rows} please delete the comma`
              }
              message.error(errorMessage())
              return
            }

            if (data.length > 5000) {
              message.error('There is a limit of 5000 rows for CSV uploads.')
              return
            }
            const hasExpectedKeys = data.every((item) =>
              expectedKeys.every((key) =>
                Object.keys(item).some((itemKey) => itemKey.toLowerCase() === key.toLowerCase()),
              ),
            )

            const csvData = data.filter((item: any) => item.handle !== null)
            if (hasExpectedKeys) {
              onFinish(campaign_id, campaign_name, csvData)
            } else {
              // message.error(
              //   `The CSV fields are not supported, please follow the instructions: Use only full_name, company, job_title, telegram_handle fields`,
              // )
              setIsErrorMessage(true)
            }
          } else {
            message.error('Only CSV files supported')
          }
        },
        // delimiter: ';',
        // beforeParse: (chunk: string) => {
        //   // Custom parsing logic before actual parsing
        //   // For example, you can modify input data, headers, etc.
        //   // You can use parseConfig for additional configuration

        //   // return 'rrr' + chunk
        // },
        // beforeFirstChunk: (chunk) => {
        //   console.log(chunk)
        //   const modifiedCsvString = chunk
        //     .replace(/, /g, '[comma]')
        //     .replace(/,/g, ';')
        //     .replace(/\[comma\]/g, ',')
        //   console.log(modifiedCsvString)
        //   return modifiedCsvString
        // },
        skipEmptyLines: 'greedy',
        header: true, // Set to true if your CSV has headers
        dynamicTyping: true, // Enable to automatically convert string values to numbers, etc.
        // beforeFirstChunk: (chunk) => {
        //   // Remove the line "Do not delete or modify these columns"
        //   return chunk.replace(/Do not delete or modify the column names\s*\n/, '')
        // },

        beforeFirstChunk: (chunk) => {
          const expectedHeadersString = 'full_name,company,job_title,handle,custom_1,custom_2'
          const rows = chunk.split('\n')
          if (
            rows[0].includes('Do not delete or modify the column names') ||
            rows[0].split(',').length !== expectedHeadersString.split(',').length
          ) {
            rows.shift()
          }
          return rows.join('\n')
        },
      })

      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }

  return (
    <div className={styles.container}>
      <input type='file' onChange={handleFileUpload} className={styles.customFileInput} ref={fileInputRef} />
      {isErrorMessage && (
        <div
          className={
            styles.errorMessage
          }>{`The CSV fields are not supported, please follow the instructions: Use only full_name, company, job_title, handle, custom_1, custom_2 fields`}</div>
      )}
    </div>
  )
}

export default CSVImporter
