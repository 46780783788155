import React from 'react'
import styles from './CustomLeadsTag.module.scss'
import sent_leads_tag from 'common/assets/svg/sent_leads_tag.svg'
// import failed_leads_tag from 'common/assets/svg/failed_leads_tag.svg'

interface CustomLeadsTagProps {
  //   tag: string | 'sent' | 'replied' | 'failed' | 'blocked' | 'pending'
  data: any
}

const CustomLeadsTag: React.FC<CustomLeadsTagProps> = ({ data }) => {
  const sent = data?.sent
  const replied = data?.replied
  const blocked = data?.blocked

  const text = blocked ? 'blocked' : replied ? 'replied' : sent ? 'sent' : 'pending'
  const image = text === 'sent' ? sent_leads_tag : undefined
  //   const image = text === 'sent' ? sent_leads_tag : text === 'failed' ? failed_leads_tag : undefined

  //   const text = tag === 'failed' ? 'running' : tag
  //   const image = tag === 'sent' ? sent_leads_tag : tag === 'failed' ? failed_leads_tag : undefined
  return (
    <div className={styles.container}>
      <div className={`${styles.tag} ${styles[text]}`}>
        {image && <img src={image} alt={text} />}
        <span>{text?.toLocaleUpperCase()}</span>
      </div>
    </div>
  )
}

export default CustomLeadsTag
