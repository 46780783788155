import React, { useEffect, useState, SyntheticEvent } from 'react'
import { useCookies } from 'react-cookie'
import {
  Modal,
  Col,
  Collapse,
  Divider,
  Empty,
  Input,
  Progress,
  Row,
  Tabs,
  TabsProps,
  Tooltip as TooltipText,
  Button,
  Popconfirm,
} from 'antd'
import { useAppDispatch } from 'state/hooks'
import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import c_linkedin from 'common/assets/svg/c_linkedin.svg'
import c_twitter from 'common/assets/svg/c_twitter.svg'
import briefcase from 'common/assets/svg/briefcase.svg'
import location from 'common/assets/svg/location.svg'
import OutOfCreditsModal from 'common/components/OutOfCredits/OutOfCreditsModal'

import { DrawerLebels } from 'common/constants/label.constants'
import ReadMoreLess from 'common/components/read-more-less/read-more-less'
import { CommonServices } from 'common/services/common-services'
import social_double from 'common/assets/svg/social_double.svg'
import audit_amount from 'common/assets/svg/audit_amount.svg'
import show_in_contacts from 'common/assets/svg/show_in_contacts.svg'
import social_instagram from 'common/assets/svg/social_instagram.svg'
import social_send from 'common/assets/svg/social_send.svg'
import social_twitter from 'common/assets/svg/social_twitter.svg'
import cube from 'common/assets/svg/cube.svg'
import { ReactComponent as LockComponent } from 'common/assets/svg/lock.svg'
import LineChart from 'common/components/LineChart/LineChart'
import RateData from 'common/components/rate_data/rate_data'
import PercentageLine from 'common/components/percentage-line/percentage-line'
import { usersBlockedList } from 'common/static-data/userData'
import { countriesWithPhonePrefix } from 'common/static-data/countires'
import FancyDrawer from 'common/components/fancy-drawer/fancy-drawer'

import { ICompany } from 'features/intent-signals/interfaces/company.interface'
import {
  SET_COMPANY,
  SET_IS_FILTER_CHANGED,
  SET_GLOBAL_COMPANY_SELECTED,
  SET_SELECTED_COMPANIES_DETAILS,
} from 'features/intent-signals/state/slice/companiesSlice'

import { useGetContactMutation, useSetExposeMutation } from 'features/contacts/state/api/ContactsApi'
import ContactDetails from 'features/contacts/components/contact-details/contact-details'
import {
  useLazyGetTrackingSocialLinksQuery,
  useLazyGetTrackingQuery,
} from 'features/intent-signals/state/api/CompaniesApi'
import { FancyImage } from 'features/contacts/components/FancyImage/FancyImage'

import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis } from 'recharts'
import BlurPaywall from '../paywalls/BlurPaywall'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import classnames from 'classnames'
import {
  getApproximateByFirstDigit,
  getApproximateHeadcount,
  getApproximateMarketDominance,
  getApproximateSocialNumbers,
} from 'features/StartScreen/utils/getApproximateAmount'

import SocialDropdown from './SocialDropdown'
import { outreach } from 'common/constants/outreach.constants'

import './company-details.scss'
import { ConfirmBox } from 'common/constants/modal.constants'
import { useDisclaimer } from 'common/hooks/useDisclaimer'
import { useLazyGetPointsStoreQuery } from 'features/settings/state/api/SettingsApi'

interface ICompanyDetails {
  data: ICompany
  toggleHide?: (value?: boolean) => void
  showCompany?: any
}

const { Panel } = Collapse
const { Search } = Input

const CompanyDetails: React.FC<ICompanyDetails> = ({ data, toggleHide, showCompany }) => {
  const [userPoints, setUserPoints] = useState<any>()
  const [refetchGetPointsStore] = useLazyGetPointsStoreQuery()

  useEffect(() => {
    const fetchData = async () => {
      const points_store = await refetchGetPointsStore()
      setUserPoints({
        points: points_store?.data?.response?.points,
        pointsStore: points_store?.data?.response?.pointsStore,
      })
    }
    fetchData().catch(console.error)
  }, [])
  const navigate = useNavigate()
  const { search, pathname } = useLocation()

  const { techStack, onChain, contacts, web2metrics } = data || {}
  const { selectedId } = useParams()
  const [refetchTracking] = useLazyGetTrackingQuery()
  const [refetchTrackingSocialLinks] = useLazyGetTrackingSocialLinksQuery()
  const [refetchGetContact, { data: contact }] = useGetContactMutation()
  const [techStackData, setTechStackData] = useState([])
  const [linkedinAlreadyValue, setLinkedinAlreadyValue] = useState<any>()
  const [twitterAlreadyValue, setTwitterAlreadyValue] = useState<any>()
  const [popconfirmLinkedin, setPopconfirmLinkedin] = useState(false)
  const { showDisclaimer, toggleShowDisclaimer } = useDisclaimer()
  const [popconfirmTwitter, setPopconfirmTwitter] = useState(false)
  const [openOutOfCreditsModal, setOpenOutOfCreditsModal] = useState(false)
  const [limitOpenOutOfCreditsModal, setLimitOpenOutOfCreditsModal] = useState('points')
  const [captionCreditsModal, setCaptionCreditsModal] = useState('')
  // eslint-disable-next-line
  const [refetchExpose, { data: expose }] = useSetExposeMutation()
  const [showContactDetails, setShowContactDetails] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [openContactSales, setOpenContactSales] = useState(false)
  const [, setCookie] = useCookies(['selected_companies'])
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (techStack) setTechStackData(techStack)
  }, [techStack])

  useEffect(() => {
    if (!selectedId) return
    const fetchData = async () => {
      setShowContactDetails(true)
      await refetchGetContact(selectedId)
    }

    fetchData()
  }, [selectedId])

  const trafficCountries = countriesWithPhonePrefix.map(({ label, countryCode }) => ({
    label,
    value: countryCode?.toLowerCase() || '',
  }))

  let userDetails: any = null
  if (localStorage.getItem('user') !== null) {
    userDetails = JSON.parse(JSON.stringify(localStorage.getItem('user')))
  }

  const handleSearchTS = (value: string) => {
    if (techStack)
      setTechStackData(techStack.filter((item: any) => item.name.toLowerCase().includes(value.toLowerCase())))
  }

  useEffect(() => {
    // if (contact?.response?.linkedin_already !== undefined) {
    setLinkedinAlreadyValue(contact?.response?.linkedin_already)
    // }
    // if (contact?.response?.twitter_already !== undefined) {
    setTwitterAlreadyValue(contact?.response?.twitter_already)
    // }
  }, [contact])

  const onClickLinkedIn = (e: SyntheticEvent) => {
    e.preventDefault()
    if (contact?.response?.linkedin_already) {
      window.open(contact?.response?.linkedin_already, '_blank')
    } else {
      if (linkedinAlreadyValue) window.open(linkedinAlreadyValue, '_blank')
      else setPopconfirmLinkedin(true)
    }
  }

  const handleClickLinkedIn = async () => {
    setPopconfirmLinkedin(false)
    const key = 'linkedin'
    const result = await refetchExpose({ selectedId: contact?.response?.index, key })

    if (result?.error?.status === 470) {
      setOpenOutOfCreditsModal(true)
      setLimitOpenOutOfCreditsModal('limit')
      setCaptionCreditsModal('linkedin')
      // message.error(result?.error.data.message)
    } else {
      setLinkedinAlreadyValue(result.data.response.exposeData.linkedin)
      window.open(result.data.response.exposeData.linkedin, '_blank')
    }
  }

  const handleClickTwitter = async () => {
    setPopconfirmTwitter(false)
    const key = 'twitter'
    const result = await refetchExpose({ selectedId: contact?.response?.index, key })

    if (result?.error?.status === 470) {
      setOpenOutOfCreditsModal(true)
      setLimitOpenOutOfCreditsModal('limit')
      setCaptionCreditsModal('twitter')

      //message.error(result?.error.data.message)
    } else {
      setTwitterAlreadyValue(result.data.response.exposeData.twitter)
      window.open(result.data.response.exposeData.twitter, '_blank')
    }
  }

  const convertString = (str: string) => {
    // eslint-disable-next-line
    const parts = str.replace(/[\[\]"'"]/g, '').split(',')
    const converted = parts.map((part: string) => part.trim()).join(' ')
    return converted
  }

  const tags: string[] = []

  if (contact?.response?.tags) {
    const tagsList = JSON.parse(contact?.response?.tags)
    tagsList.forEach((element: any) => {
      element.value !== '' && element.type !== 'name' && tags.push(convertString(element.value))
    })
  }

  const onClickTwitter = (e: SyntheticEvent) => {
    e.preventDefault()

    // setPopconfirmTwitter(true)
    if (contact?.response?.twitter_already) {
      window.open(contact?.response?.twitter_already, '_blank')
    } else {
      if (twitterAlreadyValue) window.open(twitterAlreadyValue, '_blank')
      else setPopconfirmTwitter(true)
    }
  }

  const handleShowContact = async (id: any) => {
    dispatch(SET_COMPANY(data))
    toggleHide && toggleHide(false)
    setTimeout(async () => {
      setLoading(true)
      setShowDetails(true)
      const contactDetails = await refetchGetContact(id)

      if (contactDetails) setLoading(false)
    }, 500)
  }

  const CompanyDetailsTabs: TabsProps['items'] = [
    {
      key: 'people',
      label: `People`,
      children: (
        <>
          <div className='tabSection'>
            <p className='title'>
              {DrawerLebels.DECISION_MARKETS_CONTACTS}{' '}
              <RateData company_id={data?.id} section={'decision_markets_contacts'} />
            </p>
            {contacts?.importantContacts?.slice(0, 4).map((contact, index: number) => (
              <Row
                key={index}
                className='tabContent cursorPointer'
                onClick={() => {
                  handleShowContact(contact?.index)
                }}>
                <Col className='left' span={14}>
                  <FancyImage url={contact?.photo_url} name={String(contact.full_name)} size={24} marginRight={12} />
                  <TooltipText placement='topLeft' title={String(contact.full_name)}>
                    <span className='flexDisplay'>{contact.full_name}</span>
                  </TooltipText>
                </Col>
                <Col className='right' span={10}>
                  <TooltipText placement='topLeft' title={String(contact.job_title)}>
                    <span className={'tsList'}>{contact.job_title}</span>
                  </TooltipText>
                </Col>
              </Row>
            ))}
            <Button
              className='showInContacts'
              onClick={(e: any) => {
                dispatch(SET_IS_FILTER_CHANGED(false))
                dispatch(SET_GLOBAL_COMPANY_SELECTED([data?.id]))
                dispatch(SET_SELECTED_COMPANIES_DETAILS([]))
                let expires = new Date()
                expires.setTime(expires.getTime() * 1000)
                setCookie('selected_companies', [data?.id], { path: '/', expires })

                navigate(`/contacts`)
              }}>
              <img src={show_in_contacts} alt={''} />
              <span>{DrawerLebels.SHOW_IN_CONTACTS}</span>
            </Button>
          </div>
        </>
      ),
    },
    {
      key: 'onChain',
      label: `On-Chain`,
      children: (
        <>
          {onChain?.monthly_active_users ||
          onChain?.token_name ||
          onChain?.tvl ||
          onChain?.sc_count ||
          onChain?.total_users ||
          onChain?.chains_repartition ||
          onChain?.scdr?.length > 0 ? (
            <>
              <div className='tabSection'>
                <p className='title'>
                  {DrawerLebels.ON_CHAIN_REVENUE} <RateData company_id={data?.id} section={'on_chain_overview'} />
                </p>
                <Row gutter={16} className='rowCustom'>
                  <Col span={8}>
                    <div className='card'>
                      <div className='left'>
                        <span className='title'>{DrawerLebels.SMART_CONTRACT}</span>
                        <span className='value'>{onChain?.sc_count ? onChain?.sc_count : 0}</span>
                      </div>
                      <div className='right'>
                        <img src={'/svg/cr_smartcontract.svg'} alt={''} />
                      </div>
                      {/* <Row>
                        <Col span={20}>
                          <div className='left'>
                            <span className='title'>{DrawerLebels.SMART_CONTRACT}</span>
                            <span className='value'>{onChain?.sc_count ? onChain?.sc_count : 0}</span>
                          </div>
                        </Col>
                        <Col span={4}>
                          <div className='right'>
                            <img src={'/svg/cr_smartcontract.svg'} alt={''} />
                          </div>
                        </Col>
                      </Row> */}
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className='card'>
                      <Row>
                        <div className='left'>
                          <span className='title'>{DrawerLebels.TOKEN_NAME}</span>
                          <span className='value'>
                            <TooltipText
                              placement='topLeft'
                              title={`${onChain?.token_name} (${onChain?.token_symbol})`}>
                              <span className={'value'}>${onChain?.token_symbol}</span>
                            </TooltipText>
                          </span>
                        </div>
                        <div className='right'>
                          <img src={'/svg/cr_tokenname.svg'} alt={''} />
                        </div>
                        {/* <Col span={20}>
                          <div className='left'>
                            <span className='title'>{DrawerLebels.TOKEN_NAME}</span>
                            <span className='value'>
                              <TooltipText
                                placement='topLeft'
                                title={`${onChain?.token_name} (${onChain?.token_symbol})`}>
                                <span className={'value'}>${onChain?.token_symbol}</span>
                              </TooltipText>
                            </span>
                          </div>
                        </Col>
                        <Col span={4}>
                          <div className='right'>
                            <img src={'/svg/cr_tokenname.svg'} alt={''} />
                          </div>
                        </Col> */}
                      </Row>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className='card'>
                      <div className='left'>
                        <span className='title'> {DrawerLebels.TVL}</span>
                        <span className='value'>
                          {onChain?.tvl === null ? 'N/A' : `$${CommonServices.convertNumber(Number(onChain?.tvl))}`}
                        </span>
                      </div>
                      <div className='right_tvl'>
                        <img src={'/svg/cr_TVL.svg'} alt={''} />
                      </div>
                      {/* <Row>
                        <Col span={20}>
                          <div className='left'>
                            <span className='title'> {DrawerLebels.TVL}</span>
                            <span className='value'>
                              {onChain?.tvl === null ? 'N/A' : `$${CommonServices.convertNumber(Number(onChain?.tvl))}`}
                            </span>
                          </div>
                        </Col>
                        <Col span={4}>
                          <div className='right'>
                            <img src={'/svg/cr_TVL.svg'} alt={''} />
                          </div>
                        </Col>
                      </Row> */}
                    </div>
                  </Col>
                </Row>
                {/* <Row gutter={16} className='rowCustom'>
                  <Col span={12}>
                    <div className='card'>
                      <Row>
                        <Col span={18}>
                          <div className='left'>
                            <span className='title'> {DrawerLebels.TVL}</span>
                            <span className='value'>
                              {onChain?.tvl === null ? 'N/A' : `$${CommonServices.convertNumber(Number(onChain?.tvl))}`}
                            </span>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div className='right'>
                            <img src={'/svg/cr_TVL.svg'} alt={''} />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='card'>
                      <Row>
                        <Col span={18}>
                          <div className='left'>
                            <span className='title'>{DrawerLebels.ACTIVE_WALLETS}</span>
                            <span className='value'>{getApproximateByFirstDigit(onChain?.monthly_active_users)}</span>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div className='right'>
                            <img src={'/svg/cr_activewallets.svg'} alt={''} />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row> */}
              </div>
              <Divider />
              {onChain?.whales && onChain?.total_users && (
                <>
                  <div className='tabSection'>
                    <p className='title'>
                      {DrawerLebels.WHALES_DOMINATION} <RateData company_id={data?.id} section={'whales_domain'} />
                    </p>
                    <p className='blueText'>{((onChain?.whales / onChain?.total_users) * 100).toFixed(2)}%</p>
                  </div>
                  <Divider />
                </>
              )}
              <div className='tabSection'>
                <p className='title'>
                  {DrawerLebels.CHAIN_DISTRIBUTION} <RateData company_id={data?.id} section={'chain_distribution'} />
                </p>
                <PercentageLine data={onChain?.chains_repartition} />
              </div>
              {onChain?.audits?.length > 0 && (
                <div className='tabSection'>
                  <Divider />
                  <p className='title'>
                    {DrawerLebels.AUDITS} <RateData company_id={data?.id} section={'audits'} />
                  </p>
                  <div className='auditsContainer'>
                    {onChain?.audits?.map((element: any) => (
                      <div className='item'>
                        <Row>
                          <Col className='leftAudit' span={18}>
                            <img className='logo' src={element?.s3_logo} alt='' />
                            <TooltipText placement='topLeft' title={element?.name}>
                              <span className='textTooltipLists'>{element?.name}</span>
                            </TooltipText>
                          </Col>
                          <Col className='rightAudit' span={6}>
                            <div className='image-container'>
                              <img src={audit_amount} alt='' />
                              <div className='text-overlay'>
                                <p>{element?.audit_count}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {onChain?.scdr?.length > 0 && (
                <div className='tabSection'>
                  <Divider />
                  <div className='smartContracts'>
                    <p className='title'>
                      {DrawerLebels.SCDR} <RateData company_id={data?.id} section={'scdr'} />
                    </p>
                    <ResponsiveContainer height={200} className={'fancyAreaChart'}>
                      <AreaChart
                        height={200}
                        data={CommonServices.dataGraph(onChain?.scdr)}
                        syncId='anyId'
                        margin={{
                          top: 10,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}>
                        <CartesianGrid horizontal={false} />
                        <XAxis dataKey='name' />
                        <Tooltip
                          content={(element: any) => {
                            return (
                              <>
                                <span className='labelContracts'>{`${element?.label}`}</span>
                                <span className={'numberOfContracts'}>{`${element?.payload[0]?.value} Contracts`}</span>
                              </>
                            )
                          }}
                        />
                        <Area type='monotone' dataKey='pv' stroke='#7043FF' fill-opacity='#7043FF' />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className='noChainData'>
              <img src={cube} alt='' />
              <p>{`It seems you selected a Web2 company with no on-chain data.
Continue to the next tab.`}</p>
            </div>
          )}
        </>
      ),
    },
    {
      key: 'web2metrics',
      label: `Web2 Metrics`,
      children: (
        <>
          {userDetails && !usersBlockedList.includes(JSON.parse(userDetails).email) ? (
            <>
              <div className='tabSection'>
                <p className='title'>
                  {DrawerLebels.WEB_TWO_TRAFIC} <RateData company_id={data?.id} section={'web_two_trafic'} />
                </p>
                <p className='blueText'>
                  {JSON.parse(userDetails).addons &&
                    JSON.parse(userDetails).addons['web2metrics'] &&
                    getApproximateByFirstDigit(web2metrics?.website_traffic)}
                  <span>{DrawerLebels.VISITS_LAST_MONTHS}</span>
                </p>
              </div>
              {web2metrics?.top_countries &&
                JSON.parse(JSON.parse(JSON.stringify(web2metrics?.top_countries))).length > 0 && (
                  <>
                    <Divider />
                    <div className='tabSection'>
                      <p className='title bottomMargin'>
                        {DrawerLebels.TOP_GEOS} <RateData company_id={data?.id} section={'top_countries'} />
                      </p>
                      {web2metrics?.top_countries &&
                        JSON.parse(JSON.parse(JSON.stringify(web2metrics?.top_countries))).map((element: any) => {
                          let getCountryName = trafficCountries?.filter((item: any) => item?.value === element?.flag)

                          return (
                            <Row className='spaceCountryRow'>
                              <Col className='geoImage' span={12}>
                                <img src={element.url} alt={''} />
                                <span>{getCountryName.length > 0 && getCountryName[0].label}</span>
                              </Col>
                              <Col span={12}>
                                <Progress
                                  percent={element.percent * 100}
                                  className='customProgress'
                                  format={(percent) => `${percent?.toFixed(0)}%`}
                                />
                              </Col>
                            </Row>
                          )
                        })}
                    </div>
                  </>
                )}
              {web2metrics?.traffic_sources &&
                JSON.parse(JSON.parse(JSON.stringify(web2metrics?.traffic_sources))).length > 0 && (
                  <>
                    <Divider />
                    <div className='tabSection'>
                      <p className='title bottomMargin'>
                        {DrawerLebels.TRAFIC_SOURCES} <RateData company_id={data?.id} section={'trafic_source'} />
                      </p>
                      {web2metrics?.traffic_sources &&
                        JSON.parse(JSON.parse(JSON.stringify(web2metrics?.traffic_sources))).map(
                          (element: any, index: number) => (
                            <Row key={index} className='spaceCountryRow'>
                              <Col className='geoImage' span={12}>
                                <img
                                  className='noRadius'
                                  src={CommonServices.handleTraficSourcesImage(element.source)}
                                  alt={''}
                                />
                                <span>{element.source}</span>
                              </Col>
                              <Col span={12}>
                                <Progress
                                  percent={element.percentage * 100}
                                  format={(percent) => `${percent?.toFixed(0)}%`}
                                  className='customProgress'
                                />
                              </Col>
                            </Row>
                          ),
                        )}
                    </div>
                  </>
                )}
            </>
          ) : (
            <div className={'blockContent'}>
              <LockComponent
                className={'blockIcon'}
                onClick={() => {
                  setOpenContactSales(true)
                }}
              />
            </div>
          )}
        </>
      ),
    },
    {
      key: 'techStack',
      label: `Technologies`,
      children: (
        <>
          {userDetails && !usersBlockedList.includes(JSON.parse(userDetails).email) ? (
            <>
              <div className='tabSection'>
                <p className='title'>
                  {DrawerLebels.TECH_STACK_OVERVIEW} <RateData company_id={data?.id} section={'tech_stack_overview'} />
                  <Search
                    className='techStackSearch'
                    placeholder='Search Technologies'
                    allowClear
                    onChange={(event) => {
                      handleSearchTS(event.target.value)
                    }}
                  />
                </p>
                {techStackData?.map((element: any, index: number) => {
                  return (
                    <Row key={index} className='tabContent'>
                      <Col className='left' span={12}>
                        <TooltipText placement='topLeft' title={String(element.name)}>
                          <span>{element.name}</span>
                        </TooltipText>
                      </Col>
                      <Col className='right' span={12}>
                        {element?.array_agg.map((item: string, index: number) => (
                          <TooltipText key={index} placement='topLeft' title={String(item)}>
                            <span className={'tsList'}>{item}</span>
                          </TooltipText>
                        ))}
                      </Col>
                    </Row>
                  )
                })}
              </div>
            </>
          ) : (
            <div className={'blockContent'}>
              <LockComponent
                className={'blockIcon'}
                onClick={() => {
                  setOpenContactSales(true)
                }}
              />
            </div>
          )}
        </>
      ),
    },

    {
      key: 'funding',
      label: `Funding`,
      children: (
        <>
          <div className='tabSection'>
            {data?.funding?.total && (
              <>
                <p className='title'>
                  {DrawerLebels.TOTAL_FUNDING} <RateData company_id={data?.id} section={'founding_rounds'} />
                </p>
                <Row className='tabContent'>
                  <Col className='left' span={14}>
                    <span className='fundingAmount totalFunding'>{`${`$${CommonServices.convertNumber(
                      Number(data?.funding?.total),
                    )}`}`}</span>
                  </Col>
                </Row>
              </>
            )}
            <p className='title'>
              {data?.funding?.rounds.length ? DrawerLebels.FUNDING_ROUNDS : 'No Disclosed Funding Data Available'}{' '}
              <RateData company_id={data?.id} section={'founding_rounds'} />
            </p>
            {data?.funding?.rounds &&
              data.funding.rounds.map((element: any) => (
                <Row className='tabContent'>
                  <Col className='left' span={14}>
                    <span className='fundingAmount'>{`${
                      element.raw_amount
                        ? `$${CommonServices.convertNumber(Number(element.raw_amount))}`
                        : 'Undisclosed'
                    }`}</span>
                    <span className='fundingType'>{element.funding_type}</span>
                  </Col>
                  <Col className='right' span={10}>
                    <span className='fundingDate'>{element?.date && CommonServices.convertToDate(element.date)}</span>
                  </Col>
                </Row>
              ))}
          </div>
        </>
      ),
    },
  ]

  const onChangeTab = (key: string) => {
    refetchTracking({ key: key, company_id: data.id })
  }

  return (
    <>
      <div className='container'>
        <Collapse defaultActiveKey={['about']} ghost expandIconPosition='right'>
          <Panel header={DrawerLebels.ABOUT} key='about'>
            <p className='headerRate rateMargin'>
              ...
              <RateData company_id={data?.id} section={'about'} />
            </p>
            {data?.description?.length < 100 ? (
              <div className='col-md-7'>
                <p className={'contentBigText'}>{data?.description}</p>
              </div>
            ) : (
              <ReadMoreLess text={data?.description} />
            )}
            <Row>
              <Col span={6}>
                <div className='reportDetails'>
                  <span>{data?.number_of_employees ? getApproximateHeadcount(data?.number_of_employees) : 'N/A'}</span>
                  <p>{DrawerLebels.EMPLOYEES}</p>
                </div>
              </Col>
              <Col span={6}>
                <div className='reportDetails'>
                  <span>{data?.founded_on ? data?.founded_on?.split('-')[0] : 'N/A'}</span>
                  <p>{DrawerLebels.FOUNDED}</p>
                </div>
              </Col>
              <Col span={6}>
                <div className='reportDetails'>
                  <span>
                    {data?.yearly_revenue === null || !Number(data?.yearly_revenue)
                      ? 'Undisclosed'
                      : `$${data?.yearly_revenue}`}
                  </span>
                  <p>{DrawerLebels.REVENUE}</p>
                </div>
              </Col>
              <Col span={6}>
                <div className='reportDetails'>
                  <TooltipText placement='top' title={CommonServices.generateStringFromArr(data?.vertical)}>
                    <span>{data?.vertical ? CommonServices.generateStringFromArr(data?.vertical) : 'N/A'}</span>
                  </TooltipText>
                  {/* <p>{DrawerLebels.INDUSTRY}</p> */}
                  <p>Vertical</p>
                </div>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </div>
      <div className='container'>
        <Collapse defaultActiveKey={['social_strength']} ghost expandIconPosition='right'>
          <Panel header={DrawerLebels.SOCIAL_STRENGTH} key='social_strength'>
            <p className='headerRate rateMargin'>
              ...
              <RateData company_id={data?.id} section={'social_strength'} />
            </p>
            <div className='socialImages'>
              {data?.discord && (
                <div
                  className='item'
                  onClick={() => refetchTrackingSocialLinks({ company_id: data.id, social_link: 'discord' })}>
                  <SocialDropdown
                    icon={social_double}
                    count={data?.discord_followers}
                    addresses={JSON.parse(data?.discord || '[]')}
                  />
                </div>
              )}
              {data?.telegram_followers && (
                <div
                  className='item'
                  onClick={() => refetchTrackingSocialLinks({ company_id: data.id, social_link: 'telegram' })}>
                  <span>
                    <img src={social_send} alt={''} />
                    <span>{getApproximateSocialNumbers(data?.telegram_followers)}</span>
                  </span>
                </div>
              )}
              {data?.instagram && (
                <div
                  className='item'
                  onClick={() => refetchTrackingSocialLinks({ company_id: data.id, social_link: 'instagram' })}>
                  <SocialDropdown
                    icon={social_instagram}
                    count={data?.instagram_followers}
                    addresses={JSON.parse(data?.instagram || '[]')}
                  />
                </div>
              )}
              {data?.twitter && (
                <div
                  className='item'
                  onClick={() => refetchTrackingSocialLinks({ company_id: data.id, social_link: 'twitter' })}>
                  <SocialDropdown
                    icon={social_twitter}
                    count={data?.twitter_followers}
                    addresses={JSON.parse(data?.twitter || '[]')}
                  />
                </div>
              )}
            </div>
          </Panel>
        </Collapse>
      </div>
      <div className='container'>
        <Collapse ghost expandIconPosition='right'>
          <Panel header={DrawerLebels.INTENTS_SIGNALS} key='intents_signals'>
            <p className='headerRate'>
              ...
              <RateData company_id={data?.id} section={'intents_signals'} />
            </p>
            {userDetails && JSON.parse(userDetails).addons && JSON.parse(userDetails).addons['intentsSignals'] ? (
              (data?.intentsSignals && data?.intentsSignals?.length > 0) || data?.funding_flags?.length > 0 ? (
                <LineChart
                  data={data?.intentsSignals}
                  milestones={data?.funding_flags && JSON.parse(data?.funding_flags)}
                />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            ) : (
              <BlurPaywall
                addon={DrawerLebels.INTENTS_SIGNALS}
                onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
                component={[]}
                customClass={'paywallCustom'}
              />
            )}
          </Panel>
        </Collapse>
      </div>

      {data?.similar_companies && JSON.parse(JSON.parse(JSON.stringify(data?.similar_companies))).length > 0 && (
        <div className='container'>
          <Collapse ghost expandIconPosition='right'>
            <Panel header={DrawerLebels.SIMILAR_COMPANIES} key='similar_companies'>
              <p className='headerRate'>
                ...
                <RateData company_id={data?.id} section={'similar_companies'} />
              </p>

              {userDetails &&
              JSON.parse(userDetails).addons &&
              JSON.parse(userDetails).addons['companies_similar_companies'] ? (
                <div>
                  <Row className='similarCompanies'>
                    <Col span={16}>
                      <span className='columnTitle'>{'Company'}</span>
                    </Col>
                    <Col span={8}>
                      <span className='columnTitle'>{'Market Dominance'}</span>
                    </Col>
                  </Row>

                  {data?.similar_companies &&
                    JSON.parse(JSON.parse(JSON.stringify(data?.similar_companies))).map((element: any) => (
                      <Row
                        className='similarCompanies'
                        onClick={() => {
                          showCompany
                            ? showCompany(element.id)
                            : navigate(`/${pathname.split('/')[1]}/${element.id}${search}`)
                        }}>
                        <Col span={16}>
                          <img src={element.logo} alt={''} />
                          <span className='companyName'>{element.name}</span>
                        </Col>
                        <Col span={8}>
                          <span className='percentValue'>{getApproximateMarketDominance(element.market_share)}</span>
                        </Col>
                      </Row>
                    ))}
                </div>
              ) : (
                <BlurPaywall
                  addon={DrawerLebels.SIMILAR_COMPANIES}
                  onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
                  component={[]}
                  customClass={'paywallCustom'}
                />
              )}

              {/* {userDetails?.addons?.companies_similar_companies && (
                <BlurPaywall
                  addon={DrawerLebels.SIMILAR_COMPANIES}
                  onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
                  component={[]}
                  customClass={'paywallCustom'}
                />
              )} */}
            </Panel>
          </Collapse>
        </div>
      )}
      <div className='container tabsSection'>
        <Tabs
          className={'cabmTabsCompanyDetails'}
          defaultActiveKey='1'
          items={CompanyDetailsTabs.map((tab) => ({
            ...tab,
            children:
              userDetails && JSON.parse(userDetails).addons && JSON.parse(userDetails).addons[tab.key] ? (
                tab.children
              ) : (
                <BlurPaywall
                  addon={tab.label}
                  onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
                  component={tab.children}
                />
              ),
          }))}
          onChange={onChangeTab}
        />
      </div>
      <FancyDrawer
        onClose={() => {
          setShowDetails(false)
          toggleHide && toggleHide(true)
        }}
        extraClass={'fancyDrawer extraFancyDrawer'}
        open={showDetails}
        title={
          !loading && (
            <div className={classnames('drawerTitleContact', 'contactLogoTop')}>
              <div className={'socialContacts'}>
                {contact?.response?.linkedin ? (
                  <>
                    <Popconfirm
                      title='Get Linkedin Details'
                      description={
                        <Col>
                          {/* <div>{`You will be redirected by this action. Read disclaimer:`}</div> */}
                          <div>{`This action will cost you ${userPoints?.pointsStore?.linkedin} points. Read disclaimer:`}</div>

                          {showDisclaimer ? (
                            <p className={'disclaimer disclaimerText'} onClick={toggleShowDisclaimer}>
                              {ConfirmBox.DISCLAIMER}
                            </p>
                          ) : (
                            <span className={'disclaimer disclaimerButton'} onClick={toggleShowDisclaimer}>
                              {'Disclaimer'}
                            </span>
                          )}
                        </Col>
                      }
                      open={popconfirmLinkedin && showContactDetails}
                      onConfirm={handleClickLinkedIn}
                      onCancel={() => {
                        setPopconfirmLinkedin(false)
                      }}>
                      <img className='cursor-pointer' src={c_linkedin} onClick={onClickLinkedIn} alt={''} />
                    </Popconfirm>
                  </>
                ) : (
                  <img className='imgOpacity' src={c_linkedin} alt={''} />
                )}

                {contact?.response?.twitter ? (
                  <Popconfirm
                    title='Get Twitter Details'
                    description={
                      <Col>
                        <div>{`You will be redirected by this action. Read disclaimer:`}</div>

                        {/* <div>{`${ConfirmBox.ARE_YOU_SURE}`}</div> */}
                        {showDisclaimer ? (
                          <p className={'disclaimer disclaimerText'} onClick={toggleShowDisclaimer}>
                            {ConfirmBox.DISCLAIMER}
                          </p>
                        ) : (
                          <span className={'disclaimer disclaimerButton'} onClick={toggleShowDisclaimer}>
                            {'Disclaimer'}
                          </span>
                        )}
                      </Col>
                    }
                    open={popconfirmTwitter && showContactDetails}
                    onConfirm={handleClickTwitter}
                    // okButtonProps={{ loading: confirmLoading }}
                    onCancel={() => {
                      setPopconfirmTwitter(false)
                    }}>
                    <img className='cursor-pointer' src={c_twitter} onClick={onClickTwitter} alt={''} />
                  </Popconfirm>
                ) : (
                  <img className='imgOpacity' src={c_twitter} alt={''} />
                )}
              </div>
              {contact && <FancyImage url={contact?.response?.photo_url} name={contact?.response?.full_name} />}
              <p>{contact?.response?.full_name}</p>
              <div className={'subTitle'}>
                <span>
                  <img className='imgOpacity' src={briefcase} alt={''} />
                  <span>{contact?.response?.job_title}</span>
                </span>
                <span>|</span>
                <span>
                  <img className='imgOpacity' src={location} alt={''} />
                  <span>{contact?.response?.location ? contact?.response?.location : 'NA'}</span>
                </span>
              </div>
              <div>
                {tags?.length > 0 && (
                  <div className={'tagListParent'}>
                    {tags?.map((tag: any) => (
                      <span className='contactTagsDetails contactTagsRewriteMargin'>{tag}</span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )
        }>
        {!loading ? (
          <ContactDetails
            data={contact?.response}
            toggleHide={(value: any) => {
              setShowDetails(value)
            }}
          />
        ) : (
          <LoadingBox />
        )}
      </FancyDrawer>
      <Modal
        open={openContactSales}
        onCancel={() => setOpenContactSales(false)}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}>
        <div className='flex justify-content-center'>
          <BlurPaywall
            addon={'This company details option'}
            onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
            component={[]}
            customClass='contactSalesNoMargin'
          />
        </div>
      </Modal>

      {openOutOfCreditsModal && (
        <OutOfCreditsModal
          creditType='Contacts Filter'
          open={openOutOfCreditsModal}
          onClose={() => {
            setOpenOutOfCreditsModal(false)

            if (limitOpenOutOfCreditsModal === 'points') navigate('/contacts')

            setLimitOpenOutOfCreditsModal('points')
          }}
          location={'contacts'}
          type={limitOpenOutOfCreditsModal}
          caption={captionCreditsModal}
        />
      )}
    </>
  )
}

export default CompanyDetails
