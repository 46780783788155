import copy_clipboard from 'common/assets/svg/copy_clipboard.svg'
import { useState } from 'react'

interface ICopyToClipboards {
  data?: any
}

export const CopyToClipboards = ({ data }: ICopyToClipboards) => {
  const [copied, setCopied] = useState<boolean>(false)
  const copyToClipboard = (text: string) => (event: any) => {
    navigator.clipboard.writeText(text).then(
      function () {
        setCopied(true)
      },
      function (err) {
        console.error('Failed to copy text: ', err)
      },
    )
  }

  return copied ? (
    <span className={'copied'}>{'Copied!'}</span>
  ) : (
    <img src={copy_clipboard} className='copyClipBoard' onClick={copyToClipboard(data)} alt={''} />
  )
}
