import React, { useEffect, useState } from 'react'
import { Modal, Button, Checkbox, Tooltip, Switch } from 'antd'
import styles from './ImportLeadsFromListsModal.module.scss'
import { useLazyGetLeadsQuery } from 'features/Outreach/state/api/OutreachApi'
import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import { LoadingOutlined } from '@ant-design/icons'
import { useAppSelector } from 'state'

// import LoadingBox from '../../../../LoadingBox/LoadingBox'

interface ImportLeadsFromListsModalProps {
  open: boolean
  onClose: () => void
  onFinish: (
    contactsListIds: any,
    credits: any,
    allCntactsToggle: any,
    failedCntactsToggle: any,
    excludeTeam: boolean,
    excludeTeamSent: boolean,
  ) => void
  selectedLists?: any
  isLoading: boolean
  isUserInATeam: boolean
}

const ImportLeadsFromListsModal: React.FC<ImportLeadsFromListsModalProps> = ({
  open,
  onClose,
  onFinish,
  selectedLists,
  isLoading,
  isUserInATeam,
}) => {
  const [allCntactsToggle, setAllCntactsToggle] = useState(true)
  const [failedCntactsToggle, setFailedCntactsToggle] = useState(true)
  const [excludeTeam, setExcludeTeam] = useState(true)
  const [excludeTeamSent, setExcludeTeamSent] = useState(true)
  const [refetcGetLeads, { data: leadsData }] = useLazyGetLeadsQuery()
  const [contactsListIds, setContactsListIds] = useState<any[]>([])
  const [sources, setSources] = useState<any[]>([])
  const { selected_platform } = useAppSelector((state) => state.outreachCampaign)
  // const platform = selected_platform ? selected_platform.charAt(0).toUpperCase() + selected_platform.slice(1) : ''

  const platform_name: any = {
    telegram: 'Telegram',
    linkedin: 'Linkedin',
  }
  // const selectedSources = selectedLists?.map((item: any) => item.source_name)
  const [credits, setCredits] = useState(0)
  const [showContacts, setShowContacts] = useState(false)

  useEffect(() => {
    const getLeads = async () => {
      await refetcGetLeads({ platform_type: selected_platform })
    }
    getLeads()
  }, [])

  // useEffect(() => {
  //   if (selectedSources) {
  //     setSources(selectedSources)
  //   }
  // }, [selectedLists])

  const handleCheckboxClick = (event: any, list: any) => {
    const { id, name, numberOfTelegramCredits } = list
    if (event.target.checked) {
      setContactsListIds((prevContactsListIds) => [...prevContactsListIds, id])
      setSources((prev) => [...prev, name])
      setCredits((prev) => prev + numberOfTelegramCredits)
    } else {
      setContactsListIds((prevContactsListIds) =>
        prevContactsListIds.filter((contactsListIds) => contactsListIds !== id),
      )
      setSources((prev) => prev.filter((listName) => listName !== name))
      setCredits((prev) => (prev - numberOfTelegramCredits < 0 ? 0 : prev - numberOfTelegramCredits))
    }
  }

  const handleToggleSwitchAllCntactsToggle = () => {
    setAllCntactsToggle(!allCntactsToggle)
  }

  const handleToggleSwitchFailedCntactsToggle = () => {
    setFailedCntactsToggle(!failedCntactsToggle)
  }

  const handleExcludeTeam = () => {
    setExcludeTeam(!excludeTeam)
  }

  const handleExcludeTeamSent = () => {
    setExcludeTeamSent(!excludeTeamSent)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContacts(true)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <Modal
      title={<span>Import Leads to Campaign</span>}
      centered
      open={open} // Adjust visibility based on your needs
      onCancel={onClose}
      footer={null}
      style={{ minWidth: '400px' }}
      width={'fit-content'}>
      {isLoading ? (
        <div className={styles.loading}>
          <LoadingBox />
        </div>
      ) : (
        <div className={styles.container}>
          {/* <p>Select Lists:</p> */}
          <div className={styles.columns}>
            <div className={styles.insideColumns}>
              <span></span>
              <span style={{ marginRight: '16px' }}>Verified {platform_name[selected_platform]}</span>
            </div>
          </div>
          <div>
            {leadsData !== undefined ? (
              <div style={{ overflow: 'auto', height: '350px', margin: '12px 0px' }}>
                {leadsData.response?.map((list: any) => {
                  return (
                    <div key={list.id} className={styles.list}>
                      <div className={styles.leftSide}>
                        <Checkbox
                          checked={sources?.includes(list.name)}
                          onClick={(event) => handleCheckboxClick(event, list)}
                        />
                        <p>{list.name}</p>{' '}
                      </div>
                      {/* <Tooltip trigger={'hover'} placement='top' title={'Credits'}>
                    <div
                      style={{ marginRight: '5px', width: '50px', textAlign: 'center' }}
                      className={styles.rightSide}>
                      <span>{list.numberOfTelegramCredits}</span>
                    </div>
                  </Tooltip> */}
                      <Tooltip trigger={'hover'} placement='top' title={'Contacts'}>
                        <div style={{ width: '50px', textAlign: 'center' }} className={styles.rightSide}>
                          {!showContacts ? (
                            <LoadingOutlined />
                          ) : (
                            <span>
                              {platform_name[selected_platform] === 'Telegram'
                                ? list.numberOfcontact - list.numberOfTelegramCredits
                                : platform_name[selected_platform] === 'Linkedin'
                                ? list.numberOfcontact - list.numberOfLinkedinCredits
                                : list.numberOfcontact}
                            </span>
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className={styles.loading__list}>
                <LoadingBox />
              </div>
            )}
          </div>
          <div className={styles.switchItem}>
            <Switch onChange={handleToggleSwitchAllCntactsToggle} checked={allCntactsToggle} size={'small'} />
            <span className={styles.switchLabel}>{`Exclude Leads Already Present in Other Campaigns`}</span>
          </div>
          <div className={styles.switchItem}>
            <Switch onChange={handleToggleSwitchFailedCntactsToggle} checked={failedCntactsToggle} size={'small'} />
            <span className={styles.switchLabel}>{`Exclude Leads Already Contacted in Other Campaigns`}</span>
          </div>
          {isUserInATeam && (
            <>
              <div className={styles.switchItem}>
                <Switch onChange={handleExcludeTeam} checked={excludeTeam} size={'small'} />
                <span className={styles.switchLabel}>{`Exclude Leads Already Present in Team Campaign`}</span>
              </div>
              <div className={styles.switchItem}>
                <Switch onChange={handleExcludeTeamSent} checked={excludeTeamSent} size={'small'} />
                <span className={styles.switchLabel}>{`Exclude Leads Already Contacted by other Team member`}</span>
              </div>
            </>
          )}
          <div>
            <p className={styles.points_used}>Points Used</p>
            <div className={styles.points_used_body}>
              {`Adding recipients from a list will automatically costs Convrt telegram`}
              <br></br>
              {`points and credits. By adding recipients to a campaign you approve and`}
              <br></br>
              {`acknowledge the `}
              <Tooltip title='Disclaimer: Please safeguard your private keys and refrain from sharing them. Some contacts obtained through our platform may need to be verified. Misuses, such as harassment or spam, can lead to account suspension or termination.'>
                <u style={{ cursor: 'pointer' }}>{`disclaimer`}</u>.
              </Tooltip>
            </div>
          </div>

          <Button
            className={styles.Button}
            onClick={() =>
              onFinish(contactsListIds, credits, allCntactsToggle, failedCntactsToggle, excludeTeam, excludeTeamSent)
            }>
            Add Lists
          </Button>
        </div>
      )}
    </Modal>
  )
}

export default ImportLeadsFromListsModal
