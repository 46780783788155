import { Select } from 'antd'
import styles from './SelectC.module.scss'

interface Option {
  label: string
  value: string
}

interface SelectProps {
  defaultValue: string
  prefixText: string
  options: Option[]
  width?: string
  height?: string
  value?: string
  onChange?: (value: string) => void
}

export const SelectComponent = ({
  prefixText,
  options,
  defaultValue,
  width = '100%',
  height,
  value,
  onChange = () => {},
}: SelectProps) => {
  return (
    <div className={`${styles['search_select']} search_select`}>
      <span className={styles.prefix_text}>{prefixText}</span>

      <Select defaultValue={defaultValue} value={value} style={{ width, height }} onChange={(value) => onChange(value)}>
        {options.map((option: Option) => {
          return (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          )
        })}
      </Select>
    </div>
  )
}
