export const SELECT_UPLOAD_TYPE = {
  COMPANY: 'company',
  CONTACT: 'contact',
}

export interface CompanyFields {
  domain: string
  name: string
  number_of_employees: string
  location: string
  vertical: string
}

export interface ContactFields {
  full_name: string
  company_domain: string
  job_title: string
  email: string
  twitter: string
  telegram: string
  linkedin: string
  custom_1: string
  custom_2: string
}

export type FieldMapping = {
  company: CompanyFields
  contact: ContactFields
}

export type FieldLabels = {
  [key in keyof FieldMapping]: {
    [K in keyof FieldMapping[key]]: string
  }
}

export const IMPORT_TYPE_NUMBER = {
  COMPANY: 1,
  CONTACT: 2,
}

export const IMPORT_TYPE_TEXT = {
  1: 'COMPANY',
  2: 'CONTACT',
}

export const IMPORT_TOTAL_STEP = {
  COMPANY: 2,
  CONTACT: 3,
}
