import { useEffect, useRef } from 'react'
import lottie from 'lottie-web'
import animationData from '../../assets/lottie-animations/loader.json'
import './LoadingBox.scss'
import classNames from 'classnames'

const LoadingBox = ({ className }: any) => {
  const lottieContainerRef = useRef(null)

  useEffect(() => {
    if (lottieContainerRef.current) {
      lottie.loadAnimation({
        container: lottieContainerRef.current,
        animationData,
        loop: true,
        renderer: 'svg',
      })
    }
  }, [])

  return (
    <div className={classNames('loading-box', className)}>
      <div ref={lottieContainerRef}></div>
    </div>
  )
}

export default LoadingBox
