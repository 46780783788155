import { Button } from 'antd'

import { ReloadOutlined } from '@ant-design/icons'

import './paywalls/IntentSignalsPermanentPaywall.scss'

interface errorMessageProps {
  caption?: any
  subCaption?: any
}

const ErrorMessage = ({ caption = `Something went wrong...`, subCaption }: errorMessageProps) => {
  const handleReload = () => window.location.reload()

  return (
    <div className='paywall-modal'>
      <h1>{caption}</h1>
      {subCaption && <h4 style={{ margin: '0px', textAlign: 'center' }}>{subCaption}</h4>}
      <Button className='mt-3' type='primary' onClick={handleReload}>
        <ReloadOutlined />
        {`Refresh`}
      </Button>
    </div>
  )
}

export default ErrorMessage
