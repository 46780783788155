import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { LocalStorageKeys } from 'common/constants/localstorage.constants'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_TASK_MANAGER_BASE_PATH,
  prepareHeaders: (headers, { getState }) => {
    headers.set(
      'authorization',
      `Bearer ${localStorage
        .getItem(LocalStorageKeys.ACCESS_TOKEN)
        ?.substring(1, String(localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)).length - 1)} `,
    )
    headers.set('environment', `${process.env.REACT_APP_ENVIRONMENT}`)
    headers.set('accept', 'application/json')
    headers.set('Content-Type', 'application/json')
    return headers
  },
})

export const baseQueryWithValidation: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    localStorage.clear()
    window.location.replace('/login')
  }
  return result
}
