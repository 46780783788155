import { Button, Drawer, Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from './LearningCenter.module.scss'
import supportIcon from 'common/assets/svg/Support.svg'
import GettingStartedCard from './UI/GettingStarted/GettingStartedCard'
import Videos from './UI/Videos/Videos'
import Articles from './UI/Articles/Articles'
import { useAppDispatch, useAppSelector } from 'state'
import {
  SET_ALL_CATEGORIES,
  SET_ARTICLE_SEE_MORE_DATA,
  SET_ARTICLE_SEE_MORE_OPEN,
  SET_FLOAT_VISIBLE,
  SET_INTERCOM_OPEN,
  SET_IS_ADMIN,
  SET_LEARNING_OPEN,
  SET_VIDEO_SEE_MORE_OPEN,
} from './state/slice/learningSlice'
import { useLocalStorage } from 'common/hooks/useLocalStorage.hooks'
import {
  useLazyGetAllCategoriesQuery,
  useLazyGetArticlesQuery,
  useLazyGetVideosQuery,
} from './state/api/LearningCenterAPI'
import { useLazyGetIsAdminQuery } from 'common/api/UserApi'
import { useIntercom } from 'react-use-intercom'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { ReloadOutlined } from '@ant-design/icons'
import ArticleSeeMore from './UI/Articles/ArticleSeeMore/ArticleSeeMore'
import { post } from 'common/api/axios'
import VideoSeeMore from './UI/Videos/VideoSeeMore/VideoSeeMore'
const bodyStyle: React.CSSProperties = {
  backgroundColor: '#F3F4F6',
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  paddingTop: 10,
  paddingBottom: 10,
}
export default function LearningCenter() {
  const [drawerLoading, setDrawerLoading] = useState(true)
  const { boot, show } = useIntercom()
  const { userDetails } = useAuth()
  const bootIntercom = () => {
    boot({
      name: userDetails?.name,
      email: userDetails?.email,
    })
    show()
  }
  const dispatch = useAppDispatch()
  const [name, setName] = useState(' ')
  const [user] = useLocalStorage('user', null)
  const { learningOpen } = useAppSelector((state) => state.learning)
  const [articles, setArticles] = useState<any[]>([])
  const [videos, setVideos] = useState<any[]>([])
  const [getArticles] = useLazyGetArticlesQuery()
  const [getVideos] = useLazyGetVideosQuery()
  const [getIsAdmin] = useLazyGetIsAdminQuery()
  const [getAllCategories] = useLazyGetAllCategoriesQuery()
  const { isAdmin } = useAppSelector((state) => state.learning)
  const title = (
    <Skeleton loading={drawerLoading} active title={false} paragraph={{ rows: 1 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <div className={styles.title}>Learning Center</div>
          <div className={styles.subTitle}>Hi {name}, How Can We Help You?</div>
        </div>
        <div>{isAdmin && <ReloadOutlined onClick={initLearningCenter} />}</div>
      </div>
    </Skeleton>
  )
  const footer = (
    <div className={styles.footer}>
      {drawerLoading ? (
        <>
          <Skeleton.Button active size='large' block />
        </>
      ) : (
        <Button
          type='primary'
          onClick={() => {
            post('/log', {
              action: 'Learning Center: User Clicked Support',
            })
            onClose()
            dispatch(SET_INTERCOM_OPEN(true))

            bootIntercom()
          }}>
          <span>Talk To Support</span>
          <img src={supportIcon} alt='Support' />
        </Button>
      )}
    </div>
  )

  const onCloseArticle = () => {
    dispatch(SET_LEARNING_OPEN(true))
  }
  const onOpenArticle = () => {
    dispatch(SET_LEARNING_OPEN(false))
  }
  const onSeeMore = () => {
    dispatch(SET_LEARNING_OPEN(false))
    dispatch(SET_ARTICLE_SEE_MORE_DATA({ title: 'Articles', category: 'articles' }))
    dispatch(SET_ARTICLE_SEE_MORE_OPEN(true))
  }
  function onClose() {
    dispatch(SET_FLOAT_VISIBLE(false))
    dispatch(SET_LEARNING_OPEN(false))
  }
  async function initLearningCenter() {
    try {
      setDrawerLoading(true)
      const name = user?.name
      setName(name?.split(' ')?.[0])
      const { data: articles } = await getArticles()
      const { data: videos } = await getVideos()
      const { data: categories } = await getAllCategories()
      const { data } = await getIsAdmin()
      dispatch(SET_IS_ADMIN(data?.isAdmin))
      dispatch(SET_ALL_CATEGORIES(categories))
      setArticles(articles)
      setVideos(videos)
      setDrawerLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleOnClose = () => {
    dispatch(SET_ARTICLE_SEE_MORE_OPEN(false))
    dispatch(SET_LEARNING_OPEN(true))
  }
  const handleOnCloseVideo = () => {
    dispatch(SET_VIDEO_SEE_MORE_OPEN(false))
    dispatch(SET_LEARNING_OPEN(true))
  }
  useEffect(() => {
    if (user) {
      initLearningCenter()
    }
  }, [user])
  return (
    <>
      <ArticleSeeMore articles={articles} handleOnClose={handleOnClose} init={initLearningCenter} />
      <VideoSeeMore videos={videos} handleOnClose={handleOnCloseVideo} init={initLearningCenter} />
      <Drawer
        style={{
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.4)', // Add drop shadow

          display: learningOpen ? 'flex' : 'none',
        }}
        open={learningOpen}
        onClose={onClose}
        zIndex={1001}
        closable={false}
        title={title}
        width={480}
        bodyStyle={bodyStyle}
        footer={footer}
        className='learning-center-drawer'
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
        drawerStyle={{
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Add drop shadow
        }}>
        <div style={{ flex: 0.1 }}>
          {drawerLoading ? (
            <Skeleton.Input active size='large' style={{ justifySelf: 'center' }} block />
          ) : (
            <GettingStartedCard allArticles={articles} />
          )}
        </div>
        <div style={{ flex: 0.4 }}>
          <Videos loading={drawerLoading} videos={videos} />
        </div>
        <div style={{ flex: 0.5 }}>
          <Articles
            title={'Articles'}
            actionText={'See More Articles'}
            articles={articles}
            onClose={onCloseArticle}
            onOpen={onOpenArticle}
            loading={drawerLoading}
            actionFunc={onSeeMore}
          />
        </div>
      </Drawer>
    </>
  )
}
