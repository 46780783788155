import React from 'react'
import { TableProps, Tooltip } from 'antd'
import { FancyImage } from 'features/contacts/components/FancyImage/FancyImage'
import styles from './GroupsColumns.module.scss'
import group_unlocked_icon from 'common/assets/svg/group_unlocked_icon.svg'
import group_locked_icon from 'common/assets/svg/group_locked_icon.svg'

export const getGroupsColumns = (platform: string): TableProps<any>['columns'] => {
  const commonColumns = [
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'name',
      width: 300,
      render: (text: string, data: any) => (
        <div className={styles.full_name}>
          <FancyImage
            url={data.profile_pic_url}
            className='smallRoundPicture'
            iscompany={false}
            style={{ marginRight: '5px' }}
            size={28}
            name={text}
          />
          <div className={styles.contactName}>
            <Tooltip placement='topLeft' title={text}>
              <span style={{ fontWeight: 600, cursor: 'pointer' }}>
                {text?.length > 30 ? `${text.substring(0, 30)}...` : text}
              </span>
            </Tooltip>
          </div>
        </div>
      ),
    },
    {
      title: 'Handle',
      dataIndex: 'username',
      key: 'username',
      width: 160,
      render: (text: string, data: any) => (
        <span className={styles.handle}>
          {text?.length > 22 ? 'blurhandleblurhandleblur...' : 'blurhandleblurhandleblur'.slice(0, text.length)}
        </span>
      ),
    },
    {
      title: 'Bio',
      dataIndex: 'biography',
      key: 'biography',
      width: 400,
      render: (text: string, data: any) => (
        <Tooltip placement='top' title={text}>
          <span style={{ fontWeight: 500, cursor: 'pointer' }}>
            {text?.length > 35 ? `${text.substring(0, 35)?.trim()}...` : text || 'No biography available'}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Account Status',
      dataIndex: 'is_private',
      key: 'is_private',
      width: 150,
      render: (is_private: any) => (
        <div className={styles.accountStatus}>
          <img src={is_private ? group_locked_icon : group_unlocked_icon} alt='' />
        </div>
      ),
    },
  ]

  const platformSpecificColumns: any = {
    telegram: [],
  }

  return [...commonColumns, ...(platformSpecificColumns[platform] || [])]
}
