import { EditOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Typography } from 'antd'
import React, { useRef } from 'react'
import styles from './Tag.module.scss'
import removeImg from 'common/assets/jpg/remove.jpg'
import useClickOutside from './Hook/useClickOutside'

interface Props {
  content: string
  tagId: string
  onClick: () => void
  onUpdateTag: (newTag: string) => void
  onRemoveTag: (tagId: string) => void
}

export const SearchResultTagItem = ({ content, tagId, onClick, onUpdateTag, onRemoveTag }: Props) => {
  const [isEditMode, setIsEditMode] = React.useState(false)
  const [editValue, setEditValue] = React.useState(content)
  const [showAlert, setShowAlert] = React.useState(false)
  const [showMenu, setShowMenu] = React.useState(false)

  const refMenuDropdown = useRef(null)

  useClickOutside(refMenuDropdown, () => {
    if (showMenu) setShowMenu(false)
  })

  const menuItems = [
    {
      label: (
        <div
          className={styles['chatTag__edit__item']}
          onClick={(e) => {
            e.stopPropagation()
            setIsEditMode(true)
            setShowMenu(false)
          }}>
          <EditOutlined />
          <Typography className={styles['chatTag__edit__item__text']}>Rename tag</Typography>
        </div>
      ),
    },
    {
      label: (
        <div
          className={styles['chatTag__edit__item']}
          onClick={(e) => {
            e.stopPropagation()
            setShowAlert(true)
            setShowMenu(false)
          }}>
          <DeleteOutlined color='#7144FF' />
          <Typography className={styles['chatTag__edit__item__text']}>Delete tag from all</Typography>
        </div>
      ),
      onClick: () => setShowAlert(true),
    },
  ]

  const handleClickItem = () => {
    if (isEditMode) return
    onClick()
  }

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 27) {
      setIsEditMode(false)
    }
  }

  const handleKeyUp = (event: any) => {
    if (event.key === 'Enter') {
      onUpdateTag(editValue)
      setIsEditMode(false)
    }
  }

  const handleCloseAlert = () => setShowAlert(false)

  const handleRemoveTag = () => {
    onRemoveTag(tagId)
    handleCloseAlert()
  }

  return (
    <div className={styles['chatTag__search__item']}>
      {isEditMode ? (
        <Input
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          onKeyUp={handleKeyUp}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <Typography className={styles['chatTag__search__item__text']} onClick={handleClickItem}>
          {content}
        </Typography>
      )}

      <div
        className={styles['chatTag__search__item__menu']}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setShowMenu((pre) => !pre)
        }}>
        <MoreOutlined />

        {showMenu && (
          <div className={styles['chatTag__search__item__menu__option']} ref={refMenuDropdown}>
            {menuItems.map((item) => (
              <div onClick={item?.onClick}>{item.label}</div>
            ))}
          </div>
        )}
      </div>

      <Modal
        centered
        open={showAlert}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}
        onCancel={() => handleCloseAlert()}
        width={340}
        bodyStyle={{
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          gap: '16px',
        }}>
        <img src={removeImg} alt='remove icon' />
        <Typography style={{ fontWeight: 600, fontSize: '24px' }}>Remove tag {content}?</Typography>
        <Typography>Are you sure you want to delete the {content} tag everywhere in that platform?</Typography>
        <Button
          style={{ background: '#FF5C5C', color: 'white', width: '100%', border: 'none' }}
          onClick={handleRemoveTag}>
          Yes, Remove
        </Button>
        <Button style={{ color: '#5E6C84', width: '100%', borderColor: '#DFE1E6' }} onClick={() => handleCloseAlert()}>
          Cancel
        </Button>
      </Modal>
    </div>
  )
}
