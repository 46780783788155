import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Select, message, DatePicker, Switch } from 'antd'
import styles from './CampaignTimingModal.module.scss'
import RunInScheduleModal from './RunInScheduleModal'
import {
  useLazyGetCampaignScheduleQuery,
  useLazySetCampaignsSettingsQuery,
} from 'features/Outreach/state/api/OutreachApi'
import { useAppDispatch } from 'state'
import { SET_CAMPAIGN_SETTINGS } from '../../state/outreachCampaignSlice'
import dayjs from 'dayjs'
import three_dots from 'common/assets/svg/three_dots.svg'
import { post } from 'common/api/axios'

interface CampaignTimingModalProps {
  open: boolean
  onClose: () => void
  onFinish: (values: { whenToStart: string; runInBetweenHours: string }) => void
  nodesData?: any
  setNodesData: any
  edit_mode?: boolean
  timingDataForEdit?: any
}

const CampaignTimingModal: React.FC<CampaignTimingModalProps> = ({
  open,
  onClose,
  onFinish,
  nodesData,
  setNodesData,
  edit_mode,
  timingDataForEdit,
}) => {
  const [form] = Form.useForm()
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [cooldownSwitch, setCooldownSwitch] = useState(true)
  const [scheduleData, setScheduleData] = useState([])
  const [updateSchedules, setUpdateSchedules] = useState(0)
  const [scheduleToOpen, setScheduleToOpen] = useState('Default Schedule')
  const [runInScheduleModalOpen, setRunInScheduleModalOpen] = useState(false)
  const [runInBetweenHoursOptions, setRunInBetweenHoursOptions] = useState<any[]>([])
  const [refetcGetCampaignSchedule] = useLazyGetCampaignScheduleQuery()
  const [refetcSetCampaignsSettings] = useLazySetCampaignsSettingsQuery()
  const dispatch = useAppDispatch()
  // For Edit Campaign:
  const targetTimestamp = dayjs(timingDataForEdit?.whenToStart)
  const currentTimestamp = dayjs()
  const enableTimeEdit = currentTimestamp.isAfter(targetTimestamp)

  const runInBetweenId = timingDataForEdit?.runInBetweenId

  const handleTime = (values: any, selectedSchedule: any) => {
    let whenToStart = values?.whenToStart
    const whenToStartString = values?.whenToStart
    const runInBetweenHours = selectedSchedule
    const scheduleDate = values?.scheduleDate

    if (whenToStart === 'On Campaign Launch') {
      whenToStart = dayjs()
    } else {
      whenToStart = scheduleDate
    }

    return { whenToStart, whenToStartString, runInBetweenHours }
  }

  const handleFinish = () => {
    if (edit_mode) {
      form
        .validateFields()
        .then(async (values) => {
          const selectedSchedule = scheduleData?.filter((sch: any) => sch.schedule_id === values.runInBetweenHours)
          const settings = handleTime(values, selectedSchedule[0])

          await refetcSetCampaignsSettings({
            start_date: settings.whenToStart,
            schedule_id: settings.runInBetweenHours.schedule_id,
            campaign_id: nodesData?.campaign_id,
            cooldownSwitch: cooldownSwitch,
          })
          dispatch(SET_CAMPAIGN_SETTINGS(settings))
          setNodesData((prev: any) => ({ ...prev, settings: settings }))
          onFinish(settings)
          post('/log', {
            action: 'Update Settings Campaign',
            data: settings,
          })
          onClose()
        })
        .catch((errorInfo) => {
          console.log('Failed:', errorInfo)
        })
    } else {
      form
        .validateFields()
        .then((values) => {
          const selectedSchedule = scheduleData?.filter((sch: any) => sch.schedule_id === values.runInBetweenHours)
          const settings = handleTime(values, selectedSchedule[0])
          // dispatch(SET_SEQUENCE_DATA({ ...sequence_data, settings: values }))
          dispatch(SET_CAMPAIGN_SETTINGS(settings))
          setNodesData((prev: any) => ({ ...prev, settings: settings }))
          onFinish(settings)
          post('/log', {
            action: 'Update Settings Campaig',
            data: settings,
          })
          onClose()
          // form.resetFields()
        })
        .catch((errorInfo) => {
          console.log('Failed:', errorInfo)
        })
    }
  }

  const handleWhenToStartChange = (value: string) => {
    setShowDatePicker(value === 'Custom Date & Time')
  }

  const handleRunInBetweenHoursChange = (value: any) => {
    if (value.schedule_label === 'Custom Date & Time') {
      setScheduleToOpen('custom')
    } else if (value.schedule_label === 'Default Schedule') {
      setScheduleToOpen('Default Schedule')
    } else {
      setScheduleToOpen(value.schedule_label)
    }
    setRunInScheduleModalOpen(true)
  }

  const handleRunInScheduleModalClose = () => {
    setRunInScheduleModalOpen(false)
  }

  const handleRunInScheduleModalSave = async (schedule: any) => {
    setRunInBetweenHoursOptions((prevOptions) => [
      ...prevOptions,
      { schedule_id: schedule.schedule_id, schedule_label: schedule.schedule_label },
    ])
    await refetcGetCampaignSchedule()
    setRunInScheduleModalOpen(false)
    message.success('Schedule Saved Successfully!')
  }

  const handleRunInScheduleModalUpdate = async () => {
    setUpdateSchedules((prev) => prev + 1)
    setRunInScheduleModalOpen(false)
    message.success('Schedule Updated Successfully!')
  }

  const disabledDate = (current: any) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return current.valueOf() <= today.valueOf()
  }

  const handleCooldownSwitch = () => {
    setCooldownSwitch(!cooldownSwitch)
  }

  useEffect(() => {
    const getCampaignsSchedule = async () => {
      const { data } = await refetcGetCampaignSchedule()
      const schedules = data?.response
      const runInBetween = schedules.map(({ schedule_id, schedule_label }: any) => ({ schedule_id, schedule_label }))
      setRunInBetweenHoursOptions([{ schedule_id: '1', schedule_label: 'Custom Date & Time' }, ...runInBetween])
      setScheduleData(schedules)
    }
    getCampaignsSchedule()
  }, [scheduleToOpen, updateSchedules])

  return (
    <Modal
      title={<span>Campaign Timing</span>}
      centered
      open={open}
      onCancel={onClose}
      footer={null}
      style={{ minWidth: '400px' }}
      width={'fit-content'}>
      <div className={styles.container}>
        <Form form={form} onFinish={handleFinish} layout='vertical'>
          {!edit_mode && (
            <Form.Item
              name='whenToStart'
              label={<span style={{ fontWeight: 600 }}>When to Start</span>}
              rules={[{ required: true, message: 'Please select a date and time' }]}
              initialValue='On Campaign Launch'>
              <Select
                onChange={handleWhenToStartChange}
                size='large'
                // disabled={edit_mode}
              >
                <Select.Option value='On Campaign Launch'>On Campaign Launch</Select.Option>
                <Select.Option value='Custom Date & Time'>Custom Date & Time</Select.Option>
              </Select>
            </Form.Item>
          )}

          {edit_mode && (
            <Form.Item
              name='scheduleDate'
              label={<span style={{ fontWeight: 600 }}>When to Start</span>}
              initialValue={dayjs(timingDataForEdit?.whenToStart)}
              rules={[{ required: true, message: 'Please select a date and time' }]}>
              <DatePicker
                defaultValue={dayjs(timingDataForEdit?.whenToStart)}
                disabled={enableTimeEdit}
                style={{ width: '100%' }}
                showTime={{ minuteStep: 15 }}
                disabledDate={disabledDate}
                format='MM-DD-YYYY HH:mm'
                size='large'
              />
            </Form.Item>
          )}

          {showDatePicker && (
            <Form.Item
              name='scheduleDate'
              label={<span style={{ fontWeight: 600 }}>Schedule Date & Time</span>}
              rules={[{ required: true, message: 'Please select a date and time' }]}>
              <DatePicker
                showTime={{ minuteStep: 15 }}
                disabledDate={disabledDate}
                format='MM-DD-YYYY HH:mm'
                size='large'
              />
            </Form.Item>
          )}

          <Form.Item
            name='runInBetweenHours'
            label={<span style={{ fontWeight: 600 }}>Run in Between Hours</span>}
            rules={[{ required: true, message: 'Please select a date and time' }]}
            initialValue={edit_mode ? runInBetweenId : null}>
            <Select
              size='large'
              placeholder='Select your schedule'
              optionLabelProp='key'
              defaultValue={edit_mode ? runInBetweenId : null}>
              {runInBetweenHoursOptions.map((option) => (
                <Select.Option
                  key={option.schedule_label === 'Custom Date & Time' ? undefined : option.schedule_label}
                  value={option.schedule_label === 'Custom Date & Time' ? undefined : option.schedule_id}>
                  <div
                    className={styles.customSelect}
                    onClick={() =>
                      option.schedule_label === 'Custom Date & Time' && handleRunInBetweenHoursChange(option)
                    }>
                    <span>{option.schedule_label}</span>
                    {option.schedule_label !== 'Custom Date & Time' && (
                      <img
                        style={{ padding: '10px 0px' }}
                        onClick={() => handleRunInBetweenHoursChange(option)}
                        src={three_dots}
                        alt=''
                      />
                    )}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {edit_mode && (
            <Form.Item name='cooldownSwitch' initialValue={cooldownSwitch} style={{ display: 'flex' }}>
              <Switch checked={cooldownSwitch} onChange={handleCooldownSwitch} />
              <span style={{ marginLeft: '10px', fontWeight: 600 }}>Keep Sending Messages After Cooldown</span>
            </Form.Item>
          )}

          <div className={styles.footer}>
            <Button type='primary' htmlType='submit'>
              Update Settings
            </Button>
          </div>
        </Form>

        {runInScheduleModalOpen && (
          <RunInScheduleModal
            open={runInScheduleModalOpen}
            onClose={handleRunInScheduleModalClose}
            onSave={handleRunInScheduleModalSave}
            onUpdate={handleRunInScheduleModalUpdate}
            data={scheduleData}
            scheduleToOpen={scheduleToOpen}
          />
        )}
      </div>
    </Modal>
  )
}

export default CampaignTimingModal
