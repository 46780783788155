import { Card, Modal } from 'antd'
import { ReactComponent as AiIcon } from 'common/assets/svg/add_leads_ai.svg'
import { ReactComponent as CustomLeadsIcon } from 'common/assets/svg/add_leads_csv.svg'
import { ReactComponent as GroupIcon } from 'common/assets/svg/add_leads_group.svg'
import { ReactComponent as MyContactIcon } from 'common/assets/svg/add_leads_my_contact.svg'

import styles from './AddContactOption.module.scss'

interface AddLeadsModalProps {
  open: boolean
  onClose: () => void
  onClickImportCustomList: () => void
}

const AddContactModal: React.FC<AddLeadsModalProps> = ({ open, onClose, onClickImportCustomList }) => {
  return (
    <Modal
      centered
      title={<p style={{ fontSize: '20px' }}>Add Leads to Your Campaign</p>}
      open={open}
      onCancel={onClose}
      closable={true}
      footer={null}
      width={700}
      className={styles.addLeadsModal}>
      <div className={styles.smallCardsContainer}>
        <Card onClick={() => {}} className={`addLeadsModal ${styles.card} ${styles.disabled}`}>
          <div className={styles.card__image}>
            <AiIcon className={styles.icon} />
            <div className={`${styles.comingSoon__addByAI} ${styles.comingSoon}`}>
              <span>Coming Soon</span>
            </div>
          </div>
          <div className={styles.cardContent}>
            <h3>Add Leads by AI</h3>
            <p>Discover leads identified by our AI, tailored to your Ideal Customer Profile.</p>
          </div>
        </Card>

        <Card onClick={() => {}} className={`addLeadsModal ${styles.card} ${styles.disabled}`}>
          <div className={styles.card__image}>
            <MyContactIcon className={styles.icon} />
            <div className={`${styles.comingSoon__addFromContact} ${styles.comingSoon}`}>
              <span>Coming Soon</span>
            </div>
          </div>
          <div className={styles.cardContent}>
            <h3>Add from My Contacts</h3>
            <p>Create a list out of your Convrt contacts.</p>
          </div>
        </Card>

        <Card onClick={onClickImportCustomList} className={`addLeadsModal ${styles.card} `}>
          <CustomLeadsIcon className={styles.icon} />
          <h3>Import Custom Leads / Companies</h3>
          <p>
            Import your custom leads / companies from CSV files to expand your prospect database or enrich your data.
          </p>
        </Card>

        <Card onClick={() => {}} className={`addLeadsModal ${styles.card} ${styles.disabled}`}>
          <GroupIcon className={styles.icon} />
          <h3>Add by group</h3>
          <p>Add leads from Telegram groups and communities dierctly to your campaign for targeted outreach.</p>
          <div className={`${styles.comingSoon__addByGroup} ${styles.comingSoon}`}>
            <span>Coming Soon</span>
          </div>
        </Card>
      </div>
    </Modal>
  )
}

export default AddContactModal
