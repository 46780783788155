import React, { useState, useEffect } from 'react'

const TypedText: React.FC<{ text: string; typingSpeed?: number }> = ({ text, typingSpeed = 100 }) => {
  const [displayText, setDisplayText] = useState<string>('')
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < text.length) {
        setDisplayText((prevText) => prevText + text[currentIndex])
        setCurrentIndex((prevIndex) => prevIndex + 1)
      } else {
        clearInterval(interval)
      }
    }, typingSpeed)

    return () => {
      clearInterval(interval)
    }
  }, [currentIndex, text, typingSpeed])

  return <div className='typedText_color'>{displayText}</div>
}

export default TypedText
