import React from 'react'
import { Button, Modal } from 'antd'
import warningIcon from 'common/assets/svg/warning.svg'
import styles from './AreYouSureModal.module.scss'

interface AreYouSureModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  message: string
  widthStyle?: any
  confirmText?: any
}

const AreYouSureModal: React.FC<AreYouSureModalProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  message,
  widthStyle,
  confirmText,
}) => {
  return (
    <Modal
      className={styles.modalStyleNewDeal}
      centered
      open={open}
      okButtonProps={{ className: 'hidden' }}
      cancelButtonProps={{ className: 'hidden' }}
      onCancel={onClose}
      width={'fit-content'}
      style={{ minWidth: widthStyle || 500, maxWidth: widthStyle || 500 }}>
      <div className={styles.contentStyle}>
        <div className={styles.container}>
          <img src={warningIcon} alt='' />
          <p>{title}</p>
          <span>{message}</span>
          <div className={styles.footer}>
            <Button size={'large'} style={{ color: '#7043ff', backgroundColor: 'white' }} onClick={onClose}>
              Close
            </Button>
            <Button size={'large'} style={{ color: 'white', backgroundColor: '#7043ff' }} onClick={onConfirm}>
              {confirmText ? confirmText : 'Confirm'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AreYouSureModal
