import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Form, Layout, Menu, Select, Space, Switch, Tag, Tooltip, message } from 'antd'
import hubspot from 'common/assets/svg/hubspot_purple.svg'
import info_icon from 'common/assets/svg/info_icon.svg'
import left_arrow from 'common/assets/svg/left_arrow.svg'
import settings_black from 'common/assets/svg/settings_black.svg'
import styles from './HubspotUpdateDeal.module.scss'
import { Content, Footer, Header } from 'antd/es/layout/layout'
//import { useLazyGetTakeCreditsQuery } from 'features/contacts/state/api/ContactsApi'
import { post } from 'common/api/axios'
import LoadingBox from '../../../../common/components/LoadingBox/LoadingBox'
import OutOfCreditsModal from 'common/components/OutOfCredits/OutOfCreditsModal'
import { get } from 'common/api/axios'
import { useAppDispatch, useAppSelector } from 'state'

import { SET_HUBSPOT_DEALS } from 'features/contacts/state/slice/contactsSlice'

interface HubspotUpdateDealProps {
  onBack: () => void
  selectedContacts: any
  selectedCompanies: any
  deals: any
  pipeline: any
  telegramCredits: any
  emailCredits: any
  companyIds: any
  onCloseModal: () => void
  isCompany: boolean
  userCredits: any
  userPoints: any
}

const HubspotUpdateDeal: React.FC<HubspotUpdateDealProps> = ({
  onBack,
  selectedContacts,
  selectedCompanies,
  deals,
  pipeline,
  telegramCredits,
  emailCredits,
  companyIds,
  onCloseModal,
  isCompany,
  userCredits,
  userPoints,
}) => {
  const { hubspotDeals } = useAppSelector((state) => state.contacts)
  const dispatch = useAppDispatch()
  const [dealSelectLoading, setDealSelectLoading] = useState(false)

  const [loading, setLoading] = useState(false)
  const [hubspotTmpDeals] = useState<any>(hubspotDeals)
  const [outOfCredits, setOutOfCredits] = useState(false)
  const [outOfCreditsType, setOutOfCreditsType] = useState('')
  // const { selected_contacts } = useAppSelector((state) => state.contacts)
  const { selected_companies } = useAppSelector((state) => state.companies)
  // const companyIds = selectedContacts
  //   ?.map((contact: any) => contact?.company_id)
  //   .filter((value: any, index: number, self: any[]) => self.indexOf(value) === index)
  const [onToggle, setOnToggle] = useState(true)
  const [overwriteToggle, setOverwriteToggle] = useState(false)
  const [form] = Form.useForm()
  //const [refetchGetTakeCredits] = useLazyGetTakeCreditsQuery()
  const tagOptions = isCompany
    ? {
        company_info: `Company Info (${selected_companies?.length * userPoints.pointsStore.companies_export})`,
      }
    : {
        company_info: `Company Info (${companyIds?.length * userPoints.pointsStore.companies_export})`,
        contact_info: `Contact Info (${selectedContacts?.length * userPoints.pointsStore.contacts_export})`,
        telegram: `Telegram Accounts (${telegramCredits?.length * userPoints.pointsStore.telegram})`,
        email: `Emails Addresses (${emailCredits?.length * userPoints.pointsStore.email})`,
      }
  const [selectedOptions, setSelectedOptions] = useState<any>(tagOptions)
  // const uniqueDealStages = new Set()
  const [isDealSelected, setIsDealSelected] = useState(false)
  // const allStages = pipeline.reduce((accumulator: any, current: any) => {
  //   return accumulator.concat(current.stages)
  // }, [])

  const reducedPipeline = pipeline.map(({ label, stages }: any) => ({
    label,
    options: stages.map(({ label, id }: any) => ({ label, value: id })),
  }))

  const onChangeToggle = () => {
    setOnToggle(!onToggle)
  }

  const onChangeOverwriteToggle = () => {
    setOverwriteToggle(!overwriteToggle)
  }

  const handleMenuClick = (e: any) => {
    const option = e.key as keyof typeof tagOptions
    if (!selectedOptions[option]) {
      const updatedOptions = { ...selectedOptions }
      updatedOptions[option] = tagOptions[option]
      setSelectedOptions(updatedOptions)
    }
  }

  const handleTagClose = (removedOption: string) => {
    const updatedOptions = { ...selectedOptions }
    delete updatedOptions[removedOption]
    setSelectedOptions(updatedOptions)
  }

  const handleFormValuesChange = (changedValues: any) => {
    if (changedValues?.dealId) {
      setIsDealSelected(true)
    }
  }

  const handleSubmit = async (values: any) => {
    // if (values.enrichment.contact_info && userCredits.contacts_export < selectedContacts.length) {
    if (
      values.enrichment.contact_info &&
      userPoints.points < userPoints.pointsStore.contacts_export * selectedContacts.length
    ) {
      setOutOfCreditsType('Contact')
      setOutOfCredits(true)
      return
      // } else if (values.enrichment.company_info && userCredits.companies_export < companyIds.length) {
    } else if (
      values.enrichment.company_info &&
      userPoints.points < userPoints.pointsStore.companies_export * companyIds.length
    ) {
      setOutOfCreditsType('Company')
      setOutOfCredits(true)
      return
      // } else if (values.enrichment.email && userCredits.email < emailCredits.length) {
    } else if (values.enrichment.email && userPoints.points < userPoints.pointsStore.email * emailCredits.length) {
      setOutOfCreditsType('Email')
      setOutOfCredits(true)
      return
      // } else if (values.enrichment.telegram && userCredits.telegram < telegramCredits.length) {
    } else if (
      values.enrichment.telegram &&
      userPoints.points < userPoints.pointsStore.telegram * telegramCredits.length
    ) {
      setOutOfCreditsType('Telegram')
      setOutOfCredits(true)
      return
    } else {
      const isEnrich = Object.keys(values.enrichment).length > 0
      try {
        const data = []
        for (const companyId of companyIds) {
          const filteredUsers = selectedContacts.filter((contact: any) => contact.company_id === companyId)
          const filteredUsersIds = filteredUsers?.map((contact: any) => contact?.index)
          const tempBody = {
            deal_id: values.dealId,
            stage: values.dealStage || null,
            is_data_enrichment: isEnrich,
            enrichment: {
              company_info: values.enrichment.company_info !== undefined,
              contact_info: values.enrichment.contact_info !== undefined,
              telegram: values.enrichment.telegram !== undefined,
              email: values.enrichment.email !== undefined,
            },
            contact_id: filteredUsersIds,
            company_id: [companyId],
            do_override_if_exists: overwriteToggle,
          }

          data.push(tempBody)
          setLoading(true)
        }
        const response = await post('/outreach/updateHubspotDeals', {
          data,
          telegramCredits,
          emailCredits,
          telegram: values.enrichment.telegram !== undefined,
          email: values.enrichment.email !== undefined,
        })

        const createdDealsIds = response?.data?.dealIds

        if (response.status !== 200) {
          message.error('Updating Deals failed')
        } else {
          message.success(`${createdDealsIds.length}/${data.length} Deals were updated successfully`)
        }
        onCloseModal()
        return true
      } catch (e) {
        console.error(e)
        return false
      } finally {
        setLoading(false)
      }
    }
  }

  const handleSubmitCompany = async (values: any) => {
    if (values.enrichment?.contact_info && userCredits?.contacts_export < selectedContacts?.length) {
      setOutOfCreditsType('Contact')
      setOutOfCredits(true)
      return
    } else if (values.enrichment?.company_info && userCredits?.companies_export < companyIds?.length) {
      setOutOfCreditsType('Company')
      setOutOfCredits(true)
      return
    } else {
      const isEnrich = Object.keys(values.enrichment)?.length > 0
      try {
        const data = []
        for (const company of selectedCompanies) {
          const tempBody = {
            deal_id: values.dealId,
            stage: values.dealStage || null,
            is_data_enrichment: isEnrich,
            enrichment: {
              company_info: values.enrichment.company_info !== undefined,
              contact_info: values.enrichment.contact_info !== undefined,
              telegram: values.enrichment.telegram !== undefined,
              email: values.enrichment.email !== undefined,
            },
            contact_id: [],
            company_id: [company?.id],
            do_override_if_exists: overwriteToggle,
          }

          data.push(tempBody)
          setLoading(true)
        }

        const response = await post('/outreach/updateHubspotDeals', {
          data,
          telegramCredits,
          emailCredits,
          telegram: values.enrichment.telegram !== undefined,
          email: values.enrichment.email !== undefined,
        })

        const createdDealsIds = response?.data?.dealIds

        if (response.status !== 200) {
          message.error('Creation of new Deal failed')
        } else {
          message.success(`${createdDealsIds.length}/${data.length} Deals were updated successfully`)
        }
        onCloseModal()
        return true
      } catch (e) {
        console.error(e)
        return false
      } finally {
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      const fetchedDeals = await get('/outreach/getHubspotAllDeals')
      dispatch(SET_HUBSPOT_DEALS(fetchedDeals.data.response))
      //setHunspotTmpDeals()
      //setHunspotDeals(fetchedDeals.data.response)
    }
    if (hubspotTmpDeals == null) {
      // setLoading(true)
      setDealSelectLoading(true)
      fetchData().finally(() => {
        // setLoading(false)
        setDealSelectLoading(false)
      })
    }
  }, [hubspotTmpDeals])

  useEffect(() => {
    form.setFieldValue('enrichment', onToggle ? selectedOptions : [])
  }, [onToggle, selectedOptions])

  const menu = (
    <Menu onClick={handleMenuClick}>
      {Object.entries(tagOptions).map(([key, value]) => (
        <Menu.Item key={key}>{value}</Menu.Item>
      ))}
    </Menu>
  )

  return (
    <>
      {outOfCredits && (
        <OutOfCreditsModal
          open={outOfCredits}
          onClose={() => {
            setOutOfCredits(false)
          }}
          creditType={outOfCreditsType}
          location='hubspot'
        />
      )}
      <Space direction='vertical' style={{ width: '100%' }} size={[0, 48]}>
        {loading || hubspotDeals?.length === 0 ? (
          <div>
            <LoadingBox className='hubspot_update_deal_page' />
            <div className={styles.headerLoadingBoxText}>
              Enriching your HubSpot is in progress. You can close this window - we will notify you when the enrichment
              is complete
            </div>
            {/* <span>Loading Deals...</span> */}
          </div>
        ) : (
          <Layout>
            <Header className={styles.headerStyle}>
              <img src={hubspot} alt='' />
              <h1>Update an Existing Deal</h1>
            </Header>
            <Content className={styles.contentStyle}>
              <Form
                form={form}
                id='dealForm'
                name='dealForm'
                onFinish={isCompany ? handleSubmitCompany : handleSubmit}
                onValuesChange={handleFormValuesChange}
                style={{ maxWidth: 1200 }}
                className='newDealForm'
                colon={false}>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Form.Item name='dealId' label={<p>Select Deal</p>} className={styles.formSelect}>
                    <Select size={'large'} showSearch optionFilterProp='children' loading={dealSelectLoading}>
                      {hubspotDeals?.length === 0 && (
                        <Select.Option value={null} disabled>
                          No options available
                        </Select.Option>
                      )}
                      {hubspotDeals?.map((deal: any) => (
                        <Select.Option key={deal?.id} value={deal?.id}>
                          {deal?.properties?.dealname}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={<p>Select Deal Stage</p>}
                    name='dealStage'
                    className={styles.formSelect}
                    style={{ margin: '0px 0px 20px 0px' }}>
                    <Select size='large' options={reducedPipeline} />
                  </Form.Item>
                </Form.Item>
                <Form.Item name={'enrichment'}>
                  <div className={styles.enrichment}>
                    <div className={styles.top}>
                      <div className={styles.left}>
                        <p>Data Enrichment</p>
                        <span>
                          Let Convrt enrich your data for improved results. This option incurs a point cost,
                          <br /> you can set your enrichment preferences.
                        </span>
                      </div>
                      <div className={styles.right}>
                        <Switch defaultChecked onChange={onChangeToggle} />
                        <span>{onToggle ? 'ON' : 'OFF'}</span>
                      </div>
                    </div>
                    {onToggle && (
                      <div className={styles.settings}>
                        <div className={styles.left}>
                          <div className={styles.options}>
                            <span>Enrich Companies Data:</span>
                            <div>
                              {Object.entries(selectedOptions).map(([key, value]) => (
                                <Tag
                                  key={key}
                                  closable
                                  onClose={() => handleTagClose(key)}
                                  style={{ margin: '-1px 0px 5px 8px' }}>
                                  {value as React.ReactNode}
                                </Tag>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className={styles.right}>
                          <Dropdown overlay={menu} trigger={['click']} placement={'bottomCenter'}>
                            <span style={{ cursor: 'pointer' }}>
                              <img src={settings_black} alt='' />
                            </span>
                          </Dropdown>
                        </div>
                      </div>
                    )}
                  </div>
                </Form.Item>
              </Form>
            </Content>
            <Footer className={styles.footerStyle}>
              <div className={styles.section}>
                <div className={styles.back} onClick={onBack}>
                  <img src={left_arrow} alt='' />
                  <span>Back</span>
                </div>
                <div className={styles.centeredText}>
                  <Switch
                    style={{ marginRight: '5px' }}
                    onChange={onChangeOverwriteToggle}
                    checked={overwriteToggle}
                    size={'small'}
                  />
                  <span>Overwrite Populated Fields is {overwriteToggle ? 'ON' : 'OFF'}</span>
                  <Tooltip
                    className='tooltipHubspotUpdateDeal'
                    overlayInnerStyle={{
                      color: 'black',
                      backgroundColor: 'white',
                    }}
                    title='By default, Convrt will not overwrite existing data in populated fields when enriching HubSpot records. It is possible to overwrite populated fields with Convrt data by turning this toggle ON.'>
                    <img src={info_icon} alt='' />
                  </Tooltip>
                </div>
                <Button
                  form='dealForm'
                  key='submit'
                  htmlType='submit'
                  className={styles.button}
                  disabled={
                    (isCompany ? selectedCompanies.length === 0 : selectedContacts.length === 0) || !isDealSelected
                  }>
                  Export
                </Button>
              </div>
            </Footer>
          </Layout>
        )}
      </Space>
    </>
  )
}

export default HubspotUpdateDeal

// import { useState } from 'react'
// import { Button, Dropdown, Form, Layout, Menu, Select, Space, Switch, Tag, Tooltip } from 'antd'
// import left_arrow from 'common/assets/svg/left_arrow.svg'
// import info_icon from 'common/assets/svg/info_icon.svg'
// import hubspot from 'common/assets/svg/hubspot_purple.svg'
// import settings_black from 'common/assets/svg/settings_black.svg'
// import styles from './HubspotUpdateDeal.module.scss'
// import { Content, Footer, Header } from 'antd/es/layout/layout'

// interface HubspotUpdateDealProps {
//   onBack: () => void
//   selectedContacts: any
//   selectedCompanies: any
//   owners: any
//   pipeline: any
//   telegramCredits: any
//   emailCredits: any
//   onCloseModal: () => void
//   isCompany: boolean
// }

// const HubspotUpdateDeal: React.FC<HubspotUpdateDealProps> = ({
//   onBack,
//   selectedContacts,
//   selectedCompanies,
//   owners,
//   pipeline,
//   telegramCredits,
//   emailCredits,
//   onCloseModal,
//   isCompany,
// }) => {
//   const [onToggle, setOnToggle] = useState(true)
//   const [overwriteToggle, setOverwriteToggle] = useState(false)
//   const onChangeToggle = () => {
//     setOnToggle(!onToggle)
//   }

//   const onChangeOverwriteToggle = () => {
//     setOverwriteToggle(!overwriteToggle)
//   }

//   const onFinish = (values: any) => {
//   }

//   const [selectedOptions, setSelectedOptions] = useState<string[]>([
//     'Company Info',
//     'Telegram Accounts',
//     'Emails Adressed',
//     'Deals Creations',
//   ])

//   const handleMenuClick = (e: any) => {
//     const option = e.key
//     if (!selectedOptions.includes(option)) {
//       setSelectedOptions([...selectedOptions, option])
//     }
//   }

//   const handleTagClose = (removedOption: string) => {
//     const updatedOptions = selectedOptions.filter((option) => option !== removedOption)
//     setSelectedOptions(updatedOptions)
//   }

//   const menu = (
//     <Menu onClick={handleMenuClick}>
//       <Menu.Item key='Company Info'>Company Info</Menu.Item>
//       <Menu.Item key='Telegram Accounts'>Telegram Accounts</Menu.Item>
//       <Menu.Item key='Emails Adressed'>Emails Adressed</Menu.Item>
//       <Menu.Item key='Deals Creations'>Deals Creations</Menu.Item>
//     </Menu>
//   )
//   return (
//     <Space direction='vertical' style={{ width: '100%' }} size={[0, 48]}>
//       <Layout>
//         <Header className={styles.headerStyle}>
//           <img src={hubspot} alt='' />
//           <h1>Update an Existing Deal</h1>
//         </Header>
//         <Content className={styles.contentStyle}>
//           <Form name='complex-form' onFinish={onFinish} style={{ maxWidth: 1200 }}>
//             <Form.Item style={{ marginBottom: 0 }}>
//               <Form.Item name='dealName' rules={[{ required: true }]} className={styles.formSelect}>
//                 <p>Select Deal</p>
//                 <Select size={'large'} defaultValue='Deal Option 1'>
//                   <Select.Option value='Option1'>Deal Option 1</Select.Option>
//                   <Select.Option value='Option2'>Deal Option 2</Select.Option>
//                   <Select.Option value='Option3'>Deal Option 3</Select.Option>
//                 </Select>
//               </Form.Item>
//               <Form.Item
//                 name='pipeline'
//                 rules={[{ required: true }]}
//                 className={styles.formSelect}
//                 style={{ margin: '0px 0px 20px 0px' }}>
//                 <p>Select Deal Stage Name</p>
//                 <Select size={'large'} defaultValue='Deal Stage Option 1'>
//                   <Select.Option value='Option1'>Deal Stage Option 1</Select.Option>
//                   <Select.Option value='Option2'>Deal Stage Option 2</Select.Option>
//                   <Select.Option value='Option3'>Deal Stage Option 3</Select.Option>
//                 </Select>
//               </Form.Item>
//             </Form.Item>
//             <div className={styles.enrichment}>
//               <div className={styles.top}>
//                 <div className={styles.left}>
//                   <p>Data Enrichment</p>
//                   <span>
//                     Let Convrt enrich your data for improved results. This option incurs a credit cost,
//                     <br /> you can set your enrichment preferences.
//                   </span>
//                 </div>
//                 <div className={styles.right}>
//                   <Switch defaultChecked onChange={onChangeToggle} />
//                   <span>{onToggle ? 'ON' : 'OFF'}</span>
//                 </div>
//               </div>
//               {onToggle && (
//                 <div className={styles.settings}>
//                   <div className={styles.left}>
//                     <div className={styles.options}>
//                       <span>Enrich Companies Data:</span>
//                       {selectedOptions.map((option) => (
//                         <Tag
//                           key={option}
//                           closable
//                           onClose={() => handleTagClose(option)}
//                           style={{ margin: '-2px 0px 0px 8px' }}>
//                           {option}
//                         </Tag>
//                       ))}
//                     </div>
//                   </div>
//                   <div className={styles.right}>
//                     <Dropdown overlay={menu} trigger={['click']} placement={'bottomCenter'}>
//                       <span style={{ cursor: 'pointer' }}>
//                         <img src={settings_black} alt='' />
//                       </span>
//                     </Dropdown>
//                   </div>
//                 </div>
//               )}
//             </div>
//           </Form>
//         </Content>
//         <Footer className={styles.footerStyle}>
//           <div className={styles.section}>
//             <div className={styles.back} onClick={onBack}>
//               <img src={left_arrow} alt='' />
//               <span>Back</span>
//             </div>

//             <div className={styles.centeredText}>
//               <Switch
//                 style={{ marginRight: '5px' }}
//                 onChange={onChangeOverwriteToggle}
//                 checked={overwriteToggle}
//                 size={'small'}
//               />
//               <span>Overwrite Populated Fields is {overwriteToggle ? 'ON' : 'OFF'}</span>
//               <Tooltip
//                 className='tooltipHubspotNewDeal'
//                 overlayInnerStyle={{
//                   color: 'black',
//                   backgroundColor: 'white',
//                 }}
//                 title='By default, Convrt will not overwrite existing data in populated fields when enriching HubSpot records. It is possible to overwrite populated fields with Convrt data by turning this toggle ON.'>
//                 <img src={info_icon} alt='' />
//               </Tooltip>
//             </div>

//             <Button className={styles.button}>Export</Button>
//           </div>
//         </Footer>
//       </Layout>
//     </Space>
//   )
// }

// export default HubspotUpdateDeal
