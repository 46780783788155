import { DeleteOutlined, InfoCircleOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, message, Select, Spin, Table, Typography, Upload, UploadProps } from 'antd'
import { every, isNull, reduce } from 'lodash'
import Papa from 'papaparse'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'state'
import { saveAs } from 'file-saver'

import { post } from 'common/api/axios'
import csv_icon from 'common/assets/svg/csv_icon.svg'
import { formatDomain, validateResultPapaParse } from 'helper'
import { initialStateCsvData, SET_CSV_DATA, SET_DATA_AFTER_MAPPING } from '../../state/importContactSlice'
import { IMPORT_MAX_ROWS_ALLOWED } from './import.constants'
import styles from './ImportAndMapping.module.scss'
import { IMPORT_TYPE_NUMBER } from './ImportCustomList.type'

const { Dragger } = Upload

interface Props {
  isCompanyImport: boolean
  importTypeNumber: number
  onBackToExposedContact: () => void
  onContinue: () => void
}

export const ImportAndMapping = ({ isCompanyImport, importTypeNumber, onBackToExposedContact, onContinue }: Props) => {
  const dispatch = useAppDispatch()
  const { csvData } = useAppSelector((state) => state.importContact)
  const { list_id } = useParams()

  const [isLoadingUpload, setIsloadingUpload] = React.useState(false)
  const importText = isCompanyImport ? 'Companies' : 'Contacts'

  const handleSaveCSVData = (data: any) => {
    dispatch(SET_CSV_DATA(data))
  }

  const formatFieldKey = reduce(
    csvData.fieldArray,
    (result: any, value, index) => {
      result[value?.trim()] = index
      return result
    },
    {},
  )

  const initialStateFieldMapping = {
    [IMPORT_TYPE_NUMBER.COMPANY]: {
      domain: '',
      name: '',
      number_of_employees: '',
      location: '',
      vertical: '',
    },

    [IMPORT_TYPE_NUMBER.CONTACT]: {
      full_name: '',
      first_name: '',
      last_name: '',
      company_domain: '',
      job_title: '',
      email: '',
      twitter: '',
      telegram: '',
      linkedin: '',
      custom_1: '',
      custom_2: '',
    },
  }

  const [fieldMapping, setFieldMapping] = React.useState<any>(initialStateFieldMapping)

  const fieldLabels: any = {
    [IMPORT_TYPE_NUMBER.COMPANY]: {
      domain: 'Company Domain (Mandatory)',
      name: 'Company Name',
      number_of_employees: 'Number Of Employees',
      location: 'Location',
      vertical: 'Vertical (Industry)',
    },

    [IMPORT_TYPE_NUMBER.CONTACT]: {
      full_name: 'Contact full name',
      first_name: 'Contact first name',
      last_name: 'Contact last name',
      company_domain: 'Company domain',
      job_title: 'Job title',
      email: 'Contact Email',
      twitter: 'Twitter',
      telegram: 'Telegram',
      linkedin: 'Linkedin',
      custom_1: 'Custom 1',
      custom_2: 'Custom 2',
    },
  }

  const fieldExamples: any = {
    [IMPORT_TYPE_NUMBER.COMPANY]: {
      domain: 'google.com, microsoft.com',
      name: 'Google, Microsoft',
      number_of_employees: '10, 3000000',
      location: 'United States, New York',
      vertical: 'Gaming, Legal',
      channels: 'Example 1, example 2',
      s3_logo: 'Example 1, example 2',
    },

    [IMPORT_TYPE_NUMBER.CONTACT]: {
      full_name: 'David Alaba, John Wick',
      first_name: 'David, Wayne',
      last_name: 'Alaba, Rooney',
      company_domain: 'google.com',
      job_title: 'Product Manager',
      email: 'david@email.com',
      twitter: 'https://twitter.com/david',
      telegram: 'https://telegram.com/david',
      linkedin: 'https://linkedin.com/david',
      custom_1: 'Custom 1',
      custom_2: 'Custom 2',
    },
  }

  const ERROR_MESSAGE_UNVALID_ROW = `Please upload fewer than ${IMPORT_MAX_ROWS_ALLOWED} ${
    isCompanyImport ? 'Companies' : 'Contacts'
  }.`

  const isFieldMappingInValid = isCompanyImport
    ? !fieldMapping[IMPORT_TYPE_NUMBER.COMPANY].domain
    : every(fieldMapping[IMPORT_TYPE_NUMBER.CONTACT], (value) => isNull(value) || value === '')

  const formatedDataAfterMapping = () => {
    return csvData?.rowData?.map((row: any[]) => {
      if (isCompanyImport) {
        return {
          domain: formatDomain(row[formatFieldKey[fieldMapping[IMPORT_TYPE_NUMBER.COMPANY].domain]]),
          name: row[formatFieldKey[fieldMapping[IMPORT_TYPE_NUMBER.COMPANY].name]],
          number_of_employees: row[formatFieldKey[fieldMapping[IMPORT_TYPE_NUMBER.COMPANY].number_of_employees]],
          location: row[formatFieldKey[fieldMapping[IMPORT_TYPE_NUMBER.COMPANY].location]],
          vertical: row[formatFieldKey[fieldMapping[IMPORT_TYPE_NUMBER.COMPANY].vertical]],
        }
      }
      const full_name =
        row[formatFieldKey[fieldMapping[IMPORT_TYPE_NUMBER.CONTACT].full_name]] ??
        `${row[formatFieldKey[fieldMapping[IMPORT_TYPE_NUMBER.CONTACT].first_name]] ?? ''} ${
          row[formatFieldKey[fieldMapping[IMPORT_TYPE_NUMBER.CONTACT].last_name]] ?? ''
        }`
      return {
        full_name: full_name,
        company_domain: formatDomain(row[formatFieldKey[fieldMapping[IMPORT_TYPE_NUMBER.CONTACT].company_domain]]),
        job_title: row[formatFieldKey[fieldMapping[IMPORT_TYPE_NUMBER.CONTACT].job_title]],
        email: row[formatFieldKey[fieldMapping[IMPORT_TYPE_NUMBER.CONTACT].email]],
        twitter: row[formatFieldKey[fieldMapping[IMPORT_TYPE_NUMBER.CONTACT].twitter]],
        telegram: row[formatFieldKey[fieldMapping[IMPORT_TYPE_NUMBER.CONTACT].telegram]],
        linkedin: row[formatFieldKey[fieldMapping[IMPORT_TYPE_NUMBER.CONTACT].linkedin]],
        custom_1: row[formatFieldKey[fieldMapping[IMPORT_TYPE_NUMBER.CONTACT].custom_1]],
        custom_2: row[formatFieldKey[fieldMapping[IMPORT_TYPE_NUMBER.CONTACT].custom_2]],
      }
    })
  }

  const handleUploadCSV = async () => {
    if (list_id) {
      const dataMapping = formatedDataAfterMapping() || []
      dispatch(SET_DATA_AFTER_MAPPING(dataMapping))

      const parsedCSV = Papa.unparse(dataMapping)
      const blob = new Blob([parsedCSV], { type: 'text/csv;charset=utf-8;' })

      dispatch(
        SET_CSV_DATA({
          file_blob: blob,
        }),
      )

      try {
        setIsloadingUpload(true)
        if (isCompanyImport) {
          // const domainListFormat = dataMapping
          //   ?.map((item: any) => (isCompanyImport ? item.domain : item.company_domain))
          //   .join(',')

          const formData = new FormData()
          formData.append('file', blob)
          formData.append('list_id', list_id)
          // formData.append('domains_list', domainListFormat)

          const endpointToUpload = '/companies/importCompaniesCSV'
          const data = await post(endpointToUpload, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          localStorage.setItem(
            'importResult',
            JSON.stringify({
              ...data,
              listId: list_id,
              invalidateCache: 'true',
            }),
          )
          onBackToExposedContact()
          setIsloadingUpload(false)
        } else {
          setIsloadingUpload(false)
          onContinue()
        }
      } catch (error) {
        console.error(error)
        message.error('Error when upload CSV')
        setIsloadingUpload(false)
      }
    }
  }

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: '.csv',
    beforeUpload: (file) => {
      if (file) {
        handleProcessFile(file)
      }
      return false
    },

    onDrop(e) {
      handleProcessFile(e.dataTransfer.files[0])
    },
  }

  const renderRowMapping = () => {
    return fieldMapping && importTypeNumber
      ? Object.keys(fieldMapping?.[importTypeNumber])?.map((field: string) => {
          return {
            key: field,
            field: fieldLabels?.[importTypeNumber][field],
            atribute: (
              <Select
                key={field}
                style={{ minWidth: '200px' }}
                options={['none', ...csvData?.fieldArray].map((fieldName: string) => {
                  return {
                    value: fieldName,
                    label: fieldName,
                  }
                })}
                value={fieldMapping?.[importTypeNumber][field]}
                onChange={(value: string) => {
                  const valueToChange = value === 'none' ? '' : value
                  const fieldKeyToSet = isCompanyImport ? IMPORT_TYPE_NUMBER.COMPANY : IMPORT_TYPE_NUMBER.CONTACT
                  setFieldMapping((oldFieldMapping: any) => {
                    return {
                      ...oldFieldMapping,
                      [fieldKeyToSet]: {
                        ...oldFieldMapping[fieldKeyToSet],
                        [field]: valueToChange,
                      },
                    }
                  })
                }}
              />
            ),
            exampleData: fieldExamples?.[importTypeNumber][field],
          }
        })
      : []
  }

  const handleProcessFile = (file: File) => {
    setIsloadingUpload(true)
    setFieldMapping(initialStateFieldMapping)

    Papa.parse(file, {
      complete: async (result) => {
        const { data, isValidAmountOfRow } = await validateResultPapaParse(result)

        if (!isValidAmountOfRow) {
          message.error(ERROR_MESSAGE_UNVALID_ROW)
          return
        }

        if (data) {
          handleSaveCSVData({
            ...data,
            file,
          })
        }
        setIsloadingUpload(false)
      },
      header: false,
      dynamicTyping: true,
      skipEmptyLines: true,
      error: (err) => {
        console.error(err.message)
        message.error('Error when read CSV file')
        setIsloadingUpload(false)
      },
    })
    setIsloadingUpload(false)
  }

  const downloadNotFoundDomains = (details: any) => {
    const csvContent = `data:text/csv;charset=utf-8,Domain\n${details.join('\n')}`
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    saveAs(blob, 'not_found_domains.csv')
  }

  React.useEffect(() => {
    const checkDomains = async () => {
      const listDomain = csvData?.rowData?.map((row: any[]) => {
        return formatDomain(row[formatFieldKey[fieldMapping[IMPORT_TYPE_NUMBER.COMPANY].domain]])
      })
      if (listDomain.length > 0) {
        message.info({
          content: 'Verifying domains, please wait...',
          icon: <InfoCircleOutlined style={{ color: '#7043ff', fontWeight: '600' }} />,
        })
        const response = await post('/companies/check/validate-domains', {
          domains: listDomain.filter(
            (domain) => domain && domain.toLowerCase() !== 'nan' && domain.toLowerCase() !== 'null',
          ),
        })
        if (response?.data.notFound > 0) {
          message.info({
            content: (
              <span style={{ cursor: 'pointer' }}>
                {`${response.data.found} companies found, ${response.data.notFound} not found. Click `}
                <b style={{ color: '#7043ff' }}>here</b>
                {` to download the CSV of domains not found.`}
              </span>
            ),
            duration: 10,
            onClick: () => downloadNotFoundDomains(response.data.notFoundDomains),
            icon: <InfoCircleOutlined style={{ color: '#7043ff', fontWeight: '600' }} />,
          })
        } else {
          message.success('All companies found.')
        }
      }
    }
    if (isCompanyImport && fieldMapping[IMPORT_TYPE_NUMBER.COMPANY]['domain']) {
      checkDomains()
    }
  }, [fieldMapping[IMPORT_TYPE_NUMBER.COMPANY]['domain']])

  React.useEffect(() => {
    if (csvData.file) {
      dispatch(SET_CSV_DATA(initialStateCsvData))
    }
  }, [])

  return (
    <div className={`${styles.importMapping} ${isCompanyImport && styles.importMapping__isCompanyImport}`}>
      {!csvData.file && (
        <div style={{ height: 'calc(100% - 85px)' }}>
          <Typography style={{ color: '#091E4299', padding: '0 0 15px' }}>
            {`Upload ${importText} CSV`} <InfoCircleOutlined />
          </Typography>
          <Dragger {...uploadProps} disabled={isLoadingUpload}>
            {isLoadingUpload ? (
              <>
                <Spin indicator={<LoadingOutlined spin />} size='large' />
                <Typography className={styles.importMapping__loading__title}>
                  Uploading and Validating CSV Name.csv ...
                </Typography>
                <Typography className={styles.importMapping__loading__description}>
                  Please wait while we upload and validate your data; this may take a couple of minutes.
                </Typography>
              </>
            ) : (
              <>
                <Typography className={styles.importMapping__title}>Click here or drag file to upload</Typography>
                <Typography className={styles.importMapping__description}>
                  Supported format: .csv Max file size: 10MB Max rows allowed: {IMPORT_MAX_ROWS_ALLOWED}
                </Typography>
              </>
            )}
          </Dragger>
        </div>
      )}

      {csvData.file && (
        <div className={styles.importMapping__result}>
          <div className={styles.importMapping__result__upload}>
            <Typography className={styles.importMapping__result__upload__title}>Upload {importText} CSV</Typography>

            <div className={styles.importMapping__result__upload__bar}>
              <div className={styles.importMapping__result__upload__bar__left}>
                <img src={csv_icon} alt='csv' />
                <div>
                  <Typography>{csvData.file?.name}</Typography>
                  <Typography>{csvData.file?.size} KB</Typography>
                </div>
              </div>

              <div className={styles.importMapping__result__upload__bar__right}>
                <DeleteOutlined
                  style={{ fontSize: '18px', cursor: 'pointer' }}
                  onClick={() =>
                    handleSaveCSVData({
                      file: null,
                      totalRowData: [],
                      rowData: [],
                      fieldArray: [],
                      unvalidRowData: [],
                    })
                  }
                />
                <label className={styles.importMapping__result__upload__bar__right__button} htmlFor='re-upload'>
                  <span>
                    <UploadOutlined /> Re-upload CSV
                  </span>
                </label>
                <input
                  type={'file'}
                  accept='.csv'
                  hidden
                  id='re-upload'
                  onChange={(event) => {
                    if (event.target.files) {
                      handleProcessFile(event.target.files[0])
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className={`${styles.importMapping__result__tableMapping} ${
              isCompanyImport && styles.importMapping__result__tableMapping__isCompanyImport
            }`}>
            <Typography className={styles.importMapping__result__upload__title}>Map your fields</Typography>
            <Typography className={styles.importMapping__result__upload__description}>
              To maximize your enrichment results, map as many fields from your CSV
            </Typography>
            <Table
              pagination={false}
              dataSource={renderRowMapping()}
              columns={columns}
              rootClassName={'importAndMappingTable'}
            />
          </div>
        </div>
      )}

      <div className={styles.importMapping__footer}>
        {isLoadingUpload && (
          <Spin style={{ margin: '0 10px' }} indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
        )}
        <Button
          className={`${styles.importMapping__result__footer__button} ${
            isLoadingUpload || (isFieldMappingInValid && styles['importMapping__result__footer__button--disabled'])
          }`}
          type='primary'
          disabled={isLoadingUpload || isFieldMappingInValid || !csvData?.file || csvData?.rowData?.length === 0}
          onClick={() => handleUploadCSV()}>
          Continue
        </Button>
      </div>
    </div>
  )
}

const columns = [
  {
    title: 'Field',
    dataIndex: 'field',
    key: 'field',
  },
  {
    title: 'Attribute',
    dataIndex: 'atribute',
    key: 'atribute',
  },
  {
    title: 'Example Data',
    dataIndex: 'exampleData',
    key: 'exampleData',
  },
]
