import React, { useMemo } from 'react'
import { Card, Divider, Tag, Tooltip } from 'antd'
import styles from './GroupCard.module.scss'

import telegram_icon from 'common/assets/svg/tele_icon.svg'

import group_locked_icon from 'common/assets/svg/group_locked_icon.svg'
import number_of_leads_icon from 'common/assets/svg/number_of_leads_icon.svg'

import { FancyImage } from 'features/contacts/components/FancyImage/FancyImage'
import groupFallback from 'common/assets/svg/add_leads_group.svg'
import { Group } from './GroupsContent'
import classNames from 'classnames'

interface GroupCardProps {
  group: Group
  onSelect: any
  isTelegramGroup?: boolean
}

const propsTooltip = {
  color: 'black',
  overlayStyle: {
    maxWidth: '600px',
    backgroundColor: 'white',
  },
  overlayInnerStyle: { backgroundColor: 'white' },
}

const GroupCard: React.FC<GroupCardProps> = ({ group, onSelect, isTelegramGroup = false }) => {
  const handleSelectGroup = () => {
    onSelect(group)
  }

  const elipsisDescription =
    group.description?.length > 120 ? (
      <Tooltip {...propsTooltip} title={group.description}>
        {group.description.slice(0, 120)}...
      </Tooltip>
    ) : (
      <span>{group.description}</span>
    )
  const description = group.description?.length > 0 ? elipsisDescription : 'No available description.'

  const elipsisName = group?.title ? (
    group.title?.length > 40 ? (
      <Tooltip {...propsTooltip} title={group.title}>
        {group.title.slice(0, 40)?.trim()}...
      </Tooltip>
    ) : (
      <span>{group.title}</span>
    )
  ) : (
    group?.merge_key
  )

  const formatedGroupTags = useMemo(() => {
    const tagsList = group?.tags ? JSON.parse(group?.tags || {}) : []
    const tags: string[] = []
    tagsList.forEach((element: any) => {
      element.label !== '' && element.type !== 'name' && tags.push(element?.label)
    })

    return tags
  }, [group])

  return (
    <Card className={`addLeadsModal ${styles.groupCard}`} hoverable onClick={handleSelectGroup}>
      {!isTelegramGroup && (
        <div className={styles.cardImage}>
          <div style={{ minHeight: '46px' }}>
            <FancyImage url={group.s3_logo} color={true} fallbackImage={groupFallback} />
          </div>
        </div>
      )}
      <div className={styles.cardHeader}>
        <div className={styles.groupName}>{elipsisName || '-'}</div>
      </div>
      <div className={styles.leads}>
        <div className={styles.numberOfLeads}>
          <img src={number_of_leads_icon} alt='' />
          <span>{`${group.number_of_members?.toLocaleString()}`}</span>
        </div>
        <Divider type='vertical' />
        <div className={styles.platform}>
          <img src={telegram_icon} alt='' />
          <span>Telegram</span>
        </div>
        <Divider type='vertical' />
        <div className={styles.groupStatus}>
          <img src={group_locked_icon} alt='' />
          <span>{'Locked'}</span>
        </div>
      </div>
      <div className={styles.cardBody}>
        <div className={classNames(styles.groupDescription, isTelegramGroup && styles.noTag)}>{description}</div>
        {!isTelegramGroup && (
          <div className={styles.groupTags}>
            {formatedGroupTags.length > 0 ? (
              <>
                {formatedGroupTags?.slice(0, 2).map((tag: any) =>
                  tag?.length > 30 ? (
                    <Tooltip title={tag}>
                      <span className={styles.tag}>{tag?.slice(0, 30)?.trim() + '...'}</span>
                    </Tooltip>
                  ) : (
                    <span className={styles.tag}>{tag}</span>
                  ),
                )}
                {formatedGroupTags?.length > 2 && (
                  <Tooltip
                    {...propsTooltip}
                    title={formatedGroupTags?.slice(2)?.map((tag: any) => (
                      <Tag
                        style={{
                          color: '#7043ff',
                          backgroundColor: '#f2f0ff',
                          padding: '2px 8px',
                          fontSize: '12px',
                          border: 'none',
                          marginBottom: '5px',
                        }}>
                        {tag}
                      </Tag>
                    ))}>
                    <span className={styles.tag}>+{formatedGroupTags?.length - 2}</span>
                  </Tooltip>
                )}
              </>
            ) : (
              <span className={styles.noTags}>No tags yet.</span>
            )}
          </div>
        )}
      </div>
    </Card>
  )
}

export default GroupCard
