import React, { useState } from 'react'
import { Slider, Row, Col, Button, Checkbox, Divider } from 'antd'
import styles from './BuyCredits.module.scss'

interface BuyCreditsProps {
  setConvrtPoints: any
  duration?: any
  showTitle?: any
  nextButton?: any
  handlePayment?: any
  plan?: any
  planType?: any
  quantity?: any
  TGQuantity?: any
  setTotalPrice?: any
  isFromModal?: boolean
  isStripeCustomer?: any
}

const BuyCredits: React.FC<BuyCreditsProps> = ({
  setConvrtPoints,
  duration,
  showTitle = true,
  nextButton,
  handlePayment,
  plan,
  planType,
  quantity,
  TGQuantity,
  setTotalPrice,
  isFromModal,
  isStripeCustomer,
}) => {
  const [selectedTokens, setSelectedTokens] = useState(0)
  const [agreedToTerms, setAgreedToTerms] = useState(false)
  const billingCycleMapping: any = {
    monthly: { text: 'month', number: 1 },
    three_months: { text: 'three months', number: 3 },
    yearly: { text: 'year', number: 12 },
  }
  const calculateCredits = (tokens: any) => {
    // const costA = 1.5
    // const costB = 0.2
    // const costC = 0.1
    // const costD = 19.9

    const creditA = Math.floor(tokens * 0.2)
    const creditB = Math.floor(tokens * 1)
    const creditC = Math.floor(tokens * 0.9)
    // const creditD = Math.floor(tokens * 0.2 * 0.01)

    // const totalCost =
    //   (creditA * costA + creditB * costB + creditC * costC + creditD * costD) *
    //   (duration === 'monthly' ? 1 : duration === 'six_months' ? 6 : 12)
    const managedAccount = 5
    const outreachSuite = 99
    const extraPriceOutreachSuite = Math.floor(TGQuantity / managedAccount) * outreachSuite

    const totalCost = isFromModal
      ? (tokens * 39.9) / 100
      : (typeof plan?.price === 'object' ? plan?.price?.[planType] : plan?.price?.[duration]) *
          parseFloat(quantity) *
          billingCycleMapping[duration]?.number +
        ((tokens * 39.9) / 100) * billingCycleMapping[duration]?.number +
        TGQuantity * 19.9 * billingCycleMapping[duration]?.number +
        extraPriceOutreachSuite * billingCycleMapping[duration]?.number
    setTotalPrice && setTotalPrice(totalCost)
    return { creditA, creditB, creditC, totalCost }
  }

  const handleCheckboxChange = (e: any) => {
    setAgreedToTerms(e.target.checked)
  }

  const handleTokenChange = (value: any) => {
    setSelectedTokens(value)
    setConvrtPoints(value)
  }

  const { totalCost } = calculateCredits(selectedTokens)

  const formatter = (value: any) => `${(value * 10).toLocaleString()}`
  return (
    <div className={styles.container}>
      {showTitle && <h2>Buy More Convrt Points</h2>}
      {isStripeCustomer?.company_admin ? (
        <span>You can assign the Convrt Points later in settings</span>
      ) : (
        <span>You will receive your points every {billingCycleMapping[duration]?.text}</span>
      )}
      <Row gutter={[16, 16]} justify='end' style={{ minWidth: '600px' }}>
        <Col span={20}>
          <div style={{ paddingLeft: '40px', paddingTop: '5px' }}>
            <Slider
              min={0}
              max={2500}
              step={100}
              onChange={handleTokenChange}
              value={selectedTokens}
              tooltip={{ formatter }}
            />
          </div>
        </Col>
        <Col span={4}>
          <div className={styles.displayTokens}>
            <span>{(selectedTokens * 10).toLocaleString()}</span>
          </div>
          {/* <InputNumber
            min={0}
            max={2500}
            style={{ marginLeft: 16 }}
            value={selectedTokens} 
            formatter={formatter}
            onChange={handleTokenChange}
            step={5}
            keyboard={false}
            disabled={true}
            // controls={false}
            className='buyCreditsInputNumber'
          /> */}
        </Col>
      </Row>
      <Divider style={{ margin: '10px' }} />
      <div className={styles.box}>
        {nextButton !== 'Contact Us' && (
          <>
            <span>{'Total Price: '}</span> ${(totalCost || 0)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            <span style={{ fontSize: '14px', fontWeight: 600 }}>
              /{duration === 'monthly' ? 'month' : duration === 'three_months' ? 'three-months' : 'year'}
            </span>
          </>
        )}
        <Button
          style={{
            borderRadius: '8px',
            fontSize: '16px',
            height: '42px',
            width: '120px',
            marginTop: '5px',
            marginLeft: '20px',
          }}
          key='next'
          type='primary'
          disabled={totalCost === 0 || plan?.id === 'free_trial' || !agreedToTerms || nextButton === 'Current'}
          onClick={handlePayment}>
          {nextButton}
        </Button>
      </div>
      <div className={styles.checkBox}>
        <Checkbox onChange={handleCheckboxChange}>
          <span>{`I agree to the `}</span>
          <a href='https://www.convrt.io/terms-of-use/' target='_blank' rel='noreferrer'>
            {`Terms & Conditions`}
          </a>
        </Checkbox>
      </div>
      {/* <Col span={4}>{creditD.toLocaleString()}</Col> */}
    </div>
  )
}

export default BuyCredits
