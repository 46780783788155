import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Layout, Modal, Space, Switch, message } from 'antd'
import download_csv from '../../../common/assets/svg/download_csv.svg'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import styles from './CSVModal.module.scss'
import {
  useGetAlreadyTackedCreditsMutation,
  // useGetCsvExportMutation,
  // useLazyGetTakeCreditsQuery,
  useExportToCsvMutation,
  useExportToCsvRemainingPointsMutation,
} from 'features/contacts/state/api/ContactsApi'
import FileSaver from 'file-saver'
import { useAppDispatch, useAppSelector } from 'state'
import { useGetCompaniesCSVMutation } from 'features/intent-signals/state/api/CompaniesApi'
import {
  SET_SELECTED_CONTACTS,
  SET_SELECTED_CONTACTS_IN_OUTREAH,
} from '../../../features/contacts/state/slice/contactsSlice'
import { post } from 'common/api/axios'
import OutOfPointsUseRemaining from 'common/components/OutOfPoints/OutOfPointsUseRemaining'

interface CSVModalProps {
  isCompany: boolean
  open: boolean
  onClose: () => void
  userPoints?: any
}

const CSVModal: React.FC<CSVModalProps> = ({ isCompany, open, onClose, userPoints }) => {
  const pageLookUp = {
    home: 'Home',
    events: 'Events',
    contacts: 'People',
    outreach: 'Outreach',
    companies: 'Research',
    settings: 'Profile Settings',
    company_admin: 'Company Admin Panel',
  }
  const locationPathName = (useLocation().pathname.split('/')[1] as keyof typeof pageLookUp) || 'home'

  const [isLoading, setIsLoading] = useState(false)
  const [telegramToggle, setTelegramToggle] = useState(true)
  const [emailToggle, setEmailToggle] = useState(true)
  // const [refetchGetTakeCredits] = useLazyGetTakeCreditsQuery()
  // const [refetchCsvExport] = useGetCsvExportMutation()
  const [refetchExportToCsv] = useExportToCsvMutation()
  const [refetchExportToCsvRemainingPoints] = useExportToCsvRemainingPointsMutation()
  const [fetchCSVExport] = useGetCompaniesCSVMutation()
  const { selected_contacts } = useAppSelector((state) => state.contacts)
  const { currentCustomList } = useAppSelector((state) => state.importContact)
  const { selected_companies } = useAppSelector((state) => state.companies)
  const [emailCredits, setEmailCredits] = useState([])
  const [telegramCredits, setTelegramCredits] = useState([])
  const [refetchTackedCredits] = useGetAlreadyTackedCreditsMutation()
  const [outOfPoints, setOutOfPoints] = useState(false)
  // const [costPoints, setCostPoints] = useState(0)
  const { selected_contacts: selected_contacts_in_outreach } = useAppSelector((state) => state.myContacts)
  const { selected_companies: selected_companies_in_outreach } = useAppSelector((state) => state.myContactsCompanies)
  const contacts_to_export =
    locationPathName === 'contacts' || currentCustomList ? selected_contacts : selected_contacts_in_outreach
  const company_to_export = locationPathName === 'companies' ? selected_companies : selected_companies_in_outreach

  const dispatch = useAppDispatch()

  const handleToggleSwitchTelegram = () => {
    setTelegramToggle(!telegramToggle)
  }

  const handleToggleSwitchEmail = () => {
    setEmailToggle(!emailToggle)
  }

  const handleContactsExport = async () => {
    setIsLoading(true)
    const contacts = contacts_to_export.map((id) => ({
      id,
      email: emailCredits.some((item: any) => item.id === id),
      telegram: telegramCredits.some((item: any) => item.id === id),
    }))

    const result = await refetchExportToCsv({
      contacts,
      telegramCredits,
      emailCredits,
      contacts_to_export,
      emailToggle,
      telegramToggle,
      phone: false,
    })

    if (result.error.originalStatus === 200) {
      const csvData = new Blob([result.error.data], { type: 'text/csv;charset=utf-8;' })
      FileSaver.saveAs(csvData, 'Contacts.csv')
      message.success(`Contacts Exported to CSV successfully`)

      // message.success(
      //   `Contacts Exported to CSV successfully, ${
      //     contacts_to_export?.length * userPoints?.pointsStore?.contacts_export +
      //     emailTakeCredits * userPoints?.pointsStore?.email +
      //     telegramTakeCredits * userPoints?.pointsStore?.telegram
      //   } points were redeemed.`,
      // )
      post('/log', {
        action: 'Export CSV Contacts Succeeded',
      })
      onClose()
    } else {
      if (result.error.status === 470) {
        setOutOfPoints(true)
      } else {
        post('/log', {
          action: 'Export CSV Contacts Failed',
        })
        message.error(result.error.data.message)
      }
      // onClose()
    }

    // let telegramTakeCredits = 0
    // if (telegramToggle) {
    //   telegramTakeCredits = 0
    //   for (let index = 0; index < telegramCredits.length; index++) {
    //     const dataTakeCreditTelegram = await refetchGetTakeCredits({
    //       contact_id: telegramCredits[index]['id'],
    //       value: 'telegram_account',
    //       isDisplayed: { email: false, telegram: false, phone: false },
    //       where_opened: 'export_csv',
    //     })
    //     console.log(dataTakeCreditTelegram, 'dataTakeCreditTelegram')
    //     if (dataTakeCreditTelegram?.data?.msgStatus === 0) {
    //       telegramTakeCredits++
    //     }
    //     //  msgStatus
    //   }
    // }
    // let emailTakeCredits = 0
    // if (emailToggle) {
    //   emailTakeCredits = 0

    //   for (let index = 0; index < emailCredits.length; index++) {
    //     const dataTakeCreditEmail = await refetchGetTakeCredits({
    //       contact_id: emailCredits[index]['id'],
    //       value: 'email',
    //       isDisplayed: { email: false, telegram: false, phone: false },
    //       where_opened: 'export_csv',
    //     })
    //     if (dataTakeCreditEmail?.data?.msgStatus === 0) {
    //       emailTakeCredits++
    //     }
    //   }
    // }

    // const result = await refetchCsvExport(contacts_to_export)

    // if (result?.error?.data?.message) {
    //   post('/log', {
    //     action: 'Export CSV Contacts Failed',
    //   })
    //   message.error(result.error.data.message)
    //   onClose()
    // } else {
    //   locationPathName === 'contacts' &&
    // selected_contacts.forEach(async (contactId: any) => {
    //   await post(`/outreach/exposed`, {
    //     email: emailToggle,
    //     telegram: telegramToggle,
    //     phone: false,
    //     contact_id: contactId,
    //   })
    // })
    //   const csvData = new Blob([result.error.data], { type: 'text/csv;charset=utf-8;' })
    //   FileSaver.saveAs(csvData, 'Contacts.csv')
    //   message.success(
    //     `Contacts Exported to CSV successfully, ${
    //       contacts_to_export?.length * userPoints?.pointsStore?.contacts_export +
    //       emailTakeCredits * userPoints?.pointsStore?.email +
    //       telegramTakeCredits * userPoints?.pointsStore?.telegram
    //     } points were redeemed.`,
    //   )
    //   post('/log', {
    //     action: 'Export CSV Contacts Succeeded',
    //   })
    //   onClose()
    // }
    setIsLoading(false)
  }

  const handleContactsExportUseRemainingPoints = async () => {
    setIsLoading(true)

    const contacts = contacts_to_export.map((id) => ({
      id,
      email: emailCredits.some((item: any) => item.id === id),
      telegram: telegramCredits.some((item: any) => item.id === id),
    }))

    const result = await refetchExportToCsvRemainingPoints({
      contacts,
      telegramCredits,
      emailCredits,
      contacts_to_export,
      emailToggle,
      telegramToggle,
      phone: false,
    })

    if (result.error.originalStatus === 200) {
      const csvData = new Blob([result.error.data], { type: 'text/csv;charset=utf-8;' })
      FileSaver.saveAs(csvData, 'Contacts.csv')
      message.success(`Contacts Exported to CSV successfully`)

      // message.success(
      //   `Contacts Exported to CSV successfully, ${
      //     contacts_to_export?.length * userPoints?.pointsStore?.contacts_export +
      //     emailTakeCredits * userPoints?.pointsStore?.email +
      //     telegramTakeCredits * userPoints?.pointsStore?.telegram
      //   } points were redeemed.`,
      // )
      post('/log', {
        action: 'Export CSV Contacts Succeeded',
      })
      onClose()
    } else {
      if (result.error.status === 470) {
        setOutOfPoints(true)
        message.error(result.error.data.message)
      } else {
        post('/log', {
          action: 'Export CSV Contacts Failed',
        })
        message.error(result.error.data.message)
      }
      // onClose()
    }
    setIsLoading(false)
  }

  const handleCompanyExport = async () => {
    const result = await fetchCSVExport({ companiesId: company_to_export })

    if (result?.error?.data?.message) {
      message.error(result.error.data.message)
      post('/log', {
        action: 'Export CSV Companies Failed',
      })
    } else {
      const csvData = new Blob([result.error.data], { type: 'text/csv;charset=utf-8;' })
      FileSaver.saveAs(csvData, 'Companies.csv')
      message.success(
        `Companies Exported to CSV Successfully, ${
          company_to_export?.length * userPoints?.pointsStore?.expose_communities
        } points were redeemed.`,
      )
      post('/log', {
        action: 'Export CSV Companies Succeeded',
      })
      onClose()
    }
  }

  useEffect(() => {
    contacts_to_export?.forEach((item: string) => {
      let selectedRowsList = contacts_to_export
      if (!selectedRowsList.includes(item)) {
        locationPathName === 'contacts'
          ? dispatch(SET_SELECTED_CONTACTS_IN_OUTREAH([...selected_contacts_in_outreach, item]))
          : dispatch(SET_SELECTED_CONTACTS([...selected_contacts, item]))
      }
    })

    const fetchData = async () => {
      const result = await refetchTackedCredits({
        contacts: contacts_to_export,
      })

      if (result) {
        setTelegramCredits(result?.data?.response?.telegram)
        setEmailCredits(result?.data?.response?.email)
      }
    }

    fetchData().catch(console.error)
  }, [contacts_to_export])

  return (
    <>
      <Modal
        className='modalStyleNewDeal'
        centered
        open={open}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}
        onCancel={() => {
          onClose()
        }}
        width={'fit-content'}
        style={{ minWidth: '430px', maxWidth: '430px' }}>
        <Space direction='vertical' style={{ width: '100%' }} size={[0, 48]}>
          <Layout>
            <Header className={styles.headerStyle}>
              <img src={download_csv} alt='' />
              <h1>Export To CSV</h1>
            </Header>

            <Content className={styles.contentStyle}>
              {isLoading ? (
                <div className={styles.loading}>
                  <LoadingBox />
                </div>
              ) : (
                <div className={styles.container}>
                  <div className={styles.header}>
                    <p className={styles.title}>Export to CSV</p>
                    <span className={styles.description}>
                      This action will cost you{' '}
                      {0 |
                        (isCompany
                          ? company_to_export?.length * userPoints?.pointsStore?.expose_communities
                          : contacts_to_export?.length * userPoints?.pointsStore?.contacts_export)}{' '}
                      export Points!
                    </span>
                    {!isCompany && (
                      <div className={styles.switchContainer}>
                        <div className={styles.switchItem}>
                          <Switch onChange={handleToggleSwitchTelegram} checked={telegramToggle} size={'small'} />
                          <span className={styles.switchLabel}>
                            Expose Telegram Accounts (Up to{' '}
                            {telegramCredits?.length * userPoints?.pointsStore?.telegram} Points)
                          </span>
                        </div>
                        <div className={styles.switchItem}>
                          <Switch onChange={handleToggleSwitchEmail} checked={emailToggle} size={'small'} />
                          <span className={styles.switchLabel}>
                            Expose Email Addresses (Up to {emailCredits?.length * userPoints?.pointsStore?.email}{' '}
                            Points)
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Content>

            <Footer className={styles.footerStyle}>
              <div className={styles.section}>
                <div
                  className={styles.back}
                  onClick={() => {
                    !isLoading && onClose()
                  }}>
                  <span>Cancel</span>
                </div>

                <Button
                  className={styles.button}
                  onClick={() => {
                    post('/log', {
                      action: locationPathName === 'contacts' ? 'Export CSV Contacts' : 'Export CSV Companies',
                    })
                    !isLoading && (isCompany ? handleCompanyExport() : handleContactsExport())
                  }}>
                  Export
                </Button>
              </div>
            </Footer>
          </Layout>
        </Space>
      </Modal>
      {outOfPoints && (
        <OutOfPointsUseRemaining
          open={outOfPoints}
          onClose={() => {
            onClose()
            // setOutOfPoints(false)
            //onClose()
          }}
          onUseRemainingPoints={() => {
            handleContactsExportUseRemainingPoints()
            onClose()
          }}
          cost_points={
            (isCompany
              ? company_to_export?.length * userPoints?.pointsStore?.expose_communities
              : contacts_to_export?.length * userPoints?.pointsStore?.contacts_export) +
            emailCredits?.length * userPoints?.pointsStore?.email +
            telegramCredits?.length * userPoints?.pointsStore?.telegram
          }
        />
      )}
    </>
  )
}
export default CSVModal
