import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IFilters {
  isSidebarOpen: boolean
  isTechSidebarOpen: boolean
  resetFilter: boolean
  techSidebarOpenType: string
  isSubscriptionDaysPassed: boolean
}

const initialState = {
  isSidebarOpen: false,
  isTechSidebarOpen: false,
  resetFilter: false,
  techSidebarOpenType: '',
  isSubscriptionDaysPassed: false,
} as IFilters

const filtersSlice = createSlice({
  name: 'filters',
  initialState: initialState,
  reducers: {
    setSidebarOpen(state, action: PayloadAction<boolean>) {
      state.isSidebarOpen = action.payload
    },
    setTechSidebarOpen(state, action: PayloadAction<boolean>) {
      state.isTechSidebarOpen = action.payload
    },
    setReset(state, action: PayloadAction<boolean>) {
      state.resetFilter = action.payload
    },
    setTechSidebarType(state, action: PayloadAction<string>) {
      state.techSidebarOpenType = action.payload
    },
    setIsSubscriptionDaysPassed(state, action: PayloadAction<boolean>) {
      state.isSubscriptionDaysPassed = action.payload
    },
  },
})

export const { setSidebarOpen, setTechSidebarOpen, setReset, setTechSidebarType, setIsSubscriptionDaysPassed } =
  filtersSlice.actions

export const filtersReducer = filtersSlice.reducer
