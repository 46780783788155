import { useEffect, useRef } from 'react'
import lottie from 'lottie-web'

import animationData from '../../assets/lottie-animations/connect_plus_success.json'

import styles from './Success.module.scss'

const PulseAnimation = () => {
  const lottieContainerRef = useRef(null)

  useEffect(() => {
    if (lottieContainerRef.current) {
      lottie.loadAnimation({
        container: lottieContainerRef.current,
        animationData,
        loop: false,
        renderer: 'svg',
      })
    }
  }, [])

  return (
    <div className={styles.animationContainer}>
      <div ref={lottieContainerRef} />
    </div>
  )
}

export default PulseAnimation
