import React, { useEffect, useRef, useState } from 'react'
import { Collapse, Input, Select, Spin, Typography } from 'antd'
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import styles from './ChatTab.module.scss'
import ChatDialog from './ChatDialog'
import { useLazyGetDialogsQuery, useLazyMarkDialogReadQuery, useLazySearchTagQuery } from '../state/api/Chat-API'
import { useAppSelector } from 'state'
// import { SET_SELECTED_CHAT_RECIPIENT } from '../state/chatSlice'
import { AutoSizer, List } from 'react-virtualized'
import pin_large_icon from 'common/assets/svg/pin_large_icon.svg'
import { post } from 'common/api/axios'
import { isArray, lowerCase } from 'lodash'
import { useDebounce } from '../Tag/Hook/useDebounce'
import TagItem from '../Tag/TagItem'
import useClickOutside from '../Tag/Hook/useClickOutside'
// import { List, AutoSizer } from 'react-virtualized'

interface ChatTabProps {
  filter?: any
  activeItemId: any
  setActiveItemId: any
}

const { Panel } = Collapse

const ROW_HEIGHT_WITH_HEADER = 210
const ROW_HEIGHT = 180

const ChatTab: React.FC<ChatTabProps> = ({ filter, activeItemId, setActiveItemId }) => {
  const [getDialogs, { data: dialogsData }] = useLazyGetDialogsQuery()
  const [markDialogAsRead] = useLazyMarkDialogReadQuery()
  const searchResultRef = useRef(null)

  const { selected_account, is_unify_finished, all_tags_dialog_data } = useAppSelector((state) => state.chat)
  // const [pin, setPin] = useState(pin_icon)
  // const [selectedOption, setSelectedOption] = useState<string>('') // State to track the selected option
  const [searchQuery, setSearchQuery] = useState<any>('')
  const [listKey, setListKey] = useState(0)
  const listRef = useRef<any>(null)
  const listRefGroup = useRef<any>(null)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [scrollPositionGroup, setScrollPositionGroup] = useState(0)

  const [groupBy, setGroupBy] = useState<string>('none')
  const [groupedDialogs, setGroupedDialogs] = useState<any>([])
  // const [isActive, setActive] = useState(false)
  const [isPushpinClicked, setIsPushpinClicked] = useState(false)
  // eslint-disable-next-line
  const [filteredDialogs, setFilteredDialogs] = useState<any>([])
  const [searchedDialogs, setSearchedDialogs] = useState(filteredDialogs)
  const [pinnedDialogs, setPinnedDialogs] = useState<any>([])
  const [unpinnedDialogs, setUnpinnedDialogs] = useState<any>([])

  const [tagSearch, setTagSearch] = useState<any>([])
  const [tagSearchResult, setTagSearchResult] = useState<any>()

  useClickOutside(searchResultRef, () => {
    setSearchQuery('')
    setTagSearchResult([])
  })

  const accountsReadUnreadSentReplied = dialogsData?.telegram?.accountsReadUnreadSentReplied || {}
  let sentMessagesCount
  let repliedMessagesCount
  if (selected_account?.account_id === 'all_data') {
    if (filter === 'read') {
      sentMessagesCount = accountsReadUnreadSentReplied['all_data']?.readSent
      repliedMessagesCount = accountsReadUnreadSentReplied['all_data']?.readReplied
    } else if (filter === 'unread') {
      sentMessagesCount = accountsReadUnreadSentReplied['all_data']?.unreadSent
      repliedMessagesCount = accountsReadUnreadSentReplied['all_data']?.unreadReplied
    } else if (filter === 'groups') {
      sentMessagesCount = accountsReadUnreadSentReplied['all_data']?.groupsSent
      repliedMessagesCount = accountsReadUnreadSentReplied['all_data']?.groupsReplied
    } else {
      sentMessagesCount = accountsReadUnreadSentReplied['all_data']?.sent
      repliedMessagesCount = accountsReadUnreadSentReplied['all_data']?.replied
    }
  } else {
    if (filter === 'read') {
      sentMessagesCount = accountsReadUnreadSentReplied[selected_account.receiver_number]?.readSent
      repliedMessagesCount = accountsReadUnreadSentReplied[selected_account.receiver_number]?.readReplied
    } else if (filter === 'unread') {
      sentMessagesCount = accountsReadUnreadSentReplied[selected_account.receiver_number]?.unreadSent
      repliedMessagesCount = accountsReadUnreadSentReplied[selected_account.receiver_number]?.unreadReplied
    } else if (filter === 'groups') {
      sentMessagesCount = accountsReadUnreadSentReplied[selected_account.receiver_number]?.groupsSent
      repliedMessagesCount = accountsReadUnreadSentReplied[selected_account.receiver_number]?.groupsReplied
    } else {
      sentMessagesCount = accountsReadUnreadSentReplied[selected_account.receiver_number]?.sent
      repliedMessagesCount = accountsReadUnreadSentReplied[selected_account.receiver_number]?.replied
    }
  }

  const Options = [
    {
      id: 1,
      name: 'Sent',
      label: sentMessagesCount,
    },
    {
      id: 2,
      name: 'Replied',
      label: repliedMessagesCount,
    },
    // { id: 3, name: 'Meeting Books', label: '20' },
  ]

  const handleScroll = ({ scrollTop }: any) => {
    setScrollPosition(scrollTop)
  }

  const handleScrollGroup = ({ scrollTop }: any) => {
    setScrollPositionGroup(scrollTop)
  }

  const handleGroupByChange = (value: string) => {
    setGroupBy(value)
    // console.log('Key Changed')
    setListKey((prev) => prev + 1)
    if (value !== 'none') {
      post('/log', {
        action: `Unified Inbox: Group By: ${value}`,
      })
    }
  }
  const handleOptionClick = (optionName: string, id: number) => {
    if (activeItemId === id) {
      setActiveItemId(null)
      // setSentRepliedFilteredDialogs(filteredDialogs)
      // post('/log', {
      //   action: `Unified Inbox: Remove Filter by Sent / Replied`,
      // })
    } else {
      setActiveItemId(id)
      if (id === 1) {
        // Sent
        const filtered = filteredDialogs?.filter((dialog: any) => dialog?.sent === true)
        setFilteredDialogs(filtered)
        // setSentRepliedFilteredDialogs(filtered)
        post('/log', {
          action: `Unified Inbox: Filter by Sent`,
        })
      } else {
        // Replied
        const filtered = filteredDialogs?.filter((dialog: any) => dialog?.replied === true)
        setFilteredDialogs(filtered)
        // setSentRepliedFilteredDialogs(filtered)
        post('/log', {
          action: `Unified Inbox: Filter by Replied`,
        })
      }
    }
    // setSelectedOption(optionName) // Set the selected option
  }
  // eslint-disable-next-line
  const handlePushpinClick = () => {
    setIsPushpinClicked(!isPushpinClicked)
  }

  // const changePin = (e: any) => {
  //   let value = pin

  //   if (value === pin_icon) {
  //     setPin(pinned_chat)
  //   } else {
  //     setPin(pin_icon)
  //   }
  // }

  const sortDialogs = (dialogs: any, sortKey: any) => {
    const dialogsCopy = [...dialogs]
    const priorityOrder: any = { high: 1, medium: 2, low: 3, '': 4, null: 4 }

    dialogsCopy.sort((a: any, b: any) => {
      if (sortKey === 'priority') {
        const valueA = priorityOrder[a[sortKey]] || priorityOrder['']
        const valueB = priorityOrder[b[sortKey]] || priorityOrder['']
        return valueA - valueB
      } else {
        const valueA = a[sortKey] === null ? '\uFFFF' : a[sortKey] || ''
        const valueB = b[sortKey] === null ? '\uFFFF' : b[sortKey] || ''
        return valueA.localeCompare(valueB)
      }
    })

    return dialogsCopy
  }

  const groupDialogs = (dialogs: any) => {
    if (!groupBy || groupBy === 'none') return { _: dialogs }

    const unpinned = dialogs.filter((dialog: any) => !dialog.pin)
    const pinned = dialogs.filter((dialog: any) => dialog.pin)

    if (groupBy === 'tags') {
      const enrichedDialogs = dialogs.map((dialog: any) => {
        const allTagOfDialog = all_tags_dialog_data?.find((tagData: any) => tagData.dialog_id === dialog.id)?.tags

        const customTags = allTagOfDialog ? allTagOfDialog?.map((tag: any) => tag.tag_name) : []
        return {
          ...dialog,
          customTags,
        }
      })
      let groupedByTags: any = {}
      groupedByTags = enrichedDialogs?.reduce((acc: any, dialog: any) => {
        dialog?.customTags?.forEach((tag: any) => {
          if (!acc[tag]) {
            acc[tag] = []
          }
          acc[tag].push(dialog)
        })
        return acc
      }, {})

      if (pinned?.length > 0) {
        groupedByTags.pinnedArr = pinned
      }
      return groupedByTags
    }

    const grouped = unpinned.reduce((acc: any, dialog: any) => {
      let key = dialog[groupBy] || 'Other'
      switch (key) {
        case 'high':
          key = 'High'
          break
        case 'medium':
          key = 'Medium'
          break
        case 'low':
          key = 'Low'
          break
        case 'interested':
          key = 'Interested'
          break
        case 'not_interested':
          key = 'Not Interested'
          break
        case 'booked':
          key = 'Meeting Booked'
          break
        case 'not_right_contact':
          key = 'Not Right Contact'
          break
        case 'closed':
          key = 'Closed'
          break
        case 'not_now':
          key = 'Not Now'
          break
        case 'do_not_contact':
          key = 'Do Not Contact'
          break
        default:
          break
      }
      acc[key] = acc[key] || []
      acc[key].push(dialog)
      return acc
    }, {})

    if (pinned?.length > 0) {
      grouped.pinnedArr = pinned
    }

    return grouped
  }

  const filterDialogsByAccount = (data: any, selected: any) => {
    if (!data || !selected || !selected.type) {
      return []
    }

    const readData = data?.[selected.type]?.readData || []
    const unreadData = data?.[selected.type]?.unreadData || []
    const groupsData = data?.[selected.type]?.groupsData || []
    const dialogData = data?.[selected.type]?.dialogData || []
    // const dialogData = [...unreadData, ...readData]

    let updatedData =
      filter === 'read' ? readData : filter === 'unread' ? unreadData : filter === 'groups' ? groupsData : dialogData

    if (filter !== 'groups' && activeItemId === 1) {
      updatedData = updatedData?.filter((dialog: any) => dialog?.sent === true)
    } else if (filter !== 'groups' && activeItemId === 2) {
      updatedData = updatedData?.filter((dialog: any) => dialog?.replied === true)
    }

    switch (groupBy) {
      case 'company':
        updatedData = sortDialogs(updatedData, 'company')
        // console.log('Key Changed')
        setListKey((prev) => prev + 1)
        break
      case 'priority':
        updatedData = sortDialogs(updatedData, 'priority')
        // console.log('Key Changed')
        setListKey((prev) => prev + 1)
        break
      case 'campaign_name':
        updatedData = sortDialogs(updatedData, 'campaign_name')
        // console.log('Key Changed')
        setListKey((prev) => prev + 1)
        break
      case 'meeting_book':
        updatedData = sortDialogs(updatedData, 'meeting_book')
        setListKey((prev) => prev + 1)
        break
      case 'tags':
        updatedData = sortDialogs(updatedData, 'tags')
        setListKey((prev) => prev + 1)
        break
      default:
        setListKey((prev) => prev + 1)
        // console.log('Key Changed')
        break
    }

    if (selected.account_id === 'all_data') {
      return updatedData
    }

    if (Array.isArray(updatedData)) {
      return updatedData.filter((dialog: any) => dialog.receiver_number === selected.receiver_number)
    } else {
      return []
    }
  }

  const handleOnClick = async (id: any, senderData: any, platform: string) => {
    await markDialogAsRead({
      dialog_id: id,
      senderData: senderData,
      platform: platform,
    })
    await getDialogs()
  }

  const includeHeader = (index: number, list: any) => {
    const isGroupBy = list[index]?.[groupBy] !== list[index - 1]?.[groupBy]
    const isPinned = list[index]?.pin !== list[index - 1]?.pin
    return index === 0 || isGroupBy || isPinned
  }

  const headerSelector = (index: number, list: any[]) => {
    const currentItem = list[index]
    const prevItem = list[index - 1]

    const formatTitle = (str: string | undefined, defaultValue: string) => {
      return str ? str[0].toUpperCase() + str.slice(1) : defaultValue
    }

    // Check for pin header
    if (index === 0 && currentItem.pin) {
      return (
        <div className={styles.pinedChats}>
          <h3 className={styles.chat_box_title}>Pin</h3>
          <img src={pin_large_icon} alt='' />
        </div>
      )
    }

    // Check for group header changes
    if (!currentItem.pin && currentItem[groupBy] !== prevItem?.[groupBy]) {
      let title = ''
      switch (groupBy) {
        case 'priority':
          title = formatTitle(currentItem.priority, 'New')
          break
        case 'meeting_book':
          title = formatTitle(currentItem.meeting_book, 'Meeting Book')
          break
        case 'company':
          title = formatTitle(currentItem.company, 'Company Name')
          break
        case 'campaign_name':
          if (currentItem.campaign_name === prevItem?.campaign_name && currentItem.pin !== prevItem.pin) {
            break
          }
          title = formatTitle(currentItem.campaign_name, 'Campaign Name')
          break
        default:
          break
      }
      return title ? (
        <div className={styles.pinedChats}>
          <h3 className={styles.chat_box_title}>{title}</h3>
        </div>
      ) : null
    }

    // Check if it's the first unpinned item
    if (currentItem === unpinnedDialogs[0]) {
      if (!currentItem.pin && currentItem[groupBy] === prevItem?.[groupBy]) {
        let title = ''
        switch (groupBy) {
          case 'priority':
            title = formatTitle(currentItem.priority, 'New')
            break
          case 'meeting_book':
            title = formatTitle(currentItem.meeting_book, 'Meeting Book')
            break
          case 'company':
            title = formatTitle(currentItem.company, 'Company Name')
            break
          case 'campaign_name':
            if (currentItem.campaign_name === prevItem?.campaign_name && currentItem.pin !== prevItem.pin) {
              break
            }
            title = formatTitle(currentItem.campaign_name, 'Campaign Name')
            break
          default:
            break
        }
        return title ? (
          <div className={styles.pinedChats}>
            <h3 className={styles.chat_box_title}>{title}</h3>
          </div>
        ) : (
          <div className={styles.pinedChats}>
            <h3 className={styles.chat_box_title}>New</h3>
          </div>
        )
      } else {
        return (
          <div className={styles.pinedChats}>
            <h3 className={styles.chat_box_title}>New</h3>
          </div>
        )
      }
    }

    return null // Return null if no conditions match
  }

  // const headerSelector2 = (index: number, list: any) => {
  //   const currentItem = list[index]
  //   const prevItem = list[index - 1]

  //   if (index === 0 && currentItem.pin) {
  //     return (
  //       <div className={styles.pinedChats}>
  //         <h3 className={styles.chat_box_title}>Pin</h3>
  //         <img src={pin_large_icon} alt='' />
  //       </div>
  //     )
  //   } else if (!currentItem.pin && groupBy === 'priority' && currentItem?.[groupBy] !== prevItem?.[groupBy]) {
  //     const title =
  //       currentItem?.priority?.[0]?.toUpperCase() + currentItem?.priority?.slice(1, currentItem?.priority?.length) ||
  //       'New'
  //     return (
  //       <div className={styles.pinedChats}>
  //         <h3 className={styles.chat_box_title}>{title}</h3>
  //       </div>
  //     )
  //   } else if (!currentItem.pin && groupBy === 'company' && currentItem?.[groupBy] !== prevItem?.[groupBy]) {
  //     const title =
  //       currentItem?.company?.[0]?.toUpperCase() + currentItem?.company?.slice(1, currentItem?.company?.length) ||
  //       'Company Name'
  //     return (
  //       <div className={styles.pinedChats}>
  //         <h3 className={styles.chat_box_title}>{title}</h3>
  //       </div>
  //     )
  //   } else if (
  //     !currentItem.pin &&
  //     groupBy === 'campaign_name' &&
  //     (currentItem?.['campaign_name'] !== prevItem?.['campaign_name'] ||
  //       (currentItem?.['campaign_name'] === prevItem?.['campaign_name'] && currentItem.pin !== prevItem.pin))
  //   ) {
  //     const title =
  //       currentItem?.campaign_name?.[0]?.toUpperCase() +
  //         currentItem?.campaign_name?.slice(1, currentItem?.campaign_name?.length) || 'Campaign Name'
  //     return (
  //       <div className={styles.pinedChats}>
  //         <h3 className={styles.chat_box_title}>{title}</h3>
  //       </div>
  //     )
  //   } else if (currentItem === unpinnedDialogs[0]) {
  //     return (
  //       <div className={styles.pinedChats}>
  //         <h3 className={styles.chat_box_title}>New</h3>
  //       </div>
  //     )
  //   }
  // }

  useEffect(() => {
    const getChatdata = async () => {
      await getDialogs()
    }
    if (is_unify_finished) {
      getChatdata()
    }
  }, [is_unify_finished])

  const checkDialogContainTag = (dialogId: any, searchQuery: string[]) => {
    if (!dialogId) return false

    const allTagOfDialog = all_tags_dialog_data?.find((dialog: any) => dialog.dialog_id === dialogId)?.['tags']
    const allTagNameOfDialog = allTagOfDialog?.map((tagDL: any) => tagDL.tag_name)
    let result = false
    searchQuery.forEach((item) => {
      result = allTagNameOfDialog?.some((tagName: any) => lowerCase(tagName) === lowerCase(item))
    })
    return result
  }

  useEffect(() => {
    if (dialogsData && selected_account) {
      const dialogs = filterDialogsByAccount(dialogsData, selected_account)

      // if (activeItemId === 1) {
      //   // sent
      //   const filtered = dialogs?.filter((dialog: any) => dialog?.sent === true)
      //   console.log('====')
      //   console.log(dialogs)
      //   console.log('====')
      //   console.log(filtered)
      //   console.log('====')
      //   setFilteredDialogs(filtered)
      //   // setSentRepliedFilteredDialogs(filtered)
      // } else if (activeItemId === 2) {
      //   // replied
      //   const filtered = dialogs?.filter((dialog: any) => dialog?.replied === true)
      //   setFilteredDialogs(filtered)
      //   // setSentRepliedFilteredDialogs(filtered)
      // } else {
      //   setFilteredDialogs(dialogs)
      // }

      let filtered
      switch (groupBy) {
        case 'company':
          filtered = dialogs?.filter((dialog: any) => {
            const isDialogContainTag = checkDialogContainTag(dialog?.id, tagSearch)
            return tagSearch?.length > 0
              ? isDialogContainTag
              : dialog.company?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
                  dialog?.username?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                  dialog?.company?.toLowerCase()?.includes(searchQuery?.toLowerCase())
          })
          break
        case 'priority':
          filtered = dialogs?.filter((dialog: any) => {
            const isDialogContainTag = checkDialogContainTag(dialog?.id, tagSearch)
            return tagSearch?.length > 0
              ? isDialogContainTag
              : dialog.title?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
                  dialog?.username?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                  dialog?.company?.toLowerCase()?.includes(searchQuery?.toLowerCase())
          })
          break
        case 'meeting_book':
          filtered = dialogs?.filter((dialog: any) => {
            const isDialogContainTag = checkDialogContainTag(dialog?.id, tagSearch)
            return tagSearch?.length > 0
              ? isDialogContainTag
              : dialog.title?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
                  dialog?.username?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                  dialog?.company?.toLowerCase()?.includes(searchQuery?.toLowerCase())
          })
          break
        case 'campaign_name':
          filtered = dialogs?.filter((dialog: any) => {
            const isDialogContainTag = checkDialogContainTag(dialog?.id, tagSearch)
            return tagSearch.length > 0
              ? isDialogContainTag
              : dialog.campaign_name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
                  dialog?.username?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                  dialog?.company?.toLowerCase()?.includes(searchQuery?.toLowerCase())
          })
          break
        case 'tags':
          filtered = dialogs?.filter((dialog: any) => {
            const isDialogContainTag = checkDialogContainTag(dialog?.id, tagSearch)
            return tagSearch.length > 0
              ? isDialogContainTag
              : dialog.title?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
                  dialog?.username?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                  dialog?.company?.toLowerCase()?.includes(searchQuery?.toLowerCase())
          })
          break
        default:
          filtered = dialogs?.filter((dialog: any) => {
            const isDialogContainTag = checkDialogContainTag(dialog?.id, tagSearch)

            return tagSearch.length > 0
              ? isDialogContainTag
              : lowerCase(dialog?.title).includes(lowerCase(searchQuery)) ||
                  dialog?.username?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                  dialog?.company?.toLowerCase()?.includes(searchQuery?.toLowerCase())
          })
          break
      }

      setSearchedDialogs(filtered)
      setFilteredDialogs(dialogs)

      // const grouped = groupDialogs(filtered)
      // setGroupedDialogs(grouped)

      // console.log('===')
      // console.log(grouped)
      // console.log('===')

      // setSearchedDialogs(dialogs)
    }
  }, [dialogsData, selected_account, activeItemId, searchQuery, groupBy, tagSearch])

  useEffect(() => {
    if (!groupBy || groupBy === 'none') {
      setGroupedDialogs(null)
    } else {
      if (activeItemId === 1) {
        const filtered = searchedDialogs?.filter((dialog: any) => dialog?.sent === true)
        const grouped = groupDialogs(filtered)
        setGroupedDialogs(grouped)
      } else if (activeItemId === 2) {
        const filtered = searchedDialogs?.filter((dialog: any) => dialog?.replied === true)
        const grouped = groupDialogs(filtered)
        setGroupedDialogs(grouped)
      } else {
        const grouped = groupDialogs(searchedDialogs)
        setGroupedDialogs(grouped)
      }
    }
  }, [groupBy, searchedDialogs, searchQuery, activeItemId])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const pinned = searchedDialogs.filter((dialog: any) => dialog.pin)
    const unpinned = searchedDialogs.filter((dialog: any) => !dialog.pin)
    setPinnedDialogs(pinned)
    setUnpinnedDialogs(unpinned)
    // console.log('Key Changed')
    if (listRef.current) {
      listRef.current.scrollToPosition(scrollPosition)
    }

    setListKey((prev) => prev + 1)
  }, [searchedDialogs])

  // Filter data based on search query
  //   const filteredOptions = Options.filter((option) => option.name.toLowerCase().includes(searchQuery.toLowerCase()))
  const placeholderString =
    groupBy === 'campaign_name' ? 'Campaign Name' : groupBy === 'company' ? 'Company Name' : 'Name'

  const [searchTag] = useLazySearchTagQuery()
  const debounedSearchTag = useDebounce(searchQuery, 300)

  const fetSearchResult = async () => {
    setLoading(true)
    try {
      const res = await searchTag(debounedSearchTag)
      if (res) {
        setTagSearchResult(res.data.response)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const handleRemoveTagSearch = (tag: string) => {
    const currentIndexOfTag = tagSearch.findIndex((t: string) => tag === t)

    setTagSearch((pre: any) => {
      return [...pre.slice(0, currentIndexOfTag), ...pre.slice(currentIndexOfTag + 1)]
    })
  }

  React.useEffect(() => {
    if (debounedSearchTag) {
      fetSearchResult()
    }
  }, [debounedSearchTag])

  React.useEffect(() => {
    if (!searchQuery) setTagSearchResult([])
  }, [searchQuery])

  return (
    <>
      <div className={styles.search}>
        <div className={styles['search__input']}>
          <Input
            size='large'
            placeholder={`Search by ${placeholderString}, tags, company`}
            prefix={<SearchOutlined />}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className={styles['search__input__result__list']}>
            {tagSearch.map((tag: string, index: number) => (
              <TagItem
                title={tag}
                key={`${tag} ${index}`}
                onClose={() => {
                  handleRemoveTagSearch(tag)
                }}
              />
            ))}
          </div>

          {searchQuery && (
            <div ref={searchResultRef}>
              {loading && (
                <Spin
                  style={{ position: 'absolute', top: '12px', right: '12px', zIndex: 10000 }}
                  indicator={<LoadingOutlined style={{ fontSize: 16, marginBottom: '16px' }} spin />}
                />
              )}
              {isArray(tagSearchResult) && tagSearchResult.length > 0 && (
                <div className={styles['search__input__result']}>
                  <Typography className={styles['search__input__result__title']}>Tags</Typography>
                  <div className={styles['search__input__result__list']}>
                    {tagSearchResult.map((tag) => (
                      <div
                        key={tag.id}
                        onClick={() => {
                          const isExistTagSearch = tagSearch.some((t: string) => tag.tag_name === t)
                          if (isExistTagSearch) return
                          setTagSearch((pre: any) => [...pre, tag.tag_name])
                          setSearchQuery('')
                        }}>
                        <TagItem title={tag?.tag_name} showClose={false} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={`${styles['search_select']} search_select`}>
          <span className={styles.prefix_text}>Group By</span>
          <Select defaultValue='none' onChange={handleGroupByChange} style={{ width: '105px', padding: '3px 0px' }}>
            <Select.Option value='none'>None</Select.Option>
            {filter !== 'groups' && <Select.Option value='campaign_name'>Campaign</Select.Option>}
            {filter !== 'groups' && <Select.Option value='company'>Company</Select.Option>}
            <Select.Option value='priority'>Priority</Select.Option>
            <Select.Option value='meeting_book'>Status</Select.Option>
            <Select.Option value='tags'>Tags</Select.Option>
          </Select>
        </div>
      </div>

      <div className={`${styles['optionsTiles']} optionsTiles`}>
        {filter !== 'groups' &&
          Options.map((item) => (
            <div
              key={item.id}
              className={
                activeItemId === item.id
                  ? `${styles['optionsTiles__Content']} optionsTiles__Content activeOptionsTiles`
                  : `${styles['optionsTiles__Content']} optionsTiles__Content`
              }
              onClick={() => handleOptionClick(item.name, item.id)}>
              <p>{item.name}</p>
              <h1>{item.label}</h1>
            </div>
          ))}
      </div>
      <div style={{ flex: '1 1 auto', minHeight: `calc(100vh - 330px + ${filter === 'groups' ? 70 : 0}px)` }}>
        <AutoSizer>
          {({ width, height }) => {
            let allDialogs = [...pinnedDialogs, ...unpinnedDialogs]
            if (filter !== 'groups') {
              allDialogs = allDialogs.filter((dialog: any) => dialog?.dialog_type === 'user')
            }
            const count = groupedDialogs ? 1 : allDialogs.length
            if (groupedDialogs) {
              const sortedGroups = Object.entries(groupedDialogs).sort((a, b) => {
                if (a[0] === 'pinnedArr') return -1
                if (b[0] === 'pinnedArr') return 1

                if (a[0] === 'Other') return 1
                if (b[0] === 'Other') return -1
                return a[0].localeCompare(b[0])
              })

              return (
                <Collapse
                  bordered={false}
                  style={{ width: width + 15, overflowY: 'scroll', height: height + 35 }}
                  accordion
                  className='groupedDialogs'
                  expandIconPosition='right'
                  defaultActiveKey={['0']}>
                  {sortedGroups.map(([group, dialogs]: any, index) => {
                    const header =
                      group === 'pinnedArr' ? (
                        <div className={styles.pinedChats} style={{ margin: 0 }}>
                          <span className={styles.chat_box_title}>Pin</span>
                          <img src={pin_large_icon} alt='' />
                          <span className={styles.chat_box_title}>({dialogs?.length})</span>
                        </div>
                      ) : (
                        <span style={{ fontWeight: 600, fontSize: '16px' }}>
                          {group} ({dialogs?.length})
                        </span>
                      )
                    return (
                      <Panel header={header} key={index} style={{ minWidth: '490px' }}>
                        <List
                          ref={listRefGroup}
                          onScroll={handleScrollGroup}
                          scrollTop={scrollPositionGroup}
                          key={listKey}
                          className={styles.reactVirtualizer}
                          width={width + 7}
                          height={Math.min(540, ROW_HEIGHT * dialogs?.length)}
                          rowCount={dialogs?.length}
                          rowHeight={ROW_HEIGHT}
                          rowRenderer={({ index, key, style, parent }) => {
                            const item = dialogs[index]
                            let identifierKey = ''
                            switch (item.platform) {
                              case 'telegram':
                                identifierKey = 'receiver_number'
                                break
                              case 'instagram':
                                identifierKey = 'instagram_id' // Change later
                                break
                              default:
                                identifierKey = 'receiver_number'
                            }
                            const senderData = item[identifierKey]
                            const tags = JSON.parse(item.tags || '[]')

                            return (
                              <div key={key} style={style}>
                                {/* {headerSelector(index, allDialogs)} */}
                                <ChatDialog
                                  item={item}
                                  recipientId={item.id}
                                  recipientName={item.title}
                                  senderData={senderData}
                                  platform={item.platform}
                                  timeAgo={item.sent_at}
                                  additionalTags={tags}
                                  latestMessage={item.latest_message}
                                  photo={item.photo}
                                  unread_count={item.unread_count}
                                  pin={item.pin}
                                  priority={item.priority}
                                  onClick={handleOnClick}
                                />
                              </div>
                            )
                          }}
                        />
                      </Panel>
                    )
                  })}
                </Collapse>
              )
              // for (const obj of groupedDialogs.)
              // console.log(groupedDialogs)
              // console.log(groupedDialogs)
              // return <div>gr</div>
            } else {
              return (
                <>
                  <List
                    ref={listRef}
                    onScroll={handleScroll}
                    scrollTop={scrollPosition}
                    key={listKey}
                    className={styles.reactVirtualizer}
                    width={width + 7}
                    height={height}
                    rowCount={count}
                    // rowHeight={}
                    rowHeight={({ index }) => {
                      if (groupedDialogs) {
                        return 1000
                      } else {
                        return includeHeader(index, allDialogs) ? ROW_HEIGHT_WITH_HEADER : ROW_HEIGHT
                      }
                    }}
                    rowRenderer={({ index, key, style, parent }) => {
                      const item = allDialogs[index]
                      let identifierKey = ''
                      switch (item.platform) {
                        case 'telegram':
                          identifierKey = 'receiver_number'
                          break
                        case 'instagram':
                          identifierKey = 'instagram_id' // Change later
                          break
                        default:
                          identifierKey = 'receiver_number'
                      }
                      const senderData = item[identifierKey]
                      const tags = JSON.parse(item.tags || '[]')

                      return (
                        <div key={key} style={style}>
                          {headerSelector(index, allDialogs)}
                          <ChatDialog
                            item={item}
                            recipientId={item.id}
                            recipientName={item.title}
                            senderData={senderData}
                            platform={item.platform}
                            timeAgo={item.sent_at}
                            additionalTags={tags}
                            latestMessage={item.latest_message}
                            photo={item.photo}
                            unread_count={item.unread_count}
                            pin={item.pin}
                            priority={item.priority}
                            onClick={handleOnClick}
                          />
                        </div>
                      )
                    }}
                  />
                </>
              )
            }
          }}
        </AutoSizer>
      </div>
      {/* {searchedDialogs?.map((item: any) => {
        let identifierKey = ''
        switch (item.platform) {
          case 'telegram':
            identifierKey = 'receiver_number'
            break
          case 'instagram':
            identifierKey = 'instagram_id' // Change later
            break
          default:
            identifierKey = 'receiver_number'
        }
        const senderData = item[identifierKey]
        return (
          <ChatDialog
            recipientId={item.id}
            recipientName={item.title}
            senderData={senderData}
            platform={item.platform}
            timeAgo={item.sent_at}
            status={'High'}
            additionalTags={['Name']}
            items={items}
            latestMessage={item.latest_message}
            photo={item.photo}
            unread_count={item.unread_count}
            onClick={handleOnClick}
          />
        )
      })} */}
    </>
  )
}

export default ChatTab
