import { Typography } from 'antd'
import styles from './SocialBox.module.scss'

import telegram_icon from 'common/assets/svg/telegram_icon.svg'
import linkedin_icon from 'common/assets/svg/linkedin_icon.svg'

interface SocialBoxProps {
  name?: string
  imageSocialKey: 'telegram' | 'linkedin'
  isCommingSoon?: boolean
  isAddAccount?: boolean
  accountCount?: number
  onAddAccount?: () => void
  onClickBox?: () => void
}

const SocialBox = (props: SocialBoxProps) => {
  const { name, imageSocialKey, isCommingSoon, accountCount, isAddAccount, onAddAccount, onClickBox } = props

  const imageMapping: Record<SocialBoxProps['imageSocialKey'], string> = {
    telegram: telegram_icon,
    linkedin: linkedin_icon,
  }

  const handleClickButton = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    onAddAccount && onAddAccount()
  }

  const handleClickBox = () => {
    onClickBox && onClickBox()
  }

  return (
    <div className={styles.socialBox} onClick={handleClickBox} style={isAddAccount ? {} : { cursor: 'pointer' }}>
      <img className={styles.socialBox__logo} src={imageMapping[imageSocialKey]} alt='' />

      <Typography className={styles.socialBox__name}>{name}</Typography>

      <div className={styles.socialBox__button} onClick={handleClickButton}>
        {isCommingSoon && <Typography className={styles.socialBox__button__commingSoon}>{'Coming Soon'}</Typography>}
        {isAddAccount && <Typography className={styles.socialBox__button__addAccount}>{'Add Account'}</Typography>}

        {!isCommingSoon && !isAddAccount && (
          <>
            <div className={styles.socialBox__button__dot} />
            <Typography className={styles.socialBox__button__text}>{accountCount} accounts</Typography>
          </>
        )}
      </div>
    </div>
  )
}

export default SocialBox
