import React, { useEffect, useState } from 'react'
import { Select, Form, Divider, Modal, Table, Input, Button } from 'antd'
// import { Mentions, Button, Select, Form } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

import { useAuth } from 'common/hooks/useAuth.hooks'
import send_message from 'common/assets/svg/send_message.svg'
import { useTelegram } from 'common/providers/TelegramProvider'
import { useLazyGetCreditsQuery } from 'features/settings/state/api/SettingsApi'
import {
  useLazyGetCustomMessagesQuery,
  useLazyGetMessageSuggestionsQuery,
  useLazyGetSenderQuery,
} from 'features/Outreach/state/api/OutreachApi'
import { post } from 'common/api/axios'
import BlurPaywall from '../../../../../../../features/intent-signals/components/paywalls/BlurPaywall'
import BlurPaywallFilters from '../../../../../../../features/intent-signals/components/paywalls/BlurPaywallFilters'

import { outreach } from 'common/constants/outreach.constants'

// import { SET_IS_OUTREACHED, SET_IS_SENT } from '../state/sendMessageSlice'

import styles from './NewCampaignTelegramMessage.module.scss'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_IS_OUTREACHED, SET_IS_SENT } from 'features/Outreach/state/sendMessageSlice'
import { SET_DELETE_CONTACTS_SELECTED_ROWS } from 'features/contacts/state/slice/contactsSlice'
import VariablesTags from './VariablesTags'
import OutreachConnection from 'common/components/OutreachConnection/OutreachConnection'
import {
  SET_SELECTED_SENDER,
  SET_SEQUENCE_DATA,
  SET_SENDERS_APPROVED,
  SET_OPEN_SENDER_MODAL,
  SET_IS_COMPARE_AB_TESTING,
} from 'common/components/OutreachCampaign/state/outreachCampaignSlice'
import MessageTemplateTabs from './MessageTemplateTabs'
import { useLocalStorage } from 'common/hooks/useLocalStorage.hooks'
import { useLazyUpdateOutreachDetailsQuery } from 'features/settings/state/api/SettingsApi'
import MessagesTemplates from 'common/components/OutreachAI/Builder/Tabs/MessagesTemplates'
import { TemplateSider } from 'common/components/OutreachAI/Builder/Tabs/TemplateSider'

const optionLabels = [
  // 'city',
  // 'email',
  // 'state',
  // 'country',
  'first_name',
  'full_name',
  // 'seniority',
  'job_title',
  'company_name',
  // 'job_function',
  'vertical',
  'sender_full_name',
  'sender_company',
  'sender_first_name',
  'custom_1',
  'custom_2',
]

const options = optionLabels.map((value) => ({
  value,
  label: value,
}))

interface NewCampaignTelegramMessageProps {
  contactsList?: any[]
  currentSideBar?: any
  nodesData?: any
  setNodesData?: any
}

const NewCampaignTelegramMessage: React.FC<NewCampaignTelegramMessageProps> = ({
  contactsList,
  currentSideBar,
  nodesData,
  setNodesData,
}) => {
  // const user = JSON.parse(localStorage.getItem('user') || '{}')

  const getTelegramTableData = (user: any) => {
    const OutreachAccounts =
      user?.outreach?.map((user: any) => ({
        id: user.id,
        phoneNumber: `${user.area_code}${user.number}`,
        handle: `@${user.userName ? user.userName : 'N/A'}`,
        number: user.number,
        area_code: user.area_code,
        managed: user?.isManaged,
        status: 'Connected',
        type: 'Outreach',
        name: user.sender_full_name,
        company: user.sender_company,
      })) || []

    const privateUser = user?.telegram && Object.keys(user.telegram).length > 0 ? user.telegram : null
    const telegramAccount = privateUser
      ? [
          {
            id: 1,
            phoneNumber: `${privateUser.area_code}${privateUser.number}`,
            number: privateUser.number,
            area_code: privateUser.area_code,
            handle: 'Personal',
            managed: false,
            status: 'Connected',
            type: 'Connect+',
            // name: privateUser.sender_name,
            // company: privateUser.sender_company,
          },
        ]
      : []

    return [...telegramAccount, ...OutreachAccounts]
  }
  const [warningFields, setWarningFields] = useState<any>('')
  const [isShowWarning, setIsShowWarning] = useState<any>()
  const [compareAbTesting, setCompareAbTesting] = useState<any>(false)

  const [user, setUser] = useLocalStorage('user', null)
  const [refetchUpdateOutreachDetails] = useLazyUpdateOutreachDetailsQuery()
  const [telegramTableData, setTelegramTableData] = useState<any>(getTelegramTableData(user))

  const [openContactSales, setOpenContactSales] = useState(false)
  const [openContactSalesMap, setOpenContactSalesMap] = useState(false)

  const { Option } = Select
  const dispatch = useAppDispatch()
  const [refetchGetCredits] = useLazyGetCreditsQuery()
  const [refetcGetSenderList, { data, isLoading }] = useLazyGetSenderQuery()
  // const [defaultSelect, setDefaultSelect] = useState<string | number>()
  const { selectedSender, edit_mode, enable_editing, open_sender_modal, template } = useAppSelector(
    (state) => state.outreachCampaign,
  )
  // const [openPhoneNumberTabel, setOpenPhoneNumberTabel] = useState(open_sender_modal)

  // const isNewCampaignWithAI = Object.keys(ai_generated_data)?.length === 0
  const { userDetails } = useAuth()
  const { sendMessages } = useTelegram()
  const [form] = Form.useForm()
  const [, setDisableButton] = useState(true)
  const [outreachConnectionModalOpen, setOutreachConnectionModalOpen] = useState(false)
  const [currentTab, setCurrentTab] = useState('A')
  const indexMap: { [key: string]: number } = { A: 0, B: 1, C: 2 }

  const [showSaveTemplate, setShowSaveTemplate] = useState(false)

  const [refetchGetMessageSuggestions, { data: messageSuggestions, isLoading: isFetchingMessageSuggestions }] =
    useLazyGetMessageSuggestionsQuery()
  const [refetchGetCustomMessages, { data: customMessages, isLoading: isFetchingCustomMessages }] =
    useLazyGetCustomMessagesQuery()

  // const [currentMessage, setCurrentMessage] = useState('')

  // const [currentMessage, setCurrentMessage] = useState('')
  //   const contactsNames = contactsList
  //     ?.filter(({ telegram_account }) => !!telegram_account)
  //     ?.map((obj: any) => obj.full_name)

  //   const formatContactList = (contacts: any) => {
  //     const firstThreeNames = contactsNames.slice(0, 4)
  //     const remainingCount = Math.max(0, contacts.length - 4)

  //     return (
  //       <>
  //         <span className={styles.textList}>{`To: ${firstThreeNames.join(', ')}`}</span>
  //         {remainingCount > 0 && <span className={styles.textPlus}>{` +${remainingCount}`}</span>}
  //       </>
  //     )
  //   }

  // const savedMessage = nodesData?.[currentSideBar?.nodeId]
  // const savedMessage = isNewCampaignWithAI
  //   ? nodesData?.steps?.[currentSideBar?.nodeId]?.data?.message
  //   : ai_generated_data[currentSideBar?.nodeId]?.message

  // const savedMessages = nodesData?.steps?.[currentSideBar?.nodeId]?.data?.message

  const savedMessages = ['', '', ''].map((label, index) =>
    typeof nodesData?.steps?.[currentSideBar?.nodeId]?.data?.message === 'string'
      ? [nodesData?.steps?.[currentSideBar?.nodeId]?.data?.message]?.[index]
      : nodesData?.steps?.[currentSideBar?.nodeId]?.data?.messages?.[index] || '',
  )
  // const [savedMessages, setSavedMessages] = useState<string[]>(initialSavedMessages)

  const updateNodesData = (newMessage: string, tabToRemove?: string) => {
    // Map tabKey to array index
    const index = indexMap[currentTab]
    const updatedMessages = [...savedMessages]
    if (tabToRemove) {
      const indexToRemove = indexMap[tabToRemove]
      updatedMessages.splice(indexToRemove, 1)
    } else {
      updatedMessages[index] = newMessage
    }

    // const filteredMessage = updatedMessages.filter((m: string) => m && m.trim() !== '')

    // Update the local state with the new messages array
    // setSavedMessages(updatedMessages)
    // setSavedMessages(filteredMessage)

    setNodesData((prev: any) => {
      const updatedSteps = {
        ...prev.steps,
        [currentSideBar?.nodeId]: {
          type: 'send_telegram_message',
          // data: { sender: selectedSender, messages: updatedMessages },
          data: { sender: selectedSender, messages: updatedMessages },
        },
      }

      dispatch(SET_SEQUENCE_DATA({ ...prev, steps: updatedSteps }))
      return { ...prev, steps: updatedSteps }
    })
  }

  // const updateNodesData = (nodeId: any, message: any) => {
  //   setNodesData((prev: any) => {
  //     const updatedSteps = {
  //       ...prev.steps,
  //       [nodeId]: {
  //         type: 'send_telegram_message',
  //         data: { sender: selectedSender, message },
  //       },
  //     }
  //     dispatch(SET_SEQUENCE_DATA({ ...prev, steps: updatedSteps }))
  //     return { ...prev, steps: updatedSteps }
  //   })
  // }
  const labels = ['A', 'B', 'C']

  function compareWithSavedMessages(key: any, value: any) {
    const index = labels.indexOf(key)
    const array = []
    if (index !== -1) {
      for (let i = 0; i < savedMessages.length; i++) {
        if (i !== index && value === savedMessages[i] && value !== '') {
          array.push({ equal: true, key, equalTo: labels[i] })
        }
      }
      return array
    }
    return []
  }

  const compare = (key: any, newMessage: any) => {
    const newArray = compareWithSavedMessages(key, newMessage)
    setCompareAbTesting(newArray.length > 0 ? true : false)
    dispatch(SET_IS_COMPARE_AB_TESTING(newArray.length > 0 ? true : false))
  }

  const updateSender = (nodeId: any, value: any) => {
    setNodesData((prev: any) => {
      const existingData = prev.steps[nodeId]?.data || {}
      const updatedSteps = {
        ...prev.steps,
        [nodeId]: {
          type: 'send_telegram_message',
          data: {
            ...existingData,
            sender: value,
          },
        },
      }

      dispatch(SET_SEQUENCE_DATA({ ...prev, steps: updatedSteps }))

      return { ...prev, steps: updatedSteps }
    })

    setNodesData((prev: any) => {
      const updatedSteps = Object.keys(prev.steps).reduce((acc: any, nodeId) => {
        const node = prev.steps[nodeId]
        if (node.type === 'send_telegram_message') {
          acc[nodeId] = {
            ...node,
            data: {
              ...node.data,
              sender: value,
            },
          }
        } else {
          acc[nodeId] = node
        }
        return acc
      }, {})

      return {
        ...prev,
        steps: updatedSteps,
      }
    })
  }

  const handleReplaceComposer = (message: string) => {
    compare(currentTab, message)
    post('/log', {
      action: 'Message Suggestions',
      data: message,
    })
    if (!edit_mode || enable_editing) {
      form.setFieldsValue({ [`message${currentTab}`]: message })
      updateNodesData(message)
    }
  }

  const handleAddToComposer = (message: string) => {
    post('/log', {
      action: 'Select Variable Campaign',
      data: message,
    })

    // if (!user?.addons?.responses_tracking) {
    //   setOpenContactSalesMap(true)
    // } else {
    if (!edit_mode || enable_editing) {
      const currentMessage = form.getFieldValue(`message${currentTab}`) || savedMessages[indexMap[currentTab]]
      const cursorPosition = form.getFieldInstance(`message${currentTab}`)
      const position = cursorPosition.textarea.selectionStart

      const beforeCursor = currentMessage?.slice(0, position) || ''
      const afterCursor = currentMessage?.slice(position) || ''

      const updatedMessage = `${beforeCursor} ${message}${afterCursor}`?.trim()

      form.setFieldsValue({ [`message${currentTab}`]: updatedMessage })
      updateNodesData(updatedMessage)

      const newCursorPosition = beforeCursor.length + message.length + 1
      setTimeout(() => {
        cursorPosition.textarea.setSelectionRange(newCursorPosition, newCursorPosition)
        cursorPosition.textarea.focus()
      }, 0)
    }

    isOpenPhoneNumberTabel(message)
  }

  const isOpenPhoneNumberTabel = (message: any) => {
    if (message === '@sender_full_name' || message === '@sender_company') {
      const outreachData = telegramTableData.filter((item: any) => item.type === 'Outreach')
      const name = outreachData?.filter(
        (obj: any) => obj.name === '' || obj.name === null || obj.name === undefined,
      ).length
      const company = outreachData?.filter(
        (obj: any) => obj.company === '' || obj.company === null || obj.company === undefined,
      ).length
      if ((message === '@sender_full_name' && name > 0) || (message === '@sender_company' && company > 0))
        dispatch(SET_OPEN_SENDER_MODAL(true))
      // setOpenPhoneNumberTabel(true)
    }
  }

  function replacePlaceholders(message: string, contact: any): string {
    const regex = /@(\w+)/g
    return message.replace(regex, (match, placeholder) => {
      const option = options.find((opt) => opt.value === placeholder)
      if (option && contact.hasOwnProperty(placeholder)) {
        return contact[placeholder]
      } else if (placeholder === 'first_name') {
        return contact['full_name'].split(' ')[0]
      }
      return match
    })
  }

  const handleFormChange = () => {
    if (form.getFieldValue('messageA') !== undefined && form.getFieldValue('sender') !== undefined) {
      setDisableButton(form.getFieldValue('messageA')?.trim().length === 0)
    }
  }

  const onFinish = async () => {
    dispatch(SET_IS_OUTREACHED(contactsList))
    dispatch(SET_IS_SENT(true))
    dispatch(SET_DELETE_CONTACTS_SELECTED_ROWS(true))

    try {
      const values = await form.validateFields()
      const message = values.message
      const sender = values.sender

      const creditsResponse = await refetchGetCredits()

      if (!creditsResponse?.data?.response?.credits?.send_tg_message)
        // return popUpMessage.error(`You're out of message credits`)
        return

      const localUserDetails = localStorage.getItem('user') || userDetails
      const oUserDetails = JSON.parse(localUserDetails)

      const contactListToSend = contactsList
        ?.filter(({ telegram_account }) => !!telegram_account)
        ?.map((contact) => {
          const updatedMessage = replacePlaceholders(message, contact)
          return {
            ...oUserDetails?.outreach[sender - 1],
            message: updatedMessage,
            recipient: contact?.telegram_account,
            contact_id: contact?.index,
            full_name: contact?.full_name,
          }
        })

      post('/log', {
        action: 'Press Send Message Button',
      })
      await sendMessages(
        contactListToSend,
        `${userDetails?.outreach?.area_code}${userDetails?.outreach?.number}`,
        false,
      )
    } catch (errInfo) {
      console.error('Error:', errInfo)
    }
  }

  const renderOption = (item: any) => {
    const fullNumber = item?.area_code + item?.number
    if (!fullNumber) return null
    const user: any = userDetails?.outreach?.find((u: any) => fullNumber.includes(u.number))
    if (user?.userName?.length > 0) {
      return (
        // <Tooltip title={<span>{fullNumber}</span>} key={user.number}>
        //   <span style={{ cursor: 'pointer' }}>{user?.userName}</span>
        // </Tooltip>

        <span>
          {user?.userName} ({fullNumber}){/* {fullNumber} ({user?.userName}) */}
        </span>
      )
    } else {
      return <span key={fullNumber}>{fullNumber}</span>
    }
    // old:
    // return (
    //   <div style={{ display: 'flex', alignItems: 'center' }}>
    //     {/* <img
    //       src={item.img}
    //       alt={item.name}
    //       style={{ width: '24px', height: '24px', margin: '3px 10px 3px 0px', borderRadius: '15px' }}
    //     />
    //     {item.name != null ? item.name : item.area_code + item.number} */}
    //     {item.area_code + item.number}
    //   </div>
    // )
  }

  useEffect(() => {
    const getRefetcGetSenderList = async () => {
      await refetcGetSenderList()
      // setDefaultSelect(1)
      // form.setFieldsValue({ sender: defaultSelect })
      form.setFieldsValue({ sender: selectedSender })
    }
    getRefetcGetSenderList()
  }, [])

  useEffect(() => {
    form.resetFields()
  }, [currentSideBar?.nodeId])

  const handleOutreachEdit = async (key: React.Key, e: React.ChangeEvent<HTMLInputElement>, type: any) => {
    const value = e.target.value

    const userData = await refetchUpdateOutreachDetails({
      value,
      type,
      key,
    })

    setUser(userData?.data)
  }

  useEffect(() => {
    setTelegramTableData(getTelegramTableData(user))
  }, [user])

  useEffect(() => {
    const outreachData = telegramTableData.filter((item: any) => item.type === 'Outreach')
    const name = outreachData?.filter((obj: any) => obj.name === '' || obj.name === null).length
    const company = outreachData?.filter((obj: any) => obj.company === '' || obj.company === null).length

    const customFields = []
    if (name > 0) {
      customFields.push('Sender Full Name')
    }
    if (company > 0) {
      customFields.push('Sender Company')
    }
    const fields = customFields.join(', ')
    setWarningFields(fields)
    setIsShowWarning(name + company)

    dispatch(SET_SENDERS_APPROVED(Math.floor(Math.random() * 100) + 1))

    // const company = telegramTableData?.some((obj: any) => obj.includes('company'))
  }, [telegramTableData])

  useEffect(() => {
    setCurrentTab('A')
  }, [currentSideBar])

  useEffect(() => {
    const getMessageSuggestions = async () => {
      await refetchGetMessageSuggestions()
    }
    const getCustomMessage = async () => {
      await refetchGetCustomMessages()
    }
    getMessageSuggestions()
    getCustomMessage()
  }, [])

  const columns = [
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 200,
      render: (phoneNumber: any) => <span>{phoneNumber}</span>,
    },
    { title: 'Handle', dataIndex: 'handle', key: 'handle', width: 200, render: (handle: any) => <span>{handle}</span> },

    {
      title: 'Name (@sender_full_name)',
      dataIndex: 'name',
      key: 'name',
      width: 220,
      render: (name: any, data: any) => {
        if (data.type === 'Connect+') return <span>{``}</span>
        return (
          <Input
            defaultValue={name}
            onBlur={(e: any) => {
              // e.target.style.backgroundColor = '#e3e3e3'
              handleOutreachEdit(data.id, e, 'sender_full_name')
            }}
            style={{
              border: name === '' || name === null || name === ' ' || name === undefined ? '1px solid #da1818' : 'none',
              boxShadow: 'none',
              backgroundColor: '#e3e3e366',
            }}
            onFocus={(e: any) => (e.target.style.backgroundColor = 'whitesmoke')}
          />
        )
        // return <span>{name}</span>
      },
    },
    {
      title: 'Company (@sender_company)',
      dataIndex: 'company',
      key: 'company',
      width: 230,
      render: (company: any, data: any) => {
        if (data.type === 'Connect+') return <span>{``}</span>

        return (
          <Input
            defaultValue={company}
            onBlur={(e: any) => {
              handleOutreachEdit(data.id, e, 'sender_company')
            }}
            style={{
              border:
                company === '' || company === null || company === ' ' || company === undefined
                  ? '1px solid #da1818'
                  : 'none',

              boxShadow: 'none',
              backgroundColor: '#e3e3e366',
            }}
            onFocus={(e: any) => (e.target.style.backgroundColor = 'whitesmoke')}
          />
        )
        // return <span>{name}</span>
      },
    },
    {
      title: 'Managed',
      dataIndex: 'managed',
      key: 'managed',
      width: 100,
      // render: (_: any, data: any) => <Checkbox checked={data?.managed} />,
      render: (_: any, data: any) => {
        return data?.managed ? (
          <CheckCircleOutlined style={{ fontSize: '18px', color: '#7043ff' }} />
        ) : (
          <CloseCircleOutlined style={{ fontSize: '18px', color: '#ccc' }} />
        )
      },
    },
    { title: 'Status', dataIndex: 'status', key: 'status', width: 100, render: (status: any) => <span>{status}</span> },
    { title: 'Type', dataIndex: 'type', key: 'type', width: 100, render: (type: any) => <span>{type}</span> },
  ]
  return (
    <div className={styles.container} id={currentSideBar?.nodeId}>
      <div className={styles.header}>
        <img src={send_message} alt='' />
        <span>Edit Your {template === 3 ? 'LinkedIn' : 'Telegram'} Message</span>
      </div>
      <div className={styles.inputText}>
        <Form
          id={currentSideBar?.nodeId}
          key={currentSideBar?.nodeId}
          name='messageForm'
          // name={`messageForm_${currentSideBar?.nodeId}`}
          form={form}
          layout='horizontal'
          style={{ width: '100%' }}
          onFieldsChange={handleFormChange}
          // onValuesChange={handleFormValueChange}
          onFinish={onFinish}>
          <div className={styles.senderTitle}>
            <span style={{ fontWeight: 600 }}>Sender</span>
            {currentSideBar?.nodeId === '1' && (
              <span
                style={{
                  color: edit_mode && !enable_editing ? '#acacac' : '#7043ff',
                  cursor: edit_mode && !enable_editing ? '' : 'pointer',
                }}
                onClick={() => {
                  ;(!edit_mode || enable_editing) && setOutreachConnectionModalOpen(true)
                }}>
                + Add New Account
              </span>
            )}
          </div>
          <Form.Item
            name='sender'
            style={{ marginBottom: '10px' }}
            // initialValue={selectedSender}
            // id={currentSideBar?.nodeId}
            // key={`${currentSideBar?.nodeId}_1`}
          >
            {/* <div style={{ display: 'none' }}> {defaultSelect}</div> */}
            <Select
              style={{ width: '100%', padding: '10px 0px' }}
              mode='multiple'
              size='large'
              placeholder='Select an option'
              loading={isLoading}
              defaultValue={selectedSender}
              value={selectedSender}
              onChange={(value) => {
                // setDefaultSelect(value)
                updateSender(currentSideBar?.nodeId, value)
                dispatch(SET_SELECTED_SENDER(value))
              }}
              disabled={(edit_mode && !enable_editing) || currentSideBar?.nodeId !== '1'}>
              {data?.response.map(
                (item: any) =>
                  item.number &&
                  item.area_code && (
                    <Option key={item.id} value={item.id} disabled={item.cooldown}>
                      {renderOption(item)}
                    </Option>
                  ),
              )}
            </Select>
          </Form.Item>
          <div className='flex justify-content-between align-items-center'>
            <span>Message Content</span>
            <Button className={styles.saveBtn} onClick={() => setShowSaveTemplate(true)}>
              Save Template
            </Button>
          </div>

          <div style={{ position: 'relative' }}>
            {/* <Form.Item name='message' style={{ paddingTop: '10px' }} initialValue={savedMessage}>
              <Mentions
                className={styles.inputText}
                autoSize={{ minRows: 8, maxRows: 8 }}
                maxLength={5000}
                style={{
                  padding: '15px 0px 48px 15px',
                  backgroundColor: edit_mode && !enable_editing ? '#f5f5f5' : '',
                }}
                placeholder='You can use @ to ref user data here'
                defaultValue={savedMessage}
                options={options}
                onChange={(e) => updateNodesData(currentSideBar.nodeId, e)}
                disabled={edit_mode && !enable_editing}
              />
            </Form.Item> */}
            <MessageTemplateTabs
              options={options}
              updateNodesData={updateNodesData}
              status={nodesData?.status}
              savedMessages={savedMessages}
              setCurrentTab={setCurrentTab}
              edit_mode={edit_mode}
              enable_editing={enable_editing}
              isOpenPhoneNumberTabel={isOpenPhoneNumberTabel}
              compare={compare}
              isCompareAbTesting={compareAbTesting}
              form={form}
            />
            <Divider className={styles.divider} />
            <div className={styles.generateWithAI}>
              <VariablesTags
                onAddToComposer={handleAddToComposer}
                onReplaceMessage={handleReplaceComposer}
                currentMessage={savedMessages[indexMap[currentTab]]}
              />
            </div>
          </div>
        </Form>
      </div>
      {compareAbTesting && <div className={styles.compare_A_B_testing}>A/B testing messages can not be identical.</div>}

      {!edit_mode && (
        <span style={{ color: '#a2aab8', fontSize: '13px', lineHeight: 'normal', marginTop: '8px' }}>
          Expert Advice: A/B test your outreach messages to perfect outreach efforts and increase your reach!
        </span>
      )}
      <span className='mb-3 mt-4 font-bold' style={{ lineHeight: 'normal' }}>
        Templates
      </span>
      <MessagesTemplates
        setTextMessage={handleReplaceComposer}
        messageSuggestions={messageSuggestions}
        isFetchingMessageSuggestions={isFetchingMessageSuggestions}
        customMessages={customMessages}
        isFetchingCustomMessages={isFetchingCustomMessages}
        refetchGetCustomMessages={refetchGetCustomMessages}
      />

      {outreachConnectionModalOpen && (
        <OutreachConnection open={outreachConnectionModalOpen} onClose={() => setOutreachConnectionModalOpen(false)} />
      )}
      <Modal
        open={openContactSales}
        onCancel={() => setOpenContactSales(false)}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}>
        <div className='flex justify-content-center'>
          <BlurPaywall
            addon={'This'}
            component={[]}
            onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
            customClass='contactSalesNoMargin'
          />
        </div>
      </Modal>
      <Modal
        open={openContactSalesMap}
        onCancel={() => setOpenContactSalesMap(false)}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}
        width={600}
        bodyStyle={{
          height: 350,
        }}>
        <div className='flex justify-content-center'>
          <BlurPaywallFilters
            addon={'replies_tracking'}
            component={[]}
            onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
            customClass='contactSalesNoMargin'
            type=''
          />
        </div>
      </Modal>
      <Modal
        open={open_sender_modal}
        onCancel={
          () => dispatch(SET_OPEN_SENDER_MODAL(false))

          // setOpenPhoneNumberTabel(false)
        }
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}
        width={1300}
        bodyStyle={{
          height: 450,
        }}>
        <div style={{ marginTop: '30px' }}>
          <h3 style={{ marginLeft: '10px' }}>Please fill out the necessary data to use the campaign variables.</h3>
          <Table
            dataSource={telegramTableData.filter((item: any) => item.type === 'Outreach')}
            columns={columns}
            pagination={false}
            scroll={{ y: 300 }}
          />
          {isShowWarning > 0 && (
            <h4 style={{ marginLeft: '10px', color: '#da1818', position: 'absolute', bottom: '10px' }}>
              {` You are trying to use senders variables (${warningFields}) that are not set yet, please set them before you continue.`}
            </h4>
          )}
        </div>
      </Modal>
      <TemplateSider
        open={showSaveTemplate}
        onClose={() => setShowSaveTemplate(false)}
        message={form.getFieldValue(`message${currentTab}`) || savedMessages[indexMap[currentTab]]}
        refetchGetCustomMessages={refetchGetCustomMessages}
      />
    </div>
  )
}

export default NewCampaignTelegramMessage

// import React, { useEffect, useState } from 'react'
// import { Mentions, Button, Select, Form } from 'antd'

// import { useAuth } from 'common/hooks/useAuth.hooks'
// import send_message from 'common/assets/svg/send_message.svg'
// import { useTelegram } from 'common/providers/TelegramProvider'
// import { useLazyGetCreditsQuery } from 'features/settings/state/api/SettingsApi'
// import { useLazyGetSenderListQuery } from 'features/Outreach/state/api/OutreachApi'
// import { post } from 'common/api/axios'
// // import { SET_IS_OUTREACHED, SET_IS_SENT } from '../state/sendMessageSlice'

// import styles from './NewCampaignTelegramMessage.module.scss'
// import { useAppDispatch } from 'state'
// import { SET_IS_OUTREACHED, SET_IS_SENT } from 'features/Outreach/state/sendMessageSlice'
// import { SET_DELETE_CONTACTS_SELECTED_ROWS } from 'features/contacts/state/slice/contactsSlice'

// interface NewCampaignTelegramMessageProps {
//   contactsList: any[]
// }

// const NewCampaignTelegramMessage: React.FC<NewCampaignTelegramMessageProps> = ({ contactsList }) => {
//   const { Option } = Select

//   const [refetchGetCredits] = useLazyGetCreditsQuery()
//   const [refetcGetSenderList, { data, isLoading }] = useLazyGetSenderListQuery()
//   const [defaultSelect, setDefaultSelect] = useState<string | number>()
//   const { userDetails } = useAuth()
//   const { sendMessages } = useTelegram()
//   const [form] = Form.useForm()
//   const [disableButton, setDisableButton] = useState(true)
//   const contactsNames = contactsList
//     ?.filter(({ telegram_account }) => !!telegram_account)
//     ?.map((obj: any) => obj.full_name)

//   const formatContactList = (contacts: any) => {
//     const firstThreeNames = contactsNames.slice(0, 4)
//     const remainingCount = Math.max(0, contacts.length - 4)

//     return (
//       <>
//         <span className={styles.textList}>{`To: ${firstThreeNames.join(', ')}`}</span>
//         {remainingCount > 0 && <span className={styles.textPlus}>{` +${remainingCount}`}</span>}
//       </>
//     )
//   }

//   const optionLabels = [
//     'city',
//     'email',
//     'state',
//     'country',
//     'first_name',
//     'full_name',
//     'seniority',
//     'job_title',
//     'company_name',
//     'job_function',
//   ]

//   const options = optionLabels.map((value) => ({
//     value,
//     label: value,
//   }))

//   function replacePlaceholders(message: string, contact: any): string {
//     const regex = /@(\w+)/g
//     return message.replace(regex, (match, placeholder) => {
//       const option = options.find((opt) => opt.value === placeholder)
//       if (option && contact.hasOwnProperty(placeholder)) {
//         return contact[placeholder]
//       } else if (placeholder === 'first_name') {
//         return contact['full_name'].split(' ')[0]
//       }
//       return match
//     })
//   }

//   const handleFormChange = () => {
//     if (form.getFieldValue('message') !== undefined && form.getFieldValue('sender') !== undefined) {
//       setDisableButton(form.getFieldValue('message')?.trim().length === 0)
//     }
//   }
//   const dispatch = useAppDispatch()

//   const onFinish = async () => {
//     dispatch(SET_IS_OUTREACHED(contactsList))
//     dispatch(SET_IS_SENT(true))
//     dispatch(SET_DELETE_CONTACTS_SELECTED_ROWS(true))

//     try {
//       const values = await form.validateFields()
//       const message = values.message
//       const sender = values.sender

//       const creditsResponse = await refetchGetCredits()

//       if (!creditsResponse?.data?.response?.credits?.send_tg_message)
//         // return popUpMessage.error(`You're out of message credits`)
//         return

//       const localUserDetails = localStorage.getItem('user') || userDetails
//       const oUserDetails = JSON.parse(localUserDetails)

//       const contactListToSend = contactsList
//         ?.filter(({ telegram_account }) => !!telegram_account)
//         ?.map((contact) => {
//           const updatedMessage = replacePlaceholders(message, contact)
//           return {
//             ...oUserDetails?.outreach[sender - 1],
//             message: updatedMessage,
//             recipient: contact?.telegram_account,
//             contact_id: contact?.index,
//             full_name: contact?.full_name,
//           }
//         })

//       post('/log', {
//         action: 'Press Send Message Button',
//       })
//       await sendMessages(
//         contactListToSend,
//         `${userDetails?.outreach?.area_code}${userDetails?.outreach?.number}`,
//         false,
//       )
//     } catch (errInfo) {
//       console.error('Error:', errInfo)
//     }
//   }

//   const renderOption = (item: any) => (
//     <div style={{ display: 'flex', alignItems: 'center' }}>
//       {/* <img
//           src={item.img}
//           alt={item.name}
//           style={{ width: '24px', height: '24px', margin: '3px 10px 3px 0px', borderRadius: '15px' }}
//         />
//         {item.name != null ? item.name : item.area_code + item.number} */}
//       {item.area_code + item.number}
//     </div>
//   )

//   useEffect(() => {
//     const getRefetcGetSenderList = async () => {
//       const senderList = await refetcGetSenderList()
//       for (let [, item] of senderList?.data?.response?.entries()) {
//         if (item?.cooldown === 0 || item?.cooldown === false) {
//           setDefaultSelect(item?.id)
//           form.setFieldsValue({ sender: item?.id })
//           return
//         }
//       }
//     }
//     getRefetcGetSenderList()
//   }, [])

//   return (
//     <div className={styles.container}>
//       <div className={styles.header}>
//         <img src={send_message} alt='' />
//         <span>{'Send Telegram Message'}</span>
//       </div>
//       <div className={styles.textField}>
//         <div
//           className={styles.inputText}
//           style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
//           <Form
//             id='messageForm'
//             name='messageForm'
//             form={form}
//             layout='horizontal'
//             style={{ width: '100%' }}
//             onFieldsChange={handleFormChange}
//             onFinish={onFinish}>
//             <div>Sender:</div>
//             <Form.Item name='sender'>
//               <div style={{ display: 'none' }}> {defaultSelect}</div>
//               <Select
//                 style={{ width: '100%' }}
//                 placeholder='Select an option'
//                 loading={isLoading}
//                 defaultValue={defaultSelect}
//                 value={defaultSelect}
//                 onChange={(value) => {
//                   setDefaultSelect(value)
//                 }}>
//                 {data?.response.map((item: any) => (
//                   <Option key={item.id} value={item.id} disabled={item.cooldown}>
//                     {renderOption(item)}
//                   </Option>
//                 ))}
//               </Select>
//             </Form.Item>
//             <div>Message Content:</div>
//             <Form.Item name='message'>
//               <Mentions
//                 className={styles.inputText}
//                 autoSize={{ minRows: 5, maxRows: 5 }}
//                 maxLength={5000}
//                 style={{ padding: '15px 0px 0px 15px' }}
//                 placeholder='You can use @ to ref user data here'
//                 options={options}
//               />
//             </Form.Item>
//           </Form>
//         </div>
//       </div>
//       <div className={styles.footer}>
//         <div>{formatContactList(contactsNames)}</div>
//         <Button
//           className={styles.Button}
//           onClick={onFinish} // Adjust the onClick event handler
//           disabled={disableButton}>
//           {'Send'}
//         </Button>
//       </div>
//     </div>
//   )
// }

// export default NewCampaignTelegramMessage
