import React from 'react'
import { Breadcrumb, Button, Typography } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_ACTIVE_STEP, SET_CSV_DATA } from '../../state/importContactSlice'
import CollapseStep from './CollapseStep'
import { CsvPreview } from './CsvPreview'
import styles from './ImportCustomList.module.scss'
import { TemplateGuildLine } from './TemplateGuildline'
import { EnrichCost } from '../EnrichModal/EnrichCost'
import { IMPORT_TYPE_NUMBER } from './ImportCustomList.type'

const TOTAL_STEP = {
  COMPANY: 1,
  CONTACT: 2,
}

export const ImportCustomListMyContact = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParam] = useSearchParams()
  const importTypeNumber = searchParam.get('type') || 0

  const { activeStep, csvData } = useAppSelector((state) => state.importContact)

  const isCompanyImport = +importTypeNumber === IMPORT_TYPE_NUMBER.COMPANY

  const totalStep = importTypeNumber ? (isCompanyImport ? TOTAL_STEP.COMPANY : TOTAL_STEP.CONTACT) : 0

  const handleBackToMyContact = () => {
    navigate('/exposedContacts')
    dispatch(
      SET_CSV_DATA({
        file: null,
        fieldArray: [],
        rowData: null,
        totalRowData: [],
        invalidRowData: [],
      }),
    )
    dispatch(SET_ACTIVE_STEP(1))
  }

  const handleChangeStep = (step: string) => dispatch(SET_ACTIVE_STEP(step))
  return (
    <div>
      <div className={styles['import-page__header']}>
        <div className={styles['import-page__header__left']}>
          <Breadcrumb
            items={[
              {
                title: 'My Contacts',
                // onClick: () => setCurrentDetailSocial(''),
              },
              {
                title: <span>Import Custom Leads</span>,
              },
            ]}
          />

          <Typography className={styles['import-page__header__left__pageName']}>Import Custom Leads</Typography>
        </div>

        <div className={styles['import-page__header__right']}>
          <Typography>
            Step {activeStep}/{totalStep}
          </Typography>
          <Button onClick={handleBackToMyContact}>Exit</Button>
        </div>
      </div>

      <div className={styles['import-page__body']}>
        <div className={styles['import-page__body__left']}>
          <CollapseStep
            activeKey={activeStep}
            importTypeNumber={+importTypeNumber}
            isCompanyImport={isCompanyImport}
            onChangeActiveKey={handleChangeStep}
            onBackToExposedContact={handleBackToMyContact}
          />
        </div>
        <div className={styles['import-page__body__right']}>
          {+activeStep === 2 ? (
            <div style={{ height: '100%' }}>
              <EnrichCost />
            </div>
          ) : csvData.file ? (
            <CsvPreview csvData={csvData} activeStep={activeStep} />
          ) : (
            <TemplateGuildLine type={Number(importTypeNumber)} />
          )}
        </div>
      </div>
    </div>
  )
}
