import { Select, Tooltip } from 'antd'

import { countriesWithPhonePrefix } from 'common/static-data/countires'

import styles from './FlagInput.module.scss'

interface FlagInputProps {
  className?: any
  onChange: any
  prefix?: any
  selectStyle?: any
  labelCountryCode?: boolean
  defaultCountryCodeValue?: any
}

const FlagInput = ({
  selectStyle,
  className,
  onChange: handleChange,
  prefix,
  labelCountryCode,
  defaultCountryCodeValue,
}: FlagInputProps) => {
  const filterOption: any = (inputValue: any, option: any) => {
    if (!option) return false

    return (
      option.searchValue.toLowerCase().indexOf(inputValue.trim().toLowerCase()) >= 0 ||
      option.value.toLowerCase().indexOf(inputValue.trim().toLowerCase()) >= 0
    )
  }

  return (
    <Select
      className={className}
      showSearch={true}
      style={selectStyle}
      // defaultValue={'+972'}
      defaultValue={defaultCountryCodeValue}
      value={prefix}
      filterOption={filterOption}
      onChange={handleChange}
      onClick={(e) => e.stopPropagation()}
      options={countriesWithPhonePrefix?.map(({ countryCode, phonePrefix, label }): any => ({
        key: countryCode,
        label: (
          <Tooltip title={label}>
            <div className={styles.optionLabel}>
              <img
                className={styles.flag}
                alt={label}
                src={`https://convrt-media-store.s3.us-west-2.amazonaws.com/${countryCode?.toLowerCase()}.png`}
              />
              {labelCountryCode ? <span>{countryCode}</span> : <span>{`+${phonePrefix}`}</span>}
            </div>
          </Tooltip>
        ),
        value: labelCountryCode ? countryCode : `+${phonePrefix}-${countryCode}`,
        searchValue: label,
      }))}
    />
  )
}

export default FlagInput
