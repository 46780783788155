import React from 'react'
import styles from './MessagesTemplates.module.scss'
import large_lists from 'common/assets/svg/large_lists.svg'
import { Tabs, TabsProps } from 'antd'
import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import Message from './Message'

interface MessagesTemplatesProps {
  setTextMessage: any
  messageSuggestions: any
  isFetchingMessageSuggestions: boolean
  customMessages: any
  isFetchingCustomMessages: boolean
  refetchGetCustomMessages: any
}
const MessagesTemplates: React.FC<MessagesTemplatesProps> = ({
  setTextMessage,
  messageSuggestions,
  isFetchingMessageSuggestions,
  customMessages,
  isFetchingCustomMessages,
  refetchGetCustomMessages,
}) => {
  const suggestions = messageSuggestions?.response

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <span className={styles.tabHeader}>Convrt</span>,
      children: (
        <div className={styles.templateContainer}>
          {isFetchingMessageSuggestions ? (
            <div className={styles.loadingBox}>
              <LoadingBox />
            </div>
          ) : (
            suggestions?.map((suggestion: any) => (
              <Message
                messageId={suggestion.message_id}
                message={suggestion.message}
                messageType={suggestion.message_type}
                messageName={suggestion.message_name}
                updatedAt={suggestion.updated_at}
                responseRate={suggestion.response_rate}
                onClick={() => setTextMessage(suggestion.message)}
              />
            ))
          )}
        </div>
      ),
    },
    {
      key: '2',
      label: <span className={styles.tabHeader}>Custom</span>,
      children: (
        <div className={styles.templateContainer}>
          {isFetchingCustomMessages ? (
            <div className={styles.loadingBox}>
              <LoadingBox />
            </div>
          ) : customMessages && customMessages.length > 0 ? (
            customMessages?.map((item: any) => (
              <Message
                messageId={item.message_id}
                message={item.message}
                messageType={item.message_type}
                messageName={item.message_name}
                updatedAt={item.updated_at}
                responseRate={item.response_rate}
                onClick={() => setTextMessage(item.message)}
                showTag={false}
                isCustom={true}
                refetchGetCustomMessages={refetchGetCustomMessages}
              />
            ))
          ) : (
            <div className={styles.emptyMessage}>
              <img src={large_lists} alt='' />
              <p>Custom Templates</p>
              <span>
                To create a custom template, simply enter your message in the message box and click 'Save Template' to
                use it in the future.
              </span>
            </div>
          )}
        </div>
      ),
    },
  ]

  const handleChangeTab = () => {}
  return (
    <div className={styles.container}>
      <Tabs className='builderSider' defaultActiveKey='1' centered items={items} onChange={handleChangeTab} />
    </div>
  )
}

export default MessagesTemplates
