import { useState } from 'react'
import { Input, Form } from 'antd'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

import styles from './OnBoardingPageD.module.scss'

interface OnBoardingPageDProps {
  setTelegramPassword: any
}

const OnBoardingPageD = ({ setTelegramPassword }: OnBoardingPageDProps) => {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleChange = (e: any) => {
    setTelegramPassword(e.target.value)
  }

  return (
    <div className={styles.container}>
      <div className={styles.centeredContent}>
        <p>{'Enter Your Telegram Password'}</p>
        <section>{'Your Telegram account is protected by 2-factor authentication'}</section>
        <div className={styles.codeInputs}>
          <Form.Item name='password' rules={[{ required: true, message: 'Password is required' }]}>
            <Input.Password
              className={styles['form-input']}
              onChange={handleChange}
              placeholder='Password'
              iconRender={(visible) =>
                visible ? (
                  <EyeOutlined onClick={togglePasswordVisibility} />
                ) : (
                  <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                )
              }
            />
          </Form.Item>
        </div>
      </div>
    </div>
  )
}

export default OnBoardingPageD
