import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IPagination {
  pageTasksTable: number
  numberOfElement: number
  numberOfElementOnPage: number
  numberOfElementPerPage: number
  totalPages: number
}

const initialState = {
  pageTasksTable: 0,
  numberOfElement: 0,
  numberOfElementOnPage: 0,
  numberOfElementPerPage: 10,
  totalPages: 0,
} as IPagination

const paginationSlice = createSlice({
  name: 'pagination',
  initialState: initialState,
  reducers: {
    HANDLE_CHANGE_NUMBER_PAGE(state, action: PayloadAction<number>) {
      state.pageTasksTable = action.payload
    },
    HANDLE_CHANGE_NUMBER_ELEMENT(state, action: PayloadAction<number>) {
      state.numberOfElement = action.payload
    },
    HANDLE_CHANGE_NUMBER_ELEMENT_PAGE(state, action: PayloadAction<number>) {
      state.numberOfElementOnPage = action.payload
    },
    HANDLE_CHANGE_NUMBER_ELEMENT_PER_PAGE(state, action: PayloadAction<number>) {
      state.numberOfElementPerPage = action.payload
    },
    HANDLE_CHANGE_TOTAL_PAGES(state, action: PayloadAction<number>) {
      state.totalPages = action.payload
    },
    RESET_CURENT_PAGE(state) {
      state.pageTasksTable = initialState.pageTasksTable
    },
  },
})

export const {
  HANDLE_CHANGE_NUMBER_ELEMENT_PER_PAGE,
  HANDLE_CHANGE_NUMBER_ELEMENT_PAGE,
  HANDLE_CHANGE_NUMBER_ELEMENT,
  HANDLE_CHANGE_NUMBER_PAGE,
  HANDLE_CHANGE_TOTAL_PAGES,
  RESET_CURENT_PAGE,
} = paginationSlice.actions

export const paginationReducer = paginationSlice.reducer
