import React from 'react'
import { Modal, Button, Checkbox, Col, Row, Switch } from 'antd'
import styles from './ImportTelegramContactsModal.module.scss'
import Search from 'antd/es/transfer/search'

interface ImportTelegramContactsModalProps {
  open: boolean
  onClose: () => void

  onFinish: () => void
}
const DUMMY_LISTS = [
  { id: 1, name: '@Johnnyblock', fullName: 'Johnny Block', bio: 'Developer | Kanz', connection_level: '1st' },
  { id: 2, name: '@Johnnyblock', fullName: 'Johnny Block', bio: 'Developer | Kanz', connection_level: '1st' },
  { id: 3, name: '@Johnnyblock', fullName: 'Johnny Block', bio: 'Developer | Kanz', connection_level: '1st' },
  { id: 4, name: '@Johnnyblock', fullName: 'Johnny Block', bio: 'Developer | Kanz', connection_level: '1st' },
  { id: 5, name: '@Johnnyblock', fullName: 'Johnny Block', bio: 'Developer | Kanz', connection_level: '1st' },
  { id: 6, name: '@Johnnyblock', fullName: 'Johnny Block', bio: 'Developer | Kanz', connection_level: '1st' },
  { id: 7, name: '@Johnnyblock', fullName: 'Johnny Block', bio: 'Developer | Kanz', connection_level: '1st' },
  { id: 8, name: '@Johnnyblock', fullName: 'Johnny Block', bio: 'Developer | Kanz', connection_level: '1st' },
  { id: 9, name: '@Johnnyblock', fullName: 'Johnny Block', bio: 'Developer | Kanz', connection_level: '1st' },
  { id: 10, name: '@Johnnyblock', fullName: 'Johnny Block', bio: 'Developer | Kanz', connection_level: '1st' },
  { id: 11, name: '@Johnnyblock', fullName: 'Johnny Block', bio: 'Developer | Kanz', connection_level: '1st' },
  { id: 12, name: '@Johnnyblock', fullName: 'Johnny Block', bio: 'Developer | Kanz', connection_level: '1st' },
  { id: 13, name: '@Johnnyblock', fullName: 'Johnny Block', bio: 'Developer | Kanz', connection_level: '1st' },
  { id: 14, name: '@Johnnyblock', fullName: 'Johnny Block', bio: 'Developer | Kanz', connection_level: '1st' },
  { id: 15, name: '@Johnnyblock', fullName: 'Johnny Block', bio: 'Developer | Kanz', connection_level: '1st' },
  { id: 16, name: '@Johnnyblock', fullName: 'Johnny Block', bio: 'Developer | Kanz', connection_level: '1st' },
]

const ImportTelegramContactsModal: React.FC<ImportTelegramContactsModalProps> = ({ open, onClose, onFinish }) => {
  return (
    <Modal
      title={<span>Import Telegram Contacts</span>}
      centered
      open={open} // Correct property name
      onCancel={onClose}
      footer={null}
      style={{ minWidth: '1000px' }}
      width={'fit-content'}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Row justify='space-between' align='middle'>
            <Col>
              <span style={{ marginRight: '5px' }}>Show:</span>
              <span style={{ color: '#7043ff' }}>1st, 2nd, 3rd Connections</span>
            </Col>
            <Col>
              <Search placeholder='Search Contacts' />
            </Col>
          </Row>
        </div>

        <div className={styles.listsHeader}>
          <Row gutter={16}>
            <Col span={6}>Username</Col>
            <Col span={6}>Full Name</Col>
            <Col span={6}>Bio</Col>
            <Col span={6} style={{ textAlign: 'center' }}>
              Connection Level
            </Col>
          </Row>
        </div>

        <div className={styles.listsContainer}>
          {DUMMY_LISTS.map((list) => (
            <Row key={list.id} className={styles.list} gutter={16} align='middle'>
              <Col span={6}>
                <div className={styles.checkbox}>
                  <Checkbox />
                  <p>{list.name}</p>
                </div>
              </Col>
              <Col span={6}>{list.fullName}</Col>
              <Col span={6}>{list.bio}</Col>
              <Col span={6} style={{ textAlign: 'center', fontWeight: 600 }}>
                {list.connection_level}
              </Col>
            </Row>
          ))}
        </div>

        <Row
          justify='space-between'
          style={{ alignItems: 'center', padding: '15px 0px 5px 0px', borderTop: '1px solid #ebecf0' }}>
          <Col>
            <div className={styles.enrich}>
              <Switch />
              <span>Enrich From Convrt</span>
            </div>
          </Col>
          <Col>
            <div className={styles.button}>
              <Button onClick={onFinish}>Import Contacts</Button>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default ImportTelegramContactsModal
