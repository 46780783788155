import React from 'react'
import { List, Skeleton } from 'antd'
import './LoadingBoxWithText.scss'

interface LoadingSkeletonProps {
  listStyle?: any
  listItemStyle?: any
  skeletonStyle?: any
  avatar?: boolean
}

const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({
  listStyle = { marginTop: '60px' },
  listItemStyle = { padding: '12px 0px 12px 60px' },
  skeletonStyle = { paddingTop: '0px', display: 'flex', alignItems: 'center', opacity: 0.5 },
  avatar = true,
}) => {
  const length = window.location.pathname?.includes('/outreach') ? 7 : 10
  const listData = Array.from({ length }).map((_, i) => ({
    title: '',
  }))

  return (
    <List
      itemLayout='vertical'
      size='large'
      dataSource={listData}
      style={listStyle}
      renderItem={(item) => (
        <List.Item key={item.title} style={listItemStyle}>
          <Skeleton active avatar={avatar} title={false} paragraph={{ rows: 1, width: '100%' }} style={skeletonStyle} />
        </List.Item>
      )}
    />
  )
}

export default LoadingSkeleton

// const [listData, setListData] = useState<Array<{ title: string }>>([])

// useEffect(() => {
//   const fancyTableElement = document.querySelector('[class^="ant-table-wrapper"]')
//   const determineListLength = () => {
//     if (fancyTableElement) {
//       const tableHeight = fancyTableElement.clientHeight
//       let newListDataLength = 10

//       if (tableHeight < 500) {
//         newListDataLength = 10
//       } else if (tableHeight < 630) {
//         newListDataLength = 10
//       }

//       const newListData = Array.from({ length: newListDataLength }).map((_, i) => ({
//         title: '',
//       }))

//       setListData(newListData)
//     }
//   }

//   determineListLength()
//   window.addEventListener('resize', determineListLength)

//   return () => {
//     window.removeEventListener('resize', determineListLength)
//   }
// }, [])
