export interface InitialStep {
  step_id: string
  previous_step_id: string | null
  type: string
  data?: {
    units?: string
    duration?: number
    messages?: string[]
    sender?: string[]
  }
}

export interface Condition {
  type: string
  minutes: number
  next_step_id: number
}

export interface NewStep {
  step_id: number
  previous_step_id: number | null
  type: string
  conditions_to_next_step: Condition[]
  message_id?: string
  data?: any
}

// Helper function for mapping old types to new format based on the platform
const mapTypeToNewFormat = (type: string, platform: string): string => {
  const typeMapping: { [platform: string]: { [type: string]: any } } = {
    linkedin: {
      follow_lead: 'linkedin_follow',
      linkedin_connect: 'linkedin_connect',
      unfollow_lead: 'linkedin_unfollow',
      is_wait: 'linkedin_wait_after',
      is_linkedin_connected: {
        '1': 'linkedin_connected_after',
        '2': 'linkedin_not_connected_after',
      },
      linkedin_like_post: 'linkedin_like_post',
      send_linkedin_message: 'linkedin_send_message',
      is_message_replied: 'linkedin_not_reply_after',
      comment_on_post: 'linkedin_send_comment',
      comment_on_story: 'linkedin_send_comment',
      comment_on_photo: 'linkedin_send_comment',
    },
  }
  return typeMapping?.[platform]?.[type] || type
}

const stepOrCondition = (type: string) => {
  const typeMapping: any = {
    comment_on_photo: 'step',
    comment_on_story: 'step',
    comment_on_post: 'step',
    unfollow_lead: 'step',
    send_linkedin_message: 'step',
    follow_lead: 'step',
    linkedin_connect: 'step',
    linkedin_like_post: 'step',
    is_message_replied: 'condition',
    is_linkedin_connected: 'condition_split',
    is_wait: 'condition',
  }
  return typeMapping[type] || type
}

// Helper function to calculate minutes from the "data" property (1 day = 1440 minutes, ...)
const calculateMinutes = (units: string, duration: number): number => {
  if (units === 'hour' || units === 'hours') {
    return duration * 60
  } else if (units === 'day' || units === 'days') {
    return duration * 1440
  } else if (units === 'week' || units === 'weeks') {
    return duration * 1440 * 7
  } else {
    return duration
  }
}

const findChildren = (initialSteps: any, stepId: string) => {
  return initialSteps.filter((step: any) => step.previous_step_id === stepId)
}

const updateStepConditionsInPlace = (newSteps: any[], stepId: any, newConditions: any) => {
  const step = newSteps.find((s) => s.step_id === stepId)

  if (step) {
    step.conditions_to_next_step.push(newConditions)
  }
}

const transformData = (data: any, stepType: any, platform: string) => {
  // const units = data?.units
  const duration = data?.duration
  const typeMapping: { [platform: string]: { [type: string]: any } } = {
    instagram: {
      like_post: { ...data, max_posts: duration },
    },
    linkedin: {
      like_post: { ...data, max_posts: duration },
    },
  }
  return typeMapping?.[platform]?.[stepType] || data
}

// Main function
export const transformToNewStructure = (initialSteps: any[], platform: string): NewStep[] => {
  const newSteps: NewStep[] = []
  if (!platform) return []

  initialSteps.forEach((step: any) => {
    if (step.data && stepOrCondition(step?.type).includes('condition')) {
      const minutes = calculateMinutes(step.data.units, step.data.duration)
      const nextSteps = findChildren(initialSteps, step.step_id)
      const conditionType = mapTypeToNewFormat(step.type, platform?.toLocaleLowerCase())
      // console.log('====')
      // console.log(step)
      // console.log(step.previous_step_id)
      // console.log('====')
      for (const nextStep of nextSteps) {
        if (typeof conditionType === 'string') {
          const newCond = { type: conditionType, minutes: minutes, next_step_id: nextStep.step_id }
          updateStepConditionsInPlace(newSteps, step.previous_step_id, newCond)
        } else {
          const lastEl = nextStep.step_id.charAt(nextStep.step_id.length - 1)
          const newCond = { type: conditionType[lastEl], minutes: minutes, next_step_id: nextStep.step_id }

          updateStepConditionsInPlace(newSteps, step.previous_step_id, newCond)
        }
      }
    } else if (stepOrCondition(step?.type) === 'step') {
      const newStep: NewStep = {
        step_id: step.step_id,
        previous_step_id: step.previous_step_id || null,
        type: mapTypeToNewFormat(step.type, platform?.toLocaleLowerCase()),
        conditions_to_next_step: [],
        message_id: step.message_id,
        ...(step.data !== undefined && { data: transformData(step.data, step.type, platform?.toLocaleLowerCase()) }),
      }

      newSteps.push(newStep)
    }
  })

  return newSteps
}
