import React, { ImgHTMLAttributes, useState } from 'react'

interface Props extends ImgHTMLAttributes<any> {
  fallbackStrOrElement: string | any
}

export default function ImageWithFallback({ fallbackStrOrElement, src, ...props }: Props) {
  const [imgSrc, setImgSrc] = useState<string | undefined>(src)
  const onError = () => setImgSrc(fallbackStrOrElement)

  if (typeof fallbackStrOrElement === 'string') {
    return <img src={imgSrc ? imgSrc : fallbackStrOrElement} onError={onError} alt='' {...props} />
  } else {
    return fallbackStrOrElement
  }
}
