import React, { useEffect, useState } from 'react'
import { Empty, Space, Table, Tooltip } from 'antd'
import styles from './RecipientsTable.module.scss'
// import { CheckCircleOutlined } from '@ant-design/icons'
import success_green from 'common/assets/svg/success_green.svg'
import bounce_icon_recipients from 'common/assets/svg/bounce_icon_recipients.svg'
import blocked_recipient from 'common/assets/svg/blocked_recipients.svg'
import like_outcome from 'common/assets/svg/like_outcome.svg'
import dislike_outcome from 'common/assets/svg/dislike_outcome.svg'
import booked_outcome from 'common/assets/svg/booked_outcome.svg'
import out_of_office_outcome from 'common/assets/svg/out_of_office_outcome.svg'
import closed_outcome from 'common/assets/svg/closed_outcome.svg'
import not_now_outcome from 'common/assets/svg/not_now_outcome.svg'
import do_not_contact_outcome from 'common/assets/svg/do_not_contact_outcome.svg'
import type { MenuProps } from 'antd'
import { Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useLazyUpdateRecipientOutcomeQuery } from 'features/Outreach/state/api/OutreachApi'
import CustomLastStepTag from '../Campaigns/UI/CustomLastStepTag'
import UsernameFromNumber from '../Campaigns/UI/UsernameFromNumber'
import LoadingSkeleton from 'common/components/LoadingBox/LoadingSkeleton'
import dayjs from 'dayjs'

interface RecipientsTableProps {
  data: Array<{
    key: React.Key
    recipient: string
    name: string
    title: string
    company: string
    sent: boolean
    is_replied: boolean
    blocked: boolean
    lastStep: string
    campaign: string
    outcome: string
    sentFrom: string
    campaign_id: string
  }>
  isLoading?: any
}

const RecipientsTable: React.FC<RecipientsTableProps> = ({ data, isLoading }) => {
  const [refetchUpdateRecipientsOutcome] = useLazyUpdateRecipientOutcomeQuery()
  const [selectedOutcomes, setSelectedOutcomes] = useState<any>([])

  useEffect(() => {
    // const initialOutcomes: any = {}
    // data.forEach((item) => {
    //   initialOutcomes[item.recipient] = item.outcome
    // })
    // setSelectedOutcomes([initialOutcomes])
    const initialOutcomes: any = []
    data.forEach((item: any) => {
      initialOutcomes.push({
        recipient_id: item?.recipient_id,
        outcome: item?.outcome,
      })
    })
    setSelectedOutcomes(initialOutcomes)
  }, [data])

  const menuStyle: React.CSSProperties = {}
  const contentStyle: React.CSSProperties = {
    height: '260px',
    color: 'red',
  }

  const handleOutcomeChange = async (e: any, campaign_id: string, recipient_id: string) => {
    await refetchUpdateRecipientsOutcome({
      campaign_id: campaign_id,
      outcome: e.key,
      recipient_id: recipient_id,
    })
    const updatedOutcomes = selectedOutcomes.filter((item: any) => item.recipient_id !== recipient_id)
    const newOutcome = { recipient_id: recipient_id, outcome: e.key }
    setSelectedOutcomes([...updatedOutcomes, newOutcome])
    // setSelectedOutcomes((prev:any) => [...prev, ])
    // setSelectedOutcomes((prevStates: any) => ({
    //   ...prevStates,
    //   [outcome]: 'running',
    // }))
  }

  const outcomeTable: any = {
    interested: (
      <div className={styles.outcome}>
        <img src={like_outcome} alt='' />
        <span>Interested</span>
      </div>
    ),
    not_interested: (
      <div className={styles.outcome}>
        <img src={dislike_outcome} alt='' />
        <span>Not Interested</span>
      </div>
    ),
    booked: (
      <div className={styles.outcome}>
        <img src={booked_outcome} alt='' />
        <span>Meeting Booked</span>
      </div>
    ),
    out_of_office: (
      <div className={styles.outcome}>
        <img src={out_of_office_outcome} alt='' />
        <span>Out of Office</span>
      </div>
    ),
    closed: (
      <div className={styles.outcome}>
        <img src={closed_outcome} alt='' />
        <span>Closed</span>
      </div>
    ),
    not_now: (
      <div className={styles.outcome}>
        <img src={not_now_outcome} alt='' />
        <span>Not Now</span>
      </div>
    ),
    do_not_contact: (
      <div className={styles.outcome}>
        <img src={do_not_contact_outcome} alt='' />
        <span>Do Not Contact</span>
      </div>
    ),
  }

  const items: MenuProps['items'] = [
    {
      label: outcomeTable['interested'],
      key: 'interested',
    },
    {
      label: outcomeTable['not_interested'],
      key: 'not_interested',
    },
    {
      label: outcomeTable['booked'],
      key: 'booked',
    },
    {
      label: outcomeTable['out_of_office'],
      key: 'out_of_office',
    },
    {
      label: outcomeTable['closed'],
      key: 'closed',
    },
    {
      label: outcomeTable['not_now'],
      key: 'not_now',
    },
    {
      label: outcomeTable['do_not_contact'],
      key: 'do_not_contact',
    },
  ]

  const columns = [
    {
      title: 'Name',
      dataIndex: 'full_name',
      width: 145,
      key: 'full_name',
      sorter: (a: any, b: any) => (a.full_name || '').localeCompare(b.full_name || ''),
    },
    {
      title: 'Title',
      dataIndex: 'job_title',
      width: 150,
      key: 'job_title',
      sorter: (a: any, b: any) => (a.job_title || '').localeCompare(b.job_title || ''),
    },
    {
      title: 'Company',
      dataIndex: 'company',
      width: 140,
      key: 'company',
      sorter: (a: any, b: any) => (a.company || '').localeCompare(b.company || ''),
    },
    {
      title: 'Sent',
      dataIndex: 'sent',
      width: 82,
      key: 'sent',
      sorter: (a: any, b: any) => (a.sent === b.sent ? 0 : a.sent ? -1 : 1),
      render: (sent: boolean, data: any) => {
        // const sentIcon = data?.sent_time !== null && sent === false ? bounce_icon_recipients : success_green
        return (
          <div>
            {sent ? (
              data?.sent_time ? (
                <Tooltip
                  color='#51e2bb'
                  title={
                    <span style={{ fontWeight: 700 }}>{dayjs(data.sent_time).format('MMMM DD, YYYY HH:mm')}</span>
                  }>
                  <img style={{ height: '20px', cursor: 'pointer' }} src={success_green} alt='' />
                </Tooltip>
              ) : (
                <img style={{ height: '20px' }} src={success_green} alt='' />
              )
            ) : data?.sent_time ? (
              <Tooltip
                color='#ffcb44'
                title={<span style={{ fontWeight: 700 }}>Message Bounced - Will retry again the next day</span>}>
                <img style={{ height: '20px' }} src={bounce_icon_recipients} alt='' />
              </Tooltip>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Replied',
      dataIndex: 'is_replied',
      width: 82,
      key: 'is_replied',
      sorter: (a: any, b: any) => a.is_replied - b.is_replied,
      render: (is_replied: number) => (
        <div style={{ color: '#5E6C84' }}>
          <span>{is_replied ? <img style={{ height: '20px' }} src={success_green} alt='' /> : '-'}</span>
        </div>
      ),
    },
    {
      title: 'Blocked',
      dataIndex: 'blocked',
      width: 82,
      key: 'blocked',
      sorter: (a: any, b: any) => a.blocked - b.blocked,
      render: (blocked: number) => (
        <div style={{ color: '#5E6C84' }}>
          <span>{blocked ? <img style={{ height: '20px' }} src={blocked_recipient} alt='' /> : '-'}</span>
        </div>
      ),
    },
    {
      title: 'Last Step',
      dataIndex: 'last_step',
      width: 165,
      key: 'last_step',
      sorter: (a: any, b: any) => (a.last_step || '').localeCompare(b.last_step || ''),
      render: (last_step: any) => (last_step ? <CustomLastStepTag tag={last_step} /> : '-'),
    },
    {
      title: 'Campaign',
      dataIndex: 'campaign_name',
      width: 150,
      key: 'campaign_name',
      sorter: (a: any, b: any) => (a.campaign_name || '').localeCompare(b.campaign_name || ''),
    },
    {
      title: 'Outcome',
      dataIndex: 'outcome',
      width: 185,
      key: 'outcome',
      sorter: (a: any, b: any) => (a.outcome || '').localeCompare(b.outcome || ''),
      render: (outcome_: any, campaign: any) => {
        const campaign_id = campaign.campaign_id
        const recipient_id = campaign.recipient_id
        const newOutcome = selectedOutcomes.filter((item: any) => item.recipient_id === recipient_id)
        const outcome = newOutcome[0]?.outcome
        return (
          <div style={{ cursor: 'pointer' }}>
            <Dropdown
              menu={{
                items,
                selectable: true,
                onClick: (e) => handleOutcomeChange(e, campaign_id, recipient_id),
                // defaultSelectedKeys: ['3']
                defaultSelectedKeys: [outcome],
              }}
              trigger={['click']}
              dropdownRender={(menu) => (
                <div className={styles.daniel} style={contentStyle}>
                  {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
                </div>
              )}>
              <Space>
                <span style={{ fontWeight: 600 }}>{outcome ? outcomeTable[outcome] : 'Outcome'}</span>
                <DownOutlined />
              </Space>
            </Dropdown>
          </div>
        )
      },
    },
    {
      title: 'Sent From',
      dataIndex: 'sent_from',
      width: 115,
      key: 'sent_froms',
      sorter: (a: any, b: any) => (a.sent_from || '').localeCompare(b.sent_from || ''),
      // render: (sent_from: any) => <div>{sent_from !== 'undefined' ? sent_from : '-'}</div>,
      render: (sent_from: any) => (
        <div>
          {sent_from !== 'undefined' && sent_from !== null ? (
            sent_from.includes('@') ? (
              sent_from
            ) : (
              <UsernameFromNumber phoneNumbers={[sent_from]} />
            )
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      title: 'Handle',
      dataIndex: 'recipient',
      width: 125,
      key: 'recipient',
      sorter: (a: any, b: any) => (a.recipient || '').localeCompare(b.recipient || ''),
      render: (recipient: any) => {
        if (recipient?.includes('http://')) {
          const res = recipient?.split('/')?.pop()
          return (
            <Tooltip title={recipient}>
              <span style={{ cursor: 'pointer' }}>{res}</span>
            </Tooltip>
          )
        } else {
          return <span>{recipient}</span>
        }
      },
    },
  ]

  const [yAxisTableSize, setYAxisTableSize] = useState(window.innerHeight)

  // useEffect(() => {
  //   const updateYAxisTableSize = () => {
  //     const trialBannerElement = document.querySelector('[class^="TrialBanner"]')?.clientHeight || 0
  //     const headerElement =
  //       document.querySelector('[class^="OutreachMainPageHeader_outreachMainPageHeader"]')?.clientHeight || 0
  //     const outreachElement = document.querySelector('[class^="OutreachMainPage_header"]')?.clientHeight || 0
  //     const pageElement = 200

  //     let newSize = window.innerHeight - pageElement - trialBannerElement - headerElement - outreachElement

  //     if (newSize < 0.2 * window.innerHeight) {
  //       newSize = 0.2 * window.innerHeight
  //     }
  //     setYAxisTableSize(newSize)
  //     // document.documentElement.style.setProperty('--min-fancy-table-height', `${newSize}px`)
  //   }

  //   updateYAxisTableSize()

  //   window.addEventListener('resize', updateYAxisTableSize)

  //   return () => {
  //     window.removeEventListener('resize', updateYAxisTableSize)
  //   }
  // }, [window.innerHeight, window.innerWidth])

  useEffect(() => {
    // RESIZE TABLE
    const updateYAxisTableSize = () => {
      const cooldownBannerElement = document.querySelector('[class^="CooldownBanner"]')?.clientHeight || 0
      const scheduleBannerElement = document.querySelector('[class^="CampaignScheduleStatusBanner"]')?.clientHeight || 0
      const statusBannerElement = document.querySelector('[class^="StautsBanner"]')?.clientHeight || 0

      const filterElement = document.querySelector('[class^="CampaignFilters"]')?.clientHeight || 0
      let newSize =
        window.innerHeight -
        200 -
        170 -
        cooldownBannerElement -
        scheduleBannerElement -
        statusBannerElement -
        (filterElement + 10)
      if (newSize < 0.2 * window.innerHeight) {
        newSize = 0.2 * window.innerHeight
      }
      setYAxisTableSize(newSize)
    }
    updateYAxisTableSize()
    window.addEventListener('resize', updateYAxisTableSize)
    return () => {
      window.removeEventListener('resize', updateYAxisTableSize)
    }
  }, [window.innerHeight, window.innerWidth])

  return (
    <Table
      locale={{
        emptyText: isLoading ? (
          // <Skeleton active={true} paragraph={{ rows: 10 }} style={{ opacity: 0.5 }} />
          <LoadingSkeleton
            listStyle={{ margin: '-16px' }}
            listItemStyle={{ padding: '34px 0px 34px 0px' }}
            skeletonStyle={{
              paddingTop: '0px',
              paddingLeft: '10px',
              paddingRight: '10px',
              display: 'flex',
              alignItems: 'center',
              opacity: 0.5,
            }}
            avatar={false}
          />
        ) : (
          <Empty />
        ),
      }}
      scroll={{ y: yAxisTableSize }}
      tableLayout='fixed'
      dataSource={data}
      columns={columns}
      // className={'messagesCampaignTable'}
      pagination={{ pageSize: 6 }}
    />
  )
}

export default RecipientsTable
