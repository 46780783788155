import { useEffect } from 'react'
import { Button, Collapse, Skeleton } from 'antd'
import classnames from 'classnames'

// import { ReactComponent as Lock } from 'common/assets/svg/lock.svg'
import ShootingStars from 'common/assets/svg/ShootingStarsPurple.svg'
import x_circle from 'common/assets/svg/x_circle.svg'
import tick_circle from 'common/assets/svg/tick_circle.svg'
import { useLazyGetPremiumPlansQuery } from 'common/api/UserApi'
import { useNavigate } from 'react-router-dom'

import './BlurPaywallFilters.scss'
import { post } from 'common/api/axios'

interface IBlurPaywall {
  addon?: any
  component: any
  onClick?: any
  customClass?: string
  type?: string
}
// interface AddonImages {
//   free: string
//   basic: string
//   premium: string
//   advanced: string
// }

const BlurPaywallFilters = ({
  addon,
  onClick: handleClick,
  component: Component,
  customClass = '',
  type = 'filter',
}: IBlurPaywall) => {
  const { Panel } = Collapse
  // const [openPopover, setOpenPopover] = useState(false)

  const [refetcGetPremiumPlans, { data: premiumPlansData, isLoading }] = useLazyGetPremiumPlansQuery()
  const navigate = useNavigate()

  useEffect(() => {
    const addonMap: any = {
      techstacktools: 'techStack',
      web2metrics: 'web2metrics',
      revenue: 'revenue_filter',
      funding: 'funding_filter',
      intents: 'intentsSignals',
      milestones: 'milestones',
      Interests: 'interests',
      Sentiments: 'sentiments',
      interests: 'interests',
      sentiments: 'sentiments',
      create_campaigns: 'create_campaigns',
      replies_tracking: 'replies_tracking',
      connectplus_1st: 'connectplus_1st',
      connectplus_2nd: 'connectplus_2nd',
      connectplus_3rd: 'connectplus_3rd',
      onChain: 'onChain_filter',
      onChain_filter: 'onChain_filter',
      campaign_messages_pannel: 'campaign_messages_pannel',
      campaign_recipient_pannel: 'campaign_recipient_pannel',
    }

    refetcGetPremiumPlans({ addon: addonMap?.[addon] })
  }, [])

  const mapAddonToTitle = (addon: string): string => {
    const titleMapping: Record<string, string> = {
      techstacktools: 'Tech Stack Tools',
      web2metrics: 'Web2 Metrics',
      revenue: 'Revenue',
      funding: 'Funding',
      intents: 'Signals',
      milestones: 'Milestones',
      interests: 'Interests',
      sentiments: 'Sentiments',
      create_campaigns: 'Create campaigns',
      campaign_messages_pannel: 'Campaign messages',
      campaign_recipient_pannel: 'Campaign recipient',
      onchain_filter: 'onChain',
      replies_tracking: 'Replies Tracking',
      connectplus_1st: '1st Connection Circle',
      connectplus_2nd: '2nd Connection Circle',
      connectplus_3rd: '3rd Connection Circle',
    }

    return titleMapping[addon.toLowerCase()] || addon
  }

  // const images: Record<string, AddonImages> = {
  //   techstacktools: { free: x_circle, basic: x_circle, premium: tick_circle, advanced: tick_circle },
  //   web2metrics: { free: x_circle, basic: x_circle, premium: tick_circle, advanced: tick_circle },
  //   revenue: { free: x_circle, basic: x_circle, premium: tick_circle, advanced: tick_circle },
  //   funding: { free: x_circle, basic: x_circle, premium: tick_circle, advanced: tick_circle },
  //   milestones: { free: x_circle, basic: x_circle, premium: x_circle, advanced: tick_circle },
  //   intents: { free: x_circle, basic: x_circle, premium: x_circle, advanced: x_circle },
  //   interests: { free: x_circle, basic: x_circle, premium: x_circle, advanced: x_circle },
  //   sentiments: { free: x_circle, basic: x_circle, premium: x_circle, advanced: x_circle },

  //   create_campaigns: { free: x_circle, basic: tick_circle, premium: tick_circle, advanced: tick_circle },
  //   replies_tracking: { free: x_circle, basic: x_circle, premium: tick_circle, advanced: tick_circle },
  //   connectplus_1st: { free: x_circle, basic: tick_circle, premium: tick_circle, advanced: tick_circle },
  //   connectplus_2nd: { free: tick_circle, basic: tick_circle, premium: tick_circle, advanced: tick_circle },
  //   connectplus_3rd: { free: tick_circle, basic: tick_circle, premium: tick_circle, advanced: tick_circle },
  // }
  // const defaultImages: AddonImages = { free: x_circle, basic: x_circle, premium: x_circle, advanced: tick_circle }

  // const addonImages = addon ? images[addon.toLowerCase()] : undefined
  // const {
  //   free: freeImage = defaultImages.free,
  //   basic: basicImage = defaultImages.basic,
  //   premium: premiumImage = defaultImages.premium,
  //   advanced: advancedImage = defaultImages.advanced,
  // } = addonImages || defaultImages

  const handleRedirect = () => {
    // if (handleClick) handleClick()
    post('/log', {
      action: 'See Pricing Plans',
    })
    // navigator.clipboard.writeText('pricingnow1799').then(
    //   () => {
    //     /* clipboard successfully set */
    //   },
    //   () => {
    //     /* clipboard write failed */
    //   },
    // )
    //
    // return window.open(`https://www.convrt.io/pricing-v2`, '_blank')
    navigate('/checkout')
  }

  // const handleCopyPassword = () => {
  //   navigator.clipboard.writeText('pricingnow1799').then(
  //     () => {
  //       setOpenPopover(true)
  //       setTimeout(() => {
  //         setOpenPopover(false)
  //       }, 850)
  //     },
  //     () => {
  //       /* clipboard write failed */
  //     },
  //   )
  // }

  return (
    <>
      <div className='blur-filters'>{Component}</div>
      <div className={classnames('company-details-paywall-filters', customClass)}>
        <img src={ShootingStars} alt='' />
        <p>
          {mapAddonToTitle(addon)} {type} is an advanced feature
          <br />
          and is not part of your plan.
        </p>
        {type === 'filter' && (
          <Collapse ghost expandIconPosition='right' style={{ paddingBottom: '10px' }} className='communities'>
            <Panel
              header={<span style={{ color: 'gray', fontSize: '16px' }}>How can this filter help me?</span>}
              key='about'>
              <span style={{ padding: '5px 0px' }}>
                This filter can help you identify more potential customers,
                <br />
                see customers of your competitors and personalize your outreach
              </span>
            </Panel>
          </Collapse>
        )}
        <div className='paywall-pricing-plans-filters'>
          {/* <div className='pricing-plan-item-filters'>
          {isLoading ? (
              <>
                <Skeleton.Avatar active={true} size={'small'} shape={'circle'} style={{ margin: '3px' }} />
                <Skeleton.Input active={true} size={'small'} />
              </>
              ) : (
              <>
                <img src={premiumPlansData?.free ? tick_circle : x_circle} alt='' />
                <p>Free Trial</p>
              </> 
              )}
          </div> */}

          <div className='pricing-plan-item-filters'>
            {isLoading ? (
              <>
                <Skeleton.Avatar active={true} size={'small'} shape={'circle'} style={{ margin: '3px' }} />
                <Skeleton.Input active={true} size={'small'} />
              </>
            ) : (
              <>
                <img src={premiumPlansData?.go_to_market ? tick_circle : x_circle} alt='' />
                <p>Go to Market</p>
              </>
            )}
          </div>
          <div className='pricing-plan-item-filters'>
            {isLoading ? (
              <>
                <Skeleton.Avatar active={true} size={'small'} shape={'circle'} style={{ margin: '3px' }} />
                <Skeleton.Input active={true} size={'small'} />
              </>
            ) : (
              <>
                <img src={premiumPlansData?.connect_plus ? tick_circle : x_circle} alt='' />
                <p>Connect+</p>
              </>
            )}
          </div>
          <div className='pricing-plan-item-filters'>
            {isLoading ? (
              <>
                <Skeleton.Avatar active={true} size={'small'} shape={'circle'} style={{ margin: '3px' }} />
                <Skeleton.Input active={true} size={'small'} />
              </>
            ) : (
              <>
                <img src={premiumPlansData?.market_dominance ? tick_circle : x_circle} alt='' />
                <p>Market Dominance</p>
              </>
            )}
          </div>
        </div>
        <Button onClick={handleRedirect}>See Pricing Plans</Button>

        {/* <Popover
          trigger={['click']}
          open={openPopover}
          content={<span>Password copied to clipboard!</span>}
          placement='bottom'>
          <span
            style={{ cursor: 'pointer', marginTop: '10px', color: '#7043ff', fontWeight: 600 }}
            onClick={handleCopyPassword}>
            Copy Pricing Password
          </span>
        </Popover> */}

        {/* <span
          style={{ cursor: 'pointer', marginTop: '10px', color: '#7043ff', fontWeight: 600 }}
          onClick={handleCopyPassword}>
          Copy Pricing Password
        </span> */}
      </div>
    </>
  )
}

export default BlurPaywallFilters
