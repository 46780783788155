import { Button, Drawer, Input, Spin, Typography, message } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from './TemplateSider.module.scss'
import { LoadingOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import { useInsertCustomMessageMutation, useUpdateCustomMessageMutation } from 'features/Outreach/state/api/OutreachApi'

export const TemplateSider = ({ open, onClose, refetchGetCustomMessages, ...props }: any) => {
  const [insertCustomMessage] = useInsertCustomMessageMutation()
  const [updateCustomMessage] = useUpdateCustomMessageMutation()
  const [name, setName] = useState<any>(props.name)
  const [messageId, setMessageId] = useState<any>(props.messageId)
  const [messageContent, setMessageContent] = useState<any>(props.message)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (open) {
      setMessageContent(props.message)
      setName(props.name)
      setMessageId(props.messageId)
    } else {
      setName('')
      setMessageContent('')
      setMessageId('')
    }
  }, [open, props.messageId])

  const handleSaveTemplate = async () => {
    try {
      setLoading(true)
      if (messageId) {
        await updateCustomMessage({ message_id: messageId, message: messageContent, message_name: name })
      } else {
        await insertCustomMessage({ message: messageContent, message_name: name })
      }
      await refetchGetCustomMessages()
      setLoading(false)
      onClose()
      message.success('Template saved successfully')
    } catch (error) {
      setLoading(false)
      message.error('Failed to save template')
    }
  }
  const renderTitle = () => {
    return (
      <div className={styles.sider__header}>
        <div className={styles.sider__header__left}>
          <Typography>Save Template</Typography>
          {loading && (
            <Spin style={{ margin: '0 10px' }} indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
          )}
        </div>
        <Button type='primary' disabled={loading || !name || !messageContent} onClick={handleSaveTemplate}>
          Save
        </Button>
      </div>
    )
  }

  return (
    <Drawer title={renderTitle()} open={open} closable onClose={onClose} className={'save-template-sider'}>
      <div className={styles.sider__body}>
        <div>
          <Typography className='font-bold mb-1'>Template Name</Typography>
          <Input value={name} onChange={(e) => setName(e.target.value)} style={{ padding: '6px 16px' }} />
        </div>

        <div>
          <Typography className='font-bold mb-2'>Message Content</Typography>
          <TextArea
            autoSize={{ minRows: 6, maxRows: 6 }}
            value={messageContent}
            onChange={(e) => setMessageContent(e.target.value)}
            style={{ padding: '6px 16px' }}
          />
        </div>
      </div>
    </Drawer>
  )
}
