import right_arrow from 'common/assets/svg/right_arrow.svg'
import hubspot from 'common/assets/svg/hubspot_purple.svg'
import new_deal from 'common/assets/svg/new_deal.svg'
import update_deal from 'common/assets/svg/update_deal.svg'
import export_contacts from 'common/assets/svg/export_contacts.svg'
import export_companies from 'common/assets/svg/export_companies.svg'
import styles from './HubspotInitScreen.module.scss'

const HubspotInitScreen = ({
  onOpenComponent,
  isCompany,
}: {
  onOpenComponent: (componentName: string) => void
  isCompany: boolean
}) => {
  const handleOpenNewDeal = () => {
    onOpenComponent('NewDeal')
  }

  const handleOpenUpdateDeal = () => {
    onOpenComponent('UpdateDeal')
  }

  const handleOpenExportContacts = () => {
    onOpenComponent('ExportContacts')
  }

  const handleOpenExportCompanies = () => {
    onOpenComponent('ExportCompanies')
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={hubspot} alt='' />
        <h1>Select Hubspot Export Option</h1>
      </div>
      <div className={styles.section} onClick={handleOpenNewDeal}>
        <img src={new_deal} alt='' />
        <div className={styles.item}>
          <p>Create a New Deal Per Company</p>
          <span>Convrt will automatically create a new Hubspot deal per company</span>
        </div>
        <img className={styles.rightArrow} src={right_arrow} alt='' />
      </div>
      <div className={styles.section} onClick={handleOpenUpdateDeal}>
        <img src={update_deal} alt='' />
        <div className={styles.item}>
          <p>Update an Existing Deal</p>
          <span>Select your existing Hubspot deal and export to it</span>
        </div>
        <img className={styles.rightArrow} src={right_arrow} alt='' />
      </div>

      {!isCompany && (
        <div className={styles.section} onClick={handleOpenExportContacts}>
          <img src={export_contacts} alt='' />
          <div className={styles.item}>
            <p>Export Contacts</p>
            <span>Export a list of contacts based on your Hubspot Mapping</span>
          </div>
          <img className={styles.rightArrow} src={right_arrow} alt='' />
        </div>
      )}

      <div className={styles.section} onClick={handleOpenExportCompanies}>
        <img src={export_companies} alt='' />
        <div className={styles.item}>
          <p>Export Companies</p>
          <span>Export a list of companies based on your Hubspot Mapping</span>
        </div>
        <img className={styles.rightArrow} src={right_arrow} alt='' />
      </div>
    </div>
  )
}
export default HubspotInitScreen
