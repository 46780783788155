import { useState } from 'react'
import { Form, Input } from 'antd'

import FlagInput from '../FlagInput'

import styles from './OnBoardingPageB.module.scss'
// import type { CheckboxChangeEvent } from 'antd/es/checkbox'

interface OnBoardingPageBProps {
  handleFormDataChange: (data: any) => void
}

const OnBoardingPageB = ({ handleFormDataChange }: OnBoardingPageBProps) => {
  const [prefix, setPrefix] = useState('+1-US')
  const [valuesChange, setValuesChange] = useState({ prefix: '+1-US', telegramNumber: '' })
  // const [checked, setChecked] = useState(true)

  const handleValuesChange = (changedValues: any, allValues: any) => {
    const selectedPrefix = prefix?.split('-')?.[0]
    setValuesChange({ ...allValues, telegramNumber: allValues.telegramNumber, prefix: selectedPrefix })
    handleFormDataChange({ ...allValues, telegramNumber: allValues.telegramNumber, prefix: selectedPrefix })
  }

  const handlePrefixChange = (e: any) => {
    // example of e: +1-US
    const selectedPrefix = e?.split('-')?.[0]
    setPrefix(e)
    handleFormDataChange({ ...valuesChange, prefix: selectedPrefix })
  }

  // const handleCheckbox = (e: CheckboxChangeEvent) => {
  //   setChecked(e.target.checked)
  //   handleFormDataChange({ ...valuesChange, prefix, checked })
  // }

  return (
    <div className={styles.container}>
      <p>{'Connect Your Telegram'}</p>
      <section>{'Enter your Telegram number and follow the setup steps'}</section>
      <Form onValuesChange={handleValuesChange}>
        <h5>{'Telegram Number'}</h5>
        <div className='phoneOutreachContainer'>
          <Form.Item
            name='telegramNumber'
            rules={[
              { required: true, message: 'Telegram Number is required' },
              { message: 'Invalid phone number', pattern: new RegExp(/^[0-9]+$/) },
            ]}>
            <Input prefix={<FlagInput className={styles.prefix} onChange={handlePrefixChange} prefix={prefix} />} />
          </Form.Item>
          {/* <Form.Item>
            <Checkbox checked={checked} onChange={handleCheckbox}>
              Subscribe to Connect +
            </Checkbox>
          </Form.Item> */}
        </div>
      </Form>
    </div>
  )
}

export default OnBoardingPageB
