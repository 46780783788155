import React, { useCallback, useEffect, useState } from 'react'
import { Modal, Button, Layout, Pagination, Switch, Divider } from 'antd'
import styles from './ImportLeadsFromGroupsModal.module.scss'

import { useAppDispatch, useAppSelector } from 'state'

import {
  SET_ARE_LEADS_FROM_GROUPS,
  SET_SELECTED_LEADS,
} from 'common/components/OutreachCampaign/state/outreachCampaignSlice'
import {
  useGetAllRawLeadsQuery,
  useGetLeadsByGroupIdQuery,
  useGetMatchedLeadsQuery,
  useGetRawLeadsQuery,
  useGetTelegramGroupsQuery,
} from 'features/Outreach/state/api/OutreachApi'
import three_users from 'common/assets/svg/three_users.svg'
import TelegramGroups from './TelegramGroups'
import LeadsTable from './LeadsTable'
import GroupsFilters from './GroupsFilters'
import SaveFiltersComponent from './SavedFilters'
import { debounce } from 'lodash'
import LeadsSidebar from './LeadsSidebar'

const { Header } = Layout

interface ImportLeadsFromTelegramModalProps {
  open: boolean
  isLoading: boolean
  campaign_id: string
  onClose: () => void
  onConfirm: (data: any) => Promise<any>
  isUserInATeam?: boolean
}

const ImportLeadsFromTelegramModal: React.FC<ImportLeadsFromTelegramModalProps> = ({
  open,
  isLoading,
  campaign_id,
  onClose,
  onConfirm,
  isUserInATeam,
}) => {
  const dispatch = useAppDispatch()
  const { selected_leads } = useAppSelector((state) => state.outreachCampaign)
  const [keywords, setKeywords] = useState<string[]>([])
  const [conditionedKeywords, setConditionedKeywords] = useState<string[]>([])
  const [operator, setOperator] = useState('AND')
  const [selectedAll, setSelectedAll] = useState<any>(undefined)
  const [selectedCategory, setSelectedCategory] = useState<string>('My Groups')
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectedGroup, setSelectedGroup] = useState<any>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [scrollTopGroups, setScrollTopGroups] = useState(false)
  const [ignoreLeadsFromOtherCampaigns, setIgnoreLeadsFromOtherCampaigns] = useState(true)
  const [ignoreLeadsFromOtherTeamMembers, setIgnoreLeadsFromOtherTeamMembers] = useState(true)
  const [ignoreOutreachedLeads, setIgnoreOutreachedLeads] = useState(true)
  const [ignoreOutreachedLeadsFromTeam, setIgnoreOutreachedLeadsFromTeam] = useState(true)

  const [telegramGroupsParams, setTelegramGroupsParams] = useState<any>({
    search: '',
    current: 1,
    pageSize: 102,
  })

  const [params, setParams] = useState<any>({
    current: 1,
    pageSize: 100,
    groupId: selectedGroup?.id,
    search: '',
    extraSearch: '',
  })

  const { data: allGroupsData, isFetching } = useGetTelegramGroupsQuery(telegramGroupsParams)

  const { data: groupMembers, isFetching: isFetchingGroupMemberData } = useGetLeadsByGroupIdQuery(params, {
    refetchOnMountOrArgChange: true,
    skip: !selectedGroup?.id,
  })

  const { data: allGroupMembers, isFetching: isFetchingAllGroupMember } = useGetLeadsByGroupIdQuery(
    {
      ...params,
      getAll: true,
    },

    {
      refetchOnMountOrArgChange: true,
      skip: !selectedGroup?.id,
    },
  )

  const { data: allLeads, isFetching: isFetchingAllLeads } = useGetAllRawLeadsQuery(
    {
      ...params,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: selectedCategory !== 'All Leads',
    },
  )

  const { data: allLeadIds, isFetching: isFetchingAllLeadIds } = useGetAllRawLeadsQuery(
    {
      ...params,
      getAll: true,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: selectedCategory !== 'All Leads',
    },
  )

  const { data: rawLeads, isFetching: isFetchingRawLeads } = useGetRawLeadsQuery(
    {
      ...params,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: selectedCategory !== 'Raw Leads',
    },
  )

  const { data: allRawLeadIds, isFetching: isFetchingAllRawLeadIds } = useGetRawLeadsQuery(
    {
      ...params,
      getAll: true,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: selectedCategory !== 'Raw Leads',
    },
  )

  const { data: matchedLeads, isFetching: isFetchingMatchedLeads } = useGetMatchedLeadsQuery(
    {
      ...params,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: selectedCategory !== 'Matched Leads',
    },
  )

  const { data: allMatchedLeadIds, isFetching: isFetchingAllMatchedLeadIds } = useGetMatchedLeadsQuery(
    {
      ...params,
      getAll: true,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: selectedCategory !== 'Matched Leads',
    },
  )

  const [data, setData] = useState<any>()

  const [allData, setAllData] = useState<any>()

  const [isFetchingData, setIsFetchingData] = useState(true)
  const [isFetchingAllData, setIsFetchingAllData] = useState(true)

  const handleSearchGroupChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTelegramGroupsParams({
      ...telegramGroupsParams,
      search: e.target.value,
    })
  }

  const debouncedHandleSearchGroupChange = useCallback(debounce(handleSearchGroupChange, 500), [
    handleSearchGroupChange,
  ])

  const onChangeSearchGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
    debouncedHandleSearchGroupChange(e)
  }

  const handleResetState = () => {
    setKeywords([])
    setConditionedKeywords([])
    setOperator('AND')
    setSearchQuery('')
    setSelectedAll(undefined)
    setSelectedGroup(null)
    setSelectedRowKeys([])
    setSelectedCategory('My Groups')
    setTelegramGroupsParams({
      current: 1,
      pageSize: 102,
      search: '',
    })

    setParams({
      ...params,
      current: 1,
      pageSize: 100,
      groupId: '',
    })
  }

  const handleCloseModal = () => {
    handleResetState()
    onClose()
  }

  const handleAddLeads = async () => {
    dispatch(SET_ARE_LEADS_FROM_GROUPS(true))
    setOpenConfirmationModal(false)

    const submissionData = {
      campaignId: campaign_id,
      source: 'telegram_imported',
      ids: selected_leads,
      group_name: selectedGroup?.title,
      ignoreLeadsFromOtherCampaigns: ignoreLeadsFromOtherCampaigns,
      ignoreLeadsFromOtherTeamMembers: false,
      ignoreOutreachedLeads: ignoreOutreachedLeads,
      ignoreOutreachedLeadsFromTeam: false,
    }
    const response = await onConfirm(submissionData)
    if (response) {
      setSelectedRowKeys([])
      dispatch(SET_SELECTED_LEADS([]))
      handleResetState()
    }
  }

  const handleSelectAllData = () => {
    setSelectedAll(true)
    const respSelectFormat = allData?.rows
    const uniqueContactsIds = Array.from(new Set(respSelectFormat))
    dispatch(SET_SELECTED_LEADS(uniqueContactsIds))
  }

  const headerStyle: React.CSSProperties = {
    color: 'black',
    fontWeight: 600,
    fontSize: '18px',
    height: 80,
    paddingInline: 24,
    lineHeight: '64px',
    backgroundColor: 'white',
    borderBottom: '1px solid #DFE1E6',
    alignContent: 'center',
  }

  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
  }

  const handleFilterChange = ({ keywords, operator, conditionedKeywords }: any) => {
    const formatKeyword = keywords.length > 0 ? keywords.map((keyword: any) => keyword).join(',') : ''

    const formatOperator = operator === 'AND' ? 'AND' : 'AND_NOT'
    const extraSearch =
      conditionedKeywords.length > 0
        ? `${formatOperator}:${conditionedKeywords.map((keyword: any) => keyword).join(',')}`
        : ''

    setParams({
      ...params,
      current: 1,
      search: formatKeyword,
      extraSearch: extraSearch,
    })
  }

  const parseExtraSearch = (input: string) => {
    const [operator, conditionsString] = input?.split(':') || 'AND'
    const conditions = conditionsString?.split(',').map((condition) => condition.trim()) || []

    return {
      operator,
      conditions,
    }
  }

  const handleSelectFilter = (item: any) => {
    if (item.extraSearch) {
      const { operator, conditions } = parseExtraSearch(item.extraSearch)
      setConditionedKeywords(conditions)
      setOperator(operator)
    } else {
      setConditionedKeywords([])
      setOperator('AND')
    }
    setKeywords(item?.search?.split(',') || [])
    setParams({
      ...params,
      current: 1,
      search: item.search,
      extraSearch: item.extraSearch,
    })
  }

  const handleSelectedGroupChange = (newGroup: any) => {
    setSelectedGroup(newGroup)
    setParams({
      ...params,
      current: 1,
      pageSize: 100,
      groupId: newGroup.group_id,
    })
  }

  const handleDismissData = () => {
    setSelectedAll(undefined)
    dispatch(SET_SELECTED_LEADS([]))
    setSelectedRowKeys([])
  }

  const resetFilters = () => {
    setKeywords([])
    setConditionedKeywords([])
    setOperator('AND')

    setParams({
      ...params,
      current: 1,
      search: '',
      extraSearch: '',
    })
  }

  useEffect(() => {
    dispatch(SET_SELECTED_LEADS(selectedRowKeys))
  }, [selectedRowKeys])

  useEffect(() => {
    setScrollTopGroups(true)
  }, [allGroupsData])

  useEffect(() => {
    if (scrollTopGroups) {
      setScrollTopGroups(false)
    }
  }, [scrollTopGroups])

  useEffect(() => {
    setSearchQuery('')
  }, [])

  useEffect(() => {
    switch (selectedCategory) {
      case 'My Groups':
        if (selectedGroup) {
          setData(groupMembers)
          setAllData(allGroupMembers)
          setIsFetchingData(isFetchingGroupMemberData)
          setIsFetchingAllData(isFetchingAllGroupMember)
        }
        break
      case 'Raw Leads':
        const rawLeadsData = {
          ...rawLeads,
          rows:
            rawLeads &&
            rawLeads.rows.map((item: any) => ({
              ...item,
              ...item.item,
            })),
        }
        setData(rawLeadsData)
        setAllData(allRawLeadIds)
        setIsFetchingData(isFetchingRawLeads)
        setIsFetchingAllData(isFetchingAllRawLeadIds)
        break
      case 'Matched Leads':
        const matchedLeadsData = {
          ...matchedLeads,
          rows:
            matchedLeads &&
            matchedLeads.rows.map((item: any) => ({
              ...item,
              ...item.item,
              ...item.convrt_contact,
            })),
        }
        setData(matchedLeadsData)
        setAllData(allMatchedLeadIds)
        setIsFetchingData(isFetchingMatchedLeads)
        setIsFetchingAllData(isFetchingAllMatchedLeadIds)
        break
      case 'All Leads':
        const allLeadsData = {
          ...allLeads,
          rows:
            allLeads &&
            allLeads.rows.map((item: any) => ({
              ...item,
              ...item.item,
            })),
        }
        setData(allLeadsData)
        setAllData(allLeadIds)
        setIsFetchingData(isFetchingAllLeads)
        setIsFetchingAllData(isFetchingAllLeadIds)
        break

      default:
        setData(rawLeads)
        setAllData(allRawLeadIds)
        setIsFetchingData(isFetchingRawLeads)
        setIsFetchingAllData(isFetchingAllRawLeadIds)
        break
    }
  }, [
    groupMembers,
    allGroupMembers,
    rawLeads,
    allRawLeadIds,
    matchedLeads,
    allMatchedLeadIds,
    allLeads,
    allLeadIds,
    isFetchingGroupMemberData,
    isFetchingAllGroupMember,
    isFetchingRawLeads,
    isFetchingAllRawLeadIds,
    isFetchingMatchedLeads,
    isFetchingAllMatchedLeadIds,
    isFetchingAllLeads,
    isFetchingAllLeadIds,
  ])

  useEffect(() => {
    setData(null)
    setAllData(null)
  }, [selectedCategory])

  return (
    <Modal
      className='groupsModal'
      centered
      closable={false}
      open={open}
      footer={null}
      onCancel={handleCloseModal}
      width='87%'>
      {selectedGroup || selectedCategory !== 'My Groups' ? (
        <Layout style={layoutStyle}>
          <Header style={headerStyle}>
            <div className={styles.header}>
              <span>
                {selectedCategory !== 'My Groups' ? selectedCategory : `Add Leads From ${selectedGroup?.title}`}
                <Divider type='vertical' />{' '}
                <span style={{ fontSize: '14px' }}>
                  {selected_leads?.length || 0} leads <span style={{ fontWeight: 500 }}>were selected</span>
                </span>
                {selectedAll && (
                  <span
                    onClick={handleDismissData}
                    style={{ fontSize: '14px', marginLeft: '10px', color: 'blue', cursor: 'pointer' }}>
                    Dismiss
                  </span>
                )}
              </span>
              <div className={styles.buttons}>
                <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={handleCloseModal}>
                  Cancel
                </span>
                <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={handleResetState}>
                  Back
                </span>
                <Button
                  type='primary'
                  disabled={selected_leads?.length === 0 || isLoading}
                  loading={isLoading}
                  onClick={() => {
                    setOpenConfirmationModal(true)
                  }}>
                  Import Leads
                </Button>
              </div>
            </div>
          </Header>
          <Layout>
            <>
              <LeadsTable
                selectedGroup={selectedGroup?.title}
                data={isFetchingData || isFetchingAllData ? [] : data?.rows}
                isLoading={isFetchingData || isFetchingAllData}
                selectedRowKeys={selected_leads?.map((lead: any) => lead || lead.id)}
                handleSelectAllData={handleSelectAllData}
                tableParams={{
                  ...params,
                  total: +allData?.total,
                }}
                handleTableChange={(data: any) => {
                  setParams(data)
                }}
                setSelectedRowKeys={setSelectedRowKeys}
                type={selectedCategory}
              />
              <div className={styles.filter}>
                <GroupsFilters
                  conditionedKeywords={conditionedKeywords}
                  setConditionedKeywords={setConditionedKeywords}
                  keywords={keywords}
                  setKeywords={setKeywords}
                  onFilterChange={handleFilterChange}
                  setOperator={setOperator}
                  operator={operator}
                />
                <SaveFiltersComponent
                  resetFilters={resetFilters}
                  handleSelect={handleSelectFilter}
                  params={params}
                  keywords={keywords}
                  conditionedKeywords={conditionedKeywords}
                />
              </div>
            </>
          </Layout>
        </Layout>
      ) : (
        <Layout style={layoutStyle}>
          <Header style={headerStyle}>
            <div className={styles.header}>
              <span>Import from My telegram</span>

              <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={handleCloseModal}>
                Cancel
              </span>
            </div>
          </Header>
          <Layout>
            <LeadsSidebar selectedCategory={selectedCategory} categories={[]} onCategoryChange={setSelectedCategory} />
            <Layout>
              <TelegramGroups
                totalGroups={allGroupsData?.total}
                groupsData={allGroupsData?.response}
                isLoading={isFetching}
                searchQuery={searchQuery}
                onChangeSearchGroup={onChangeSearchGroup}
                scrollTopGroups={scrollTopGroups}
                setSelectedGroup={handleSelectedGroupChange}
              />
              {!isFetching && (
                <div style={{ display: 'flex', justifyContent: 'center', padding: 5, backgroundColor: 'white' }}>
                  <Pagination
                    defaultCurrent={1}
                    current={telegramGroupsParams.current}
                    onChange={(current, pageSize) => {
                      setTelegramGroupsParams({
                        ...telegramGroupsParams,
                        current: current,
                        pageSize: pageSize,
                      })
                    }}
                    total={allGroupsData?.total}
                    size='small'
                    pageSize={102}
                  />
                </div>
              )}
            </Layout>
          </Layout>
        </Layout>
      )}

      {openConfirmationModal && (
        <Modal
          className={styles.modalStyleNewDeal}
          centered
          open={open}
          okButtonProps={{ className: 'hidden' }}
          cancelButtonProps={{ className: 'hidden' }}
          onCancel={() => {
            setOpenConfirmationModal(false)
          }}
          width={'fit-content'}
          style={{ minWidth: 500, maxWidth: 500 }}>
          <div className={styles.contentStyle}>
            <div className={styles.container}>
              <img src={three_users} alt='' />
              <p>Leads Configurations</p>
              <span>Please select your configurations before adding your desired leads</span>
              <div className={styles.switches}>
                <div className={styles.switchItem}>
                  <Switch
                    onChange={() => {
                      setIgnoreLeadsFromOtherCampaigns(!ignoreLeadsFromOtherCampaigns)
                    }}
                    checked={ignoreLeadsFromOtherCampaigns}
                    size={'small'}
                  />
                  <span className={styles.switchLabel}>{`Exclude Leads Already Present in Other Campaigns`}</span>
                </div>
                <div className={styles.switchItem}>
                  <Switch
                    onChange={() => {
                      setIgnoreOutreachedLeads(!ignoreOutreachedLeads)
                    }}
                    checked={ignoreOutreachedLeads}
                    size={'small'}
                  />
                  <span className={styles.switchLabel}>{`Exclude Leads Already Contacted in Other Campaigns`}</span>
                </div>
                {isUserInATeam && (
                  <>
                    <div className={styles.switchItem}>
                      <Switch
                        onChange={() => {
                          setIgnoreLeadsFromOtherTeamMembers(!ignoreLeadsFromOtherTeamMembers)
                        }}
                        checked={ignoreLeadsFromOtherTeamMembers}
                        size={'small'}
                      />
                      <span className={styles.switchLabel}>{`Exclude Leads Already Present in Team Campaign`}</span>
                    </div>
                    <div className={styles.switchItem}>
                      <Switch
                        onChange={() => {
                          setIgnoreOutreachedLeadsFromTeam(!ignoreOutreachedLeadsFromTeam)
                        }}
                        checked={ignoreOutreachedLeadsFromTeam}
                        size={'small'}
                      />
                      <span className={styles.switchLabel}>
                        {`Exclude Leads Already Contacted by other Team member`}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className={styles.footer}>
                <Button
                  size={'middle'}
                  style={{ color: '#7043ff', backgroundColor: 'white' }}
                  onClick={() => setOpenConfirmationModal(false)}>
                  Close
                </Button>
                <Button size={'middle'} style={{ color: 'white', backgroundColor: '#7043ff' }} onClick={handleAddLeads}>
                  Add Leads
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </Modal>
  )
}

export default ImportLeadsFromTelegramModal
