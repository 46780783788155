/* eslint-disable no-relative-import-paths/no-relative-import-paths */
import React, { useEffect, useState } from 'react'
import { useAuth } from 'common/hooks/useAuth.hooks'

import { Checkbox, Col, Divider, Input, Modal, Popconfirm, Popover, Row, Select, Tag, Tooltip, message } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import { post } from 'common/api/axios'

import OutOfCreditsModal from 'common/components/OutOfCredits/OutOfCreditsModal'
import large_lists from 'common/assets/svg/large_lists.svg'
import arrow_down from 'common/assets/svg/arrow_down.svg'
import company_list from 'common/assets/svg/company_list.svg'
import contact_list from 'common/assets/svg/contact_list.svg'
import arrow_up from 'common/assets/svg/arrow_up.svg'
import stars from 'common/assets/svg/stars.svg'

import FancyFilterExpandItemBody from 'common/components/fancy-filter-expand-item-body/fancy-filter-expand-item-body'
// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { IExpandGroupeChildren, IExpandListChildren, IFilter } from 'common/interfaces/IFilters'

import { useAppDispatch, useAppSelector } from 'state'
import { setReset, setTechSidebarOpen, setTechSidebarType } from 'state/filtersSlice/filtersSlice'

import FancyFilterExpandGroupe from '../fancy-filter-expand-groupe/fancy-filter-expand-grupe'
import FancyFilterGroupe from '../fancy-filter-expand-groupe/fancy-filter-grupe'
import styles from './fancy-filter-expand-item.module.scss'

import { ReactComponent as LockComponent } from 'common/assets/svg/lock.svg'
import {
  useLazyAddNewListQuery,
  useLazyDeleteListQuery,
  useLazyGetCompaniesByIdsQuery,
  useLazyGetListsQuery,
  useLazyRenameListQuery,
} from '../../../features/intent-signals/state/api/CompaniesApi'
import {
  SET_FILTER,
  SET_LISTS,
  SET_SELECTED_COMPANIES_DETAILS,
} from '../../../features/intent-signals/state/slice/companiesSlice'
import { useLocation } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { CustomTag } from '../CustomTag/CustomTag'
import { removeEmpty } from '../../utils/objectToValues'
import { useCookies } from 'react-cookie'
import { ConfirmBox } from '../../constants/modal.constants'
import { injectionRegex } from '../../static-data/userData'
import { MESSAGE_ERROR } from '../../constants/messages.constants'
import NewTag from '../NewTag/NewTag'
// import BlurPaywall from '../../../features/intent-signals/components/paywalls/BlurPaywall'
import { outreach } from 'common/constants/outreach.constants'
import BlurPaywallFilters from 'features/intent-signals/components/paywalls/BlurPaywallFilters'
import { useActiveFilters } from 'common/hooks/useFilters'
import { useLazyGetAllCampaignsQuery } from 'features/Outreach/state/api/OutreachApi'

interface IFancyFilterExpandItem {
  item: IFilter
}

const FancyFilterExpandItem: React.FC<IFancyFilterExpandItem> = ({ item }) => {
  const { isBetaOrTrialUser } = useAuth()
  const [outOfCredits, setOutOfCredits] = useState(false)
  const [activeFilters] = useActiveFilters()
  // const filterTags = objectToValues(activeFilters)

  const location = useLocation()
  const [openContactSales, setOpenContactSales] = useState(false)
  const [expand, setExpand] = useState(!!item?.open)
  const [renameList, setRenameList] = useState('')
  const [addToListInput, setAddToListInput] = useState(false)
  const [selectedList, setSelectedList] = useState<any>(null)
  const [activeCompanies, setActiveCompanies] = useState(0)
  const dispatch = useAppDispatch()
  const { isTechSidebarOpen, resetFilter } = useAppSelector((state) => state.filters)
  const { companyId } = useAppSelector((state) => state.contacts)
  const { selected_companies_details, company_lists, global_company_selected } = useAppSelector(
    (state) => state.companies,
  )
  const [refetchGetCompaniesByIds] = useLazyGetCompaniesByIdsQuery()
  const [getAllCampaigns, { data: campaignData }] = useLazyGetAllCampaignsQuery()
  const [refetchLists] = useLazyGetListsQuery()
  const [refetchDeleteList] = useLazyDeleteListQuery()
  const [refetchRenameList] = useLazyRenameListQuery()
  const { filter } = useAppSelector((state) => state.companies)
  const [listNameCookies, setListNameCookies] = useCookies(['list_name'])
  const [refetchAddList] = useLazyAddNewListQuery()
  // eslint-disable-next-line
  const [selectedCompaniesCookies, setSelectedCompaniesCookies] = useCookies(['selected_companies'])
  const [selectValue, setSelectValue] = useState<any>([])
  const [selectCampaignValue, setSelectCampaignValue] = useState<any>([])

  const allOptionList = {
    name: 'All',
    key: 'all',
    value: 'all',
    id: 'all',
  }

  const allOptionCampaign = {
    campaign_name: 'All',
    campaign_id: 'all',
    key: 'all',
    value: 'all',
    name: 'all',
  }

  const formatData = (isAllOption: boolean, data: any) => {
    if (isAllOption) return data.filter((item: any) => item.label === 'All')
    const indexOfAllOptionIfHave = data.findIndex((item: any) => item.label === 'All')
    if (indexOfAllOptionIfHave < 0) return data
    if (indexOfAllOptionIfHave === 0) return [...data.slice(1)]
    return [...data.slice(0, indexOfAllOptionIfHave), ...data.slice(indexOfAllOptionIfHave)]
  }

  const handleExcClick = (option: any, campaign?: boolean) => {
    const isAllOption = option?.name === (campaign ? allOptionCampaign.name : allOptionList.name)

    if (campaign) {
      setSelectCampaignValue((prev: any) => {
        let data: any = []
        const existingOption = prev.find((item: any) => item.label === option.campaign_name)
        data = existingOption
          ? prev.map((item: any) => (item.label === option.campaign_name ? { ...item, type: 'exclude' } : item))
          : [...prev, { label: option.campaign_name, key: option.campaign_id, type: 'exclude' }]

        return formatData(isAllOption, data)
      })
    } else {
      setSelectValue((prev: any) => {
        let data: any = []
        const existingOption = prev.find((item: any) => item.label === option.name)
        data = existingOption
          ? prev.map((item: any) => (item.label === option.name ? { ...item, type: 'exclude' } : item))
          : [...prev, { label: option.name, key: option.id, type: 'exclude', listType: option.type }]

        return formatData(isAllOption, data)
      })
    }
  }

  const handleIncClick = (option: any, campaign?: boolean) => {
    const isAllOption = option?.name === (campaign ? allOptionCampaign.name : allOptionList.name)

    if (campaign) {
      setSelectCampaignValue((prev: any) => {
        let data: any = []
        const existingOption = prev.find((item: any) => item.label === option.campaign_name)
        data = existingOption
          ? prev.map((item: any) => (item.label === option.campaign_name ? { ...item, type: 'include' } : item))
          : [...prev, { label: option.campaign_name, key: option.campaign_id, type: 'include' }]

        return formatData(isAllOption, data)
      })
    } else {
      setSelectValue((prev: any) => {
        let data: any = []
        const existingOption = prev.find((item: any) => item.label === option.name)
        data = existingOption
          ? prev.map((item: any) => (item.label === option.name ? { ...item, type: 'include' } : item))
          : [...prev, { label: option.name, key: option.id, type: 'include', listType: option.type }]

        return formatData(isAllOption, data)
      })
    }
  }

  const handleDeselect = (value: any, campaign?: boolean) => {
    if (campaign) {
      setSelectCampaignValue((prev: any) => prev.filter((item: any) => item.key !== value))
    } else {
      setSelectValue((prev: any) => prev.filter((item: any) => item.key !== value))
    }
  }

  const handleExcludeContacted = (e: any) => {
    const checked = e.target.checked
    const filterCopy = JSON.parse(JSON.stringify(filter))

    if (checked) {
      _.set(filterCopy, 'excludeOutreachedContacts', [{ a: 'Exclude Outreached' }])
    } else {
      delete filterCopy.excludeOutreachedContacts
    }
    dispatch(SET_FILTER(removeEmpty(filterCopy)))
  }

  const handleExcludeExposed = (e: any) => {
    const checked = e.target.checked
    const filterCopy = JSON.parse(JSON.stringify(filter))

    if (checked) {
      _.set(filterCopy, 'excludeExposedContacts', [{ a: 'Exclude Exposed' }])
    } else {
      delete filterCopy.excludeExposedContacts
    }
    dispatch(SET_FILTER(removeEmpty(filterCopy)))
  }

  useEffect(() => {
    if (resetFilter) {
      setSelectedList(null)
      dispatch(setReset(false))
      setExpand(false)
      setAddToListInput(false)
    }
  }, [resetFilter])

  useEffect(() => {
    if (selectedCompaniesCookies['selected_companies']?.length) {
      if (selected_companies_details.length === 0 && location.pathname === '/contacts' && !selectedList) {
        const fetchData = async () => {
          const response = await refetchGetCompaniesByIds(global_company_selected.join(','))
          if (!response.isError) {
            dispatch(SET_SELECTED_COMPANIES_DETAILS(response?.data?.map((obj: any) => ({ ...obj, selected: true }))))
          }
        }

        if (selected_companies_details.length === 0 && global_company_selected.length !== 0) fetchData()
      } else {
        const fetchDataCompanyLists = async () => {
          let result = await refetchLists()
          if (result) dispatch(SET_LISTS(result.data))
        }

        fetchDataCompanyLists()
      }
      setActiveCompanies(selected_companies_details.filter((item: any) => item.selected === true).length)
    }
  }, [companyId, selected_companies_details])

  useEffect(() => {
    if ((filter.contactsList || filter.companyList) && selected_companies_details.length > 0) {
      setActiveCompanies(0)
    } else {
      setActiveCompanies(selected_companies_details.filter((item: any) => item.selected === true).length)
    }
  }, [activeCompanies, company_lists, filter, listNameCookies])

  useEffect(() => {
    setSelectedList(null)
    setSelectValue([])
  }, [location.pathname])

  useEffect(() => {
    if (selectedList) {
      const fetchData = async () => {
        const filterCopy = JSON.parse(JSON.stringify(filter))
        if (location.pathname === '/contacts') {
          delete filterCopy.contactsList
          _.set(filterCopy, 'contactsList', [
            {
              list_id: selectedList.id,
            },
          ])
        } else if (location.pathname === '/companies') {
          delete filterCopy.contactsList
          _.set(filterCopy, 'companyList', [
            {
              list_id: selectedList.id,
            },
          ])
        }
        dispatch(SET_FILTER(removeEmpty(filterCopy)))
      }

      fetchData()
    }
  }, [selectedList])

  useEffect(() => {
    if (selectValue) {
      const isHaveAllOption = (type: 'exclude' | 'include', list: any) =>
        list.find((item: any) => item.label === 'All' && item.type === type)

      const formatDataAllList = (list: any, type: string) => {
        return list?.map((item: any) => ({
          key: item?.id,
          label: item?.name,
          type,
          listType: item?.type,
        }))
      }

      const excludeData = isHaveAllOption('exclude', selectValue)
        ? formatDataAllList(company_lists, 'exclude')
        : selectValue?.filter((i: any) => i.type === 'exclude')

      const includeData = isHaveAllOption('include', selectValue)
        ? formatDataAllList(company_lists, 'include')
        : selectValue?.filter((i: any) => i.type === 'include')

      const fetchData = async () => {
        const filterCopy = JSON.parse(JSON.stringify(filter))
        if (location.pathname === '/contacts') {
          delete filterCopy.companyExcludeList
          delete filterCopy.companyIncludeList
          _.set(filterCopy, 'contactsExcludeList', excludeData)
          _.set(filterCopy, 'contactsIncludeList', includeData)
        } else if (location.pathname === '/companies') {
          delete filterCopy.contactsList
          _.set(filterCopy, 'companyExcludeList', excludeData)
          _.set(filterCopy, 'companyIncludeList', includeData)
        }

        dispatch(SET_FILTER(removeEmpty(filterCopy)))
      }
      fetchData()
    }
  }, [selectValue, company_lists])

  useEffect(() => {
    if (selectCampaignValue) {
      const isHaveAllOption = (type: 'exclude' | 'include', list: any) =>
        list.find((item: any) => item.label === 'All' && item.type === type)

      const formatDataAllCampaign = (list: any, type: string) => {
        return list?.map((campaign: any) => ({
          key: campaign?.campaign_id,
          label: campaign?.campaign_name,
          type,
        }))
      }

      const excludeData = isHaveAllOption('exclude', selectCampaignValue)
        ? formatDataAllCampaign(campaignData, 'exclude')
        : selectCampaignValue?.filter((i: any) => i.type === 'exclude')

      const includeData = isHaveAllOption('include', selectCampaignValue)
        ? formatDataAllCampaign(campaignData, 'include')
        : selectCampaignValue?.filter((i: any) => i.type === 'include')

      const fetchData = async () => {
        const filterCopy = JSON.parse(JSON.stringify(filter))
        if (location.pathname === '/contacts') {
          delete filterCopy.companyExcludeCampaign
          delete filterCopy.companyIncludeCampaign
          _.set(filterCopy, 'contactsExcludeCampaign', excludeData)
          _.set(filterCopy, 'contactsIncludeCampaign', includeData)
        } else if (location.pathname === '/companies') {
          delete filterCopy.contactsCampaign
          _.set(filterCopy, 'companyExcludeCampaign', excludeData)
          _.set(filterCopy, 'companyIncludeCampaign', includeData)
        }

        dispatch(SET_FILTER(removeEmpty(filterCopy)))
      }
      fetchData()
    }
  }, [selectCampaignValue, campaignData])

  useEffect(() => {
    const hasExclude = selectValue.filter((item: any) => item.type === 'exclude')
    const hasInclude = selectValue.filter((item: any) => item.type === 'include')

    if (activeFilters.companyExcludeList || activeFilters.companyIncludeList) {
      if (!Object.keys(activeFilters).includes('companyExcludeList') && hasExclude?.length > 0) {
        setSelectValue((prev: any) => prev.filter((item: any) => item.type !== 'exclude'))
      }

      if (!Object.keys(activeFilters).includes('companyIncludeList') && hasInclude?.length > 0) {
        setSelectValue((prev: any) => prev.filter((item: any) => item.type !== 'include'))
      }
    }

    if (activeFilters.contactsExcludeList || activeFilters.contactsIncludeList) {
      if (!Object.keys(activeFilters).includes('contactsExcludeList') && hasExclude?.length > 0) {
        setSelectValue((prev: any) => prev.filter((item: any) => item.type !== 'exclude'))
      }

      if (!Object.keys(activeFilters).includes('contactsIncludeList') && hasInclude?.length > 0) {
        setSelectValue((prev: any) => prev.filter((item: any) => item.type !== 'include'))
      }
    }

    const hasExcludeCampaign = selectCampaignValue.filter((item: any) => item.type === 'exclude')
    const hasIncludeCampaign = selectCampaignValue.filter((item: any) => item.type === 'include')

    if (!Object.keys(activeFilters).includes('companyExcludeCampaign') && hasExcludeCampaign?.length > 0) {
      setSelectCampaignValue((prev: any) => prev.filter((item: any) => item.type !== 'exclude'))
    }

    if (!Object.keys(activeFilters).includes('companyIncludeCampaign') && hasIncludeCampaign?.length > 0) {
      setSelectCampaignValue((prev: any) => prev.filter((item: any) => item.type !== 'include'))
    }
  }, [activeFilters])

  const toggleExpand = (key: string) => {
    if (!item?.comingSoon) {
      if (item.type === 'sidelist') {
        dispatch(setTechSidebarOpen(!isTechSidebarOpen))
        dispatch(setTechSidebarType(key))
      }

      setExpand(!expand)
    }
  }

  const handleDeleteList = async (listId: string) => {
    const result = await refetchDeleteList({ id: listId })

    if (result) {
      let getLists = await refetchLists()

      if (getLists) dispatch(SET_LISTS(getLists.data))
      message.success(`List deleted successfully`)
    }
  }

  const handleRenameList = async (event: any) => {
    if (13 === event.keyCode) {
      let getList = company_lists.filter((element: any) => element.name === event.target.value)

      if (getList.length === 0) {
        const result = await refetchRenameList({ id: renameList, name: event.target.value })

        if (result) {
          let getLists = await refetchLists()

          if (getLists) dispatch(SET_LISTS(getLists.data))
          setRenameList('')
          message.success(`List renamed successfully`)
        }
      } else {
        setRenameList('')
        message.success(`We already have a list with the same name!`)
      }
    }
  }

  const handleAddNewItem = async (event: any) => {
    if (13 === event.keyCode) {
      if (injectionRegex.test(event.target.value)) {
        message.error(MESSAGE_ERROR.INVALID_LIST_NAME)
      } else {
        let checkItem = company_lists.find((item: any) => item.name === event.target.value)

        if (!checkItem) {
          let result = await refetchAddList({
            name: event.target.value,
            type: location.pathname === '/contacts' ? 2 : 1,
          })

          if (result?.error?.status === 470) {
            setOutOfCredits(true)
          } else {
            if (result) {
              let getLists = await refetchLists()

              if (getLists) dispatch(SET_LISTS(getLists.data))
              setAddToListInput(!addToListInput)
              message.success(`The list, '${event.target.value}' was created successfully.`)
            }
          }
        } else {
          message.success(`We already have a list with the same name!`)
        }
      }
    }
  }

  useEffect(() => {
    if (company_lists?.length === 0) {
      const fetchDataCompanyLists = async () => {
        const result = await refetchLists()

        if (result) dispatch(SET_LISTS(result.data))
      }

      fetchDataCompanyLists()
    }
  }, [])

  useEffect(() => {
    const getCampaigns = async () => {
      await getAllCampaigns()
    }
    getCampaigns()
  }, [])

  const boxShadow =
    location?.pathname === '/companies'
      ? item?.key === 'intents'
        ? '0px 4px 4px rgba(50, 50, 50, 0.2) inset'
        : item?.key === 'funding'
        ? '0px -4px 4px rgba(50, 50, 50, 0.2) inset'
        : ''
      : location?.pathname === '/contacts'
      ? item?.key === 'intents'
        ? '0px 4px 4px rgba(50, 50, 50, 0.2) inset'
        : item?.key === 'funding'
        ? '0px -4px 4px rgba(50, 50, 50, 0.2) inset'
        : ''
      : ''

  const tagRender: any = (props: any, campaign?: boolean) => {
    const { label, value, closable, onClose } = props
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault()
      event.stopPropagation()
    }
    const tag = campaign
      ? selectCampaignValue?.filter((item: any) => item.key === value)
      : selectValue?.filter((item: any) => item.key === value)
    const color = tag?.[0]?.type === 'exclude' ? 'red' : 'blue'
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}>
        {label}
      </Tag>
    )
  }
  return (
    <>
      <Row
        className={classNames(styles.item, expand && styles.expanded)}
        key={item.key}
        style={
          item.comingSoon
            ? {
                backgroundColor: '#f3f4f6',
                boxShadow: boxShadow,
                borderBottom: '1px solid #dfe1e6',
              }
            : {}
        }>
        <Tooltip
          placement='right'
          title={
            (isBetaOrTrialUser || item?.key === 'stages') &&
            item?.tooltip && (
              <div style={{ margin: '10p' }}>
                <div>
                  {item?.tooltip}
                  <span style={{ textDecoration: 'underline' }}>{item?.tooltipUnderline}</span>
                </div>
              </div>
            )
          }>
          <div
            className='flex cursor-pointer w-full'
            onClick={() => {
              toggleExpand(item.key)

              if (item.comingSoon) {
                post('/log', {
                  action: `${item.label}`,
                })
                item.comingSoon && setOpenContactSales(true)
              }
            }}>
            {item.image &&
              (typeof item.image === 'string' ? (
                <Col span={4}>
                  <img src={item.image} alt='' />
                </Col>
              ) : (
                <Col className={styles.pulse}>
                  <item.image />
                </Col>
              ))}
            {item.comingSoon && <LockComponent className={styles.soonTag} />}
            <Col span={item.image ? 16 : 20}>
              {item.beta === true ? (
                <span className={classNames(styles.itemLabel, item.className ? styles[item.className] : '')}>
                  {item.label}
                  <span
                    style={{
                      marginLeft: '5px',
                      backgroundColor: '#7043ff',
                      color: 'white',
                      borderRadius: '6px',
                      padding: '4px 6px',
                      fontWeight: 600,
                      fontSize: '12px',
                    }}>
                    BETA
                  </span>
                </span>
              ) : (
                <span className={classNames(styles.itemLabel, item.className ? styles[item.className] : '')}>
                  {item.label}
                </span>
              )}
              {item?.new && <NewTag />}
            </Col>
            <Col span={4}>
              <img
                className='pl-2 cursor-pointer'
                onClick={() => toggleExpand(item.key)}
                src={!expand ? arrow_down : arrow_up}
                alt={''}
              />
            </Col>
          </div>
        </Tooltip>

        {item.children && expand && item.type === 'expandList' && item.children.length > 0 && (
          <FancyFilterExpandItemBody data={item.children as IExpandListChildren[]} />
        )}
        {expand && item.type === 'expandGroupe' && (
          <div className='py-3 w-full flex flex-column gap-3'>
            {item?.children &&
              item?.children?.map((child: IExpandGroupeChildren | IExpandListChildren, index: number) => {
                if (child.label === 'Milestones') {
                  return <FancyFilterGroupe key={index} data={child as IExpandGroupeChildren} />
                }
                return (
                  <>
                    <FancyFilterExpandGroupe key={index} data={child as IExpandGroupeChildren} />
                  </>
                )
              })}
          </div>
        )}
        {/* {expand && item.type === 'companiesList' && (
          <>
            <div className={styles.selectedCompaniesExpanded}>
              <Swiper
                className='mx-0'
                modules={[]}
                navigation={{ nextEl: '.nextEl', prevEl: '.prevEl' }}
                slidesPerView={'auto'}>
                <SwiperSlide className='w-auto'>
                  <CustomTag
                    className='h-2rem w-min'
                    closable
                    onClose={() => {
                      dispatch(
                        SET_SELECTED_COMPANIES_DETAILS(
                          selected_companies_details.map((obj: any) => {
                            return { ...obj, selected: false }
                          }),
                        ),
                      )
                    }}>
                    {' '}
                    {`(${activeCompanies})`}
                  </CustomTag>
                </SwiperSlide>
              </Swiper>
            </div>
            { {selected_companies_details?.map((company: any) => {
              return (
                <div
                  className={classNames(styles.companyItem, !company.selected ? styles.unselectedCompany : '')}
                  key={company.id}>
                  <span className={styles.label}>{company.name}</span>
                  <img
                    className={classNames(styles.close, !company.selected ? styles.unselected : '')}
                    src={close_company}
                    alt=''
                    onClick={() => {
                      handleActiveCompany(company.id)
                    }}
                  />
                </div>
              )
            })} }
          </>
        )} */}
        {!expand && item.type === 'companiesList' && (
          <div className={styles.selectedCompanies}>
            <Swiper
              className='mx-0'
              modules={[]}
              navigation={{ nextEl: '.nextEl', prevEl: '.prevEl' }}
              slidesPerView={'auto'}>
              <SwiperSlide className='w-auto'>
                <CustomTag
                  className='h-2rem w-min'
                  closable
                  onClose={() => {
                    dispatch(
                      SET_SELECTED_COMPANIES_DETAILS(
                        selected_companies_details.map((obj: any) => {
                          return { ...obj, selected: false }
                        }),
                      ),
                    )
                  }}>
                  {' '}
                  {`(${activeCompanies})`}
                </CustomTag>
              </SwiperSlide>
            </Swiper>
          </div>
        )}
        {expand && item.type === 'listsFilter' && (
          <div className='py-3 w-full flex flex-column gap-3'>
            {company_lists.length === 0 && (
              <div className={styles.emptyLists}>
                <img src={large_lists} alt='' />
                <p>Create Lists</p>
                <span>
                  To create a list select your companies or contacts of interest and at the bottom of the screen click{' '}
                  <span style={{ fontWeight: 600 }}>{'Actions -> Add to list'}</span>
                </span>
              </div>
            )}
            {company_lists.map((item: any) => {
              return renameList === item.id ? (
                <Input
                  placeholder=''
                  className='renameListInput'
                  onKeyDown={handleRenameList}
                  defaultValue={item.name}
                />
              ) : (
                <>
                  <div
                    className={classNames('filterListItem', selectedList?.id === item.id ? 'selectedListItem' : '')}
                    onClick={() => {
                      let expires = new Date()
                      expires.setTime(expires.getTime() * 1000)
                      setSelectedList(item)
                      if (renameList !== '') setRenameList('')
                      setListNameCookies('list_name', item.name, { path: '/', expires })
                    }}>
                    {item.name.includes('twitter_mentions_list') ? (
                      <img src={stars} alt={''} style={{ width: '20px', height: '20px' }} />
                    ) : (
                      <img src={Number(item?.type) === 1 ? company_list : contact_list} alt={''} />
                    )}
                    <Tooltip placement='topLeft' title={item.name}>
                      <span className={classNames('textTooltipLists')}>{item.name}</span>
                    </Tooltip>
                    <Popover
                      placement='right'
                      title={''}
                      content={
                        <div className='listOptions'>
                          <span
                            className='removePadding'
                            onClick={() => {
                              setRenameList(item.id)
                            }}>
                            Rename List
                          </span>
                          <Divider />
                          <span className='removePadding'>
                            <Popconfirm
                              title={ConfirmBox.DELETE_LIST}
                              description={
                                <Col>
                                  <div>{`${ConfirmBox.DELETE_LIST_ARE_YOU_SURE}`}</div>
                                </Col>
                              }
                              onConfirm={() => {
                                handleDeleteList(item.id)
                              }}
                              onCancel={() => {
                                message.error('You have left this action!')
                              }}
                              okText='Yes'
                              cancelText='No'>
                              Delete List
                            </Popconfirm>
                          </span>
                        </div>
                      }
                      trigger='click'>
                      <span className='moreOptions'>...</span>
                    </Popover>
                  </div>
                </>
              )
            })}
            {addToListInput && (
              <div className={classNames('addNewContainer', 'addNewContainerNoMargin')}>
                <Input placeholder='' className={classNames('addNewInput')} onKeyDown={handleAddNewItem} />
              </div>
            )}
            {/* <span
              className={classNames('createANewItem', 'createNewItemNoMargin')}
              onClick={() => {
                setAddToListInput(!addToListInput)
              }}>
              + Create New
            </span> */}
          </div>
        )}
        {expand && item.type === 'stagesFilter' && (
          <div className={styles.checkboxes}>
            <Checkbox
              defaultChecked={Object.keys(activeFilters).includes('excludeOutreachedContacts')}
              onChange={handleExcludeContacted}
              style={{ color: '#5e6c84' }}
              disabled={isBetaOrTrialUser}>
              <div className={styles.comingSoonTag}>
                <span>Exclude Contacted Prospects</span>
              </div>
            </Checkbox>
            <br />
            <Checkbox
              defaultChecked={Object.keys(activeFilters).includes('excludeExposedContacts')}
              onChange={handleExcludeExposed}
              style={{ color: '#5e6c84' }}
              disabled={isBetaOrTrialUser}>
              <div className={styles.comingSoonTag}>
                <span>Exclude Exposed Prospects</span>
              </div>
            </Checkbox>
            <br />
            <Checkbox disabled>
              <div className={styles.comingSoonTag}>
                <span>Exclude My Customers </span>
                <LockComponent style={{ marginLeft: '6px' }} />
              </div>
            </Checkbox>
          </div>
        )}

        {expand && item.type === 'excludeListsFilter' && (
          <div
            className='w-full flex flex-column'
            style={{
              gap: '0.6rem',
              backgroundColor: 'white',
              padding: '10px 6px',
              borderRadius: '8px',
              marginTop: '10px',
              outline: '1px solid var(--color-white-04)',
            }}>
            {company_lists.length === 0 && (
              <div className={styles.emptyLists}>
                <img src={large_lists} alt='' />
                <p>Create Lists</p>
                <span>
                  To create a list select your companies or contacts of interest and at the bottom of the screen click{' '}
                  <span style={{ fontWeight: 600 }}>{'Actions -> Add to list'}</span>
                </span>
              </div>
            )}
            {/* <CustomMultiSelect options={company_lists} selectValue={selectValue} setSelectValue={setSelectValue} /> */}
            {company_lists?.length > 0 && (
              <>
                <span style={{ color: '#5E6C84', marginLeft: '2px' }}>Include / Exclude Lists</span>
                <Select
                  mode='multiple'
                  className='excludeIncludeSelect'
                  placeholder='Select Lists'
                  optionLabelProp='key'
                  // value={_.get(filter, item.key)}
                  value={selectValue}
                  tagRender={tagRender}
                  onDeselect={(e) => handleDeselect(e)}
                  showSearch>
                  {[...company_lists, allOptionList].map((option: any) => {
                    return (
                      <Select.Option className='excludeIncludeSelect' key={option.id} value={option.name}>
                        <div className={styles.customSelect}>
                          <Tooltip title={option.name}>
                            <span>
                              {option.name?.length > 15 ? `${option.name.slice(0, 15)?.trim()}...` : option.name}
                            </span>
                          </Tooltip>
                          <div className={`${styles.excludeInclude}`}>
                            <Tooltip title='Exclude'>
                              <span
                                className={`${styles.exclude} insideSpan`}
                                onClick={() => {
                                  handleExcClick(option)
                                }}>
                                Exc
                              </span>
                            </Tooltip>
                            <Divider type='vertical' />
                            <Tooltip title='Include'>
                              <span
                                className={`${styles.include} insideSpan`}
                                onClick={() => {
                                  handleIncClick(option)
                                }}>
                                Inc
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      </Select.Option>
                    )
                  })}
                </Select>
              </>
            )}
            {!item?.isNoCampaignOnExclude && campaignData?.length > 0 && (
              <>
                {/* <div /> */}
                <span style={{ color: '#5E6C84', marginLeft: '2px' }}>Include / Exclude Campaigns</span>
                <Select
                  mode='multiple'
                  className='excludeIncludeSelect'
                  placeholder='Select Campaigns'
                  optionLabelProp='key'
                  // value={_.get(filter, item.key)}
                  value={selectCampaignValue}
                  tagRender={(e) => tagRender(e, true)}
                  onDeselect={(e) => handleDeselect(e, true)}
                  showSearch>
                  {[...campaignData, allOptionCampaign].map((option: any) => (
                    <Select.Option
                      className='excludeIncludeSelect'
                      key={option.campaign_id}
                      value={option.campaign_name}>
                      <div className={styles.customSelect}>
                        <Tooltip title={option.campaign_name}>
                          <span>
                            {option.campaign_name?.length > 15
                              ? `${option.campaign_name.slice(0, 15)?.trim()}...`
                              : option.campaign_name}
                          </span>
                        </Tooltip>
                        <div className={`${styles.excludeInclude}`}>
                          <Tooltip title='Exclude'>
                            <span
                              className={`${styles.exclude} insideSpan`}
                              onClick={() => {
                                handleExcClick(option, true)
                              }}>
                              Exc
                            </span>
                          </Tooltip>
                          <Divider type='vertical' />
                          <Tooltip title='Include'>
                            <span
                              className={`${styles.include} insideSpan`}
                              onClick={() => {
                                handleIncClick(option, true)
                              }}>
                              Inc
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </>
            )}
            {/* <Select
              // size='large'
              placeholder='Select From Lists'
              optionLabelProp='key'
              onClick={(e) => e.stopPropagation()}
              // defaultValue={edit_mode ? runInBetweenId : null}
            >
              {company_lists.map((option: any) => (
                <Select.Option key={option.name} value={option.name} onClick={(e: any) => e.preventDefault()}>
                  <div className={styles.customSelect}>
                    <Tooltip title={option.name}>
                      <span>{option.name?.length > 10 ? `${option.name.slice(0, 10)?.trim()}...` : option.name}</span>
                    </Tooltip>
                    <div className={styles.excludeInclude}>
                      <span className={styles.exclude}>Exc</span>
                      <Divider type='vertical' />
                      <span className={styles.include}>Inc</span>
                    </div>
                  </div>
                </Select.Option>
              ))}
            </Select> */}
            {/* {company_lists.map((item: any) => {
              return (
                <Tooltip placement='topLeft' title={item.name}>
                  <div
                    className={classNames(
                      styles.filterListItem,
                      selectedExcludeList?.id === item.id ? 'selectedListItem' : '',
                    )}
                    onClick={() => {
                      setSelectedExcludeList(item)
                    }}>
                    <img src={Number(item?.type) === 1 ? company_list : contact_list} alt={''} />
                    <span className={classNames('textTooltipLists')}>{item.name}</span>
                    <div className={styles.excludeInclude}>
                      <span>Exc</span>
                      <Divider type='vertical' />
                      <span>Inc</span>
                    </div>
                  </div>
                </Tooltip>
              )
            })} */}
          </div>
        )}
        {expand && item.component && item.component}
      </Row>
      <Modal
        open={openContactSales}
        onCancel={() => setOpenContactSales(false)}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}
        width={600}
        bodyStyle={{
          height: 350,
        }}>
        <div className='flex justify-content-center'>
          <BlurPaywallFilters
            addon={item.key}
            onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
            component={[]}
            customClass='contactSalesNoMargin'
          />
        </div>
      </Modal>

      {outOfCredits && (
        <OutOfCreditsModal
          open={outOfCredits}
          onClose={() => {
            setOutOfCredits(false)
          }}
          creditType={'list'}
          location='lists'
        />
      )}
    </>
  )
}

export default FancyFilterExpandItem
