function generateStringFromArr(data: string) {
  let verticalTxt = ''
  if (data) {
    JSON.parse(data).length > 0 &&
      JSON.parse(data).map((element: string, index: number) => {
        verticalTxt += element
        if (index < JSON.parse(data).length - 1) {
          verticalTxt += ', '
        }
        return verticalTxt
      })
  }

  return verticalTxt
}

function convertToK(data: string) {
  if (data !== '0') {
    if (Number(data) > 1000000) {
      return (Number(data) / 1000000).toFixed(1) + 'M'
    } else {
      return (Number(data) / 1000).toFixed(1) + 'k'
    }
  } else {
    return '0'
  }
}

function renderToFilter(key: string, data: any) {}

function dataGraph(data: any) {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sepr', 'Oct', 'Nov', 'Dec']
  const monthOrder: any = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  }
  const dataGraph: any = []
  data?.length > 0 &&
    data?.forEach((element: any) => {
      let str = element.deployement_month
      let d = new Date(str)
      let m = d.getMonth()
      let y = d.getFullYear()

      const foundObject = dataGraph.find((obj: any) => obj.key === monthNames[m - 1])
      if (foundObject) {
        foundObject.pv += Number(element.smartcontracts_deployed)
      } else {
        const newObject = { name: monthNames[m - 1] + ' ' + y + ' ', pv: Number(element.smartcontracts_deployed) }
        dataGraph.push(newObject)
      }
    })
  dataGraph.sort((a: any, b: any) => {
    const monthA = monthOrder[a.name]
    const monthB = monthOrder[b.name]
    return monthA - monthB
  })
  return dataGraph
}

export const convertNumber = (value: number) => {
  if (value >= 1000000000) {
    return (value / 1000000000).toFixed(2) + 'B'
  } else if (value >= 1000000) {
    return (value / 1000000).toFixed(2) + 'M'
  } else if (value >= 1000) {
    return (value / 1000).toFixed(2) + 'k'
  } else {
    return value.toString()
  }
}

export const convertToDate = (data: string) => {
  let dateString = data.split('T')[0]
  return new Date(dateString).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
}

export const getFirstThreeDifferentObjects = (arr: any) => {
  const result: any = []
  const seen: Set<string> = new Set()

  for (const obj of arr) {
    const objString = JSON.stringify(obj)
    if (!seen.has(objString)) {
      seen.add(objString)
      if (!result.some((element: any) => element['chain'] === obj.chain)) result.push(obj)
      if (result.length === 3) {
        break
      }
    }
  }

  return result
}

const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export const addColorCode = (arr: any) => {
  const result: any = []
  const seen: Set<string> = new Set()

  for (const obj of arr) {
    obj.color = getRandomColor()
    const objString = JSON.stringify(obj)
    if (!seen.has(objString)) {
      seen.add(objString)
      if (!result.some((element: any) => element['chain'] === obj.chain)) result.push(obj)
    }
  }

  return result
}

export const handleTraficSourcesImage = (name: string) => {
  let imgPath = '/svg/ts_displaysads.svg'
  if (name.indexOf('Direct') >= 0) {
    imgPath = '/svg/ts_direct.svg'
  } else if (name.indexOf('Search') >= 0) {
    imgPath = '/svg/ts_search.svg'
  } else if (name.indexOf('Referrals') >= 0) {
    imgPath = '/svg/ts_refferals.svg'
  } else if (name.indexOf('Mail') >= 0) {
    imgPath = '/svg/ts_email.svg'
  } else if (name.indexOf('Social') >= 0) {
    imgPath = '/svg/ts_social.svg'
  }
  return imgPath
}

export const handleBarCollor = (index: number, numberOfItems: number) => {
  let color = '#50E2BA'
  if (index % 7 === 0) {
    color = '#50E2BA'
  } else if (index % 7 === 1) {
    color = '#7043FF'
  } else if (index % 7 === 2) {
    color = '#FF4FE6'
  } else if (index % 7 === 3) {
    color = '#85E2F7'
  } else if (index % 7 === 4) {
    color = '#A44EF8'
  } else if (index % 7 === 5) {
    color = '#9196F8'
  } else if (index % 7 === 6) {
    color = '#5FBCF8'
  }
  return color
}

export const groupByMonth = (arr: any) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const groupedData = arr.reduce((result: any, item: any) => {
    const date = new Date(item.eventdate)
    const monthKey = `${monthNames[date.getMonth() - 1]} ${date.getFullYear()}`

    if (!result[monthKey]) {
      result[monthKey] = []
    }

    result[monthKey].push(item)
    return result
  }, {})

  return groupedData
}

export const groupByDay = (arr: any) => {
  const groupedData = arr.reduce((result: any, item: any) => {
    const date = item.eventdate
    const dayKey = `${Number(date.split('-')[2])}`

    if (!result[dayKey]) {
      result[dayKey] = []
    }

    result[dayKey].push(item)
    return result
  }, {})

  return groupedData
}

export const sortByDateAscending = (a: any, b: any): number => {
  const dateA = new Date(a.eventdate)
  const dateB = new Date(b.eventdate)
  return dateA.getTime() - dateB.getTime()
}

export const filterByDistinctField = (arr: any[], field: keyof any): any[] => {
  const uniqueValues = new Set<string>()
  return arr.filter((item) => {
    if (!uniqueValues.has(item[field])) {
      uniqueValues.add(item[field])
      return true
    }
    return false
  })
}

export const getRandomElementFromArray = (arr: any) => {
  if (!Array.isArray(arr) || arr.length === 0) {
    return null
  }

  const randomIndex = Math.floor(Math.random() * arr.length)
  return arr[randomIndex]
}

export const handleKeyName = (key: string) => {
  switch (key) {
    case 'email':
      return `"Email"`
    case 'phone_numbers':
      return `"Phone"`
    case 'telegram_account':
      return `"Telegram"`
    case 'discord':
      return `"Discord"`
    default:
      break
  }
}

export const CommonServices = {
  generateStringFromArr,
  convertToK,
  renderToFilter,
  dataGraph,
  convertNumber,
  convertToDate,
  getFirstThreeDifferentObjects,
  addColorCode,
  handleTraficSourcesImage,
  handleBarCollor,
  groupByMonth,
  groupByDay,
  sortByDateAscending,
  filterByDistinctField,
  getRandomElementFromArray,
  handleKeyName,
}
