import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useNavigate, useLocation } from 'react-router-dom'
import { Form, Input, Button, Typography } from 'antd'
import VideoBg from '../../common/components/video-bg/video-bg'
import { AuthService } from 'common/services/authServices'
import { Buttons, TilePage } from 'common/constants/label.constants'
import convrtSignup from '../../common/assets/svg/convrtSignup.svg'

import styles from './sign-up.module.scss'

const { Title } = Typography
const ResetPasswordPage: React.FC = () => {
  const navigate = useNavigate()
  const [errorSignup, setErrorSignup] = useState('')
  const [isSuccessfully, setIsSuccessfully] = useState(false)
  const [isAllowed, setIsAllowed] = useState(false)

  const location = useLocation()

  const queryString = location.search.slice(1)
  const [, value] = queryString.split('=')

  useEffect(() => {
    const getIsAllowedResetPassword = async () => {
      const result = await AuthService.checkAllowedResetPassword({ email: value })
      setIsAllowed(result.is_allowed)
    }
    getIsAllowedResetPassword()
  }, [value])

  const emailFromState = value
  const formik = useFormik({
    initialValues: {
      email: emailFromState,
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: async (values) => {
      const result = await AuthService.changePassword(values)
      setIsSuccessfully(result.code === 201 ? true : false)
      if (result.code === 201) {
        setIsSuccessfully(true)
        navigate('/login')
      } else {
        setIsSuccessfully(false)
      }
      if (result.code === 'ERR_BAD_REQUEST') {
        setErrorSignup(result.response.data.message)
      } else {
        setErrorSignup(result.message)
      }
    },
  })
  return (
    <>
      <VideoBg srcType={0} />

      <div id='signup-container' className={styles.signupContainer}>
        <div className={styles.allowedPageParent}>
          <img src={convrtSignup} alt='' className={styles.logo} />
          {!isAllowed ? (
            <>
              <h2 className={styles.allowedPage} style={{ color: '#ffffff' }}>
                {'Link expired'}
              </h2>
              <Button
                className={styles.allowedPageButton}
                type='primary'
                onClick={() => {
                  navigate('/login')
                }}>
                {'Login Page'}
              </Button>
            </>
          ) : (
            <>
              <div className={styles.form}>
                <Form onFinish={formik.handleSubmit}>
                  <Title level={2}>{TilePage.RESET_PASSWORD}</Title>
                  <Form.Item
                    name='email'
                    rules={[
                      {
                        type: 'email',
                        message: 'Invalid email address',
                      },
                    ]}>
                    <Input
                      {...formik.getFieldProps('email')}
                      placeholder={emailFromState}
                      readOnly={true}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item
                    name='newPassword'
                    rules={[
                      { required: true, message: 'New password is required' },
                      { min: 8, message: 'Password must be at least 8 characters' },
                    ]}>
                    <Input.Password
                      {...formik.getFieldProps('newPassword')}
                      placeholder='New password'
                      className='password'
                    />
                  </Form.Item>
                  <Form.Item
                    name='confirmPassword'
                    rules={[
                      { required: true, message: 'Confirm your password' },
                      { min: 8, message: 'Password must be at least 8 characters' },
                    ]}>
                    <Input.Password
                      {...formik.getFieldProps('confirmPassword')}
                      placeholder='Confirm new password'
                      className='password'
                    />
                  </Form.Item>

                  {errorSignup !== '' ? <p className={styles.error}>{errorSignup}</p> : null}
                  {isSuccessfully && <p className={styles.succeeded}>{'Sign Up successfully!'}</p>}
                  <Form.Item>
                    <Button type='primary' htmlType='submit' className={styles.btnSignup}>
                      {Buttons.RESET_PASSWORD}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              <p className={styles.bottom_title}>Join industry leaders using Convrt.</p>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ResetPasswordPage
