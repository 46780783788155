import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import './FilterBar.scss'
import { Button, Divider } from 'antd'

import { useAppDispatch, useAppSelector } from 'state'
// import { setReset, setSidebarOpen } from 'state/filtersSlice/filtersSlice'
import { setReset } from 'state/filtersSlice/filtersSlice'
import { SelectComponent } from 'common/components/SelectComponent'

import {
  SET_FILTER,
  SET_SAVE_FILTER_MODAL,
  SET_IS_FILTER_CHANGED,
  SET_LAST_ACTION_PAGE,
  SET_DELETE_COMPANY_SELECTED_ROWS,
} from 'features/intent-signals/state/slice/companiesSlice'
import { SET_SELECTED_COMPANIES_DETAILS } from '../../../features/intent-signals/state/slice/companiesSlice'
import {
  SET_LAST_ACTION_PAGE_CONTACT,
  SET_DELETE_CONTACTS_SELECTED_ROWS,
  SET_CURRENT_ORDER_BY,
} from '../../../features/contacts/state/slice/contactsSlice'

// import { ReactComponent as Funnel } from 'common/assets/svg/FunnelSimple.svg'
import { useActiveFilters } from 'common/hooks/useFilters'
import { IFilterBar } from 'common/interfaces/IFilters'
import { objectToValues, removePropertyByLeaf } from 'common/utils/objectToValues'
import { useGetEventMutation } from '../../../features/events/state/api/EventsApi'
import Tag from './Tag'
import { CloseOutlined } from '@ant-design/icons'

const pageLookUp = {
  home: 'Home',
  events: 'Events',
  contacts: 'People',
  outreach: 'Outreach',
  companies: 'Research',
  settings: 'Profile Settings',
  company_admin: 'Company Admin Panel',
}

interface IFilterBarComponent {
  emptyMessage: string
  deactivate?: boolean
  showSort?: boolean
}

export const FilterBar = ({ emptyMessage, deactivate, showSort }: IFilterBarComponent) => {
  const locationPathName = (useLocation().pathname.split('/')[1] as keyof typeof pageLookUp) || 'home'

  const [eventName, setEventName] = useState<string>('')
  const [refetchGetEvent] = useGetEventMutation()
  // const { isSidebarOpen } = useAppSelector((state) => state.filters)
  const dispatch = useAppDispatch()
  const [activeFilters, setActiveFilters] = useActiveFilters()
  const filterTags = objectToValues(activeFilters)

  const { selected_companies, is_filter_changed } = useAppSelector((state) => state.companies)
  const { selected_contacts, selected_contacts_in_outreach, my_contact_current_order_by } = useAppSelector(
    (state) => state.contacts,
  )
  const location = useLocation()
  // const handleFilterClick = () => {
  //   dispatch(setSidebarOpen(true))
  // }
  const setSortOption = (value: any) => dispatch(SET_CURRENT_ORDER_BY(value))

  const handleOrderBy = (value: string) => {
    setSortOption(value)
  }

  const handleRemoveFilters = (filterName: string, isExcluded?: boolean) => {
    dispatch(SET_FILTER({}))

    dispatch(SET_IS_FILTER_CHANGED(true))
    dispatch(SET_LAST_ACTION_PAGE(1))
    dispatch(SET_LAST_ACTION_PAGE_CONTACT(1))

    // if (isExcluded !== undefined) {
    //   const tempFilters = activeFilters
    //   for (const key in tempFilters) {
    //     if (typeof tempFilters[key] === 'object' && tempFilters[key] !== null) {
    //       for (const innerKey in tempFilters[key]) {
    //         if (innerKey === filterName) {
    //           Object.entries(tempFilters[key][innerKey]).forEach((entry: any) => {
    //             if (entry[1].excluded === isExcluded) delete tempFilters[key][innerKey][entry[0]]
    //           })
    //         }
    //       }
    //     }
    //   }

    //   setActiveFilters(tempFilters)
    //   return
    // }

    const newFilters = removePropertyByLeaf(activeFilters, filterName)

    if ('searchBarContact' in newFilters)
      newFilters.searchBarContact = newFilters.searchBarContact.filter((x: any) => !!x)

    setActiveFilters(newFilters)
    if (filterName === 'companiesName') dispatch(SET_SELECTED_COMPANIES_DETAILS([]))
  }

  useEffect(() => {
    if (!is_filter_changed || Object.values(activeFilters).length === 0) {
      dispatch(setReset(true))
    }
  }, [activeFilters])

  useEffect(() => {
    const fetchData = async () => {
      Object.values(filterTags).forEach(async (tags: any) => {
        for (const obj of tags) {
          if (obj['key'] === 'events' && eventName === '') {
            let result = await refetchGetEvent(obj['value'])
            if (result?.data?.response) {
              setEventName(result?.data?.response.name)
            }
          }
        }
      })
    }
    fetchData().catch(console.error)
  }, [])

  const handleResetFilters = () => {
    dispatch(setReset(true))
    dispatch(SET_FILTER({}))
    dispatch(SET_IS_FILTER_CHANGED(true))
    dispatch(SET_LAST_ACTION_PAGE(1))
    dispatch(SET_LAST_ACTION_PAGE_CONTACT(1))

    setActiveFilters({})
  }

  return (
    <div className='flex gap-3 align-items-center justify-content-between'>
      <div className='c-filters'>
        <div className='flex gap-3 align-items-center'>
          {/* {!isSidebarOpen ||
          (!isSidebarOpen && locationPathName !== 'outreach' && (
            <Button type='primary' onClick={handleFilterClick}>
              <div className='c-filters__primary-btn'>
                <Funnel className='c-filters__primary-btn-funnel' />
                <span>{'Filters'}</span>
              </div>
            </Button>
          ))} */}
          <span className='c-filters__selected-items-text'>
            {selected_companies.length > 0 && location.pathname.indexOf('/companies') >= 0
              ? `${selected_companies.length} Selected`
              : selected_contacts.length > 0 && location.pathname.indexOf('/contacts') >= 0
              ? `${selected_contacts.length} Selected`
              : selected_contacts_in_outreach.length > 0 && location.pathname.indexOf('/exposedContacts') >= 0
              ? `${selected_contacts_in_outreach.length} Selected`
              : '0 Selected'}

            {(selected_companies.length > 0 && location.pathname.indexOf('/companies') >= 0) ||
            (selected_contacts.length > 0 && location.pathname.indexOf('/contacts') >= 0) ||
            (selected_contacts_in_outreach.length > 0 && location.pathname.indexOf('/exposedContacts') >= 0) ? (
              <span
                className='test'
                onClick={() => {
                  if (location.pathname.indexOf('/companies') >= 0) {
                    dispatch(SET_DELETE_COMPANY_SELECTED_ROWS(true))
                  }
                  if (
                    location.pathname.indexOf('/contacts') >= 0 ||
                    location.pathname.indexOf('/exposedContacts') >= 0
                  ) {
                    dispatch(SET_DELETE_CONTACTS_SELECTED_ROWS(true))
                  }
                }}>
                {' '}
                {/* <img src={closeDrawer} alt={'Close'} style={{ position: 'relative', top: '7px', cursor: 'pointer' }} /> */}
                {/* <span style={{ color: '#7043ff', cursor: 'pointer', fontWeight: 600 }}>Clear</span> */}
                <CloseOutlined
                  style={{ color: 'var(--color-gray-02)', fontSize: '16px', margin: '0px 3px', cursor: 'pointer' }}
                />
                <Divider type='vertical' style={{ fontSize: '18px' }} />
              </span>
            ) : (
              <>
                <Divider type='vertical' style={{ fontSize: '18px' }} />
              </>
            )}
          </span>
        </div>

        {(locationPathName === 'contacts' || locationPathName === 'companies') &&
          Object.values(filterTags).some((tag: any) => tag.length > 0) && (
            <>
              <div className='c-filters-saveFiltersBtn'>
                <Button
                  className='text-save-filters-btn '
                  type='text'
                  onClick={() => {
                    dispatch(
                      SET_SAVE_FILTER_MODAL({
                        open_modal: true,
                        filters: [activeFilters],
                        open_from: 'filterBar',
                        isSaved: false,
                      }),
                    )
                  }}>
                  {'Save Filters'}
                </Button>
              </div>
              <div className='c-filters-clearFiltersBtn'>
                <Button className='blue-text-clear-filters-btn ' type='text' onClick={handleResetFilters}>
                  {'Clear Filters'}
                </Button>
              </div>
            </>
          )}

        {(locationPathName === 'contacts' || locationPathName === 'companies') &&
          Object.values(filterTags).some((tag: any) => tag.length > 0) && (
            <div className='c-filters-clearFiltersBtn'>
              <Button className='blue-text-clear-filters-btn ' type='text' onClick={handleResetFilters}>
                {'Clear Filters'}
              </Button>
            </div>
          )}
        {(locationPathName === 'contacts' || locationPathName === 'companies') &&
          Object.values(filterTags).some((tag: any) => tag.length > 0 && tag.some((t: any) => t?.key !== 'circle')) && (
            <div className='flex gap-2 align-items-center'>
              <BsChevronLeft className='prevEl' />
              <Swiper
                className='mx-0'
                modules={[Navigation]}
                navigation={{ nextEl: '.nextEl', prevEl: '.prevEl' }}
                slidesPerView={'auto'}>
                {filterTags?.excluded.map((tag: IFilterBar, index: number) => (
                  <SwiperSlide key={index} className='w-auto'>
                    <Tag
                      excluded={true}
                      tag={tag}
                      eventName={eventName}
                      handleRemoveFilters={() => handleRemoveFilters(tag.key, true)}
                    />
                  </SwiperSlide>
                ))}
                {filterTags?.included.map((tag: IFilterBar, index: number) => (
                  <SwiperSlide key={index} className='w-auto'>
                    <Tag
                      tag={tag}
                      eventName={eventName}
                      handleRemoveFilters={() => handleRemoveFilters(tag.key, false)}
                    />
                  </SwiperSlide>
                ))}
                {filterTags?.natural
                  .filter((tag: any) => tag.key !== 'circle')
                  .map((tag: IFilterBar, index: number) => (
                    <SwiperSlide key={index} className='w-auto'>
                      <Tag tag={tag} eventName={eventName} handleRemoveFilters={() => handleRemoveFilters(tag.key)} />
                    </SwiperSlide>
                  ))}
              </Swiper>
              <BsChevronRight className='nextEl' />
            </div>
          )}
      </div>
      {showSort && (
        <div className='c-filters'>
          <SelectComponent
            prefixText='Sort By'
            defaultValue=''
            width='200px'
            height='32px'
            options={[
              { label: 'Default', value: '' },
              { label: 'Companies (A to Z)', value: 'company_name,ASC' },
              { label: 'Companies (Z to A)', value: 'company_name,DESC' },
            ]}
            value={my_contact_current_order_by}
            onChange={(value: string) => {
              handleOrderBy(value)
            }}
          />
        </div>
      )}
    </div>
  )
}
