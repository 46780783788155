import React, { useState } from 'react'
import { Col, Row, Tooltip } from 'antd'

import dislikeActive from 'common/assets/svg/dislikeActive.svg'
import dislikeInactive from 'common/assets/svg/dislikeInactive.svg'
import likeActive from 'common/assets/svg/likeActive.svg'
import likeInactive from 'common/assets/svg/likeInactive.svg'
import notDataActive from 'common/assets/svg/notDataActive.svg'
import notDataInactive from 'common/assets/svg/notDataInactive.svg'
import successRate from 'common/assets/svg/successRate.svg'
import { DrawerLebels } from 'common/constants/label.constants'
import { useGetRateDataMutation } from 'features/intent-signals/state/api/CompaniesApi'

interface IRateData {
  company_id: number
  section: string
}

const RateData: React.FC<IRateData> = ({ company_id, section }) => {
  const [refetchRateData] = useGetRateDataMutation()

  const [message, setMessage] = useState('')
  const [showRateTooltip, setShowRateTooltip] = useState(false)
  const [lightModeLike, setLightModeLike] = useState(false)
  const [lightModeDislike, setLightModeDislike] = useState(false)
  const [lightModeNoData, setLightModeNoData] = useState(false)

  const handleRate = async (rate_type: string) => {
    let rateData = { company_id: company_id, section: section, rate_type: rate_type }
    const result = await refetchRateData(rateData)
    if (result) {
      setMessage(result.data.messages)
      setTimeout(() => {
        setShowRateTooltip(false)
        setMessage('')
      }, 3000)
    }
  }

  return (
    <div className='customTooltip'>
      <Tooltip
        trigger='click'
        open={showRateTooltip}
        onOpenChange={() => setShowRateTooltip((prev) => !prev)}
        color={'#fff'}
        placement='bottomRight'
        title={
          <>
            {message !== '' ? (
              <span className='rateSuccess'>
                <img src={successRate} alt={''} />
                {message}
              </span>
            ) : (
              <Row gutter={12}>
                <Col span={8}>
                  <Tooltip title={'Bad Data!'}>
                    <img
                      className='rateImg'
                      src={lightModeDislike ? dislikeActive : dislikeInactive}
                      alt={''}
                      onClick={() => {
                        setLightModeLike(false)
                        setLightModeNoData(false)
                        setLightModeDislike(!lightModeDislike)
                        !lightModeDislike && handleRate('dislike')
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col span={8}>
                  <Tooltip title={'Good Data!'}>
                    <img
                      className='rateImg'
                      src={lightModeLike ? likeActive : likeInactive}
                      alt={''}
                      onClick={() => {
                        setLightModeDislike(false)
                        setLightModeLike(!lightModeLike)
                        setLightModeNoData(false)
                        !lightModeLike && handleRate('like')
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col span={8}>
                  <Tooltip title={'Missing Data!'}>
                    <img
                      className='rateImg'
                      src={lightModeNoData ? notDataActive : notDataInactive}
                      alt={''}
                      onClick={() => {
                        setLightModeDislike(false)
                        setLightModeLike(false)
                        setLightModeNoData(!lightModeNoData)
                        !lightModeNoData && handleRate('notData')
                      }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            )}
          </>
        }>
        <span
          className={'rateData'}
          onClick={() => {
            setLightModeDislike(false)
            setLightModeLike(false)
            setLightModeNoData(false)
          }}>
          {DrawerLebels.RATE_DATA}
        </span>
      </Tooltip>
    </div>
  )
}

export default RateData
