import React from 'react'
import _ from 'lodash'
import { Col, Row, Select, Checkbox } from 'antd'

import FancyFilterExpandItemTabs from 'common/components/fancy-filter-expand-item-tabs/fancy-filter-expand-item-tabs'

import { SET_FILTER } from 'features/intent-signals/state/slice/companiesSlice'

import { useAppDispatch, useAppSelector } from 'state/hooks'

import { IExpandListChildren, IFilterOption } from 'common/interfaces/IFilters'
import { removeEmpty } from 'common/utils/objectToValues'
import OptionsTree from '../OptionsTree/OptionsTree'
import styles from './fancy-filter-expand-item-body.module.scss'
import NewTag from '../NewTag/NewTag'
import FancyFilterExpandItemTabsSelect from '../fancy-filter-expand-item-tabs-select/fancy-filter-expand-item-tabs-select'
import FancyFilterExpandItemTabsCheckbox from '../fancy-filter-expand-item-tabs-checkbox/fancy-filter-expand-item-tabs-checkbox'
import Range from '../Range/Range'

interface IFancyFilterExpandItemBody {
  data: IExpandListChildren[]
}

type FilterFunc<OptionType> = (inputValue: string, option?: OptionType) => boolean

const FancyFilterExpandItemBody: React.FC<IFancyFilterExpandItemBody> = ({ data }) => {
  const dispatch = useAppDispatch()
  const { filter } = useAppSelector((state) => state.companies)

  const handleFilter = (key: string, value: any) => {
    const filterCopy = JSON.parse(JSON.stringify(filter))
    _.set(filterCopy, key, value)
    dispatch(SET_FILTER(removeEmpty(filterCopy)))
  }

  const handleReset = (key: string) => {
    const filterCopy = JSON.parse(JSON.stringify(filter))
    const innerKeys = key.split('.')
    if (!!Object.keys(filterCopy).length) delete filterCopy[innerKeys[0]][innerKeys[1]]
    dispatch(SET_FILTER(removeEmpty(filterCopy)))
  }

  return (
    <div className={styles.expandContainer}>
      {data.length > 0 &&
        data.map((item: any) => {
          const onNumberChange = (minKey: string, maxKey: string, event: any, value: 'min' | 'max') => {
            const filterCopy = JSON.parse(JSON.stringify(filter))

            if (value === 'min') {
              _.set(filterCopy, minKey, event)
            } else {
              _.set(filterCopy, maxKey, event)
            }
            dispatch(SET_FILTER(removeEmpty(filterCopy)))
          }

          const filterOption: FilterFunc<IFilterOption> = (inputValue, option) => {
            if (!option) return false

            return option.label.toLowerCase().indexOf(inputValue.trim().toLowerCase()) >= 0
          }

          return (
            <div className={styles.expandBodyItem} key={item?.key}>
              <Row>
                <Col span={18}>
                  <span className={styles.label}>{item.label}</span>
                  {item?.new && <NewTag />}
                </Col>
                <Col span={6}>
                  {item.haveReset && (
                    <span
                      className={styles.reset}
                      onClick={() => {
                        handleReset(item.key || '')
                      }}>
                      Reset
                    </span>
                  )}
                </Col>
              </Row>
              {item.type === 'three' && (
                <OptionsTree treeData={item?.threeContent} isSearchable={item.hasSearch} groupKey={item.key} />
              )}
              {item.type === 'select' && (
                <Select
                  maxTagCount={3}
                  className={styles.selectBox}
                  placeholder='Choose...'
                  mode={item.mode || null}
                  value={_.get(filter, item.key)}
                  onChange={(event) => {
                    handleFilter(item.key || '', event)
                  }}
                  showSearch={item.searchable}
                  options={item.options}
                  filterOption={filterOption}
                />
              )}
              {item.type === 'checkbox' && (
                <Checkbox
                  className={styles.selectBox}
                  checked={_.get(filter, item.key)}
                  onChange={(e) => {
                    handleFilter(item.key || '', e.target.checked)
                  }}>
                  <span className={styles.reset}>Has token</span>
                </Checkbox>
              )}
              {item.type === 'range' && <Range onNumberChange={onNumberChange} filter={filter} item={item} />}
              {item.type === 'averange' && (
                <>
                  <input type='range' id='vol' name='vol' min='0' max='50' />
                </>
              )}
              {item.type === 'tabs' && <FancyFilterExpandItemTabs data={item.children} />}

              {item.type === 'tabs_select' && <FancyFilterExpandItemTabsSelect data={item.children} />}

              {item.type === 'tabs_checkbox' && <FancyFilterExpandItemTabsCheckbox data={item.children} />}
            </div>
          )
        })}
    </div>
  )
}

export default FancyFilterExpandItemBody
