import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { Switch, Typography } from 'antd'
import styles from './EnrichContactModal.module.scss'
import telegram_big_logo from 'common/assets/svg/telegram_big_logo.svg'
import linkedin_big_logo from 'common/assets/svg/linkedin_big_logo.svg'
import { capitalize } from 'lodash'

export const EnrichChannel = ({
  channelSelect,
  channelTotalRow,
  loading,
  channelCostPerRow,
  channelTotalCost,
  handleChangeToggle,
}: any) => {
  const channelValues = [
    {
      platform: 'telegram',
      checked: channelSelect.telegram,
    },
    {
      platform: 'linkedin',
      checked: channelSelect.linkedin,
    },
  ]

  return (
    <div className={styles.EnrichContactModal__body__channel__left}>
      <div className={styles.EnrichContactModal__body__channel__left__text}>
        <Typography>Channels Enrichment </Typography>
        <Typography>Please select the channel you'd like to enrich</Typography>
      </div>

      <div className={styles.EnrichContactModal__body__channel__left__box}>
        {channelValues.map((channel: any) => (
          <ChannelBox
            key={channel.platform}
            loading={loading}
            platform={channel.platform}
            checked={channel.checked}
            onChange={handleChangeToggle}
            channelTotalRow={channelTotalRow}
            channelCostPerRow={channelCostPerRow}
            channelTotalCost={channelTotalCost}
          />
        ))}
      </div>
    </div>
  )
}

const ChannelBox = (props: any) => {
  const { platform, checked, onChange, channelTotalRow, channelCostPerRow, channelTotalCost, loading } = props

  const logo: any = {
    linkedin: linkedin_big_logo,
    telegram: telegram_big_logo,
  }

  const handleToggle = (isChecked: boolean) => {
    onChange(platform, isChecked)
  }

  return (
    <div className={styles.channelBox}>
      <div className={styles.channelBox__logo}>
        <img src={logo[platform]} alt='telegram' />
        <Switch checked={checked} onChange={handleToggle} disabled={loading} />
      </div>
      <Typography className={styles.channelBox__name}>{platform}</Typography>
      <Typography style={{ opacity: `${checked ? 1 : 0}` }} className={styles.channelBox__count}>
        <span>
          {loading ? (
            <Spin style={{ marginRight: '6px' }} indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
          ) : (
            channelTotalRow[platform]
          )}{' '}
          people
        </span>{' '}
        are classified for {capitalize(platform)} enrichment
      </Typography>
      <Typography className={styles.channelBox__cost}>
        <span>Cost Per Contact: {channelCostPerRow[platform]} Points</span>
        <span>Total Cost {channelTotalCost[platform]} Points</span>
      </Typography>
    </div>
  )
}
