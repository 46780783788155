import React from 'react'
import { Row, Col, Divider, Input } from 'antd'
import { Content } from 'antd/es/layout/layout'
import styles from './GroupsContent.module.scss'
import GroupCard from './GroupCard'
import { Grid, AutoSizer } from 'react-virtualized'
import { SearchOutlined } from '@ant-design/icons'
import LoadingBox from 'common/components/LoadingBox/LoadingBox'

export interface Group {
  group_name: string
  number_of_members: string
  number_of_admins: string
  number_of_people_with_first_name: string
  number_of_admins_with_first_name: string
  merge_key: string
  group_name_silver: string
  url: string
  id: string
  title: string
  participants_count: string
  description: string
  logo_status: string
  s3_logo: string
  type: string
  key: string
  tags: any
}

interface FeaturedGroupsContentProps {
  groupsData: Group[]
  totalGroups: number
  setSelectedGroup: any
  isLoading: boolean
  scrollTopGroups: boolean
  searchQuery: string
  onChangeSearchGroup: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const FeaturedGroupsContent: React.FC<FeaturedGroupsContentProps> = ({
  groupsData = [],
  totalGroups,
  setSelectedGroup,
  isLoading,
  scrollTopGroups,
  searchQuery,
  onChangeSearchGroup,
}) => {
  const contentStyle: React.CSSProperties = {
    minHeight: 669,
    color: '#fff',
    padding: '24px 10px 0px 20px',
    backgroundColor: 'white',
  }

  return (
    <Content style={contentStyle}>
      <Row gutter={10} className={styles.rowStyle}>
        <React.Fragment>
          <Col span={24} style={{ maxHeight: 87 }} className={styles.colStyle}>
            <div className={styles.categoryHeader}>
              <div className={styles.leftSide}>
                <span style={{ fontWeight: 600 }}>{'Explore Featured Groups'}</span>
                <Divider type='vertical' />
                <span>
                  {!isLoading ? totalGroups : ' '} Group{totalGroups === 1 ? '' : 's'}
                </span>
              </div>
            </div>
          </Col>
          <Input
            defaultValue={''}
            prefix={<SearchOutlined style={{ color: '#97A0AF' }} />}
            placeholder='Search groups or channels'
            size='large'
            value={searchQuery}
            onChange={onChangeSearchGroup}
          />

          {isLoading ? (
            <div style={{ position: 'absolute', top: '60%', left: '50%', transform: 'translate(-50%,-50%)' }}>
              <LoadingBox />
            </div>
          ) : (
            <div style={{ width: '100%', height: '80%', marginTop: '20px' }}>
              <AutoSizer>
                {({ width }) => (
                  <Grid
                    scrollToRow={scrollTopGroups ? 0 : undefined}
                    style={{ paddingBottom: '20px' }} // Replace with better css later
                    columnCount={3} // number of columns in the grid
                    columnWidth={width / 3} // width of each column
                    height={500} // height of the grid
                    rowCount={Math.ceil(groupsData.length / 3)} // number of rows in the grid
                    rowHeight={270} // height of each row
                    width={width + 10} // total width of the grid
                    cellRenderer={({ columnIndex, key, rowIndex, style }) => {
                      const index = rowIndex * 3 + columnIndex
                      if (index >= groupsData.length) return null
                      const group = groupsData[index]
                      return (
                        <div key={key} style={style}>
                          <div style={{ padding: '2px 10px 0px 10px' }}>
                            <GroupCard group={group} onSelect={setSelectedGroup} />
                          </div>
                        </div>
                      )
                    }}
                  />
                )}
              </AutoSizer>
            </div>
          )}
        </React.Fragment>
      </Row>
    </Content>
  )
}

export default FeaturedGroupsContent
