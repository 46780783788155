import React, { useEffect, useState } from 'react'
import {
  Button,
  Dropdown,
  Form,
  Input,
  Layout,
  Menu,
  Select,
  Space,
  Switch,
  Tag,
  Tooltip,
  message,
  InputNumber,
} from 'antd'
import hubspot from 'common/assets/svg/hubspot_purple.svg'
import info_icon from 'common/assets/svg/info_icon.svg'
import left_arrow from 'common/assets/svg/left_arrow.svg'
import settings_black from 'common/assets/svg/settings_black.svg'
import styles from './HubspotNewDeal.module.scss'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import { get } from 'common/api/axios'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_HUBSPOT_DEALS } from 'features/contacts/state/slice/contactsSlice'

//import { useLazyGetTakeCreditsQuery } from 'features/contacts/state/api/ContactsApi'
import { post } from 'common/api/axios'
import LoadingBox from '../../../../common/components/LoadingBox/LoadingBox'
import OutOfCreditsModal from 'common/components/OutOfCredits/OutOfCreditsModal'

interface HubspotNewDealProps {
  onBack: () => void
  selectedContacts: any
  selectedCompanies: any
  owners: any
  pipeline: any
  telegramCredits: any
  emailCredits: any
  onCloseModal: () => void
  companyIds: any
  isCompany: boolean
  userCredits: any
  userPoints: any
}

const HubspotNewDeal: React.FC<HubspotNewDealProps> = ({
  onBack,
  selectedContacts,
  selectedCompanies,
  owners,
  pipeline,
  telegramCredits,
  emailCredits,
  onCloseModal,
  companyIds,
  isCompany,
  userCredits,
  userPoints,
}) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [outOfCredits, setOutOfCredits] = useState(false)
  const [outOfCreditsType, setOutOfCreditsType] = useState('')
  const [stages, setStages] = useState<any>(pipeline[0]?.stages || [])
  // const { selected_contacts } = useAppSelector((state) => state.contacts)
  const { selected_companies } = useAppSelector((state) => state.companies)
  // const companyIds = selectedContacts
  //   ?.map((contact: any) => contact?.company_id)
  //   .filter((value: any, index: number, self: any[]) => self.indexOf(value) === index)
  const [onToggle, setOnToggle] = useState(true)
  const [overwriteToggle, setOverwriteToggle] = useState(false)
  const [form] = Form.useForm()
  //const [refetchGetTakeCredits] = useLazyGetTakeCreditsQuery()
  const tagOptions = isCompany
    ? {
        company_info: `Company Info (${selected_companies?.length * userPoints?.pointsStore.companies_export})`,
      }
    : {
        company_info: `Company Info (${companyIds?.length * userPoints?.pointsStore.companies_export})`,
        contact_info: `Contact Info (${selectedContacts?.length * userPoints?.pointsStore.contacts_export})`,
        telegram: `Telegram Accounts (${telegramCredits?.length * userPoints?.pointsStore.telegram})`,
        email: `Emails Addresses (${emailCredits?.length * userPoints?.pointsStore.email})`,
      }
  const [selectedOptions, setSelectedOptions] = useState<any>(tagOptions)

  const onChangeToggle = () => {
    setOnToggle(!onToggle)
  }

  const onChangeOverwriteToggle = () => {
    setOverwriteToggle(!overwriteToggle)
  }

  const handleMenuClick = (e: any) => {
    const option = e.key as keyof typeof tagOptions
    if (!selectedOptions[option]) {
      const updatedOptions = { ...selectedOptions }
      updatedOptions[option] = tagOptions[option]
      setSelectedOptions(updatedOptions)
    }
  }

  const handleTagClose = (removedOption: string) => {
    const updatedOptions = { ...selectedOptions }
    delete updatedOptions[removedOption]
    setSelectedOptions(updatedOptions)
  }

  const handleFormValuesChange = (changedValues: any) => {
    if (changedValues?.pipeline) {
      const targetLabel = changedValues?.pipeline
      const targetPipeline = pipeline.find((item: any) => item.label === targetLabel)
      setStages(targetPipeline.stages)
      form.setFieldValue('dealStage', stages[0]?.id)
    }
  }

  const fetchHubspotDealData = async () => {
    const fetchedDeals = await get('/outreach/getHubspotAllDeals')
    dispatch(SET_HUBSPOT_DEALS(fetchedDeals.data.response))
    // setHunspotDeals(fetchedDeals.data.response)
  }

  const handleSubmit = async (values: any) => {
    // if (values.enrichment.contact_info && userCredits.contacts_export < selectedContacts.length) {
    if (
      values.enrichment.contact_info &&
      userPoints.points < userPoints.pointsStore.contacts_export * selectedContacts.length
    ) {
      setOutOfCreditsType('Contact')
      setOutOfCredits(true)
      return
      // } else if (values.enrichment.company_info && userCredits.companies_export < companyIds.length) {
    } else if (
      values.enrichment.company_info &&
      userPoints.points < userPoints.pointsStore.companies_export * companyIds.length
    ) {
      setOutOfCreditsType('Company')
      setOutOfCredits(true)
      return
      // } else if (values.enrichment.email && userCredits.email < emailCredits.length) {
    } else if (values.enrichment.email && userPoints.points < userPoints.pointsStore.email * emailCredits.length) {
      setOutOfCreditsType('Email')
      setOutOfCredits(true)
      return
      // } else if (values.enrichment.telegram && userCredits.telegram < telegramCredits.length) {
    } else if (
      values.enrichment.telegram &&
      userPoints.points < userPoints.pointsStore.telegram * telegramCredits.length
    ) {
      setOutOfCreditsType('Telegram')
      setOutOfCredits(true)
      return
    } else {
      const isEnrich = Object.keys(values.enrichment).length > 0
      try {
        const data = []

        for (const companyId of companyIds) {
          const filteredUsers = selectedContacts.filter((contact: any) => contact.company_id === companyId)
          const filteredUsersIds = filteredUsers?.map((contact: any) => contact?.index)

          const companyData = {
            domain: filteredUsers[0]?.company_domain,
            name: filteredUsers[0]?.company_name,
          }

          const tempBody = {
            deal_name: `${companyData.name} Convrt Deal`,
            deal_amount: values.dealRevenue || null,
            stage: values.dealStage || null,
            deal_owner: values.dealOwner || null,
            is_data_enrichment: isEnrich,
            enrichment: {
              company_info: values.enrichment.company_info !== undefined,
              contact_info: values.enrichment.contact_info !== undefined,
              telegram: values.enrichment.telegram !== undefined,
              email: values.enrichment.email !== undefined,
            },
            contact_id: filteredUsersIds,
            company_id: [companyId],
            do_override_if_exists: overwriteToggle,
          }
          data.push(tempBody)
          setLoading(true)
        }

        const response = await post('/outreach/createHubspotDeals', {
          data,
          telegramCredits,
          emailCredits,
          telegram: values.enrichment.telegram !== undefined,
          email: values.enrichment.email !== undefined,
        })

        const createdDealsIds = response?.data?.dealIds

        if (response.status !== 200) {
          message.error('Creation of new Deal failed')
        } else {
          message.success(`${createdDealsIds.length}/${data.length} Deals were created successfully`)
        }
        fetchHubspotDealData()
        onCloseModal()

        return true
      } catch (e) {
        console.error(e)
        return false
      } finally {
        setLoading(false)
      }
    }
  }

  const handleSubmitCompany = async (values: any) => {
    if (values.enrichment.contact_info && userCredits.contacts_export < selectedContacts?.length) {
      setOutOfCreditsType('Contact')
      setOutOfCredits(true)
      return
    } else if (values.enrichment.company_info && userCredits.companies_export < selectedCompanies?.length) {
      setOutOfCreditsType('Company')
      setOutOfCredits(true)
      return
    } else {
      const isEnrich = Object.keys(values.enrichment).length > 0
      try {
        const data = []

        for (const company of selectedCompanies) {
          const body = {
            deal_name: `${company?.name} Convrt Deal`,
            deal_amount: values.dealRevenue || null,
            stage: values.dealStage || null,
            deal_owner: values.dealOwner || null,
            is_data_enrichment: isEnrich,
            enrichment: {
              company_info: values.enrichment.company_info !== undefined,
              contact_info: false,
              telegram: false,
              email: false,
            },
            contact_id: [],
            company_id: [company?.id],
            do_override_if_exists: overwriteToggle,
          }

          data.push(body)
          setLoading(true)
        }

        const response = await post('/outreach/createHubspotDeals', {
          data,
          emailCredits: [],
          telegramCredits: [],
          telegram: false,
          email: false,
        })

        const createdDealsIds = response?.data?.dealIds

        if (response.status !== 200) {
          message.error('Creation of new Deal failed')
        } else {
          message.success(`${createdDealsIds.length}/${data.length} Deals were created successfully`)
        }

        fetchHubspotDealData()
        onCloseModal()
        return true
      } catch (e) {
        console.error(e)
        return false
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    form.setFieldValue('enrichment', onToggle ? selectedOptions : [])
  }, [onToggle, selectedOptions])

  const menu = (
    <Menu onClick={handleMenuClick}>
      {Object.entries(tagOptions).map(([key, value]) => (
        <Menu.Item key={key}>{value}</Menu.Item>
      ))}
    </Menu>
  )

  const handleKeyDown = (event: any) => {
    if (
      (event.key >= '0' && event.key <= '9') ||
      event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown'
    ) {
      return
    } else {
      event.preventDefault()
    }
  }

  return (
    <>
      {outOfCredits && (
        <OutOfCreditsModal
          open={outOfCredits}
          onClose={() => {
            setOutOfCredits(false)
          }}
          creditType={outOfCreditsType}
          location='hubspot'
        />
      )}
      <Space direction='vertical' style={{ width: '100%' }} size={[0, 48]}>
        {loading ? (
          <>
            <LoadingBox className='hubspot_deal_page' />
            <div className={styles.headerLoadingBoxText}>
              Enriching your HubSpot is in progress. You can close this window - we will notify you when the enrichment
              is complete
            </div>
          </>
        ) : (
          <Layout>
            <Header className={styles.headerStyle}>
              <img src={hubspot} alt='' />
              <h1>Create a New Deal Per Company</h1>
            </Header>
            <Content className={styles.contentStyle}>
              <Form
                form={form}
                id='dealForm'
                name='dealForm'
                onFinish={isCompany ? handleSubmitCompany : handleSubmit}
                onValuesChange={handleFormValuesChange}
                style={{ maxWidth: 1200 }}
                className='newDealForm'
                colon={false}>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Form.Item
                    label={<p className={styles.formSelect}>Deal Name</p>}
                    name='dealName'
                    className={styles.formSelect}>
                    <Input disabled={true} size={'large'} placeholder='{ Company Name } Convrt Deal' />
                  </Form.Item>
                  <Form.Item
                    name='pipeline'
                    label={<p>Pipeline Name</p>}
                    className={styles.formSelect}
                    style={{ margin: '0px 0px 20px 0px' }}>
                    <Select size={'large'}>
                      {pipeline.length === 0 && (
                        <Select.Option value={null} disabled>
                          No options available
                        </Select.Option>
                      )}
                      {pipeline.map((pipeline: any, index: number) => (
                        <Select.Option key={index} value={pipeline.label}>
                          {pipeline.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label={<p>Deal Revenue (Amount)</p>} name='dealRevenue' className={styles.formSelect}>
                    <InputNumber
                      size={'large'}
                      placeholder=''
                      min={0}
                      style={{ width: '100%' }}
                      onKeyDown={handleKeyDown}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<p>Deal Owner</p>}
                    name='dealOwner'
                    className={styles.formSelect}
                    style={{ margin: '0px 0px 20px 0px' }}>
                    <Select size={'large'}>
                      {owners.length === 0 && (
                        <Select.Option value={null} disabled>
                          No options available
                        </Select.Option>
                      )}
                      {owners?.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {`${item.firstName} (${item.email})`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label={<p>Deal Stage</p>} name='dealStage' className={styles.formSelect}>
                    <Select size={'large'}>
                      {stages.length === 0 && (
                        <Select.Option value={null} disabled>
                          No options available
                        </Select.Option>
                      )}
                      {stages?.map((item: any, index: number) => (
                        <Select.Option key={index} value={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form.Item>
                <Form.Item name={'enrichment'}>
                  <div className={styles.enrichment}>
                    <div className={styles.top}>
                      <div className={styles.left}>
                        <p>Data Enrichment</p>
                        <span>
                          Let Convrt enrich your data for improved results. This option incurs a point cost,
                          <br /> you can set your enrichment preferences.
                        </span>
                      </div>
                      <div className={styles.right}>
                        <Switch defaultChecked onChange={onChangeToggle} />
                        <span>{onToggle ? 'ON' : 'OFF'}</span>
                      </div>
                    </div>
                    {onToggle && (
                      <div className={styles.settings}>
                        <div className={styles.left}>
                          <div className={styles.options}>
                            <span>Enrich Companies Data:</span>
                            <div>
                              {Object.entries(selectedOptions).map(([key, value]) => (
                                <Tag
                                  key={key}
                                  closable
                                  onClose={() => handleTagClose(key)}
                                  style={{ margin: '-1px 0px 5px 8px' }}>
                                  {value as React.ReactNode}
                                </Tag>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className={styles.right}>
                          <Dropdown overlay={menu} trigger={['click']} placement={'bottomCenter'}>
                            <span style={{ cursor: 'pointer' }}>
                              <img src={settings_black} alt='' />
                            </span>
                          </Dropdown>
                        </div>
                      </div>
                    )}
                  </div>
                </Form.Item>
              </Form>
            </Content>
            <Footer className={styles.footerStyle}>
              <div className={styles.section}>
                <div className={styles.back} onClick={onBack}>
                  <img src={left_arrow} alt='' />
                  <span>Back</span>
                </div>
                <div className={styles.centeredText}>
                  <Switch
                    style={{ marginRight: '5px' }}
                    onChange={onChangeOverwriteToggle}
                    checked={overwriteToggle}
                    size={'small'}
                  />
                  <span>Overwrite Populated Fields is {overwriteToggle ? 'ON' : 'OFF'}</span>
                  <Tooltip
                    className='tooltipHubspotNewDeal'
                    overlayInnerStyle={{
                      color: 'black',
                      backgroundColor: 'white',
                    }}
                    title='By default, Convrt will not overwrite existing data in populated fields when enriching HubSpot records. It is possible to overwrite populated fields with Convrt data by turning this toggle ON.'>
                    <img src={info_icon} alt='' />
                  </Tooltip>
                </div>
                <Button
                  form='dealForm'
                  key='submit'
                  htmlType='submit'
                  className={styles.button}
                  disabled={isCompany ? selectedCompanies.length === 0 : selectedContacts.length === 0}>
                  Export
                </Button>
              </div>
            </Footer>
          </Layout>
        )}
      </Space>
    </>
  )
}

export default HubspotNewDeal
