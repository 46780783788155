import React, { useState } from 'react'
import { Tabs } from 'antd'
import styles from './ChatTabs.module.scss'
import ChatMessage from '../ChatMessage'
import chatData from '../chatData'
import ChatTab from './ChatTab'
import { useAppSelector } from 'state'
import { post } from 'common/api/axios'
interface ChatTabsProps {}

const ChatTabs: React.FC<ChatTabsProps> = () => {
  // eslint-disable-next-line
  const [selectedOption, setSelectedOption] = useState<string>('')
  const { selected_account, all_telegram_data } = useAppSelector((state) => state.chat)
  const [activeItemId, setActiveItemId] = useState<number | null>(null)
  const groupsData = all_telegram_data?.groupsData || []
  const unreadData = all_telegram_data?.unreadData || []
  const allData = all_telegram_data?.dialogData || []
  const accountsReadUnreadSentReplied = all_telegram_data?.accountsReadUnreadSentReplied || {}

  const groupsDataLength =
    selected_account?.account_id === 'all_data'
      ? groupsData?.length
      : accountsReadUnreadSentReplied[selected_account.receiver_number]?.groups
  // const allData = data?.telegram?.dialogData
  // const allData = [...unreadData, ...readData]
  const handleChange = (e: any) => {
    switch (e) {
      case '1':
        // post('/log', {
        //   action: 'Unified Inbox: User Selected All Chats',
        // })
        break
      case '2':
        // post('/log', {
        //   action: 'Unified Inbox: User Selected Unread Chats',
        // })
        break
      case '3':
        post('/log', {
          action: 'Unified Inbox: User Selected Groups Chats',
        })
        break
      default:
        break
    }
  }

  return (
    <>
      <div className={styles.ChatTabsSidebar}>
        <div className={styles.ChatTabsSidebarContent}>
          <h2>Telegram</h2>
          <h1>All Active Accounts</h1>
          <Tabs defaultActiveKey='1' centered className='ChatTabsSidebarContent_tabs' onChange={handleChange}>
            {/* All Chats */}
            <Tabs.TabPane
              tab={
                <>
                  All Chats <span>x{allData?.length?.toLocaleString()}</span>
                </>
              }
              key='1'
              style={{ position: 'relative' }}>
              <ChatTab filter={'all'} activeItemId={activeItemId} setActiveItemId={setActiveItemId} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <>
                  Unread{' '}
                  <span>
                    x
                    {selected_account?.account_id === 'all_data'
                      ? unreadData?.filter((item: any) => item.dialog_type === 'user').length
                      : accountsReadUnreadSentReplied[selected_account.receiver_number]?.unread}
                  </span>
                </>
              }
              key='2'>
              <ChatTab filter={'unread'} activeItemId={activeItemId} setActiveItemId={setActiveItemId} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <>
                  Groups <span>x{groupsDataLength?.toLocaleString()}</span>
                </>
              }
              key='3'>
              <ChatTab filter={'groups'} activeItemId={activeItemId} setActiveItemId={setActiveItemId} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
      <ChatMessage selectedChannelId={selectedOption} selectedChannelMessages={chatData} />
    </>
  )
}

export default ChatTabs
