import { Drawer } from 'antd'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'state'

import arrowLeft from 'common/assets/svg/ArrowLeft.svg'
import styles from './ArticleSeeMore.module.scss'
import {
  SET_ARTICLE_SEE_MORE_DATA,
  SET_ARTICLE_SEE_MORE_OPEN,
  SET_LEARNING_OPEN,
} from '../../../state/slice/learningSlice'
import ArticleCard from '../ArticleCard'
import { ReloadOutlined } from '@ant-design/icons'
const bodyStyle: React.CSSProperties = {
  backgroundColor: '#F3F4F6',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  paddingTop: 15,
  paddingBottom: 10,
}

interface GettingStartedDrawerProps {
  articles: any
  handleOnClose: any
  init: any
}
export default function ArticleSeeMore({ articles, handleOnClose, init }: GettingStartedDrawerProps) {
  const dispatch = useAppDispatch()

  const onCloseArticle = () => {
    dispatch(SET_ARTICLE_SEE_MORE_OPEN(true))
  }
  const onOpenArticle = () => {
    dispatch(SET_LEARNING_OPEN(false))
    dispatch(SET_ARTICLE_SEE_MORE_OPEN(false))
  }
  const { articleSeeMoreOpen, isAdmin, articleSeeMoreData } = useAppSelector((state) => state.learning)
  const all = articleSeeMoreData?.category === 'articles'
  const title = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ display: 'flex', height: '100%', gap: 7 }}>
        <div style={{ alignItems: 'flex-end', display: 'flex', marginBottom: 2, cursor: 'pointer' }}>
          <img src={arrowLeft} alt='' onClick={handleOnClose} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <div className={styles.title}>
            Learning Center {'>'} {articleSeeMoreData?.title}{' '}
          </div>
          <div className={styles.subTitle}>{articleSeeMoreData?.title}</div>
        </div>
      </div>

      <div>
        {isAdmin && (
          <ReloadOutlined
            onClick={() => {
              init()
              handleOnClose()
              const onSeeMore = () => {
                dispatch(SET_LEARNING_OPEN(false))
                dispatch(
                  SET_ARTICLE_SEE_MORE_DATA({
                    title: articleSeeMoreData?.title,
                    category: articleSeeMoreData?.category,
                  }),
                )
                dispatch(SET_ARTICLE_SEE_MORE_OPEN(true))
              }
              onSeeMore()
            }}
          />
        )}
      </div>
    </div>
  )
  return (
    <Drawer
      style={{
        display: articleSeeMoreOpen ? 'flex' : 'none',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.4)', // Add drop shadow
      }}
      open={articleSeeMoreOpen}
      onClose={handleOnClose}
      closable={false}
      title={title}
      width={480}
      bodyStyle={bodyStyle}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
      className='learning-center-drawer'>
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: 16 }}>
        <div
          style={{
            flex: 0.95,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
          }}>
          <>
            {isAdmin ? (
              articles
                ?.filter(
                  (article: any) =>
                    article.category === articleSeeMoreData?.category || articleSeeMoreData?.category === 'articles',
                )
                ?.sort((a: any, b: any) => (all ? a.priority_all - b.priority_all : a.priority - b.priority))

                ?.map((article: any, index: number) => {
                  if (!article.published) {
                    return (
                      <ArticleCard
                        key={index}
                        article={article}
                        onClose={onCloseArticle}
                        onOpen={onOpenArticle}
                        fade={true}
                        all={all}
                      />
                    )
                  } else {
                    return (
                      <ArticleCard
                        key={index}
                        article={article}
                        onClose={onCloseArticle}
                        onOpen={onOpenArticle}
                        all={all}
                      />
                    )
                  }
                })
            ) : (
              <>
                {articles
                  ?.filter(
                    (article: any) =>
                      article.category === articleSeeMoreData?.category || articleSeeMoreData?.category === 'articles',
                  )
                  ?.filter((article: any) => article.published)
                  ?.sort((a: any, b: any) => (all ? a.priority_all - b.priority_all : a.priority - b.priority))
                  .map((article: any, index: number) => {
                    if (!article.published) {
                      return null
                    } else {
                      return (
                        <ArticleCard
                          key={index}
                          article={article}
                          onClose={onCloseArticle}
                          onOpen={onOpenArticle}
                          all={all}
                        />
                      )
                    }
                  })}
              </>
            )}
          </>
        </div>
      </div>
    </Drawer>
  )
}
