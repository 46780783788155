export const fundingDates = [
  {
    value: 'Last Month',
    label: 'Last Month',
  },
  {
    value: 'Last 3 Months',
    label: 'Last 3 Months',
  },
  {
    value: 'Last 6 Months',
    label: 'Last 6 Months',
  },
  {
    value: 'Last 12 Months',
    label: 'Last 12 Months',
  },
  {
    value: '2022',
    label: '2022',
  },
]
