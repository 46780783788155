import { Col, Popover, Progress, Row, Tag } from 'antd'
import { IIntentSignal } from 'features/intent-signals/interfaces/intent-signals-table.interface'
import { ReactNode } from 'react'
import { milestonesMap } from 'common/static-data/company-filter'
import './IntentsPopup.scss'

interface IIntentsPopup {
  children: ReactNode | ReactNode[]
  intents: IIntentSignal[]
  isIntentsEmpty: boolean
  milestones?: any
}

const IntentsPopup = ({ children, intents, isIntentsEmpty, milestones }: IIntentsPopup): any => {
  const getIntentColor = (intent_strength: any) => {
    if (intent_strength.includes('HIGH')) return '#7CDFBC'
    if (intent_strength.includes('MEDIUM')) return '#F6BE4E'
    if (intent_strength.includes('LOW')) return '#DF7C7C'
  }

  const updatedIntents = intents?.sort((a, b) => b.value - a.value).slice(0, 2)

  const content = (
    <div className='intent-popup'>
      {updatedIntents
        .sort((intent) => intent.value)
        .reverse()
        .map(({ title, value, intent_strength }: IIntentSignal, index, array) => (
          <Row
            key={value}
            className='intent-popup__row'
            style={{
              borderBottom:
                index !== array?.length - 1 || milestones?.length > 0 ? '1px solid var(--color-gray-03)' : 'none',
            }}>
            <Col span={14}>
              <div className='intent-popup-title'>
                <h2>{title}</h2>
                <span>INTERESTS</span>
              </div>
            </Col>
            <Col span={6}>
              <Progress
                strokeColor={getIntentColor(intent_strength)}
                showInfo={false}
                className='w-5rem'
                percent={value}
              />
            </Col>
            <Col span={4}>
              <h3>{intent_strength}</h3>
            </Col>
          </Row>
        ))}

      {milestones?.map((value: any, index: number, array: any) => {
        const updatedValue =
          value === 'Testnet' ? 'Soon to be launched' : value === 'Launching product' ? 'Soon to be launched' : value
        if (!updatedValue) {
          return null
        }
        return (
          <Row
            key={updatedValue}
            className='intent-popup__row'
            style={{ borderBottom: index !== array?.length - 1 ? '1px solid var(--color-gray-03)' : 'none' }}>
            <Col span={14}>
              <div className='intent-popup-title'>
                <h2>{milestonesMap[updatedValue]?.label}</h2>
                <span>MILESTONES</span>
              </div>
            </Col>
            <Col span={10} style={{ display: 'flex', justifyContent: 'center' }}>
              {/* <span style={{ fontWeight: 600 }}>Milestone</span> */}
              <Tag className='fundingFlag' color={milestonesMap[updatedValue]?.color}>
                {milestonesMap[updatedValue]?.label.toUpperCase()}
              </Tag>
            </Col>
          </Row>
        )
      })}
    </div>
  )

  const proFeature = <span className='text-xs proWidget'>Pro Feature</span>
  return (
    <Popover placement='bottomRight' content={isIntentsEmpty ? content : proFeature} trigger='hover'>
      {children}
    </Popover>
  )
}

export default IntentsPopup
