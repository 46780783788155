import React, { useState } from 'react'
import { Modal, Button, Select, Radio, message } from 'antd'
import { CreditCardOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import styles from './AddMoreSeatsModal.module.scss'
import stripeLogo from 'common/assets/svg/stripe_logo.svg' // Adjust the path as necessary
import { useLazyBuyMoreSeatsQuery, useLazyGetUnassignedSeatsQuery } from 'features/settings/state/api/SettingsApi'
import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import Success from 'common/components/SuccessAnimation/Success'
import { useAuth } from 'common/hooks/useAuth.hooks'

const planMapping: any = {
  go_to_market: 'Go To Market',
  connect_plus: 'Connect+',
  market_dominance: 'Market Dominance',
  trial: 'Trial',
  trail: 'Trial',
  custom: 'Custom',
}

interface AddMoreSeatsModalProps {
  open: boolean
  onClose: () => void
  defaultPaymentMethod?: {
    last4: string
    link?: string
  }
}

const { Option } = Select

const AddMoreSeatsModal: React.FC<AddMoreSeatsModalProps> = ({ open, onClose, defaultPaymentMethod }) => {
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'three_months' | 'yearly'>('three_months')
  const [selectedPlan, setSelectedPlan] = useState<any>('market_dominance')
  const [isLoading, setIsLoading] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const { updateUserDetails } = useAuth()
  const [refetchBuyMoreSeats] = useLazyBuyMoreSeatsQuery()
  const [refetchGetUnassignedSeats] = useLazyGetUnassignedSeatsQuery()

  const planDetails: any = {
    go_to_market: { name: 'Go To Market', prices: { monthly: 99, three_months: 79, yearly: 68 } },
    connect_plus: { name: 'Connect+', prices: { monthly: 319, three_months: 259, yearly: 207 } },
    market_dominance: { name: 'Market Dominance', prices: { monthly: 649, three_months: 538, yearly: 422 } },
  }

  const billingCycleMapping = {
    monthly: 'Every Month',
    three_months: 'Every Three Months',
    six_months: 'Every Six Months',
    yearly: 'Every Year',
  }

  //   const handlePlanChange = (value: 'go_to_market' | 'connect_plus' | 'market_dominance') => {
  //     setSelectedPlan(value)
  //   }

  const handleBillingCycleChange = (e: any) => {
    setBillingCycle(e.target.value)
  }

  const handlePayFromModal = async () => {
    try {
      setIsLoading(true)
      const { data } = await refetchBuyMoreSeats({
        plan: selectedPlan,
        billing_cycle: billingCycle,
        // convrtPoints: convrtPoints,
        quantity: quantity,
      })

      if (data) {
        if (data?.permissions === false) {
          message.error('Please contact your owner in order to buy more seats.')
        } else {
          setIsCompleted(true)
        }
        updateUserDetails()
        await refetchGetUnassignedSeats()
        setIsLoading(false)
      } else {
        message.error('Something went wrong')
        setIsLoading(false)
      }
    } catch (e: any) {
      message.error('Something went wrong')
      console.error(e)
    }
  }

  const handleIncreaseQuantity = () => {
    setQuantity((prev) => prev + 1)
  }

  const handleDecreaseQuantity = () => {
    setQuantity((prev) => (prev >= 2 ? prev - 1 : prev))
  }

  return (
    <Modal
      title={<div className={styles.modalTitle}>Add Seats</div>}
      open={open}
      onCancel={onClose}
      footer={null}
      centered
      width={580}
      className={styles.upgradePlanModal}>
      {isLoading ? (
        <div className={styles.loading}>
          <LoadingBox />
        </div>
      ) : isCompleted ? (
        <div className={styles.completed}>
          <Success />
          <h2>Thank You For Your Payment!</h2>
        </div>
      ) : (
        <>
          <div className={styles.billingCycleSelection}>
            <Radio.Group defaultValue='three_months' onChange={handleBillingCycleChange}>
              <Radio.Button value='monthly'>Monthly</Radio.Button>
              <Radio.Button value='three_months'>Three Months</Radio.Button>
              <Radio.Button value='yearly'>Yearly</Radio.Button>
            </Radio.Group>
          </div>
          <div className={styles.planSelection}>
            <Select value={selectedPlan} style={{ width: '100%' }} onChange={setSelectedPlan} disabled={isLoading}>
              <Option value='go_to_market'>Go To Market</Option>
              <Option value='connect_plus'>Connect Plus</Option>
              <Option value='market_dominance'>Market Dominance</Option>
            </Select>
          </div>

          <div className={styles.planDetails}>
            <p>
              Selected Plan: <strong style={{ color: '#7043ff' }}>{planMapping[selectedPlan]}</strong>
            </p>
            <p>
              <div className={styles.quantity}>
                Quantity:{' '}
                <strong style={{ color: '#7043ff' }}>
                  <span onClick={handleDecreaseQuantity} style={{ marginRight: '5px' }}>
                    <MinusOutlined style={quantity <= 1 ? { color: '#ccc' } : { cursor: 'pointer' }} />
                  </span>
                  <span style={{ width: '50px' }}>{quantity}</span>
                  <span onClick={handleIncreaseQuantity} style={{ cursor: 'pointer', marginLeft: '5px' }}>
                    <PlusOutlined />
                  </span>
                </strong>
              </div>
            </p>
            <p>
              Total Price:{' '}
              <strong style={{ color: '#7043ff' }}>
                ${(planDetails[selectedPlan].prices[billingCycle] * quantity)?.toLocaleString()},{' '}
              </strong>
              <span style={{ fontWeight: 400, color: '#666' }}> {billingCycleMapping[billingCycle]}</span>
            </p>
          </div>
          <div className={styles.cardInfoSection}>
            {defaultPaymentMethod?.link ? (
              <img
                style={{ height: '20px', marginRight: '5px' }}
                src={defaultPaymentMethod.link}
                alt='Payment Method'
              />
            ) : (
              <CreditCardOutlined className={styles.cardIcon} />
            )}
            <span className={styles.cardText}>
              Paying with {`${defaultPaymentMethod?.link ? 'LINK' : ` •••• •••• •••• ${defaultPaymentMethod?.last4}`}`}
            </span>
          </div>
          <div className={styles.poweredByStripe}>
            <span className={styles.poweredBy}>Powered by</span>
            <img src={stripeLogo} alt='Stripe' className={styles.stripeLogo} />
          </div>
          <div className={styles.actionButtons}>
            <Button className={styles.cancelButton} onClick={onClose}>
              Cancel
            </Button>
            <Button type='primary' className={styles.upgradeButton} onClick={handlePayFromModal}>
              Pay Now
            </Button>
          </div>
        </>
      )}
    </Modal>
  )
}

export default AddMoreSeatsModal
