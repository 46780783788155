import React from 'react'
import { Modal } from 'antd'

import ConnectPlusOnBoarding from './ConnectPlusOnBoarding'

import styles from './ConnectPlusModal.module.scss'

interface ConnectPlusModalProps {
  open: boolean
  onClose: () => void
  setProgress?: (data: any) => void
  setContributorLogsParams?: (data: any) => void
  isImportTelegram: boolean
}

const ConnectPlusModal: React.FC<ConnectPlusModalProps> = ({
  open,
  onClose,
  setProgress,
  setContributorLogsParams,
  isImportTelegram,
}) => (
  <div className={styles.modal}>
    <Modal
      centered
      open={open}
      okButtonProps={{
        className: 'hidden',
      }}
      cancelButtonProps={{
        className: 'hidden',
      }}
      onCancel={onClose}
      width={600}
      bodyStyle={{
        height: 590,
        display: 'flex',
        flexDirection: 'column',
      }}>
      <ConnectPlusOnBoarding
        onClose={onClose}
        setProgress={setProgress}
        setContributorLogsParams={setContributorLogsParams}
        isImportTelegram={isImportTelegram}
      />
    </Modal>
  </div>
)

export default ConnectPlusModal
