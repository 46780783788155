import { Button, Drawer, Form, Input, message, Modal } from 'antd'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import {
  SET_ARTICLE_SEE_MORE_DATA,
  SET_ARTICLE_SEE_MORE_OPEN,
  SET_GETTING_STARTED_OPEN,
  SET_LEARNING_OPEN,
} from '../../state/slice/learningSlice'
import arrowLeft from 'common/assets/svg/ArrowLeft.svg'
import styles from './GettingStartedDrawer.module.scss'
import plusOutline from 'common/assets/svg/plus_outlined.svg'
import Articles from '../Articles/Articles'
import { useLazyUploadCategoryQuery } from '../../state/api/LearningCenterAPI'
const bodyStyle: React.CSSProperties = {
  backgroundColor: '#F3F4F6',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  paddingTop: 15,
  paddingBottom: 10,
}

interface GettingStartedDrawerProps {
  allArticles: any
}
export default function GettingStartedDrawer({ allArticles }: GettingStartedDrawerProps) {
  const dispatch = useAppDispatch()
  const { gettingStartedOpen, allCategories, isAdmin } = useAppSelector((state) => state.learning)
  const [addCategoryModalOpen, setAddCategoryModalOpen] = React.useState(false)
  const [uploadCategoryQuery] = useLazyUploadCategoryQuery()
  const onCloseArticle = () => {
    dispatch(SET_GETTING_STARTED_OPEN(true))
  }
  const onOpenArticle = () => {
    dispatch(SET_LEARNING_OPEN(false))
    dispatch(SET_GETTING_STARTED_OPEN(false))
  }
  const handleOnClose = () => {
    dispatch(SET_GETTING_STARTED_OPEN(false))
    dispatch(SET_LEARNING_OPEN(true))
  }
  const title = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ display: 'flex', height: '100%', gap: 7 }}>
        <div style={{ alignItems: 'flex-end', display: 'flex', marginBottom: 2, cursor: 'pointer' }}>
          <img src={arrowLeft} alt='' onClick={handleOnClose} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <div className={styles.title}>Learning Center</div>
          <div className={styles.subTitle}>Getting Started</div>
        </div>
      </div>
      <div>
        {isAdmin && (
          <span>
            <img
              src={plusOutline}
              alt='Plus'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setAddCategoryModalOpen(true)
              }}
            />
          </span>
        )}
      </div>
    </div>
  )

  const onSeeMore = (category: any) => {
    dispatch(SET_GETTING_STARTED_OPEN(false))
    dispatch(SET_ARTICLE_SEE_MORE_DATA({ title: category.title, category: category.name }))
    dispatch(SET_ARTICLE_SEE_MORE_OPEN(true))
  }
  const uploadCategory = async (values: any) => {
    try {
      const { title, name } = values
      await uploadCategoryQuery({ title, name })
      message.success('Category uploaded successfully')
      setAddCategoryModalOpen(false)
    } catch (e) {
      message.error('Error in uploading category')
      console.log('Error in uploading category', e)
    }
  }
  return (
    <>
      <Modal
        width={800}
        title='Upload Category'
        open={addCategoryModalOpen}
        onCancel={() => setAddCategoryModalOpen(false)}
        footer={null}
        zIndex={1002}>
        <Form
          name='basic'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={uploadCategory}
          autoComplete='off'>
          <Form.Item label='Title' name='title' rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label='Name' name='name' rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button htmlType='submit' typeof='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Drawer
        style={{
          display: gettingStartedOpen ? 'flex' : 'none',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.4)', // Add drop shadow
        }}
        open={gettingStartedOpen}
        onClose={handleOnClose}
        closable={false}
        title={title}
        width={480}
        className='learning-center-drawer'
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
        bodyStyle={bodyStyle}>
        {allCategories?.map((category: any) => (
          <div style={{ flex: 0.4 }}>
            <Articles
              articles={allArticles?.filter((article: any) => article.category === category.name)}
              onClose={onCloseArticle}
              onOpen={onOpenArticle}
              title={category.title}
              actionText={'See More'}
              loading={false}
              actionFunc={() => {
                onSeeMore(category)
              }}
            />
          </div>
        ))}
      </Drawer>
    </>
  )
}
