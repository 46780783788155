import React from 'react'
import { Button, Modal } from 'antd'
import warning from 'common/assets/svg/warning.svg'
import styles from './RemoveLeadsModal.module.scss'
import { useAppSelector } from 'state'
import LoadingBox from 'common/components/LoadingBox/LoadingBox'

interface RemoveLeadsModalProps {
  open: boolean
  onClose: () => void
  campaignName: string
  onConfirm: () => void
  isCampaignRunning?: any
  isLoading?: any
}

const RemoveLeadsModal: React.FC<RemoveLeadsModalProps> = ({
  open,
  onClose,
  campaignName,
  onConfirm,
  isCampaignRunning,
  isLoading,
}) => {
  const { selected_leads } = useAppSelector((state) => state.outreachCampaign)
  const numOfLeads = `${selected_leads?.length} ${selected_leads?.length === 1 ? 'Lead' : 'Leads'}`
  return (
    <>
      <Modal
        className='modalStyleNewDeal'
        centered
        open={open}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}
        onCancel={() => {
          !isLoading && onClose()
        }}
        width={'fit-content'}
        style={{ minWidth: 700, maxWidth: 700 }}>
        <div className={styles.contentStyle}>
          {isLoading ? (
            <div className={styles.loading}>
              <LoadingBox />
            </div>
          ) : (
            <div className={styles.container}>
              <img src={warning} alt='' />
              <p>Are You Sure You Want to Remove {numOfLeads} From this Campaign?</p>
              <span style={isCampaignRunning ? { marginBottom: '5px' } : {}}>
                Feel free to remove these leads now. You can always re-add them later if needed.
              </span>
              {isCampaignRunning && (
                <span style={{ fontWeight: 600 }}>
                  Note: This action will pause your campaign. You can re-run it later.
                </span>
              )}
              <div className={styles.footer}>
                <Button size={'large'} onClick={onClose}>
                  Cancel
                </Button>
                <Button size={'large'} style={{ color: 'white', backgroundColor: '#7043ff' }} onClick={onConfirm}>
                  Remove
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}
export default RemoveLeadsModal
