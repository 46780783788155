import React, { useState } from 'react'
import { Button, Modal, message } from 'antd'
import bin from 'common/assets/svg/bin.svg'
import styles from './DeleteTemplateModal.module.scss'
import { useLazyDeleteCustomMessageQuery } from 'features/Outreach/state/api/OutreachApi'
import LoadingBox from 'common/components/LoadingBox/LoadingBox'

interface DeleteTemplateModalProps {
  open: boolean
  onClose: () => void
  messageId: string
  messageName: string
  refetchGetCustomMessages: any
}

const DeleteTemplateModal: React.FC<DeleteTemplateModalProps> = ({
  open,
  onClose,
  messageId,
  messageName,
  refetchGetCustomMessages,
}) => {
  const [deleteCustomMessage] = useLazyDeleteCustomMessageQuery()
  const [loading, setLoading] = useState(false)

  const handleDelete = async () => {
    if (!messageId) return
    try {
      setLoading(true)
      await deleteCustomMessage({ message_id: messageId })
      await refetchGetCustomMessages()
      setLoading(false)
      onClose()
      message.success(`Template '${messageName}' has been deleted successfully`)
    } catch (error) {
      setLoading(false)
      message.error(`Failed to delete template '${messageName}'`)
    }
  }
  return (
    <>
      <Modal
        className='modalStyleNewDeal'
        centered
        open={open}
        okButtonProps={{ className: 'hidden' }}
        cancelButtonProps={{ className: 'hidden' }}
        onCancel={onClose}
        width={'fit-content'}
        style={{ minWidth: 380, maxWidth: 380 }}>
        <div className={styles.contentStyle}>
          <div className={styles.container}>
            {loading ? (
              <LoadingBox />
            ) : (
              <>
                {' '}
                <img src={bin} alt='' />
                <h3>Delete Template '{messageName}'?</h3>
                <p>
                  Are you sure you want to delete the template named '{messageName}'? This process cannot be undone.
                </p>
                <Button className={styles.btnRed} onClick={handleDelete}>
                  Yes, Delete
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default DeleteTemplateModal
