import React from 'react'
import { Popover } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import styles from './PricingForm.module.scss'
import info_icon from 'common/assets/svg/info_icon.svg'

// const { Panel } = Collapse

interface CreditsProps {
  points: any
  credits: {
    email: string
    telegram: string
    outreach: string
  }
}

const Credits: React.FC<CreditsProps> = ({ points, credits }) => {
  const creditsContent = (
    <span>
      <CheckOutlined
        style={{
          padding: '1.5px',
          color: 'black',
          fontSize: '12px',
          marginRight: '5px',
        }}
      />
      {/* <span>Email Credits:</span> <span style={{ fontWeight: 400 }}>{credits.email}</span> */}
      <span>Email Exposing</span>
      <br />
      <CheckOutlined
        style={{
          padding: '1.5px',
          color: 'black',
          fontSize: '12px',
          marginRight: '5px',
        }}
      />
      {/* <span>Telegram Credits:</span> <span style={{ fontWeight: 400 }}>{credits.telegram}</span> */}
      <span>Telegram Exposing</span>
      <br />
      <CheckOutlined
        style={{
          padding: '1.5px',
          color: 'black',
          fontSize: '12px',
          marginRight: '5px',
        }}
      />
      {/* <span>Outreach Credits:</span> <span style={{ fontWeight: 400 }}>{credits.outreach}</span> */}
      <span>LinkedIn Exposing</span>
      <br />
      <CheckOutlined
        style={{
          padding: '1.5px',
          color: 'black',
          fontSize: '12px',
          marginRight: '5px',
        }}
      />
      <span>Companies CSV Export</span>
      <br />
      <CheckOutlined
        style={{
          padding: '1.5px',
          color: 'black',
          fontSize: '12px',
          marginRight: '5px',
        }}
      />
      <span>Contacts CSV Export</span>
      <br />
    </span>
  )

  return (
    <div className={styles.collapse}>
      <div>
        <CheckOutlined
          style={{
            padding: '1.5px',
            color: 'black',
            fontSize: '12px',
            marginRight: '5px',
          }}
        />
        <span>{points?.toLocaleString()} Convrt Points</span>
      </div>
      <Popover content={creditsContent} trigger='hover'>
        <img src={info_icon} alt={''} />
      </Popover>
    </div>
  )
}

export default Credits
