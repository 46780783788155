import React from 'react'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
// import { Line } from 'react-chartjs-2'

import './LineChart.scss'
import { Col, Row, Tag } from 'antd'
// import { GraphLegend } from '../../constants/label.constants'
import { CommonServices } from '../../services/common-services'
import { milestonesMap } from 'common/static-data/company-filter'

interface ILineChart {
  data: any
  milestones?: any
}

const LineChart: React.FC<ILineChart> = ({ data, milestones }) => {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //     customCanvasBackgroundColor: {
  //       color: 'lightGreen',
  //     },
  //   },
  //   scales: {
  //     y: {
  //       ticks: {
  //         display: false,
  //         beginAtZero: true,
  //       },
  //       grid: {
  //         drawBorder: false,
  //         display: false,
  //       },
  //     },
  //   },
  // }

  // const labels: string[] = CommonServices.filterByDistinctField(data, 'week_str').map((item) => item.week_str)
  const filterByIntent: any = CommonServices.filterByDistinctField(data, 'intent')

  let dataPoints: any = []
  filterByIntent.forEach((element: any) => {
    let point = data.filter((item: any) => item.intent === element.intent)
    dataPoints = [...dataPoints, point]
  })

  let dataGraph: any = []
  dataPoints?.forEach((element: any, index: number) => {
    let dataPoint = element.map((item: any) => item.count)
    dataGraph = [
      ...dataGraph,
      {
        data: dataPoint,
        borderColor:
          index === 0
            ? '#7043FF'
            : index === 1
            ? '#FF4FE6'
            : index === 2
            ? '#F6BE4E'
            : index === 3
            ? '#4EC4F6'
            : '7043FF',
        backgroundColor:
          index === 0
            ? '#7043FF'
            : index === 1
            ? '#FF4FE6'
            : index === 2
            ? '#F6BE4E'
            : index === 3
            ? '#4EC4F6'
            : '7043FF',
        pointRadius: 0,
      },
    ]
  })

  // const intentSignalsData = {
  //   labels,
  //   datasets: dataGraph,
  // }

  return (
    <>
      <div className='legend'>
        {/* <span className='frequencySection'>{GraphLegend.PRIME_INTENT}</span> */}
        {filterByIntent[0]?.intent && (
          <Row className='item'>
            <Col span={20} style={{ display: 'flex', alignItems: 'center' }}>
              <span className='bulletBlue'></span>
              <div className='intent-popup-title'>
                <span className='name'>{filterByIntent[0]?.intent}</span>
                <span style={{ paddingLeft: '12px' }}>INTERESTS</span>
              </div>
            </Col>
            <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
              <span className='frequency'>
                {data.filter((item: any) => item.intent === filterByIntent[0]?.intent) &&
                  filterByIntent[0]?.intent_strength}
              </span>
            </Col>
          </Row>
        )}
      </div>
      {/* <Line options={options} data={intentSignalsData} className='customGraph' /> */}
      <div className='legend'>
        {/* <span className='frequencySection'>{GraphLegend.MINOR_INTENTS}</span> */}
        {filterByIntent[1]?.intent && (
          <Row className='item'>
            <Col span={20} style={{ display: 'flex', alignItems: 'center' }}>
              <span className='bulletBlue'></span>
              <div className='intent-popup-title'>
                <span className='name'>{filterByIntent[1]?.intent}</span>
                <span style={{ paddingLeft: '12px' }}>INTERvESTS</span>
              </div>
            </Col>
            <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
              <span className='frequency'>
                {data.filter((item: any) => item.intent === filterByIntent[1]?.intent) &&
                  filterByIntent[1]?.intent_strength}
              </span>
            </Col>
          </Row>
        )}

        {milestones?.map((value: any) => {
          const updatedValue =
            value === 'Testnet' ? 'Soon to be launched' : value === 'Launching product' ? 'Soon to be launched' : value
          if (!updatedValue) {
            return null
          }
          return (
            <Row className='item'>
              <Col span={15} style={{ display: 'flex', alignItems: 'center' }}>
                <span className='bulletBlue'></span>
                <div className='intent-popup-title'>
                  <span className='name'>{milestonesMap[updatedValue]?.label}</span>
                  <span style={{ paddingLeft: '12px' }}>MILESTONES</span>
                </div>
              </Col>
              <Col span={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Tag className='fundingFlag' color={milestonesMap[updatedValue]?.color}>
                  {milestonesMap[updatedValue]?.label.toUpperCase()}
                </Tag>
              </Col>
            </Row>

            // <div>
            //   <Tag className='fundingFlag' color={milestonesMap[value]?.color}>
            //     {milestonesMap[value]?.label.toUpperCase()}
            //   </Tag>
            // </div>
          )
        })}

        {/* {filterByIntent[2]?.intent && (
          <Row className='item'>
            <Col span={20} style={{ display: 'flex', alignItems: 'center' }}>
              <span className='bulletYellow'></span>
              <div className='intent-popup-title'>
                <span className='name'>{filterByIntent[2]?.intent}</span>
                <span style={{ paddingLeft: '12px' }}>INTERESTS</span>
              </div>
            </Col>
            <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
              <span className='frequency'>
                {data.filter((item: any) => item.intent === filterByIntent[2]?.intent) &&
                  filterByIntent[2]?.intent_strength}
              </span>
            </Col>
          </Row>
        )} */}

        {/* {filterByIntent[3]?.intent && (
          <Row className='item'>
            <Col span={20}>
              <span className='bulletLightBlue'></span>
              <span className='name'>{filterByIntent[3]?.intent}</span>
            </Col>
            <Col span={4}>
              <span className='frequency' style={{ display: 'flex', alignItems: 'center' }}>
                {data.filter((item: any) => item.intent === filterByIntent[3]?.intent) &&
                  filterByIntent[3]?.intent_strength}
              </span>
            </Col>
          </Row>
        )} */}
      </div>
    </>
  )
}

export default LineChart
