import { useAuth } from 'common/hooks/useAuth.hooks'
import styles from './OnBoardingPageG.module.scss'

const videoSrc = require('../../../assets/mp4/connect_plus.mp4') as any

const OnBoardingPageG = () => {
  const { userDetails } = useAuth()
  const userType = userDetails.subscription_type
  return (
    <div className={styles.container}>
      <div className={styles.containerText}>
        <p>{'Leverage Your Telegram Power with Connect+'}</p>
        <section>
          Gain valuable insights into your network and maximize the power of your Telegram network for warm
          introductions and relationship building.
          <br />
          {userType === 'BETA' && (
            <span style={{ fontWeight: 600 }}>Also, use your Telegram account for outreach to prospects.</span>
          )}
        </section>
      </div>
      <div className={styles.animation}>
        <video src={videoSrc} autoPlay loop muted />
      </div>
    </div>
  )
}

export default OnBoardingPageG
