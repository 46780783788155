import { Button } from 'antd'
import classnames from 'classnames'

import { ReactComponent as Lock } from 'common/assets/svg/lock.svg'

import './BlurPaywall.scss'

interface IBlurPaywall {
  addon: any
  component: any
  onClick?: any
  customClass?: string
}

const BlurPaywall = ({ addon, onClick: handleClick, component: Component, customClass = '' }: IBlurPaywall) => {
  const handleRedirect = () => {
    if (handleClick) handleClick()
    window.open(`https://calendly.com/roy-convrt/demo-convrt`, '_blank', 'noreferrer')
  }

  return (
    <>
      <div className='blur'>{Component}</div>
      <div className={classnames('company-details-paywall', customClass)}>
        <Lock />
        <p>
          {`${addon} is an advanced feature that can help you find the companies with the highest probability to Convrt to a customer/partnership.`}
        </p>
        <Button onClick={handleRedirect}>{'Contact Sales'}</Button>
      </div>
    </>
  )
}

export default BlurPaywall
