import { useEffect, useState } from 'react'

import { Button, Tooltip, Typography } from 'antd'

import styles from './ExposedContacts.module.scss'
import { useAppDispatch, useAppSelector } from 'state'

import { SideBar } from 'features/my-contact/components/Sidebar'
import { SET_LISTS } from 'features/my-contact/state/slice/companiesSlice'
import { SET_LISTS as SET_LISTS_GLOBAL } from 'features/intent-signals/state/slice/companiesSlice'

import { useLazyGetListsQuery } from 'features/intent-signals/state/api/CompaniesApi'
import { SET_CURRENT_CUSTOM_LIST } from './state/importContactSlice'
import { getResultFromImport } from './outreach.helper'
import ConnectPlusModal from 'common/components/ConnectPlus/ConnectPlusModal'
import { AddListSider } from './components/AddListSider'
import ProgressBar from 'common/components/OutreachAI/HomePage/CampaignsTab/ProgressBar'
import {
  useGetContributorLogsQuery,
  useImportFromTelegramMutation,
  useUpdateImportTelegramStatusMutation,
} from './state/api/OutreachApi'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'common/hooks/useAuth.hooks'
import classNames from 'classnames'
import { useLocalStorage } from 'common/hooks/useLocalStorage.hooks'

const NOT_IMPORTED = 'not imported'
const IMPORTING = 'importing'
const IMPORTED = 'imported'
const IMPORT_FAILED = 'import failed'

const ImportTelegramContacts = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { refetchUserLite, userDetails } = useAuth()
  const [, setUser] = useLocalStorage('user', null)
  const { currentCustomList: currentList } = useAppSelector((state) => state.importContact)

  const { lists } = useAppSelector((state) => state.myContactsCompanies)
  const setList = (data: any) => {
    dispatch(SET_LISTS(data))
    dispatch(SET_LISTS_GLOBAL(data))
  }
  const setCurrentList = (data: any) => dispatch(SET_CURRENT_CUSTOM_LIST(data))

  const [currentDefaultList, setCurrentDefaultList] = useState('import_telegram_contacts')
  const [openImportModal, setOpenImportModal] = useState(false)
  const [openSiderAddList, setOpenSiderAddList] = useState(false)
  const [progress, setProgress] = useState<any>(null)

  const [refetchLists] = useLazyGetListsQuery()

  const [lastSync, setLastSync] = useState<any>()
  const [contributorLogsParams, setContributorLogsParams] = useState<any>()
  const [importFromTelegram] = useImportFromTelegramMutation()
  const [updateImportTelegramStatus] = useUpdateImportTelegramStatusMutation()
  const [importStatus, setImportStatus] = useState<string>()
  const { data: contributorLogsData, isFetching: isFetchingContributorLog } = useGetContributorLogsQuery(
    contributorLogsParams,
    {
      skip: progress >= 50 || importStatus === IMPORT_FAILED || !contributorLogsParams,
      pollingInterval: 3000,
    },
  )

  const importLeads = async (params: any) => {
    try {
      const response = await importFromTelegram(params)
      const user = response.data.user
      refetchUserLite({
        ...userDetails,
        outreach: user.outreach,
        connected_telegrams: user.connected_telegrams,
        is_imported_telegram: true,
      })

      setUser({
        ...user,
        outreach: user.outreach,
        connected_telegrams: user.connected_telegrams,
        is_imported_telegram: true,
      })
    } catch (error) {
      console.error(`Error importing from telegram: ${error}`)
    }
  }

  const handleImportFailed = async () => {
    try {
      await updateImportTelegramStatus({
        ...contributorLogsParams,
        status: 'failed',
      })
      setImportStatus(IMPORT_FAILED)
      setProgress(0)
      localStorage.setItem(
        'imported_telegram',
        JSON.stringify({
          ...contributorLogsParams,
          progress: 0,
        }),
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (contributorLogsData && contributorLogsData.length > 0) {
      if (contributorLogsData[0].status === 'failed') {
        handleImportFailed()
      } else {
        if (contributorLogsData[0].completion >= 50) {
          importLeads(contributorLogsParams)
        }
        setProgress(contributorLogsData[0].completion)
        localStorage.setItem(
          'imported_telegram',
          JSON.stringify({
            ...contributorLogsParams,
            progress: contributorLogsData[0].completion,
          }),
        )
        if (!lastSync) {
          setLastSync(new Date())
        }
      }
    }
  }, [contributorLogsData, isFetchingContributorLog])

  useEffect(() => {
    if (contributorLogsParams) setImportStatus(IMPORTING)
  }, [contributorLogsParams])

  const fetchList = async () => {
    try {
      const res = await refetchLists()
      if (res) {
        setList(res.data)

        const resultImport = getResultFromImport()
        if (resultImport) {
          const { listId } = resultImport
          const newList = res.data?.find((item: any) => item?.id === listId)

          if (newList) setCurrentList(newList)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeCustomList = (list: any) => {
    localStorage.setItem('current_list', JSON.stringify(list))
    navigate('/exposedContacts')
    setCurrentList(list)
    setCurrentDefaultList('')
  }

  const handleAddNewListSuccess = (lists: any) => {
    setOpenSiderAddList(false)
    setCurrentDefaultList('')
    setCurrentList(lists?.[0])
    setList(lists)

    localStorage.setItem('recentAddedList', JSON.stringify(lists?.[0]))
  }

  const retrySync = () => {
    setOpenImportModal(true)
    setContributorLogsParams(null)
  }

  useEffect(() => {
    let importedAccount = localStorage.getItem('imported_telegram')
      ? JSON.parse(localStorage.getItem('imported_telegram') || '')
      : null
    const importedProgress = importedAccount?.progress
    if (userDetails && userDetails.outreach && userDetails.outreach.length) {
      importedAccount = userDetails.outreach.find((item: any) => item.import_at)
    }
    if (userDetails && userDetails.connected_telegrams && userDetails.connected_telegrams.length) {
      importedAccount = userDetails.connected_telegrams.find((item: any) => item.import_at)
    }
    if (!importedAccount) {
      setImportStatus(NOT_IMPORTED)
    } else if (importedAccount.import_status === 'in progress') {
      setImportStatus(IMPORTING)
      setContributorLogsParams({
        area_code: importedAccount.area_code,
        number: importedAccount.number,
        email: importedAccount.email,
      })
      setLastSync(importedAccount.import_at)
      if (importedProgress) setProgress(importedProgress)
    } else {
      setImportStatus(IMPORTED)
    }
  }, [userDetails])

  useEffect(() => {
    fetchList()
  }, [])

  const renderContentEmptyCustomList = () => {
    return (
      <div className={styles.emptyList}>
        <div>
          <Typography className={styles.emptyList__title}>Import Contacts Directly From Your Telegram</Typography>
          <Typography className={styles.emptyList__description}>
            Sync your telegram to get your contacts, direct chats and small groups members
          </Typography>
        </div>
        <Button type='primary' className={styles.emptyList__button} onClick={() => setOpenImportModal(true)}>
          Sync Telegram
        </Button>
      </div>
    )
  }

  const renderContentProgress = () => {
    return (
      <div className={styles.emptyList}>
        <div>
          <Typography className={styles.emptyList__title}>Syncing Contacts</Typography>
          <ProgressBar percentage={progress || 0} size='xl' />
        </div>
      </div>
    )
  }

  const renderSyncingFailed = () => {
    return (
      <div className={styles.emptyList}>
        <div>
          <Typography className={styles.emptyList__title}>Syncing Failed</Typography>
          <Button
            type='primary'
            className={classNames('mx-auto', styles.emptyList__button)}
            onClick={() => retrySync()}>
            Retry sync
          </Button>
        </div>
      </div>
    )
  }

  const renderSyncedSuccessfully = () => {
    return (
      <div className={styles.emptyList}>
        <div>
          <Typography className={styles.emptyList__title}>Contacts Synced Successfully</Typography>
          <Typography className={styles.emptyList__description}>
            Last sync: {(lastSync ? new Date(lastSync) : new Date()).toLocaleDateString('de-DE')}
          </Typography>
        </div>
        <Tooltip title='Coming Soon'>
          <Button type='primary' className={styles.emptyList__button}>
            Resync Telegram
          </Button>
        </Tooltip>
      </div>
    )
  }

  return (
    <div className={`${styles['myContact']} myContact`}>
      <SideBar
        setIndexDefault={() => {}}
        listContact={lists}
        currentDefaultList={currentDefaultList}
        currentList={currentList}
        onCreateList={() => setOpenSiderAddList(true)}
        onChangeCurrentDetailList={(value) => {
          setCurrentDefaultList(value)
          setCurrentList(null)
        }}
        onChangeCustomList={handleChangeCustomList}
        refetchLists={refetchLists}
        onChangeNewList={setList}
      />

      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}>
        <div className={styles['myContact__top']}>
          <div className={styles['myContact__top__left']}>
            {currentList ? currentList.name : 'Import Telegram Contacts'}
          </div>

          <div className={styles['myContact__top__right']}>
            <Button type='primary' onClick={() => setOpenSiderAddList(true)}>
              Add New List
            </Button>
          </div>
        </div>
        {userDetails && importStatus
          ? importStatus === NOT_IMPORTED
            ? renderContentEmptyCustomList()
            : importStatus === IMPORTING
            ? !openImportModal && renderContentProgress()
            : importStatus === IMPORTED
            ? renderSyncedSuccessfully()
            : importStatus === IMPORT_FAILED && renderSyncingFailed()
          : null}
      </div>

      <ConnectPlusModal
        open={openImportModal}
        onClose={() => setOpenImportModal(false)}
        setContributorLogsParams={(data) => {
          setContributorLogsParams(data)
        }}
        setProgress={(data) => {
          setProgress(data)
        }}
        isImportTelegram={true}
      />

      <AddListSider
        isCompany={true}
        open={openSiderAddList}
        onClose={() => setOpenSiderAddList(false)}
        list={lists}
        onAddSuccess={handleAddNewListSuccess}
        refetchLists={refetchLists}
      />
    </div>
  )
}

export default ImportTelegramContacts
