import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import convrt_logo_login from '../../common/assets/svg/convrt_logo_login.svg'
import styles from './SignupSuccess.module.scss'
import { AuthService } from 'common/services/authServices'
import { Button, Input, message as antdMessage } from 'antd'

const SignupSuccess: React.FC = () => {
  const [error, setError] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [showResendEmailInput, setShowResendEmailInput] = useState(false)
  const [resendEmail, setResendEmail] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token')

  useEffect(() => {
    if (token) {
      AuthService.checkEmailToken(token)
        .then((response: any) => {
          if (response.error) {
            setError(true)
          } else {
            setConfirmed(true)
          }
        })
        .catch((err: any) => {
          console.log(err)
          setError(true)
        })
    }
  }, [location.search])

  const handleResendEmail = () => {
    if (!showResendEmailInput) {
      setShowResendEmailInput(true)
      return
    }

    if (!resendEmail) {
      antdMessage.error('Please enter your email address.')
      return
    }

    AuthService.resendConfirmationEmail(resendEmail)
      .then((response: any) => {
        if (response.error) {
          antdMessage.error(response.message)
        } else {
          antdMessage.success(response.message)
        }
      })
      .catch((err: any) => {
        antdMessage.error(err.message || 'Failed to resend email.')
      })
  }
  // return (
  //   <>
  //     <VideoBg srcType={1} />
  //     <div className={styles.overlay}></div>
  //     <div className={styles.signupContainer}>
  //       <img src={convrtSignup} alt='Convrt' className={styles.logo} />
  //       <div className={styles.emailConfirmation}>
  //         {error ? (
  //           <>
  //             <h2>Email Confirmation Failed</h2>
  //             <p>Your token is invalid.</p>
  //             {!showResendEmailInput && (
  //               <div>
  //                 <p style={{ color: 'gray' }}>
  //                   Email did not recived?{' '}
  //                   <span style={{ cursor: 'pointer', fontWeight: 600, color: '#7043ff' }} onClick={handleResendEmail}>
  //                     Resend Email
  //                   </span>
  //                 </p>
  //               </div>
  //             )}
  //             {showResendEmailInput && (
  //               <div className={styles.inputEmail}>
  //                 <Input
  //                   value={resendEmail}
  //                   onChange={(e) => setResendEmail(e.target.value)}
  //                   placeholder='Enter your email'
  //                 />
  //                 <Button onClick={handleResendEmail}>Send Email</Button>
  //               </div>
  //             )}
  //             <p style={{ color: 'gray', marginBottom: '0px' }}>
  //               Having Trouble?{' '}
  //               <a href='https://calendly.com/roy-convrt/demo-convrt' target='_blank' rel='noopener noreferrer'>
  //                 Contact us
  //               </a>
  //             </p>
  //           </>
  //         ) : confirmed ? (
  //           <>
  //             <h2>Email Confirmation Completed!</h2>
  //             <p>You can now start exploring Convrt with endless opportunities!</p>
  //             <Button
  //               type='primary'
  //               style={{ marginTop: '10px' }}
  //               onClick={() => {
  //                 navigate('/login')
  //               }}>
  //               Back to Login Page
  //             </Button>
  //           </>
  //         ) : (
  //           <>
  //             <h2>You need to confirm your email</h2>
  //             <p>
  //               Start using Convrt after confirming your email address by clicking the link in the email we sent you.
  //             </p>
  //             {!showResendEmailInput && (
  //               <div>
  //                 <p style={{ color: 'gray' }}>
  //                   Email did not recived?{' '}
  //                   <span style={{ cursor: 'pointer', fontWeight: 600, color: '#7043ff' }} onClick={handleResendEmail}>
  //                     Resend Email
  //                   </span>
  //                 </p>
  //               </div>
  //             )}
  //             {showResendEmailInput && (
  //               <div className={styles.inputEmail}>
  //                 <Input
  //                   value={resendEmail}
  //                   onChange={(e) => setResendEmail(e.target.value)}
  //                   placeholder='Enter your email'
  //                 />
  //                 <Button onClick={handleResendEmail}>Send Email</Button>
  //               </div>
  //             )}
  //             <p style={{ color: 'gray', marginBottom: '0px' }}>
  //               Having Trouble?{' '}
  //               <a href='https://calendly.com/roy-convrt/demo-convrt' target='_blank' rel='noopener noreferrer'>
  //                 Contact us
  //               </a>
  //             </p>
  //           </>
  //         )}
  //       </div>
  //     </div>
  //   </>
  // )

  return (
    <div className={styles.holdingContainer}>
      <div className={styles.logo}>
        <img src={convrt_logo_login} alt='' />
      </div>
      <div className={styles.form}>
        <div className={styles.emailConfirmation}>
          {error ? (
            <>
              <h2>Email Confirmation Failed</h2>
              <p>Your token is invalid.</p>
              {!showResendEmailInput && (
                <div>
                  <p style={{ color: 'gray' }}>
                    Email did not recived?{' '}
                    <span style={{ cursor: 'pointer', fontWeight: 600, color: '#7043ff' }} onClick={handleResendEmail}>
                      Resend Email
                    </span>
                  </p>
                </div>
              )}
              {showResendEmailInput && (
                <div className={styles.inputEmail}>
                  <Input
                    value={resendEmail}
                    onChange={(e) => setResendEmail(e.target.value)}
                    placeholder='Enter your email'
                  />
                  <Button onClick={handleResendEmail}>Send Email</Button>
                </div>
              )}
              <p style={{ color: 'gray', marginBottom: '0px' }}>
                Having Trouble?{' '}
                <a href='https://calendly.com/roy-convrt/demo-convrt' target='_blank' rel='noopener noreferrer'>
                  Contact us
                </a>
              </p>
            </>
          ) : confirmed ? (
            <>
              <h2>Email Confirmation Completed!</h2>
              <p>You can now start exploring Convrt with endless opportunities!</p>
              <Button
                type='primary'
                style={{ marginTop: '10px' }}
                onClick={() => {
                  navigate('/login')
                }}>
                Back to Login Page
              </Button>
            </>
          ) : (
            <>
              <h2>You need to confirm your email</h2>
              <p>
                Start using Convrt after confirming your email address by clicking the link in the email we sent you.
              </p>
              {!showResendEmailInput && (
                <div>
                  <p style={{ color: 'gray' }}>
                    Email did not recived?{' '}
                    <span style={{ cursor: 'pointer', fontWeight: 600, color: '#7043ff' }} onClick={handleResendEmail}>
                      Resend Email
                    </span>
                  </p>
                </div>
              )}
              {showResendEmailInput && (
                <div className={styles.inputEmail}>
                  <Input
                    value={resendEmail}
                    onChange={(e) => setResendEmail(e.target.value)}
                    placeholder='Enter your email'
                  />
                  <Button onClick={handleResendEmail}>Send Email</Button>
                </div>
              )}
              <p style={{ color: 'gray', marginBottom: '0px' }}>
                Having Trouble?{' '}
                <a href='https://calendly.com/roy-convrt/demo-convrt' target='_blank' rel='noopener noreferrer'>
                  Contact us
                </a>
              </p>
            </>
          )}
        </div>
      </div>
      <div className={styles.footer}></div>
    </div>
  )
}

export default SignupSuccess
