import React, { useState, createContext, useContext } from 'react'

export const ConnectPlusContext = createContext<any>(null)

export const ConnectPlusProvider = ({ children }: { children: any }) => {
  const [connectPlusSum, setConnectPlusSum] = useState(0)
  const [connectPlusPictures, setConnectPlusPictures] = useState([])

  return (
    <ConnectPlusContext.Provider
      value={{
        connectPlusPictures,
        setConnectPlusPictures,
        connectPlusSum,
        setConnectPlusSum,
      }}>
      {children}
    </ConnectPlusContext.Provider>
  )
}

export const useConnectPlus = () => useContext(ConnectPlusContext)
