import React from 'react'
import './LoadingDots.css'

const LoadingDots: React.FC = () => {
  return (
    <div className='loading'>
      <span className='loading__dot'></span>
      <span className='loading__dot'></span>
      <span className='loading__dot'></span>
    </div>
  )
}

export default LoadingDots
