import React, { useState } from 'react'
import styles from './MessagesTemplates.module.scss'
import { Dropdown, MenuProps, Tag, Tooltip } from 'antd'
import increase_icon from 'common/assets/svg/increase_icon.svg'
import right_purple_arrow from 'common/assets/svg/right_purple_arrow.svg'
import three_dots from 'common/assets/svg/three_dots.svg'
import { TemplateSider } from './TemplateSider'
import DeleteTemplateModal from './DeleteTemplateModal'

interface TemplateProps {
  messageId: any
  message: string
  messageType: string
  messageName: string
  responseRate: string
  updatedAt: string
  onClick: any
  showTag?: boolean
  isCustom?: boolean
  refetchGetCustomMessages?: any
}

// Utility function to convert timestamp to "X Y ago"
const timeAgo = (date: string) => {
  const now = new Date()
  const updatedAt = new Date(date)
  const diffInSeconds = Math.floor((now.getTime() - updatedAt.getTime()) / 1000)

  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 },
  ]

  for (const interval of intervals) {
    const count = Math.floor(diffInSeconds / interval.seconds)
    if (count >= 1) {
      return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`
    }
  }

  return 'Just Now'
}

const IconBin = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8.42857 3.51451C8.32009 3.51451 8.23214 3.60245 8.23214 3.71094V5.47879H11.7679V3.71094C11.7679 3.60245 11.6799 3.51451 11.5714 3.51451H8.42857ZM12.9464 5.47879V3.71094C12.9464 2.95155 12.3308 2.33594 11.5714 2.33594H8.42857C7.66918 2.33594 7.05357 2.95155 7.05357 3.71094V5.47879H4.50689C4.50274 5.47875 4.49859 5.47875 4.49442 5.47879H3.71429C3.38883 5.47879 3.125 5.74263 3.125 6.06808C3.125 6.39353 3.38883 6.65737 3.71429 6.65737H3.95778L4.69659 15.5231C4.71073 16.7042 5.67263 17.6574 6.85714 17.6574H13.1429C14.3274 17.6574 15.2893 16.7042 15.3034 15.5231L16.0422 6.65737H16.2857C16.6112 6.65737 16.875 6.39353 16.875 6.06808C16.875 5.74263 16.6112 5.47879 16.2857 5.47879H15.5056C15.5014 5.47875 15.4973 5.47875 15.4931 5.47879H12.9464ZM5.14044 6.65737L5.87296 15.4477C5.87432 15.464 5.875 15.4803 5.875 15.4967C5.875 16.0391 6.31472 16.4788 6.85714 16.4788H13.1429C13.6853 16.4788 14.125 16.0391 14.125 15.4967C14.125 15.4803 14.1257 15.464 14.127 15.4477L14.8596 6.65737H5.14044ZM8.42857 8.62165C8.75402 8.62165 9.01786 8.88548 9.01786 9.21094V13.9252C9.01786 14.2507 8.75402 14.5145 8.42857 14.5145C8.10312 14.5145 7.83929 14.2507 7.83929 13.9252V9.21094C7.83929 8.88548 8.10312 8.62165 8.42857 8.62165ZM11.5714 8.62165C11.8969 8.62165 12.1607 8.88548 12.1607 9.21094V13.9252C12.1607 14.2507 11.8969 14.5145 11.5714 14.5145C11.246 14.5145 10.9821 14.2507 10.9821 13.9252V9.21094C10.9821 8.88548 11.246 8.62165 11.5714 8.62165Z'
        fill='url(#paint0_linear_4274_428959)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_4274_428959'
          x1='3.125'
          y1='-10.5697'
          x2='27.5172'
          y2='-3.5903'
          gradientUnits='userSpaceOnUse'>
          <stop offset='0.24' stop-color='currentColor' />
          <stop offset='1' stop-color='currentColor' />
        </linearGradient>
      </defs>
    </svg>
  )
}

const IconEdit = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.29781 4.74162H4.65928C4.30779 4.74162 3.97069 4.88125 3.72215 5.12979C3.47361 5.37833 3.33398 5.71543 3.33398 6.06692V15.344C3.33398 15.6955 3.47361 16.0326 3.72215 16.2811C3.97069 16.5296 4.30779 16.6693 4.65928 16.6693H13.9363C14.2878 16.6693 14.6249 16.5296 14.8735 16.2811C15.122 16.0326 15.2616 15.6955 15.2616 15.344V10.7054M14.2677 3.74765C14.5313 3.48404 14.8888 3.33594 15.2616 3.33594C15.6344 3.33594 15.992 3.48404 16.2556 3.74765C16.5192 4.01127 16.6673 4.36881 16.6673 4.74162C16.6673 5.11444 16.5192 5.47198 16.2556 5.73559L9.96045 12.0307L7.30987 12.6934L7.97251 10.0428L14.2677 3.74765Z'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
const Message: React.FC<TemplateProps> = ({
  messageId,
  message,
  messageType,
  messageName,
  responseRate,
  updatedAt,
  onClick,
  showTag = true,
  isCustom = false,
  refetchGetCustomMessages,
}) => {
  const [showSaveTemplate, setShowSaveTemplate] = useState(false)
  const [showDeleteTemplate, setShowDeleteTemplate] = useState(false)

  const items: MenuProps['items'] = [
    {
      label: (
        <div className={styles.icon}>
          <IconEdit />
          <span>Edit Template</span>
        </div>
      ),
      key: '0',
    },
    {
      label: (
        <div className={styles.icon}>
          <IconBin />
          <span>Delete Template</span>
        </div>
      ),
      key: '1',
    },
  ]

  return (
    <div className={styles.template} onClick={onClick}>
      <div className={styles.header}>
        <p className='font-bold'>{messageName || 'Name'}</p>
        {showTag && (
          <div className={styles.tags}>
            <Tag style={{ borderRadius: '8px', color: '#7043ff' }} color='#F2F0FF'>
              {'Convrt'}
            </Tag>
            <Tag style={{ borderRadius: '8px', color: '#02C3AB' }} color='#02C3AB1A'>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={increase_icon} alt='' />
                <span>{responseRate}%</span>
              </div>
            </Tag>
          </div>
        )}
        {isCustom && (
          <Dropdown
            menu={{
              items: items,
              onClick: (e) =>
                e.key === '0' ? setShowSaveTemplate(true) : e.key === '1' ? setShowDeleteTemplate(true) : null,
            }}
            placement='bottomLeft'
            arrow={false}
            trigger={['click']}
            className={styles.dropDown}>
            <div className={styles.moreOptions}>
              <img src={three_dots} alt='' />
            </div>
          </Dropdown>
        )}
      </div>

      <Tooltip title={message} placement='left'>
        <div className={styles.body}>{message}</div>
      </Tooltip>
      <div className={styles.footer}>
        <span>Last Update: {timeAgo(updatedAt)}</span>
        <img src={right_purple_arrow} alt='' />
      </div>
      <TemplateSider
        open={showSaveTemplate}
        onClose={() => setShowSaveTemplate(false)}
        messageId={messageId}
        message={message}
        name={messageName}
        refetchGetCustomMessages={refetchGetCustomMessages}
      />
      <DeleteTemplateModal
        open={showDeleteTemplate}
        onClose={() => setShowDeleteTemplate(false)}
        messageId={messageId}
        messageName={messageName}
        refetchGetCustomMessages={refetchGetCustomMessages}
      />
    </div>
  )
}

export default Message
