import { ReactComponent as PrevMonth } from 'common/assets/svg/left-month.svg'
import { ReactComponent as NextMonth } from 'common/assets/svg/right-month.svg'
import { addMonths, format, subMonths } from 'date-fns'
import { SET_CURRENT_DATE } from 'features/events/state/slice/events.slice'
import { SyntheticEvent } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import './MonthSwitcher.scss'

const MonthSwitcher = () => {
  const dispatch = useAppDispatch()
  const { currentDate } = useAppSelector((state) => state.events)

  const currentDateFormatted = format(currentDate, 'MMMM yyyy')

  const onPrevMonth = (e: SyntheticEvent) => {
    e.preventDefault()
    dispatch(SET_CURRENT_DATE(subMonths(currentDate, 1)))
  }

  const onNextMonth = (e: SyntheticEvent) => {
    e.preventDefault()
    dispatch(SET_CURRENT_DATE(addMonths(currentDate, 1)))
  }

  const onReset = (e: SyntheticEvent) => {
    e.preventDefault()
    dispatch(SET_CURRENT_DATE(new Date()))
  }
  return (
    <div className='month-switcher'>
      <PrevMonth className='cursor-pointer' onClick={onPrevMonth} />
      <span className='cursor-pointer' onClick={onReset}>
        {currentDateFormatted}
      </span>
      <NextMonth className='cursor-pointer' onClick={onNextMonth} />
    </div>
  )
}

export default MonthSwitcher
