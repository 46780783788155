import React from 'react'
import { List, Skeleton } from 'antd'

interface LoadingGroupsSkeletonProps {
  listStyle?: any
  listItemStyle?: any
  skeletonStyle?: any
  avatar?: boolean
}

const LoadingGroupsSkeleton: React.FC<LoadingGroupsSkeletonProps> = ({
  listStyle = { marginTop: '0px' },
  listItemStyle = { padding: '0px 0px 0px 60px' },
  skeletonStyle = { paddingTop: '0px', display: 'flex', alignItems: 'center', opacity: 0.5 },
  avatar = true,
}) => {
  const listData = Array.from({ length: 8 }).map((_, i) => ({
    title: '',
  }))

  return (
    <List
      itemLayout='vertical'
      size='large'
      dataSource={listData}
      style={listStyle}
      renderItem={(item) => (
        <List.Item key={item.title} style={listItemStyle}>
          <Skeleton active avatar={false} title={false} paragraph={{ rows: 1, width: '100%' }} style={skeletonStyle} />
        </List.Item>
      )}
    />
  )
}

export default LoadingGroupsSkeleton
