import React, { useEffect, useState } from 'react'
import { Row, Col, Steps, Typography } from 'antd'
// import { Row, Col, Typography, Progress, Divider, Button, Tabs, Steps } from 'antd'

// import type { TabsProps } from 'antd'

import styles from './EditCampaignHeader.module.scss'
import left_arrow from 'common/assets/svg/left_arrow.svg'
import edit_icon from 'common/assets/svg/edit_icon.svg'
import campaign_timing_icon from 'common/assets/svg/campaign_timing_icon.svg'
import campaign_timing_icon_purple from 'common/assets/svg/campaign_timing_icon_purple.svg'

// import { useNavigate } from 'react-router-dom'
// import { useBeforeUnload } from 'react-router-dom'

import ExitModal from '../NewCampaign/UI/ExitModal'
import { DownOutlined } from '@ant-design/icons'
import CampaignTimingModal from '../NewCampaign/UI/CampaignTimingModal'
import { useLazyEditCampaignQuery, useLazyGetCampaignsSettingsQuery } from 'features/Outreach/state/api/OutreachApi'
import { SET_SEQUENCE_DATA } from '../state/outreachCampaignSlice'
import { useAppDispatch, useAppSelector } from 'state'
import { post } from 'common/api/axios'
import { transformToNewStructure } from '../NewCampaign/transformStepsUtil'

interface EditCampaignHeaderProps {
  nodesData?: any
  setNodesData?: any
  currentStep: any
  setCurrentStep?: any
  campaignId?: any
}

const EditCampaignHeader: React.FC<EditCampaignHeaderProps> = ({
  nodesData,
  setNodesData,
  currentStep,
  setCurrentStep,
  campaignId,
}) => {
  const { Paragraph } = Typography
  const [mixpanelData, setMixpanelData] = useState<any>({ campaign_id: campaignId })

  const [openExitModal, setOpenExitModal] = useState(false)
  const [openCampaignTimingModal, setOpenCampaignTimingModal] = useState(false)
  const [refetcGetCampaignSettings] = useLazyGetCampaignsSettingsQuery()
  const [refetchEditCampaign] = useLazyEditCampaignQuery()
  const [isHovered, setIsHovered] = useState(false)
  const { sequence_data, template } = useAppSelector((state) => state.outreachCampaign)
  const [timingDataForEdit, setTimingDataForEdit] = useState<any>()
  // eslint-disable-next-line
  const [editableCampaignName, setEditableCampaignName] = useState(nodesData.campaign_name)
  // eslint-disable-next-line
  const [editableCampaignDescription, setEditableCampaignDescription] = useState(nodesData.campaign_description)
  const dispatch = useAppDispatch()

  const handleNewCampaignClick = () => {
    if (currentStep === 1) {
      setCurrentStep(0)
      // setOpenExitModal(true)
    } else {
      setOpenExitModal(true)
    }
  }

  const onChange = (value: Number) => {
    setCurrentStep(value)
  }

  const handleSetCampaignName = (val: any) => {
    const newVal = val.replace(/'/g, '')
    setEditableCampaignName(newVal)
    setNodesData((prev: any) => {
      dispatch(SET_SEQUENCE_DATA({ ...prev, campaign_name: newVal }))
      return { ...prev, campaign_name: newVal }
    })
  }

  const handleSetCampaignDescription = (val: any) => {
    const newVal = val.replace(/'/g, '')
    setEditableCampaignDescription(newVal)
    setNodesData((prev: any) => {
      dispatch(SET_SEQUENCE_DATA({ ...prev, campaign_description: newVal }))
      return { ...prev, campaign_description: newVal }
    })
  }

  const handleOpenCampaignTimingModal = () => {
    setOpenCampaignTimingModal(true)
    post('/log', {
      action: 'Open Campaign Timing ',
      data: mixpanelData,
    })
  }

  const handleSetCampaignSettings = (value: any) => {
    setTimingDataForEdit({ whenToStart: value?.whenToStart, runInBetweenId: value?.runInBetweenHours?.schedule_id })
    setNodesData((prev: any) => {
      return { ...prev, settings: value }
    })
  }

  const handleEditCampaign = async () => {
    const campaign_name = sequence_data.campaign_name
    const campaign_description = sequence_data.campaign_description
    const status = sequence_data.status
    const settings = sequence_data.settings

    const steps = {
      ...sequence_data.steps,
      '1': {
        ...sequence_data.steps['1'],
        data: {
          ...sequence_data.steps['1'].data,
          messages: sequence_data.steps['1'].data.messages.filter((message: any) => message.trim() !== ''),
        },
      },
    }

    const dataArray = Object.keys(steps).map((key) => ({
      step_id: key,
      ...steps[key],
    }))

    const messages_steps = template === 3 || template === 4 ? transformToNewStructure(dataArray, 'linkedin') : undefined

    // console.log('-----')
    // console.log(dataArray)
    // console.log(messages_steps)
    // console.log(template)
    // console.log('-----')
    setMixpanelData({
      campaign_id: campaignId,
      campaign_name,
      status,
      settings,
    })
    await refetchEditCampaign({
      campaign_id: campaignId,
      campaign_name,
      campaign_description,
      status,
      steps,
      settings,
      edit_mode: true,
      messages_steps,
    })
  }

  useEffect(() => {
    setNodesData((prev: any) => ({
      ...prev,
      campaign_name: editableCampaignName,
      campaign_description: editableCampaignDescription,
    }))
  }, [])

  useEffect(() => {
    const getCampaignsSettings = async () => {
      const { data } = await refetcGetCampaignSettings({ campaign_id: campaignId })
      const response = data?.response?.[0]
      const whenToStart = response?.start_date
      const runInBetweenId = response?.schedule_id
      setTimingDataForEdit({ whenToStart: whenToStart, runInBetweenId: runInBetweenId })
    }

    getCampaignsSettings()
  }, [])

  return (
    <Row
      justify='space-between'
      align='middle'
      className={styles.header} // in global.css
      style={{
        padding: '25px 35px',
        backgroundColor: 'white',
        fontWeight: 600,
        // zIndex: 1000,
      }}>
      <Col>
        <div className={styles.leftSide}>
          <span style={{ marginRight: '10px', cursor: 'pointer' }} onClick={handleNewCampaignClick}>
            <img src={left_arrow} alt='left' />
          </span>
          <div className={styles.nameAndDescription}>
            <Paragraph
              style={{ margin: '0px 0px 5px 0px', width: '250px' }}
              //   editable={editableCampaignName ? false : true}
              editable={{
                onChange: handleSetCampaignName,
                maxLength: 30,
                autoSize: { maxRows: 1, minRows: 1 },
                icon: <img style={{ margin: '0px', height: '13px', width: '13px' }} src={edit_icon} alt='' />,
              }}>
              {editableCampaignName}
            </Paragraph>
            <Paragraph
              style={{ margin: '0px', width: '510px', color: '#97a0af', fontSize: '12px' }}
              //   editable={editableCampaignDescription ? false : true}
              editable={{
                onChange: handleSetCampaignDescription,
                maxLength: 80,
                autoSize: { maxRows: 1, minRows: 1 },
                icon: <img style={{ margin: '0px', height: '13px', width: '13px' }} src={edit_icon} alt='' />,
              }}>
              {editableCampaignDescription !== 'undefined' ? editableCampaignDescription : ''}
            </Paragraph>
          </div>
        </div>
      </Col>
      {/* <Col>
        <Steps
          current={currentStep}
          // onChange={onChange}
          size='small'
          items={[
            {
              title: 'Sequence',
            },
            {
              title: 'Leads',
            },
          ]}
        />
      </Col> */}
      <Col span={5}>
        <Steps
          current={currentStep}
          type='navigation'
          onChange={onChange}
          size='small'
          items={[
            {
              title: 'Sequence',
            },
            {
              title: 'Leads',
              onClick: handleEditCampaign,
              // disabled: !campaign_id,
              // disabled: currentStep === 0,
            },
          ]}
        />
      </Col>
      <Col>
        <div
          className={styles.rightSide}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleOpenCampaignTimingModal}>
          <img src={isHovered ? campaign_timing_icon_purple : campaign_timing_icon} alt='' />
          <span style={{ margin: '0px 5px' }}> Campaign Timing</span>
          <DownOutlined style={{ fontSize: '12px' }} />
        </div>
      </Col>
      {openExitModal && (
        <ExitModal
          open={openExitModal}
          onClose={() => {
            setOpenExitModal(false)
          }}
        />
      )}
      {openCampaignTimingModal && (
        <CampaignTimingModal
          open={openCampaignTimingModal}
          onClose={() => {
            setOpenCampaignTimingModal(false)
          }}
          onFinish={(value) => {
            handleSetCampaignSettings(value)
          }}
          nodesData={{ ...nodesData, campaign_id: campaignId }}
          setNodesData={setNodesData}
          edit_mode={true}
          timingDataForEdit={timingDataForEdit}
        />
      )}
    </Row>
  )
}

export default EditCampaignHeader
