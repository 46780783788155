import React from 'react'
import { Alert, Button } from 'antd'
import { useNavigate } from 'react-router-dom'

interface NotificationStripProps {
  message: string
  actionText?: string
  type?: 'info' | 'warning' | 'success' | 'error'
  modalType?: 'upgrade'
  data?: any
}

const NotificationStrip: React.FC<NotificationStripProps> = ({
  message,
  actionText,
  type = 'info',
  modalType,
  data,
}) => {
  const navigate = useNavigate()
  // const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    if (modalType === 'upgrade') {
      return navigate('/checkout')
    }
    // setIsModalOpen(true)
  }

  return (
    <div>
      <Alert
        banner
        message={message}
        type={type}
        action={
          actionText && (
            <Button size='small' onClick={showModal}>
              {actionText}
            </Button>
          )
        }
        showIcon
      />
    </div>
  )
}

export default NotificationStrip
