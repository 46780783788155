import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { Button, Col, Form, Input, Modal, Popconfirm, Typography, message } from 'antd'

import FancyTable from '../../common/components/fancy-table/fancy-table'
import { AuthService } from '../../common/services/authServices'
import { useLazyDeleteUserQuery, useLazyDisableUserQuery, useLazyGetUsersListQuery } from './state/api/AdminCompanyApi'

import styles from './company-admin-pannel.module.scss'
import { ICompanyAdminTable } from './company-admin.interface'

const { Title } = Typography

const CompanyAdminPannel: React.FC = () => {
  const navigate = useNavigate()

  const [refetchUsersList, { data: users }] = useLazyGetUsersListQuery()
  const [refetchDeleteUser] = useLazyDeleteUserQuery()
  const [refetchDisableUser] = useLazyDisableUserQuery()
  const [tableParams, setTableParams] = useState<TablePaginationConfig>({ current: 1, pageSize: 10 })
  const [openAddNewUser, setOpenAddNewUser] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  let userDetails: any = null
  if (localStorage.getItem('user') !== null) {
    userDetails = JSON.parse(JSON.stringify(localStorage.getItem('user')))
  }

  const companyAdminPanel: ColumnsType<ICompanyAdminTable> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 50,
      render: (name: string, item: any) => {
        return <span>{name}</span>
      },
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: 50,
      render: (company: string, item: any) => {
        return <span>{company}</span>
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 50,

      render: (text: string, item: any) => {
        return <span>{text}</span>
      },
    },
    {
      title: 'Domain',
      dataIndex: 'email',
      key: 'domain',
      width: 50,

      render: (text: string, item: any) => {
        return <span>{text.split('@')[1]}</span>
      },
    },
    {
      title: 'Status',
      dataIndex: 'disabled',
      key: 'status',
      width: 50,
      render: (text: boolean, item: any) => {
        return <span>{text ? 'Disabled' : 'Enabled'}</span>
      },
    },
    {
      title: 'User Type',
      dataIndex: 'disabled',
      key: 'userType',
      width: 50,

      render: (text: boolean, item: any) => {
        return <span>{!item.company_admin ? 'User' : 'Admin'}</span>
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (text: string, item: any) => {
        if (!item.company_admin) {
          return (
            <div className='actionBtns' style={{ minHeight: '50px' }}>
              <Popconfirm
                title={`${item?.disabled ? 'Enable' : 'Disable'} user`}
                description={
                  <Col>
                    <div>{`Are you sure that you want to ${item?.disabled ? 'enable' : 'disable'} this user?`}</div>
                  </Col>
                }
                onConfirm={async () => {
                  const result = await refetchDisableUser({ user_id: item })
                  if (result) {
                    await refetchUsersList()
                    message.success(`${item?.disabled ? 'Enabled' : 'Disabled'} successfully`)
                  }
                }}
                onCancel={() => {
                  message.error('You have left this action!')
                }}
                okText='Yes'
                cancelText='No'>
                <p className='buyMore'>{item?.disabled ? 'Enable' : 'Disable'}</p>
              </Popconfirm>
              <Popconfirm
                title={'Delete user'}
                description={
                  <Col>
                    <div>{`Are you sure that you want to delete this user?`}</div>
                  </Col>
                }
                onConfirm={async () => {
                  const result = await refetchDeleteUser({ user_id: item })
                  if (result) {
                    await refetchUsersList()
                    message.success(`Deleted successfully`)
                  }
                }}
                onCancel={() => {
                  message.error('You have left this action!')
                }}
                okText='Yes'
                cancelText='No'>
                <p className='buyMore'>Delete</p>
              </Popconfirm>
            </div>
          )
        } else {
          return <div style={{ minHeight: '50px' }}></div>
        }
      },
    },
  ]
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await refetchUsersList()
      setIsLoading(false)
    }

    fetchData().catch(console.error)
  }, [])

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
    },
    onSubmit: async (values) => {
      const result = await AuthService.ssoSignUp({
        email: values.email,
        password: '',
        name: values.name,
        company: '',
      })
      if (
        (result && result?.response?.data?.message === 'The email already exists') ||
        result?.user?.email === values.email ||
        result.code === 201
      ) {
        await refetchUsersList()
        setOpenAddNewUser(false)
        message.success(`The user ${values.email} added!`)
      }
    },
  })

  useEffect(() => {
    if (userDetails && JSON.parse(userDetails)?.company_admin === false) {
      navigate('/companies')
    }
  }, [userDetails])

  const active_users = users?.response?.active_users?.rows[0]?.active_users
  const total_users = users?.response?.active_users?.rows[0]?.total_users

  return (
    <>
      <div className='addNewUserSection'>
        <p className='activeUsers'>{`${active_users ? active_users : 0}/${
          total_users ? total_users : 0
        } Active Users`}</p>
        <p
          className='buyMore'
          style={{ margin: '10px 20px 0px 0px' }}
          onClick={() => {
            setOpenAddNewUser(true)
          }}>
          Add new user
        </p>
      </div>

      {/* <div className='removeSelectedCell'> */}
      <FancyTable
        displayAll
        page_type={'company'}
        key={'id'}
        loading={isLoading}
        columns={companyAdminPanel}
        data={users?.response?.users?.rows}
        tableParams={tableParams}
        setTableParams={setTableParams}
        removeSelectRowOption={true}
        handleSelectedRows={() => {}}
        handleUnSelectRows={() => {}}
        selected_items={[]}
        handleRemoveRow={() => {}}
      />
      {/* </div> */}

      <Modal
        className='frmNewUser'
        open={openAddNewUser}
        onCancel={() => setOpenAddNewUser(false)}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}>
        <div className='flex justify-content-center'>
          <Form onFinish={formik.handleSubmit}>
            <Title level={2}>{'Add New User'}</Title>
            <Form.Item name='name' rules={[{ required: true, message: 'Name is required', whitespace: true }]}>
              <Input {...formik.getFieldProps('name')} placeholder='Name' />
            </Form.Item>
            <Form.Item
              name='email'
              rules={[
                {
                  type: 'email',
                  message: 'Invalid email address',
                },
                {
                  required: true,
                  message: 'Email is required',
                },
              ]}>
              <Input {...formik.getFieldProps('email')} placeholder='Email' />
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit' className={styles.btnSignup}>
                {'Add'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  )
}

export default CompanyAdminPannel
