import React, { useState } from 'react'

import type { MenuProps } from 'antd'
import { Menu } from 'antd'

import logo from 'common/assets/svg/logo.svg'
import convrt from 'common/assets/svg/convrt.svg'
import { useAppDispatch } from 'state'
import { setReset } from 'state/filtersSlice/filtersSlice'

import { MenuServices } from 'common/services/menu-services'
import {
  SET_FILTER,
  SET_IS_FILTER_CHANGED,
  SET_LAST_ACTION_PAGE,
} from 'features/intent-signals/state/slice/companiesSlice'
import { SET_LAST_ACTION_PAGE_CONTACT } from '../../../features/contacts/state/slice/contactsSlice'
import { useNavigate } from 'react-router-dom'
import styles from './logo.module.scss'
import ExitModal from '../OutreachCampaign/NewCampaign/UI/ExitModal'
import { useAppSelector } from 'state'

interface ILogo {
  collapsed: boolean
}

const Logo: React.FC<ILogo> = ({ collapsed }) => {
  const dispatch = useAppDispatch()
  const { isSubscriptionDaysPassed } = useAppSelector((state) => state.filters)

  type MenuItem = Required<MenuProps>['items'][number]
  const [openExitModal, setOpenExitModal] = useState(false)
  const navigate = useNavigate()
  const disableNavigationsRoutes = ['/outreach/create-new-campaign']
  const [navigateTo, setNavigateTo] = useState('')
  const items: MenuItem[] = [
    MenuServices.getItem(
      !collapsed ? <img src={convrt} alt={''} className='convrt' /> : '',
      'home',
      <img src={logo} alt={''} />,
      isSubscriptionDaysPassed,
    ),
  ]

  const handleNavigation = () => {
    const currentRoute = window.location.pathname

    if (disableNavigationsRoutes.includes(currentRoute)) {
      setOpenExitModal(true)
      setNavigateTo('/companies')
    } else {
      dispatch(setReset(true))
      dispatch(SET_FILTER({}))
      dispatch(SET_IS_FILTER_CHANGED(true))
      dispatch(SET_LAST_ACTION_PAGE(1))
      dispatch(SET_LAST_ACTION_PAGE_CONTACT(1))
      navigate('/companies')
    }

    const refreshAfterNavigation = ['/checkout/success']
    if (refreshAfterNavigation.includes(currentRoute)) {
      window.location.reload()
      navigate('/companies')
    }
  }

  return (
    <div className='logoParent'>
      <Menu
        onClick={handleNavigation}
        theme='dark'
        defaultSelectedKeys={[]}
        mode='inline'
        items={items}
        className={styles.logoMenu}
      />
      {openExitModal && (
        <ExitModal
          open={openExitModal}
          onClose={() => {
            setOpenExitModal(false)
          }}
          navigateTo={navigateTo}
        />
      )}
    </div>
  )
}

export default Logo
