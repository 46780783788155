import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Button, Divider, message } from 'antd'
import { useGoogleLogin } from '@react-oauth/google'
import { AuthService } from 'common/services/authServices'
import { Buttons } from 'common/constants/label.constants'
import convrt_logo_login from '../../common/assets/svg/convrt_logo_login.svg'
import styles from './sign-up.module.scss'

const SignUpPage: React.FC = () => {
  const navigate = useNavigate()
  const [user, setUser] = useState<any>([])
  const [loading, setLoading] = useState(false)
  // const [errorSignup, setErrorSignup] = useState('')
  // const [isSuccessfully, setIsSuccessfully] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: '',
      company: '',
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      setLoading(true)
      const result = await AuthService.signUp(values)
      // setIsSuccessfully(result.code === 201 ? true : false)
      if (result.code === 201) {
        // setIsSuccessfully(true)
        // message.success('Signed Up Successfully')
        setLoading(false)
        return navigate('/signup/success')
      } else {
        // setIsSuccessfully(false)
      }
      if (result.code === 'ERR_BAD_REQUEST') {
        // setErrorSignup(result.response.data.message)
        setLoading(false)
        message.error(result.response.data.message)
      } else {
        // setErrorSignup(result.message)
        setLoading(false)
        message.error(result.message)
      }
    },
  })

  const handleLoginNavigate = () => {
    navigate('/login')
  }

  const handleTerms = () => {
    window.open('https://www.convrt.io/terms-of-use', '_blank')
  }
  const handlePrivacyPolicy = () => {
    window.open('https://www.convrt.io/privacy-policy', '_blank')
  }

  const googleSSOSignUp = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.error('Google SSO Login Failed:', error),
  })

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json',
            },
          })
          .then(async (res) => {
            let data: any = {}
            data.email = res.data.email
            data.name = res.data.name

            const result = await AuthService.ssoSignUp(data)
            if (
              (result && result?.response?.data?.message === 'The email already exists') ||
              result?.user?.email === res.data.email
            ) {
              navigate('/login')
            }
          })
          .catch((err) => console.error(err))
      }
    }

    fetchData().catch(console.error)
  }, [user])

  return (
    <div className={styles.holdingContainer}>
      <div className={styles.logo}>
        <img src={convrt_logo_login} alt='' />
      </div>
      <div className={styles.form}>
        <div className={styles.header}>
          <p>Welcome</p>
          <span>
            Please enter your account details or sign up with
            <br />
            Google to begin using Convrt.
          </span>
        </div>
        <>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <button
              className='gsi-material-button'
              style={{ width: '400px' }}
              onClick={() => {
                googleSSOSignUp()
              }}>
              <div className='gsi-material-button-state'></div>
              <div className='gsi-material-button-content-wrapper'>
                <div className='gsi-material-button-icon'>
                  <svg
                    version='1.1'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 48 48'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    style={{ display: 'block' }}>
                    <path
                      fill='#EA4335'
                      d='M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z'></path>
                    <path
                      fill='#4285F4'
                      d='M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z'></path>
                    <path
                      fill='#FBBC05'
                      d='M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z'></path>
                    <path
                      fill='#34A853'
                      d='M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z'></path>
                    <path fill='none' d='M0 0h48v48H0z'></path>
                  </svg>
                </div>
                <span className='gsi-material-button-contents'>Sign in with Google</span>
                <span style={{ display: 'none' }}>Sign in with Google</span>
              </div>
            </button>
          </div>
          <Divider className={styles.Divider} style={{ margin: '32px 0px' }}>
            <span className={styles.Text}>Or</span>
          </Divider>
        </>
        <Form onFinish={formik.handleSubmit}>
          <Form.Item
            style={{ marginTop: '10px' }}
            name='name'
            rules={[{ required: true, message: 'Full Name is required', whitespace: true }]}>
            <Input
              style={{ height: 40, borderRadius: '10px' }}
              {...formik.getFieldProps('name')}
              placeholder='Full Name'
            />
          </Form.Item>
          <Form.Item
            name='company'
            style={{ marginTop: '10px' }}
            rules={[{ required: true, message: 'Company Name is required', whitespace: true }]}>
            <Input
              style={{ height: 40, borderRadius: '10px' }}
              {...formik.getFieldProps('company')}
              placeholder='Company Name'
            />
          </Form.Item>
          <Form.Item
            style={{ marginTop: '10px' }}
            name='email'
            rules={[
              {
                type: 'email',
                message: 'Invalid email address',
              },
              {
                required: true,
                message: 'Email is required',
              },
            ]}>
            <Input
              style={{ height: 40, borderRadius: '10px' }}
              {...formik.getFieldProps('email')}
              placeholder='Email'
            />
          </Form.Item>
          <Form.Item
            style={{ marginTop: '10px' }}
            name='password'
            rules={[
              {
                required: true,
                message: 'Password is required',
              },
              {
                min: 8,
                message: 'Invalid credentials',
              },
            ]}>
            <Input.Password
              style={{ height: 40, color: 'rgba(255,255,255)', borderRadius: '10px' }}
              {...formik.getFieldProps('password')}
              placeholder='Password'
              className='password'
            />
          </Form.Item>
          <Form.Item>
            <Button type='primary' className={styles.btnLogin} htmlType='submit' loading={loading}>
              {Buttons.SIGN_UP}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className={styles.footer}>
        <span>
          By signing up, you agree to our{' '}
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleTerms}>
            Terms
          </span>{' '}
          &{' '}
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handlePrivacyPolicy}>
            Privacy Policy.
          </span>
        </span>
        <span>
          Already have an account?{' '}
          <span onClick={handleLoginNavigate} style={{ color: '#005AF4', cursor: 'pointer' }}>
            Login Now!
          </span>
        </span>
      </div>
    </div>
  )
}

export default SignUpPage
