import { DownloadOutlined } from '@ant-design/icons'
import { Button, Divider, Typography } from 'antd'
import styles from './TemplateGuildline.module.scss'

const downloadContents = [
  {
    label: 'Contacts Simple File',
    description: 'Download a sample CSV file to see the required format for importing contacts.',
    download: '/csv/import_contact.csv',
    type: 2,
  },
  // {
  //   label: 'Contacts Mass import template',
  //   description: 'Empty CSV file to import your data onto',
  // },
  <Divider />,
  {
    label: 'Companies Simple File',
    description: 'Download a sample CSV file to see the required format for importing companies.',
    download: '/csv/import_companies.csv',
    type: 1,
  },
  // {
  //   label: 'Companies Mass import template',
  //   description: 'Empty CSV file to import your data onto',
  // },
]

export const TemplateGuildLine = ({ type }: { type: number }) => {
  return (
    <div className={styles.templateGuildline}>
      <Typography className={styles.templateGuildline__title}>Template And guideliends</Typography>
      <Typography className={styles.templateGuildline__description}>
        Please use the sample template to ensure your information is in the correct format before uploading.
      </Typography>

      <Divider />

      <div className={styles.templateGuildline__download}>
        {downloadContents
          .filter((ct) => ct.type === type)
          .map((box: any, index: number) => {
            return box.label ? <BoxDownload key={box.label} {...box} /> : <div key={index}>{box}</div>
          })}
      </div>
    </div>
  )
}

const BoxDownload = (props: any) => {
  const handleDownload = () => {
    const link = document.createElement('a')
    link.href = props.download
    link.download = props.download
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div className={styles.templateGuildline__box}>
      <div className={styles.templateGuildline__box__left}>
        <Typography>{props.label}</Typography>
        <Typography>{props.description}</Typography>
      </div>

      <Button icon={<DownloadOutlined />} onClick={handleDownload}>
        Download
      </Button>
    </div>
  )
}
