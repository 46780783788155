import React, { useEffect, useState } from 'react'
import { Layout, Spin, message } from 'antd'
import Sider from 'antd/es/layout/Sider'
import styles from './Chat.module.scss'
import ChatChannels from './ChatChannels'
import ChatTabs from './Tabs/ChatTabs'
import { useLazyGetDialogsQuery, useLazyUnifyQuery } from './state/api/Chat-API'
import { SET_IS_UNIFY_FINISHED, SET_SELECTED_CHAT_RECIPIENT, SET_ALL_TELEGRAM_DATA } from './state/chatSlice'
import { useAppDispatch, useAppSelector } from 'state'
import { LoadingOutlined } from '@ant-design/icons'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'

const { Content } = Layout

const Chat: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false)
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [getDialogs] = useLazyGetDialogsQuery()
  const [unifyAccounts] = useLazyUnifyQuery()
  const { is_unify_finished, close_left_sidebar } = useAppSelector((state) => state.chat)
  const { userDetails } = useAuth()
  const [userData, setUserData] = useState(userDetails)
  const [unifiedFailed, setUnifiedFailed] = useState(false)
  const dispatch = useAppDispatch()

  const contentStyle: React.CSSProperties = {
    minHeight: 120,
    lineHeight: '120px',
    overflow: 'scroll',
  }

  const layoutStyle = {
    overflow: 'hidden',
    height: '100%',
    paddingTop: '0px',
    paddingLeft: '0px',
    borderLeft: '1px solid #DFE1E6',
  }

  // useEffect(() => {
  //   const unifyAllAccounts = async () => {
  //     await unifyAccounts()
  //   }

  //   const getAllDialogs = async () => {
  //     await getDialogs()
  //   }

  //   unifyAllAccounts()
  //   getAllDialogs()
  // }, [])

  useEffect(() => {
    const getUserDetails = async () => {
      const { data } = await refetchGetUserDetails()
      setUserData(data)
    }
    getUserDetails()
  }, [])

  useEffect(() => {
    const unifyAllAccounts = async () => {
      const { data } = await unifyAccounts()
      if (data?.isUnified === false) {
        setUnifiedFailed(true)
        return message.error('Data could not be synced. Please try again or contact support.')
      }
      await getAllDialogs()
      dispatch(SET_IS_UNIFY_FINISHED(true))
    }

    const getAllDialogs = async () => {
      const { data } = await getDialogs()
      dispatch(
        SET_SELECTED_CHAT_RECIPIENT({
          ...data?.telegram?.dialogData?.[0],
          senderData: data?.telegram?.dialogData?.[0]?.receiver_number,
        }),
      )
      dispatch(SET_ALL_TELEGRAM_DATA(data?.telegram))
    }

    // dispatch(SET_IS_UNIFY_FINISHED(false))
    if (userData?.addons?.unified_inbox && !is_unify_finished) {
      unifyAllAccounts()
      // post('/log', {
      //   action: 'Unified Inbox: User Unified',
      // })
    }
  }, [])

  const LoadingOverlay = () => (
    <div className={styles.loadingOverlay}>
      <Spin size='large' indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      <span style={{ margin: '10px 0px' }}>Syncing Data...</span>
    </div>
  )

  const FailedOverlay = () => (
    <div className={styles.loadingOverlay}>
      <span style={{ margin: '10px 0px' }}>
        Syncing Data Did not complete. Please try again later or contact support.
      </span>
    </div>
  )

  const handleCollapse = () => {
    setCollapsed(!collapsed)
  }

  useEffect(() => {
    if (!collapsed && close_left_sidebar) {
      setCollapsed(true)
    }
  }, [close_left_sidebar])

  return (
    <Layout>
      {unifiedFailed && <FailedOverlay />}
      {!is_unify_finished && userData?.addons?.unified_inbox && !unifiedFailed && <LoadingOverlay />}
      <Sider
        className='chat_sider'
        collapsible
        collapsed={collapsed}
        onCollapse={handleCollapse}
        width={400}
        style={{
          overflow: 'auto',
          height: '100%',
          position: 'sticky',
          top: 0,
          left: 0,
        }}>
        <div className={styles.top_section}>
          <h3>Channels</h3>
          {/* <Button className='hidebtn_collapse'>Manage</Button> */}
        </div>
        <div className='hide_collapse'>
          <p className='para'>Select Channel to view chats</p>
          <ChatChannels collapsed={collapsed} />
        </div>
      </Sider>
      <Content>
        <Layout style={layoutStyle}>
          <Content className='customScrollContent flex flex-row' style={contentStyle}>
            <ChatTabs />
          </Content>
        </Layout>
      </Content>
    </Layout>
  )
}

export default Chat
