import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Switch, message } from 'antd'
import styles from './EditCampaignFooter.module.scss'
import {
  // useLazySetCampaignRecipientsQuery,
  useLazyGetCampaignsRecipientsByIdQuery,
  useLazyDeleteCampaignRecipientsQuery,
} from 'features/Outreach/state/api/OutreachApi'

import {
  useLazyCreateCampaignQuery,
  useLazyEditCampaignQuery,
  useLazySetCampaignsActiveQuery,
  useLazyUpdateCampaignQuery,
} from 'features/Outreach/state/api/OutreachApi'
import { useAppDispatch, useAppSelector } from 'state'

import {
  SET_CAMPAIGN_ID,
  SET_CAMPAIGN_NAME,
  SET_ENABLE_EDITING,
  SET_SELECTED_LEADS,
  SET_SEQUENCE_DATA,
  SET_OPEN_SENDER_MODAL,
} from '../state/outreachCampaignSlice'

import { useNavigate } from 'react-router-dom'
import DisableCampaignModal from '../MainCampaign/Campaigns/UI/DisableCampaignModal'
import EditCampaignSaveModal from './UI/EditCampaignSaveModal'
import RemoveLeadsModal from './UI/RemoveLeadsModal'
import { transformToNewStructure } from '../NewCampaign/transformStepsUtil'

interface EditCampaignFooterProps {
  currentStep: any
  setCurrentStep?: any
  id?: string
  nodesData?: any
  setNodesData?: any
  current: number
}

const EditCampaignFooter: React.FC<EditCampaignFooterProps> = ({
  id,
  setCurrentStep,
  currentStep,
  nodesData,
  setNodesData,
  current,
}) => {
  const [disabledErrorText, setDisabledErrorText] = useState(0)

  // eslint-disable-next-line
  const [refetchCreateCampaign, { data: campaignData }] = useLazyCreateCampaignQuery()
  const [refetcGetCampaignRecipients] = useLazyGetCampaignsRecipientsByIdQuery()
  const [refetcSetCampaignsActive] = useLazySetCampaignsActiveQuery()
  const [refetchUpdateCampaign] = useLazyUpdateCampaignQuery()
  const [refetchDeleteRecipients] = useLazyDeleteCampaignRecipientsQuery()
  const [refetchEditCampaign] = useLazyEditCampaignQuery()
  const [disabledButton, setDisabledButton] = useState(false)
  const { sequence_data, template, selected_leads, senders_approved } = useAppSelector(
    (state) => state.outreachCampaign,
  )
  const steps = sequence_data.steps
  const [isChecked, setIsChecked] = useState<any>()
  const [isCheckedLoading, setIsCheckedLoading] = useState(false)
  const [showSwitch, setShowSwitch] = useState<any>()
  const [openDisableCampaignModal, setOpenDisableCampaignModal] = useState(false)
  const [openSaveCampaignModal, setOpenSaveCampaignModal] = useState(false)
  const [openRemoveLeadsModal, setOpenRemoveLeadsModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const removeEmptyMessages = (data: any) => {
    const clonedData = JSON.parse(JSON.stringify(data))
    for (const key in clonedData) {
      if (clonedData[key].data && Array.isArray(clonedData[key].data.messages)) {
        clonedData[key].data.messages = clonedData[key].data.messages.filter((message: string) => message !== '')
      }
    }
    return clonedData
  }

  const handleUpdateCampaign = async () => {
    const campaign_name = sequence_data.campaign_name
    const campaign_description = sequence_data.campaign_description
    const status = sequence_data.status
    const settings = sequence_data.settings

    const steps = removeEmptyMessages({ ...sequence_data.steps })

    await refetchUpdateCampaign({
      campaign_id: id,
      campaign_name,
      campaign_description,
      status,
      steps,
      settings,
      edit_mode: true,
    })
  }

  const handleEditCampaign = async () => {
    const campaign_name = sequence_data.campaign_name
    const campaign_description = sequence_data.campaign_description
    const status = sequence_data.status
    const settings = sequence_data.settings

    const steps = removeEmptyMessages({ ...sequence_data.steps })

    const dataArray = steps
      ? Object.keys(steps).map((key) => ({
          step_id: key,
          ...steps[key],
        }))
      : []

    const messages_steps = template === 3 || template === 4 ? transformToNewStructure(dataArray, 'linkedin') : undefined

    await refetchEditCampaign({
      campaign_id: id,
      campaign_name,
      campaign_description,
      status,
      steps,
      settings,
      edit_mode: true,
      messages_steps,
    })
  }

  const handleButtonClick = () => {
    if (currentStep === 0) {
      handleEditCampaign()
      setCurrentStep(1)
    } else {
      handleUpdateCampaign()
      dispatch(SET_SELECTED_LEADS([]))
      // navigate('/outreach')
      setOpenSaveCampaignModal(true)
    }
  }

  const handleButtonRemoveClick = () => {
    setOpenRemoveLeadsModal(true)
  }

  const handleRemoveLeads = async () => {
    try {
      setIsLoading(true)

      if (showSwitch) {
        await refetcSetCampaignsActive({ campaign_ids: [id], is_active: false, status: 'paused' })
        setNodesData((prev: any) => ({ ...prev, status: 'paused' }))
        dispatch(SET_SEQUENCE_DATA({ ...nodesData, status: 'paused' }))
        setTimeout(async () => {
          dispatch(SET_ENABLE_EDITING(true))
          setShowSwitch(false)
        }, 1000)
        setTimeout(async () => {
          await refetchDeleteRecipients({
            campaign_id: id,
            recipient_ids: selected_leads,
          })
          await refetcGetCampaignRecipients({ campaign_id: id, page: current, limit: 50 })
          setOpenRemoveLeadsModal(false)
          setIsLoading(false)
          dispatch(SET_SELECTED_LEADS([]))
          message.success('Leads removed successfully!')
        }, 2000)
      } else {
        await refetchDeleteRecipients({
          campaign_id: id,
          recipient_ids: selected_leads,
        })
        await refetcGetCampaignRecipients({ campaign_id: id, page: current, limit: 50 })
        dispatch(SET_SELECTED_LEADS([]))
        setOpenRemoveLeadsModal(false)
        setIsLoading(false)
        message.success('Leads removed successfully!')
      }
    } catch (e) {
      console.error('Error removing leads: ', e)
    }

    // setIsLoading(true)

    // const response = await refetcSetCampaignRecipients({
    //   contactsListIds,
    //   campaign_id,
    //   campaign_name,
    //   credits,
    //   allCntactsToggle,
    //   failedCntactsToggle,
    // })

    // if (response?.error?.status === 470) {
    //   setOutOfCredits(true)
    // }
    // setImportFromListsOpen(false)
    // setCurrentStep(1)
    // dispatch(SET_IMPORTED_LEADS(true))
    // setIsLoading(false)
    // setOpenRemoveLeadsModal(false)
  }

  const handleConfirmation = async () => {
    setIsCheckedLoading(true)
    setOpenDisableCampaignModal(false)
    await refetcSetCampaignsActive({ campaign_ids: [id], is_active: false, status: 'paused' })
    setNodesData((prev: any) => ({ ...prev, status: 'paused' }))
    dispatch(SET_SEQUENCE_DATA({ ...nodesData, status: 'paused' }))
    setTimeout(() => {
      // set loaidng for the switch check
      setIsChecked(false)
      dispatch(SET_ENABLE_EDITING(true))
      setIsCheckedLoading(false)
      setShowSwitch(false)
      message.success('Campaign paused successfully. You can now edit your campaign!')
    }, 2000)
  }

  const handleCampaignActiveStatus = async (checked: boolean) => {
    if (!checked) {
      setOpenDisableCampaignModal(true)
    }
  }

  const handleActiveCampaign = async () => {
    await refetcSetCampaignsActive({ campaign_ids: [id], is_active: true, status: 'running' })
    navigate('/outreach')
  }

  const getTelegramTableData = (user: any) => {
    const OutreachAccounts =
      user?.outreach?.map((user: any) => ({
        id: user.id,
        phoneNumber: `${user.area_code}${user.number}`,
        handle: `@${user.userName ? user.userName : 'N/A'}`,
        number: user.number,
        area_code: user.area_code,
        managed: user?.isManaged,
        status: 'Connected',
        type: 'Outreach',
        name: user.sender_full_name,
        company: user.sender_company,
      })) || []

    return OutreachAccounts
  }

  useEffect(() => {
    if (
      steps &&
      ((template === 0 && Object.keys(steps)?.length > 1) ||
        (template === 1 && Object.keys(steps)?.length > 3) ||
        (template === 2 && Object.keys(steps)?.length > 6))
    ) {
      let everythingIsFull = true

      Object.keys(steps).forEach((key) => {
        const subObject = steps[key]
        if (subObject.type === 'send_telegram_message') {
          const { sender, message, messages } = subObject.data
          if (message) {
            if (sender?.length === 0 || !sender || !message || message?.length === 0) {
              everythingIsFull = false
            }
          } else if (messages) {
            if (
              sender?.length === 0 ||
              !sender ||
              !messages ||
              messages?.filter((m: string) => m !== '')?.length === 0
            ) {
              everythingIsFull = false
            }
            const is_sender_full_name = messages.some((messages: any) => messages.includes('@sender_full_name'))
            const is_sender_company = messages.some((messages: any) => messages.includes('@sender_company'))

            if (is_sender_full_name || is_sender_company) {
              const local_user = localStorage.getItem('user')

              const telegramTableData = getTelegramTableData(JSON.parse(local_user || '{}'))
              const name = telegramTableData?.filter(
                (obj: any) => obj.name === '' || obj.name === null || obj.name === undefined,
              ).length
              const company = telegramTableData?.filter(
                (obj: any) => obj.company === '' || obj.company === null || obj.company === undefined,
              ).length
              if ((is_sender_full_name && name > 0) || (is_sender_company && company > 0)) {
                everythingIsFull = false
                setDisabledErrorText(name + company)
              }
            } else {
              setDisabledErrorText(0)
            }
          }
        } else if (subObject.type === 'is_wait') {
          const { duration, units } = subObject.data
          if (!duration || !units) {
            if (duration !== 0) everythingIsFull = false
          }
        } else if (subObject.type === 'is_message_replied') {
          const { duration, units } = subObject.data
          if (!duration || !units) {
            if (duration !== 0) everythingIsFull = false
          }
        }
      })
      if (everythingIsFull) {
        setDisabledButton(false)
      } else {
        setDisabledButton(true)
      }
    }
  }, [sequence_data, steps, senders_approved])

  useEffect(() => {
    if (campaignData !== undefined) {
      const { campaign_id, campaign_name } = campaignData?.response
      dispatch(SET_CAMPAIGN_ID(campaign_id))
      dispatch(SET_CAMPAIGN_NAME(campaign_name))
    }
  }, [campaignData])

  useEffect(() => {
    const status = nodesData.status === 'running' || nodesData.status === 'failed'
    setIsChecked(status)
    setShowSwitch(status)
  }, [])

  return (
    <Row
      justify='space-between'
      align='middle'
      className={styles.header}
      style={{
        padding: '30px 35px',
        backgroundColor: 'white',
        fontWeight: 600,
        zIndex: 50,
      }}>
      {/* <Col flex='auto' span={3} />
       */}
      <Col flex='auto' span={3}>
        {currentStep === 1 && (
          <div className={styles.leftSide}>
            <Button onClick={handleButtonRemoveClick} disabled={selected_leads?.length === 0}>
              Remove Leads
            </Button>
          </div>
        )}
      </Col>

      <Col flex='auto' span={13}>
        {currentStep === 0 && disabledButton && disabledErrorText > 0 ? (
          <div style={{ color: '#da1818' }}>
            {`You are trying to use senders variables that are not set yet, please set them before you continue.`}{' '}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                dispatch(SET_OPEN_SENDER_MODAL(true))
              }}>
              Fix Here
            </span>
          </div>
        ) : (
          <></>
        )}
        {showSwitch && (
          <div className={styles.pauseCampaign}>
            {/* <span>Editing the Campaign Sequence is Only Possible When the Campaign is not Running</span> */}
            <div className={styles.switchPause}>
              <span>Campaign is currently running. Pause Your Campaign To Edit the Sequence</span>
              <Switch
                size='small'
                loading={isCheckedLoading}
                checked={isChecked}
                onClick={(checked) => handleCampaignActiveStatus(checked)}
              />
            </div>
          </div>
        )}
      </Col>
      <Col flex='auto' span={3}>
        <div className={styles.rightSide}>
          <Button onClick={handleButtonClick} disabled={disabledButton}>
            {currentStep === 0 ? 'Continue' : 'Save Campaign'}
          </Button>
        </div>
      </Col>
      {openDisableCampaignModal && (
        <DisableCampaignModal
          open={openDisableCampaignModal}
          onClose={() => {
            setOpenDisableCampaignModal(false)
          }}
          campaignName={'this campaign'}
          onConfirm={handleConfirmation}
        />
      )}
      {openSaveCampaignModal && (
        <EditCampaignSaveModal
          open={openSaveCampaignModal}
          onClose={() => {
            navigate('/outreach')
          }}
          campaignName={'this campaign'}
          onConfirm={handleActiveCampaign}
        />
      )}
      {openRemoveLeadsModal && (
        <RemoveLeadsModal
          open={openRemoveLeadsModal}
          onClose={() => {
            setOpenRemoveLeadsModal(false)
          }}
          campaignName={'this campaign'}
          onConfirm={handleRemoveLeads}
          isCampaignRunning={showSwitch}
          isLoading={isLoading}
        />
      )}
    </Row>
  )
}

export default EditCampaignFooter
