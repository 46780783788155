export enum TilePage {
  LOGIN = 'Log in to Convrt',
  SIGN_UP = 'Sign up to Convrt',
  RESET_PASSWORD = 'Reset your password',
}
export enum DrawerLebels {
  EMPLOYEES = 'Employees',
  NOTIFY_ME = 'Notify Me',
  FOUNDED = 'Founded',
  REVENUE = 'Revenue',
  INDUSTRY = 'Industry',
  INTENT_TOPIC = 'Intent topic',
  PAYMENT_SOLUTION = 'Web3 Payments Solution',
  ABOUT = 'About',
  ON_CHAIN_REVENUE = 'On-Chain Overview',
  ACTIVE_WALLETS = 'Active Wallets',
  TOKEN_NAME = 'Token Name',
  TVL = 'TVL',
  SMART_CONTRACT = 'Smart Contract',
  CHAIN_DISTRIBUTION = 'Chain Distribution',
  AUDITS = 'Audits',
  TRAFIC_SOURCES = 'Traffic Sources',
  SIGNALS_FOUND = 'Signals found',
  BSC = 'BSC',
  ETHEREUM = 'Ethereum',
  AVALANCHE = 'Avalanche',
  CORRELATION_SCORE = 'Correlation score',
  FUNDING_ROUNDS = 'Funding Rounds',
  TOTAL_FUNDING = 'Total Funding',
  TECH_STACK_OVERVIEW = 'Tech Stack & Tools',
  SOCIAL_STRENGTH = 'Social Strength',
  INTENTS_SIGNALS = 'Signals',
  TOP_GEOS = "Top Geo's",
  WEB_TWO_TRAFIC = 'Web 2 Traffic',
  VISITS_LAST_MONTHS = 'Visits last months',
  WHALES_DOMINATION = 'Whales Domination',
  SCDR = 'Smart Contracts Deployment Rate (SCDR)',
  DECISION_MARKETS_CONTACTS = 'Notable Contacts',
  SHOW_IN_CONTACTS = 'Show in Contacts',
  MAIN_CONTACT_DETAILS = 'Contact Details',
  TAGS = 'Tags',
  BIO = 'Bio',
  ADDITIONAL_CONTACT_DETAILS = 'Additional contact details',
  EMAIL = 'Email',
  ACCESS_EMAIL = 'Access Email',
  EXPOSE = 'Expose',
  CALL = 'Call',
  ACCESS_CALL = 'Access Phone',
  MAP = 'Map',
  COMPANY = 'Company',
  NAME = 'Name:',
  VERTICAL = 'Vertical:',
  COMMUNITIES = 'Communities',
  CONTACT_COMMUNITIES = 'Contact communities',
  SHARED_COMMUNITIES = 'Shared communities:',
  LOCATION = 'Location',
  DIRECT = 'Direct',
  FACEBOOK = 'Facebook',
  TWITTER = 'Twitter',
  TOP_COMPETITORS = 'Top Competitors',
  TELEGRAM = 'Telegram',
  ACCESS_TELEGRAM = 'Access Telegram',
  CONNECT_TELEGRAM = 'Connect Telegram',
  DISCORD = 'Discord',
  ACCESS_DISCORD = 'Access Discord',
  RATE_DATA = 'Rate Data',
  SIMILAR_COMPANIES = 'Similar Companies',
  SEND_MESSAGE = 'Send Message',
  SENT_MESSAGES = 'Sent Messages',
  OUTREACH = 'Outreach',
}

export enum Buttons {
  LOGIN_WITH_TELEGRAM = 'Login with Telegram',
  LOGIN_WITH_GOOGLE = 'Login with Google',
  LOGIN_WITH_YOUR_COMPANY = 'Login with your company',
  LOG_IN = 'Log In',
  TELEGRAM_CONTACTS = 'Telegram Contacts',
  VERIFIED_CONTACTS = 'Verified Contacts',
  ACCESS_TELEGRAM = 'Access Telegram',
  COPY = 'Copy',
  COMPANIES = 'Companies',
  SIGN_UP_WITH_GOOGLE = 'Sign up with Google',
  SIGN_UP = 'Sign Up',
  RESET_PASSWORD = 'Reset password',
}

export enum Inputs {
  EMAIL = 'Email',
  PASSWORD = 'Password',
  OR = 'OR',
}

export enum GraphLegend {
  FRAUD_PREVENTION = 'Fraud Prevention',
  PAYMENT_SECURITY = 'Payments Security',
  EXPANSION = 'Expansion and Market Penetration',
  RISK = 'Risk Assessment and Management',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  PRIME_INTENT = 'Prime Intent:',
  MINOR_INTENTS = 'Minor Intents:',
}
