import React, { useState } from 'react'

import type { MenuProps } from 'antd'
import { Menu } from 'antd'

import settingsBlack from 'common/assets/svg/settingsBlack.svg'
import settingsWhite from 'common/assets/svg/settingsWhite.svg'

import { MenuServices } from 'common/services/menu-services'

import styles from './bottom-menu.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import ExitModal from '../OutreachCampaign/NewCampaign/UI/ExitModal'
import { useAppSelector } from 'state'

interface IBottomMenu {
  collapsed: boolean
  callbackOnChange?: (value?: string) => void
  selectedKey: string
}
const BottomMenu: React.FC<IBottomMenu> = ({ collapsed, callbackOnChange, selectedKey }) => {
  const { isSubscriptionDaysPassed } = useAppSelector((state) => state.filters)

  const [openExitModal, setOpenExitModal] = useState(false)
  const navigate = useNavigate()
  const disableNavigationsRoutes = ['/outreach/create-new-campaign']
  const refreshAfterNavigation = ['/checkout/success']
  const [navigateTo, setNavigateTo] = useState('')
  type MenuItem = Required<MenuProps>['items'][number]
  const location = useLocation()
  const settings = location.pathname === '/settings' ? settingsBlack : settingsWhite
  // const settings = location.pathname === '/settings' ? settingsBlack : settingsWhite

  const items: MenuItem[] = [
    // MenuServices.getItem(!collapsed && 'Get Points', 'settings', <img src={getPointsWhite} alt={''} />),
    MenuServices.getItem(
      !collapsed && 'Settings',
      'settings',
      <img src={settings} alt={''} />,
      isSubscriptionDaysPassed,
    ),
  ]

  const handleNavigation = (value: any) => {
    const routeToNavigate = `/${value.key}`
    const currentRoute = window.location.pathname

    if (disableNavigationsRoutes.includes(currentRoute)) {
      setOpenExitModal(true)
      setNavigateTo(routeToNavigate)
    } else {
      callbackOnChange && callbackOnChange(value.key)
      navigate(routeToNavigate)
    }

    if (refreshAfterNavigation.includes(currentRoute)) {
      window.location.reload()
      navigate(routeToNavigate)
    }
  }

  return (
    <>
      <Menu
        defaultSelectedKeys={[window.location.pathname.indexOf('settings') >= 0 ? 'settings' : '']}
        selectedKeys={[window.location.pathname.indexOf('settings') >= 0 ? 'settings' : '']}
        theme='dark'
        mode='inline'
        items={items}
        className={`bottomSideBar ${styles.bottomMenu}`}
        onSelect={handleNavigation}
      />
      {openExitModal && (
        <ExitModal
          open={openExitModal}
          onClose={() => {
            setOpenExitModal(false)
          }}
          navigateTo={navigateTo}
        />
      )}
    </>
  )
}

export default BottomMenu
