import React, { useState } from 'react'
import { Select } from 'antd'
import styles from './SelectTime.module.scss'

interface SelectTimeProps {
  value: string[]
  onChange: (value: string[]) => void
  onApplyToAll?: any
  row?: any
}

const generateOptions = (start: number, end: number) => {
  return Array.from({ length: end - start + 1 }, (_, index) => ({
    label: `${start + index}`.padStart(2, '0'),
    value: `${start + index}`.padStart(2, '0'),
  }))
}

const SelectTime: React.FC<SelectTimeProps> = ({ value, onChange, onApplyToAll, row }) => {
  const [displayApplyToAll, setDisplayApplyToAll] = useState(false)
  const hoursOptions = generateOptions(0, 23)
  const minutesOptions = generateOptions(0, 59)

  const startHour = parseInt(value[0], 10)
  const startMinute = parseInt(value[1], 10)

  const endHourOptions = generateOptions(startHour, 23)
  const endMinuteOptions = startHour === parseInt(value[2], 10) ? generateOptions(startMinute + 1, 59) : minutesOptions

  const handleValueChange = (newValue: string, index: number) => {
    setDisplayApplyToAll(true)
    const newValues = [...value]
    newValues[index] = newValue

    if (index === 0 && parseInt(newValues[2], 10) < parseInt(newValue, 10)) {
      newValues[2] = newValue
      newValues[3] = newValues[1]
    } else if (index === 1 && parseInt(newValues[2], 10) === startHour && parseInt(newValue, 10) <= startMinute) {
      newValues[3] = `${startMinute + 1}`.padStart(2, '0')
    }
    onChange(newValues)
  }

  const handleApplyToAll = () => {
    onApplyToAll(value)
  }

  return (
    <div className={styles.selectTimeContainer}>
      {displayApplyToAll && (
        <span className={styles.applyToAll} onClick={handleApplyToAll}>
          Apply to All
        </span>
      )}
      {value.map((selectedValue, index) => (
        <div key={index}>
          {index === 2 && <span className={styles.dash}>-</span>}
          <Select
            dropdownStyle={{ padding: '0px', scrollbarColor: 'inherit', overflow: 'hidden' }}
            className='selectTime'
            popupClassName='selectTime'
            showSearch
            size='large'
            style={{ width: 43, marginLeft: '5px' }}
            suffixIcon={null}
            value={selectedValue}
            onChange={(newValue) => handleValueChange(newValue as string, index)}
            options={
              index === 0
                ? hoursOptions
                : index === 1
                ? minutesOptions
                : index === 2
                ? endHourOptions
                : endMinuteOptions
            }
          />
        </div>
      ))}
    </div>
  )
}

export default SelectTime
