import FancyTable from 'common/components/fancy-table/fancy-table'

import classnames from 'classnames'

import { rawLeadTableColumn } from 'features/my-contact/constants/rawLeadTable'

const RawImportLead = ({ data, isLoading, tableParams, handleChangeTableParams }: any) => {
  const tableData =
    data.rows &&
    data.rows.map((item: any) => ({
      ...item,
      ...item.item,
    }))
  return (
    <>
      <div className='flex flex-column flex-1'>
        <div className='flex-1'>
          <FancyTable
            preserveSelectedRowKeys
            loading={isLoading}
            page_type={'company'}
            totalItems={data?.totalNumber}
            columns={rawLeadTableColumn}
            totalDataNumber={data?.totalDataNumber}
            data={tableData}
            key={'id'}
            tableParams={{ ...tableParams, total: data?.totalDataNumber }}
            setTableParams={handleChangeTableParams}
            hasBanner={false}
            className={classnames('my_contact_table leadTable', {
              'no-data': data?.rows?.length === 0,
            })}
            isFromOutreach={true}
            removeSelectRowOption={true}
          />
        </div>
      </div>
    </>
  )
}

export default RawImportLead
