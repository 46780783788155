import { Button, Drawer, Input, message, Select, Spin, Typography } from 'antd'
import { useLazyAddNewListQuery } from 'features/intent-signals/state/api/CompaniesApi'
import React from 'react'
import styles from './AddListSider.module.scss'
import { injectionRegex } from 'common/static-data/userData'
import { MESSAGE_ERROR } from 'common/constants/messages.constants'
import OutOfCreditsModal from 'common/components/OutOfCredits/OutOfCreditsModal'
import { LoadingOutlined } from '@ant-design/icons'

export const AddListSider = ({ open, onClose, list, refetchLists, onAddSuccess }: any) => {
  const [listName, setListName] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [outOfCredits, setOutOfCredits] = React.useState(false)
  const [listType, setListType] = React.useState('')

  const [refetchAddList] = useLazyAddNewListQuery()

  const handleAddList = async () => {
    try {
      setLoading(true)

      if (injectionRegex.test(listName)) {
        message.error(MESSAGE_ERROR.INVALID_LIST_NAME)
      } else {
        let checkItem = list?.find((item: any) => item.name === listName)
        if (!checkItem) {
          let result = await refetchAddList({ name: listName, type: listType })
          if (result?.error?.status === 470) {
            setOutOfCredits(true)
          } else {
            if (result) {
              setListName('')
              setListType('')
              const data = await refetchLists()
              onAddSuccess(data?.data)
              setLoading(false)
            }
          }
        } else {
          setLoading(false)
          return message.error(`You already have a list with the same name!`)
        }
      }
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  const renderTitle = () => {
    return (
      <div className={styles.sider__header}>
        <div className={styles.sider__header__left}>
          <Typography>Add new list</Typography>
          {loading && (
            <Spin style={{ margin: '0 10px' }} indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
          )}
        </div>
        <Button type='primary' disabled={!listName || loading} onClick={handleAddList}>
          Add
        </Button>
      </div>
    )
  }

  const handleChangeListType = (value: any) => {
    setListType(value)
  }

  return (
    <Drawer title={renderTitle()} open={open} closable onClose={onClose} className={'add-list-sider'}>
      <div className={styles.sider__body}>
        <div>
          <Typography>Type</Typography>
          <Select
            placeholder='Type'
            style={{ width: '100%' }}
            value={listType}
            onChange={handleChangeListType}
            size='large'
            options={[
              { value: 1, label: 'Companies List' },
              { value: 2, label: 'Contacts List' },
            ]}
          />
        </div>

        <div>
          <Typography>List name</Typography>
          <Input value={listName} onChange={(e) => setListName(e.target.value)} style={{ padding: '6px 16px' }} />
        </div>
      </div>

      {outOfCredits && (
        <OutOfCreditsModal
          open={outOfCredits}
          onClose={() => {
            setOutOfCredits(false)
          }}
          creditType={'list'}
          location='lists'
          type='limit'
        />
      )}
    </Drawer>
  )
}
