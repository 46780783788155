import React from 'react'
import { Table } from 'antd'
import styles from './GroupsTable.module.scss'
import LoadingGroupsSkeleton from './LoadingGroupsSkeleton'
import { getLeadsColumns } from './LeadsColumns'

interface LeadsTableProps {
  selectedGroup: string
  data: any
  isLoading: boolean
  selectedRowKeys: any
  setSelectedRowKeys: any
  tableParams: any
  type: string
  handleTableChange: (param: any) => void
  handleSelectAllData: (param: any) => void
}

const LeadsTable: React.FC<LeadsTableProps> = ({
  selectedGroup,
  data,
  isLoading,
  selectedRowKeys,
  setSelectedRowKeys,
  tableParams,
  type,
  handleSelectAllData,
  handleTableChange,
}) => {
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
    selections: [
      {
        key: 'allData',
        text: `Select all data`,
        onSelect: handleSelectAllData,
      },
    ],
  }
  const getSourceAndSourceType = (item: any) => {
    const sources = []
    const sourceTypes = []
    if (item.in_contact) {
      sources.push('Personal Telegram Contact')
      sourceTypes.push('Contact')
    }
    if (item.groups && item.groups.length) {
      sources.push(item.groups.map((group: any) => group.title))
      sourceTypes.push('Group')
    }
    return {
      source: sources.join(', '),
      sourceType: sourceTypes.join(', '),
    }
  }
  const convertedData: any[] = React.useMemo(() => {
    return data?.map((item: any) => {
      return {
        key: item.id,
        full_name: [item.first_name || '', item.last_name || ''].join(' '),
        username: item.user_name || '',
        biography: item.about || item.bio,
        is_private: false,
        follower_count: 0,
        profile_pic_url: '',
        company_name: item.company_name,
        job_title: item.job_title,
        source: getSourceAndSourceType(item).source || '',
        source_type: getSourceAndSourceType(item).sourceType || '',
      }
    })
  }, [data])

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div className={styles.title__text}>
          Displaying{' '}
          <span style={{ fontWeight: 600 }}>
            all of {tableParams?.total ? tableParams.total?.toLocaleString() : 0} available leads
          </span>{' '}
          from {selectedGroup}
        </div>
      </div>
      <Table
        locale={{
          emptyText: isLoading ? (
            <LoadingGroupsSkeleton
              listStyle={{ margin: '-20px -8px' }}
              listItemStyle={{ padding: '23px 0px 23px 0px' }}
              skeletonStyle={{
                paddingTop: '0px',
                paddingLeft: '5px',
                paddingRight: '5px',
                display: 'flex',
                alignItems: 'center',
                opacity: 0.5,
              }}
            />
          ) : (
            ''
          ),
        }}
        dataSource={convertedData}
        columns={getLeadsColumns(type)}
        rowSelection={rowSelection}
        scroll={{ y: 'calc(100vh - 400px)', x: 'auto' }}
        pagination={
          tableParams
            ? {
                ...(tableParams
                  ? {
                      ...tableParams,
                      pageSize: +tableParams?.pageSize,
                      total: +tableParams?.total,
                    }
                  : {}),
              }
            : false
        }
        {...(tableParams && { onChange: handleTableChange })}
      />
    </div>
  )
}

export default LeadsTable
