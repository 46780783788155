import React from 'react'
import { Button, Modal } from 'antd'
import warning from 'common/assets/svg/warning.svg'
import styles from './DisableCampaignModal.module.scss'

interface DisableCampaignModalProps {
  open: boolean
  onClose: () => void
  campaignName: string
  onConfirm: () => void
  loading?: boolean
}

const DisableCampaignModal: React.FC<DisableCampaignModalProps> = ({
  open,
  onClose,
  campaignName,
  onConfirm,
  loading,
}) => {
  return (
    <>
      <Modal
        className='modalStyleNewDeal'
        centered
        open={open}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}
        onCancel={() => {
          onClose()
        }}
        width={'fit-content'}
        style={{ minWidth: 600, maxWidth: 600 }}>
        <div className={styles.contentStyle}>
          <div className={styles.container}>
            <img src={warning} alt='' />
            <p>Are you sure you want to pause {campaignName}?</p>
            <span>
              Your campaign will stop running immediately.
              <br />
              However, messages that are already in the process of being sent
              <br /> will continue to be delivered.
            </span>
            <div className={styles.footer}>
              <Button size={'large'} onClick={onClose}>
                Cancel
              </Button>
              <Button
                size={'large'}
                style={{ color: 'white', backgroundColor: '#7043ff' }}
                loading={loading}
                onClick={onConfirm}>
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default DisableCampaignModal
