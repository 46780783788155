import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'antd'
import warning from 'common/assets/svg/warning.svg'
import styles from './OutOfPointsUseRemaining.module.scss'
import BuyCreditsModal from '../BuyConvrt/BuyCreditsModal'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'

interface OutOfPointsUseRemainingProps {
  open: boolean
  onClose: () => void
  onUseRemainingPoints: () => void // Function to be called when "Use Remaining Points" is clicked
  cost_points?: any
}

const OutOfPointsUseRemaining: React.FC<OutOfPointsUseRemainingProps> = ({
  open,
  onClose,
  onUseRemainingPoints,
  cost_points,
}) => {
  const [openBuyCreditsModal, setOpenBuyCreditsModal] = useState(false)
  const { userDetails } = useAuth()
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()

  const [userData, setUserData] = useState(userDetails)

  const handleBuyPoints = () => {
    setOpenBuyCreditsModal(true)
    // onClose()
  }

  useEffect(() => {
    const getSubscriptionType = async () => {
      const { data } = await refetchGetUserDetails()
      setUserData(data)
      // const type = data?.subscription_type
      // setSubscriptionType(type)
    }
    getSubscriptionType()
  }, [])

  return (
    <>
      <Modal
        className='modalStyleNewDeal'
        centered
        open={open}
        okButtonProps={{ className: 'hidden' }}
        cancelButtonProps={{ className: 'hidden' }}
        onCancel={onClose}
        width={'fit-content'}
        style={{ minWidth: 500, maxWidth: 500 }}>
        <div className={styles.contentStyle}>
          <div className={styles.container}>
            <img src={warning} alt='' />
            <p>Insufficient Points</p>
            <span style={{ fontSize: '14px', margin: 0, padding: 0 }}>
              {`This action costs ${cost_points} points and you only have ${userData?.convrt_points} left.`}
              {/* You have {userDetails?.convrt_points} points left. {cost_points && `this cost you ${cost_points}`} */}
            </span>
            <span style={{ fontSize: '14px', margin: '10px 0 0 0', padding: 0 }}>
              You can buy more Convrt Points or
            </span>
            <span style={{ fontSize: '14px' }}>use your remaining points to proceed.</span>
            <div className={styles.footer}>
              <Button size={'large'} onClick={onUseRemainingPoints} disabled={userData?.convrt_points === 0}>
                Use Remaining Points
              </Button>
              <Button size={'large'} style={{ color: 'white', backgroundColor: '#7043ff' }} onClick={handleBuyPoints}>
                Buy Points
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <BuyCreditsModal
        open={openBuyCreditsModal}
        onClose={() => {
          setOpenBuyCreditsModal(false)
          onClose()
        }}
      />
    </>
  )
}

export default OutOfPointsUseRemaining
