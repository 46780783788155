import React from 'react'
import styles from './CustomTag.module.scss'
import sent from 'common/assets/svg/outreach_lightning.svg'
import replied from 'common/assets/svg/replied_tag.svg'
import meetings from 'common/assets/svg/meetings_tag.svg'
import blocked from 'common/assets/svg/blocked_tag.svg'

interface CustomTagProps {
  tag: 'sent' | 'replied' | 'meetings' | 'blocked'
  percentage: number
  number: number
}

const CustomTag: React.FC<CustomTagProps> = ({ tag, percentage, number }) => {
  const image = tag === 'sent' ? sent : tag === 'replied' ? replied : tag === 'meetings' ? meetings : blocked
  return (
    <div className={styles.container}>
      <div className={`${styles.tag} ${styles[tag]}`}>
        <img src={image} alt={tag} />
        <span className={styles.percentage}>{percentage}%</span>
      </div>
      <span className={`${styles.number} ${styles[tag + '-tag']}`}>{number}</span>
    </div>
  )
}

export default CustomTag
