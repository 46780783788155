import React from 'react'
import { Button, Modal } from 'antd'

import warning from 'common/assets/svg/warning.svg'

import styles from './ExitModal.module.scss'
import { useNavigate } from 'react-router-dom'

interface ExitModalProps {
  open: boolean
  onClose: () => void
  navigateTo?: any
}

const ExitModal: React.FC<ExitModalProps> = ({ open, onClose, navigateTo }) => {
  const navigate = useNavigate()

  return (
    <>
      <Modal
        className='modalStyleNewDeal'
        centered
        open={open}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}
        onCancel={() => {
          onClose()
        }}
        width={'fit-content'}
        style={{ minWidth: 500, maxWidth: 500 }}>
        <div className={styles.contentStyle}>
          <div className={styles.container}>
            <img src={warning} alt='' />
            <p>Are you sure you want to exit this page?</p>
            <span>All changes will be lost. Please save your campaign before exiting.</span>
            <div className={styles.footer}>
              <Button size={'large'} onClick={onClose}>
                Cancel
              </Button>
              <Button
                size={'large'}
                style={{ color: 'white', backgroundColor: '#7043ff' }}
                onClick={() => {
                  navigate(navigateTo ? navigateTo : '/outreach')
                  onClose()
                }}>
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default ExitModal
