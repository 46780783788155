export enum HUBSPOT {
  repliedProspect = 'On every replied prospect',
  outreach = 'On every Outreach',
  campaignName = 'Name',
  campaignPlatform = 'Platform',
}
export const HUBSPOT_CONTACT_OPTIONS = [
  { value: 'telegram_reply_message', label: 'On every replied prospect' },
  { value: 'telegram_send_message', label: 'On every Outreach' },
]

export const CAMPAIGN_OPTIONS = [
  { value: HUBSPOT.campaignName, label: HUBSPOT.campaignName },
  { value: HUBSPOT.campaignPlatform, label: HUBSPOT.campaignPlatform },
]
