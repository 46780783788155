import React, { useEffect, useState } from 'react'
import styles from './ArticleDrawer.module.scss'
import { Button, Drawer, Input, message, Modal, Upload, UploadProps } from 'antd'
import arrowLeft from 'common/assets/svg/ArrowLeft.svg'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_ARTICLE_OPEN } from '../../../state/slice/learningSlice'
import { useLazyUpdateArticleQuery } from '../../../state/api/LearningCenterAPI'
import 'react-quill/dist/quill.snow.css'
import ReactQuill, { Quill } from 'react-quill'
import ResizeModule from '@botom/quill-resize-module'
import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import { CopyOutlined, UploadOutlined } from '@ant-design/icons'
import { LocalStorageKeys } from 'common/constants/localstorage.constants'
import axios from 'axios'

Quill.register('modules/resize', ResizeModule)

interface ArticleDrawerProps {
  articleTitle: string
  articlePhoto: any
  onClose: any
  content: any
  articleID: any
}

const bodyStyle: React.CSSProperties = {
  backgroundColor: '#F3F4F6',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  paddingTop: 15,
  paddingBottom: 10,
}
export default function ArticleDrawer({ articleTitle, articlePhoto, content, onClose, articleID }: ArticleDrawerProps) {
  const dispatch = useAppDispatch()
  const { articleOpen } = useAppSelector((state) => state.learning)
  const [isEditor, setIsEditor] = useState(false)
  const [updateArticle] = useLazyUpdateArticleQuery()
  const [editValue, setEditValue] = useState<any>(content)
  const [firstRender, setFirstRender] = useState(true)
  const [firstOnCHange, setFirstOnChange] = useState(true)
  const { isAdmin } = useAppSelector((state) => state.learning)
  const [isPreview, setIsPreview] = useState(false)
  const [uploadVideoModalOpen, setUploadVideoModalOpen] = useState(false)
  const [videoURL, setVideoURL] = useState('')
  const [articleVideos, setArticleVideos] = useState<any>([])
  const footer = (
    <div className={`${styles.footer}`}>
      <span className={styles.titleFooter}>Related Content</span>
      <span className={styles.seeMore}>See More</span>
    </div>
  )
  const title = (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
      <div style={{ display: 'flex', height: '100%', gap: 7 }}>
        <div style={{ alignItems: 'flex-end', display: 'flex', marginBottom: 2, cursor: 'pointer' }}>
          <img src={arrowLeft} onClick={handleClose} alt='Arrow Left' />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <div className={styles.title}>Learning Center</div>
          <div className={styles.subTitle}>{articleTitle}</div>
        </div>
      </div>
      <div>
        {isEditor && <Button onClick={handleSave}>Save</Button>}
        {isEditor && (
          <Button
            onClick={() => {
              setIsPreview(true)
              message.success('Preview mode enabled for 10 seconds', 10)
              setTimeout(() => {
                setIsPreview(false)
              }, 10000)
            }}>
            View
          </Button>
        )}
        {isEditor && <Button onClick={() => setUploadVideoModalOpen(true)}>Upload Video</Button>}
      </div>
    </div>
  )
  const toolbarOptions = [
    [{ header: 1 }, { header: 2 }],
    ['bold', 'italic', 'underline', 'link', 'image', 'video'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
  ]

  const uploadVideoProps: UploadProps = {
    name: 'file',
    headers: {
      'Content-Type': 'multipart/form-data',
      authorization: `Bearer ${localStorage
        .getItem(LocalStorageKeys.ACCESS_TOKEN)
        ?.substring(1, String(localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)).length - 1)} `,
      environment: `${process.env.REACT_APP_ENVIRONMENT}`,
    },
    async customRequest(options) {
      const { file, onSuccess } = options

      const formData = new FormData()
      formData.append('video', file)
      const headers = {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${localStorage
          .getItem(LocalStorageKeys.ACCESS_TOKEN)
          ?.substring(1, String(localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)).length - 1)} `,
        environment: `${process.env.REACT_APP_ENVIRONMENT}`,
      }
      try {
        const { data: result } = await axios.post(
          `${process.env.REACT_APP_API_URL}/learning_center/uploadSingleVideo`,
          formData,
          {
            headers: headers,
          },
        )
        if (result.videoUrl) {
          setVideoURL(result.videoUrl)
          if (onSuccess) {
            onSuccess('upload success!')
          }
        } else {
          message.error('Error uploading video')
        }
      } catch (error) {
        message.error('Error uploading video')
        console.error('Error uploading article:', error)
      }
    },
  }
  const stopAllVideos = () => {
    // videos are an iframe with classname ql-video
    // get all of them
    const iframes = document.querySelectorAll('.ql-editor iframe')
    iframes.forEach((iframe) => {
      const iframeSrc = iframe.getAttribute('src')
      // Temporarily remove src to stop the video
      if (iframeSrc) {
        // Save the src to restore it later
        setArticleVideos((prev: any) => [...prev, iframeSrc])
        iframe.setAttribute('src', '')
      }
    })
  }
  const resumeAllVideos = () => {
    // videos are an iframe with classname ql-video
    // get all of them

    const iframes = document.querySelectorAll('.ql-editor iframe')
    iframes.forEach((iframe, index) => {
      const iframeSrc = articleVideos[index]
      // Temporarily remove src to stop the video
      if (iframeSrc) {
        // Save the src to restore it later
        iframe.setAttribute('src', iframeSrc)
      }
    })
  }
  function handleClose() {
    stopAllVideos()
    dispatch(SET_ARTICLE_OPEN('NONE'))
    onClose()
  }
  async function handleSave() {
    const contentToSave = editValue
    if (contentToSave) {
      await updateArticle({ content: contentToSave, articleID: articleID })
      message.success('Article updated successfully')
    }
  }

  useEffect(() => {
    return () => {
      stopAllVideos()
    }
  }, [])

  useEffect(() => {
    if (articleOpen === articleID) {
      resumeAllVideos()
    }
  }, [articleOpen])
  useEffect(() => {
    const init = async () => {
      setIsEditor(isAdmin)
      setEditValue(content)
      setFirstRender(false)
    }
    init()
  }, [])

  return (
    <>
      <Modal
        width={800}
        open={uploadVideoModalOpen}
        onCancel={() => setUploadVideoModalOpen(false)}
        footer={null}
        bodyStyle={{
          height: 200,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
        }}
        title='Upload Video And Get Link'>
        <Input
          addonAfter={
            <CopyOutlined
              style={{ cursor: 'pointer' }}
              onClick={() => {
                message.success('Link copied to clipboard')
                navigator.clipboard.writeText(videoURL)
              }}
            />
          }
          readOnly={true}
          value={videoURL}
        />
        <Upload {...uploadVideoProps}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>{' '}
      </Modal>

      <Drawer
        open={articleOpen === articleID}
        onClose={handleClose}
        closable={false}
        title={title}
        style={{
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.4)', // Add drop shadow
        }}
        width={600}
        bodyStyle={bodyStyle}
        className='article-drawer learning-center-drawer'
        footer={footer}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
        footerStyle={{ backgroundColor: '#F3F4F6' }}>
        {!firstRender && !isPreview && (
          <ReactQuill
            theme='snow'
            value={editValue}
            onChange={(val) => {
              if (firstOnCHange) {
                setEditValue(content)
                setFirstOnChange(false)
                return
              }
              if (!firstRender) {
                setEditValue(val)
              }
            }}
            modules={{
              toolbar: isEditor ? toolbarOptions : false,
              resize: isEditor
                ? {
                    locale: {},
                  }
                : false,
            }}
            readOnly={!isEditor}
          />
        )}
        {isPreview && (
          <ReactQuill
            theme='snow'
            value={editValue}
            modules={{
              toolbar: false,
              resize: false,
            }}
            readOnly={true}
          />
        )}
        {firstRender && <LoadingBox />}
      </Drawer>
    </>
  )
}
