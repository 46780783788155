import cx from 'classnames'
import { ReactComponent as Logo } from 'common/assets/svg/logo.svg'
import { HTMLAttributes } from 'react'
import './MobileLayout.scss'

const MobileLayout = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cx('mobile-layout relative', props.className)}>
      <div className='flex gap-3 align-items-center my-5 absolute top-0'>
        <Logo />
        <span>Convrt</span>
      </div>
      <h1 className='mx-5'>Mobile is coming soon...</h1>
    </div>
  )
}

export default MobileLayout
