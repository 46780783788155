import React, { useEffect, useState } from 'react'
import { Avatar, Dropdown, Space, Select, Tag, Tooltip } from 'antd'
import { UserAddOutlined, EllipsisOutlined, PushpinFilled } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import Pin from 'common/assets/svg/pin_icon.svg'
// import pin_icon from 'common/assets/svg/pin_chat.svg'
// import pinned_chat from 'common/assets/svg/pinned_chat.svg'
import Enrich from 'common/assets/png/Enrich_img.png'
import styles from './ChatDialog.module.scss'
import { useAppDispatch, useAppSelector } from 'state'
import { useLazyChangeMetadataQuery, useLazyGetDialogsQuery } from '../state/api/Chat-API'
import { SET_SELECTED_CHAT_RECIPIENT } from '../state/chatSlice'
import { post } from 'common/api/axios'
import TagItem from '../Tag/TagItem'
import ImageWithFallback from 'common/components/General/ImageWithFallback'

type ChatDialogProps = {
  item: any
  recipientId: any
  recipientName: string
  timeAgo: string
  additionalTags: string[]
  latestMessage: string
  senderData: any
  platform: string
  photo: any
  unread_count: any
  pin: boolean
  priority: string

  onClick: any
}

const ChatDialog: React.FC<ChatDialogProps> = ({
  item,
  recipientId,
  recipientName,
  timeAgo,
  additionalTags,
  latestMessage,
  senderData,
  platform,
  photo,
  unread_count,
  pin,
  priority,

  onClick,
}) => {
  const { selected_chat_recipient } = useAppSelector((state) => state.chat)
  const [changeMetadata] = useLazyChangeMetadataQuery()
  const [getDialogs] = useLazyGetDialogsQuery()
  const dispatch = useAppDispatch()
  const isChatSelected = selected_chat_recipient.id === recipientId

  const { current_tags_dialog } = useAppSelector((state) => state.chat)
  const filteredTagsDialog = current_tags_dialog?.find((record: any) => record.id === item.id)
  const tagsOfDialog = filteredTagsDialog?.data

  const handleOnClick = () => {
    dispatch(
      SET_SELECTED_CHAT_RECIPIENT({
        ...item,
        tags: JSON.stringify(additionalTags),
        senderData: senderData,
      }),
    )
    onClick(
      recipientId,
      // recipientName,
      senderData,
      platform,
      // photo,
      // pin,
      // priority,
      // additionalTags,
      // unread_count,
      // dialog_type,
      // job_title,
      // item.campaign_name,
    )
    // post('/log', {
    //   action: `Unified Inbox: Chat Selected`,
    //   data: {
    //     id: recipientId,
    //     platform: platform,
    //     senderData: senderData,
    //   },
    // })
  }
  const [defaultPriority, setDefaultPriority] = useState(priority)

  const handleChangePriority = async (priority: string) => {
    await changeMetadata({
      platform: platform,
      dialog_id: recipientId,
      senderData: senderData,
      username: item.convrt_index,
      metadata: { priority: priority },
    })
    setDefaultPriority(priority)
    await getDialogs()
    post('/log', {
      action: `Unified Inbox: Priority Changed to: ${priority}`,
      data: {
        id: recipientId,
        platform: platform,
        senderData: senderData,
      },
    })
  }

  const handleEnrichClick = () => {
    console.log('Enrich chat clicked')
  }

  const handlePinChatClick = async () => {
    await changeMetadata({
      platform: platform,
      dialog_id: recipientId,
      senderData: senderData,
      username: item.convrt_index,
      metadata: { pin: !pin },
    })
    await getDialogs()
    if (!pin) {
      post('/log', {
        action: `Unified Inbox: Chat ${!pin ? 'Pinned' : 'Unpinned'}`,
        data: {
          id: recipientId,
          platform: platform,
          senderData: senderData,
        },
      })
    }
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Tooltip title='Coming Soon!'>Enrich</Tooltip>,
      icon: <img src={Enrich} alt='' />,
      disabled: true,
      onClick: handleEnrichClick,
    },
    {
      key: '2',
      label: <span>{pin ? 'Unpin Chat' : 'Pin Chat'}</span>,
      icon: <img src={Pin} alt='' />,
      onClick: handlePinChatClick,
    },
  ]

  const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
  }

  const getInitials = (name: string) => {
    const names = name.split(' ')
    const initials = names.map((part) => part[0]).join('')
    return initials
  }

  const getColor = (initials: string) => {
    const colors = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae', '#f44336', '#009688']
    const index = initials.charCodeAt(0) % colors.length
    return colors[index]
  }

  const initials = recipientName ? getInitials(recipientName) : null

  const formatDate = (inputDate: string) => {
    const now = new Date()
    const sentDate = new Date(inputDate)
    const dateDiff = now.getTime() - sentDate.getTime()
    const seconds = Math.round(dateDiff / 1000)
    const minutes = Math.round(seconds / 60)
    const hours = Math.round(minutes / 60)
    const days = Math.round(hours / 24)

    const formatTime = (date: Date) => date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

    if (seconds < 60) {
      return 'A few seconds ago'
    } else if (minutes < 60) {
      return `${minutes} minute${minutes === 1 ? '' : 's'} ago`
    } else if (hours < 24) {
      return `${hours} hour${hours === 1 ? '' : 's'} ago`
    } else if (hours < 48) {
      // Yesterday
      return `Yesterday at ${formatTime(sentDate)}`
    } else if (days < 3) {
      // 2 days ago
      return `${days} days ago at ${formatTime(sentDate)}`
    } else {
      // Formatting date in MM/DD/YYYY, HH:MM format
      return sentDate.toLocaleDateString() + ' at ' + formatTime(sentDate)
    }
  }

  useEffect(() => {
    setDefaultPriority(priority)
  }, [priority])

  return (
    <div
      className={`${styles['chat_receipt_box']} chat_receipt_box`}
      style={isChatSelected ? { outline: '1px solid #7043ff' } : {}}
      onClick={handleOnClick}>
      <div className='chat_receipt_box__main'>
        <div className={styles.chat_receipt_box__topSection}>
          <h3 style={{ display: 'flex', alignItems: 'center' }}>
            {unread_count > 0 && (
              <div className={styles.unRead}>
                <span className={styles.redDot}></span>
              </div>
            )}
            {photo ? (
              <ImageWithFallback
                className={styles.profilePhoto}
                src={photo}
                fallbackStrOrElement={
                  <Avatar size={24} style={{ backgroundColor: getColor(initials || ''), width: '24px' }}>
                    {initials}
                  </Avatar>
                }
              />
            ) : initials ? (
              <Avatar size={24} style={{ backgroundColor: getColor(initials), width: '24px' }}>
                {initials}
              </Avatar>
            ) : (
              <Avatar size={24} style={{ width: '24px', height: '24px' }} icon={<UserAddOutlined />} />
            )}
            {recipientName?.length > 35 ? (
              <Tooltip title={recipientName}>{recipientName?.slice(0, 35)?.trim()}...</Tooltip>
            ) : (
              recipientName
            )}
          </h3>
          <div className={styles.chat_receipt_box__topSection__right} onClick={handleStopPropagation}>
            <span className={styles.chat_receipt_box__topSection__time}>{formatDate(timeAgo)}</span>
            <Dropdown menu={{ items }}>
              <Space>
                <Avatar
                  style={{ backgroundColor: 'transparent', color: '#000000', fontSize: '18px' }}
                  size={24}
                  icon={<EllipsisOutlined />}
                />
              </Space>
            </Dropdown>
          </div>
        </div>
        <div className={styles.chat_receipt_box__middleSection}>
          <p>
            {latestMessage?.length > 100 ? (
              <Tooltip title={latestMessage}>{latestMessage.slice(0, 100)?.trim()}...</Tooltip>
            ) : (
              latestMessage
            )}
          </p>
          <div className={styles.chat_receipt_box__middleSection__icons}>
            {pin && <PushpinFilled style={{ color: '#7043ff' }} />}
            {unread_count > 0 && <span style={{ color: '#97a0af' }}>{unread_count}</span>}
          </div>
        </div>
        <div className={styles['chat_receipt_box__bottomSection']}>
          <Select
            className='selectPriority'
            size='small'
            defaultValue={defaultPriority}
            value={defaultPriority}
            style={{ width: 99 }}
            onClick={handleStopPropagation}
            placeholder='Priority'
            onChange={handleChangePriority}>
            <Select.Option value='high'>
              <div className={styles.priority}>
                <span className='select_dots'></span>
                <span>High</span>
              </div>
            </Select.Option>
            <Select.Option value='medium'>
              <div className={styles.priority}>
                <span className='select_dots' style={{ backgroundColor: '#FFC700' }}></span>
                <span>Medium</span>
              </div>
            </Select.Option>
            <Select.Option value='low'>
              <div className={styles.priority}>
                <span className='select_dots' style={{ backgroundColor: 'green' }}></span>
                <span>Low</span>
              </div>
            </Select.Option>
          </Select>
          {additionalTags.slice(0, 2).map((tag) => (
            <Tag
              className={styles['tag-item']}
              style={{
                backgroundColor: '#f2efff',
                color: '#7043ff',
                borderColor: '#f2f0ff',
                marginRight: 0,
              }}
              key={tag}>
              {tag?.length > 11 ? <Tooltip title={tag}>{tag.slice(0, 11)?.trim()}...</Tooltip> : tag}
            </Tag>
          ))}
          {additionalTags.length > 2 && (
            <Tooltip
              title={additionalTags.slice(2).map((tag, index, array) => (
                <Tag
                  className={styles['tag-item']}
                  style={{
                    backgroundColor: '#f2efff',
                    color: '#7043ff',
                    borderColor: '#f2f0ff',
                    marginRight: index === array.length - 1 ? '0px' : undefined,
                  }}
                  key={tag}>
                  {tag}
                </Tag>
              ))}>
              <span className={styles['chat_receipt_box__bottomSection__more']}>+{additionalTags.length - 2}</span>
            </Tooltip>
          )}
          {tagsOfDialog?.slice(0, 1).map((tag: any) =>
            tag?.tag_name?.length > 11 ? (
              <Tooltip title={tag}>
                <TagItem key={tag.id} title={`${tag.tag_name?.slice(0, 11)?.trim()}...`} />
              </Tooltip>
            ) : (
              <TagItem key={tag.id} title={tag.tag_name} />
            ),
          )}
          {tagsOfDialog?.length > 1 && (
            <Tooltip
              title={tagsOfDialog?.slice(1).map((tag: any, index: number) => (
                <TagItem key={tag.id} title={tag.tag_name}></TagItem>
              ))}>
              <span className={styles['chat_receipt_box__bottomSection__tag__more']}>+{tagsOfDialog?.length - 1}</span>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChatDialog
