import { Tooltip, Typography } from 'antd'
import type { ColumnsType } from 'antd/es/table'

import { IContactsTable } from 'features/contacts/interfaces/contacts-table.interface'
import styles from './contactTable.module.scss'
import { FancyImage } from 'features/contacts/components/FancyImage/FancyImage'

export const rawLeadTableColumn: ColumnsType<IContactsTable> = [
  {
    title: 'Name',
    dataIndex: 'full_name',
    key: 'full_name',
    width: 120,
    render: (text: string, item: any) => {
      const fullName = `${item.first_name} ${item.last_name}`
      return (
        <div className='tblLogoName'>
          <FancyImage
            className={`${styles['company_logo']} 'mainPicture'`}
            url={item?.photo_url}
            name={fullName}
            size={24}
          />
          <Tooltip placement='topLeft' title={fullName}>
            <span className={`${styles['table__text__company']} textTooltip`}>
              {fullName?.length > 17 ? `${fullName.slice(0, 17)?.trim()}...` : fullName}
            </span>
          </Tooltip>
        </div>
      )
    },
  },
  {
    title: 'Username',
    dataIndex: 'user_name',
    key: 'user_name',
    width: 120,
    render: (text: string, item: any) => {
      return <Typography className={`${styles['table__text__company']}`}>{text}</Typography>
    },
  },
  {
    title: 'Phone number',
    dataIndex: 'phone_number',
    key: 'phone_number',
    width: 120,
    render: (text: string, item: any) => {
      return <Typography className={`${styles['table__text__company']}`}>{text}</Typography>
    },
  },
]
