import React, { useEffect } from 'react'
import black_tick from 'common/assets/svg/black_tick.svg'
import like_heart from 'common/assets/svg/like_heart.svg'

import styles from './LinkedinLike.module.scss'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_SEQUENCE_DATA } from 'common/components/OutreachCampaign/state/outreachCampaignSlice'

interface LinkedinLikeProps {
  currentSideBar?: any
  nodesData?: any
  setNodesData?: any
}

const LinkedinLike: React.FC<LinkedinLikeProps> = ({ currentSideBar, nodesData, setNodesData }) => {
  const dispatch = useAppDispatch()
  const { max_posts } = useAppSelector((state) => state.outreachCampaign)

  useEffect(() => {
    const nodeId = currentSideBar.nodeId
    const updatedSteps = {
      ...nodesData.steps,
      [nodeId]: {
        type: 'linkedin_like_post',
        previous_step_id: nodesData?.steps?.[currentSideBar?.nodeId]?.previous_step_id,

        data: { max_posts: max_posts },
      },
    }

    setNodesData((prev: any) => {
      dispatch(SET_SEQUENCE_DATA({ ...prev, steps: updatedSteps }))
      return { ...prev, steps: updatedSteps }
    })
  }, [max_posts])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={black_tick} alt='' />
        <span>Step is Set</span>
      </div>
      <div className={styles.mainBody}>
        <img src={like_heart} alt='' />
        <p>
          Like {max_posts} Post{max_posts === 1 ? '' : 's'}
        </p>
        {/* <span>And then...</span> */}
      </div>
    </div>
  )
}

export default LinkedinLike
