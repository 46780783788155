import React, { useState } from 'react'
import { Tabs, Form, Mentions } from 'antd'
import classNames from 'classnames'

interface Option {
  value: string
  label: string
}

interface MessageTemplateTabsProps {
  options: Option[]
  updateNodesData: any
  savedMessages: string[] // Now an array of messages
  setCurrentTab: any
  edit_mode: boolean
  enable_editing: boolean
  isOpenPhoneNumberTabel: any
  compare?: any
  isCompareAbTesting?: any
  form: any
  status: string
}

const MessageTemplateTabs: React.FC<MessageTemplateTabsProps> = ({
  options,
  updateNodesData,
  savedMessages = [],
  setCurrentTab,
  edit_mode,
  status,
  enable_editing,
  isOpenPhoneNumberTabel,
  compare,
  isCompareAbTesting,
  form,
}) => {
  const labels = ['A', 'B', 'C']
  const allowEditTesting = status !== 'draft' && status !== 'paused'
  const initialTabs = labels.map((label, index) => ({
    label,
    key: label,
    message: savedMessages[index] || '',
  }))

  const [activeKey, setActiveKey] = useState<string>(initialTabs?.[0]?.key || 'A')

  const [tabs, setTabs] = useState<any[]>(initialTabs)
  const [messages, setMessages] = useState<{ [key: string]: string }>(
    initialTabs.reduce((acc, tab) => ({ ...acc, [tab.key]: tab.message }), {}),
  )

  const onChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey)
    setCurrentTab(newActiveKey)
  }

  const onEdit = (targetKey: any, action: 'add' | 'remove') => {
    if (action === 'add') {
      add()
    } else if (typeof targetKey === 'string') {
      remove(targetKey)
    }
  }

  const add = () => {
    if (tabs.length >= 3) return

    const nextLabel = labels[tabs.length]
    const newTab = {
      label: nextLabel,
      key: nextLabel,
      message: '',
    }
    setTabs([...tabs, newTab])
    // setMessages({ ...messages, [nextLabel]: '' })
    setMessages((prevMessages) => ({ ...prevMessages, [nextLabel]: '' }))
    setActiveKey(newTab.key)
    setCurrentTab(newTab.key)
  }

  const remove = (targetKey: string) => {
    const newTabs = tabs.filter((tab) => tab.key !== targetKey)
    const { [targetKey]: removed, ...newMessages } = messages
    form.setFieldsValue({ [`message${targetKey}`]: '' })

    setTabs(newTabs)
    setMessages(newMessages)
    updateNodesData('', targetKey)

    if (newTabs.length) {
      setActiveKey(newTabs[newTabs.length - 1].key)
      setCurrentTab(newTabs[newTabs.length - 1].key)
    } else {
      setActiveKey('A')
    }
  }

  const handleMentionsChange = (key: string, value: string) => {
    // setMessages({ ...messages, [key]: value })
    setMessages((prevMessages) => ({
      ...prevMessages,
      [key]: value,
    }))
    updateNodesData(value)
    compare(key, value)
  }

  return (
    <Tabs
      className={classNames('builderSider', isCompareAbTesting && 'messagesTabsError')}
      onChange={onChange}
      activeKey={activeKey}
      onEdit={onEdit}
      style={{ margin: 0, paddingTop: '10px' }}
      items={tabs.map((tab, index, arr) => {
        return {
          disabled: isCompareAbTesting,
          label: tab.label,
          key: tab.key,
          children: (
            <Form.Item name={`message${tab.label}`} style={{ paddingTop: '0px' }}>
              <Mentions
                className='inputText'
                autoSize={{ minRows: 6, maxRows: 6 }}
                maxLength={5000}
                style={{
                  padding: '12px 4px 50px 4px',
                  marginTop: '-1px',
                  backgroundColor: edit_mode && !enable_editing && allowEditTesting ? '#f5f5f5' : '',
                  borderRadius: '8px',
                  borderColor: isCompareAbTesting && '#da1818',
                }}
                placeholder='You can use @ to ref user data here'
                defaultValue={messages[tab.key]}
                options={options}
                onChange={(e) => handleMentionsChange(tab.key, e)}
                onSelect={(option: any, prefix: string) => {
                  isOpenPhoneNumberTabel(`@${option.key}`)
                }}
                disabled={edit_mode && !enable_editing && allowEditTesting}
              />
            </Form.Item>
          ),
        }
      })}
      hideAdd={
        tabs.length >= 3 ||
        (edit_mode && !enable_editing && allowEditTesting) ||
        (allowEditTesting && edit_mode) ||
        isCompareAbTesting
      }
    />
  )
}

export default MessageTemplateTabs
