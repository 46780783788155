import { createSlice } from '@reduxjs/toolkit'

interface IImportContactSlice {
  activeStep: string
  csvData: {
    file: File | null
    fieldArray: string[]
    rowData: string[][]
    dataAfterMapping: any[]
    file_blob: Blob | null
  }
  currentCustomList: any
  importType: string
  enrichPoints: any

  userPoints: any
  isLoadingEnrich: boolean
}

export const initialStateCsvData = {
  file: null,
  fieldArray: [],
  rowData: [],
  dataAfterMapping: [],
  file_blob: null,
}

export const initialStateUserPoints = {
  points: 0,
  pointsStore: {},
}

const initialState = {
  activeStep: '1',
  csvData: initialStateCsvData,
  importType: '',
  enrichPoints: null,
  userPoints: initialStateUserPoints,
  currentCustomList: null,
  isLoadingEnrich: false,
} as IImportContactSlice

export const importContactSlice = createSlice({
  name: 'outreached',
  initialState,
  reducers: {
    SET_ACTIVE_STEP(state, action) {
      state.activeStep = action.payload
    },
    SET_CSV_DATA(state, action) {
      const oldState = state.csvData
      state.csvData = {
        ...oldState,
        ...action.payload,
      }
    },
    SET_CURRENT_CUSTOM_LIST(state, action) {
      state.currentCustomList = action.payload
    },
    SET_IMPORT_TYPE(state, action) {
      state.importType = action.payload
    },
    SET_DATA_AFTER_MAPPING(state, action) {
      state.csvData.dataAfterMapping = action.payload
    },
    SET_ENRICH_POINT(state, action) {
      const oldState = state.enrichPoints
      state.enrichPoints = {
        ...oldState,
        ...action.payload,
      }
    },
    SET_USER_POINTS(state, action) {
      state.userPoints = action.payload
    },
    SET_LOADING_ENRICH(state, action) {
      state.isLoadingEnrich = action.payload
    },
  },
})

export const {
  SET_ACTIVE_STEP,
  SET_CSV_DATA,
  SET_CURRENT_CUSTOM_LIST,
  SET_IMPORT_TYPE,
  SET_DATA_AFTER_MAPPING,
  SET_ENRICH_POINT,
  SET_USER_POINTS,
  SET_LOADING_ENRICH,
} = importContactSlice.actions
export const importContactReducer = importContactSlice.reducer
