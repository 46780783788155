import { useEffect, useState } from 'react'
import { Button, Checkbox } from 'antd'

import FinishPage from './FinishPage'
import LoadingBox from '../LoadingBox/LoadingBox'
import { useAuth } from 'common/hooks/useAuth.hooks'
import OnBoardingPageA from './OnBoardingPages/OnBoardingPageA'
import OnBoardingPageB from './OnBoardingPages/OnBoardingPageB'
import OnBoardingPageC from './OnBoardingPages/OnBoardingPageC'
import OnBoardingPageD from './OnBoardingPages/OnBoardingPageD'
import OnBoardingPageE from './OnBoardingPages/OnBoardingPageE'
import OnBoardingPageF from './OnBoardingPages/OnBoardingPageF'

import OutreachConnection from 'common/components/OutreachConnection/OutreachConnection'

import { post, put, telegramPost } from 'common/api/axios'

import styles from './ConnectPlusOnBoarding.module.scss'
import { useLocalStorage } from 'common/hooks/useLocalStorage.hooks'

const ConnectPlusOnBoarding = ({
  onClose: handleClose,
  setProgress,
  setContributorLogsParams,
  isImportTelegram,
}: any) => {
  const { refetchUserLite, userDetails } = useAuth()
  // eslint-disable-next-line
  const [user, setUser] = useLocalStorage('user', null)

  const [step, setStep] = useState(0)
  const stages = [
    OnBoardingPageA,
    OnBoardingPageB,
    OnBoardingPageC,
    OnBoardingPageD,
    OnBoardingPageE,
    OnBoardingPageF,
    FinishPage,
  ]
  const buttonText = [
    "Let's Connect Your Telegram Account",
    'Send Me a Verification Code',
    'Validate Code And Connect',
    'Validate Password',
    `Done! Let's Connect`,
    `I'm not interested in Outreach Powers`,
    `Finalize`,
  ]

  const [agreedToTerms, setAgreedToTerms] = useState(false)
  const [validationStatus, setValidationStatus] = useState(false)
  const [verificationCode, setVerificationCode] = useState<string>()
  const [telegramPassword, setTelegramPassword] = useState()
  const [passwordError, setPasswordError] = useState<string>()
  const [groups, setGroups] = useState<any>([])
  const [contacts, setContacts] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false)
  const [verificationData, setVerificationData] = useState<{
    hash: string
    session: string
    encrypted_telegram_credential: string
  }>()

  const [formData, setFormData] = useState<any>({
    prefix: '',
    telegramNumber: '',
  })
  const handleContactsChange = (e: any) => {
    setContacts(e)
  }

  const handleGroupsChange = (e: any) => {
    setGroups(e)
  }

  const handleStepChange = async () => {
    if (step === 1) {
      if (!(await handleSubmit())) return
    }

    if (step === 2) {
      await verifyCode()
      return
    }

    if (step === 3) {
      await verifyPassword()
      return
    }

    if (step === 4) {
      if (!(await setExclusions())) return
    }

    if (step === 5) {
      if (isImportTelegram) {
        await handleAddTelegram()
      } else {
        await finish()
      }
      // return
    }

    if (step === 6) {
      // await finish()
      handleClose()
      setStep(0)
      return
    }

    setStep((step) => step + 1)
  }

  const handleAddTelegram = async () => {
    try {
      setProgress(0)
      setLoading(true)
      telegramPost(`/fetchContributor`, {
        email: userDetails?.email,
        number: formData?.telegramNumber,
        area_code: formData?.prefix?.split('-')?.[0],
      })
      setContributorLogsParams({
        email: userDetails?.email,
        number: formData?.telegramNumber,
        area_code: formData?.prefix?.split('-')?.[0],
      })

      const { data } = await put(`/user/telegram`, {
        email: userDetails?.email,
        number: formData?.telegramNumber,
        area_code: formData?.prefix?.split('-')?.[0],
        isUpdateOutreach: false,
        isUpdateTelegram: false,
        isManaged: false,
        isUpdateConnectedTelegrams: true,
      })
      const {
        id,
        name,
        email,
        addons,
        telegram,
        company,
        outreach,
        did_funnel,
        funnelData,
        limit_number,
        company_admin,
        subscription_type,
        subscription_created_at,
        plan_type,
        plan_cycle,
        credits,
        initial_credits,
        premium_id,
        subscription_days,
        convrt_points,
        initial_convrt_points,
        dont_show_pricing_page,
        linkedin,
        instagram,
        connected_telegrams,
      } = data?.user || {}

      refetchUserLite({
        id,
        name,
        email,
        telegram,
        company,
        limit_number,
        company_admin,
        subscription_days,
        subscription_created_at,
        plan_type,
        plan_cycle,
        did_funnel,
        funnelData,
        subscription_type,
        addons,
        credits,
        initial_credits,
        outreach,
        premium_id,
        convrt_points: +convrt_points,
        initial_convrt_points: +initial_convrt_points,
        flag: dont_show_pricing_page,
        linkedin,
        instagram,
        connected_telegrams,
      })

      setUser({
        id,
        name,
        email,
        telegram,
        company,
        limit_number,
        company_admin,
        subscription_days,
        subscription_created_at,
        plan_type,
        plan_cycle,
        did_funnel,
        funnelData,
        subscription_type,
        addons,
        credits,
        initial_credits,
        outreach,
        premium_id,
        convrt_points: +convrt_points,
        initial_convrt_points: +initial_convrt_points,
        flag: dont_show_pricing_page,
        linkedin,
        instagram,
        connected_telegrams,
      })

      post('/log', {
        action: 'TG Onboarding: Finished',
      })

      // handleClose()
    } catch (e) {
      console.error(`Error fetching contributor: ${e}`)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const response = await telegramPost('/getCode', {
        number: formData?.telegramNumber,
        area_code: formData?.prefix?.split('-')?.[0],
      })

      if (response.status !== 200) return false

      const {
        data: { hash, session, encrypted_telegram_credential },
      } = response

      setVerificationData({ hash, session, encrypted_telegram_credential })

      post('/log', {
        action: 'TG Onboarding: Entered Phone Number',
      })

      return true
    } catch (e) {
      console.error(e)
      return false
    } finally {
      setLoading(false)
    }
  }

  const verifyCode = async () => {
    let proceed = 0

    try {
      setLoading(true)
      const response = await telegramPost('/signin', {
        ...verificationData,
        code: verificationCode,
        number: formData?.telegramNumber,
        area_code: formData?.prefix?.split('-')?.[0],
      })
      if (response?.status === 200) proceed = 2
      setIsSignedIn(true)
    } catch (e: any) {
      console.error(e)

      if (e.response.data.message === 'Two-steps verification is enabled and a password is required') proceed = 1
    } finally {
      await telegramPost('/submitForm', {
        email: userDetails?.email,
        terms: agreedToTerms,
        number: formData?.telegramNumber,
        session: verificationData?.session,
        lastname: userDetails?.name,
        firstname: userDetails?.name,
        area_code: formData?.prefix?.split('-')?.[0],
        exclusions: { contact_ids: [], group_ids: [] },
        encrypted_telegram_credential: verificationData?.encrypted_telegram_credential,
      })
      setLoading(false)
      setStep((step) => step + proceed)

      post('/log', {
        action: 'TG Onboarding: Entered Login Code',
      })
    }
  }

  const verifyPassword = async () => {
    try {
      setLoading(true)
      await telegramPost(`/setPassword`, {
        email: userDetails?.email,
        number: formData?.telegramNumber,
        password: telegramPassword,
        area_code: formData?.prefix?.split('-')?.[0],
      })
      setIsSignedIn(true)
      setStep((step) => step + 1)
      post('/log', {
        action: 'TG Onboarding: Entered 2FA Password',
      })
    } catch (e: any) {
      console.error(e)
      if (e?.response?.data && e.response.data.message) {
        setPasswordError(e.response.data.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const setExclusions = async () => {
    try {
      setLoading(true)
      const response = await telegramPost('/submitForm', {
        email: userDetails?.email,
        terms: agreedToTerms,
        number: formData?.telegramNumber,
        session: verificationData?.session,
        lastname: userDetails?.name,
        firstname: userDetails?.name,
        area_code: formData?.prefix?.split('-')?.[0],
        exclusions: { contact_ids: contacts || [], group_ids: groups || [] },
        encrypted_telegram_credential: verificationData?.encrypted_telegram_credential,
      })

      post('/log', {
        action: 'TG Onboarding: Finished Excluding Groups & Chats',
      })

      return response?.status === 201
    } catch (e) {
      console.error(e)
      return false
    } finally {
      setLoading(false)
    }
  }

  const finish = async () => {
    try {
      setProgress(0)
      setLoading(true)
      telegramPost(`/fetchContributor`, {
        email: userDetails?.email,
        number: formData?.telegramNumber,
        area_code: formData?.prefix?.split('-')?.[0],
      })
      setContributorLogsParams({
        email: userDetails?.email,
        number: formData?.telegramNumber,
        area_code: formData?.prefix?.split('-')?.[0],
      })

      const { data } = await put(`/user/telegram`, {
        email: userDetails?.email,
        number: formData?.telegramNumber,
        area_code: formData?.prefix?.split('-')?.[0],
        isUpdateOutreach: isImportTelegram,
        isUpdateTelegram: !isImportTelegram,
        isManaged: false,
        isImportTelegram,
      })
      const {
        id,
        name,
        email,
        addons,
        telegram,
        company,
        outreach,
        did_funnel,
        funnelData,
        limit_number,
        company_admin,
        subscription_type,
        subscription_created_at,
        plan_type,
        plan_cycle,
        credits,
        initial_credits,
        premium_id,
        subscription_days,
        convrt_points,
        initial_convrt_points,
        dont_show_pricing_page,
        linkedin,
        instagram,
        connected_telegrams,
      } = data?.user || {}

      refetchUserLite({
        id,
        name,
        email,
        telegram,
        company,
        limit_number,
        company_admin,
        subscription_days,
        subscription_created_at,
        plan_type,
        plan_cycle,
        did_funnel,
        funnelData,
        subscription_type,
        addons,
        credits,
        initial_credits,
        outreach,
        premium_id,
        convrt_points: +convrt_points,
        initial_convrt_points: +initial_convrt_points,
        flag: dont_show_pricing_page,
        linkedin,
        instagram,
        connected_telegrams,
      })

      setUser({
        id,
        name,
        email,
        telegram,
        company,
        limit_number,
        company_admin,
        subscription_days,
        subscription_created_at,
        plan_type,
        plan_cycle,
        did_funnel,
        funnelData,
        subscription_type,
        addons,
        credits,
        initial_credits,
        outreach,
        premium_id,
        convrt_points: +convrt_points,
        initial_convrt_points: +initial_convrt_points,
        flag: dont_show_pricing_page,
        linkedin,
        instagram,
        connected_telegrams,
      })

      post('/log', {
        action: 'TG Onboarding: Finished',
      })

      // handleClose()
    } catch (e) {
      console.error(`Error fetching contributor: ${e}`)
    } finally {
      setLoading(false)
    }
  }

  const handleFormDataChange = (data: any) => {
    setFormData((formData: any) => ({ ...formData, ...data }))
    const isTelegramNumber = /^[0-9]{4,}$/.test(data.telegramNumber)
    setValidationStatus(isTelegramNumber)
  }

  const handleCheckboxChange = (e: any) => {
    setAgreedToTerms(e.target.checked)
  }

  const Stage = stages[step] || stages[0]
  const [connectPlusModalOpen, setConnectPlusModalOpen] = useState(false)

  useEffect(() => {
    setPasswordError('')
  }, [telegramPassword])
  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <div className={styles.container}>
          <Stage
            formData={formData}
            handleSubmit={handleSubmit}
            handleGroupsChange={handleGroupsChange}
            setTelegramPassword={setTelegramPassword}
            handleFormDataChange={handleFormDataChange}
            handleContactsChange={handleContactsChange}
            handleVerificationCompletion={setVerificationCode}
            selectedGroups={groups}
            selectedContacts={contacts}
            error={passwordError}
            isSignedIn={isSignedIn}
          />
          <div className={styles.footer}>
            {step === 2 && (
              <div className={styles.terms}>
                <Checkbox onChange={handleCheckboxChange}>
                  <span>{`I agree to the `}</span>
                  <a href='https://www.convrt.io/terms-of-use/' target='_blank' rel='noreferrer'>
                    {`Terms & Conditions`}
                  </a>
                </Checkbox>
              </div>
            )}

            {step === 5 ? (
              <Button
                onClick={async () => {
                  await finish()
                  // handleClose()
                  if (!isImportTelegram) {
                    setConnectPlusModalOpen(true)
                  } else {
                    setStep((step) => step + 1)
                  }
                }}
                className={styles.modalButton_page_f}>
                {isImportTelegram ? 'Add to outreach' : 'Continue'}
              </Button>
            ) : (
              <></>
            )}

            <Button
              style={step === 2 ? { margin: '80px 0px' } : {}}
              onClick={handleStepChange}
              disabled={(step === 1 && !validationStatus) || (step === 2 && (!agreedToTerms || !verificationCode))}
              className={step === 5 ? styles.modaBackButton : styles.modalButton}>
              {buttonText[step]}
            </Button>

            {connectPlusModalOpen && (
              <OutreachConnection
                open={connectPlusModalOpen}
                onClose={() => setConnectPlusModalOpen(false)}
                comingFromConnect={true}
                onClosePrevModal={() => handleClose()}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default ConnectPlusOnBoarding
