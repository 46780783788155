import React, { useEffect, useState } from 'react'
import { Select, Divider, DatePicker, Space, Button } from 'antd'
import styles from './CampaignFilters.module.scss'
import {
  SET_CAMPAIGN_DATES_FILTERS,
  SET_CAMPAIGN_FILTERS,
  SET_SHOW_PERCENTAGE,
} from '../../../state/outreachCampaignSlice'
import { useAppDispatch, useAppSelector } from 'state'
import { useLazyGetSenderQuery } from 'features/Outreach/state/api/OutreachApi'
import { useAuth } from 'common/hooks/useAuth.hooks'
import type { TimeRangePickerProps } from 'antd'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import CustomToggleSwitch from '../UI/CustomToggleSwitch'
import { PlusCircleOutlined } from '@ant-design/icons'
import { post } from 'common/api/axios'
import { useNavigate } from 'react-router-dom'
import OutreachCampaignWithAIModal from 'common/components/OutreachCampaign/NewCampaign/Components/Templates/OutreachCampaignWithAIModal'
import TemplatesWizardModal from 'common/components/OutreachCampaign/NewCampaign/Components/Templates/TemplatesWizardModal'

const { Option } = Select
const { RangePicker } = DatePicker

interface CampaignFiltersProps {
  selectedTab: any // 1 === campaigns | 2 === recipients | 3 === messages
}

const CampaignFilters: React.FC<CampaignFiltersProps> = ({ selectedTab }) => {
  const [filterType, setFilterType] = useState<string>()
  const [filterValue, setFilterValue] = useState<any>()
  const [refetcGetSenderList, { data }] = useLazyGetSenderQuery()
  const [openTemplatesWizardModal, setOpenTemplatesWizardModal] = useState(false)
  const [openOutreachCampaignWithAIModal, setOpenOutreachCampaignWithAIModal] = useState(false)
  const { all_campaigns_data, campaign_filters, campaign_date_filters } = useAppSelector(
    (state) => state.outreachCampaign,
  )
  const [campaignNames, setCampaignNames] = useState([])
  const [dateType, setDateType] = useState<any>('sent_at')
  const navigate = useNavigate()

  const stepsItems = [
    { telegram_outreach: 'Outreach' },
    { telegram_followup: 'Followup' },
    // { second_telegram_followup: 'Followup (2)' },
  ]

  const handleSetDateType = (value: any) => {
    setDateType(value)
    dispatch(SET_CAMPAIGN_DATES_FILTERS({ ...campaign_date_filters, dateType: value }))
  }

  const handleNewCampaignClick = () => {
    // navigate('/outreach/create-new-campaign')
    setOpenOutreachCampaignWithAIModal(true)
    post('/log', {
      action: 'Add new Campaign button',
    })
  }

  const handleSelectTemplate = () => {
    navigate('/outreach/create-new-campaign')
  }

  const getFilterItems = (selectedTab: any) => {
    switch (selectedTab) {
      case '1':
        return ['Campaign Name', 'Senders', 'Show Archive', 'Platform']
      case '2':
        // return ['Campaign Name', 'Last Step', 'Replied']
        return ['Campaign Name', 'Senders', 'Last Step', 'Replied']
      case '3':
        return ['Campaign Name', 'Senders', 'Last Step']
      default:
        return []
    }
  }
  const filterItems = getFilterItems(selectedTab)
  const { userDetails } = useAuth()
  const dispatch = useAppDispatch()

  const handleFilterTypeChange = (value: string) => {
    setFilterType(value)
    if (value === 'Show Archive') {
      setFilterValue('archive')
      dispatch(SET_CAMPAIGN_FILTERS({ type: 'Show Archive', value: 'archive' }))
    } else {
      setFilterValue(undefined)
    }
    // dispatch(SET_CAMPAIGN_FILTERS({}))
  }

  const handleSelectChange = (value: any) => {
    setFilterValue(value)
    dispatch(SET_CAMPAIGN_FILTERS({ type: filterType, value }))
  }

  //   const handleApplyFilters = () => {
  //     dispatch(SET_CAMPAIGN_FILTERS({ type: filterType, value: filterValue }))
  //   }

  const handleClearFilters = async () => {
    dispatch(SET_CAMPAIGN_FILTERS({}))
    setFilterValue(undefined)
    setFilterType(filterItems[0])
  }

  const renderOption = (item: any) => {
    const fullNumber = item.area_code + item.number
    if (!fullNumber) return null
    const user: any = userDetails?.outreach?.find((u: any) => fullNumber?.includes(u.number))
    if (user?.userName?.length > 0) {
      return (
        <span>
          {user?.userName} ({fullNumber})
        </span>
      )
    } else {
      return <span key={fullNumber}>{fullNumber}</span>
    }
  }

  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
  ]

  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (dates) {
      dispatch(SET_CAMPAIGN_DATES_FILTERS({ from: dates[0], to: dates[1], dateType: dateType }))
    } else {
      dispatch(SET_CAMPAIGN_DATES_FILTERS({}))
    }
  }

  useEffect(() => {
    setFilterType(filterItems[0])
    setFilterValue(undefined)
    dispatch(SET_CAMPAIGN_FILTERS({}))
  }, [selectedTab])

  useEffect(() => {
    const getRefetcGetSenderList = async () => {
      await refetcGetSenderList()
    }
    getRefetcGetSenderList()
  }, [])

  useEffect(() => {
    const names = all_campaigns_data?.map((c: any) => ({ campaign_name: c.campaign_name, campaign_id: c.campaign_id }))
    const uniqueArray: any = Array.from(new Set(names))
    setCampaignNames(uniqueArray)
  }, [all_campaigns_data])

  const renderFilterOptions = () => {
    switch (filterType) {
      case 'Senders':
        return data?.response.map((item: any, index: number) => {
          return (
            <Option key={index} value={item.id} disabled={item.cooldown}>
              {renderOption(item)}
            </Option>
          )
        })
      case 'Campaign Name':
        return campaignNames?.map((item: any, index: any) => {
          return (
            <Option key={index} value={item.campaign_id} label={item.campaign_name}>
              {item.campaign_name}
            </Option>
          )
        })
      case 'Last Step':
        return stepsItems?.map((item: any, index: any) => {
          const stepValue: any = Object.values(item)[0]
          const stepKey: any = Object.keys(item)[0]
          return (
            <Option key={index} value={stepKey}>
              {stepValue}
            </Option>
          )
        })
      case 'Replied':
        return ['Replied', 'Did not Reply']?.map((item: any, index: any) => (
          <Option key={index} value={item}>
            {item}
          </Option>
        ))
      case 'Platform':
        return ['Telegram', 'LinkedIn']?.map((item: any, index: any) => (
          <Option key={index} value={item?.toLowerCase()}>
            {item}
          </Option>
        ))
      default:
        return null
    }
  }

  return (
    <div className={styles.campaignFilters}>
      <div className={styles.leftSide}>
        <span style={{ marginRight: '10px', fontWeight: 600 }}>Apply Filters:</span>
        <Select
          style={{ width: 160 }}
          placeholder='Select Filter Type'
          value={filterType}
          onChange={handleFilterTypeChange}>
          {filterItems.map((item, index) => (
            <Option key={index} value={item}>
              {item}
            </Option>
          ))}
        </Select>
        {filterType && (
          <>
            <Divider type='vertical' style={{ height: '24px', margin: '0 8px' }} />
            {filterType !== 'Show Archive' && (
              <Select
                mode='multiple'
                style={{ width: 400, marginRight: '10px' }}
                placeholder={`Select ${filterType}`}
                value={filterValue}
                maxTagCount={1}
                onChange={(value) => handleSelectChange(value)}
                filterOption={(input, option: any) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {renderFilterOptions()}
              </Select>
            )}
            {/* <Button type='primary' onClick={handleApplyFilters} disabled={!filterValue || filterValue?.length === 0}>
            Apply
          </Button> */}
            {/* <Button type='primary' onClick={handleClearFilters} disabled={Object.keys(campaign_filters).length === 0}>
            Clear All
          </Button> */}
            <span
              style={
                Object.keys(campaign_filters).length === 0
                  ? { marginLeft: '0px', fontWeight: 600, color: 'lightgray' }
                  : { marginLeft: '0px', fontWeight: 600, color: '#7043ff', cursor: 'pointer' }
              }
              onClick={handleClearFilters}>
              Clear All
            </span>
          </>
        )}
      </div>
      <div className={styles.rightSide}>
        {' '}
        <Space>
          <Select style={{ width: '95px' }} value={dateType} onChange={handleSetDateType}>
            <Option value='sent_at'>Sent</Option>
            <Option value='created_at'>Created</Option>
          </Select>
          <RangePicker presets={rangePresets} onChange={onRangeChange} />
          <CustomToggleSwitch onToggle={(checked) => dispatch(SET_SHOW_PERCENTAGE(checked))} />
          <Divider type='vertical' />
          <Button onClick={handleNewCampaignClick}>
            <div className={styles.newCampaignButton}>
              {/* <img src={plus_circle_icon} alt='' /> */}
              <PlusCircleOutlined style={{ marginRight: '5px' }} />
              <span>New Campaign</span>
            </div>
          </Button>
        </Space>
      </div>
      {openOutreachCampaignWithAIModal && (
        <OutreachCampaignWithAIModal
          open={openOutreachCampaignWithAIModal}
          onCancel={() => {
            setOpenOutreachCampaignWithAIModal(false)
          }}
          setOpenTemplatesWizardModal={setOpenTemplatesWizardModal}
        />
      )}
      <TemplatesWizardModal
        open={openTemplatesWizardModal}
        onCancel={() => {
          setOpenTemplatesWizardModal(false)
        }}
        onFinish={handleSelectTemplate}
      />
    </div>
  )
}

export default CampaignFilters
