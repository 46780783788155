import React, { useEffect, useState } from 'react'
import { Card } from 'antd'
import styles from './MessageSuggestions.module.scss'
import { useLazyGetMessageSuggestionsQuery } from 'features/Outreach/state/api/OutreachApi'
import LoadingBox from '../../../../../LoadingBox/LoadingBox'

interface MessageSuggestion {
  responseRate: number
  message: string
}

interface MessageSuggestionsProps {
  messages?: MessageSuggestion[]
  onAddToComposer: any
}

const MessageSuggestions: React.FC<MessageSuggestionsProps> = ({ onAddToComposer }) => {
  const [refetcGetMessageSuggestions, { data: messageSuggestions, isLoading }] = useLazyGetMessageSuggestionsQuery()
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null)

  const handleCardHover = (index: number) => {
    setExpandedIndex(index)
  }

  const handleCardLeave = () => {
    setExpandedIndex(null)
  }

  useEffect(() => {
    const getMessageSuggestions = async () => {
      await refetcGetMessageSuggestions()
    }
    getMessageSuggestions()
  }, [])

  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        messageSuggestions?.response?.map((message: any, index: any) => (
          <Card
            key={index}
            hoverable
            style={{
              backgroundColor: '#F2F0FF',
              margin: '5px 0px',
              padding: '0px !important',
              overflow: 'hidden',
            }}
            bodyStyle={{
              padding: '15px',
              maxHeight: expandedIndex === index ? 'none' : '150px',
              transition: 'max-height 1s !important',
            }}
            onMouseEnter={() => handleCardHover(index)}
            onMouseLeave={handleCardLeave}>
            <div className={styles.container}>
              <span>+{message.response_rate}% Response Rate</span>
              <p>{expandedIndex === index ? message.message : message.message.slice(0, 100)}</p>
              <div className={styles.rightSide}>
                <span onClick={() => onAddToComposer(message.message)}>Add to Composer</span>
              </div>
            </div>
          </Card>
        ))
      )}
    </>
  )
}

export default MessageSuggestions
