import React, { useState, useEffect } from 'react'
import { Layout, Menu } from 'antd'
import type { MenuProps } from 'antd'

const { Sider } = Layout

const siderStyle: React.CSSProperties = {
  borderRight: '1px solid #dfe1e6',
  background: 'white',
}

interface LeadsSidebarProps {
  categories: string[]
  selectedCategory: string
  onCategoryChange: (category: string) => void
}

const LeadsSidebar: React.FC<LeadsSidebarProps> = ({ categories = [], selectedCategory, onCategoryChange }) => {
  const [selectedKey, setSelectedKey] = useState<string>(selectedCategory)

  const displayedCategories = ['My Groups', 'Raw Leads', 'Matched Leads', 'All Leads']

  const handleExploreAll = (e: any) => {
    setSelectedKey(e)
    onCategoryChange(e)
  }
  const onClick: MenuProps['onClick'] = (e: any) => {
    handleExploreAll(e.key)
  }

  useEffect(() => {
    setSelectedKey(selectedCategory)
  }, [selectedCategory])

  const items: MenuProps['items'] = ['Categories'].map((text, index) => {
    const key = String(index + 1)
    return {
      key: `sub${key}`,
      label: <span style={{ fontWeight: 600 }}>{text}</span>,
      children: displayedCategories.map((cat, j) => {
        return {
          key: cat,
          label: <span>{cat}</span>,
        }
      }),
    }
  })
  return (
    <Sider style={siderStyle} width={240}>
      <Menu
        defaultOpenKeys={['sub1']}
        className='LeadsSidebar'
        mode='inline'
        style={{ height: '100%', borderRight: 0, maxHeight: '680px', overflowY: 'auto', backgroundColor: '#fff' }}
        items={items}
        onClick={onClick}
        selectedKeys={[selectedKey]}
      />
    </Sider>
  )
}

export default LeadsSidebar
