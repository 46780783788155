import { Divider } from 'antd'
import styles from './styles.module.scss'
import person_group_icon from 'common/assets/svg/person_group_icon.svg'
// import wifi_icon from 'common/assets/svg/wifi_icon.svg'

import InfoCard from './InfoCard'
interface ConnectionInfoBoxProps {
  portalID: number
  isLoadingPortalId?: boolean
}
const ConnectionInfoBox: React.FC<ConnectionInfoBoxProps> = ({ portalID, isLoadingPortalId }) => {
  return (
    <div className={styles.section}>
      <label className={styles.nameSection}>Connection information</label>
      <Divider />
      <div className={styles.body}>
        <div className={styles.information}>
          <InfoCard src={person_group_icon} title='Team portal ID' subTitle={portalID} isLoading={isLoadingPortalId} />
          {/* <InfoCard src={wifi_icon} title='Connected as' subTitle={connectionInfo} /> */}
        </div>
      </div>
    </div>
  )
}

export default ConnectionInfoBox
