import React from 'react'
import styles from './ActiveAccounts.module.scss'
import facebook_icon from 'common/assets/svg/logo_facebook.svg'
import telegram_icon from 'common/assets/svg/logo_telegram.svg'
import linkedin_icon from 'common/assets/svg/logo_linkedin.svg'
import instagram_icon from 'common/assets/svg/logo_instagram.svg'
import discord_icon from 'common/assets/svg/discord_logo.svg'
import twitter_icon from 'common/assets/svg/twitter_logo.svg'
import { Tooltip } from 'antd'

interface ActiveAccountsProps {
  activePlatforms: { [key: string]: boolean }
}

const ActiveAccounts: React.FC<ActiveAccountsProps> = ({ activePlatforms }) => {
  const platforms = [
    { key: 'facebook', label: 'Facebook', icon: facebook_icon },
    { key: 'instagram', label: 'Instagram', icon: instagram_icon },
    { key: 'telegram', label: 'Telegram', icon: telegram_icon },
    { key: 'twitter', label: 'Twitter', icon: twitter_icon },
    { key: 'linkedin', label: 'LinkedIn', icon: linkedin_icon },
    { key: 'discord', label: 'Discord', icon: discord_icon },
  ]

  return (
    <>
      <div className={styles.container}>
        <Tooltip title='Coming Soon'>
          <div className={styles.comingSoonOverlay}></div>
        </Tooltip>
        {platforms.map((platform) => (
          <div
            key={platform.key}
            className={`${styles.icon} ${activePlatforms[platform.key] ? styles.active : styles.inactive}`}>
            <img src={platform.icon} alt={platform.label} />
          </div>
        ))}
      </div>
    </>
  )
}

export default ActiveAccounts
