import React from 'react'
import { Card, Divider, Skeleton, Statistic, Tooltip } from 'antd'
import styles from './SequencesPerformance.module.scss'
import increase_icon from 'common/assets/svg/increase_icon.svg'
import decrease_icon from 'common/assets/svg/decrease_icon.svg'
import { useAppSelector } from 'state'

interface SequencesPerformanceProps {
  total_leads: any
  total_sent: any
  total_replied: any
  total_blocked: any
  change_in_total_leads?: any
  change_in_total_sent_leads?: any
  change_in_total_replied_leads?: any
  change_in_total_blocked?: any
  loading: boolean
}

const SequencesPerformance: React.FC<SequencesPerformanceProps> = ({
  total_leads,
  total_sent,
  total_replied,
  total_blocked,
  change_in_total_leads,
  change_in_total_sent_leads,
  change_in_total_replied_leads,
  change_in_total_blocked,
  loading,
}) => {
  const { campaign_date_filters, showPercentage } = useAppSelector((state) => state.outreachCampaign)
  const { from, to } = campaign_date_filters || {}

  const getDateDifferenceInDays = (start: Date, end: Date) => {
    const diffInTime = end.getTime() - start.getTime()
    return Math.ceil(diffInTime / (1000 * 3600 * 24))
  }

  let dateRangeText = 'Last 7 days'
  if (from && to) {
    const fromDate = new Date(from)
    const toDate = new Date(to)
    const dateDifference = getDateDifferenceInDays(fromDate, toDate)
    dateRangeText = `Prev ${dateDifference} days`
  }

  const getChangeStyle = (changeValue: number) => {
    // eslint-disable-next-line
    if (changeValue == 0) {
      return { icon: undefined, style: styles.noChange }
    }
    return changeValue > 0
      ? { icon: increase_icon, style: styles.increase }
      : { icon: decrease_icon, style: styles.decrease }
  }

  return (
    <Card className={`campaignStatsCard ${styles.card}`}>
      <div className={styles.cardBody}>
        <h3>Sequences Performance</h3>
        <div className={styles.statistics}>
          <div className={styles.stats}>
            <Statistic className='leadsInsightStatistics' title='Total Leads' value={total_leads} loading={loading} />
            {loading ? (
              <Skeleton.Avatar shape='square' active={true} size={16} style={{ width: '40px' }} />
            ) : (
              <div className={getChangeStyle(change_in_total_leads).style}>
                <img src={getChangeStyle(change_in_total_leads).icon} alt='' />
                <span>
                  {Math.abs(change_in_total_leads)}
                  {showPercentage ? '%' : ''}
                </span>
                <Tooltip title={dateRangeText}>
                  <span className={styles.time}>{dateRangeText}</span>
                </Tooltip>
              </div>
            )}
          </div>
          <Divider className='leadsInsightsDivider' type='vertical' />
          <div className={styles.stats}>
            <Statistic
              className='leadsInsightStatistics'
              title='Sent Leads'
              value={total_sent}
              loading={loading}
              suffix={showPercentage ? '%' : false}
            />
            {loading ? (
              <Skeleton.Avatar shape='square' active={true} size={16} style={{ width: '40px' }} />
            ) : (
              <div className={getChangeStyle(change_in_total_sent_leads).style}>
                <img src={getChangeStyle(change_in_total_sent_leads).icon} alt='' />
                <span>
                  {Math.abs(change_in_total_sent_leads)}
                  {showPercentage ? '%' : ''}
                </span>
                <Tooltip title={dateRangeText}>
                  <span className={styles.time}>{dateRangeText}</span>
                </Tooltip>
              </div>
            )}
          </div>
          <Divider className='leadsInsightsDivider' type='vertical' />
          <div className={styles.stats}>
            <Statistic
              className='leadsInsightStatistics'
              title='Replied Leads'
              value={total_replied}
              loading={loading}
              suffix={showPercentage ? '%' : false}
            />
            {loading ? (
              <Skeleton.Avatar shape='square' active={true} size={16} style={{ width: '40px' }} />
            ) : (
              <div className={getChangeStyle(change_in_total_replied_leads).style}>
                <img src={getChangeStyle(change_in_total_replied_leads).icon} alt='' />
                <span>
                  {Math.abs(change_in_total_replied_leads)}
                  {showPercentage ? '%' : ''}
                </span>
                <Tooltip title={dateRangeText}>
                  <span className={styles.time}>{dateRangeText}</span>
                </Tooltip>
              </div>
            )}
          </div>
          <Divider className='leadsInsightsDivider' type='vertical' />
          <div className={styles.stats}>
            <Statistic
              className='leadsInsightStatistics'
              title='Blocked Leads'
              value={total_blocked}
              loading={loading}
              suffix={showPercentage ? '%' : false}
            />
            {loading ? (
              <Skeleton.Avatar shape='square' active={true} size={16} style={{ width: '40px' }} />
            ) : (
              <div className={getChangeStyle(change_in_total_blocked).style}>
                <img src={getChangeStyle(change_in_total_blocked).icon} alt='' />
                <span>
                  {Math.abs(change_in_total_blocked)}
                  {showPercentage ? '%' : ''}
                </span>{' '}
                <Tooltip title={dateRangeText}>
                  <span className={styles.time}>{dateRangeText}</span>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default SequencesPerformance
