import React, { useState } from 'react'

import styles from './read-more-less.module.scss'

interface IReadMoreLess {
  text: string
}
const ReadMoreLess: React.FC<IReadMoreLess> = ({ text }) => {
  const [showFullDescription, setFullDescription] = useState(false)

  return (
    <div className='col-md-7'>
      <p className={styles.content}>
        {text && (showFullDescription ? text : text.slice(0, 115))}
        <span
          onClick={() => {
            setFullDescription(!showFullDescription)
          }}>
          ... {showFullDescription ? 'Show less' : 'Show more'}
        </span>
      </p>
    </div>
  )
}

export default ReadMoreLess
