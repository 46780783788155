import React, { useEffect, useState } from 'react'
import { Button, Table } from 'antd'
import { ReactComponent as FacebookIcon } from 'common/assets/svg/logo_facebook.svg'
import { ReactComponent as TelegramIcon } from 'common/assets/svg/logo_telegram.svg'
import { ReactComponent as LinkedInIcon } from 'common/assets/svg/logo_linkedin.svg'
import { ReactComponent as InstagramIcon } from 'common/assets/svg/logo_instagram.svg'
import { ReactComponent as DiscordIcon } from 'common/assets/svg/discord_logo.svg'
import { ReactComponent as TwitterIcon } from 'common/assets/svg/twitter_logo.svg'
import getColumns from './SocialAccountTableColumns'
import { capitalize } from 'lodash'
import styles from './SocialAccountTable.module.scss'
import EditAccountDrawer from './EditAccountDrawer'
import { useLazyGetTelegramAccountInfoQuery } from 'features/settings/state/api/SettingsApi'

interface SocialAccount {
  profilePhoto?: string
  userName?: string
  fullName?: string
  phoneNumber?: string
  bio?: string
  outreachMethod?: string
  status?: string
  photos?: string[]
}

interface SocialAccountTableProps {
  data: SocialAccount[]
  platform: string
  onAddAccount: () => void
  setOpenQrcodPage?: any
  onEditAccount: (e: any) => void
  handleOutreachEdit?: any
}

const getPlatformIcon = (platform: string) => {
  switch (platform) {
    case 'telegram':
      return <TelegramIcon className={styles.platformIcon} />
    case 'instagram':
      return <InstagramIcon className={styles.platformIcon} />
    case 'facebook':
      return <FacebookIcon className={styles.platformIcon} />
    case 'twitter':
      return <TwitterIcon className={styles.platformIcon} />
    case 'linkedin':
      return <LinkedInIcon className={styles.platformIcon} />
    case 'discord':
      return <DiscordIcon className={styles.platformIcon} />
    default:
      return null
  }
}

const SocialAccountTable: React.FC<SocialAccountTableProps> = ({
  data,
  platform,
  onAddAccount,
  setOpenQrcodPage,
  onEditAccount,
  handleOutreachEdit,
}) => {
  const [getTelegramAccountInfo] = useLazyGetTelegramAccountInfoQuery()
  const [isLoadingAccount, setIsLoadingAccount] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState({})
  const [openDrawer, setOpenDrawer] = useState(false)
  const [pageSize, setPageSize] = useState(7)

  const handleEditAccount = async (accountData: any) => {
    setIsLoadingAccount(true)
    const telegram_number = `${accountData?.area_code?.replace('+', '')}${accountData?.number}`
    const { data } = await getTelegramAccountInfo({ telegram_number })
    setSelectedAccount({ ...accountData, ...data })
    setIsLoadingAccount(false)
    setOpenDrawer(true)
  }

  const columns = getColumns(
    platform,
    setOpenDrawer,
    setPhoneNumber,
    handleEditAccount,
    setOpenQrcodPage,
    handleOutreachEdit,
    isLoadingAccount,
  )

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1600) {
        setPageSize(6)
      } else {
        setPageSize(8)
      }
    }

    handleResize() // Set initial page size
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [window.innerHeight, window.innerWidth])

  return (
    <div className={styles.tableContainer}>
      <div className={styles.header}>
        <div className={styles.leftSide}>
          {getPlatformIcon(platform)}
          <h2>{capitalize(platform)} Accounts</h2>
        </div>
        <div className={styles.rightSide}>
          <Button type='primary' onClick={onAddAccount}>
            Add Account
          </Button>
        </div>
      </div>
      <Table
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={[...data]}
        rowKey='userName'
        pagination={{ pageSize: pageSize }}
      />
      {openDrawer && (
        <EditAccountDrawer
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false)
          }}
          data={selectedAccount}
          phoneNumber={phoneNumber}
        />
      )}
    </div>
  )
}

export default SocialAccountTable
