import React, { useState, useEffect } from 'react'
import {
  Tag,
  Select,
  Button,
  Input,
  Layout,
  Spin,
  Avatar,
  message,
  Tooltip,
  Dropdown,
  MenuProps,
  Divider,
  Image,
  Upload,
} from 'antd'
import { Content } from 'antd/es/layout/layout'
import { LoadingOutlined, PaperClipOutlined } from '@ant-design/icons'
import Sider from 'antd/es/layout/Sider'
import styles from './MessageComponent.module.scss'
import bookmark from 'common/assets/svg/bookmark.svg'
import bookmark_black from 'common/assets/svg/bookmark_black.svg'
import bookmark_chat from 'common/assets/svg/bookmark_chat.svg'
import bookmark_chat_after from 'common/assets/svg/bookmark_chat_after.svg'
import double_tick from 'common/assets/svg/double_tick.svg'
import bookmark_blue from 'common/assets/svg/bookmark_blue.svg'
// import telegram from 'common/assets/svg/tele_icon.svg'
// import facebook from 'common/assets/svg/facebook_icon.svg'
// import instagram from 'common/assets/svg/instagram_icon.svg'
// import linkedin from 'common/assets/svg/linked_icon.svg'
// import discord from 'common/assets/svg/disc_icon.svg'
// import twitter from 'common/assets/svg/xtwit_icon.svg'
import pin_icon from 'common/assets/svg/pin_chat.svg'
import pinned_chat from 'common/assets/svg/pinned_chat.svg'
import menuIcon from 'common/assets/svg/menu_icon.svg'
import accountIcon from 'common/assets/svg/account_icon.svg'
import settingIcon from 'common/assets/svg/setting_icon.svg'
import emojiPickerIcon from 'common/assets/svg/emoji_picker.svg'
import Linkify from 'linkify-react'
import { SendOutlined, CloseOutlined, SearchOutlined, UserAddOutlined } from '@ant-design/icons'
import {
  useLazyChangeMetadataQuery,
  useLazyGetBookmarkQuery,
  useLazyGetDialogsQuery,
  useLazyGetMessagesQuery,
  useLazySendChatMessageQuery,
  useLazySetBookmarkQuery,
  useSaveConvrtObjectsMapMutation,
  useDeleteHubspotSyncMutation,
  useSaveHubspotUnifiedInboxMutation,
  useLazyGetDialogByIdQuery,
} from './state/api/Chat-API'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_CLOSE_LEFT_SIDEBAR, SET_SELECTED_CHAT_RECIPIENT } from './state/chatSlice'
import ProfileSider from './ProfileSider'
import { post } from 'common/api/axios'
import TagItem from './Tag/TagItem'
import TagDialog from './Tag'
import useClickOutside from './Tag/Hook/useClickOutside'
import ImageWithFallback from '../General/ImageWithFallback'
import CustomnMeetingBook from './UI/CustomMeetingBook'
import { HubspotSyncSider } from './HubspotSyncSider'
// import SendMessage from 'features/Outreach/components/SendMessage'
import Picker from 'emoji-picker-react'
import { FancyImage } from 'features/contacts/components/FancyImage/FancyImage'

interface Props {
  channelId: string | null // Adjusted type definition to accept null
  channelMessages: {
    id: string
    text: string
    sender: string
    receiver: string
    timestamp: string
  }[] // Define the type for messages
  // onSendMessage: (newMessage: { text: string sender: string receiver: string }) => void // Callback function to send a new message
}

const MessageComponent: React.FC<Props> = ({ channelId, channelMessages }) => {
  // API Calls
  const dispatch = useAppDispatch()
  const [getDialogs, { data: dialogsData }] = useLazyGetDialogsQuery()
  const [getDialogId, { data: dialogData }] = useLazyGetDialogByIdQuery()
  const [sendChatMessage] = useLazySendChatMessageQuery()
  const [getMessages, { data: messagesData }] = useLazyGetMessagesQuery()
  const [changeMetadata] = useLazyChangeMetadataQuery()
  const [getBookmarks, { data: bookmarksData }] = useLazyGetBookmarkQuery()
  const [setBookmark] = useLazySetBookmarkQuery()
  const [saveHubspotContact] = useSaveConvrtObjectsMapMutation()
  const [deleteHubspotSync] = useDeleteHubspotSyncMutation()
  const [saveHubspotUnified] = useSaveHubspotUnifiedInboxMutation()
  // useApp
  const { selected_chat_recipient, is_unify_finished, close_left_sidebar } = useAppSelector((state) => state.chat)
  const [additionalTags, setAdditionalTags] = useState<string[]>(JSON.parse(selected_chat_recipient?.tags || '[]'))
  const { current_tags_dialog } = useAppSelector((state) => state.chat)
  const filteredTagsDialog = current_tags_dialog?.find((record: any) => record.id === selected_chat_recipient.id)
  const tagsOfDialog = filteredTagsDialog?.data
  // useStates
  const [loadingMessages, setLoadingMessages] = useState(false)
  const [updateMessage, setUpdateMessage] = useState(messagesData || [])
  const [openProfileSider, setOpenProfileSider] = useState(false)
  const [openAllTagDetail, setOpenAllTagDetail] = useState(false)
  const [openHubSpotSync, setOpenHubSpotSync] = useState(false)

  // eslint-disable-next-line
  const [chatImg, setChatImg] = useState(bookmark_chat)
  const [nameImg, setNameImg] = useState(bookmark)
  const [pin, setPin] = useState(pin_icon)
  const [defaultPriority, setDefaultPriority] = useState(selected_chat_recipient?.priority)
  const [defaultMeetingBook, setDefaultMeetingBook] = useState(selected_chat_recipient?.meeting_book)
  const [bookmarkCollapsed, setBookmarkCollapsed] = useState(true)
  const [selectedMessage, setSelectedMessage] = useState('')
  const [filteredMessages, setFilteredMessages] = useState<string[]>([])
  // eslint-disable-next-line
  const [chatImgStates, setChatImgStates] = useState<{ [key: string]: string }>({})
  const [loading, setLoading] = useState(false)
  const [showPicker, setShowPicker] = useState(false)
  const [file, setFile] = useState<any>(null)
  // useRefs
  const messageEndRef = React.useRef<any>(null)
  const tagDetailRef = React.useRef<any>(null)
  const pickerRef = React.useRef<any>(null)
  useClickOutside(pickerRef, () => setShowPicker(false))

  // Functions & Methods

  const handleCloseHubspotSync = () => {
    setOpenHubSpotSync(false)
  }

  const handleSyncHubspotContacts = async (value: boolean) => {
    if (value) {
      return saveHubspotUnified({
        dialog_id: selected_chat_recipient?.id,
        is_sync: true,
        sender_id: selected_chat_recipient?.receiver_number,
      }).then((rs: any) => {
        if (rs.err) {
          message.error('Some thing went wrong. Save Failed!')
          return
        }
      })
    } else {
      return deleteHubspotSync({
        dialog_id: selected_chat_recipient?.id,
      }).then((rs: any) => {
        if (rs.err) {
          message.error('Some thing went wrong. Please try again')
          return
        }
        message.success('Save successfully.')
      })
    }
  }

  const handleSelectHubspotContact = async (id: string) => {
    return saveHubspotContact({
      dialog_id: selected_chat_recipient?.id,
      contact_id: id,
      hubspot_type: 'CONTACT',
      convrt_item_type: 'telegram_dialog',
    }).then((rs: any) => {
      if (rs.err) {
        message.error('Some thing went wrong. Please try again')
        return
      }
      message.success('Save successfully.')
    })
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        e.preventDefault()
        setSelectedMessage((prevText) => prevText + '\n')
      } else {
        e.preventDefault()
        sendMessage()
      }
    }
  }

  const handleInputChange = (event: any) => {
    setSelectedMessage(event.target.value)
  }

  const handleChangePriority = async (priority: string) => {
    await changeMetadata({
      platform: selected_chat_recipient.platform,
      dialog_id: selected_chat_recipient.id,
      senderData: selected_chat_recipient.senderData,
      convrt_index: selected_chat_recipient.convrt_index,
      metadata: { priority: priority },
    })
    setDefaultPriority(priority)
    await getDialogs()
    post('/log', {
      action: `Unified Inbox: Priority Changed to: ${priority}`,
      data: {
        id: selected_chat_recipient.id,
        platform: selected_chat_recipient.platform,
        senderData: selected_chat_recipient.senderData,
      },
    })
  }
  const handleChangeMeetingBook = async (meeting_book: string) => {
    await changeMetadata({
      platform: selected_chat_recipient.platform,
      dialog_id: selected_chat_recipient.id,
      senderData: selected_chat_recipient.senderData,
      convrt_index: selected_chat_recipient.convrt_index,
      metadata: { meeting_book: meeting_book },
    })
    setDefaultMeetingBook(meeting_book)
    await getDialogs()
    post('/log', {
      action: `Unified Inbox: Meeting Book Changed to: ${meeting_book}`,
      data: {
        id: selected_chat_recipient.id,
        platform: selected_chat_recipient.platform,
        senderData: selected_chat_recipient.senderData,
      },
    })
  }

  const handlePinChatClick = async (check: boolean) => {
    await changeMetadata({
      platform: selected_chat_recipient.platform,
      dialog_id: selected_chat_recipient.id,
      senderData: selected_chat_recipient.senderData,
      username: selected_chat_recipient.username,
      metadata: { pin: check },
    })
    dispatch(SET_SELECTED_CHAT_RECIPIENT({ ...selected_chat_recipient, pin: check }))
    await getDialogs()
    if (check) {
      post('/log', {
        action: `Unified Inbox: Chat ${check ? 'Pinned' : 'Unpinned'}`,
        data: {
          id: selected_chat_recipient.id,
          platform: selected_chat_recipient.platform,
          senderData: selected_chat_recipient.senderData,
        },
      })
    }
  }
  const convertFileToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }
  const fetchAfterSend = async () => {
    const { data } = await getMessages({
      dialogId: selected_chat_recipient?.id,
      senderData: selected_chat_recipient?.senderData,
    })
    if (data) {
      setUpdateMessage(data)
    }
  }
  const sendMessage = async () => {
    if ((selectedMessage.trim() !== '' || file) && !loading) {
      try {
        const lastMessage = updateMessage[updateMessage.length - 1]
        const lastMessageId = parseInt(updateMessage[updateMessage.length - 1].id)

        // Generate the new ID for the next message
        const newMessageId = (lastMessageId + 1).toString()
        let b64Data = null
        if (file) {
          b64Data = await convertFileToBase64(file)
        }
        const newMessage = {
          id: newMessageId,
          dialog_id: lastMessage?.dialog_id,
          message: selectedMessage.trimEnd(),
          // sender_id: senders[0],
          // sender_username: '',
          sent_at: new Date().toISOString(),
          receiver_number: lastMessage.receiver_number,
          outgoing: true,
        }
        const sendChatMessageData = {
          dialog_id: lastMessage?.dialog_id,
          message: selectedMessage.trimEnd(),
          senderData: selected_chat_recipient.senderData,
          platform: selected_chat_recipient.platform,
          file: file
            ? {
                data: b64Data,
                name: file.name,
              }
            : null,
        }
        setLoading(true)
        const data = await sendChatMessage(sendChatMessageData)

        if (data?.error?.status) {
          console.log('data error status', data?.error?.status)
          if (data?.error?.status === 'PARSING_ERROR') {
            message.error('File size is too large. Please try again with a smaller file.')
          } else {
            message.error('An error occured. Please try again later.')
          }
          console.error('Error sending a message. ', data.error)
          setLoading(false)
          return
        }

        post('/log', {
          action: `Unified Inbox: User Sent a Message'}`,
          data: newMessage,
        })

        await getDialogs()

        const updatedMessages = [...updateMessage, newMessage]
        if (b64Data) {
          await fetchAfterSend()
        } else {
          setUpdateMessage(updatedMessages)
        }
        setSelectedMessage('')
        setFile(null)
        setLoading(false)
      } catch (err) {
        message.error('An error occured. Please try again later.')
        console.error('Error sending a message. ', err)
      }
    }
  }

  // const handleKeyPress = (event: any) => {
  //   if (event.key === 'Enter') {
  //     event.preventDefault()
  //     sendMessage()
  //   }
  // }

  const changeBookmark = () => {
    let value = nameImg

    if (value === bookmark) {
      setNameImg(bookmark_blue)
    } else {
      setNameImg(bookmark)
    }
    setBookmarkCollapsed(!bookmarkCollapsed)

    // post('/log', {
    //   action: `Unified Inbox: User ${bookmarkCollapsed ? 'Opened' : 'Closed'} Bookmarks'}`,
    // })
  }

  const suffix = <img src={nameImg} style={{ cursor: 'pointer' }} onClick={changeBookmark} alt='' />

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filtered = filteredMessages.filter((msg) => msg.toLowerCase().includes(e.target.value.toLowerCase()))
    setFilteredMessages(filtered.map((msg) => msg))
  }

  const closeBookmark = () => {
    setBookmarkCollapsed(true)
    setNameImg(bookmark)
  }

  const changeChatBookmark = async (messageText: string) => {
    // Check if the message text is already in the filteredMessages
    if (!filteredMessages.includes(messageText)) {
      // If not present, add it to the filteredMessages array
      const updatedFilteredMessages = [...filteredMessages, messageText]
      // Update the state with the new array
      setFilteredMessages(updatedFilteredMessages)
    }

    // Update the state for the clicked message text
    setChatImgStates((prevState) => {
      if (!bookmarksData.includes(messageText)) {
        post('/log', {
          action: `Unified Inbox: User ${bookmarksData.includes(messageText) ? 'Deleted' : 'Added'} a Bookmark`,
          data: { bookmark: messageText },
        })
      }
      return {
        ...prevState,
        [messageText]: prevState[messageText] === bookmark_chat ? bookmark_chat_after : bookmark_chat,
      }
    })

    await setBookmark({ platform: 'telegram', message: messageText })
    await getBookmarks()
  }

  const handleDeleteBookmark = async (messageText: string) => {
    await setBookmark({ platform: 'telegram', message: messageText })
    await getBookmarks()
    // post('/log', {
    //   action: `Unified Inbox: User Deleted a Bookmark`,
    //   data: { bookmark: messageText },
    // })
  }

  const changePin = () => {
    if (!selected_chat_recipient.pin) {
      setPin(pinned_chat)
      handlePinChatClick(true)
    } else {
      setPin(pin_icon)
      handlePinChatClick(false)
    }
  }

  const valuepass = (value: any) => {
    setSelectedMessage(value)
    setBookmarkCollapsed(true)
    setNameImg(bookmark)

    post('/log', {
      action: `Unified Inbox: User Used a Bookmark`,
      data: { bookmark: value },
    })
  }

  const getInitials = (name: string) => {
    const names = name.split(' ')
    const initials = names.map((part) => part[0]).join('')
    return initials
  }

  const getColor = (initials: string) => {
    const colors = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae', '#f44336', '#009688']
    const index = initials.charCodeAt(0) % colors.length
    return colors[index]
  }

  const getColorByUsername = (username: string) => {
    const colors = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae', '#f44336', '#009688']
    const cleanedName = username.replace(/\s+/g, '').toUpperCase()
    let hash = 0
    for (let i = 0; i < cleanedName.length; i++) {
      hash = cleanedName.charCodeAt(i) + ((hash << 5) - hash)
    }
    const index = Math.abs(hash) % colors.length
    return colors[index]
  }

  const initials = selected_chat_recipient?.title ? getInitials(selected_chat_recipient?.title) : null

  const userImage = (size: number) => {
    return selected_chat_recipient?.photo ? (
      <ImageWithFallback
        className={styles.profilePhoto}
        style={{ height: `${size}px`, width: `${size}px`, marginRight: '15px', cursor: 'pointer' }}
        src={selected_chat_recipient?.photo}
        fallbackStrOrElement={
          <Avatar
            size={size}
            className={styles.avatar}
            style={{ minWidth: `${size}px`, backgroundColor: getColor(initials || ''), cursor: 'pointer' }}>
            {initials}
          </Avatar>
        }
      />
    ) : initials ? (
      <Avatar
        size={size}
        className={styles.avatar}
        style={{ minWidth: `${size}px`, backgroundColor: getColor(initials), cursor: 'pointer' }}>
        {initials}
      </Avatar>
    ) : (
      <Avatar size={size} style={{ minWidth: `${size}px` }} icon={<UserAddOutlined />} />
    )
  }

  const formatDate = (inputDate: string) => {
    const now = new Date()
    const sentDate = new Date(inputDate)
    const dateDiff = now.getTime() - sentDate.getTime()
    const seconds = Math.round(dateDiff / 1000)
    const minutes = Math.round(seconds / 60)
    const hours = Math.round(minutes / 60)
    const days = Math.round(hours / 24)

    const formatTime = (date: Date) => date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

    if (seconds < 60) {
      return 'A few seconds ago'
    } else if (minutes < 60) {
      return `${minutes} minute${minutes === 1 ? '' : 's'} ago`
    } else if (hours < 24) {
      return `${hours} hour${hours === 1 ? '' : 's'} ago`
    } else if (hours < 48) {
      // Yesterday
      return `Yesterday at ${formatTime(sentDate)}`
    } else if (days < 3) {
      // 2 days ago
      return `${days} days ago at ${formatTime(sentDate)}`
    } else {
      // Formatting date in MM/DD/YYYY, HH:MM format
      return sentDate.toLocaleDateString() + ' at ' + formatTime(sentDate)
    }
  }

  const renderContact = (media: any, left = false) => {
    const contact = JSON.parse(media)
    const fullName = `${contact.first_name} ${contact.last_name}`
    return (
      <div style={{ display: 'flex', gap: 7 }}>
        <FancyImage
          className='unified_contact_image'
          name={contact.first_name}
          size={48}
          url=''
          style={{
            backgroundColor: 'white !important',
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: left ? 7 : 3,
            alignItems: 'flex-start',
            fontSize: '16px',
            fontWeight: 'bold',
          }}>
          <span>{fullName}</span>
          <span
            onClick={() => {
              navigator.clipboard.writeText(contact.phone_number)
              message.success('Phone number copied to clipboard')
            }}
            style={{ fontSize: '14px', cursor: 'pointer', fontWeight: '400' }}>
            {contact.phone_number}
          </span>
        </div>
      </div>
    )
  }
  const renderReplyTo = (replyTo: any) => {
    try {
      const parsed = JSON.parse(replyTo)
      const { user, message } = parsed
      return (
        <div
          style={{
            width: '100%',
            padding: '8px 12px',
            backgroundColor: '#f1f1f1',
            borderLeft: '3px solid var(--color-blue-03)',
            borderRadius: '6px',
            marginBottom: '10px',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <div
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              color: 'var(--color-blue-03)',
              marginBottom: '4px',
            }}>
            {user}
          </div>

          <Tooltip title={message}>
            <div
              style={{
                fontSize: '14px',
                color: '#333',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100px',
              }}>
              {message}
            </div>
          </Tooltip>
        </div>
      )
    } catch (e) {
      return <></>
    }
  }

  // useEffects
  const getChatdata = async () => {
    await getDialogs()
  }

  useEffect(() => {
    if (is_unify_finished) {
      getChatdata()
    }
  }, [is_unify_finished])

  useEffect(() => {
    const getMessagesData = async () => {
      setLoadingMessages(true)
      const { data } = await getMessages({
        dialogId: selected_chat_recipient?.id,
        senderData: selected_chat_recipient.senderData,
      })
      if (data) {
        setUpdateMessage(data)
      }
      setLoadingMessages(false)
    }
    if (is_unify_finished && selected_chat_recipient?.id && selected_chat_recipient.senderData) {
      getMessagesData()
    }
    if (selected_chat_recipient?.id) {
      getDialogId({ dialogId: selected_chat_recipient.id })
    }
    setAdditionalTags(JSON.parse(selected_chat_recipient?.tags || '[]'))
  }, [selected_chat_recipient, is_unify_finished])

  useEffect(() => {
    if (selected_chat_recipient?.id && dialogData?.campaign_name && !additionalTags.length) {
      setAdditionalTags([`Campaign: ${dialogData.campaign_name}`])
    }
  }, [dialogData])
  // useEffect(() => {
  //   const getMessagesData = async () => {
  //     setLoadingMessages(true)
  //     const { data } = await getMessages({ dialogId: selected_chat_recipient.id })
  //     setUpdateMessage(data)
  //     setLoadingMessages(false)
  //   }

  //   let intervalId: any

  //   if (is_unify_finished) {
  //     getMessagesData()

  //     intervalId = setInterval(() => {
  //       getMessagesData()
  //     }, 50040)
  //   }

  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId)
  //     }
  //   }
  // }, [selected_chat_recipient, is_unify_finished, getMessages])

  async function fetchMessages() {
    if (!is_unify_finished || !selected_chat_recipient?.id) {
      return
    }

    try {
      const { data } = await getMessages({
        dialogId: selected_chat_recipient?.id,
        senderData: selected_chat_recipient?.senderData,
      })
      getChatdata()
      if (data) {
        // Assume messages are sorted latest last
        const lastFetchedMessage = data[data.length - 1]
        const lastDisplayedMessage = updateMessage[updateMessage.length - 1]
        if (lastFetchedMessage?.id !== lastDisplayedMessage?.id) {
          setUpdateMessage(data)
        }
      }
    } catch (error) {
      console.error('Failed to fetch messages:', error)
    }
  }
  useEffect(() => {
    let intervalId: any

    if (is_unify_finished) {
      intervalId = setInterval(fetchMessages, 5000)
    }

    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [selected_chat_recipient, is_unify_finished])

  useEffect(() => {
    setPin(selected_chat_recipient.pin ? pinned_chat : pin_icon)
    setDefaultPriority(selected_chat_recipient.priority)
    setDefaultMeetingBook(selected_chat_recipient.meeting_book)
    setOpenAllTagDetail(false)
  }, [selected_chat_recipient])

  useEffect(() => {
    const getAllBookmarks = async () => {
      await getBookmarks()
    }
    if (is_unify_finished) {
      getAllBookmarks()
    }
  }, [is_unify_finished])

  useEffect(() => {
    if (bookmarksData?.length > 0) {
      for (const message of bookmarksData) {
        setChatImgStates((prevState) => ({
          ...prevState,
          [message]: bookmark_chat_after,
        }))
      }
    }
  }, [bookmarksData])

  const handleOpenSideBar = () => {
    setOpenProfileSider(!openProfileSider)
    dispatch(SET_CLOSE_LEFT_SIDEBAR(!openProfileSider))
  }

  useClickOutside(tagDetailRef, () => setOpenAllTagDetail(false))

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className={styles.enrichDropdown__item} onClick={changePin}>
          <span>Pin Chat</span>
        </div>
      ),
      icon: <img style={{ marginLeft: '5px', marginRight: '12px' }} src={pin} alt='pin' />,
    },
    {
      key: '2',
      label: (
        <div className={styles.enrichDropdown__item} onClick={handleOpenSideBar}>
          <span>View Client Profile</span>
        </div>
      ),
      icon: <img src={accountIcon} width='26px' height={'26px'} alt='accountIcon' />,
    },
    {
      key: '3',
      label: (
        <div className={styles.enrichDropdown__item} onClick={() => setOpenHubSpotSync(true)}>
          <span>Manage sync</span>
        </div>
      ),
      icon: <img src={settingIcon} width='26px' height={'26px'} alt='accountIcon' />,
    },
  ]

  const numberToName = () => {
    const outreach = dialogsData?.telegram?.accounts
    const { receiver_number } = selected_chat_recipient

    if (receiver_number && outreach?.length > 0) {
      const contact = outreach.find((contact: any) => `${contact.area_code}${contact.number}` === `+${receiver_number}`)
      return contact ? contact?.username : `+${receiver_number}`
    }

    return `+${receiver_number}`
  }

  const renderLink = ({ attributes, content }: any) => {
    const { href } = attributes
    return (
      <span
        style={{ background: 'none', border: 'none', color: '#4b96ff', textDecoration: 'none', cursor: 'pointer' }}
        onClick={() => window.open(href, '_blank')}>
        {content}
      </span>
    )
  }

  const handleEmojiClick = (emojiObject: any) => {
    setSelectedMessage((pre) => pre + emojiObject?.emoji)
  }

  const optionLinkify = { target: '_blank', rel: 'noopener noreferrer', render: renderLink }

  return (
    <Layout>
      <Content>
        <div
          className={`${styles['MessageComponent']} flex flex-column relative max-h-full`}
          style={{ userSelect: 'text' }}>
          <div className={styles.MessageComponent__TopSection}>
            <div className={styles.MessageComponent__TopSection__left}>
              <div onClick={handleOpenSideBar}>{userImage(48)}</div>
              <div className={`${styles['left_recipient']} left_recipient`}>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    gap: '12px',
                    position: 'relative',
                  }}>
                  <h2 onClick={handleOpenSideBar} style={{ cursor: 'pointer' }}>
                    {selected_chat_recipient?.title}
                  </h2>
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                    onClick={() => setOpenAllTagDetail((pre) => !pre)}>
                    <div>
                      {tagsOfDialog?.slice(0, 1).map((tag: any) => (
                        <TagItem key={tag.id} title={tag.tag_name}></TagItem>
                      ))}
                    </div>
                    {tagsOfDialog?.length > 1 && (
                      <Tooltip title={'View All tag'}>
                        <span className={styles['chat_receipt_box__bottomSection__tag__more']}>
                          +{tagsOfDialog?.length - 1}
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  {openAllTagDetail && tagsOfDialog?.length > 0 && (
                    <div ref={tagDetailRef} className={styles['chat_receipt_box__bottomSection__tag__detail']}>
                      <TagDialog showDescription={false} />
                    </div>
                  )}
                </div>
                <div className={styles.MessageComponent__TopSection__left__tags}>
                  <span className={styles.tags}>
                    {additionalTags.slice(0, 2).map((tag: any) => (
                      <Tag key={tag}>{tag}</Tag>
                    ))}
                    {additionalTags.length > 2 && (
                      <Tooltip
                        title={additionalTags.slice(2).map((tag: any, index: number, array: any) => (
                          <Tag
                            style={{
                              backgroundColor: '#f2efff',
                              color: '#7043ff',
                              borderColor: '#f2f0ff',
                              marginRight: index === array.length - 1 ? '0px' : undefined,
                            }}
                            key={tag}>
                            {tag}
                          </Tag>
                        ))}>
                        <span className='chat_receipt_box__bottomSection__more'>+{additionalTags.length - 2}</span>
                      </Tooltip>
                    )}
                  </span>
                  {/* <span className={styles.socials}>
                    <img src={facebook} alt='' />
                    <img src={instagram} alt='' />
                    <img src={telegram} alt='' />
                    <img src={linkedin} alt='' />
                    <img src={discord} alt='' />
                    <img src={twitter} alt='' />
                  </span> */}
                </div>
              </div>
            </div>

            <div className={styles.MessageComponent__TopSection__right}>
              <CustomnMeetingBook
                defaultValue={defaultMeetingBook}
                value={defaultMeetingBook}
                onChange={handleChangeMeetingBook}
              />
              <Divider type='vertical' />
              <Select
                className='selectPriority'
                size='small'
                defaultValue={defaultPriority}
                value={defaultPriority}
                style={{ width: 99 }}
                placeholder='Priority'
                onChange={handleChangePriority}>
                <Select.Option value='high'>
                  <div className={styles.priority}>
                    <span className='select_dots'></span>
                    <span>High</span>
                  </div>
                </Select.Option>
                <Select.Option value='medium'>
                  <div className={styles.priority}>
                    <span className='select_dots' style={{ backgroundColor: '#FFC700' }}></span>
                    <span>Medium</span>
                  </div>
                </Select.Option>
                <Select.Option value='low'>
                  <div className={styles.priority}>
                    <span className='select_dots' style={{ backgroundColor: 'green' }}></span>
                    <span>Low</span>
                  </div>
                </Select.Option>
              </Select>
              <Divider type='vertical' />

              {/* <span className={styles.pin}> */}
              <div>
                <img style={{ cursor: 'pointer' }} src={pin} alt='pin' onClick={changePin} />
              </div>
              {/* </span> */}
              {/* <span className={styles.enrich}>Enrich</span> */}
              <Dropdown trigger={['click']} menu={{ items }} placement='bottomLeft'>
                <img src={menuIcon} alt='icon' style={{ cursor: 'pointer' }} />
              </Dropdown>
            </div>
          </div>

          {loadingMessages ? (
            <div className={styles.loadingOverlay}>
              <Spin size='large' indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
              <span>Loading</span>
            </div>
          ) : (
            <div className={styles.MessageComponent__middleSection}>
              {[...updateMessage]?.reverse()?.map((msg: any, i: any) => {
                return (
                  <div key={i}>
                    {!msg.outgoing ? (
                      <div className={styles.left_chat}>
                        {userImage(24)}
                        <div className={styles.left_chat_text}>
                          <Linkify tagName='pre' options={optionLinkify}>
                            <div className={styles.groupUsername}>
                              {selected_chat_recipient?.dialog_type === 'chat' && (
                                <>
                                  <span
                                    style={{
                                      fontWeight: 600,
                                      color: getColorByUsername(msg.sender_username || 'a'),
                                    }}>
                                    {msg.sender_username}
                                  </span>
                                  <br />
                                </>
                              )}
                              {msg?.media?.includes('ogg') ? (
                                <>
                                  {msg?.media?.includes('ogg') && (
                                    <>
                                      <div className={styles.leftAudio}>
                                        <audio controls={true}>
                                          <source src={msg?.media} type='audio/ogg' />
                                        </audio>
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : (
                                <div className={styles.messageContainer}>
                                  {msg?.reply_to?.includes('{') && renderReplyTo(msg.reply_to)}
                                  {msg?.media && (
                                    <>
                                      {msg?.media?.includes('jpeg') && (
                                        <>
                                          <Image
                                            src={msg?.media}
                                            style={{ width: '100%', height: '200px', padding: 0 }}
                                          />
                                        </>
                                      )}
                                      {msg?.media?.includes('jpg') && (
                                        <>
                                          <Image
                                            src={msg?.media}
                                            style={{ width: '100%', height: '200px', padding: 0 }}
                                          />
                                        </>
                                      )}
                                      {msg?.media?.includes('png') && (
                                        <>
                                          <Image
                                            src={msg?.media}
                                            style={{ width: '100%', height: '200px', padding: 0 }}
                                          />
                                        </>
                                      )}
                                      {msg?.media?.includes('gif') && (
                                        <>
                                          <Image
                                            src={msg?.media}
                                            style={{ width: '100%', height: '200px', padding: 0 }}
                                          />
                                        </>
                                      )}
                                      {msg?.media?.includes('mp4') && (
                                        <>
                                          <video width='100%' height='200px' autoPlay loop muted src={msg?.media} />
                                        </>
                                      )}
                                      {msg?.media?.includes('phone_number') && renderContact(msg?.media, true)}
                                    </>
                                  )}
                                  <span
                                    style={{
                                      maxWidth: '200px',
                                      overflow: 'hidden',
                                      whiteSpace: 'initial',
                                    }}>
                                    {msg.message}
                                  </span>
                                </div>
                              )}
                            </div>
                          </Linkify>
                          <span className={styles.left_chat_time}>
                            {/* {msg.receiver} <span className={styles.left_chat_text__dots}></span>Few seconds ago */}
                            <span className={styles.left_chat_text__dots}></span>
                            {formatDate(msg.sent_at)}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.right_chat}>
                        <div className={styles.right_chat_text}>
                          {msg?.media?.includes('ogg') ? (
                            <>
                              {msg?.media?.includes('ogg') && (
                                <>
                                  <div className={styles.rightAudio}>
                                    <audio controls={true} muted>
                                      <source src={msg?.media} type='audio/ogg' />
                                    </audio>
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <div className={styles.messageContent}>
                              <Linkify tagName='pre' options={optionLinkify}>
                                <>
                                  <div className={styles.messageContainerRight}>
                                    {msg?.reply_to?.includes('{') && renderReplyTo(msg.reply_to)}

                                    {msg?.media && (
                                      <>
                                        {msg?.media?.includes('jpeg') && (
                                          <>
                                            <Image
                                              src={msg?.media}
                                              style={{ width: '100%', height: '200px', padding: 0 }}
                                            />
                                          </>
                                        )}

                                        {msg?.media?.includes('jpg') && (
                                          <>
                                            <Image
                                              src={msg?.media}
                                              style={{ width: '100%', height: '200px', padding: 0 }}
                                            />
                                          </>
                                        )}
                                        {msg?.media?.includes('png') && (
                                          <>
                                            <Image
                                              src={msg?.media}
                                              style={{ width: '100%', height: '200px', padding: 0 }}
                                            />
                                          </>
                                        )}
                                        {msg?.media?.includes('gif') && (
                                          <>
                                            <Image
                                              src={msg?.media}
                                              style={{ width: '100%', height: '200px', padding: 0 }}
                                            />
                                          </>
                                        )}
                                        {msg?.media?.includes('mp4') && (
                                          <>
                                            <video width='100%' height='200px' autoPlay loop muted src={msg?.media} />
                                          </>
                                        )}
                                        {msg?.media?.includes('phone_number') && renderContact(msg?.media)}
                                      </>
                                    )}
                                    <span
                                      style={{
                                        maxWidth: '200px',
                                        overflow: 'hidden',
                                        whiteSpace: 'initial',
                                      }}>
                                      {msg.message}
                                    </span>
                                  </div>
                                </>
                              </Linkify>
                              <img src={double_tick} alt='' />
                            </div>
                          )}
                          <span className={styles.left_chat_time}>
                            <span>
                              <span className={styles.left_chat_text__dots}></span>
                              {formatDate(msg.sent_at)}
                            </span>
                            <img
                              src={chatImgStates[msg.message] || chatImg}
                              style={{ height: '20px', width: '16px', marginLeft: '5px' }}
                              onClick={() => changeChatBookmark(msg.message)}
                              alt=''
                            />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
              {/* {updateMessage.map((msg, i) => {
              // console.log('mmmsssggg', msg) // Log msg here
              return (
                <div key={i}>
                  {msg.receiver ? (
                    <div className={styles.left_chat}>
                      <img src={user} className={styles.avatar} alt='' />
                      <div className={styles.left_chat_text}>
                        <p>{msg.text}</p>
                        <span className={styles.left_chat_time}>
                          {msg.receiver} <span className={styles.left_chat_text__dots}></span>Few seconds ago
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.right_chat}>
                      <div className={styles.right_chat_text}>
                        <p>
                          {msg.text}
                          <img src={double_tick} alt='' />
                        </p>
                        <span className={styles.left_chat_time}>
                          <span>
                            {msg.sender} <span className={styles.left_chat_text__dots}></span>Few seconds ago
                          </span>
                          <img
                            src={chatImgStates[msg.text] || chatImg}
                            onClick={() => changeChatBookmark(msg.text)}
                            alt=''
                          />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )
            })} */}
              <div ref={messageEndRef} />
            </div>
          )}
          <div className={styles.MessageComponent__bottomSection}>
            {/* <span className='MessageComponent__bottomSection__left'>
              <Select size='large' defaultValue='Ask AI' style={{ width: 111 }}>
                <Select.Option value='ask ai'>Ask AI</Select.Option>
              </Select>
            </span> */}
            <span className='MessageComponent__bottomSection__middle'>
              <div className={styles.emojiAbsolute}>
                <img
                  style={{ cursor: 'pointer' }}
                  src={emojiPickerIcon}
                  alt='picker icon'
                  onClick={() => setShowPicker((pre) => !pre)}
                />
                <div ref={pickerRef} className={styles.emojiSelect}>
                  <Picker open={showPicker} onEmojiClick={handleEmojiClick} autoFocusSearch={false} />
                </div>
              </div>
              <div className={styles.bookmarkAbsolute}>{suffix}</div>
              <div className={styles.clipAbsolute}>
                <Upload
                  beforeUpload={(file) => {
                    // check if file is the right type
                    if (
                      file.type === 'image/jpeg' ||
                      file.type === 'image/png' ||
                      file.type === 'video/mp4' ||
                      file.type === 'image/gif'
                    ) {
                      setFile(file)
                      return false
                    } else {
                      message.error('Please upload only image or video file')
                      return false
                    }
                  }}
                  showUploadList={false}
                  accept='.jpg, .jpeg, .png, .mp4, .gif'>
                  <PaperClipOutlined style={{ color: 'var(--color-gray-01)', fontSize: '20px' }} />
                </Upload>
              </div>
              {file && (
                <>
                  <div className={styles.fileAbsolute}>
                    <Tag
                      closable
                      onClose={() => {
                        setFile(null)
                      }}>
                      <Tooltip title={file?.name}>
                        {file?.name?.length > 7 ? file?.name?.slice(0, 7) + '...' : file?.name}
                      </Tooltip>
                    </Tag>
                  </div>
                </>
              )}
              <div className='fileAbsolute'></div>
              <Input.TextArea
                className='messageComponentInput'
                placeholder={`Message from ${numberToName()}`}
                // prefix={loading ? <Spin style={{ marginRight: '5px' }} /> : <></>}
                size='large'
                // suffix={suffix}
                // onChange={}
                autoSize={{ minRows: 1, maxRows: 6 }}
                value={selectedMessage}
                onChange={handleInputChange}
                // onPressEnter={handleKeyPress}
                onKeyDown={handleKeyDown}
                disabled={loading}
              />

              {loading && (
                <div className={styles.loadingSendMessage}>
                  <Spin style={{ marginRight: '5px' }} />
                </div>
              )}
            </span>
            <span className='MessageComponent__bottomSection__right'>
              <Button type='primary' icon={<SendOutlined />} onClick={sendMessage}></Button>
            </span>
          </div>
        </div>
      </Content>
      <Sider
        width='340px'
        className={`${styles['MessageComponent_sider']} MessageComponent_sider`}
        collapsedWidth='0'
        collapsed={bookmarkCollapsed}>
        <div className={styles.MessageComponent_sider__topSection}>
          <h1>
            <img src={bookmark_black} alt='' /> Chat Bookmark
          </h1>
          <CloseOutlined onClick={closeBookmark} />
        </div>
        <Input size='large' placeholder='Search for messages' prefix={<SearchOutlined />} onChange={handleSearch} />
        {bookmarksData?.map((message: string, index: number) => (
          <div className={styles.sider_bookmark} key={index} onClick={() => valuepass(message)}>
            <p>{message}</p>
            <CloseOutlined
              className={styles.close_icon}
              // style={{ fontSize: '14px' }}
              onClick={(e) => {
                e.stopPropagation()
                handleDeleteBookmark(message)
              }}
            />
          </div>
        ))}
      </Sider>
      {selected_chat_recipient && openHubSpotSync && (
        <HubspotSyncSider
          open={openHubSpotSync}
          onClose={handleCloseHubspotSync}
          onChangeSyncContact={handleSyncHubspotContacts}
          onSelectContact={handleSelectHubspotContact}
          dialogId={selected_chat_recipient.id}
        />
      )}

      <ProfileSider
        isVisible={openProfileSider}
        openHubSpotSync={() => {
          setOpenHubSpotSync(true)
        }}
        onChangeSyncContact={handleSyncHubspotContacts}
        onOpen={handleOpenSideBar}
        onClose={() => {
          setOpenProfileSider(false)
          dispatch(SET_CLOSE_LEFT_SIDEBAR(!close_left_sidebar))
        }}
        userImage={userImage(128)}
      />
    </Layout>
  )
}

export default MessageComponent

// const filteredMessages = messages.filter((message) => message.toLowerCase().includes(searchQuery.toLowerCase()))
// const filteredMessages = [...updateMessage, ...channelMessages].map((msg) => {
//   console.log('Message:', msg)
//   return msg.text
// })
// .filter((text) => text.toLowerCase().includes(searchQuery.toLowerCase()))

// console.log('Filtered Messages:', filteredMessages)
// const changeChatBookmark = () => {
//   const newMessage = 'Lorem ipsum dolor sit amet consectetur. Viverra fames tincidunt duis fringilla ac.'
//   const updatedMessages = messages.filter((message) => message !== newMessage)
//   updatedMessages.unshift(newMessage)
//   setMessages(updatedMessages)

//   let value = chatImg
//   if (value === bookmark_chat) {
//     setChatImg(bookmark_chat_after)
//   } else {
//     setChatImg(bookmark_chat)
//   }
// }
