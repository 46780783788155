import React, { useEffect, useState } from 'react'
import styles from './NodeWrapper.module.scss'

interface NodeWrapperProps {
  children: any
  delay: any
}

const NodeWrapper: React.FC<NodeWrapperProps> = ({ children, delay }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, delay)

    return () => clearTimeout(timer)
  }, [delay])

  if (!isVisible) return null

  return <div className={styles.nodeEnterAnimation}>{children}</div>
}

export default NodeWrapper
