import React, { useEffect, useState } from 'react'
import { Row, Col, Steps, Typography } from 'antd'
import styles from './NewCampaignHeader.module.scss'
import left_arrow from 'common/assets/svg/left_arrow.svg'
import edit_icon from 'common/assets/svg/edit_icon.svg'
import campaign_timing_icon from 'common/assets/svg/campaign_timing_icon.svg'
import campaign_timing_icon_purple from 'common/assets/svg/campaign_timing_icon_purple.svg'
import { post } from 'common/api/axios'
import ExitModal from './UI/ExitModal'
import { DownOutlined } from '@ant-design/icons'
import CampaignTimingModal from './UI/CampaignTimingModal'
import { useLazyGetAllCampaignNamesQuery } from 'features/Outreach/state/api/OutreachApi'

interface NewCampaignHeaderProps {
  currentStep: any
  setCurrentStep?: any
  setNodesData?: any
}

const NewCampaignHeader: React.FC<NewCampaignHeaderProps> = ({ currentStep, setCurrentStep, setNodesData }) => {
  const { Paragraph } = Typography
  const [refetchGetAllCampaignNames, { data: allCampaignNames }] = useLazyGetAllCampaignNamesQuery()
  const [names, setNames] = useState([])
  const [openExitModal, setOpenExitModal] = useState(false)
  const [openCampaignTimingModal, setOpenCampaignTimingModal] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const defaultName = 'New Campaign Name'
  const defaultDescription = 'New Campaign Description'
  const [editableCampaignName, setEditableCampaignName] = useState(defaultName)
  const [editableCampaignDescription, setEditableCampaignDescription] = useState(defaultDescription)

  const handleNewCampaignClick = () => {
    if (currentStep === 1) {
      setCurrentStep(0)
    } else {
      setOpenExitModal(true)
    }
  }

  const handleOpenCampaignTimingModal = () => {
    setOpenCampaignTimingModal(true)
    post('/log', {
      action: 'Open Campaign Timing',
    })
  }

  const handleSetCampaignName = (val: any) => {
    const newVal = val.replace(/'/g, '')
    if (!allCampaignNames?.names?.includes(newVal)) {
      setEditableCampaignName(newVal)
      setNodesData((prev: any) => ({ ...prev, campaign_name: newVal }))
    }
  }

  const handleSetCampaignDescription = (val: any) => {
    const newVal = val.replace(/'/g, '')
    setEditableCampaignDescription(newVal)
    setNodesData((prev: any) => ({ ...prev, campaign_description: newVal }))
  }

  const handleSetCampaignSettings = (value: any) => {
    setNodesData((prev: any) => {
      return { ...prev, settings: value }
    })
  }

  const onChange = (value: Number) => {
    setCurrentStep(value)
  }

  useEffect(() => {
    setNodesData((prev: any) => ({
      ...prev,
      campaign_name: editableCampaignName,
      campaign_description: editableCampaignDescription,
    }))
  }, [])

  // useEffect(() => {
  //   const getAllCampaignNames = async () => {
  //     await refetchGetAllCampaignNames()
  //   }
  //   getAllCampaignNames()
  // }, [])

  useEffect(() => {
    const updateCampaignName = (name: any) => {
      let newName = name
      let count = 1

      while (true) {
        let nameExists = false

        for (const existingName of names) {
          if (existingName === newName) {
            nameExists = true
            break
          }
        }

        if (!nameExists) {
          break
        }

        newName = `${name} (${count})`
        count++

        if (count >= 100) break
      }

      setEditableCampaignName(newName)
      return newName
    }

    const getAllCampaignNames = async () => {
      const { data } = await refetchGetAllCampaignNames()
      setNames(data?.names)
      const newName = updateCampaignName(defaultName)

      setNodesData((prev: any) => ({
        ...prev,
        campaign_name: newName,
        campaign_description: editableCampaignDescription,
      }))
    }

    getAllCampaignNames()
  }, [names])

  return (
    <Row
      justify='space-between'
      align='middle'
      className={styles.header} // in global.css
      style={{
        padding: '15px 35px',
        backgroundColor: 'white',
        fontWeight: 600,
        // zIndex: 1000,
      }}>
      <Col>
        <div className={styles.leftSide}>
          <span style={{ marginRight: '10px', cursor: 'pointer' }} onClick={handleNewCampaignClick}>
            <img src={left_arrow} alt='left' />
          </span>
          <div className={styles.nameAndDescription}>
            <Paragraph
              style={{
                margin: '0px 0px 5px 0px',
                width: '250px',
                // color: editableCampaignName === defaultName ? 'red' : 'black',
              }}
              editable={
                currentStep === 0
                  ? {
                      onChange: handleSetCampaignName,
                      maxLength: 30,
                      autoSize: { maxRows: 1, minRows: 1 },
                      icon: <img style={{ margin: '0px', height: '13px', width: '13px' }} src={edit_icon} alt='' />,
                    }
                  : false
              }>
              {editableCampaignName}
            </Paragraph>
            <Paragraph
              style={{
                margin: '0px',
                width: '510px',
                fontSize: '12px',
                // color: editableCampaignDescription === defaultDescription ? 'red' : '#97a0af',
                color: '#97a0af',
              }}
              placeholder='New Campaign Description'
              editable={
                currentStep === 0
                  ? {
                      onChange: handleSetCampaignDescription,
                      maxLength: 80,
                      autoSize: { maxRows: 1, minRows: 1 },
                      icon: <img style={{ margin: '0px', height: '13px', width: '13px' }} src={edit_icon} alt='' />,
                    }
                  : false
              }>
              {editableCampaignDescription}
            </Paragraph>
          </div>
        </div>
      </Col>
      <Col span={5}>
        <Steps
          current={currentStep}
          type='navigation'
          onChange={onChange}
          size='small'
          items={[
            {
              title: 'Sequence',
            },
            {
              title: 'Leads',
              // disabled: !campaign_id,
              disabled: currentStep === 0,
            },
          ]}
        />
      </Col>
      <Col>
        <div
          className={styles.rightSide}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleOpenCampaignTimingModal}>
          <img src={isHovered ? campaign_timing_icon_purple : campaign_timing_icon} alt='' />
          <span style={{ margin: '0px 5px' }}> Campaign Timing</span>
          <DownOutlined style={{ fontSize: '12px' }} />
        </div>
      </Col>
      {openExitModal && (
        <ExitModal
          open={openExitModal}
          onClose={() => {
            setOpenExitModal(false)
          }}
        />
      )}
      {/* {openCampaignTimingModal && ( */}
      <CampaignTimingModal
        open={openCampaignTimingModal}
        onClose={() => {
          setOpenCampaignTimingModal(false)
        }}
        onFinish={(value) => {
          handleSetCampaignSettings(value)
        }}
        setNodesData={setNodesData}
      />
      {/* )} */}
    </Row>
  )
}

export default NewCampaignHeader
