import React from 'react'
import styles from './CampaignsStats.module.scss'
import CampaignStatusOverview from './Stats/CampaignStatusOverview'
import SequencesPerformance from './Stats/SequencesPerformance'
import LeadsOverview from './Stats/LeadsOverview'
import ChatMessagesOverview from './Stats/ChatMessagesOverview'
import { useAppSelector } from 'state'

interface CampaignsStatsProps {
  campaignsHeaderData?: any
  isFetching: boolean
}

const CampaignsStats: React.FC<CampaignsStatsProps> = ({ campaignsHeaderData, isFetching }) => {
  // const [getCampaignStats, { data: campaignsHeaderData, isFetching: isLoading }] = useLazyGetCampaignHeaderQuery()
  const { showPercentage } = useAppSelector((state) => state.outreachCampaign)
  const {
    // acceptance_rate,
    completed_campaigns,
    other_campaigns,
    percentage_responsive_leads,
    percentage_sent_leads,
    percentage_blocked_leads,
    change_in_total_blocked,
    percentage_change_in_total_blocked,
    total_blocked_leads,
    paused_campaigns,
    warming_campaigns,
    running_campaigns,
    total_campaigns,
    total_leads,
    total_replied_leads,
    total_sent_leads,
    completed_leads,
    continuing_leads,
    paused_leads,
    change_in_total_leads,
    change_in_total_sent_leads,
    change_in_total_replied_leads,
    percentage_change_in_total_leads,
    percentage_change_in_total_sent_leads,
    percentage_change_in_total_replied_leads,
    negative_replies,
    positive_replies,
    neutral_replies,
  } = campaignsHeaderData || {}

  return (
    <div className={styles.container}>
      <CampaignStatusOverview
        total={total_campaigns || 0}
        others={other_campaigns || 0}
        paused={paused_campaigns || 0}
        completed={completed_campaigns || 0}
        running={running_campaigns || 0}
        warming={warming_campaigns || 0}
        loading={isFetching}
      />
      <SequencesPerformance
        total_leads={total_leads}
        total_sent={showPercentage ? percentage_sent_leads : total_sent_leads}
        total_replied={showPercentage ? percentage_responsive_leads : total_replied_leads}
        total_blocked={showPercentage ? percentage_blocked_leads : total_blocked_leads}
        change_in_total_leads={showPercentage ? percentage_change_in_total_leads || 0 : change_in_total_leads || 0}
        change_in_total_sent_leads={
          showPercentage ? percentage_change_in_total_sent_leads || 0 : change_in_total_sent_leads || 0
        }
        change_in_total_replied_leads={
          showPercentage ? percentage_change_in_total_replied_leads || 0 : change_in_total_replied_leads || 0
        }
        change_in_total_blocked={
          showPercentage ? percentage_change_in_total_blocked || 0 : change_in_total_blocked || 0
        }
        loading={isFetching}
      />
      <LeadsOverview
        paused={Number(paused_leads)}
        completed={Number(completed_leads)}
        continuing={Number(continuing_leads)}
        loading={isFetching}
      />
      <ChatMessagesOverview
        positive={+positive_replies}
        neutral={+neutral_replies}
        negative={+negative_replies}
        loading={isFetching}
      />
    </div>
  )
}

export default CampaignsStats
