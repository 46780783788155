import { useState, useRef, useEffect } from 'react'

import styles from './OnBoardingPageC.module.scss'

interface OnBoardingPageCProps {
  formData: any
  handleSubmit?: any
  handleVerificationCompletion: any
}

const OnBoardingPageC = ({ formData, handleVerificationCompletion, handleSubmit }: OnBoardingPageCProps) => {
  const [verificationCodes, setVerificationCodes] = useState<string[]>(['', '', '', '', ''])
  const inputRefs = useRef<(HTMLInputElement | null)[]>([null, null, null, null, null])

  useEffect(() => {
    const handleInput = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      if (value.length > 1) {
        e.target.value = value[value.length - 1]
      }

      setVerificationCodes((prevCodes) => {
        const updatedCodes = [...prevCodes]
        updatedCodes[index] = value

        if (index < 4 && value.length === 1) {
          inputRefs.current[index + 1]?.focus()
        } else if (index > 0 && value.length === 0) {
          inputRefs.current[index - 1]?.focus()
        }

        const isCompleted = updatedCodes.every((code) => code.length === 1)

        if (isCompleted) handleVerificationCompletion(updatedCodes.join().replaceAll(',', ''))

        return updatedCodes
      })
    }

    inputRefs.current.forEach((input, index) => {
      if (input) {
        input.addEventListener('input', handleInput(index) as any)
      }
    })

    return () => {
      inputRefs.current.forEach((input, index) => {
        if (input) {
          input.removeEventListener('input', handleInput(index) as any)
        }
      })
    }
  }, [])

  const handleResendCode = () => {
    setVerificationCodes(['', '', '', '', ''])
    inputRefs.current[0]?.focus()
    handleSubmit()
  }

  return (
    <div className={styles.container}>
      <div className={styles.centeredContent}>
        <p>{'Enter The 5 Digits Code'}</p>
        <section>{`Sent to your Telegram number: ${formData?.prefix?.split('-')?.[0]}-${
          formData.telegramNumber
        }`}</section>
        <div className={styles.codeInputs}>
          {verificationCodes.map((code, index) => (
            <input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              className={styles.verificationInput}
              value={code}
              maxLength={1}
            />
          ))}
        </div>
        <section className={styles.resendButton}>
          <span onClick={handleResendCode}>{'Resend Code'}</span>
        </section>
      </div>
    </div>
  )
}

export default OnBoardingPageC
