import { useEffect, useState } from 'react'
import { Checkbox, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import { telegramGet } from 'common/api/axios'

import styles from './OnBoardingPageE.module.scss'
import { useAuth } from 'common/hooks/useAuth.hooks'

interface OnBoardingPageEProps {
  handleGroupsChange: any
  handleContactsChange: any
  formData: any
  selectedGroups: any
  selectedContacts: any
}

const OnBoardingPageE = ({
  handleGroupsChange,
  handleContactsChange,
  formData,
  selectedGroups,
  selectedContacts,
}: OnBoardingPageEProps) => {
  const { userDetails } = useAuth()

  const [groups, setGroups] = useState<any>([])
  const [contacts, setContacts] = useState<any>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [filteredGroups, setFilteredGroups] = useState<any>([])
  const [filteredContacts, setFilteredContacts] = useState<any>([])

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value)
  }

  useEffect(() => {
    const fetchData = async () => {
      const fetchedGroups = await telegramGet('/getGroups', {
        params: {
          email: userDetails?.email,
          number: formData?.telegramNumber,
          area_code: formData?.prefix,
        },
      })
      const fetchedContacts = await telegramGet('/getContacts', {
        params: {
          email: userDetails?.email,
          number: formData?.telegramNumber,
          area_code: formData?.prefix,
        },
      })

      setGroups(fetchedGroups.data)
      setContacts(fetchedContacts.data)
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (groups) {
      setFilteredGroups(
        groups?.filter(
          (group: any) =>
            group.title?.toLowerCase().indexOf(searchValue.trim()?.toLowerCase()) >= 0 ||
            selectedGroups?.includes(group?.id),
        ),
      )
    }

    if (contacts) {
      setFilteredContacts(
        contacts?.filter(
          (contact: any) =>
            `${contact.firstname || ''} ${contact.lastname || ''}`
              ?.toLowerCase()
              .indexOf(searchValue.trim()?.toLowerCase()) >= 0 || selectedContacts?.includes(contact.id),
        ),
      )
    }
  }, [searchValue, groups, contacts])

  return (
    <div className={styles.container}>
      <div className={styles.containerText}>
        <p>{'We Care About Your Privacy'}</p>
        <section>
          {`If there are any groups or contacts that you wish to exclude from importing into your Convrt account, select them now.`}
        </section>
      </div>
      <div className={styles.searchContainer}>
        <Input
          prefix={<SearchOutlined style={{ color: '#d9d9d9', marginRight: '2px' }} />}
          onChange={handleSearch}
          placeholder={`Search Groups or Contacts`}
          defaultValue={searchValue}
        />
      </div>
      <div className={styles.resultsContainer}>
        {filteredGroups?.length > 0 && (
          <div className={styles.groupsContainer}>
            {(searchValue ||
              filteredGroups?.filter((group: any) => selectedGroups?.includes(group?.id))?.length > 0) && (
              <h5>{`Groups`}</h5>
            )}
            <Checkbox.Group
              options={
                searchValue
                  ? filteredGroups?.map((group: any) => ({
                      value: group.id,
                      label: group.title,
                    }))
                  : filteredGroups
                      ?.filter((group: any) => selectedGroups?.includes(group?.id))
                      ?.map((group: any) => ({
                        value: group.id,
                        label: group.title,
                      }))
              }
              value={selectedGroups}
              onChange={handleGroupsChange}
              className={styles.options}
            />
          </div>
        )}
        {filteredContacts?.length > 0 && (
          <div className={styles.contactsContainer}>
            {(searchValue ||
              filteredContacts?.filter((contact: any) => selectedContacts?.includes(contact?.id))?.length > 0) && (
              <h5>{`Contacts`}</h5>
            )}
            <Checkbox.Group
              options={
                searchValue
                  ? filteredContacts?.map((contact: any) => ({
                      value: contact.id,
                      label: `${contact.firstname || ''} ${contact.lastname || ''}`,
                    }))
                  : filteredContacts
                      ?.filter((contact: any) => selectedContacts?.includes(contact?.id))
                      ?.map((contact: any) => ({
                        value: contact.id,
                        label: `${contact.firstname || ''} ${contact.lastname || ''}`,
                      }))
              }
              value={selectedContacts}
              onChange={handleContactsChange}
              className={styles.options}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default OnBoardingPageE
