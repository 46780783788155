import React, { useMemo } from 'react'
import { Card, Divider, Tag, Tooltip } from 'antd'
import { MdDateRange } from 'react-icons/md'

import styles from './GroupCard.module.scss'

import telegram_icon from 'common/assets/svg/tele_icon.svg'
import web2Filter from 'common/assets/svg/web2Filter.svg'

import { Event } from './EventAttendees'

interface GroupCardProps {
  event: Event
  onSelect: any
}

const propsTooltip = {
  color: '#fff',
  overlayStyle: {
    maxWidth: '600px',
    backgroundColor: 'white',
  },
  overlayInnerStyle: { backgroundColor: 'white' },
}

const GroupCard: React.FC<GroupCardProps> = ({ event, onSelect }) => {
  const handleSelectGroup = () => {
    onSelect(event)
  }

  const elipsisName = event?.title ? (
    event.title?.length > 40 ? (
      <Tooltip {...propsTooltip} title={event.title}>
        {event.title.slice(0, 40)?.trim()}...
      </Tooltip>
    ) : (
      <span>{event.title}</span>
    )
  ) : (
    event?.title
  )

  const handleLinkClick = (e: React.MouseEvent<HTMLDivElement | HTMLAnchorElement, MouseEvent>, link: string) => {
    e.stopPropagation()
    window.open(link, '_blank')
  }

  const formatedGroupTags = useMemo(() => {
    const tags: string[] = event?.tags
      ? event.tags
          .replace(/[{}]/g, '')
          .split(',')
          .map((tag: string) => tag.trim())
      : []
    tags.push('Events', `${event.country}`)
    return tags
  }, [event])

  const description = `From ${event.start_date} to ${event.end_date}. Explore the beauty of ${event.region} in this unique event.`
  return (
    <Card className={`addLeadsModal ${styles.groupCard}`} hoverable onClick={handleSelectGroup}>
      <div className={styles.cardHeader}>
        <div className={styles.groupName}>{elipsisName || '-'}</div>
      </div>
      <div className={styles.leads}>
        <div className={styles.groupStatus}>
          <MdDateRange />
          <span>{event.timing}</span>
        </div>
        <Divider type='vertical' />
        <div className={styles.platform}>
          <span>{`${event.city}, ${event.country}`}</span>
        </div>
      </div>
      <div className={styles.leads}>
        {event.event_link && (
          <>
            <div className={styles.link} onClick={(e) => handleLinkClick(e, event.event_link)}>
              <img src={web2Filter} alt='' />
              <span>Website</span>
            </div>
            <Divider type='vertical' />
          </>
        )}
        {event.telegram && (
          <>
            <div className={styles.platform}>
              <img src={telegram_icon} alt='' />
              <span>Telegram</span>
            </div>
          </>
        )}
      </div>
      <div className={styles.cardBody}>
        <div className={styles.groupDescription}>
          <Tooltip {...propsTooltip} color='#222' title={description}>
            {description.slice(0, 120)}...
          </Tooltip>
        </div>
        <div className={styles.groupTags}>
          {formatedGroupTags.length > 0 ? (
            <>
              {formatedGroupTags?.slice(0, 4).map((tag: any) =>
                tag?.length > 30 ? (
                  <Tooltip title={tag}>
                    <span className={styles.tag}>{tag?.slice(0, 30)?.trim() + '...'}</span>
                  </Tooltip>
                ) : (
                  <span className={styles.tag}>{tag}</span>
                ),
              )}
              {formatedGroupTags?.length > 4 && (
                <Tooltip
                  {...propsTooltip}
                  title={formatedGroupTags?.slice(4)?.map((tag: any) => (
                    <Tag
                      style={{
                        color: '#7043ff',
                        backgroundColor: '#f2f0ff',
                        padding: '2px 8px',
                        fontSize: '12px',
                        border: 'none',
                        marginBottom: '5px',
                      }}>
                      {tag}
                    </Tag>
                  ))}>
                  <span className={styles.tag}>+{formatedGroupTags?.length - 4}</span>
                </Tooltip>
              )}
            </>
          ) : (
            <span className={styles.noTags}>No tags yet.</span>
          )}
        </div>
      </div>
    </Card>
  )
}

export default GroupCard
