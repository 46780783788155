import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'antd'
import no_logo from 'common/assets/svg/no_logo.svg'
import power from 'common/assets/svg/power.svg'
import { Image } from 'common/constants/alt.constants'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useIntercom } from 'react-use-intercom'
import styles from './user-logged.module.scss'

interface IUserLogged {
  collapsed: boolean
}
const UserLogged: React.FC<IUserLogged> = ({ collapsed }) => {
  // const [isActive, setIsActive] = useState(false)
  const { shutdown } = useIntercom()

  // const handleElementClick = () => {
  //   setIsActive((isActive) => !isActive)
  // }

  const { logout: handleLogout, userDetails: uDetails } = useAuth()
  const navigate = useNavigate()
  const logout = (
    <div
      onClick={() => {
        handleLogout()
        shutdown()
        navigate('/login')
      }}>
      <img src={power} alt={''} className='floatLeftImg' />
      <span className={styles.logout}>Logout</span>
    </div>
  )

  const LogoutTooltip = {
    width: '140px',
    justifyContent: 'center',
    padding: '15px 18px',
    color: '#000',
    'font-family': 'Roboto',
    'font-size': '13px',
    'font-style': 'normal',
    'font-weight': '500',
    'line-height': '25px',
    'letter-spacing': '0.065px',
    display: 'flex',
    cursor: 'pointer',
    paddingLeft: '-5px',
  }

  let userDetails = null
  if (localStorage.getItem('user') !== null) {
    userDetails = JSON.parse(JSON.stringify(localStorage.getItem('user')))
  }

  const userTypeMap = {
    BETA: 'Trial',
    PREMIUM: 'Premium',
  }

  const planMapping: any = {
    go_to_market: 'Go To Market',
    connect_plus: 'Connect+',
    market_dominance: 'Market Dominance',
    trial: 'Trial',
    trail: 'Trial',
    custom: 'Custom',
    TRIAL: 'Trial',
    BETA: 'Trial',
    PAID_TRIAL: 'Trial',
  }

  return (
    <div
      className={styles.userLogged}
      style={{ cursor: 'pointer' }}
      // onClick={handleElementClick}
    >
      <Tooltip
        overlayStyle={{ zIndex: 2500 }}
        placement='right'
        title={logout}
        trigger='click'
        overlayInnerStyle={LogoutTooltip}
        color='white'
        // onOpenChange={handleElementClick}
      >
        <div className={styles.container}>
          <img src={no_logo} alt={Image.USER_LOGGED} />
          <div className={!collapsed ? styles.visible : styles.hidden}>
            {userDetails !== 'null' && <div className={styles.name}>{!collapsed && JSON.parse(userDetails).name}</div>}
            <div className={`${styles.type}`}>
              {!collapsed &&
                (uDetails?.plan_type
                  ? `${planMapping[uDetails?.plan_type]}`
                  : `${userTypeMap[JSON.parse(userDetails)?.subscription_type as 'BETA' | 'PREMIUM']} User`)}
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  )
}

export default UserLogged
