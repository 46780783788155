import { Tooltip } from 'antd'

export const ChainsList = ({ chains }: { chains: any[] }) => {
  const toShowChains = chains.slice(0, 3)

  const toTooltipChains = chains.slice(3)

  const TooltipContent = () => {
    return (
      toTooltipChains.length > 0 &&
      toTooltipChains.map((chain, index) => {
        return (
          <img
            className={'chainLogo'}
            key={`${chain} ${index}`}
            src={`https://convrt-media-store.s3.us-west-2.amazonaws.com/${chain}.png`}
            title={chain}
            alt=''
          />
        )
      })
    )
  }

  return (
    <>
      {toShowChains.map((chain, index) => {
        return (
          <img
            className={'chainLogo'}
            key={`${chain} ${index}`}
            src={`https://convrt-media-store.s3.us-west-2.amazonaws.com/${chain}.png`}
            title={chain}
            alt=''
          />
        )
      })}
      {toTooltipChains.length > 0 && (
        <Tooltip color={'#fff'} className={'chainLogoTooltip'} placement='topLeft' title={TooltipContent}>
          <span>{`+${toTooltipChains.length}`} more</span>
        </Tooltip>
      )}
    </>
  )
}
