import React, { useState } from 'react'
import { Dropdown, Tag, Menu, Button, Spin, message } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import styles from './VariablesTags.module.scss'
import wizard_icon from 'common/assets/svg/wizard_icon.svg'
import magic_wand from 'common/assets/svg/magic_wand.svg'
import type { MenuProps } from 'antd'
import { useLazyAssistWithAIQuery } from 'features/Outreach/state/api/OutreachApi'

// const { useToken } = theme

interface VariablesTagsProps {
  onAddToComposer: any
  currentMessage?: any
  onReplaceMessage?: any
}

const VariablesTags: React.FC<VariablesTagsProps> = ({ onAddToComposer, currentMessage, onReplaceMessage }) => {
  // const { token } = useToken()
  const [refetchAssistWithAI] = useLazyAssistWithAIQuery()
  const [loading, setLoading] = useState(false)
  // const [customVariable, setCustomVariable] = useState('')

  //eslint-disable-next-line
  const [tags, setTags] = useState<string[]>([
    // '@city',
    // '@email',
    // '@state',
    // '@country',
    '@first_name',
    '@full_name',
    // '@seniority',
    '@job_title',
    '@company_name',
    // '@job_function',
    '@vertical',
    '@sender_full_name',
    '@sender_company',
    '@sender_first_name',
    '@custom_1',
    '@custom_2',
  ])

  // const [customTags, setCustomTags] = useState<string[]>(['@custom_1', '@custom_2'])
  const successMessageMapping: any = {
    grammar: 'Grammar fixed by Convrt AI. Your message is now polished!',
    shorten: 'Message shortened by Convrt AI. Review your concise text!',
    casual: 'Message transformed to a casual tone by Convrt AI. Check out the friendly vibe!',
    formal: 'Message elevated to formal elegance by Convrt AI. Examine the professional tone!',
  }

  const handleClickAskAIButton = async (key: any) => {
    if (!currentMessage?.trim()) {
      message.warning('Please enter a message to proceed with Convrt AI enhancements.')
      return
    }
    setLoading(true)
    try {
      const { data } = await refetchAssistWithAI({
        message: currentMessage,
        task: key,
      })
      if (data?.result) {
        onReplaceMessage(data?.result)
        message.success(successMessageMapping[key])
      } else {
        message.error('Somthing went wrong generating your message. Plesae try again.')
      }
      setLoading(false)
    } catch (e) {
      console.error('An error has occured: ', e)
      message.error('Somthing went wrong generating your message. Plesae try again.')
      setLoading(false)
    }
  }
  const menuOptions = [
    // { key: 'title_1', text: 'Improve Text', isTitle: true },
    { key: '1', text: 'Fix Grammar', action: () => handleClickAskAIButton('grammar') },
    { key: '2', text: 'Make Shorter', action: () => handleClickAskAIButton('shorten') },
    // { key: 'title_2', text: 'Change Tone', isTitle: true },
    { key: '3', text: 'More Casual', action: () => handleClickAskAIButton('casual') },
    { key: '4', text: 'More Formal', action: () => handleClickAskAIButton('formal') },
    // { key: '5', text: 'More Fun', action: () => handleClickAskAIButton('fun') },
  ]

  const askAIMenu = (
    <Menu
      items={menuOptions.map((option) => {
        // if (option?.isTitle) {
        //   return {
        //     key: option.key,
        //     label: <div style={{ color: 'black', fontWeight: '600' }}>{option.text}</div>,
        //     disabled: true,
        //   }
        // }
        return {
          key: option.key,
          // icon: <UserOutlined />,
          icon: <img src={magic_wand} alt='' style={{ margin: 0 }} />,
          label: (
            <Button style={{ color: '#7043FF ' }} type='link' onClick={option.action}>
              {option.text}
            </Button>
          ),
        }
      })}
    />
  )

  const maxVisibleTags = 0

  const menuStyle: React.CSSProperties = {
    textAlign: 'center',
    boxShadow: 'none',
    // backgroundColor: 'red',
  }

  const contentStyle: React.CSSProperties = {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow:
      '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
  }

  // Take the first 4 tags to display, and the rest to show in the tooltip
  const visibleTags = tags.slice(0, maxVisibleTags)
  const hiddenTags = tags

  const hiddenTagItems = hiddenTags.map((tag, index) => {
    if (tag === '@custom_1' || tag === '@custom_2') {
      return {
        key: `${index + 1}`,
        label: (
          <Tag color='#F2F0FF' style={{ margin: '0px', padding: '5px' }} onClick={() => onAddToComposer(tag)}>
            <span style={{ color: '#33a892', fontSize: '13px', fontWeight: 600 }}>{tag}</span>
          </Tag>
        ),
      }
    }

    return {
      key: `${index + 1}`,
      label: (
        <Tag color='#F2F0FF' style={{ margin: '0px', padding: '5px' }} onClick={() => onAddToComposer(tag)}>
          <span style={{ color: '#7043ff', fontSize: '13px', fontWeight: 600 }}>{tag}</span>
        </Tag>
      ),
    }
  })

  const items: MenuProps['items'] = [...hiddenTagItems]

  return (
    <div className={styles.container}>
      {visibleTags.map((tag, index) => (
        <Tag color='#F2F0FF' key={index} onClick={() => onAddToComposer(tag)} style={{ cursor: 'pointer' }}>
          <span>{tag}</span>
        </Tag>
      ))}
      {hiddenTags.length > 0 && (
        <Dropdown
          menu={{ items }}
          placement='bottom'
          trigger={['click']}
          className={'variablesDropDown'}
          // dropdownRender={(menu) => <div>{React.cloneElement(menu as React.ReactElement, { style: menuStyle })}</div>}
          dropdownRender={(menu) => (
            <div style={contentStyle}>
              {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}

              {/* <Divider style={{ margin: 0 }} />
              <Space style={{ padding: 8 }}>
                <Input
                  placeholder='Add a Custom Variable'
                  prefix='@'
                  onChange={handleInputChange}
                  value={customVariable}
                  onKeyDown={handleInputEnter}
                />
              </Space> */}
            </div>
          )}>
          <Tag color='white' className={styles.flex_center}>
            {'Add personalization'}
          </Tag>
        </Dropdown>
      )}

      <Dropdown overlay={askAIMenu} trigger={['click']}>
        <Button style={{ borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
          <img src={wizard_icon} style={{ filter: 'none' }} alt='' />
          <span>Ask AI</span>
          <DownOutlined />
        </Button>
      </Dropdown>
      {loading && (
        <div className={styles.spin}>
          {/* {'Generating AI'} */}
          <Spin />
        </div>
      )}
    </div>
  )
}

export default VariablesTags
