import React from 'react'
import { Button, Modal } from 'antd'
import completed from 'common/assets/svg/completed_tag.svg'
import styles from './EditCampaignSaveModal.module.scss'

interface EditCampaignSaveModalProps {
  open: boolean
  onClose: () => void
  campaignName: string
  onConfirm: () => void
}

const EditCampaignSaveModal: React.FC<EditCampaignSaveModalProps> = ({ open, onClose, campaignName, onConfirm }) => {
  return (
    <>
      <Modal
        className='modalStyleNewDeal'
        centered
        open={open}
        okButtonProps={{
          className: 'hidden',
        }}
        cancelButtonProps={{
          className: 'hidden',
        }}
        onCancel={() => {
          onClose()
        }}
        width={'fit-content'}
        style={{ minWidth: 600, maxWidth: 600 }}>
        <div className={styles.contentStyle}>
          <div className={styles.container}>
            <img src={completed} alt='' />
            <p style={{ marginBottom: '0px' }}>All Set!</p>
            <p style={{ marginTop: '0px', marginBottom: '20px' }}>Would you like to run your campaign now?</p>
            {/* <span>
              Your campaign will stop running immediately.
              <br />
              However, messages that are already in the process of being sent
              <br /> will continue to be delivered.
            </span> */}
            <div className={styles.footer}>
              <Button size={'large'} onClick={onClose}>
                Cancel
              </Button>
              <Button size={'large'} style={{ color: 'white', backgroundColor: '#7043ff' }} onClick={onConfirm}>
                Run Now
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default EditCampaignSaveModal
