import React, { useEffect } from 'react'
import { Typography } from 'antd'
import classNames from 'classnames'
import styles from './styles.module.scss'

interface Props {
  tabList: any[]
  index: number
  onTabChange?: (value: string) => void
}

export const Tab = ({ tabList = [], onTabChange, index }: Props) => {
  const [currentTab, setCurrentTab] = React.useState(tabList[index || 0]?.value)
  const handleTabChange = (value: string) => {
    onTabChange?.(value)
    setCurrentTab(value)
  }

  useEffect(() => {
    setCurrentTab(tabList[index].value)
  }, [index])

  return (
    <div className={styles.tab}>
      {currentTab &&
        tabList.map((tab, index: number) => {
          const isActiveTab = currentTab === tab.value
          return (
            <div
              key={tab.value}
              className={classNames(styles.tab__item, {
                [styles['tab__item--active']]: isActiveTab,
                [styles['tab__item--active--first']]: index === 0,
                [styles['tab__item--active--last']]: index === tabList?.length - 1,
              })}
              onClick={() => handleTabChange(tab.value)}>
              <Typography>{tab.label}</Typography>
            </div>
          )
        })}
    </div>
  )
}
