import React from 'react'
import styles from './CustomStatus.module.scss'
import { capitalize } from 'lodash'

interface CustomStatusProps {
  status: string
}

const CustomStatus: React.FC<CustomStatusProps> = ({ status }) => {
  const statusText = status === 'failed' ? 'error' : status
  const getStyle = (status: string) => {
    switch (status) {
      case 'running':
        return styles.running
      case 'error':
        return styles.error
      case 'pending':
        return styles.pending
      case 'completed':
        return styles.completed
      case 'pause':
        return styles.pause
      case 'draft':
        return styles.draft
      case 'awaiting':
        return styles.awaiting
      case 'warming':
        return styles.warming
      default:
        return styles.error
    }
  }

  return (
    <div className={`${styles.container} ${getStyle(statusText)}`}>
      <span className={styles.number}>{capitalize(statusText)}</span>
    </div>
  )
}

export default CustomStatus
