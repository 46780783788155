import { useState } from 'react'
import { Form, Input } from 'antd'

import FlagInput from '../FlagInput'

import styles from './OnBoardingPageB.module.scss'

interface OnBoardingPageBProps {
  handleFormDataChange: (data: any) => void
}

const OnBoardingPageB = ({ handleFormDataChange }: OnBoardingPageBProps) => {
  const [prefix, setPrefix] = useState('+1')

  const handleValuesChange = (changedValues: any, allValues: any) => {
    handleFormDataChange({ ...allValues, telegramNumber: allValues.telegramNumber, prefix })
  }

  const handlePrefixChange = (e: any) => {
    setPrefix(e)
  }

  return (
    <div className={styles.container}>
      <p>{'Fill In Your Number'}</p>
      <section>
        {'We are committed to safeguarding your data, the information requested here will solely be used for you'}
      </section>
      <Form onValuesChange={handleValuesChange}>
        <h5>{'Telegram Number'}</h5>
        <div className='phoneContainer'>
          <Form.Item
            name='telegramNumber'
            rules={[
              { required: true, message: 'Telegram Number is required' },
              { message: 'Invalid phone number', pattern: new RegExp(/^[0-9]+$/) },
            ]}>
            <Input prefix={<FlagInput className={styles.prefix} onChange={handlePrefixChange} prefix={prefix} />} />
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default OnBoardingPageB
