import React, { useState } from 'react'

import { Layout } from 'antd'
import classNames from 'classnames'
import { Menu } from 'antd'
import type { MenuProps } from 'antd'
import { MenuServices } from 'common/services/menu-services'
import { useAppSelector } from 'state'

import BottomMenu from 'common/components/bottom-menu/bottom-menu'
import Logo from 'common/components/logo/logo'
import TopMenu from 'common/components/top-menu/top-menu'
import UserLogged from 'common/components/user-logged/user-logged'
import getPointsWhite from 'common/assets/svg/getPointsWhite.svg'
import BuyCreditsModal from 'common/components/BuyConvrt/BuyCreditsModal'

import styles from './sidebar.module.scss'

const { Sider } = Layout

const Sidebar: React.FC = () => {
  const { isSubscriptionDaysPassed } = useAppSelector((state) => state.filters)

  const [openBuyCreditsModal, setOpenBuyCreditsModal] = useState(false)

  const [collapsed, setCollapsed] = useState(true)
  // const [pinClicked, setPinClicked] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')

  // useEffect(() => {
  //   setCollapsed(!pinClicked)
  // }, [pinClicked])

  type MenuItem = Required<MenuProps>['items'][number]

  const items: MenuItem[] = [
    MenuServices.getItem(
      !collapsed && 'Buy Points',
      'Buy Points',
      <img src={getPointsWhite} alt={''} />,
      isSubscriptionDaysPassed,
    ),
  ]

  return (
    <div className='cabmCustomSidebar' style={{ zIndex: '20000px' }}>
      <Sider
        className={`mainSidebar ${classNames(
          styles.cabmSidebar,
          collapsed ? styles.sidebarCollapsed : styles.sidebarExpanded,
        )}`}
        collapsed={collapsed}
        onMouseEnter={() => {
          setCollapsed(false)
        }}
        onMouseLeave={() => {
          setCollapsed(true)
        }}>
        <div className={styles.topSection}>
          {/* {!collapsed && (
            <span
              className={`pinElipse ${!collapsed && 'movePin'}`}
              onClick={() => {
                setPinClicked(pinClicked ? false : true)
              }}>
              <img src={push_pin} alt={''} />
            </span>
          )} */}
          <Logo collapsed={collapsed} />
          <TopMenu
            collapsed={collapsed}
            callbackOnChange={(value) => {
              value && setSelectedOption(value)
            }}
            selectedKey={selectedOption}
          />
        </div>
        <div className={styles.bottomSection}>
          <hr />
          <div className='buy_pointParent'>
            <Menu
              onClick={() => {
                setOpenBuyCreditsModal(true)
              }}
              theme='dark'
              defaultSelectedKeys={[]}
              mode='inline'
              items={items}
              className={styles.buy_point}
            />
          </div>

          {/* <div style={{ width: '260px', height: '60px', display: 'flex', flexDirection: 'row' }}>
            <div className={styles.buy_point}>
              <img className={styles.buy_point_img} src={getPointsWhite} alt={''} />
              <div className={styles.caption} style={{ lineHeight: '45px' }}>
                Buy Points
              </div>
            </div>
          </div> */}

          <BottomMenu
            collapsed={collapsed}
            callbackOnChange={(value) => {
              value && setSelectedOption(value)
            }}
            selectedKey={selectedOption}
          />
          <div className='userLoggedParent'>
            <UserLogged collapsed={collapsed} />
          </div>
        </div>
      </Sider>
      <BuyCreditsModal
        open={openBuyCreditsModal}
        onClose={() => {
          setOpenBuyCreditsModal(false)
        }}
      />
    </div>
  )
}

export default Sidebar
