import React, { useState } from 'react'
import { Button, Layout, Space, Switch, Tooltip, message } from 'antd'
import left_arrow from 'common/assets/svg/left_arrow.svg'
import info_icon from 'common/assets/svg/info_icon.svg'
import hubspot from 'common/assets/svg/hubspot_purple.svg'
import styles from './HubspotExportContacts.module.scss'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import { useGetContactsHubSpotMutation } from 'features/contacts/state/api/ContactsApi'
// import { useLazyGetPointsQuery } from 'features/settings/state/api/SettingsApi'

//import { useGetContactsHubSpotMutation, useLazyGetTakeCreditsQuery } from 'features/contacts/state/api/ContactsApi'
import { post } from 'common/api/axios'

import LoadingBox from 'common/components/LoadingBox/LoadingBox'
//import { post } from 'common/api/axios'
import OutOfCreditsModal from 'common/components/OutOfCredits/OutOfCreditsModal'

interface HubspotExportContactsProps {
  onBack: () => void
  onCloseModal: () => void
  selectedContacts: any
  emailCredits: any
  telegramCredits: any
  userCredits: any
  userPoints: any
}

const HubspotExportContacts: React.FC<HubspotExportContactsProps> = ({
  onBack,
  onCloseModal,
  selectedContacts,
  emailCredits,
  telegramCredits,
  userCredits,
  userPoints,
}) => {
  const [outOfCredits, setOutOfCredits] = useState(false)
  const [outOfCreditsType, setOutOfCreditsType] = useState('')
  const [telegramToggle, setTelegramToggle] = useState(true)
  const [emailToggle, setEmailToggle] = useState(true)
  const [overwriteToggle, setOverwriteToggle] = useState(false)
  const [fetchHubSpot] = useGetContactsHubSpotMutation()
  const [isLoading, setIsLoading] = useState(false)
  // const [refetchGetPoints] = useLazyGetPointsQuery()

  const handleToggleSwitchTelegram = () => {
    setTelegramToggle(!telegramToggle)
  }

  const handleToggleSwitchEmail = () => {
    setEmailToggle(!emailToggle)
  }

  const onChangeOverwriteToggle = () => {
    setOverwriteToggle(!overwriteToggle)
  }

  const handleExport = async () => {
    // const creditsResponse = await refetchGetPoints()

    // if (userCredits.contacts_export < selectedContacts.length) {
    if (userPoints.points < userPoints.pointsStore.contacts_export * selectedContacts.length) {
      setOutOfCreditsType('Contacts')
      setOutOfCredits(true)
      return
      // } else if (emailToggle && userCredits.email < emailCredits.length) {
    } else if (emailToggle && userPoints.points < userPoints.pointsStore.email * emailCredits.length) {
      setOutOfCreditsType('Email')
      setOutOfCredits(true)
      return
      // } else if (telegramToggle && userCredits.telegram < telegramCredits.length) {
    } else if (telegramToggle && userPoints.points < userPoints.pointsStore.telegram * telegramCredits.length) {
      setOutOfCreditsType('Telegram')
      setOutOfCredits(true)
      return
    } else {
      try {
        setIsLoading(true)
        const params = {
          contacts: selectedContacts,
          do_override_if_exists: overwriteToggle,
          emailCredits,
          telegramCredits,
          emailToggle,
          telegramToggle,
        }
        const result = await fetchHubSpot(params)

        if (result?.error?.status === 470) {
          setOutOfCreditsType('Contacts')
          setOutOfCredits(true)
          setIsLoading(false)
        } else if (result.error) {
          message.error(
            `Oops! Your HubSpot enrichment failed to complete. Please try again or contact support if the issue persists.`,
          )
          setIsLoading(false)
        } else {
          message.success(
            `HubSpot enriched! We've added data for ${result.data.addedToHubspot} contacts to your HubSpot`,
          )
          onCloseModal()
        }
      } catch (error) {
        console.error(error)
        message.error('Something went wrong!')
        setIsLoading(false)
      }
    }
  }

  return (
    <>
      {outOfCredits && (
        <OutOfCreditsModal
          open={outOfCredits}
          onClose={() => {
            setOutOfCredits(false)
          }}
          creditType={outOfCreditsType}
          location='hubspot'
        />
      )}
      <Space direction='vertical' style={{ width: '100%' }} size={[0, 48]}>
        <Layout>
          <Header className={styles.headerStyle}>
            <img src={hubspot} alt='' />
            <h1>Export Contact List</h1>
          </Header>

          <Content className={styles.contentStyle}>
            {isLoading ? (
              <>
                <div className={styles.loading}>
                  <LoadingBox />
                </div>
                <div className={styles.headerLoadingBoxText}>
                  Enriching your HubSpot is in progress. You can close this window - we will notify you when the
                  enrichment is complete
                </div>
              </>
            ) : (
              <div className={styles.container}>
                <div className={styles.header}>
                  <p className={styles.title}>Export Contacts</p>
                  <p className={styles.sub_title}>
                    Export Contacts: {userPoints.pointsStore.contacts_export * selectedContacts.length} points
                  </p>

                  <span className={styles.description}>
                    To export contacts from your selected companies, we need to expose contacts’ data.
                    <br />
                    Please indicate your preferences for exporting:
                  </span>
                </div>
                <div className={styles.switchContainer}>
                  <div className={styles.switchItem}>
                    <Switch onChange={handleToggleSwitchTelegram} checked={telegramToggle} size={'small'} />
                    <span className={styles.switchLabel}>
                      Expose Telegram Accounts ({userPoints.pointsStore.telegram * telegramCredits?.length} Points)
                    </span>
                  </div>
                  <div className={styles.switchItem}>
                    <Switch onChange={handleToggleSwitchEmail} checked={emailToggle} size={'small'} />
                    <span className={styles.switchLabel}>
                      Expose Email Addresses ({userPoints.pointsStore.email * emailCredits?.length} Points)
                    </span>
                  </div>
                </div>
                <div className={styles.terms}>
                  <span>
                    {/* NEED TO OPEN THE MAPPING COMPONENT WHEN A USER CLICKS ON THE MAPPING SETTINGS */}
                    {/* style={{ color: '#7043ff' }} */}
                    The export will be done according to your <span>Mapping Setting</span>.
                  </span>
                </div>
              </div>
            )}
          </Content>

          <Footer className={styles.footerStyle}>
            <div className={styles.section}>
              <div
                className={styles.back}
                onClick={() => {
                  !isLoading && onBack()
                }}>
                <img src={left_arrow} alt='' />
                <span>Back</span>
              </div>

              <div className={styles.centeredText}>
                <Switch
                  style={{ marginRight: '5px' }}
                  onChange={onChangeOverwriteToggle}
                  checked={overwriteToggle}
                  size={'small'}
                  disabled={isLoading}
                />
                <span>Overwrite Populated Fields is {overwriteToggle ? 'ON' : 'OFF'}</span>
                <Tooltip
                  className='tooltipHubspotNewDeal'
                  overlayInnerStyle={{
                    color: 'black',
                    backgroundColor: 'white',
                  }}
                  title='By default, Convrt will not overwrite existing data in populated fields when enriching HubSpot records. It is possible to overwrite populated fields with Convrt data by turning this toggle ON.'>
                  <img src={info_icon} alt='' />
                </Tooltip>
              </div>

              <Button
                className={styles.button}
                onClick={() => {
                  post('/log', {
                    action: ' Export Hubspot Contacts',
                  })
                  !isLoading && handleExport()
                }}>
                Export
              </Button>
            </div>
          </Footer>
        </Layout>
      </Space>
    </>
  )
}

export default HubspotExportContacts
