import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'common/api/baseQueryWithValidation'

export const EventsApi: any = createApi({
  reducerPath: 'EventsApi',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/events`,
        method: 'POST',
        body: { currentDay: params.currentDay, filters: params.filters },
      }),
    }),
    getEvent: builder.mutation({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/events/${params}`,
      }),
    }),
    purchaseEvent: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/events/${params}`,
        method: 'PUT',
      }),
    }),
  }),
})

export const { useLazyGetEventsQuery, useGetEventMutation, useLazyPurchaseEventQuery } = EventsApi
