import React from 'react'
import { Button, Layout, Modal, Space, message } from 'antd'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import add_to_list from '../../../common/assets/svg/add_to_list.svg'
import styles from './RemoveFromListModal.module.scss'
import { useAppDispatch } from 'state'
import { useLazyUpdateListQuery } from 'features/intent-signals/state/api/CompaniesApi'
import { post } from 'common/api/axios'
import { SET_SELECTED_COMPANIES } from '../state/slice/companiesSlice'
import { SET_SELECTED_CONTACTS } from '../state/slice/contactsSlice'

interface RemoveFromListModalProps {
  open: boolean
  onClose: () => void
  isCompany?: boolean
  selectedContacts?: any
  selectedCompanies?: any
  listID: string
  refetchData: any
  lists: any
}

const MyContactRemoveFromListModal: React.FC<RemoveFromListModalProps> = ({
  open,
  onClose,
  isCompany = false,
  selectedContacts,
  selectedCompanies,
  listID,
  refetchData,
  lists,
}) => {
  const [refetchUpdateList, { isFetching }] = useLazyUpdateListQuery()
  const dispatch = useAppDispatch()
  const activeList = lists?.filter((list: any) => listID === list.id)[0]
  const disable = (activeList?.type === '1' && isCompany) || (activeList?.type === '2' && !isCompany)

  const numberToRemove = isCompany
    ? `${selectedCompanies.length} ${selectedCompanies.length === 1 ? 'company' : 'companies'}`
    : `${selectedContacts.length} ${selectedContacts.length === 1 ? 'contact' : 'contacts'}`

  // const arraysEqual = (a: any, b: any) => {
  //   if (a === b) return true
  //   if (a == null || b == null) return false
  //   if (a.length !== b.length) return false
  //   for (let i = 0; i < a.length; ++i) {
  //     if (a[i] !== b[i]) return false
  //   }
  //   return true
  // }

  const handleRemoveItemFromListCompany = async () => {
    const companiesItems = activeList?.company_ids
    const companiesArray = companiesItems.split(',')
    const filteredCompaniesArray = companiesArray.filter((company: any) => !selectedCompanies.includes(company))
    const updatedCompaniesItems = filteredCompaniesArray.join(',')
    post('/log', {
      action: 'Remove from list (Companies)',
    })
    try {
      await refetchUpdateList({
        id: listID,
        items_list: updatedCompaniesItems,
        // field_name: 'company_ids',
        field_name: isCompany ? 'company_ids' : 'contact_ids',
        // page_type: 'company',
        page_type: isCompany ? 'company' : 'contact',
        action: 'remove',
      })

      message.success(`Items removed from the list successfully!`)
      dispatch(SET_SELECTED_COMPANIES([]))
      onClose()
      refetchData()
    } catch (e) {
      console.error(e)
      message.error('Something went wrong. Please try again.')
    }
  }

  const handleRemoveItemFromListContact = async () => {
    post('/log', {
      action: 'Remove from list (Contacts)',
    })
    try {
      await refetchUpdateList({
        id: listID,
        items_list: selectedContacts?.join(','),
        // field_name: 'contact_ids',
        field_name: isCompany ? 'company_ids' : 'contact_ids',
        // page_type: locationPathName === 'companies' ? 'company' : 'contact',
        action: 'remove',
      })
      message.success(`Items removed from the list successfully!`)
      dispatch(SET_SELECTED_CONTACTS([]))
      onClose()
      refetchData()
    } catch (e) {
      console.error(e)
      message.error('Something went wrong. Please try again.')
    }
  }

  const handleRemoveItemFromList = isCompany ? handleRemoveItemFromListCompany : handleRemoveItemFromListContact

  return (
    <Modal
      className='modalStyleNewDeal'
      centered
      open={open}
      okButtonProps={{
        className: 'hidden',
      }}
      cancelButtonProps={{
        className: 'hidden',
      }}
      onCancel={() => {
        onClose()
      }}
      width={'fit-content'}
      style={{ minWidth: '400px', maxWidth: '400px' }}>
      <Space direction='vertical' style={{ width: '100%' }} size={[0, 48]}>
        <Layout>
          <Header className={styles.headerStyle}>
            <img src={add_to_list} alt='' />
            <h1>Remove Selected From List</h1>
          </Header>
          <Content className={styles.contentStyle} style={{ borderBottom: '1px solid #ebecf0' }}>
            <div className={styles.createNew}>
              {!disable ? (
                <p>You can only remove contacts from a contacts list, and companies from a companies list.</p>
              ) : (
                <p>
                  Are you sure you want <br />
                  to remove {numberToRemove} from your list?
                </p>
              )}
            </div>
          </Content>
          <Footer className={styles.footerStyle}>
            <div className={styles.section}>
              <div className={styles.back} onClick={onClose}>
                <span>Cancel</span>
              </div>
              <Button
                disabled={isFetching}
                loading={isFetching}
                className={styles.button}
                onClick={!disable ? onClose : handleRemoveItemFromList}>
                {disable ? 'Confirm' : 'Ok'}
              </Button>
            </div>
          </Footer>
        </Layout>
      </Space>
    </Modal>
  )
}
export default MyContactRemoveFromListModal
