import type { MenuProps } from 'antd'

type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  disabled?: boolean,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    disabled,
  } as MenuItem
}

export const MenuServices = {
  getItem,
}
