import React, { useState } from 'react'
import styles from './CustomToggleSwitch.module.scss'

interface CustomToggleSwitchProps {
  onToggle: (checked: boolean) => void
}

const CustomToggleSwitch: React.FC<CustomToggleSwitchProps> = ({ onToggle }) => {
  const [checked, setChecked] = useState<boolean>(true)

  const handleChange = () => {
    setChecked(!checked)
    onToggle(!checked)
  }

  return (
    <div className={styles.toggleContainer} onClick={handleChange}>
      <div className={`${styles.toggleOption} ${styles.leftToggle} ${!checked ? styles.active : styles.notActive}`}>
        #
      </div>

      <div className={`${styles.toggleOption} ${styles.rightToggle} ${checked ? styles.active : styles.notActive}`}>
        %
      </div>
    </div>
  )
}

export default CustomToggleSwitch
