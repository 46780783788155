import { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { useLocalStorage } from './useLocalStorage.hooks'
import { LocalStorageKeys } from 'common/constants/localstorage.constants'
import { useLazyGetAutoCheckRepliesQuery, useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'

export interface IAuthProvider {
  user: any
  login: any
  logout: any
  refetchUser: any
  refetchUserLite: any
  updateUserDetails: any
  userDetails: any
  isBetaOrTrialUser: boolean
  isPaidTrialUser: boolean
}

const AuthContext = createContext<IAuthProvider | null>(null)

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useLocalStorage(LocalStorageKeys.ACCESS_TOKEN, null)
  const [, setHubspot] = useLocalStorage(LocalStorageKeys.HUBSPOT_ACCESS_TOKEN, null)
  const [refetcGetAutoCheckReplies] = useLazyGetAutoCheckRepliesQuery()
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()

  const [userDetails, setUserDetails] = useLocalStorage('user', null)
  const navigate = useNavigate()

  const checkIsBetaOrTrialUser = (userObject: any) =>
    userObject?.subscription_type === 'BETA' || userObject?.subscription_type === 'TRIAL'

  const checkIsPaidTrialUser = (userObject: any) => userObject?.subscription_type === 'PAID_TRIAL'

  const login = async (data: any) => {
    setUser(data.accessToken)
    setHubspot(data.hubspotToken)
    localStorage.setItem('fetchDeals', 'true')
    checkIsBetaOrTrialUser(data.user) || checkIsPaidTrialUser(data.user)
      ? localStorage.setItem('showBanner', 'true')
      : localStorage.setItem('showBanner', 'false')
    // Userpilot.identify(data.user.id, {
    //   name: data.user.name,
    //   email: data.user.email,
    //   created_at: data.user.subscription_created_at,
    // })
    refetchUser(data.user)
  }

  const logout = () => {
    setUser(null)
    setHubspot(null)
    localStorage.clear()
    navigate('/login', { replace: true })
  }

  const refetchUser = (user: any) => {
    // user.did_funnel = true
    const autoCheckReplies = async () => {
      await refetcGetAutoCheckReplies()
    }

    autoCheckReplies()
    // refetcGetAutoCheckReplies()
    setUserDetails(user)
    navigate('/companies')
    window.location.reload()
  }

  const refetchUserLite = (user: any) => {
    setUserDetails(user)
  }

  const updateUserDetails = async () => {
    const { data } = await refetchGetUserDetails()
    data && setUserDetails(data)
  }

  const value = useMemo(
    () => ({
      user,
      userDetails,
      isBetaOrTrialUser: checkIsBetaOrTrialUser(userDetails),
      isPaidTrialUser: checkIsPaidTrialUser(userDetails),
      login,
      logout,
      refetchUser,
      refetchUserLite,
      updateUserDetails,
    }),
    [user, userDetails],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext) as IAuthProvider
