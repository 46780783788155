import React, { useEffect, useState } from 'react'
import styles from './TemplateLinkedin.module.scss'
import CustomNode from '../Nodes/CustomNode'
import NodeWrapper from './NodeWrapper'

interface TemplateLinkedinProps {
  setCurrentSideBar: any
  delay?: any
}

const calculateNodePosition = (parentPosition: any, parentType: any, childIndex: any, childType: any, id: any) => {
  const offsetX = parentType === 'single' && childType === 'multi' ? -66 : parentType === 'single' ? 0 : 0
  const offsetY = parentType === 'single' ? 120 : 148

  const extraX = id.includes('3_1') ? -130 : id.includes('2_2') ? -123 : id.includes('7_1') ? 263 : 0

  const x = parentPosition.x + offsetX + extraX
  const y = parentPosition.y + offsetY

  return { x, y }
}

const nodes = [
  {
    id: '1',
    parentId: null,
    type: 'single',
    data: { label: `Connect with Lead's Account`, action: 'linkedin_connect', childType: 'single' },
    position: { x: 0, y: 0 },
    storedData: {},
    children: [
      {
        id: '2',
        parentId: '1',
        type: 'multi',
        data: { label: 'Lead Accepted', action: 'is_linkedin_connected', childType: 'multi', isLastExit: true },
        position: { x: 0, y: 0 },
        storedData: {},
        children: [
          {
            id: '3_1',
            parentId: '2',
            type: 'multi',
            data: { label: 'Like', action: 'linkedin_like_post', childType: 'single' },
            position: { x: 0, y: 0 },
            storedData: {},
            children: [
              {
                id: '4_1',
                parentId: '2_1',
                type: 'multi',
                data: {
                  label: 'Wait',
                  action: 'is_wait',
                  childType: 'single',
                },
                position: { x: 0, y: 0 },
                storedData: {},
                children: [
                  {
                    id: '5_1',
                    parentId: '3_1',
                    type: 'single',
                    data: {
                      label: 'Send LinkedIn Message',
                      action: 'send_linkedin_message',
                      childType: 'single',
                      isLeftLast: true,
                    },
                    position: { x: 0, y: 0 },
                    storedData: {},
                    children: [
                      {
                        id: '6_1',
                        parentId: '4_1',
                        type: 'single',
                        data: { label: 'Message Replied', action: 'is_message_replied', childType: 'multi' },
                        position: { x: 0, y: 0 },
                        storedData: {},
                        children: [
                          {
                            id: '7_1',
                            parentId: '5_1',
                            type: 'multi',
                            data: {
                              label: 'Send LinkedIn Message',
                              action: 'send_linkedin_message',
                              childType: 'single',
                              isLast: true,
                              isLastExit: true,
                            },
                            position: { x: 0, y: 0 },
                            storedData: {},
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]

const renderNodes = (nodes: any, setCurrentSideBar: any, parentPosition: any, parentType: any, delay: any) => {
  return nodes.map((node: any, index: any) => {
    const newPosition = calculateNodePosition(parentPosition, parentType, index, node.data.childType, node.id)

    return (
      <NodeWrapper key={node.id} delay={delay}>
        <CustomNode
          key={node.id}
          id={node.id}
          type={node.type}
          data={node.data}
          position={newPosition}
          setCurrentSideBar={setCurrentSideBar}
          storedData={node.storedData}
        />

        {node.children && renderNodes(node.children, setCurrentSideBar, newPosition, node.data.childType, delay)}
      </NodeWrapper>
    )
  })
}

const TemplateLinkedin: React.FC<TemplateLinkedinProps> = ({ setCurrentSideBar, delay }) => {
  const [yAxisSize, setYAxisSize] = useState(0)
  useEffect(() => {
    const updateYAxisSize = () => {
      setYAxisSize((window.innerHeight + 40) / 2)
    }
    updateYAxisSize()
    window.addEventListener('resize', updateYAxisSize)
    return () => {
      window.removeEventListener('resize', updateYAxisSize)
    }
  }, [window.innerHeight, window.innerWidth])

  const initialPosition = { x: -50, y: -yAxisSize }
  useEffect(() => {
    const data = nodes[0].data
    const id = nodes[0].id
    setCurrentSideBar({ type: data.action, nodeId: id })
  }, [])
  return (
    <div className={styles.container}>{renderNodes(nodes, setCurrentSideBar, initialPosition, 'single', delay)}</div>
  )
}

export default TemplateLinkedin
