import React, { useEffect } from 'react'
import styles from './TemplateZero.module.scss'
import CustomNode from '../Nodes/CustomNode'
import NodeWrapper from './NodeWrapper'

// interface Node {
//   id: string
//   parentId: string | null
//   type: string
//   data: {
//     label: string
//     action: string
//     childType?: string
//     isLast?: boolean
//   }
//   position: {
//     x: number
//     y: number
//   }
//   storedData: any
//   children?: Node[]
// }

interface TemplateZeroProps {
  setCurrentSideBar: any
  delay?: any
}

const calculateNodePosition = (parentPosition: any, parentType: any, childIndex: any, childType: any, id: any) => {
  const offsetX = parentType === 'single' && childType === 'multi' ? -66 : parentType === 'single' ? 0 : 0
  const offsetY = parentType === 'single' ? 120 : 148

  const extraX = id.includes('3_1') ? 270 : id.includes('3_2') ? -123 : 0

  const x = parentPosition.x + offsetX + extraX
  const y = parentPosition.y + offsetY

  return { x, y }
}

const nodes = [
  {
    id: '1',
    parentId: null,
    type: 'single',
    data: { label: 'Send Telegram Message', action: 'send_tg_message', childType: 'single' },
    position: { x: 0, y: 0 },
    storedData: {},
    conditions_to_next_step: [{ type: 'not_receive_reply_after', minutes: '', next_step_id: '2' }],
    children: [
      {
        id: '2',
        parentId: '2',
        type: 'single',
        data: { label: 'Message Replied', childType: 'multi', action: 'is_message_replied' },
        position: { x: 0, y: 0 },
        storedData: {},
        conditions_to_next_step: [],
        children: [],
      },
    ],
  },
]

const renderNodes = (nodes: any, setCurrentSideBar: any, parentPosition: any, parentType: any, delay: any) => {
  return nodes.map((node: any, index: any) => {
    const newPosition = calculateNodePosition(parentPosition, parentType, index, node.data.childType, node.id)

    return (
      <NodeWrapper key={node.id} delay={delay}>
        <CustomNode
          key={node.id}
          id={node.id}
          type={node.type}
          data={node.data}
          position={newPosition}
          setCurrentSideBar={setCurrentSideBar}
          storedData={node.storedData}
        />

        {node.children && renderNodes(node.children, setCurrentSideBar, newPosition, node.data.childType, delay)}
      </NodeWrapper>
    )
  })
}

const TemplateZero: React.FC<TemplateZeroProps> = ({ setCurrentSideBar, delay }) => {
  const initialPosition = { x: -150, y: -275 }
  useEffect(() => {
    const data = nodes[0].data
    const id = nodes[0].id
    const conditions_to_next_step = nodes[0].conditions_to_next_step
    setCurrentSideBar({ type: data.action, nodeId: id, conditions_to_next_step: conditions_to_next_step })
  }, [])
  return (
    <div className={styles.container}>{renderNodes(nodes, setCurrentSideBar, initialPosition, 'single', delay)}</div>
  )
}

export default TemplateZero
