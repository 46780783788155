import { createSlice } from '@reduxjs/toolkit'

interface IGeneralSlice {
  status: string
  outOfOutreach: boolean
}

const initialState = {
  status: '',
  outOfOutreach: false,
} as IGeneralSlice

export const GeneralSlice = createSlice({
  name: 'General',
  initialState,
  reducers: {
    SET_OUT_OF_OUTREACH(state, action) {
      state.status = 'succeeded'
      state.outOfOutreach = action.payload
    },
    RESET_GENERAL(state) {
      return initialState
    },
  },
})

export const { SET_OUT_OF_OUTREACH, RESET_GENERAL } = GeneralSlice.actions
export const GeneralReducer = GeneralSlice.reducer
