import { Col, Row, Select } from 'antd'
import _ from 'lodash'

const generateYearOptions = () => {
  const currentYear = new Date().getFullYear()
  const startYear = 1998
  const yearOptions = []

  for (let year = currentYear; year >= startYear; year--) {
    yearOptions.push({
      value: year.toString(),
      label: year.toString(),
    })
  }

  return yearOptions
}

// const resultYearOptions = generateYearOptions()

const RangeYears = ({ filter, item, onNumberChange, min }: any) => {
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const startYearValue = _.get(filter, item?.children?.[0].key || '')
  const endYearValue = _.get(filter, item?.children?.[1].key || '')

  const startYearOptions = endYearValue
    ? generateYearOptions().filter((option) => option.value <= endYearValue)
    : generateYearOptions()

  const endYearOptions = startYearValue
    ? generateYearOptions().filter((option) => option.value >= startYearValue)
    : generateYearOptions()

  return (
    <Row>
      <Col span={11}>
        <Select
          style={{ width: '85px' }}
          showSearch
          placeholder='Start'
          optionFilterProp='children'
          onChange={(event) => {
            onNumberChange(item?.children?.[0]?.key || '', item?.children?.[1]?.key || '', Number(event), 'min')
          }}
          filterOption={filterOption}
          value={startYearValue}
          options={startYearOptions}
        />
      </Col>
      <Col span={2}>{/* <span className={'rangeSeparator'}>-</span> */}</Col>
      <Col span={11}>
        <Select
          style={{ width: '85px' }}
          showSearch
          placeholder='End'
          optionFilterProp='children'
          onChange={(event) => {
            onNumberChange(item?.children?.[0]?.key || '', item?.children?.[1]?.key || '', Number(event), 'max')
          }}
          filterOption={filterOption}
          value={endYearValue}
          options={endYearOptions}
        />
      </Col>
    </Row>
  )
}

export default RangeYears
