import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Select, Switch, DatePicker, message } from 'antd'
import styles from './LaunchCampaignModal.module.scss'
import three_dots from 'common/assets/svg/three_dots.svg'
import RunInScheduleModal from './RunInScheduleModal'

import {
  useLazyGetCampaignScheduleQuery,
  useLazyGetCampaignsSettingsQuery,
} from 'features/Outreach/state/api/OutreachApi'
import { useAppSelector } from 'state'
import dayjs from 'dayjs'
// import moment from 'moment-timezone'
import { post } from 'common/api/axios'

interface LaunchCampaignModalProps {
  open: boolean
  onClose: () => void
  onFinish: (values: { whenToStart: string; runInBetweenHours: string }) => void
  campaignId: any
}

const LaunchCampaignModal: React.FC<LaunchCampaignModalProps> = ({ open, onClose, onFinish, campaignId }) => {
  const [form] = Form.useForm()

  const [refetcGetCampaignSchedule] = useLazyGetCampaignScheduleQuery()
  const [refetcGetCampaignSettings] = useLazyGetCampaignsSettingsQuery()
  const [runInBetweenHoursOptions, setRunInBetweenHoursOptions] = useState<any[]>([])
  const { campaign_id, campaign_settings } = useAppSelector((state) => state.outreachCampaign)
  const [scheduleToOpen, setScheduleToOpen] = useState('Default Schedule')
  const [runInScheduleModalOpen, setRunInScheduleModalOpen] = useState(false)
  const [updateSchedules, setUpdateSchedules] = useState(0)
  const [scheduleData, setScheduleData] = useState([])
  const [cooldownSwitch, setCooldownSwitch] = useState(true)
  // const timeZones = moment.tz.names().map((zone) => ({
  //   name: zone,
  //   offset: moment.tz(zone).format('Z'),
  // }))
  // const usersTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  // eslint-disable-next-line
  const [defaultWhenToStart, setDefualtWhenToStart] = useState(campaign_settings?.whenToStart)

  // eslint-disable-next-line
  const [defaultWhenToStartString, setDefualtWhenToStartString] = useState(campaign_settings?.whenToStartString)

  // eslint-disable-next-line
  const [defaultRunInBetween, setDefualtRunInBetween] = useState(campaign_settings?.runInBetweenHours)
  const [showDatePicker, setShowDatePicker] = useState(
    defaultWhenToStartString !== 'On Campaign Launch' && !!defaultWhenToStartString,
  )

  // const handleTime = (values: any, selectedSchedule: any) => {
  //   let whenToStart = values?.whenToStart
  //   const runInBetweenHours = selectedSchedule
  //   const scheduleDate = values?.scheduleDate

  //   if (whenToStart === 'On Campaign Launch') {
  //     whenToStart = new Date()
  //   } else {
  //     whenToStart = scheduleDate
  //   }

  //   return { whenToStart, runInBetweenHours }
  // }

  const handleFormValues = (values: any) => {
    const whenToStart = values.scheduleDate ? values.scheduleDate : dayjs()
    if (defaultRunInBetween) {
      // const whenToStart = values.whenToStart
      // const cooldownSwitch = values.cooldownSwitch
      return {
        whenToStart: whenToStart,
        runInBetweenHours: defaultRunInBetween.schedule_id,
        cooldownSwitch: cooldownSwitch,
        ...defaultRunInBetween,
      }
    } else {
      const scheduleData = runInBetweenHoursOptions.filter(
        (schedule: any) => schedule.schedule_id === values.runInBetweenHours,
      )
      return { ...values, whenToStart: whenToStart, ...scheduleData[0], cooldownSwitch }
    }
  }

  const handleFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const updatedValues = handleFormValues(values)
        post('/log', {
          action: 'Final approval - Updated Launch Campaign',
          data: updatedValues,
        })
        onFinish(updatedValues)
        form.resetFields()
      })
      .catch((errorInfo) => {
        console.log('Failed:', errorInfo)
      })
  }

  const disabledDate = (current: any) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return current.valueOf() <= today.valueOf()
  }

  const handleWhenToStartChange = (value: string) => {
    setShowDatePicker(value === 'Custom Date & Time')
  }

  useEffect(() => {
    const getCampaignsSchedule = async () => {
      const { data } = await refetcGetCampaignSchedule()
      const schedules = data?.response
      // const runInBetween = schedules?.map(({ schedule_id, schedule_label }: any) => ({ schedule_id, schedule_label }))
      setRunInBetweenHoursOptions(schedules)
    }
    const getCampaignsSettings = async () => {
      await refetcGetCampaignSettings({ campaign_id: campaign_id })
    }

    getCampaignsSchedule()
    getCampaignsSettings()
  }, [])

  const handleRunInBetweenHoursChange = (value: any) => {
    if (value.schedule_label === 'Custom Date & Time') {
      setScheduleToOpen('custom')
    } else if (value.schedule_label === 'Default Schedule') {
      setScheduleToOpen('Default Schedule')
    } else {
      setScheduleToOpen(value.schedule_label)
    }
    setRunInScheduleModalOpen(true)
  }

  const handleRunInScheduleModalClose = () => {
    setRunInScheduleModalOpen(false)
  }

  const handleRunInScheduleModalSave = async (schedule: any) => {
    setRunInBetweenHoursOptions((prevOptions) => [
      ...prevOptions,
      { schedule_id: schedule.schedule_id, schedule_label: schedule.schedule_label },
    ])
    await refetcGetCampaignSchedule()
    setRunInScheduleModalOpen(false)
    message.success('Schedule Saved Successfully!')
  }

  const handleRunInScheduleModalUpdate = async () => {
    setUpdateSchedules((prev) => prev + 1)
    setRunInScheduleModalOpen(false)
    message.success('Schedule Updated Successfully!')
  }

  useEffect(() => {
    const getCampaignsSchedule = async () => {
      const { data } = await refetcGetCampaignSchedule()
      const schedules = data?.response
      // const labels = schedules.map((schedule: any) => schedule.schedule_label)

      const runInBetween = schedules.map(({ schedule_id, schedule_label }: any) => ({ schedule_id, schedule_label }))
      setRunInBetweenHoursOptions([{ schedule_id: '1', schedule_label: 'Custom Date & Time' }, ...runInBetween])
      setScheduleData(schedules)
    }
    getCampaignsSchedule()
  }, [scheduleToOpen, updateSchedules])

  const handleCooldownSwitch = () => {
    setCooldownSwitch(!cooldownSwitch)
  }

  return (
    <Modal centered open={open} onCancel={onClose} footer={null} style={{ minWidth: '500px' }} width={'fit-content'}>
      <div className={styles.container}>
        <div className={styles.header}>
          <p>You Are About To Launch Your Campaign</p>
          <span>Please make sure the details are correct</span>
        </div>
        <Form form={form} onFinish={handleFinish} layout='vertical'>
          <Form.Item
            name='whenToStart'
            label={<span style={{ fontWeight: 600 }}>When to Start</span>}
            rules={[{ required: true, message: 'Please select a date and time' }]}
            initialValue={!showDatePicker ? 'On Campaign Launch' : 'Custom Date & Time'}>
            <Select onChange={handleWhenToStartChange} size='large' placeholder='Select when to start your campaign'>
              <Select.Option value='On Campaign Launch'>On Campaign Launch</Select.Option>
              <Select.Option value='Custom Date & Time'>Custom Date & Time</Select.Option>
            </Select>
          </Form.Item>

          {/* <Form.Item
            name='timeZone'
            label={<span style={{ fontWeight: 600 }}>When to Start's Time Zone</span>}
            rules={[{ required: true, message: 'Please select a Time Zone' }]}
            initialValue={usersTimezone}>
            <Select size='large' placeholder='Select time zone' showSearch defaultValue={usersTimezone}>
              {timeZones.map((zone, index) => (
                <Select.Option key={index} value={zone.name}>
                  {`${zone.name} (UTC${zone.offset})`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}

          {showDatePicker && (
            <Form.Item
              name='scheduleDate'
              label={<span style={{ fontWeight: 600 }}>Schedule Date & Time</span>}
              rules={[{ required: true, message: 'Please select a date and time' }]}
              initialValue={!!defaultWhenToStart ? defaultWhenToStart : null}>
              <DatePicker
                defaultValue={!!defaultWhenToStart ? defaultWhenToStart : null}
                showTime={{ minuteStep: 15 }}
                disabledDate={disabledDate}
                format='MM-DD-YYYY HH:mm'
                size='large'
                style={{ width: '100%' }}
              />
            </Form.Item>
          )}

          <Form.Item
            name='runInBetweenHours'
            label={<span style={{ fontWeight: 600 }}>Run in Between Hours</span>}
            rules={[{ required: true, message: 'Please select a date and time' }]}
            initialValue={!!defaultRunInBetween ? `${defaultRunInBetween?.schedule_label}` : null}>
            {/* <Select
              // onChange={handleRunInBetweenHoursChange}
              size='large'
              placeholder='Select your schedule'
              // defaultValue={`${defaultRunInBetween}`}
              // defaultValue='{defaultWhenToStart}'

              defaultValue={`${defaultRunInBetween?.schedule_label}`}
              onChange={() => setDefualtRunInBetween(null)}
              optionLabelProp='key'>
              {runInBetweenHoursOptions?.map((option) => (
                <Select.Option key={option.schedule_label} value={option.schedule_id}>
                  <div className={styles.customSelect}>
                    <span>{option.schedule_label}</span>
                  </div>
                </Select.Option>
              ))}
            </Select> */}
            <Select
              // onChange={handleRunInBetweenHoursChange}
              // open={true}
              size='large'
              placeholder='Select your schedule'
              optionLabelProp='key'>
              {runInBetweenHoursOptions.map((option) => (
                <Select.Option
                  key={option.schedule_label === 'Custom Date & Time' ? undefined : option.schedule_label}
                  value={option.schedule_label === 'Custom Date & Time' ? undefined : option.schedule_id}>
                  <div
                    className={styles.customSelect}
                    onClick={() =>
                      option.schedule_label === 'Custom Date & Time' && handleRunInBetweenHoursChange(option)
                    }>
                    <span>{option.schedule_label}</span>
                    {option.schedule_label !== 'Custom Date & Time' && (
                      <img
                        style={{ padding: '10px 0px' }}
                        onClick={() => handleRunInBetweenHoursChange(option)}
                        src={three_dots}
                        alt=''
                      />
                    )}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name='cooldownSwitch' initialValue={cooldownSwitch}>
            <Switch checked={cooldownSwitch} onChange={handleCooldownSwitch} />
            <span style={{ marginLeft: '10px', fontWeight: 600 }}>Keep Sending Messages After Cooldown</span>
          </Form.Item>

          <div className={styles.footer}>
            <Button type='primary' htmlType='submit'>
              <span style={{ fontWeight: 600, fontSize: '15px' }}>Launch Campaign</span>
            </Button>
          </div>
        </Form>

        {runInScheduleModalOpen && (
          <RunInScheduleModal
            open={runInScheduleModalOpen}
            onClose={handleRunInScheduleModalClose}
            onSave={handleRunInScheduleModalSave}
            onUpdate={handleRunInScheduleModalUpdate}
            data={scheduleData}
            scheduleToOpen={scheduleToOpen}
          />
        )}
      </div>
    </Modal>
  )
}

export default LaunchCampaignModal
