import user from 'common/assets/png/user_table.png'
import telegram from 'common/assets/svg/tele_icon.svg'
import instagram from 'common/assets/svg/insta_icon.svg'
import linkedin from 'common/assets/svg/linked_icon.svg'
import discord from 'common/assets/svg/disc_icon.svg'
import twitter from 'common/assets/svg/xtwit_icon.svg'
import facebook from 'common/assets/svg/facebook_icon.svg'
import add_icon from 'common/assets/svg/add_icon.svg'
import channel_icon from 'common/assets/svg/channel_icon.svg'

const chatData_old = [
  {
    id: '1',
    icon: telegram,
    title: 'Telegram',
    channels: [
      {
        id: '1',
        icon: user,
        name: 'All active accounts',
        count: 6,
        online: true,
        messages: [
          { id: '1', text: 'Lorem ipsum dolor sit amet', sender: 'username1.il', timestamp: '2024-02-06T12:00:00' },
          { id: '2', text: 'abc', receiver: 'username2.il', timestamp: '2024-02-06T12:00:00' },
          { id: '3', text: 'xyz', sender: 'username1.il', timestamp: '2024-02-06T12:00:00' },
          { id: '4', text: 'pqr', receiver: 'username2.il', timestamp: '2024-02-06T12:00:00' },
        ],
      },
      {
        id: '2',
        icon: user,
        name: 'username1.il',
        online: true,
        messages: [
          { id: '1', text: '2nd Lorem ipsum dolor sit amet', sender: 'username1.il', timestamp: '2024-02-06T12:00:00' },
          { id: '2', text: '2nd abc', sender: 'username1.il', timestamp: '2024-02-06T12:00:00' },
        ],
      },
      { id: '3', icon: user, name: 'username2.il', online: false },
      { id: '4', icon: user, name: 'username3.il', count: 6, online: true },
      { id: '5', icon: user, name: 'username4.il', online: false },
      { id: '6', icon: user, name: 'username5.il', online: true },
      { id: '7', icon: add_icon, name: 'Add Facebook account' },
    ],
  },
  {
    id: '2',
    icon: instagram,
    title: 'Instagram',
    channels: [
      { id: '1', icon: user, name: 'All active accounts', online: true },
      { id: '2', icon: user, name: 'username6.il', online: false },
    ],
  },
  {
    id: '3',
    icon: facebook,
    title: 'Facebook',
    channels: [
      { id: '1', icon: user, name: 'All active accounts', online: true },
      { id: '2', icon: user, name: 'username7.il', online: true },
    ],
  },
  {
    id: '4',
    icon: linkedin,
    title: 'LinkedIn',
    channels: [
      { id: '1', icon: user, name: 'All active accounts', online: true },
      { id: '2', icon: user, name: 'username8.il', online: false },
    ],
  },
  {
    id: '5',
    icon: discord,
    title: 'Discord',
    channels: [
      { id: '1', icon: user, name: 'All active accounts', online: true },
      { id: '2', icon: user, name: 'username9.il', online: false },
    ],
  },
  {
    id: '6',
    icon: twitter,
    title: 'X (Twitter)',
    channels: [
      { id: '1', icon: user, name: 'All active accounts', online: true },
      { id: '2', icon: user, name: 'username10.il', online: false },
    ],
  },
]

const chatData = [
  {
    id: '1',
    icon: telegram,
    title: 'Telegram',
    channels: [
      {
        id: '1',
        icon: user,
        name: 'All active accounts',
        count: 6,
        online: true,
        messages: [
          { id: '1', text: 'Lorem ipsum dolor sit amet', sender: 'username1.il', timestamp: '2024-02-06T12:00:00' },
          { id: '2', text: 'abc', receiver: 'username2.il', timestamp: '2024-02-06T12:00:00' },
          { id: '3', text: 'xyz', sender: 'username1.il', timestamp: '2024-02-06T12:00:00' },
          { id: '4', text: 'pqr', receiver: 'username2.il', timestamp: '2024-02-06T12:00:00' },
        ],
      },
      {
        id: '2',
        icon: user,
        name: 'username1.il',
        online: true,
        messages: [
          { id: '1', text: '2nd Lorem ipsum dolor sit amet', sender: 'username1.il', timestamp: '2024-02-06T12:00:00' },
          { id: '2', text: '2nd abc', sender: 'username1.il', timestamp: '2024-02-06T12:00:00' },
        ],
      },
      { id: '3', icon: user, name: 'username2.il', online: false },
      { id: '4', icon: user, name: 'username3.il', count: 6, online: true },
      { id: '5', icon: user, name: 'username4.il', online: false },
      { id: '6', icon: user, name: 'username5.il', online: true },
      { id: '7', icon: add_icon, name: 'Add Facebook account' },
    ],
  },
  {
    id: '2',
    icon: instagram,
    title: 'Instagram',
    channels: [
      { id: '1', icon: user, name: 'All active accounts', online: true },
      { id: '2', icon: user, name: 'username6.il', online: false },
    ],
  },
  {
    id: '3',
    icon: facebook,
    title: 'Facebook',
    channels: [
      { id: '1', icon: user, name: 'All active accounts', online: true },
      { id: '2', icon: user, name: 'username7.il', online: true },
    ],
  },
  {
    id: '4',
    icon: linkedin,
    title: 'LinkedIn',
    channels: [
      { id: '1', icon: user, name: 'All active accounts', online: true },
      { id: '2', icon: user, name: 'username8.il', online: false },
    ],
  },
  {
    id: '5',
    icon: discord,
    title: 'Discord',
    channels: [
      { id: '1', icon: user, name: 'All active accounts', online: true },
      { id: '2', icon: user, name: 'username9.il', online: false },
    ],
  },
  {
    id: '6',
    icon: twitter,
    title: 'X (Twitter)',
    channels: [
      { id: '1', icon: user, name: 'All active accounts', online: true },
      { id: '2', icon: user, name: 'username10.il', online: false },
    ],
  },
]

export default chatData
