import React from 'react'
import black_tick from 'common/assets/svg/black_tick.svg'
import connect_step from 'common/assets/svg/connect_step.svg'

import styles from './LinkedinLike.module.scss'

interface LinkedinConnectProps {
  currentSideBar?: any
  nodesData?: any
  setNodesData?: any
}

const LinkedinConnect: React.FC<LinkedinConnectProps> = ({ currentSideBar, nodesData, setNodesData }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={black_tick} alt='' />
        <span>Step is Set</span>
      </div>
      <div className={styles.mainBody}>
        <img src={connect_step} alt='' />
        <p>Connect with Lead's LinkedIn Account</p>
      </div>
    </div>
  )
}

export default LinkedinConnect
