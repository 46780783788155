import React from 'react'

import { Drawer } from 'antd'
import classnames from 'classnames'

import closeDrawer from 'common/assets/svg/closeDrawer.svg'

interface IFancyDrawer {
  children: any
  onClose?: () => void
  open: boolean
  title: any
  customClass?: string
  closable?: boolean
  extraClass?: string
  closeIcon?: any
}

const FancyDrawer: React.FC<IFancyDrawer> = ({
  children,
  onClose,
  open,
  title,
  customClass,
  extraClass,
  closeIcon,
}) => {
  return (
    <Drawer
      className={classnames(extraClass ? extraClass : 'fancyDrawer', customClass ? customClass : '')}
      title={title}
      placement='right'
      onClose={onClose}
      open={open}
      width={460}
      closeIcon={closeIcon === 'no' ? null : <img src={closeDrawer} alt={'Close'} />}>
      {children}
    </Drawer>
  )
}

export default FancyDrawer
