import { createSlice } from '@reduxjs/toolkit'

import { IAdminCompanySlice } from '../interface/IAdminCompanySlice'

const initialState = {
  status: '',
} as IAdminCompanySlice

export const adminCompanySlice = createSlice({
  name: 'admin_company',
  initialState,
  reducers: {
    SET_USERS(state, action) {
      state.status = 'succeeded'
      state.users = action.payload
    },
  },
})

export const { SET_USERS } = adminCompanySlice.actions

export const adminCompanyReducer = adminCompanySlice.reducer
