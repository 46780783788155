import React, { useState } from 'react'
import hubspot from 'common/assets/svg/hubspot_purple.svg'
import styles from './NewCampaignHubspotEnrichment.module.scss'
import { Checkbox, Form, Select, Switch } from 'antd'
// import { get } from 'common/api/axios'

interface NewCampaignHubspotEnrichmentProps {
  currentSideBar: any
  nodesData: any
  setNodesData: any
}

const NewCampaignHubspotEnrichment: React.FC<NewCampaignHubspotEnrichmentProps> = ({
  currentSideBar,
  nodesData,
  setNodesData,
}) => {
  const [form] = Form.useForm()
  const [onToggle, setOnToggle] = useState(true)

  const pipeline: any = []
  // const [pipeline, setPipeline] = useState<any>([])

  const owners: any = []
  const stages: any = []

  const onChangeToggle = () => {
    setOnToggle(!onToggle)
  }

  // const handleKeyDown = (event: any) => {
  //   if (
  //     (event.key >= '0' && event.key <= '9') ||
  //     event.key === 'Backspace' ||
  //     event.key === 'Delete' ||
  //     event.key === 'ArrowLeft' ||
  //     event.key === 'ArrowRight' ||
  //     event.key === 'ArrowUp' ||
  //     event.key === 'ArrowDown'
  //   ) {
  //     return
  //   } else {
  //     event.preventDefault()
  //   }
  // }

  // const updateNodesData = (
  //   nodeId: any,
  //   action: any,
  //   pipeline: any,
  //   stage: any,
  //   include_messages: any,
  //   enrich_data: any,
  // ) => {
  //   setNodesData((prev: any) => ({
  //     ...prev,
  //     steps: {
  //       ...prev.steps,
  //       [nodeId]: {
  //         action: action,
  //         pipeline: pipeline,
  //         stage: stage,
  //         include_messages: include_messages,
  //         enrich_data: enrich_data,
  //       },
  //     },
  //   }))
  // }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={hubspot} alt='' />
        <span>Setup Hubspot Data Enrichment</span>
      </div>
      <Form
        form={form}
        id='dealForm'
        name='dealForm'
        // onFinish={isCompany ? handleSubmitCompany : handleSubmit}
        // onValuesChange={handleFormValuesChange}
        style={{ maxWidth: 1200 }}
        className='newDealForm'
        colon={false}>
        <Form.Item style={{ marginBottom: 0 }}>
          <span style={{ fontWeight: 600 }}>Action</span>
          <Form.Item name='pipeline' className={styles.formSelect} style={{ margin: '10px 0px 30px 0px' }}>
            <Select size={'large'}>
              {pipeline.length === 0 && (
                <Select.Option value={null} disabled>
                  No options available
                </Select.Option>
              )}
              {pipeline.map((pipeline: any, index: number) => (
                <Select.Option key={index} value={pipeline.label}>
                  {pipeline.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* <Form.Item label={<p>Deal Revenue (Amount)</p>} name='dealRevenue' className={styles.formSelect}>
            <InputNumber size={'large'} placeholder='' min={0} style={{ width: '100%' }} onKeyDown={handleKeyDown} />
          </Form.Item> */}
          <span style={{ fontWeight: 600 }}>Pipeline</span>

          <Form.Item
            // label={<p style={{ fontWeight: 600 }}>Pipeline</p>}
            name='dealOwner'
            className={styles.formSelect}
            style={{ margin: '10px 0px 30px 0px' }}>
            <Select size={'large'}>
              {owners.length === 0 && (
                <Select.Option value={null} disabled>
                  No options available
                </Select.Option>
              )}
              {owners?.map((item: any) => (
                <Select.Option key={item.id} value={item.id}>
                  {`${item.firstName} (${item.email})`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <span style={{ fontWeight: 600 }}>Stage</span>

          <Form.Item name='dealStage' className={styles.formSelect} style={{ margin: '10px 0px 30px 0px' }}>
            <Select size={'large'}>
              {stages.length === 0 && (
                <Select.Option value={null} disabled>
                  No options available
                </Select.Option>
              )}
              {stages?.map((item: any, index: number) => (
                <Select.Option key={index} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item name={'enrichment'}>
          <div className={styles.enrichment}>
            <div className={styles.top}>
              <div className={styles.left}>
                <p>Include messages on deal notes</p>
                <span>
                  Let Convrt enrich your data for improved results. This option <br /> incurs a credit cost, you can set
                  your enrichment preferences.
                </span>
              </div>
              <div className={styles.right}>
                <Switch defaultChecked onChange={onChangeToggle} />
                {/* <span>{onToggle ? 'ON' : 'OFF'}</span> */}
              </div>
            </div>
          </div>
        </Form.Item>
        <Form.Item name={'enrichment'}>
          <div className={styles.enrichment}>
            <div className={styles.top}>
              <div className={styles.left}>
                <p>Enrich contact & companies</p>
                <span>
                  Let Convrt enrich your data for improved results. This option <br /> incurs a credit cost, you can set
                  your enrichment preferences.
                </span>
                <div className={styles.bottom}>
                  <Checkbox />
                  <span style={{ marginLeft: '6px' }}>Override existing data</span>
                </div>
              </div>
              <div className={styles.right}>
                <Switch defaultChecked onChange={onChangeToggle} />
                {/* <span>{onToggle ? 'ON' : 'OFF'}</span> */}
              </div>
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  )
}

export default NewCampaignHubspotEnrichment
