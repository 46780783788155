import { useState, createContext, useContext, useEffect } from 'react'
import { message as popupMessage } from 'antd'
import { SET_IS_SEND_MESSAGE } from '../../features/contacts/state/slice/contactsSlice'
import { useAppDispatch } from 'state'

import { get, post } from 'common/api/axios'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useLocalStorage } from 'common/hooks/useLocalStorage.hooks'
import { SET_IS_SENT } from 'features/Outreach/state/sendMessageSlice'

export const TelegramContext = createContext<any>(null)

export const TelegramProvider = ({ children }: { children: any }) => {
  const [cooldown, setCooldown] = useState<boolean>(false)
  const [cooldownSendMessages, setCooldownSendMessages] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const [, setContactsLoadingStatus] = useLocalStorage('loading_status_outreach', [])

  const { user } = useAuth()

  useEffect(() => {
    if (user) getCooldown()
  }, [user])

  const sendMessages = async (messages: any[], user_telegram_id: string, isSingle: boolean) => {
    try {
      dispatch(SET_IS_SEND_MESSAGE(true))
      popupMessage.success(
        `Sending ${messages.length} ${
          messages.length === 1 ? 'message' : 'messages'
        }. We'll notify you when outreach is complete.`,
      )

      const messageResponse = await post(`/outreach/sendMessage`, { messages, user_telegram_id, isSingle })
      dispatch(SET_IS_SEND_MESSAGE(false))
      setCooldownSendMessages(messageResponse?.data?.cooldown)

      const statuses = messageResponse?.data?.messagesStatus

      const hasFalseStatus = statuses.some((item: any) => item.status === false)
      const allFalseStatus = statuses.every((item: any) => item.status === false)

      let loadingStatus = JSON.parse(localStorage.getItem('loading_status_outreach') || '[]')
      const updatedLoadingStatusArray = loadingStatus.map((statusItem: any) => {
        const correspondingDataItem = statuses.find((dataItem: any) => dataItem.contact_id === statusItem.contact_id)
        if (correspondingDataItem) {
          return {
            ...statusItem,
            isLoading: correspondingDataItem.isLoading,
            status: correspondingDataItem.status,
          }
        }
        return statusItem
      })
      dispatch(SET_IS_SENT(true))
      setContactsLoadingStatus(updatedLoadingStatusArray)

      if (allFalseStatus) {
        popupMessage.error(messageResponse?.data.message)
      } else if (hasFalseStatus) {
        popupMessage.warning(messageResponse?.data.message)
      } else {
        popupMessage.success(messageResponse?.data.message)
      }
      if (messageResponse?.data?.errorMsssage?.contacts.length > 0)
        popupMessage.error(
          `Sending message to ${messageResponse?.data?.errorMsssage?.contacts.join(
            ', ',
          )} Failed. Please try again later`,
        )
    } catch (e) {
      dispatch(SET_IS_SEND_MESSAGE(false))
      popupMessage['error']('Failed. Please try again later')
      console.error(e)
    }
  }

  const getCooldown = async () => {
    try {
      const response = await get('/outreach/cooldown')
      setCooldown(!!response?.data?.cooldown)
    } catch (e) {
      // console.error(e)
    }
  }

  return (
    <TelegramContext.Provider
      value={{
        cooldown,
        setCooldown,
        sendMessages,
        cooldownSendMessages,
        setCooldownSendMessages,
        getCooldown,
      }}>
      {children}
    </TelegramContext.Provider>
  )
}

export const useTelegram = () => useContext(TelegramContext)
