import { Skeleton } from 'antd'

interface InfoCardProp {
  src: string
  title: string
  subTitle: string | number
  isLoading?: boolean
}
const InfoCard: React.FC<InfoCardProp> = ({ src, title, subTitle, isLoading }) => {
  return (
    <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
      <img src={src} alt='' />
      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <label style={{ color: '#666' }}>{title}</label>
        {isLoading ? <Skeleton.Input block active /> : <span style={{ color: '#111' }}>{subTitle}</span>}
      </div>
    </div>
  )
}
export default InfoCard
