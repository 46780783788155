import { Button, Modal } from 'antd'

import styles from './CooldownModal.module.scss'

const CooldownModal = ({ open, onClose: handleClose }: any) => (
  <div className={styles.modal}>
    <Modal
      centered
      open={open}
      okButtonProps={{
        className: 'hidden',
      }}
      cancelButtonProps={{
        className: 'hidden',
      }}
      onCancel={handleClose}
      width={600}
      bodyStyle={{
        height: 200,
        display: 'flex',
        flexDirection: 'column',
      }}>
      <h2 className={styles.text}>{`Telegram Rules Violation Alert`}</h2>
      <p
        className={
          styles.text
        }>{`Your Telegram account has reached its daily quota. please refrain from messaging for the next 24 hours to reset your limits.`}</p>
      <div className={styles.buttonContainer}>
        <Button className={styles.button} type='primary' onClick={handleClose}>{`Got It`}</Button>
      </div>
    </Modal>
  </div>
)

export default CooldownModal
