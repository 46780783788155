import { useState, createContext, useContext } from 'react'

export const ShowStartScreenContext = createContext<any>(null)

export const ShowStartScreenProvider = ({ children }: { children: any }) => {
  const [showStartScreen, setShowStartScreen] = useState<boolean>(true)

  return (
    <ShowStartScreenContext.Provider
      value={{
        showStartScreen,
        setShowStartScreen,
      }}>
      {children}
    </ShowStartScreenContext.Provider>
  )
}

export const useShowStartScreen = () => useContext(ShowStartScreenContext)
