import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'antd'
import styles from './TemplatesWizardModal.module.scss'
import wizard_icon from 'common/assets/svg/wizard_icon.svg'
import template_one from 'common/assets/svg/template_one.svg'
import template_two from 'common/assets/svg/template_two.svg'
import template_three from 'common/assets/svg/template_three.svg'
import linkedin_flow from 'common/assets/svg/linkedin_flow.svg'
import right_arrow from 'common/assets/svg/right_arrow_white.svg'
import WizardBlock from './UI/WizardBlock'
import { SET_TEMPLATE } from '../../../state/outreachCampaignSlice'
import { useAppDispatch } from 'state'
import { post } from 'common/api/axios'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'

interface TemplatesWizardModalProps {
  open: boolean
  onCancel: () => void
  onFinish: () => void
}

const TemplatesWizardModal: React.FC<TemplatesWizardModalProps> = ({ open, onCancel, onFinish }) => {
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [maxTemplate, setMaxTemplate] = useState(0)
  const [template, setTemplate] = useState(0)
  const [platform, setPlatform] = useState('telegram')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const dispatch = useAppDispatch()
  const errorMessage =
    maxTemplate > 0 ? `Your Plan Supports Up To ${maxTemplate} Followup!` : `Your Plan Does Not Support Followups!`
  const template_img = template === 0 ? template_one : template === 1 ? template_two : template_three

  useEffect(() => {
    const getAddons = async () => {
      const { data } = await refetchGetUserDetails()
      const addons = data?.addons
      const outreachFollowups = addons?.outreach_followups
      if (typeof outreachFollowups === 'number' && outreachFollowups >= 0 && outreachFollowups <= 2) {
        setMaxTemplate(outreachFollowups)
      } else {
        setMaxTemplate(0)
      }
    }

    dispatch(SET_TEMPLATE(template))
    getAddons()
  }, [])

  useEffect(() => {
    setShowErrorMessage(false)
  }, [template])

  const handleGenerate = () => {
    if (template > maxTemplate && template < 3) {
      setShowErrorMessage(true)
    } else {
      post('/log', {
        action: 'Generate Template Campaign',
        data: template,
      })
      onFinish()
    }
  }

  return (
    <Modal open={open} onCancel={onCancel} footer={null} width='1000px'>
      <div className={styles.header}>
        <p>Templates Wizard</p>
        <img src={wizard_icon} alt='' />
      </div>
      <div className={styles.modalContent}>
        <div className={styles.leftPanel}>
          <WizardBlock
            circleNumber={1}
            label='send_tg_message'
            setTemplate={setTemplate}
            setPlatform={setPlatform}
            platform={platform}
          />
          <WizardBlock
            circleNumber={2}
            label='linkedin'
            setPlatform={setPlatform}
            setTemplate={setTemplate}
            platform={platform}
          />
          <WizardBlock circleNumber={3} label='enrich_hubspot' />
          <WizardBlock
            circleNumber={4}
            label='request'
            setPlatform={setPlatform}
            setTemplate={setTemplate}
            platform={platform}
          />
        </div>
        <div className={styles.rightPanel}>
          <span className={styles.previewTitle}>Preview Example</span>
          <div className={styles.previewImage}>
            {platform === 'telegram' && <img src={template_img} alt='' />}
            {platform === 'linkedin' && <img style={{ height: '540px' }} src={linkedin_flow} alt='' />}
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        {showErrorMessage && (
          <span style={{ marginRight: '20px', color: 'red', fontWeight: 600 }}>{errorMessage} </span>
        )}
        <Button onClick={handleGenerate}>
          <span>Generate</span>
          <img src={right_arrow} alt='' />
        </Button>
      </div>
    </Modal>
  )
}

export default TemplatesWizardModal
