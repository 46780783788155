import React from 'react'
import styles from './ChatMessagesOverview.module.scss'
import { Card, Skeleton } from 'antd'

interface ChatMessagesOverviewProps {
  positive: number
  neutral: number
  negative: number
  loading: boolean
}

const ChatMessagesOverview: React.FC<ChatMessagesOverviewProps> = ({ positive, neutral, negative, loading }) => {
  const total = positive + negative + neutral

  let positivePercentage = Math.round((positive / total) * 100) || 0
  let negativePercentage = Math.round((negative / total) * 100) || 0
  let neutralPercentage = Math.round((neutral / total) * 100) || 0

  const percentageTotal = positivePercentage + negativePercentage + neutralPercentage

  if (percentageTotal !== 100 && total > 0) {
    neutralPercentage += 100 - percentageTotal
  }

  const nothingPercentage = positivePercentage + negativePercentage + neutralPercentage > 0 ? 0 : 100

  const borderRadius =
    (positive > 0 && neutral === 0 && negative === 0) ||
    (neutral > 0 && positive === 0 && negative === 0) ||
    (negative > 0 && positive === 0 && neutral === 0)
      ? '12px'
      : 'initial'

  return (
    <Card className={`campaignStatsCard ${styles.card}`}>
      <h3>Chat Messages Overview</h3>
      <div className={styles.progressBar}>
        {loading && <div className={styles.loadingBar}></div>}
        {!loading && (
          <>
            <div
              className={styles.negative}
              style={{
                width: `${negativePercentage}%`,
                borderRadius: negative > 0 && positive === 0 && neutral === 0 ? borderRadius : '12px 0px 0px 12px',
              }}></div>
            <div
              className={styles.neutral}
              style={{
                width: `${neutralPercentage}%`,
                borderRadius: neutral > 0 && positive === 0 && negative === 0 ? borderRadius : 'initial',
              }}></div>
            <div
              className={styles.positive}
              style={{
                width: `${positivePercentage}%`,
                borderRadius: positive > 0 && neutral === 0 && negative === 0 ? borderRadius : '0px 12px 12px 0px',
              }}></div>
            <div
              className={styles.nothing}
              style={{
                width: `${nothingPercentage}%`,
                borderRadius: '12px',
              }}></div>
          </>
        )}
      </div>
      <div className={styles.labels}>
        <div className={styles.text}>
          <span className={styles.header}>
            Negative <span className={styles.hide}>Messages</span>
          </span>
          <div className={styles.label}>
            <span className={styles.dot} style={{ backgroundColor: '#FF5C5C' }}></span>
            <span className={styles.count}>
              {loading ? (
                <Skeleton.Avatar shape='square' active={true} size={16} style={{ width: '22px', marginTop: '3px' }} />
              ) : (
                // negative
                `${negativePercentage} %`
              )}
            </span>
          </div>
        </div>
        <div className={styles.text}>
          <span className={styles.header}>
            Neutral <span className={styles.hide}>Messages</span>
          </span>
          <div className={styles.label}>
            <span className={styles.dot} style={{ backgroundColor: '#F9C628' }}></span>
            <span className={styles.count}>
              {loading ? (
                <Skeleton.Avatar shape='square' active={true} size={16} style={{ width: '22px', marginTop: '3px' }} />
              ) : (
                // neutral
                `${neutralPercentage} %`
              )}
            </span>
          </div>
        </div>
        <div className={styles.text}>
          <span className={styles.header}>
            Positive <span className={styles.hide}>Messages</span>
          </span>
          <div className={styles.label}>
            <span className={styles.dot} style={{ backgroundColor: '#02C3AB' }}></span>
            <span className={styles.count}>
              {loading ? (
                <Skeleton.Avatar shape='square' active={true} size={16} style={{ width: '22px', marginTop: '3px' }} />
              ) : (
                // positive
                `${positivePercentage} %`
              )}
            </span>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default ChatMessagesOverview
