export const headcounList = [
  {
    label: '1-10',
    value: '1-10',
    headcountMin: 1,
    headcountMax: 10,
  },
  {
    label: '11-50',
    value: '11-50',
    headcountMin: 11,
    headcountMax: 15,
  },
  {
    label: '51-200',
    value: '51-200',
    headcountMin: 51,
    headcountMax: 200,
  },
  {
    label: '201-500',
    value: '201-500',
    headcountMin: 201,
    headcountMax: 500,
  },
  {
    label: '501-1000',
    value: '501-1000',
    headcountMin: 501,
    headcountMax: 1000,
  },
  {
    label: '1001-5000',
    value: '1001-5000',
    headcountMin: 1001,
    headcountMax: 5000,
  },
  {
    label: '5001+',
    value: '5001+',
    headcountMin: 5001,
    headcountMax: 1000000000,
  },
]
